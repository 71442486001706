import React, { useContext, useEffect } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import BrandsContext from "../../context/Brands/BrandsContext";

export default function BrandSelect(props) {
  const { brands, GetBrands } = useContext(BrandsContext);

  useEffect(() => {
    GetBrands();
  }, []);
  const detectarCambiosBrand = (e) => {
    props.detectarCambiosBrand(e);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Marca</InputLabel>
          {props.brandId ? (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Marca "
              defaultValue={props.brandId}
              onChange={detectarCambiosBrand}
            >
              {brands.map((brand) => (
                <MenuItem key={brand._id} value={brand._id}>
                  {brand.name}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Marca "
              onChange={detectarCambiosBrand}
            >
              {brands.map((brand) => (
                <MenuItem key={brand._id} value={brand._id}>
                  {brand.name}
                </MenuItem>
              ))}
            </Select>
          )}
          <FormHelperText>Compo Requerido*</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}
