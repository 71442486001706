import React, { useContext, useEffect } from "react";
import Layout from "../../components/layout/Layout";
import CouponsContext from "../../context/coupons/CouponsContext";
import NoDataComponents from "../../components/loading/NoDataComponents";
import CouponExpCard from "../../components/Cards/Cupon/CuponExpCard";

import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";

const useStyles = makeStyles({
  titleCard: {
    color: "white",
    fontSize: "35px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 2,
  },
});

export default function CouponesExpired() {
  const classes = useStyles();
  const { coupons, GetCouponsExpired, success } = useContext(CouponsContext);

  useEffect(() => {
    GetCouponsExpired();
  }, [success]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#000000",
            textAling: "center",
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            // borderRadius: "0 0 24px 0",
          }}>
          <div className={classes.titleCard}>Cupones Expirados</div>
        </Box>
        <Box sx={{ width: "100%", paddingTop: 4, p: 3 }}>
          <Grid container spacing={2} sx={{ paddingBottom: 10 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container spacing={2}>
                {!coupons.length ? (
                  <NoDataComponents />
                ) : (
                  coupons.map((coupon) => (
                    <CouponExpCard coupon={coupon} key={coupon._id} />
                  ))
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
