import React, { useContext, useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { makeStyles } from "@mui/styles";
import DashboardContext from "../../../context/Dashboard/DashboardContext";
const useStyles = makeStyles(() => ({
  dollar: {
    objectFit: "cover",
  },
  div_ganancias: {
    marginRight: "5%",
    marginLeft: "5%",
  },
}));

const CardShippings = () => {
  const classes = useStyles();
  const { count_shipped, CountShipped, success } = useContext(DashboardContext);
  useEffect(() => {
    CountShipped();
  }, [success]);
  return (
    <Card
      style={{ backgroundColor: "#6a1b9a" }}
      sx={{
        boxShadow: 4,
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      <div>
        <LocalShippingIcon
          style={{
            fontSize: 60,
            marginLeft: 10,
            marginTop: 10,
            marginBottom: 10,
            color: "white",
          }}
        />
      </div>
      <div className={classes.div_ganancias}>
        <Typography
          component="div"
          variant="h5"
          sx={{
            fontWeight: "bold",
            fontSize: 20,
            color: "white",
            border: "1px",
            borderColor: "green",
          }}
        >
          Envios realizados
        </Typography>
        <Typography
          sx={{
            fontSize: 23,
            color: "white",
            border: "1px",
            borderColor: "green",
          }}
        >
          {count_shipped.orders}
        </Typography>
      </div>
    </Card>
  );
};
export default CardShippings;
