import React, { useEffect, useContext, useState } from "react";
import ProductsContext from "../../context/Products/ProductsContext";
import ShopProductCard from "../../components/Cards/products/ProductCard";
import NoDataComponents from "../../components/loading/NoDataComponents";
import { useDebounce } from "use-debounce";
import { makeStyles } from "@mui/styles";
import { Box, Grid, CardContent, Button } from "@mui/material";
import ModulesContext from "../../context/Permissions/PermissionsContext";
import Add from "./Add";
import { Paginate } from "../../components/Pagination/Paginate";
const useStyles = makeStyles({
  titleCard: {
    color: "white",
    fontSize: "35px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 0,
    display: "flex",
    justifyContent: "end",
  },
  pagination: {
    marginBottom: 25,
    marginRight: 25,
    fontFamily: "cursive",
  },
});

export default function ProductsDesactivated() {
  const classes = useStyles();
  const [modalAdd, abrirModalAdd] = useState(false);
  //funcion para agregar
  const abrirModalAgregar = () => {
    abrirModalAdd(true);
  };
  const cerrarModalAgregar = () => {
    abrirModalAdd(false);
  };

  const GetProductsList = useContext(ProductsContext);

  var [pages, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  //filtrado
  const [magicWord, setMagicWord] = useState(null);
  const [debouncedWord] = useDebounce(magicWord, 500);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const limite = rowsPerPage;
  const params = { limite, pages };
  const {
    products,
    GetProductsDesactivated,
    totalDocs,
    totalPages,
    searchProductDesactivated,
  } = GetProductsList;
  const { permissionsPerUser, GetModulesPerUser } = useContext(ModulesContext);
  useEffect(() => {
    if (sessionStorage.getItem("module_id")) {
      const permiss = sessionStorage.getItem("module_id");
      GetModulesPerUser(permiss);
    }
    GetProductsDesactivated(params);
  }, [rowsPerPage, pages]);

  useEffect(() => {
    if (magicWord != null) {
      searchProductDesactivated(magicWord);
    }
  }, [debouncedWord]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <div className={classes.fab}>
          <Button
            onClick={abrirModalAdd}
            sx={{
              // margin: 1,
              color: "black",
              backgroundColor: "#26c6da",
              hoverColor: "white",
              fontFamily: "monospace",
              fontWeight: "bolder",
              fontSize: "15px",
              textTransform: "none",
              "&:hover": {
                color: "black",
                fontSize: "15px",
                backgroundColor: "#67cedb",
                hoverColor: "white",
                fontFamily: "monospace",
                fontWeight: "bolder",
              },
            }}
          >
            Agregar
          </Button>
        </div>
        <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container spacing={3}>
                {!products.length ? (
                  <NoDataComponents />
                ) : (
                  products.map((product, index) => (
                    <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={3}>
                      <ShopProductCard
                        product={product}
                        permiss={permissionsPerUser}
                      />
                    </Grid>
                  ))
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {!products.length ? (
          <Box className={classes.pagination}></Box>
        ) : (
          <Box className={classes.pagination}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              display="flex"
              justifyContent="center"
            >
              <Paginate
                pages={pages}
                handleChangePage={handleChangePage}
                totalPages={totalPages}
              />
            </Grid>
          </Box>
        )}
        <CardContent></CardContent>
      </Grid>
      <Add
        modalAgregar={modalAdd}
        handleClickOpen={abrirModalAdd}
        handleClose={cerrarModalAgregar}
      />
    </Grid>
  );
}
