import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

const SelectMethodPayment = (props) => {
  const paymentMethods = [
    { value: "04", name: "Tarjeta de Crédito" },
    { value: "28", name: "Tarjeta de Débito" },
    { value: "03", name: "Transferencia Electronica de fondos" },
  ];
  const detectarCambiosPaymentMethods = (e) => {
    props.detectarCambiosPaymentMethods(e.target.value);
  };
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Método de pago</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        //value={age}
        label="Age"
        onChange={detectarCambiosPaymentMethods}>
        {paymentMethods.map((payment, index) => (
          <MenuItem key={index} value={payment.value}>
            {payment.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectMethodPayment;
