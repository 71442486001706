import React, { useReducer } from "react";
import OffersReducer from "./OffersReducer";
import OffersContext from "./OffersContext";
import MethodGet, {
  MethodDelete,
  MethodPost,
  MethodPut,
} from "../../config/Service";
import {
  ADD_OFFERS,
  CURRENT_OFFERS,
  DELETE_OFFERS,
  GET_ALL_OFFERS,
  SHOW_ERRORS_API,
  UPDATE_OFFERS,
} from "../../types";
import headerConfig from "../../config/imageHeaders";
import Swal from "sweetalert2";

const OffersState = ({ children }) => {
  //estado inicial
  const initialState = {
    offers: [],
    offer: null,
    ErrorsApi: [],
    success: false,
  };

  const [state, dispatch] = useReducer(OffersReducer, initialState);
  //obtener la lista
  const GetOffers = () => {
    sessionStorage.removeItem("offer");
    let url = `/offers`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_OFFERS,
          payload: res.data.offers,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
      });
  };

  //funcion para agregar oferta
  const AddOffers = (offer) => {
    const formData = new FormData();
    formData.append("text", offer.text);
    formData.append("imageWeb", offer.imageWeb);
    formData.append("imageMobile", offer.imageMobile);
    formData.append("type", offer.type);
    if (offer.type === 1) {
      formData.append("category", offer.category);
    } else if (offer.type === 2) {
      formData.append("subcategory", offer.subcategory);
    } else {
      formData.append("product", offer.product);
    }

    let url = `/offers/`;

    MethodPost(url, formData, { headerConfig })
      .then((res) => {
        Swal.fire({
          title: "Oferta Registrada",
          text: "La oferta se registro exitosamente!",
          icon: "success",
          timer: 1200,
          showConfirmButton: false,
        });
        dispatch({
          type: ADD_OFFERS,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.res.data.message,
        });
      });
  };

  //funcion para editar oferta
  const UpdateOffers = (offer) => {
    const formData = new FormData();
    formData.append("text", offer.text);
    formData.append("short_text", offer.short_text);
    formData.append("imageWeb", offer.imageWeb);
    formData.append("imageMobile", offer.imageMobile);
    if (offer.type === 1) {
      formData.append("category", offer.category);
    } else if (offer.type === 2) {
      formData.append("subcategory", offer.subcategory);
    } else {
      formData.append("product", offer.product);
    }
    let url = `/offers/${offer.id}`;
    MethodPut(url, formData, { headerConfig })
      .then((res) => {
        Swal.fire({
          title: "Oferta Actualizada",
          text: res.data.message,
          timer: 1200,
          showConfirmButton: false,
          icon: "success",
        });
        dispatch({
          type: UPDATE_OFFERS,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.res.data.message,
        });
      });
  };

  //eliminar oferta
  const DeleteOffers = (id) => {
    Swal.fire({
      title: "¿Estas Seguro?",
      text: "La oferta seleccionada sera eliminada",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/offers/${id}`;

        MethodDelete(url)
          .then((res) => {
            Swal.fire({
              title: "Eliminado",
              text: res.data.message,
              icon: "success",
              timer: 2000,
              showConfirmButton: false,
            });
            dispatch({
              type: DELETE_OFFERS,
              payload: id,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              timer: 2000,
              showConfirmButton: false,
            });
          });
      }
    });
  };

  //funcion para seleccionar una oferta
  const SaveCurrentOffer = (offer) => {
    sessionStorage.setItem("offer", JSON.stringify(offer));
    dispatch({
      type: CURRENT_OFFERS,
      payload: offer,
    });
  };

  return (
    <OffersContext.Provider
      value={{
        offers: state.offers,
        offer: state.offer,
        ErrorsApi: state.ErrorsApi,
        success: state.success,
        GetOffers,
        AddOffers,
        UpdateOffers,
        DeleteOffers,
        SaveCurrentOffer,
      }}
    >
      {children}
    </OffersContext.Provider>
  );
};

export default OffersState;
