import { Grid, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Layout from "../../components/layout/Layout";
import MethodGet from "../../config/Service";
import TablePayments from "./TablePayments";
import formatDate from "./../../utils/FormatDate";
import { PriceFormat } from "./../../utils/FormatPrice";
import SpinnerComponent from "../../components/loading/SpinnerComponent";

const DetailAccount = (props) => {
  const accountId = props.match.params.accountId;
  const providerId = props.match.params.providerId;
  const [accounts, saveAccounts] = useState(null);
  const [payments, savePayments] = useState();
  const [provider, saveProvider] = useState(null);
  const [cargando, spinnerCargando] = useState(false);
  useEffect(() => {
    let url = `/accounts-to-pay/${accountId}`;
    MethodGet(url)
      .then((res) => {
        saveAccounts(res.data.account);
        savePayments(res.data.account.payments);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
        spinnerCargando(true);
      });
  }, [accountId]);
  useEffect(() => {
    let url = `/providers/${providerId}`;
    MethodGet(url)
      .then((res) => {
        saveProvider(res.data.provider);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
        spinnerCargando(true);
      });
  }, [providerId]);

  return (
    <Layout>
      {!cargando ? (
        <SpinnerComponent />
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ backgroundColor: "black", color: "white" }}
            >
              <Typography
                fontWeight="bold"
                fontSize="25px"
                sx={{ marginLeft: 2 }}
              >
                Detalle de la cuenta
              </Typography>
            </Grid>
          </Grid>
          {provider && (
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ border: 1, margin: 4 }}
              >
                <Typography
                  fontWeight="bold"
                  fontFamily="monospace"
                  variant="h6"
                >
                  Proveedor: {provider.name}, Compania: {provider.company}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2} sx={{ padding: 2 }}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Typography fontWeight="bold" fontSize="17px">
                Folio Factura: {accounts.NoFolio}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Typography fontWeight="bold" fontSize="17px">
                Tipo Factura: {accounts.typeInvoice}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Typography fontWeight="bold" fontSize="17px">
                Fecha: {formatDate(accounts.createdAt)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Typography fontWeight="bold" fontSize="17px">
                Monto Factura: $ {PriceFormat(accounts.total)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Typography fontWeight="bold" fontSize="17px">
                Total a pagar: $ {PriceFormat(accounts.amount)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Typography fontWeight="bold" fontSize="17px">
                Total Pagado: $ {PriceFormat(accounts.totalPayment)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Typography fontWeight="bold" fontSize="17px">
                Restante por pagar: $
                {PriceFormat(accounts.total - accounts.totalPayment)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ padding: 2 }}>
            {payments && (
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <TablePayments payments={payments} accountId={accountId} />
              </Grid>
            )}
          </Grid>
        </>
      )}
    </Layout>
  );
};

export default DetailAccount;
