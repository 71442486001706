import React, { useContext, useState } from "react";
import DivisasContext from "../../../context/Divisas/DivisasContext";
import ModalImage from "react-modal-image-responsive";
import ModalEdit from "../../../containers/Administration/Divisas/ModalEdit";
import { makeStyles } from "@mui/styles";
import { Box, Card, Typography, Tooltip, Grid, Button } from "@mui/material";
import { EditIcon } from "../../icons/EditIcon";
import { DeleteIcon } from "../../icons/DeleteIcon";

const useStyles = makeStyles({
  Imagelogo: {
    height: "131px",
    objectFit: "cover",
    textAlign: "center",
  },
});

const DivisaCard = ({ divisa, permiss }) => {
  //Menu
  const classes = useStyles();
  const { DeleteDivisas } = useContext(DivisasContext);
  const [modalUpdate, AbrirModalUpdate] = useState(false);
  const [id_divisa, setIdDivisa] = useState();
  const handleClickOpenUpdate = () => {
    AbrirModalUpdate(true);
  };
  const handleClickCloseUpdate = () => {
    sessionStorage.removeItem("divisa");
    AbrirModalUpdate(false);
  };

  const selectDivisa = (divisa) => {
    setIdDivisa(divisa);
    handleClickOpenUpdate();
  };

  return (
    <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
      <Card sx={{ boxShadow: 3 }}>
        <Box
          fullWidth
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            paddingX: 2,
            backgroundColor: "white",
            color: "black",
          }}
        >
          <Typography
            variant="su arrowbtitle2"
            noWrap
            sx={{ fontWeight: "bold", fontSize: "17px" }}
            className="blink-1"
          >
            {divisa.currency}
          </Typography>
        </Box>
        <Box
          fullWidth
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <ModalImage
            className={classes.Imagelogo}
            small={divisa.image}
            large={divisa.image}
            alt={divisa.name}
          />
        </Box>
        <Grid container spacing={2}>
        {permiss.update === true && (
          <Grid item xs={6} display="flex" justifyContent="center">
            <Tooltip title="Editar" placement="top">
              <Button
                onClick={() => {
                  selectDivisa(divisa._id);
                }}
              >
                <EditIcon />
              </Button>
            </Tooltip>
          </Grid>
        )}
         {permiss.delete === true && (
          <Grid item xs={6} display="flex" justifyContent="center">
            <Tooltip title="Eliminar" placement="top">
              <Button
                onClick={() => {
                  DeleteDivisas(divisa._id);
                }}
              >
                <DeleteIcon />
              </Button>
            </Tooltip>
          </Grid>
         )}
        </Grid>
      </Card>
      {id_divisa && (
        <ModalEdit
          modalUpdate={modalUpdate}
          id={id_divisa}
          handleClickOpen={handleClickOpenUpdate}
          handleCloseEdit={handleClickCloseUpdate}
        />
      )}
    </Grid>
  );
};

export default DivisaCard;
