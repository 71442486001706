import React, { Fragment } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import AccountsContext from "../../context/Accounts/AccountsContext";
import Select from "react-select";
export default function SelectClient(props) {
  const { accounts_eccomerce, GetAccountsEcommerce } =
    useContext(AccountsContext);

  useEffect(() => {
    GetAccountsEcommerce();
  }, []);
  const selectStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };
  const detectarCambiosAccount = (value) => {
    props.detectarCambiosAccount(value);
  };

  const nuevoArreglo = accounts_eccomerce.map((account) => {
    const accountName = `${account.beneficiary} - ${account.account_number} - ${account.bank.name}`;
    return { value: account._id, label: accountName };
  });
  return (
    <Fragment>
      <Select
        fullwith
        styles={selectStyles}
        onChange={(value) => detectarCambiosAccount(value)}
        //className="basic-single"
        classNamePrefix="select"
        name="account"
        placeholder="Selecciona cuenta bancaria"
        options={nuevoArreglo}
        // options={accounts_eccomerce.map((account) => {
        //   let attribute = {
        //     label: account.beneficiary + "-" + account.number_account,
        //     number_account: account.number_account,
        //     typeAccount: account.typeAccount,
        //     value: account._id,
        //   };
        //   return attribute;
        // })}
      />
    </Fragment>
  );
}
