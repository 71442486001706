import { Button, Grid, Stack, TextField } from "@mui/material";
import React from "react";
///datepicker
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import esLocale from "date-fns/locale/es";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useContext } from "react";
import CouponsContext from "../../context/coupons/CouponsContext";
import { useForm } from "react-hook-form";
import ProductsContext from "../../context/Products/ProductsContext";
/**new pickers */
import { formatDateNow, formatDateSelect } from "../../utils/FormatDate";
import { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import SelectOrderStatus from "../../containers/SelectOptions/SelectOrderStatus";
const useStyles = makeStyles({
  card: {
    marginTop: 7,
    marginLeft: 7,
    marginRight: 7,
  },
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 10,
  },
});

export default function SelectDate() {
  /* Mi intento de Fecha chido */
  const classes = useStyles();
  const valueDesktopDatePicker = new Date();
  const [valueDesktopDatePickerSelected, setValueDesktopDatePickerSelected] =
    useState(new Date());
  const [pickerFecini, SetpickerIni] = useState(null);
  const [pickerFecter, SetpickerTer] = useState(null);
  /* Mi intento de Fecha chido */

  const { ExportOrdersExcel } = useContext(ProductsContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  //datepicker
  const [fecha_inicio, setFechaInicio] = useState("");
  const [fecha_fin, setFechaFin] = useState("");
  const MINDATENOW = formatDateNow(valueDesktopDatePickerSelected);
  //orderStatus
  const [orderStatus, saveOrderStatus] = useState(null);
  const detectarCambiosOrderStatus = (value) => {
    saveOrderStatus(value);
  };
  console.log(orderStatus, "el order status");
  const onSubmit = (data, e) => {
    data.start_date = formatDateSelect(fecha_inicio);
    data.end_date = formatDateSelect(fecha_fin);
    data.order_status = orderStatus.value;
    ExportOrdersExcel(data);
  };
  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.code === "Enter" || e.code === "NumpadEnter")
            e.preventDefault();
        }}
        autoComplete="off"
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <SelectOrderStatus
            detectarCambiosOrderStatus={detectarCambiosOrderStatus}
          />
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ marginTop: 2 }}>
            {" "}
            <LocalizationProvider dateAdapter={AdapterDayjs} locale={esLocale}>
              <Stack spacing={3}>
                <MobileDatePicker
                  label="Desde la fecha"
                  name="end_date"
                  todayText="Hoy"
                  showTodayButton={true}
                  disableFuture
                  okText="Aceptar"
                  cancelText="Cancelar"
                  value={pickerFecini}
                  onChange={(newValue) => {
                    SetpickerIni(newValue);
                    setFechaInicio(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={errors.start_date ? true : false}
                      helperText={errors?.start_date?.message}
                      {...register("start_date", {
                        required: {
                          value: true,
                          message: "Este campo es requerido",
                        },
                      })}
                    />
                  )}
                />
              </Stack>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ mt: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} locale={esLocale}>
              <Stack spacing={3}>
                <MobileDatePicker
                  label="Hasta la fecha"
                  name="end_date"
                  todayText="Hoy"
                  showTodayButton={true}
                  disableFuture
                  okText="Aceptar"
                  cancelText="Cancelar"
                  value={pickerFecter}
                  onChange={(newValue) => {
                    SetpickerTer(newValue);
                    setFechaFin(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={errors.end_date ? true : false}
                      helperText={errors?.end_date?.message}
                      {...register("end_date", {
                        required: {
                          value: true,
                          message: "Este campo es requerido",
                        },
                      })}
                    />
                  )}
                />
              </Stack>
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 2 }}>
          <Button type="submit" variant="outlined" className="button_primary">
            Generar Reporte
          </Button>
        </Grid>
      </form>
    </>
  );
}
