import { InputAdornment, TextField } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import CropFreeIcon from "@mui/icons-material/CropFree";
import { useDebounce } from "use-debounce";
import ProductsContext from "../../context/Products/ProductsContext";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
export default function ComponentsBarcode({
  guardarProductoID,
  guardarSalida,
  salida,
}) {
  const {
    register,
    formState: { errors },
  } = useForm();
  const [barcode, saveBarcode] = useState("");
  const [debouncedBarcode] = useDebounce(barcode, 500);
  const { products, productsSelect, success } = useContext(ProductsContext);
  useEffect(() => {
    productsSelect();
  }, [success]);
  //cambios del codigo de barras
  const handleChangeBarcode = (e) => {
    saveBarcode(e.target.value);
  };
  useEffect(() => {
    filterProductExist();
  }, [debouncedBarcode]);

  const filterProductExist = () => {
    if (debouncedBarcode !== "") {
      var product = [];
      product = products.filter((p) => p.barcode === debouncedBarcode);

      if (!product.length) {
        Swal.mixin({
          toast: true,
          background: "red",
          color: "white",
          iconColor: "white",
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        }).fire({
          icon: "error",
          title: `¡Lo sentimos!`,
          text: "El producto no esta registrado",
        });
        saveBarcode("");
        return;
      }
      product[0].value = product[0]._id;
      guardarProductoID(product[0]);
      //obtener la posicion del producto
      const position = salida
        .map(function (e) {
          return e.product_id;
        })
        .indexOf(product[0]._id);

      let productoResultado = {};
      productoResultado.barcode = product[0].barcode;
      productoResultado.image = product[0].multimedia[0].path;
      productoResultado.name = product[0].name;
      productoResultado.brand = product[0].brand.name;
      productoResultado.quantity = 1;
      productoResultado.product_id = product[0]._id;
      productoResultado.price = product[0].price;
      productoResultado.stock_product = product[0].quantity;
      if (productoResultado.stock_product === 0) {
        Swal.mixin({
          toast: true,
          background: "red",
          color: "white",
          iconColor: "white",
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        }).fire({
          icon: "error",
          title: `¡Lo sentimos!`,
          text: "El producto no cuenta con stock",
        });
        saveBarcode("");
        return;
      }
      if (position === -1) {
        productoResultado.product_id = product[0]._id;
        productoResultado.quantity = 1;
        localStorage.setItem(
          "ExitProducts",
          JSON.stringify([productoResultado, ...salida])
        );
        guardarSalida([productoResultado, ...salida]);
        saveBarcode("");
        Swal.mixin({
          toast: true,
          background: "#388e3c",
          color: "white",
          iconColor: "white",
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        }).fire({
          icon: "success",
          title: `El producto se agregó correctamente `,
        });
      } else {
        let quantityProduct = salida[position].quantity;
        var new_quantity_product = (quantityProduct += 1);
        if (product[0].quantity < new_quantity_product) {
          Swal.mixin({
            toast: true,
            background: "red",
            color: "white",
            iconColor: "white",
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          }).fire({
            icon: "error",
            title: `¡Lo sentimos!`,
            text: "No queda más productos en stock",
          });
          saveBarcode("");
          return;
        }
        salida[position].quantity = new_quantity_product;
        guardarSalida([...salida]);
        saveBarcode("");
        Swal.mixin({
          toast: true,
          background: "#388e3c",
          color: "white",
          iconColor: "white",
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        }).fire({
          icon: "success",
          title: `El producto se agregó correctamente `,
        });
      }
    }
  };

  return (
    <TextField
      placeholder="Usa el lector de codigos de barra"
      variant="outlined"
      fullWidth
      value={barcode}
      id="barcode"
      label="Código De Barras"
      name="barcode"
      autoComplete="barcode"
      autoFocus
      error={errors.barcode ? true : false}
      helperText={errors?.barcode?.message}
      {...register("barcode", {
        required: {
          value: true,
          message: "Este campo es requerido",
        },
        minLength: {
          value: 2,
          message: "Minimo 2 caracteres",
        },
        maxLength: {
          value: 255,
          message: "Maximo 255 caracteres",
        },
        onChange: (e) => {
          handleChangeBarcode(e);
        },
      })}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <CropFreeIcon />
          </InputAdornment>
        ),
      }}
    />
  );
}
