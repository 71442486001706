import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

import AuthState from "./context/auth/authState";
import AppRouter from "./routes/AppRouter";
import ResetPasswordState from "./context/resetPassword/ResetPasswordState";
import TypeUsersState from "./context/TypeUsers/TypeUsersState";
import UsersState from "./context/Users/UsersState";
import CategoriesState from "./context/CategoriesP/CategoriesState";
import SubcategoriesState from "./context/SubCategories/SubcategoriesState";
import BrandsState from "./context/Brands/BrandsState";
import FaqCategoriesState from "./context/FaqCategories/FaqCategoriesState";
import FaqsState from "./context/Faqs/FaqsState";
import TagsState from "./context/Tags/TagsState";
import SliderEcommerceState from "./context/SliderEccomerce/SliderEcommerceState";
import LogoState from "./context/Logo/LogoState";
import CouponsState from "./context/coupons/CouponsState";
import TypeProductsState from "./context/TypeProducts/TypeProductsState";
import BusinessRulesState from "./context/BusinessRules/BusinessRulesState";
import ProductsState from "./context/Products/ProductsState";
import OffersState from "./context/Offers/OffersState";
import InfluencersState from "./context/influencers/InfluencersState";
import BanksState from "./context/Banks/BanksState";
import AccountsState from "./context/Accounts/AccountsState";
import AboutState from "./context/about/AboutState";
import CouponNewUserState from "./context/CouponNewUser/CouponNewUserState";
import ShippmentsState from "./context/Shippments/ShippmentsState";
import WarehouseState from "./context/Warehouse/WarehouseState";
import PermissionsState from "./context/Permissions/PermissionsState";
import SalesState from "./context/Sales/SalesState";
import ClientsState from "./context/Clients/ClientsState";
import InvoicesState from "./context/Invoices/InvoicesState";
import GraphsState from "./context/Graphs/GraphsState";
import DashboardState from "./context/Dashboard/DashboardState";
import WarehouseCanvasState from "./context/WarehouseCanvas/WarehouseCanvasState";
import MeasuresState from "./context/MEASURES/MeasuresState";
import PermissionCountriesState from "./context/PermissionsCountries/PermissionCountriesState";
import CanvasVariantsState from "./context/canvasVariants/CanvasVariantsState";
import DivisasState from "./context/Divisas/DivisasState";
import ShippmentInternationalState from "./context/ShippmentInternational/ShippmentInternationalState";
import InventoriesState from "./context/Inventories/InventoriesState";
import SettingsState from "./context/settings/SettingsState";
import ExitProductsState from "./context/ExitProducts/ExitProductsState";
import TypeCategoriesState from "./context/typecategories/TypeCategoriesState";
import TypeClientsState from "./context/TypeClients/TypeClientsState";
import SalesFisicState from "./context/SalesFisic/SalesFisicState";
import PoliciesState from "./context/Policies/PoliciesState";
import StatesState from "./context/States/StatesState";
import ProviderState from "./context/Provider/ProviderState";
import AccountsForPaymentState from "./context/AccountsForPayment/AccountForPaymentState";
import AccountsReceivableState from "./context/AccountsReceivable/AccountsReceivableState";
import ExpensesState from "./context/Expenses/ExpensesState";
import ReportUtilityState from "./context/reportUtility/ReportUtilityState";
import ContactState from "./context/Contact/ContactState";

export default function EcommerceApp() {
  return (
    <AuthState>
      <GoogleReCaptchaProvider reCaptchaKey="6LfpM9wfAAAAAKsbZYIDz10Xbd05l-5_TgD3H_dK">
        <ResetPasswordState>
          <TypeUsersState>
            <UsersState>
              <SettingsState>
                <CategoriesState>
                  <TypeCategoriesState>
                    <SubcategoriesState>
                      <BrandsState>
                        <FaqCategoriesState>
                          <FaqsState>
                            <TagsState>
                              <SliderEcommerceState>
                                <LogoState>
                                  <CouponsState>
                                    <TypeProductsState>
                                      <BusinessRulesState>
                                        <ProductsState>
                                          <OffersState>
                                            <InfluencersState>
                                              <BanksState>
                                                <AccountsState>
                                                  <AboutState>
                                                    <CouponNewUserState>
                                                      <ShippmentsState>
                                                        <WarehouseState>
                                                          <PermissionsState>
                                                            <SalesState>
                                                              <ClientsState>
                                                                <InvoicesState>
                                                                  <GraphsState>
                                                                    <DashboardState>
                                                                      <WarehouseCanvasState>
                                                                        <MeasuresState>
                                                                          <PermissionCountriesState>
                                                                            <CanvasVariantsState>
                                                                              <DivisasState>
                                                                                <InventoriesState>
                                                                                  <ShippmentInternationalState>
                                                                                    <ExitProductsState>
                                                                                      <TypeClientsState>
                                                                                        <SalesFisicState>
                                                                                          <PoliciesState>
                                                                                            <StatesState>
                                                                                              <ProviderState>
                                                                                                <AccountsForPaymentState>
                                                                                                  <AccountsReceivableState>
                                                                                                    <ExpensesState>
                                                                                                      <ReportUtilityState>
                                                                                                        <ContactState>
                                                                                                          <AppRouter />
                                                                                                        </ContactState>
                                                                                                      </ReportUtilityState>
                                                                                                    </ExpensesState>
                                                                                                  </AccountsReceivableState>
                                                                                                </AccountsForPaymentState>
                                                                                              </ProviderState>
                                                                                            </StatesState>
                                                                                          </PoliciesState>
                                                                                        </SalesFisicState>
                                                                                      </TypeClientsState>
                                                                                    </ExitProductsState>
                                                                                  </ShippmentInternationalState>
                                                                                </InventoriesState>
                                                                              </DivisasState>
                                                                            </CanvasVariantsState>
                                                                          </PermissionCountriesState>
                                                                        </MeasuresState>
                                                                      </WarehouseCanvasState>
                                                                    </DashboardState>
                                                                  </GraphsState>
                                                                </InvoicesState>
                                                              </ClientsState>
                                                            </SalesState>
                                                          </PermissionsState>
                                                        </WarehouseState>
                                                      </ShippmentsState>
                                                    </CouponNewUserState>
                                                  </AboutState>
                                                </AccountsState>
                                              </BanksState>
                                            </InfluencersState>
                                          </OffersState>
                                        </ProductsState>
                                      </BusinessRulesState>
                                    </TypeProductsState>
                                  </CouponsState>
                                </LogoState>
                              </SliderEcommerceState>
                            </TagsState>
                          </FaqsState>
                        </FaqCategoriesState>
                      </BrandsState>
                    </SubcategoriesState>
                  </TypeCategoriesState>
                </CategoriesState>
              </SettingsState>
            </UsersState>
          </TypeUsersState>
        </ResetPasswordState>
      </GoogleReCaptchaProvider>
    </AuthState>
  );
}
