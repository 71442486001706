import React, { useContext, useEffect } from "react";
import TypeUsersContext from "../../context/TypeUsers/TypeUsersContext";

import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

export default function SelectTypeUsers(props) {
  const { type_users, GetTypeUsers } = useContext(TypeUsersContext);

  useEffect(() => {
    GetTypeUsers();
  }, []);

  const detectarCambiosTypeUser = (e) => {
    if (e.target.value) {
    }
    props.detectarCambiosTypeUser(e);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Tipo de usuario</InputLabel>
          {props.typeUserId ? (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Tipo de regla de negocio "
              onChange={detectarCambiosTypeUser}
              value={props.typeUserId}
            >
              {type_users.map((type_user) => (
                <MenuItem key={type_user._id} value={type_user._id}>
                  {type_user.name}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Tipo de regla de negocio "
              onChange={detectarCambiosTypeUser}
            >
              {type_users.map((type_user) => (
                <MenuItem key={type_user._id} value={type_user._id}>
                  {type_user.name}
                </MenuItem>
              ))}
            </Select>
          )}
          <FormHelperText>Compo Requerido*</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}
