import React from "react";
import {
	ADD_CANVAS_VARIANT,
	DELETE_CANVAS_VARIANT,
	GET_ALL_CANVAS_VARIANTS,
	SHOW_ERRORS_API,
	UPDATE_CANVAS_VARIANT,
} from "../../types";

export default function CanvasVariantsReducer(state, action) {
	switch (action.type) {
		case GET_ALL_CANVAS_VARIANTS:
			return {
				...state,
				canvasVariants: action.payload,
				success: false,
				ErrorsAPI: [],
			};
		case ADD_CANVAS_VARIANT:
			return {
				...state,
				success: true,
				ErrorsAPI: [],
			};
		case SHOW_ERRORS_API:
			return {
				...state,
				success: true,
				ErrorsAPI: [],
			};
		case UPDATE_CANVAS_VARIANT:
			return {
				...state,
				success: true,
				ErrorsAPI: [],
			};
		case DELETE_CANVAS_VARIANT:
			return {
				...state,
				success: true,
				canvasVariants: state.canvasVariants.filter(
					(cv) => cv.id !== action.payload
				),
			};
		default:
			return state;
	}
}
