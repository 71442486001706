import React, { useEffect, useState } from "react";
import ProductsContext from "../context/Products/ProductsContext";
import { useContext } from "react";
import MethodGet from "../config/Service";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
const DiffProducts = () => {
  const { products, GetAllProductsSelect } = useContext(ProductsContext);
  const [inventory, saveInventory] = useState();
  const [productsInventory, saveProductsInventory] = useState([]);
  const [cargando, spinnerCargando] = useState(false);
  const productsSelect = products;
  useEffect(() => {
    GetAllProductsSelect();
  }, []);

  const id = "6472acd906fdbb18dfa7642e";
  useEffect(() => {
    let url = `/inventory/${id}`;
    MethodGet(url)
      .then((res) => {
        saveInventory(res.data.inventory);
        saveProductsInventory(res.data.inventory.products);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  const productsInventoried = productsInventory.map((p) => {
    let attribute = {
      id: p.product_id._id,
    };
    return attribute;
  });

  let diff = [];
  productsSelect.map((product) => {
    let test = productsInventoried.find((pro) => pro.id == product._id);
    if (test) {
    } else {
      diff.push(product);
    }
  });
  // let setNewProducts = [];
  // products.map((product) => {
  //   let test = productsAddes.find((pro) => pro._id == product._id);
  //   if (test) {
  //   } else {
  //     setNewProducts.push(product);
  //   }
  // });

  // console.log(setNewProducts, "la diferencia");

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <b>barcode</b>
              </TableCell>
              <TableCell align="center">
                <b>producto</b>
              </TableCell>
              <TableCell align="center">
                <b>cantidad</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {diff.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="center">{row.barcode}</TableCell>
                <TableCell align="center">{row.name}</TableCell>
                <TableCell align="center">{row.quantity}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default DiffProducts;
