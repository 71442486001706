import React, {

  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "react-toastify/dist/ReactToastify.css";
import CategoriesContext from "../../context/CategoriesP/CategoriesContext";
import { ToastContainer, toast } from "react-toastify";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  Tooltip,
  Box,
} from "@mui/material";
import { useForm } from "react-hook-form";

import { makeStyles } from "@mui/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CircularProgress from "@material-ui/core/CircularProgress";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles({
  input: {
    display: "none",
  },

  buttonProgress: {
    color: "indigo",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  container: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 10,
    //marginBottom: theme.spacing(10),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: 9,
    paddingLeft: 2,
    paddingRight: 2,
    marginBlockEnd: 1,
  },
  submit: {
    background: "indigo",
    "&:hover": {
      background: "#5902CF",
    },
    color: "white",
  },
  formControl: {
    margin: 1,
    width: "100%",
  },
  selectEmpty: {
    marginTop: 2,
  },
  containerImageProfile: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  PaperFormAdd: {
    background: "white",
    color: "black",
    boxShadow: "2px 4px 4px #cacaca",
  },
});
export default function ModalImportCategories({
  modalImport,
  handleClickClose,
}) {
  const classes = useStyles();
  const { handleSubmit } = useForm({});
  const [archivo, guardarArchivo] = useState("");
  const [loading, setLoading] = useState(false);
  const timer = useRef();
  const leerArchivo = (e) => {
    guardarArchivo(e.target.files[0]);
  };

  const { success, ImportCategoriesExcel } = useContext(CategoriesContext);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  useEffect(() => {
    if (success) {
      handleClickClose();
      setLoading(false);
    }
  }, [success]);

  const onSubmit = (data, e) => {
    e.preventDefault();
    setLoading(true);
    data.file = archivo;

    if (archivo !== "") {
      //enviamos la data a la funcion que se encarga de guardar los nuevos registros
      ImportCategoriesExcel(data);
      handleClickClose();
    } else {
      setLoading(false);
      toast.error("Introduce un archivo de excel válido", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      handleClickClose();
    }
  };

  return (
    <Dialog
      open={modalImport}
      onClose={handleClickClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"Importar categorias desde excel"}
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <InputLabel sx={{ textAlign: "center" }}>
            Selecciona el archivo a importar
          </InputLabel>
          <input
            className={classes.input}
            id="icon-button-file"
            type="file"
            name="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={leerArchivo}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <label htmlFor="icon-button-file">
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                style={{ align: "center" }}
              >
                <Tooltip
                  style={{ fontSize: "70px" }}
                  title="seleccionar archivo excel a importar"
                  aria-label="seleccionar imagen"
                >
                  <CloudUploadIcon />
                </Tooltip>
              </IconButton>
            </label>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between",
          }}
        >
          <Button
            autoFocus
            onClick={handleClickClose}
            endIcon={<CloseIcon />}
            sx={{
              backgroundColor: "#b71c1c",
              color: "white",
              textTransform: "none",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#CF2121",
              },
            }}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            color="primary"
            disabled={loading}
            endIcon={<SaveIcon />}
            sx={{
              backgroundColor: "#1b5e20",
              color: "white",
              textTransform: "none",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#22992B",
              },
            }}
          >
            {loading ? "Importando" : "Guardar"}
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
        </DialogActions>
      </form>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Dialog>
  );
}
