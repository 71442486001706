import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";

import ClientsContext from "../../context/Clients/ClientsContext";
const SelectClientsAccount = (props) => {
  const { clientsAccounts, GetClientAccount } = useContext(ClientsContext);
  useEffect(() => {
    GetClientAccount();
  }, []);
  const detectarCambiosClientAccount = (e) => {
    if (e.target.value) {
    }
    props.detectarCambiosClientAccount(e);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Selecciona cliente
            </InputLabel>
            {props.client_id ? (
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Tipo de cliente "
                onChange={detectarCambiosClientAccount}
                value={props.client_id}>
                {clientsAccounts.map((client, index) => (
                  <MenuItem key={index} value={client.id}>
                    {client.fullname}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="tipo de cliente"
                onChange={detectarCambiosClientAccount}>
                {clientsAccounts.map((client, index) => (
                  <MenuItem key={index} value={client.id}>
                    {client.fullname}
                  </MenuItem>
                ))}
              </Select>
            )}
            <FormHelperText>Compo Requerido*</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default SelectClientsAccount;
