import React, { useContext, useState } from "react";
import EditCountry from "../../../containers/PermissionCountries/Edit";
import UpdateFile from "../../../containers/PermissionCountries/UpdateFiles";

import { makeStyles } from "@mui/styles";
import {
  Box,
  Card,
  Typography,
  Tooltip,
  Grid,
  Menu,
  MenuItem,
  IconButton,
  Button,
} from "@mui/material";
import ModalImage from "react-modal-image-responsive";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PermissionCountriesContext from "../../../context/PermissionsCountries/PermissionCountriesContext";
import { Link } from "react-router-dom";
import { EditIcon } from "../../icons/EditIcon";

import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";

const useStyles = makeStyles({
  Imagelogo: {
    height: "150px",
    width: "400px",
    objectFit: "cover",
    textAlign: "center",
  },
});

const CountryCard = ({ country, permiss }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();
  const { DeleteCountry, saveCurrentCountry } = useContext(
    PermissionCountriesContext
  );
  const [modalUpdate, AbrirModalUpdate] = useState(false);
  const [id_country, setIdCountry] = useState();
  const handleClickOpenUpdate = () => {
    AbrirModalUpdate(true);
  };
  const handleClickCloseUpdate = () => {
    //sessionStorage.removeItem("brand");
    AbrirModalUpdate(false);
  };

  const selectCountry = (country) => {
    setIdCountry(country);
    handleClickOpenUpdate();
  };

  const selectCountryFiles = (country) => {
    saveCurrentCountry(country);
    //handleClickOpenUpdateFiles();
  };

  return (
    <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
      <Card sx={{ boxShadow: 3 }}>
        <Box
          fullWidth
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            paddingX: 2,
            backgroundColor: "white",
            color: "black",
          }}>
          <Typography
            variant="su arrowbtitle2"
            noWrap
            sx={{ fontWeight: "bold", fontSize: "17px" }}
            className="blink-1">
            {country.country}
          </Typography>
        </Box>
        <Box
          fullWidth
          display="flex"
          alignItems="center"
          justifyContent="center">
          <ModalImage
            className={classes.Imagelogo}
            small={country.image}
            large={country.image}
            alt={country.image}
          />
        </Box>
        <Grid container spacing={2}>
          {permiss.update === true && (
            <Grid item xs={6} display="flex" justifyContent="center">
              <Tooltip title="Editar" placement="top">
                <Button
                  onClick={() => {
                    selectCountry(country._id);
                  }}>
                  <CreateIcon className="button_primary" />
                </Button>
              </Tooltip>
            </Grid>
          )}
          {permiss.delete === true && (
            <Grid item xs={6} display="flex" justifyContent="center">
              <Tooltip title="Eliminar" placement="top">
                <Button
                  onClick={() => {
                    DeleteCountry(country._id);
                  }}>
                  <DeleteIcon className="button_danger" />
                </Button>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Card>
      {id_country && (
        <EditCountry
          modalUpdate={modalUpdate}
          id={id_country}
          handleClickOpen={handleClickOpenUpdate}
          handleCloseEdit={handleClickCloseUpdate}
        />
      )}
    </Grid>
  );
};

export default CountryCard;
