import React, { useContext, useState } from "react";
import ShippmentsContext from "../../../context/Shippments/ShippmentsContext";
import ModalImage from "react-modal-image-responsive";
import ModalUpdateShippment from "../../../containers/Shippments/EditModal";

import { makeStyles } from "@mui/styles";
import {
  Box,
  Card,
  Typography,
  Tooltip,
  Grid,
  Menu,
  MenuItem,
  IconButton,
  Button,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { EditIcon } from "../../icons/EditIcon";
import { DeleteIcon } from "../../icons/DeleteIcon";

const useStyles = makeStyles({
  Imagelogo: {
    height: "131px",
    objectFit: "cover",
    textAlign: "center",
  },
});

const ShippmentsCard = ({ shippment, permiss }) => {
  //Menu
  const classes = useStyles();
  const { DeleteShippment } = useContext(ShippmentsContext);
  const [modalUpdate, AbrirModalUpdate] = useState(false);
  const [id_Shippment, setIdShippment] = useState();
  const handleClickOpenUpdate = () => {
    AbrirModalUpdate(true);
  };
  const handleClickCloseUpdate = () => {
    sessionStorage.removeItem("shippment");
    AbrirModalUpdate(false);
  };

  const selectShippment = (shippment) => {
    setIdShippment(shippment);
    handleClickOpenUpdate();
  };
  return (
    <Card sx={{ boxShadow: 3 }}>
      <Box
        fullWidth
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          paddingX: 2,
          backgroundColor: "white",
          color: "black",
        }}
      >
        <Typography
          variant="su arrowbtitle2"
          noWrap
          sx={{ fontWeight: "bold", fontSize: "17px" }}
          className="blink-1"
        >
          {shippment.name}
        </Typography>
      </Box>
      <Box fullWidth display="flex" alignItems="center" justifyContent="center">
        <ModalImage
          className={classes.Imagelogo}
          small={shippment.image}
          large={shippment.image}
          alt={shippment.name}
        />
      </Box>
      <Grid container spacing={2}>
      {permiss.update === true && (
        <Grid item xs={6} display="flex" justifyContent="center">
          <Tooltip title="Editar" placement="top">
            <Button
              onClick={() => {
                selectShippment(shippment._id);
              }}
            >
              <EditIcon />
            </Button>
          </Tooltip>
        </Grid>
      )}
       {permiss.delete === true && (
        <Grid item xs={6} display="flex" justifyContent="center">
          <Tooltip title="Eliminar" placement="top">
            <Button
              onClick={() => {
                DeleteShippment(shippment._id);
              }}
            >
              <DeleteIcon />
            </Button>
          </Tooltip>
        </Grid>
       )}
      </Grid>
      {id_Shippment && (
        <ModalUpdateShippment
          modalUpdate={modalUpdate}
          id={id_Shippment}
          handleClickOpen={handleClickOpenUpdate}
          handleCloseEdit={handleClickCloseUpdate}
        />
      )}
    </Card>
  );
};

export default ShippmentsCard;
