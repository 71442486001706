import React, { useContext, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import InfluencersContext from "../../context/influencers/InfluencersContext";
import CouponNewUserContext from "../../context/CouponNewUser/CouponNewUserContext";
import { makeStyles } from "@mui/styles";
import { Grid, Chip, Box, Divider, Typography, Button } from "@mui/material";
import CardUserLogged from "../../components/Dashboard/UserLoged";
import CardSalesOfDay from "../../components/Dashboard/SectionSales/CardSalesOfDay";
import CardSalesOfMonth from "../../components/Dashboard/SectionSales/CardSalesOfMonth";
import CardSalesOfLastWeek from "../../components/Dashboard/SectionSales/CardSalesOfLastWeek";
import CardInputForDay from "../../components/Dashboard/SectionInputs/CardInputForDay";
import CardInputForWeek from "../../components/Dashboard/SectionInputs/CardInputForWeek";
import CardInputForMonth from "../../components/Dashboard/SectionInputs/CardInputForMonth";
import CardNewUserCupon from "../../components/Dashboard/SeccionNewUserCupon/CardNewUserCupon";
import CardShippings from "../../components/Dashboard/SectionSales/CardShippings";
import GraphStock from "../../components/Dashboard/Graphs/GraphStock";
import GraphProductsMostSold from "../../components/Dashboard/Graphs/GraphProductsMostSold";
import AuthContext from "../../context/auth/authContext";
import TableUserDistribuidor from "../../components/Dashboard/UserDistribuidor/TableUserDistribuidor";
import TableBussinessRules from "../../components/Dashboard/TableBussinessRules";
import ModalCotizations from "../../components/Dashboard/UserDistribuidor/ModalCotizations";
import SelectDate from "../../components/Dashboard/SelectDate";
import ProductsContext from "../../context/Products/ProductsContext";
import CardShippmentInternational from "../../components/Dashboard/SectionShippmentInternational/CardShippmentInternational";
import ProductsMostSold from "../../components/Dashboard/ProductsMostSold";
import SpinnerComponent from "../../components/loading/SpinnerComponent";
const useStyles = makeStyles(() => ({
  titleCard: {
    color: "white",
    fontSize: "26px",
    fontFamily: "roboto",
    fontWeight: "bolder",
  },
  subtitleCard: {
    color: "white",
    fontSize: "20px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },

  div_btnnew_user: {
    marginLeft: 5,
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  //modal
  const [modal, abrirModal] = useState(false);
  const handleClickOpenModal = () => {
    abrirModal(true);
  };
  const handleClickCloseModal = () => {
    abrirModal(false);
  };
  const { usuario, cerrarSesion } = useContext(AuthContext);
  const [modalEditar, AbrirModalAdd] = useState(false);

  const {
    influencersindex,
    GetInfluencersPendingIndex,
    success: succesPending,
  } = useContext(InfluencersContext);

  const {
    couponsnu,
    GetCouponNu,
    success: successcuponnewuser,
    saveCurrentCouponNewUser,
    UpdateCouponNu,
  } = useContext(CouponNewUserContext);

  const { ExportProductsMostSold } = useContext(ProductsContext);

  const handleClickOpenAdd = () => {
    AbrirModalAdd(true);
  };
  const handleClickCloseAdd = () => {
    AbrirModalAdd(false);
  };
  const selectCoupon = (couponnu) => {
    saveCurrentCouponNewUser(couponnu);
  };

  useEffect(() => {
    GetInfluencersPendingIndex();
  }, [succesPending]);

  useEffect(() => {
    GetCouponNu();
  }, [successcuponnewuser]);

  return (
    <>
      {usuario ? (
        <>
          {usuario && usuario.user.type_user_id.name !== "Distribuidor" ? (
            <Layout>
              <Grid container spacing={2} sx={{ backgroundColor: "white" }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      paddingTop: 4,
                      p: 3,
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {/* <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <CardUserLogged />
                          </Grid>
                        </Grid> */}
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Divider sx={{ marginBottom: 1, marginTop: 3 }}>
                              <Chip
                                label={
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    Ventas
                                  </Typography>
                                }
                              />
                            </Divider>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                            <CardSalesOfDay />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                            <CardSalesOfLastWeek />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                            <CardSalesOfMonth />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                            <CardShippings />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                            <CardInputForDay />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                            <CardInputForWeek />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                            <CardInputForMonth />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                            <CardNewUserCupon />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                            <CardShippmentInternational />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          paddingTop: 4,
                          p: 3,
                          marginBottom: 2,
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Divider sx={{ marginBottom: 1 }}>
                              <Chip
                                label={
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    Gráficas
                                  </Typography>
                                }
                              />
                            </Divider>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <GraphStock />
                              </Grid>
                              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <GraphProductsMostSold />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          paddingTop: 4,
                          p: 3,
                          marginBottom: 10,
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Divider sx={{ marginBottom: 1 }}>
                              <Chip
                                label={
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    Reportes
                                  </Typography>
                                }
                              />
                            </Divider>
                            <Grid container spacing={2}>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                xl={6}
                                sx={{ mt: 2 }}
                              >
                                <Typography
                                  textAlign="center"
                                  variant="h4"
                                  fontFamily="monospace"
                                  sx={{ marginBottom: 2 }}
                                >
                                  Productos más vendidos
                                </Typography>
                                <ProductsMostSold />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                xl={6}
                                sx={{ mt: 2 }}
                              >
                                <Typography
                                  textAlign="center"
                                  variant="h4"
                                  fontFamily="monospace"
                                  sx={{ marginBottom: 2 }}
                                >
                                  Reporte de ventas
                                </Typography>
                                <SelectDate />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Layout>
          ) : (
            <>
              <Grid container spacing={2} sx={{ padding: 1 }}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  display="flex"
                  justifyContent="end"
                >
                  <Button
                    onClick={cerrarSesion}
                    sx={{
                      backgroundColor: "#0d47a1",
                      color: "white",
                      "&:hover": { backgroundColor: "#0d47a1", color: "white" },
                    }}
                  >
                    Cerrar Sesión
                  </Button>
                </Grid>
                {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <CardUserLogged />
                    </Grid>
                  </Grid>
                </Grid> */}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "right",
                      justifyContent: "right",
                    }}
                  >
                    <Button
                      sx={{ background: "#0d47a1", color: "white" }}
                      variant="contained"
                      onClick={handleClickOpenModal}
                    >
                      Ver mis cotizaciones realizadas
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TableUserDistribuidor />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TableBussinessRules />
                </Grid>
              </Grid>
              <ModalCotizations
                modal={modal}
                handleClickOpen={handleClickOpenModal}
                handleClose={handleClickCloseModal}
              />
            </>
          )}
        </>
      ) : (
        <>
          <SpinnerComponent />
        </>
      )}
    </>
  );
}
