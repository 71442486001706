import React, { useReducer } from "react";
import TypeClientsReducer from "./TypeClientsReducer";
import TypeClientsContext from "./TypeClientsContext";
import MethodGet, {
  MethodDelete,
  MethodPost,
  MethodPut,
} from "../../config/Service";
import {
  ADD_TYPE_CLIENTS,
  DELETE_TYPE_CLIENTS,
  GET_TYPE_CLIENTS,
  SHOW_ERRORS_API,
  UPDATE_TYPE_CLIENT,
} from "../../types";
import Swal from "sweetalert2";
const TypeClientsState = ({ children }) => {
  //estado inicial
  const initialState = {
    typeClients: [],
    typeClient: null,
    ErrorsApi: [],
    success: false,
  };
  const [state, dispatch] = useReducer(TypeClientsReducer, initialState);

  //index
  const GetTypeClients = () => {
    sessionStorage.removeItem("typeClient");
    let url = "/customer-type";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_TYPE_CLIENTS,
          payload: res.data.customerTypes,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: error.response.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  //add
  const AddTypeClients = (data) => {
    const formData = new FormData();
    formData.append("name", data.name);
    let url = "/customer-type";
    MethodPost(url, formData)
      .then((res) => {
        Swal.fire({
          title: "Registrado",
          text: res.data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: ADD_TYPE_CLIENTS,
          payload: res.data.customerType,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        dispatch({
          type: SHOW_ERRORS_API,
        });
      });
  };

  //UPDATE
  const UpdateTypeClient = (data) => {
    const formData = new FormData();
    formData.append("name", data.name);

    let url = `/customer-type/${data.id}`;
    MethodPut(url, formData)
      .then((res) => {
        Swal.fire({
          title: "Actualizado",
          text: res.data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: UPDATE_TYPE_CLIENT,
          payload: res.data.customerType,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: SHOW_ERRORS_API,
        });
      });
  };

  //eliminar tag
  const DeleteTypeClient = (id) => {
    Swal.fire({
      title: "¿Estas Seguro?",
      text: "El tipo de cliente seleccionada sera eliminado",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/customer-type/${id}`;
        MethodPost(url)
          .then((res) => {
            Swal.fire({
              title: "Eliminado",
              text: res.data.message,
              icon: "success",
              timer: 2000,
              showConfirmButton: false,
            });
            dispatch({
              type: DELETE_TYPE_CLIENTS,
              payload: id,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              timer: 2000,
              showConfirmButton: false,
            });
          });
      }
    });
  };
  return (
    <TypeClientsContext.Provider
      value={{
        typeClients: state.typeClients,
        typeClient: state.typeClient,
        ErrorsApi: state.ErrorsApi,
        GetTypeClients,
        AddTypeClients,
        UpdateTypeClient,
        DeleteTypeClient,
      }}
    >
      {children}
    </TypeClientsContext.Provider>
  );
};

export default TypeClientsState;
