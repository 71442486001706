import React from "react";
import InventoriesReducer from "./InventoriesReducer";
import InventoriesContext from "./InventoriesContext";
import MethodGet, { MethodPost } from "../../config/Service";
import {
  ADD_NEW_INVENTORY,
  ADD_PRODUCT_INVENTORY,
  CURRENT_INVENTORY,
  END_INVENTORY,
  GET_ALL_INVENTORIES,
  GET_ALL_PRODUCTS_INVENTORIES,
  SHOW_ERRORS_API,
  UPDATE_STOCK_ERRORS,
  UPDATE_STOCK_PRODUCTS,
} from "../../types";
import Swal from "sweetalert2";
import headerConfig from "../../config/imageHeaders";
import { useReducer } from "react";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const InventoriesState = ({ children }) => {
  //estado inicial
  const initialState = {
    inventories: [],
    products: [],
    inventory: {},
    ErrorsAPI: [],
    success: false,
    loading: false,
  };

  const [state, dispatch] = useReducer(InventoriesReducer, initialState);

  const GetInventories = () => {
    sessionStorage.removeItem("inventory");
    let url = "/inventory";
    MethodGet(url).then((res) => {
      dispatch({
        type: GET_ALL_INVENTORIES,
        payload: res.data.inventories,
      });
    });
  };

  const GetProductsInventory = (id) => {
    let url = `/inventories/products/${id}`;
    MethodGet(url).then((res) => {
      dispatch({
        type: GET_ALL_PRODUCTS_INVENTORIES,
        payload: res.data.products,
      });
    });
  };

  const endInventory = (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "Se finalizara el inventario",
      icon: "question",
      confirmButtonText: "Si, Finalizar",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      focusConfirm: false,
    }).then((result) => {
      if (result.value) {
        let url = `/inventory/${id}`;
        MethodPost(url)
          .then((res) => {
            Swal.fire({
              title: "Finalizado",
              icon: "success",
              text: res.data.message,
              timer: 1500,
              showConfirmButton: false,
            });
            dispatch({
              type: END_INVENTORY,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              timer: 1500,
              showConfirmButton: false,
            });
          });
      }
    });
  };

  const AddInventory = () => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "Se iniciara un nuevo inventario",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, iniciar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = "/inventory";
        MethodPost(url)
          .then((res) => {
            Swal.fire({
              title: "Creado",
              text: res.data.message,
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
            dispatch({
              type: ADD_NEW_INVENTORY,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
            });
            dispatch({
              payload: error.response.data.message,
            });
          });
      }
    });
  };

  const UpdateBarcode = () => {
    dispatch({
      type: ADD_PRODUCT_INVENTORY,
    });
  };

  const AddProductInventory = (inventory) => {
    Swal.fire({
      title: "Cantidad disponible",
      allowOutsideClick: false,
      html: `<input type="number" id="quantity" class="swal2-input" placeholder="1258">`,
      confirmButtonText: "Guardar",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      focusConfirm: false,
      preConfirm: () => {
        var quantity = Swal.getPopup().querySelector("#quantity").value;
        if (!quantity) {
          Swal.showValidationMessage(
            `Por favor ingresa una cantidad para continuar`
          );
        } else if (quantity === 0) {
          Swal.showValidationMessage(
            `La cantidad que se asigne debe ser mayor a 0`
          );
        }
        return { quantity: quantity };
      },
    }).then((result) => {
      if (result.value) {
        inventory.quantity = result.value.quantity;
        let url = `/inventory/save-products/${inventory.inventory_id}`;
        MethodPost(url, inventory)
          .then((res) => {
            Swal.mixin({
              toast: true,
              background: "#43a047",
              color: "white",
              iconColor: "white",
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            }).fire({
              icon: "success",
              title: `Agregado `,
              text: "El producto se ha agregado al inventario correctamente!",
            });

            dispatch({
              type: ADD_PRODUCT_INVENTORY,
            });
          })
          .catch((error) => {
            Swal.mixin({
              toast: true,
              background: "#d32f2f",
              color: "white",
              iconColor: "white",
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            }).fire({
              icon: "error",
              title: `Error `,
              text: error.response.data.message,
            });
            dispatch({
              payload: error.response.data.message,
            });
          });
      }
    });
  };

  const UpdateQuantityProduct = (inventory) => {
    let url = `/inventory/add-product/${inventory.inventory_id}/${inventory.product_id}`;
    MethodPost(url, inventory)
      .then((res) => {
        Swal.mixin({
          toast: true,
          background: "#43a047",
          color: "white",
          iconColor: "white",
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        }).fire({
          icon: "success",
          title: `Actualizado `,
          text: "Se ha actualizado el stock del producto",
        });
        dispatch({
          type: ADD_PRODUCT_INVENTORY,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: error.response.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          payload: error.response.data.message,
        });
      });
  };

  const SaveCurrentInventory = (inventory) => {
    sessionStorage.setItem("inventory", JSON.stringify(inventory));
    dispatch({
      type: CURRENT_INVENTORY,
      payload: inventory,
    });
  };

  const ExportInventoryExcel = (id) => {
    Swal.fire({
      title: "Descargar archivo ",
      text: "Se descargará, el archivo excel del inventario",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, descargar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/inventory/export-excel/${id}`;
        MethodGet(url, { responseType: "blob" })
          .then((res) => {
            let timerInterval;
            Swal.fire({
              title: "Generando!",
              html: "El archivo se esta generando!.",
              timer: 2000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const b = Swal.getHtmlContainer().querySelector("b");
                timerInterval = setInterval(() => {
                  b.textContent = Swal.getTimerLeft();
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              fileDownload(res.data, "productos_de_inventario.xlsx");
              Swal.fire({
                title: "Descargado",
                text: "Se ha descargado, el detalle de inventario",
                icon: "success",
                timer: 1500,
                showConfirmButton: false,
              });
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: "Se ha producido un error al descargar el detalle de inventario",
              icon: "error",
            });
            dispatch({
              type: SHOW_ERRORS_API,
            });
          });
      }
    });
  };

  const InventoryReportPdf = (id) => {
    Swal.fire({
      title: "Descargar PDF ",
      text: "Se descargará, el reporte del inventario",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, descargar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/inventory/export-pdf/${id}`;
        MethodGet(url, { responseType: "blob" })
          .then((res) => {
            let timerInterval;
            Swal.fire({
              title: "Generando",
              html: "El reporte se esta generando",
              timer: 2000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const b = Swal.getHtmlContainer().querySelector("b");
                timerInterval = setInterval(() => {
                  b.textContent = Swal.getTimerLeft();
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              fileDownload(res.data, "reporte_de_inventario.pdf");
              Swal.fire({
                title: "Descargado",
                text: "Se ha descargado, el reporte de inventario",
                icon: "success",
                timer: 1500,
                showConfirmButton: false,
              });
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: "Se ha producido un error al descargar el detalle de inventario",
              icon: "error",
            });
            dispatch({
              type: SHOW_ERRORS_API,
            });
          });
      }
    });
  };
  const UpdateStockProductsExcel = (products) => {
    const formData = new FormData();
    formData.append("excel", products.file);
    let url = "/inventory/update/excel/products";
    MethodPost(url, formData, { headerConfig })
      .then((res) => {
        toast.success("Stock de productos actualizados exitosamente!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        dispatch({
          type: UPDATE_STOCK_PRODUCTS,
        });
      })
      .catch((error) => {
        error.response.data.errors.map((response) =>
          toast.error(
            <div>
              <p>Error: {response.message} </p>
            </div>,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "colored",
            }
          )
        );
        dispatch({
          type: UPDATE_STOCK_ERRORS,
        });
      });
  };
  return (
    <InventoriesContext.Provider
      value={{
        inventories: state.inventories,
        inventory: state.inventory,
        products: state.products,
        ErrorsAPI: state.ErrorsAPI,
        success: state.success,
        loading: state.loading,
        GetInventories,
        AddInventory,
        endInventory,
        SaveCurrentInventory,
        AddProductInventory,
        GetProductsInventory,
        UpdateBarcode,
        ExportInventoryExcel,
        InventoryReportPdf,
        UpdateStockProductsExcel,
        UpdateQuantityProduct,
      }}
    >
      {children}
    </InventoriesContext.Provider>
  );
};

export default InventoriesState;
