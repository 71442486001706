import React, { useReducer } from "react";
import Swal from "sweetalert2";
import MethodGet, { MethodPut } from "../../config/Service";
import {
  CURRENT_ABOUT,
  UPDATE_ABOUT,
  GET_ALL_ABOUT,
  SHOW_ERRORS_API,
} from "../../types";
import headerConfig from "../../config/imageHeaders";
import AboutContext from "./AboutContext";
import AboutReducer from "./AboutReducer";

const AboutState = ({ children }) => {
  const initialState = {
    about: [],
    ErrorsApi: [],
    success: false,
  };

  const [state, dispatch] = useReducer(AboutReducer, initialState);

  //obtener la lista de About
  const GetAbout = () => {
    sessionStorage.removeItem("about");
    let url = "/administrable/about";
    MethodGet(url)
      .then(({ data }) => {
        dispatch({
          type: GET_ALL_ABOUT,
          payload: data.about.about,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
      });
  };

  //funcion para editar about
  const UpdateAboutHistory = (data) => {
    const formData = new FormData();
    formData.append("aboutThat", data.aboutThat);
    formData.append("imageAbout", data.imageAbout);

    let url = `/administrable/aboutThat`;
    MethodPut(url, formData, { headerConfig })
      .then((res) => {
        Swal.fire({
          title: "Información Actualizada",
          text: res.data.message,
          timer: 3000,
          showConfirmButton: false,
          icon: "success",
        });
        dispatch({
          type: UPDATE_ABOUT,
        });
      })
      .catch((error) => {
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.res.data.error,
        });
      });
  };
  const UpdateAboutMission = (data) => {
    const formData = new FormData();
    formData.append("mission", data.mission);
    formData.append("imageMission", data.imageMission);

    let url = `/administrable/mission`;
    MethodPut(url, formData, { headerConfig })
      .then((res) => {
        Swal.fire({
          title: "Información Actualizada",
          text: res.data.message,
          timer: 3000,
          showConfirmButton: false,
          icon: "success",
        });
        dispatch({
          type: UPDATE_ABOUT,
        });
      })
      .catch((error) => {
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.res.data.error,
        });
      });
  };

  //funcion para seleccionar una about
  const SaveCurrentAbout = (about) => {
    sessionStorage.setItem("about", JSON.stringify(about));
    dispatch({
      type: CURRENT_ABOUT,
      payload: about,
    });
  };
  return (
    <AboutContext.Provider
      value={{
        about: state.about,
        ErrorsApi: state.ErrorsApi,
        success: state.success,
        GetAbout,
        UpdateAboutHistory,
        UpdateAboutMission,
        SaveCurrentAbout,
      }}>
      {children}
    </AboutContext.Provider>
  );
};

export default AboutState;
