import React, { useContext, useState } from "react";
import ProductsContext from "../../context/Products/ProductsContext";
import CategorySelect from "../../containers/SelectOptions/SelectCategory";
import SelectSubcategoriesCategories from "../SelectOptions/SelectSubcategoriesCategories";
import BrandSelect from "../SelectOptions/BrandSelect";
import SelectTags from "../SelectOptions/SelectTags";
import { useForm } from "react-hook-form";
import { PriceFormat } from "../../utils/FormatPrice";
import AddCircle from "@mui/icons-material/AddCircle";
import AddModalTag from "../tags/Add";
import SelectTypeProduct from "../SelectOptions/SelectTypeProduct";
import VariantsCanvasSelect from "../SelectOptions/VariantsCanvasSelect";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@mui/styles";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";

import {
  Grid,
  Box,
  Fab,
  TextField,
  Tooltip,
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
const useStyles = makeStyles({
  input: {
    display: "none",
  },
  logoimagen: {
    height: "200px",
    boxShadow: "5px 5px #289FED",
    objectFit: "cover",
  },
  mainaddcategory: {
    background: "white",
    marginX: "10px",
  },
  aver: {
    marginY: "20px",
  },
  card: {
    marginTop: 7,
    marginLeft: 7,
    marginRight: 7,
  },
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 2,
  },
});

export default function Add(props) {
  const classes = useStyles();
  const { modalAgregar, handleClose } = props;

  const { AddProducts } = useContext(ProductsContext);
  //Funcion para validaciones
  const [typeProduct, saveTypeProduct] = useState("");
  const [CategoryId, saveCategory] = useState(""); //categoria
  const [SubcategoryId, saveSubcategory] = useState(""); //subcategoria
  const [brandId, saveBrand] = useState(""); //marca
  const [tagId, saveTag] = useState([]); //tags
  // const [multimedia, setMultimedia] = useState(0); //multimedia
  const [minPrice, setMinPrice] = useState(0);
  const [variant, saveVariant] = useState(null);
  //const [grs, saveGrs] = useState(0);
  const [modalAdd, AbrirModalAdd] = useState(false);

  const handleClickOpenAdd = () => {
    AbrirModalAdd(true);
  };
  const handleClickCloseAdd = () => {
    AbrirModalAdd(false);
  };

  const detectarCambiosCategory = (e) => {
    saveCategory(e.target.value);
  };
  const detectarCambiosSubcategoryCategory = (e) => {
    saveSubcategory(e.target.value);
  };
  const detectarCambiosBrand = (e) => {
    saveBrand(e.target.value);
  };
  const detectarCambiosTagsAdd = (value) => {
    saveTag(value);
  };
  const detectarCambiosTypeProduct = (e) => {
    saveTypeProduct(e.target.value);
  };
  const detectarCambiosCanvasVariants = (e) => {
    saveVariant(e.target.value);
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  const reset = () => {
    setValue("barcode", "", { shouldDirty: true });
    setValue("name", "", { shouldDirty: true });
    setValue("sku", "", { shouldDirty: true });
    setValue("product_key", "", { shouldDirty: true });
    setValue("price_purchase", "", { shouldDirty: true });
    setValue("price", "", { shouldDirty: true });
    // setValue("quantity", "", { shouldDirty: true });
    setValue("onzas", "", { shouldDirty: true });
    setValue("description", "", { shouldDirty: true });
    setValue("google_product_category", "", { shouldDirty: true });
    setValue("location", "", { shouldDirty: true });
    setValue("weight", "", { shouldDirty: true });
  };

  const onSubmit = (data, e) => {
    data.category = CategoryId;
    data.subcategory = SubcategoryId;
    data.tags = tagId;
    data.brand = brandId;
    data.product_type = typeProduct;
    data.discount = 0;

    //data.multimedia = multimedia;
    if (typeProduct === "2" && variant !== null) {
      data.variation = variant;
    }
    data.price_purchase = PriceFormat(data.price_purchase);
    data.price = PriceFormat(data.price);

    AddProducts(data);
    reset();
    handleClose();
  };

  return (
    <Dialog open={modalAgregar} onClose={handleClose} fullWidth maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <DialogTitle>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#000000",
                textAling: "center",
                boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                borderRadius: "0 0 24px 0",
              }}
            >
              <div className={classes.titleCard}>Registrar nuevo producto</div>
              <div className={classes.fab}>
                <Tooltip title="cerrar" arrow>
                  <Fab
                    size="small"
                    onClick={handleClose}
                    sx={{
                      color: "#FFFFFF",
                      backgroundColor: "#FF0000",
                      "&:hover": {
                        color: "#FFFFFF",
                        backgroundColor: "#FF0000",
                      },
                    }}
                  >
                    <CancelPresentationIcon />
                  </Fab>
                </Tooltip>
              </div>
            </Box>
          </DialogTitle>
          <DialogContent>
            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(e) => {
                if (e.code === "Enter" || e.code === "NumpadEnter")
                  e.preventDefault();
              }}
              autoComplete="off"
            >
              <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <SelectTypeProduct
                      detectarCambiosTypeProduct={detectarCambiosTypeProduct}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id="barcode"
                      label="Codigo de barras"
                      name="barcode"
                      defaultValue={""}
                      error={errors.barcode ? true : false}
                      helperText={errors?.barcode?.message}
                      {...register("barcode", {
                        required: {
                          value: true,
                          message: "EL codigo de barras es necesario",
                        },
                      })}
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id="sku"
                      label="SKU"
                      name="sku"
                      defaultValue={""}
                      error={errors.sku ? true : false}
                      helperText={errors?.sku?.message}
                      {...register("sku", {
                        required: {
                          value: true,
                          message: "EL SKU es requerido",
                        },
                      })}
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id="product_key"
                      label="Product key SAT"
                      name="product_key"
                      type="number"
                      error={errors.product_key ? true : false}
                      helperText={errors?.product_key?.message}
                      {...register("product_key", {
                        required: {
                          value: true,
                          message: "EL product key SAT es requerido",
                        },
                      })}
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id="google_product_category"
                      label="Product Google category"
                      name="google_product_category"
                      defaultValue={""}
                      error={errors.google_product_category ? true : false}
                      helperText={errors?.google_product_category?.message}
                      {...register("google_product_category", {
                        required: {
                          value: true,
                          message: "La clave google_category es requerida",
                        },
                      })}
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id="name"
                      label="Nombre"
                      name="name"
                      defaultValue={""}
                      error={errors.name ? true : false}
                      helperText={errors?.name?.message}
                      {...register("name", {
                        required: {
                          value: true,
                          message: "El Nombre es requerido",
                        },
                        minLength: {
                          value: 1,
                          message: "Minimo 4 caracteres",
                        },
                        maxLength: {
                          value: 255,
                          message: "Maximo 255 caracteres",
                        },
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <BrandSelect detectarCambiosBrand={detectarCambiosBrand} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <CategorySelect
                      detectarCambiosCategory={detectarCambiosCategory}
                    />
                  </Grid>
                  {CategoryId !== "" ? (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <SelectSubcategoriesCategories
                        category_id={CategoryId}
                        detectarCambiosSubcategoryCategory={
                          detectarCambiosSubcategoryCategory
                        }
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormControl fullWidth disabled>
                        <InputLabel id="demo-simple-select-disabled-label">
                          Subcategorias
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-disabled-label"
                          id="demo-simple-select-disabled"
                          label="Subcategorias"
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                        </Select>
                        <FormHelperText>
                          Seleccione alguna Categoria
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      placeholder="Ejemplo 1: 12 Ejemplo 2: 12.00"
                      id="price_purchase"
                      name="price_purchase"
                      label="Precio de compra"
                      variant="outlined"
                      defaultValue={""}
                      type="number"
                      error={errors.price_purchase ? true : false}
                      helperText={errors?.price_purchase?.message}
                      {...register("price_purchase", {
                        required: {
                          value: true,
                          message: "El precio de compra es requerido",
                        },
                        pattern: {
                          value: /^([0-9 .])*$/,
                          message: "Error: Favor de Seguir el Ejemplo",
                        },
                        onChange: ({ target }) => {
                          const minValor = parseFloat(target.value) + 1;
                          setMinPrice(minValor);
                        },
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      id="price"
                      type="number"
                      placeholder="Ejemplo 1: 12 Ejemplo 2: 12.00"
                      name="price"
                      label="Precio"
                      defaultValue={""}
                      variant="outlined"
                      error={errors.price ? true : false}
                      helperText={errors?.price?.message}
                      {...register("price", {
                        required: {
                          value: true,
                          message: "El precio es requerido",
                        },
                        pattern: {
                          value: /^([0-9 .])*$/,
                          message: "Error: Favor de Seguir el Ejemplo",
                        },

                        min: {
                          value: parseFloat(minPrice),
                          message:
                            "El precio tiene que ser mayor al precio compra",
                        },
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      id="location"
                      placeholder="Ejemplo: 12"
                      label="Ubicación"
                      name="location"
                      variant="outlined"
                      defaultValue={""}
                      error={errors.location ? true : false}
                      helperText={errors?.location?.message}
                      {...register("location", {
                        required: {
                          value: true,
                          message: "Este campo es requerido",
                        },
                      })}
                    />
                  </Grid>
                  {typeProduct === "1" && (
                    <>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextField
                          fullWidth
                          id="weight"
                          placeholder="Ejemplo: 12.8"
                          label="Peso"
                          name="weight"
                          defaultValue={""}
                          variant="outlined"
                          //type="number"
                          error={errors.weight ? true : false}
                          helperText={errors?.weight?.message}
                          {...register("weight", {
                            required: {
                              value: true,
                              message: "Este campo es requerido",
                            },
                          })}
                        />
                      </Grid>
                    </>
                  )}
                  {typeProduct === "2" && (
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <VariantsCanvasSelect
                        detectarCambiosCanvasVariants={
                          detectarCambiosCanvasVariants
                        }
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      id="outlined-multiline-static"
                      fullWidth
                      label="Descripcion"
                      name="description"
                      multiline
                      defaultValue={""}
                      placeholder="Ingresa la descripcion del producto"
                      rows={4}
                      variant="outlined"
                      error={errors.description ? true : false}
                      helperText={errors?.description?.message}
                      {...register("description", {
                        required: {
                          value: true,
                          message: "La descripcion es requerida",
                        },
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <SelectTags
                      detectarCambiosTagsAdd={detectarCambiosTagsAdd}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    xl={4}
                    justifyContent="center"
                    display="flex"
                  >
                    <Button
                      onClick={handleClickOpenAdd}
                      startIcon={<AddCircle />}
                      sx={{
                        backgroundColor: "black",
                        color: "white",
                        "&:hover": { backgroundColor: "black", color: "white" },
                      }}
                    >
                      Agregar Etiquetas
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                        textAling: "center",
                      }}
                    >
                      <div className={classes.fab}>
                        <Fab
                          type="submit"
                          variant="extended"
                          sx={{
                            backgroundColor: "#00B9FF",
                            color: "white",
                            "&:hover": {
                              backgroundColor: "#00DEED",
                              color: "white",
                            },
                          }}
                        >
                          <SaveOutlinedIcon sx={{ mr: 1 }} />
                          Guardar
                        </Fab>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </DialogContent>
        </Grid>
      </Grid>
      <AddModalTag
        modal={modalAdd}
        handleClickOpen={handleClickOpenAdd}
        handleClose={handleClickCloseAdd}
      />
    </Dialog>
  );
}
