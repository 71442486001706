import React, { Fragment, useContext, useEffect } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import BrandsContext from "../../context/Brands/BrandsContext";

export default function SelectBrandMostSale(props) {
  const { brands, GetBrands, success } = useContext(BrandsContext);

  useEffect(() => {
    GetBrands();
  }, [success]);
  const allbrand = { _id: 0, name: "Todas las marcas" };
  const detectarCambiosBrand = (e) => {
    props.detectarCambiosBrand(e);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Marca</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label="Marca "
        onChange={detectarCambiosBrand}
      >
        {brands.map((brand, index) => (
          <MenuItem key={index} value={brand._id}>
            {brand.name}
          </MenuItem>
        ))}
        <MenuItem value={allbrand._id}>{allbrand.name}</MenuItem>
      </Select>
    </FormControl>
  );
}
