import React, { useState, useContext, useEffect } from "react";
import BrandsContext from "../../context/Brands/BrandsContext";
import MethodGet from "../../config/Service";
import { useForm } from "react-hook-form";
//Estilos , Icons y Alerta
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Box,
  Fab,
  TextField,
  Tooltip,
  InputLabel,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import CircularProgress from "@mui/material/CircularProgress";

import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import Swal from "sweetalert2";

//
const useStyles = makeStyles({
  logoimagen: {
    height: "200px",
    boxShadow: "5px 5px #289FED",
    objectFit: "cover",
  },
  input: {
    display: "none",
  },
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 10,
  },
});

export default function EditBrand({ modalUpdate, handleCloseEdit, id }) {
  const classes = useStyles();
  const { UpdateBrands } = useContext(BrandsContext);
  const [cargando, spinnerCargando] = useState(false);

  const [photo, setImage] = useState({
    urlPhoto:
      "https://cdn.pngsumo.com/index-of-areaedu-wp-content-uploads-2016-02-default-png-600_600.png",
    image: "",
  });

  const handleChangeImage = (e) => {
    setImage({
      ...photo,
      urlPhoto: URL.createObjectURL(e.target.files[0]),
      image: e.target.files[0],
    });
  };

  const [Brand, SaveBrand] = useState({});
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
  } = useForm({ defaultValues: Brand });

  useEffect(() => {
    let url = `/brands/${id}`;
    MethodGet(url)
      .then((res) => {
        SaveBrand(res.data.brand);
        setImage({ urlPhoto: res.data.brand.image, image: "" });
        spinnerCargando(true);
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          text: error.response.data.message,
          icon: "error",
        });
        spinnerCargando(true);
      });
  }, [id]);

  useEffect(() => {
    if (Brand) {
      setValue("name", Brand.name);
    } else {
      setValue("name", "");
    }
  }, [Brand]);

  const onSubmit = (data, e) => {
    data.id = id;
    data.image = photo.image;
    UpdateBrands(data);
    handleCloseEdit();
  };

  return (
    <Dialog open={modalUpdate} onClose={handleCloseEdit}>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#000000",
            textAling: "center",
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            borderRadius: "0 0 24px 0",
          }}>
          <div className={classes.titleCard}>Actualiza marca</div>
          <div className={classes.fab}>
            <Tooltip title="cerrar" arrow>
              <Fab
                size="small"
                onClick={handleCloseEdit}
                sx={{
                  color: "#FFFFFF",
                  backgroundColor: "#FF0000",
                  "&:hover": {
                    color: "#FFFFFF",
                    backgroundColor: "#FF0000",
                  },
                }}>
                <CancelPresentationIcon />
              </Fab>
            </Tooltip>
          </div>
        </Box>
      </DialogTitle>
      {!cargando ? (
        <CircularProgress />
      ) : (
        <DialogContent>
          <form
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter")
                e.preventDefault();
            }}
            autoComplete="off">
            <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    <div>
                      <img
                        src={photo.urlPhoto}
                        className={classes.logoimagen}
                        alt="agrega marca"
                      />
                    </div>
                  </Box>

                  <input
                    className={classes.input}
                    id="icon-button-file"
                    type="file"
                    name="image"
                    accept="image/png, image/jpg, image/webp"
                    onChange={handleChangeImage}
                  />
                  <InputLabel sx={{ textAlign: "center" }}>
                    Selecciona Imagen o logo de la Marca :
                    <label htmlFor="icon-button-file">
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span">
                        <Tooltip
                          title="seleccionar imagen"
                          aria-label="seleccionar imagen">
                          <PhotoCamera />
                        </Tooltip>
                      </IconButton>
                    </label>
                  </InputLabel>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    focused
                    fullWidth
                    id="name"
                    placeholder="Ejemplo: Princess"
                    name="name"
                    label="Nombre de la Marca Asociada"
                    variant="outlined"
                    type="text"
                    error={errors.name ? true : false}
                    helperText={errors?.name?.message}
                    {...register("name", {
                      required: {
                        value: true,
                        message: "Este campo es requerido",
                      },
                      minLength: {
                        value: 4,
                        message: "Minimo 4 caracteres",
                      },
                      maxLength: {
                        value: 255,
                        message: "Maximo 255 caracteres",
                      },
                    })}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      textAling: "center",
                    }}>
                    <div className={classes.fab}>
                      <Fab
                        type="submit"
                        variant="extended"
                        sx={{
                          backgroundColor: "#00B9FF",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#00DEED",
                            color: "white",
                          },
                        }}>
                        <SaveOutlinedIcon sx={{ mr: 1 }} />
                        Guardar
                      </Fab>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </form>
        </DialogContent>
      )}
    </Dialog>
  );
}
