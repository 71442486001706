import {
  Button,
  Card,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
const example = () => {
  const arra1 = [
    { name: "lavado completo" },
    { name: "pulido" },
    { name: "lavado de interiores" },
  ];
  const arra2 = [
    { name: "lavado completo" },
    { name: "pulido" },
    { name: "lavado de interiores" },
    { name: "lavado de exteriores" },
  ];
  const arra3 = [
    { name: "lavado completo" },
    { name: "pulido" },
    { name: "lavado de interiores" },
    { name: "lavado de interiores" },
    { name: "lavado de interiores" },
    { name: "lavado de exteriores" },
  ];
  const arra4 = [
    { name: "lavado completo" },
    { name: "pulido" },
    { name: "pulido" },
    { name: "pulido" },
    { name: "pulido" },
    { name: "lavado de interiores" },
    { name: "lavado de interiores" },
    { name: "lavado de interiores" },
    { name: "lavado de exteriores" },
  ];
  return (
    <>
      <div>example</div>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
          <Card
            sx={{
              boxShadow: 10,
              borderRadius: 2,
              width: "auto",
              height: "750px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid container spacing={2} sx={{ display: "flex" }}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  margin: 2,
                }}
              >
                <Typography
                  fontWeight="bold"
                  fontFamily="serif"
                  fontSize="40px"
                >
                  Titulo del servicio
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Divider>
                  <DirectionsCarIcon />
                </Divider>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  fontWeight="bold"
                  fontSize="35px"
                  textAlign="center"
                >
                  Nuestros Beneficios
                </Typography>
                <br />
                <List>
                  {arra1.map((a) => (
                    <ListItem>
                      <CheckIcon
                        sx={{
                          fontSize: "25px",
                          color: "green",
                          marginRight: 2,
                        }}
                      />{" "}
                      {a.name}
                    </ListItem>
                  ))}
                </List>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{
                  margin: 3,
                  display: "flex",
                  justifyContent: "end",
                  alignContent: "end",
                  alignItems: "end",
                }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  size="large"
                  color="primary"
                >
                  Adquirir
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default example;
