import React, { useContext, useEffect } from "react";
import FaqsContext from "../../context/Faqs/FaqsContext";
import FaqCard from "../../components/Cards/faqs/FaqCard";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Card, Grid, Box, Button } from "@mui/material/";
import ModulesContext from "../../context/Permissions/PermissionsContext";

const useStyles = makeStyles({
  titleCard: {
    color: "white",
    fontSize: "35px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 2,
    marginRight: 5,
  },
  img_data: {
    margin: "auto",
    height: "90%",
  },
});

export default function Faqs() {
  const classes = useStyles();

  const { faqs, GetFaqs, success } = useContext(FaqsContext);
  const { permissionsPerUser, GetModulesPerUser } = useContext(ModulesContext);

  useEffect(() => {
    if (localStorage.getItem("module_id")) {
      const permiss = localStorage.getItem("module_id");
      GetModulesPerUser(permiss);
    }
    GetFaqs();
  }, [success]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card className={classes.card}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                textAling: "center",
                boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
              }}
            >
              {permissionsPerUser.write === true && (
                <div className={classes.fab}>
                  <Link to="/agrega-pregunta-frecuente">
                    <Button
                      sx={{
                        color: "black",
                        backgroundColor: "#26c6da",
                        hoverColor: "white",
                        fontFamily: "monospace",
                        fontWeight: "bolder",
                        fontSize: "15px",
                        textTransform: "none",
                        "&:hover": {
                          color: "black",
                          backgroundColor: "#67cedb",
                          hoverColor: "white",
                          fontFamily: "monospace",
                          fontWeight: "bolder",
                          fontSize: "15px",
                        },
                      }}
                    >
                      Agregar
                    </Button>
                  </Link>
                </div>
              )}
            </Box>
            {!faqs.length ? (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <img
                  src={require("../../assets/gif/noData.gif")}
                  className={classes.img_data}
                />
              </Grid>
            ) : (
              <FaqCard permiss={permissionsPerUser} faqitems={faqs} />
            )}
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
