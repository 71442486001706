import React from "react";
import { useReducer } from "react";
import MethodGet, { MethodPost, MethodPut } from "../../config/Service";
import AccountsReceivableReducer from "./AccountsReceivableReducer";
import Swal from "sweetalert2";
import headerConfig from "../../config/imageHeaders";

import AccountsReceivableContext from "./AccountsReceivableContext";
import {
  ADD_ACCOUNTS_RECEIVABLE,
  ADD_PAYMENT_ACCOUNT_RECEIVABLE,
  GET_ACCOUNTS_CLIENT,
  GET_ACCOUNTS_RECEIVABLE,
  UPDATE_ACCOUNTS_RECEIVABLE,
} from "./../../types/index";
import fileDownload from "js-file-download";
const AccountsReceivableState = ({ children }) => {
  //estado inicial
  const initialState = {
    accountsReceivable: [],
    paymentsAccountsReceivable: [],
    accountReceivable: null,
    success: false,
    ErrorsAPI: [],
  };
  const [state, dispatch] = useReducer(AccountsReceivableReducer, initialState);
  //Obetner las lista de las cuentas
  const GetAccountsReceivable = () => {
    sessionStorage.removeItem("accountReceivable");
    let url = "/accounts-to-receive";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ACCOUNTS_RECEIVABLE,
          payload: res.data.accounts,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const AddAccountReceivable = (data) => {
    let url = "/accounts-to-receive";
    MethodPost(url, data)
      .then((res) => {
        dispatch({
          type: ADD_ACCOUNTS_RECEIVABLE,
          payload: res.data.client,
        });
        Swal.fire({
          title: "Registrado",
          icon: "success",
          text: res.data.message,
          timer: 1500,
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const UpdateAccountReceivable = (data) => {
    let url = "";
    MethodPut(url)
      .then((res) => {
        dispatch({
          type: UPDATE_ACCOUNTS_RECEIVABLE,
          payload: res.data.account,
        });
        Swal.fire({
          title: "Actualizado",
          text: res.data.message,
          icon: "success",
          timer: 1500,
          ShowConfirmButton: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const GetAccountsClients = (id) => {
    let url = `/clients/${id}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ACCOUNTS_CLIENT,
          payload: res.data.client.accounts,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const AddPaymentToAccountReceivable = (data) => {
    const formData = new FormData();
    formData.append("client_id", data.client_id);
    formData.append("account_id", data.account_id);
    formData.append("amount", data.amount);
    formData.append("folio_trans", data.folio_trans);
    formData.append("voucher", data.voucher);

    let url = `/accounts-to-receive/payment`;
    MethodPost(url, formData, { headerConfig })
      .then((res) => {
        console.log(res.data, "la respuesta");
        dispatch({
          type: ADD_PAYMENT_ACCOUNT_RECEIVABLE,
          payload: res.data.account,
        });
        Swal.fire({
          title: "Registrado",
          text: res.data.message,
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        }).then(function () {
          window.location.reload();
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
      });
  };

  const downloadPdfAllAccountsReceivable = (account) => {
    Swal.fire({
      title: "Descargar PDF",
      text: "Se descargara un PDF con detalle de las cuentas",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, descargar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/accounts-to-receive/ticket-client/${account._id}`;
        MethodGet(url, { responseType: "blob" })
          .then((res) => {
            let timerInterval;
            Swal.fire({
              title: "Generando",
              html: "El PDF se esta generando!",
              timer: 2000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const b = Swal.getHtmlContainer().querySelector("b");
                timerInterval = setInterval(() => {
                  b.textContent = Swal.getTimerLeft();
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              fileDownload(
                res.data,
                `cuentas_por_cobrar_${account.fullname}.pdf`
              );
              Swal.fire({
                title: "Descargado",
                text: "El PDF de la orden se ha descargado correctamente",
                icon: "success",
                timer: 1500,
                showConfirmButton: false,
              });
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              timer: 1500,
              showConfirmButton: false,
            });
          });
      }
    });
  };

  const downloadPdfAccountDetail = (account) => {
    Swal.fire({
      title: "Descargar PDF",
      text: "Se descargara un PDF con detalle de las cuentas",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, descargar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/accounts-to-receive/ticket-account/${account._id}`;
        MethodGet(url, { responseType: "blob" })
          .then((res) => {
            let timerInterval;
            Swal.fire({
              title: "Generando",
              html: "El PDF se esta generando!",
              timer: 2000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const b = Swal.getHtmlContainer().querySelector("b");
                timerInterval = setInterval(() => {
                  b.textContent = Swal.getTimerLeft();
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              fileDownload(
                res.data,
                `cuentas_por_cobrar_${account.NoFolio}.pdf`
              );
              Swal.fire({
                title: "Descargado",
                text: "El PDF de la orden se ha descargado correctamente",
                icon: "success",
                timer: 1500,
                showConfirmButton: false,
              });
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              timer: 1500,
              showConfirmButton: false,
            });
          });
      }
    });
  };
  return (
    <AccountsReceivableContext.Provider
      value={{
        accountsReceivable: state.accountsReceivable,
        success: state.success,
        ErrorsApi: state.ErrorsAPI,
        paymentsAccountsReceivable: state.paymentsAccountsReceivable,
        AddPaymentToAccountReceivable,
        UpdateAccountReceivable,
        AddAccountReceivable,
        GetAccountsReceivable,
        GetAccountsClients,
        downloadPdfAllAccountsReceivable,
        downloadPdfAccountDetail,
      }}>
      {children}
    </AccountsReceivableContext.Provider>
  );
};

export default AccountsReceivableState;
