import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { Alert, Grid, IconButton, Tooltip } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import formatDate from "../../utils/FormatDate";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function ShippmentDetail({ shipping, orderStatus }) {
  return (
    <>
      {orderStatus === 2 ? (
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            display="flex"
            justifyContent="center"
          >
            <Alert icon={false} severity="warning">
              Preparando pedido
            </Alert>
          </Grid>
        </Grid>
      ) : (
        orderStatus === 3 &&
        shipping && (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Paqueteria</StyledTableCell>
                  <StyledTableCell align="center">
                    Numero de guia
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Fecha de envio
                  </StyledTableCell>
                  <StyledTableCell align="center">PDF</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {shipping.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row" align="center">
                      {row.shipment_id.name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.no_guide}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {formatDate(row.createdAt)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <a href={row.pdf} target="_blank" rel="noreferrer">
                        <Tooltip title="Ver guia" placement="top">
                          <IconButton
                            variant="contained"
                            size="small"
                            color="secondary"
                            aria-label="add"
                            sx={{ marginBottom: 2 }}
                          >
                            <CloudDownloadIcon />
                          </IconButton>
                        </Tooltip>
                      </a>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )
      )}
    </>
  );
}
