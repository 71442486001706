import React, { Fragment } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import ProductsContext from "../../context/Products/ProductsContext";
import Select from "react-select";
export default function ProductSelectAllProducts(props) {
  // console.log(props);
  const { products, GetAllProductsSelect } = useContext(ProductsContext);

  useEffect(() => {
    GetAllProductsSelect();
  }, []);
  const selectStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };
  const detectarCambiosProduct = (value) => {
    props.detectarCambiosProduct(value);
  };
  return (
    <Fragment>
      <Select
        onChange={(value) => detectarCambiosProduct(value)}
        className="basic-single"
        classNamePrefix="select"
        name="product"
        styles={selectStyles}
        defaultValue={{ label: "Selecciona un producto ", value: "" }}
        placeholder="Selecciona producto"
        options={products.map((product) => {
          let attribute = {
            label: product.name,
            value: product._id,
          };
          return attribute;
        })}
      />
    </Fragment>
  );
}
