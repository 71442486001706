import { Button, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import ProductsContext from "../context/Products/ProductsContext";
import ProductSelectAllProducts from "../containers/SelectOptions/ProductSelectAllProducts";
//Este componente unicamente va a funcionar para el select de entradas de productos
export default function SelectProduct({
  guardarProductoID,
  saveProductsList,
  productsList,
}) {
  const { GetAllProductsSelect, products, success } =
    useContext(ProductsContext);
  useEffect(() => {
    GetAllProductsSelect();
  }, []);
  const [product, saveProduct] = useState(null);
  const detectarCambiosProduct = (value) => {
    saveProduct(value.value);
  };
  const AgregarProductoState = () => {
    if (product === null) {
      Swal.fire({
        title: "Error",
        text: "Primero selecciona un producto",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }
    if (product !== null) {
      var producto = [];
      producto = products.filter((p) => p._id === product);
      console.log(producto, "el producto");
      if (!producto.length) {
        Swal.fire({
          title: "Error",
          text: "ocurrio un error al consultar la informacion del producto",
          icon: "error",
        });
      }
    }
    producto[0].value = producto[0]._id;
    guardarProductoID(producto[0]);
    //obtener la posicion del producto
    const position = productsList
      .map(function (e) {
        return e.product_id;
      })
      .indexOf(producto[0]._id);
    let productoResultado = {};
    productoResultado.barcode = producto[0].barcode;
    //productoResultado.product_id = producto[0]._id;
    // productoResultado.image = producto[0].multimedia[0].path;
    productoResultado.name = producto[0].name;
    // productoResultado.brand = producto[0].brand.name;
    productoResultado.quantity = 1;
    productoResultado.product_id = producto[0]._id;
    productoResultado.price = producto[0].price;
    productoResultado.stock_product = producto[0].quantity;
    productoResultado.status = producto[0].status;
    // if (productoResultado.stock_product === 0) {
    //   Swal.fire({
    //     title: "Error",
    //     text: "No se puede dar salida al producto porque no tiene stock",
    //     icon: "error",
    //     showConfirmButton: false,
    //     timer: 1500,
    //   });
    //   saveProduct("");
    //   return;
    // }
    var exist = productsList.filter((p) => p.product_id === product);
    if (exist.length) {
      Swal.mixin({
        toast: true,
        background: "red",
        color: "white",
        iconColor: "white",
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire({
        icon: "error",
        title: `¡Error!`,
        text: "El producto ya se agrego, modifica la cantidad del producto, si deseas agregar mas productos",
      });
      return;
    }
    if (position === -1) {
      productoResultado.product_id = producto[0]._id;
      productoResultado.quantity = 1;
      localStorage.setItem(
        "EnterProducts",
        JSON.stringify([productoResultado, ...productsList])
      );
      saveProductsList([productoResultado, ...productsList]);
      //saveProduct("");
      Swal.mixin({
        toast: true,
        background: "#388e3c",
        color: "white",
        iconColor: "white",
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire({
        icon: "success",
        title: `¡Agregado!`,
        text: "El producto se agrego correctamente",
      });
    }
    // localStorage.setItem("EnterProducts", JSON.stringify(productsList));
  };
  return (
    <>
      <Grid container spacing={2} sx={{ marginLeft: 2 }}>
        <Grid
          item
          xs={10}
          sm={10}
          md={10}
          lg={10}
          xl={10}
          sx={{ marginTop: 2 }}
        >
          <ProductSelectAllProducts
            detectarCambiosProduct={detectarCambiosProduct}
            saveProduct={saveProduct}
          />
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
          <Button
            fullWidth
            variant="contained"
            sx={{ marginTop: 2 }}
            onClick={AgregarProductoState}
          >
            Agregar
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
