import React, { useContext, useEffect, useState } from "react";
import SliderContext from "../../../context/SliderEccomerce/SliderEcommerceContext";
import ModalImage from "react-modal-image-responsive";

import { Box, Tooltip, Grid, Menu, MenuItem, Card, CardContent, IconButton, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export default function SliderCard({ slider, permiss }) {
  const { sliders, GetSliders, success, DeleteSlider } = useContext(SliderContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    GetSliders();
  }, [success]);

  return (
    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
      <Card>
        <Box
          fullWidth
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            paddingX: 2,
            backgroundColor: "white",
            color: "black",
          }}
        >
          <Typography
            variant="su arrowbtitle2"
            noWrap
            sx={{ fontWeight: "bold", fontSize: "17px" }}
            className="blink-1"
          >
            {slider.text}
          </Typography>
          {/* <div> {categorie.name}</div> */}
          {permiss.delete == true && (
            <div>
              {" "}
              <Tooltip arrow title="Operaciones" placement="top">
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                  sx={{ color: "black" }}
                >
                  <MoreVertIcon />
                </IconButton>
              </Tooltip>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                 {permiss.delete === true && (
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    DeleteSlider(slider._id)
                  }}
                >
                  Eliminar
                </MenuItem>
                 )}
              </Menu>
            </div>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <div>
            <CardContent>

              <ModalImage
                small={slider.imageWeb}
                large={slider.imageWeb}
                alt="imagen Web"
              />

              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography
                  variant="su arrowbtitle2"
                  noWrap
                  sx={{ fontWeight: "bold", fontSize: "17px" }}
                  className="blink-1"
                >
                  {slider.short_text}
                </Typography>
              </Box>
            </CardContent>
          </div>
        </Box>
      </Card>
    </Grid>
  );
}
