import React, { useContext, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import { makeStyles } from "@mui/styles";
import { styled, alpha } from "@mui/material/styles";
import {
  Button,
  CardContent,
  Grid,
  InputBase,
  Pagination,
  Stack,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import SalesContext from "../../context/Sales/SalesContext";
import TablePagination from "@mui/material/TablePagination";
import SalesWithOutPaymentCard from "../../components/Cards/Sales/SalesWithOutPaymentCard";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import SearchIcon from "@mui/icons-material/Search";
import NoDataComponents from "../../components/loading/NoDataComponents";
import NoResultsComponent from "../../components/loading/NoResultsComponent";
import MethodGet from "../../config/Service";
import Swal from "sweetalert2";
const useStyles = makeStyles(() => ({
  titleCard: {
    color: "white",
    fontSize: "35px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 2,
  },
}));

//Estilos busqueda
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
  },
}));

export default function SalesWithoutPayments() {
  const classes = useStyles();

  //***Paginacion */
  var [pages, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [magicWord, setMagicWord] = useState("");
  const [salesFilter, setSalesFilter] = useState();
  const [
    salesWithoutPaymentAndPagination,
    setSalesWithoutPaymentAndPagination,
  ] = useState({});

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const limite = rowsPerPage;

  const params = { limite, pages };

  const {
    salesWithOutPayment,
    success,
    SalesWithOutPayment,
    totalDocs,
    totalPages,
  } = useContext(SalesContext);

  useEffect(() => {
    let url = "/payments/no-pagination/limit/pay";
    MethodGet(url)
      .then((res) => {
        setSalesWithoutPaymentAndPagination(res.data.orders);
        console.log(res.data.orders, "la respuesta");
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
      });
  }, [magicWord]);

  const handleFilterSales = ({ target }) => {
    setMagicWord(target.value);
    const Palabra = target.value;
    try {
      let resultfiltersale = salesWithoutPaymentAndPagination.filter(
        (salef) =>
          salef.folio
            .toString()
            .toLowerCase()
            .includes(Palabra.toString().toLowerCase()) ||
          salef.client_id.fullname
            .toString()
            .toLowerCase()
            .includes(Palabra.toLowerCase())
      );
      setSalesFilter(resultfiltersale);
    } catch (error) {}
  };

  useEffect(() => {
    SalesWithOutPayment(params);
    setSalesFilter(salesWithoutPaymentAndPagination);
  }, [success, rowsPerPage, pages]);

  return (
    <Grid container spacing={2} sx={{ backgroundColor: "white" }}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
          <Grid container spacing={2}>
            {!salesWithOutPayment.length ? (
              <></>
            ) : (
              <>
                <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                  <TextField
                    fullWidth
                    label="Buscar Orden"
                    //placeholder="Buscar venta por el número de Folio o por Nombre de cliente"
                    inputProps={{ "aria-label": "search" }}
                    onChange={handleFilterSales}
                  />
                </Grid>
              </>
            )}

            {!magicWord &&
              (!salesWithOutPayment.length ? (
                <NoDataComponents />
              ) : (
                salesWithOutPayment.map((sales) => (
                  <SalesWithOutPaymentCard sales={sales} key={sales._id} />
                ))
              ))}

            {magicWord &&
              (!salesFilter.length ? (
                <NoResultsComponent />
              ) : (
                salesFilter.map((sales) => (
                  <SalesWithOutPaymentCard sales={sales} key={sales._id} />
                ))
              ))}
          </Grid>
        </Box>
        {!magicWord &&
          (!salesWithOutPayment.length ? (
            <Box className={classes.pagination}></Box>
          ) : (
            <Box
              className={classes.pagination}
              sx={{ marginTop: 4, marginBottom: 4 }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ marginBottom: 5 }}
                display="flex"
                justifyContent="center"
              >
                <Stack spacing={2} sx={{ marginLeft: 2, marginTop: 2 }}>
                  <Pagination
                    count={totalPages}
                    variant="outlined"
                    shape="rounded"
                    onChange={handleChangePage}
                    color="secondary"
                    page={pages}
                    size="large"
                    sx={{
                      color: "#880e4f",
                      "&:hover": { color: "#f06292" },
                    }}
                  />
                </Stack>
              </Grid>
            </Box>
          ))}
      </Grid>
    </Grid>
  );
}
