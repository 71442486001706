import React, { useState, useContext } from "react";
import BusinessRulesContext from "../../../context/BusinessRules/BusinessRulesContext";
import ModalEdit from "../../../containers/businessrules/ModalEdit";
import formatDate from "../../../utils/FormatDate";

import {
  Card,
  Grid,
  Box,
  Divider,
  Chip,
  Typography,
  Tooltip,
  Menu,
  MenuItem,
  IconButton,
  Button,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { EditIcon } from "../../icons/EditIcon";
import { DeleteIcon } from "../../icons/DeleteIcon";

export default function CardBusinessRules({ business_rule, permiss }) {
  const { DeleteBusinessRulesSubcategory } = useContext(BusinessRulesContext);
  const [id_business, setIdBusiness] = useState();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [modalUpdate, AbrirModalUpdate] = useState(false);

  const handleClickOpenUpdate = () => {
    AbrirModalUpdate(true);
  };
  const handleClickCloseUpdate = () => {
    sessionStorage.removeItem("business_rule");
    AbrirModalUpdate(false);
  };
  const selectBusiness = (business_rule) => {
    setIdBusiness(business_rule);
    handleClickOpenUpdate();
  };

  return (
    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
      <Card sx={{ boxShadow: 3, paddingX: 2 }}>
        <Divider sx={{ marginTop: 2 }}>
          <Chip label="Tipo: Subcategoria" />
        </Divider>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginX: 1,
          }}
        >
          <>
            <div>
              <Typography
                sx={{ fontWeight: "bold", fontSize: 14, marginRight: 1 }}
              >
                Subcategoria:
              </Typography>
            </div>
            <div>{business_rule?.subcategory?.name}</div>
          </>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            marginY: 1,
            marginX: 1,
          }}
        >
          <div>
            <Typography
              sx={{ fontWeight: "bold", fontSize: 14, marginRight: 1 }}
            >
              Descuento a Aplicar:
            </Typography>
          </div>
          <div> {business_rule?.discount} %</div>
        </Box>
        <Grid container spacing={2}>
        {permiss.update === true && (

          <Grid item xs={6} display="flex" justifyContent="center">
            <Tooltip title="Editar" placement="top">
              <Button
                onClick={() => {
                  selectBusiness(business_rule._id);
                }}
              >
                <EditIcon />
              </Button>
            </Tooltip>
          </Grid>
        )}
                {permiss.delete === true && (
          <Grid item xs={6} display="flex" justifyContent="center">
            <Tooltip title="Eliminar" placement="top">
              <Button
                onClick={() => {
                  DeleteBusinessRulesSubcategory(business_rule._id);
                }}
              >
                <DeleteIcon />
              </Button>
            </Tooltip>
          </Grid>
                )}
        </Grid>
      </Card>

      {id_business && (
        <ModalEdit
          modalUpdate={modalUpdate}
          id={id_business}
          handleClickOpen={handleClickOpenUpdate}
          handleCloseEdit={handleClickCloseUpdate}
        />
      )}
    </Grid>
  );
}
