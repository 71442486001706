import React from 'react';
import { PRODUCTS_MOST_SOLD, PRODUCTS_STOCK,PRODUCTS_LESS_SOLD } from '../../types';

export default function GraphsReducer(state, action) {
    switch (action.type) {
        case PRODUCTS_STOCK:
            return {
                ...state,
                products: action.payload,
                success: false,
                ErrorsApi: []
            }
        case PRODUCTS_MOST_SOLD:
            return {
                ...state,
                products: action.payload,
                success: false,
                ErrorsApi: [],
            }
            case PRODUCTS_LESS_SOLD:
                return {
                    ...state,
                    products: action.payload,
                    success: false,
                    ErrorsApi: [],
                }
        default:
            return state;
    }
}
