import { useState, useContext, useEffect } from "react";
import { Cancel, Send } from "@mui/icons-material";
import { Button, Grid, TextField } from "@mui/material";
import TypeCategoriesContext from "../../context/typecategories/TypeCategoriesContext";

export default function TypeCategoriesForm({ dataItem }) {
  const { addTypeCategory, updateTypeCategory } = useContext(
    TypeCategoriesContext
  );
  const [currenVal, setCurrenVal] = useState();
  const [ValInput, setValInput] = useState(dataItem?.name || "");

  const HandleChangeVal = (val) => {
    setValInput(val);
  };

  const HandleChaneCancel = () => {
    setValInput("");
    setCurrenVal();
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (ValInput.trim().length > 0) {
      if (currenVal) {
        updateTypeCategory({ id: currenVal, name: ValInput });
      } else {
        addTypeCategory(ValInput);
      }
      setValInput("");
      setCurrenVal();
    }
  };

  useEffect(() => {
    setValInput(dataItem?.name || "");
    setCurrenVal(dataItem?.id || "");
  }, [dataItem]);

  return (
    <>
      <form onSubmit={onSubmit} >
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={6} 
            lg={6}
            xl={6}
          >
            <TextField
              fullWidth
              id="name"
              label="Nombre"
              variant="outlined"
              name="name"
              value={ValInput}
              onChange={({ target }) => {
                HandleChangeVal(target.value);
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            display="flex"
            justifyContent="center"
          >
            <Button
              type="submit"
              sx={{
                margin: 1,
                color: "white",
                backgroundColor: "#26c6da",
                hoverColor: "white",
                fontFamily: "monospace",
                fontWeight: "bolder",
                fontSize: "15px",
                textTransform: "none",
                "&:hover": {
                  color: "white",
                  backgroundColor: "#67cedb",
                  hoverColor: "white",
                  fontFamily: "monospace",
                  fontWeight: "bolder",
                  fontSize: "15px",
                },
              }}
              endIcon={<Send />}
            >
              {!currenVal ? "Agregar" : "Actualizar"}
            </Button>
            {!currenVal ? null : (
              <Button
                onClick={HandleChaneCancel}
                sx={{
                  color: "white",
                  backgroundColor: "#B71C1C",
                  hoverColor: "white",
                  fontFamily: "monospace",
                  fontWeight: "bolder",
                  fontSize: "15px",
                  textTransform: "none",
                  "&:hover": {
                    color: "white",
                    backgroundColor: "#E24141",
                    hoverColor: "white",
                    fontFamily: "monospace",
                    fontWeight: "bolder",
                    fontSize: "15px",
                  },
                }}
                endIcon={<Cancel />}
              >
                cancelar
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
    </>
    // <div>TypeCategoriesForm</div>
  );
}
