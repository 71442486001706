import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Layout from "../../../components/layout/Layout";
import MethodGet from "../../../config/Service";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { PriceFormat } from "../../../utils/FormatPrice";
import formatDate from "./../../../utils/FormatDate";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";
import AccountsReceivableContext from "../../../context/AccountsReceivable/AccountsReceivableContext";
import { useContext } from "react";
import { styled } from "@mui/material/styles";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const DetailAccountReceivable = (props) => {
  const idClient = props.match.params.idAccount;
  const {
    GetAccountsClients,
    paymentsAccountsReceivable,
    downloadPdfAccountDetail,
  } = useContext(AccountsReceivableContext);
  useEffect(() => {
    GetAccountsClients(idClient);
  }, [idClient]);

  const [client, saveClient] = useState();
  useEffect(() => {
    let url = `/clients/${idClient}`;
    MethodGet(url)
      .then((res) => {
        saveClient(res.data.client);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [idClient]);
  return (
    <Layout>
      <Box sx={{ backgroundColor: "black", color: "white" }}>
        <Typography fontWeight="bold" fontSize="35px">
          Detalle de la cuenta
        </Typography>
      </Box>
      {client && (
        <Grid container spacing={2} sx={{ padding: 2 }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography fontWeight="bold" fontFamily="monospace">
              Cliente: {client.fullname}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Typography fontWeight="bold" fontFamily="monospace">
              Total A pagar: $ {PriceFormat(client.total)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Typography fontWeight="bold" fontFamily="monospace">
              Total Pagado: $ {PriceFormat(client.totalPayment)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Typography fontWeight="bold" fontFamily="monospace">
              Resta por pagar: ${" "}
              {PriceFormat(client.total - client.totalPayment)}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2} sx={{ padding: 2, marginBottom: 10 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">Fecha</StyledTableCell>
                  <StyledTableCell align="center">
                    Método de pago
                  </StyledTableCell>
                  <StyledTableCell align="center">Monto</StyledTableCell>
                  <StyledTableCell align="center">
                    Monto Abonado
                  </StyledTableCell>
                  <StyledTableCell align="center">Opciones</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              {paymentsAccountsReceivable && (
                <TableBody>
                  {paymentsAccountsReceivable.map((row, index) => (
                    <StyledTableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}>
                      <StyledTableCell align="center">
                        {formatDate(row.createdAt)}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.typeInvoice}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        $ {PriceFormat(row.total)}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        $ {PriceFormat(row.totalPayment)}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.total > row.totalPayment && (
                          <Tooltip title="Agregar Pago" placement="top">
                            <Link
                              to={`/PaymentToAccountReceivable/${idClient}/${row._id}`}>
                              <IconButton>
                                <AddIcon sx={{ color: "green" }} />
                              </IconButton>
                            </Link>
                          </Tooltip>
                        )}
                        <Tooltip title="Detalle cuenta" placement="top">
                          <Link to={`/DetallePagosCuenta/${row._id}`}>
                            <IconButton>
                              <VisibilityIcon sx={{ color: "#01579b" }} />
                            </IconButton>
                          </Link>
                        </Tooltip>
                        <Tooltip title="Descargar PDF" placement="top">
                          <IconButton
                            onClick={() => downloadPdfAccountDetail(row)}>
                            <PictureAsPdfIcon sx={{ color: "red" }} />
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default DetailAccountReceivable;
