import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Tooltip } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ProductsContext from "../../../context/Products/ProductsContext";
import { useContext } from "react";
import FormatDate from "../../../utils/FormatDate";
import SpinnerComponent from "../../../components/loading/SpinnerComponent";
import { EyeIcon } from "../../../components/icons/EyeIcon";
import { useState } from "react";
import { Link } from "react-router-dom";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function ListEnterProductos({ enterProducts }) {
  const { DownloadPdfEnterProducts } = useContext(ProductsContext);

  return (
    <>
      {enterProducts ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Fecha</StyledTableCell>
                <StyledTableCell align="center">Folio</StyledTableCell>
                <StyledTableCell align="center">
                  ¿Quien Realizo?
                </StyledTableCell>
                <StyledTableCell align="center">Opciones</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {enterProducts.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row" align="center">
                    {FormatDate(row.createdAt)}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    {row.num_folio ? row.num_folio : "N/A"}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.user}</StyledTableCell>
                  <StyledTableCell align="center">
                    <Button onClick={() => DownloadPdfEnterProducts(row)}>
                      <PictureAsPdfIcon sx={{ color: "red" }} />
                    </Button>
                    <Tooltip title="Detalle" placement="top">
                      <Link to={`/DetalleEntrada/${row._id}`}>
                        <Button>
                          <EyeIcon />
                        </Button>
                      </Link>
                    </Tooltip>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <SpinnerComponent />
      )}
    </>
  );
}
