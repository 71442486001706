import React, { useContext, useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

import CouponNewUserContext from "../../../context/CouponNewUser/CouponNewUserContext";
import EditCouponNewUser from "../../../containers/coupons/EditCouponNewUser";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";

const useStyles = makeStyles(() => ({
  dollar: {
    objectFit: "cover",
  },
  div_ganancias: {
    marginRight: "5%",
    marginLeft: "5%",
  },
  div_btnnew_user: {
    marginLeft: 5,
  },
}));

export default function CardNewUserCupon() {
  const classes = useStyles();
  const [modalEditar, AbrirModalAdd] = useState(false);

  const {
    couponsnu,
    GetCouponNu,
    success: successcuponnewuser,
    saveCurrentCouponNewUser,
    UpdateCouponNu,
  } = useContext(CouponNewUserContext);

  const handleClickOpenAdd = () => {
    AbrirModalAdd(true);
  };
  const handleClickCloseAdd = () => {
    AbrirModalAdd(false);
  };
  const selectCoupon = (couponnu) => {
    saveCurrentCouponNewUser(couponnu);
  };

  useEffect(() => {
    GetCouponNu();
  }, [successcuponnewuser]);
  return (
    <Card
      style={{ backgroundColor: "#536dfe" }}
      sx={{
        boxShadow: 4,
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      <div
        style={{
          marginLeft: 10,
          marginTop: 10,
          marginBottom: 10,
          fontSize: 40,
          color: "white",
        }}
      >
        {couponsnu.discount}%
      </div>
      <div className={classes.div_ganancias}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 14,
            color: "white",
          }}
        >
          Porcentaje de descuento nuevo usuario
        </Typography>
        <Typography
          sx={{
            fontSize: 10,
            color: "white",
            border: "1px",
            borderColor: "green",
          }}
        >
          <Button
            onClick={(e) => {
              UpdateCouponNu(couponsnu._id);
              handleClickOpenAdd();
            }}
            variant="contained"
            sx={{
              width: "100%",
              backgroundColor: "#FF4900",
              padding: 0,
              textTransform: "none",
              lineHeight: 0,
              "&:hover": { backgroundColor: "#FF6E34" },
            }}
            startIcon={<ChangeCircleIcon />}
          >
            Cambiar
          </Button>
        </Typography>
      </div>
      <EditCouponNewUser
        modal={modalEditar}
        handleClickOpen={handleClickOpenAdd}
        handleClose={handleClickCloseAdd}
      />
    </Card>
  );
}
