import { Button, Grid } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import MethodGet from "../../config/Service";
import ProductSelectReactSelect from "../SelectOptions/ProductSelectReactSelect";
import ProductsContext from "../../context/Products/ProductsContext";
import { useContext } from "react";
export default function SelectProduct({
  guardarProductoID,
  guardarSalida,
  salida,
}) {
  const [product, saveProduct] = useState(null);
  // const [producto, setInfoProduct] = useState();
  const [cargando, spinnerCargando] = useState();
  const { productsSelect, products, success } = useContext(ProductsContext);
  useEffect(() => {
    productsSelect();
  }, [success]);
  const detectarCambiosProductSelect = (value) => {
    saveProduct(value.value);
  };
  const AgregarProductoState = () => {
    if (product === null) {
      Swal.fire({
        title: "Error",
        text: "Primero selecciona un producto",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }
    if (product !== null) {
      var producto = [];
      producto = products.filter((p) => p._id === product);
      if (!producto.length) {
        Swal.fire({
          title: "error",
          text: "Ocurrio un error al consultar la informacion del producto",
          icon: "error",
        });
      }
    }
    producto[0].value = producto[0]._id;
    guardarProductoID(producto[0]);
    //obtener la posicion del producto
    const position = salida
      .map(function (e) {
        return e.product_id;
      })
      .indexOf(producto[0]._id);
    let productoResultado = {};
    productoResultado.barcode = producto[0].barcode;
    productoResultado.product_id = producto[0]._id;
    productoResultado.image = producto[0].multimedia[0].path;
    productoResultado.name = producto[0].name;
    productoResultado.brand = producto[0].brand.name;
    productoResultado.quantity = 1;
    productoResultado.product_id = producto[0]._id;
    productoResultado.price = producto[0].price;
    productoResultado.stock_product = producto[0].quantity;
    if (productoResultado.stock_product === 0) {
      Swal.fire({
        title: "Error",
        text: "No se puede dar salida al producto porque no tiene stock",
        icon: "error",
        showConfirmButton: false,
        timer: 1500,
      });
      saveProduct(null);
      return;
    }
    var exist = salida.filter((p) => p.product_id === product);
    if (exist.length) {
      Swal.fire({
        title: "No permitido",
        icon: "error",
        text: "El producto ya ha sido agregado a la lista de productos, por favor modifica la cantidad si deseas agregar más productos!",
        timer: 2000,
        showConfirmButton: false,
      });
      return;
    }
    if (position === -1) {
      productoResultado.product_id = producto[0]._id;
      productoResultado.quantity = 1;
      localStorage.setItem(
        "ExitProducts",
        JSON.stringify([productoResultado, ...salida])
      );
      guardarSalida([productoResultado, ...salida]);
      Swal.fire({
        title: "Agregado",
        text: "El producto se agregó correctamente!",
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
      });
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
          <ProductSelectReactSelect
            detectarCambiosProductSelect={detectarCambiosProductSelect}
            saveProduct={saveProduct}
          />
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
          <Button variant="contained" fullWidth onClick={AgregarProductoState}>
            Agregar
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
