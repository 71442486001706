import { useEffect, useState, useContext } from "react";
import { Box, Grid } from "@mui/material";
import TypeCategoriesCard from "../../components/Cards/typecategories/TypeCategoriesCard";
import TypeCategoriesContext from "../../context/typecategories/TypeCategoriesContext";
import TypeCategoriesForm from "./TypeCategoriesForm";
import NoDataComponents from "../../components/loading/NoDataComponents";
import ModulesContext from "../../context/Permissions/PermissionsContext";

export const TypeCategories = () => {
  const [dataItem, setDataItem] = useState();
  const { getAllTypeCategories, typecategories, success } = useContext(
    TypeCategoriesContext
  );
  useEffect(() => {
    getAllTypeCategories();
  }, []);

  const { permissionsPerUser, GetModulesPerUser } = useContext(ModulesContext);

  useEffect(() => {
    if (localStorage.getItem("module_id")) {
      const permiss = localStorage.getItem("module_id");
      GetModulesPerUser(permiss);
      // GetTypeUsers();
    }
  }, [success]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
            sx={{
              width: "100%",
              paddingTop: 4,
              paddingLeft: 3,
              paddingRight: 3,
            }}>
            <TypeCategoriesForm dataItem={dataItem} />
            {/* key={typecategorie._id} */}
            <Grid container spacing={2}>
              {!typecategories.length ? (
                <NoDataComponents />
              ) : (
                typecategories.map((typecategorie) => (
                  <TypeCategoriesCard
                    key={typecategorie._id}
                    typecategorie={typecategorie}
                    setDataItem={setDataItem}
                    permiss={permissionsPerUser}
                  />
                ))
              )}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
