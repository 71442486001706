import React, { useContext } from "react";
import WarehouseContext from "../../../context/Warehouse/WarehouseContext";
import formatDate from "../../../utils/FormatDate";

import SwipeDownIcon from "@mui/icons-material/SwipeDown";
import {
  Box,
  Card,
  Typography,
  Divider,
  Button,
  Grid,
  Alert,
  IconButton,
} from "@mui/material";
import { Link } from "react-router-dom";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ArticleIcon from "@mui/icons-material/Article";

const PendingCard = ({ shipping, permiss }) => {
  const { TakeOrder, SaveCurrentOder, PdfOrder } = useContext(WarehouseContext);

  const SelectShowOrder = (shipping) => {
    SaveCurrentOder(shipping);
  };
  return (
    <>
      <Card sx={{ boxShadow: 10 }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            display="flex"
            justifyContent="space-between"
            sx={{ backgroundColor: "#ffa726", color: "white" }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                display="flex"
                justifyContent="center"
              >
                <Typography
                  fontWeight="bold"
                  fontFamily="monospace"
                  textAlign="center"
                >
                  Pedido sin enpaquetar
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                display="flex"
                justifyContent="center"
              >
                <div>
                  <img
                    src={require("../../../assets/gif/pending_package.gif")}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                lg={12}
                md={12}
                xl={12}
                display="flex"
                justifyContent="center"
              >
                <Alert severity="success" icon={false}>
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    fontFamily="monospace"
                  >
                    #Pedido: {shipping.order_id.folio}
                  </Typography>
                </Alert>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                lg={12}
                md={12}
                xl={12}
                display="flex"
                justifyContent="center"
              >
                <Typography variant="h6" fontWeight="bold">
                  Fecha compra:{""}
                  {formatDate(shipping.order_id.createdAt)}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                lg={12}
                md={12}
                xl={12}
                display="flex"
                justifyContent="center"
              >
                <Alert icon={false} severity="info">
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    fontFamily="monospace"
                  >
                    Cliente: {shipping?.order_id?.client_id?.fullname}
                  </Typography>
                </Alert>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                lg={12}
                md={12}
                xl={12}
                display="flex"
                justifyContent="center"
              >
                <Typography variant="h6" fontWeight="bold">
                  Productos comprados: {shipping.order_id.products_list.length}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                sx={{ marginBottom: 2 }}
              >
                <IconButton onClick={() => PdfOrder(shipping.order_id)}>
                  <PictureAsPdfIcon sx={{ color: "#b71c1c" }} />
                </IconButton>
              </Grid>
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                sx={{ marginBottom: 2 }}
              >
                <Link to={`/Detalle_venta/${shipping.order_id._id}`}>
                  <IconButton
                    // sx={{
                    //   backgroundColor: "",
                    //   borderColor: "#1769aa",
                    //   color: "white",
                    //   marginTop: 5,
                    //   textTransform: "none",
                    // }}
                    onClick={() => SelectShowOrder(shipping.order_id._id)}
                  >
                    <ArticleIcon sx={{ color: "#1769aa" }} />
                  </IconButton>
                </Link>
              </Grid>
              {permiss.update === true && (
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  lg={4}
                  xl={4}
                  sx={{ marginBottom: 2 }}
                >
                  <IconButton
                    variant="outlined"
                    size="small"
                    onClick={(e) => {
                      TakeOrder(shipping._id);
                    }}
                  >
                    <SwipeDownIcon sx={{ color: "#388e3c" }} />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default PendingCard;
