import React, { useContext, useEffect, useState } from "react";
import Layout from "../../../components/layout/Layout";
import AddTypeClients from "./Add";
import TypeClientsContext from "../../../context/TypeClients/TypeClientsContext";
import { makeStyles } from "@mui/styles";
import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import TypeClientCard from "../../../components/Cards/TypeClients/TypeClientsCard";

const useStyles = makeStyles(() => ({
  titleCard: {
    color: "white",
    fontSize: "35px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    margin: 5,
  },
}));

export default function TypeClients() {
  const classes = useStyles();
  //abrir modal
  const [modalAdd, AbrirModalAdd] = useState(false);
  const handleClickOpenAdd = () => {
    AbrirModalAdd(true);
  };
  const handleClickCloseAdd = () => {
    AbrirModalAdd(false);
  };
  const { GetTypeClients, typeClients } = useContext(TypeClientsContext);
  useEffect(() => {
    GetTypeClients();
  }, []);
  return (
    <Layout>
      <Grid container spacing={2} sx={{ backgroundColor: "white" }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#000000",
              textAling: "center",
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
              // borderRadius: "0 0 24px 0",
            }}
          >
            <div className={classes.titleCard}>Tipo de clientes</div>
            <div className={classes.fab}>
              <Button
                onClick={handleClickOpenAdd}
                sx={{
                  color: "black",
                  backgroundColor: "#26c6da",
                  hoverColor: "white",
                  fontFamily: "monospace",
                  fontWeight: "bolder",
                  fontSize: "15px",
                  textTransform: "none",
                  "&:hover": {
                    color: "black",
                    backgroundColor: "#67cedb",
                    hoverColor: "white",
                    fontFamily: "monospace",
                    fontWeight: "bolder",
                    fontSize: "15px",
                  },
                }}
              >
                Agregar
              </Button>
            </div>
          </Box>
        </Grid>
        {typeClients.map((tClient, index) => (
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <TypeClientCard typeClient={tClient} key={index} />
          </Grid>
        ))}
        <AddTypeClients
          modal={modalAdd}
          handleClickOpen={handleClickOpenAdd}
          handleClose={handleClickCloseAdd}
        />
      </Grid>
    </Layout>
  );
}
