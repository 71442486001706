import React from "react";
import MethodGet from "../../config/Service";
import { GET_MUNICIPALITIES, GET_STATES } from "../../types";
import StatesReducer from "./StatesReducer";
import StatesContext from "./StatesContext";
import { useReducer } from "react";
const StatesState = ({ children }) => {
  //estado inicial
  const initialState = {
    states: [],
    municipalities: [],
    success: false,
    ErrorsApi: [],
  };

  const [state, dispatch] = useReducer(StatesReducer, initialState);

  //lista de estados
  const GetStates = () => {
    let url = "/states";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_STATES,
          payload: res.data.states,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const GetMunicipalities = (id) => {
    let url = `/municipalities/${id}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_MUNICIPALITIES,
          payload: res.data.municipalities,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <StatesContext.Provider
      value={{
        states: state.states,
        municipalities: state.municipalities,
        success: state.success,
        ErrorsApi: state.ErrorsApi,
        GetMunicipalities,
        GetStates,
      }}>
      {children}
    </StatesContext.Provider>
  );
};

export default StatesState;
