import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { Box, Fab, Grid, TextField, Typography } from "@mui/material";
import SelectShippments from "../../../containers/SelectOptions/SelectShippments";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { makeStyles } from "@mui/styles";
import WarehouseContext from "../../../context/Warehouse/WarehouseContext";
import { useContext } from "react";
import WarehouseCanvasContext from "../../../context/WarehouseCanvas/WarehouseCanvasContext";
const useStyles = makeStyles({
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 10,
  },
  input: {
    display: "none",
  },
});

export default function ModalUpdateShipping({
  open,
  handleClose,
  id,
  shippment_id,
  no_guide,
  canvas,
}) {
  const { UpdateShipping } = useContext(WarehouseContext);
  const { UpdateShippingCanvas } = useContext(WarehouseCanvasContext);
  const classes = useStyles();
  const [file_first, setFile] = useState({
    file: "",
  });
  const [shippmentId, saveShippment] = useState(shippment_id);
  //   const [guide, saveGuide] = useState();
  //   const handleChangeGuide = (e) => {

  //     saveGuide(e.target.value);
  //   };
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  const detectarCambiosShippment = (e) => {
    saveShippment(e.target.value);
  };
  const handleChangeFile = (e) => {
    setFile({
      ...file_first,
      file: e.target.files[0],
    });
  };
  const onSubmit = (data, e) => {
    // if (file_first.file === "") {
    //   Swal.mixin({
    //     toast: true,
    //     background: "red",
    //     color: "white",
    //     iconColor: "white",
    //     position: "top-end",
    //     showConfirmButton: false,
    //     timer: 2400,
    //     timerProgressBar: true,
    //     didOpen: (toast) => {
    //       toast.addEventListener("mouseenter", Swal.stopTimer);
    //       toast.addEventListener("mouseleave", Swal.resumeTimer);
    //     },
    //   }).fire({
    //     icon: "error",
    //     title: `Por favor sube la guia del pedido en PDF `,
    //   });
    //   return;
    // }
    data.id = id;
    //data.no_guide = guide;
    data.shipment_id = shippmentId;
    if (file_first.file) {
      data.pdf = file_first.file;
    }

    //ShippOrder(data);
    if (canvas) {
      UpdateShippingCanvas(data);
    } else {
      UpdateShipping(data);
    }
    handleClose();
    setValue("no_guide", "", { shouldDirty: true });
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Actualizar N° de guía y/o PDF de la guía"}
        </DialogTitle>
        <DialogContent>
          <form
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter")
                e.preventDefault();
            }}
            autoComplete="off"
          >
            <Grid container spacing={2} sx={{ marginTop: 1 }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SelectShippments
                  detectarCambiosShippment={detectarCambiosShippment}
                  ShippmentId={shippmentId}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12} xl={12}>
                <TextField
                  fullWidth
                  id="no_guide"
                  name="no_guide"
                  placeholder="5484224875548"
                  label="Número de guía"
                  variant="outlined"
                  defaultValue={no_guide}
                  //onChange={handleChangeGuide}
                  sx={{ marginBottom: 2 }}
                  error={errors.no_guide ? true : false}
                  helperText={errors?.no_guide?.message}
                  {...register("no_guide", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                    minLength: {
                      value: 4,
                      message: "Minimo 4 caracteres",
                    },
                  })}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                display="flex"
                justifyContent="center"
              >
                <Button
                  variant="contained"
                  component="label"
                  startIcon={<AttachFileIcon />}
                  onChange={handleChangeFile}
                  sx={{
                    color: "white",
                    backgroundColor: "#ad1457",
                    "&:hover": { backgroundColor: "#ad1457", color: "white" },
                  }}
                >
                  Adjuntar Guía PDF
                  <input hidden accept="application/pdf" multiple type="file" />
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                display="flex"
                justifyContent="center"
              >
                <Typography variant="overline" display="block" gutterBottom>
                  Archivo Seleccionado: {""}
                </Typography>
                <Typography variant="overline" display="block" gutterBottom>
                  {file_first.file !== ""
                    ? file_first?.file?.name
                    : " No se ha seleccionado ningun archivo"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    textAling: "center",
                  }}
                >
                  <div className={classes.fab}>
                    <Fab
                      variant="extended"
                      type="submit"
                      sx={{
                        backgroundColor: "#00B9FF",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#00DEED",
                          color: "white",
                        },
                      }}
                    >
                      <SaveOutlinedIcon sx={{ mr: 1 }} />
                      Guardar
                    </Fab>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
