import {
  GET_ALL_TYPE_CATEGORY,
  ADD_TYPE_CATEGORY,
  UPDATE_TYPE_CATEGORY,
  DELETE_TYPE_CATEGORY,
} from "../../types";

export default function TypeCategoriesReducer(state, action) {
  switch (action.type) {
    case GET_ALL_TYPE_CATEGORY:
      return {
        ...state,
        typecategories: action.payload,
        copytypecategories: action.payload,
      };
    case ADD_TYPE_CATEGORY:
      return {
        ...state,
        typecategories: [...state.typecategories, action.payload],
      };
    case DELETE_TYPE_CATEGORY:
      return {
        ...state,
        typecategories: state.typecategories.filter(
          (typecategory) => typecategory._id !== action.payload
        ),
      };
    case UPDATE_TYPE_CATEGORY:
      return {
        ...state,
        typecategories: state.typecategories.map((typecategorie) => {
          if (typecategorie._id === action.payload._id) {
            typecategorie = action.payload;
          }
          return typecategorie;
        }),
      };

    default:
      return state;
  }
}
