import React, { useContext, useEffect } from "react";
import BanksContext from "../../context/Banks/BanksContext";

import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";

export default function BankSelect(props) {
  const GetBanksList = useContext(BanksContext);
  const { banks, GetBanks, success } = GetBanksList;

  useEffect(() => {
    GetBanks();
  }, []);

  const detectarCambiosBank = (e) => {
    if (e.target.value) {
    }
    props.detectarCambiosBank(e);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Banco</InputLabel>
          {props.bankId ? (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Banco "
              onChange={detectarCambiosBank}
              value={props.bankId}
            >
              {banks.map((bank, index) => (
                <MenuItem value={bank._id} key={index}>
                  {bank.name}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Banco "
              onChange={detectarCambiosBank}
            >
              {banks.map((bank, index) => (
                <MenuItem value={bank._id} key={index}>
                  {bank.name}
                </MenuItem>
              ))}
            </Select>
          )}
          <FormHelperText>Campo Requerido*</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}
