import React from "react";
import Layout from "../../components/layout/Layout";
import AboutComponent from "../../components/About/AboutComponent";

import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";

const useStyles = makeStyles(() => ({
  titleCard: {
    color: "white",
    fontSize: "35px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
}));

export default function AboutIndex() {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={2} sx={{ backgroundColor: "white" }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#000000",
              textAling: "center",
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
              // borderRadius: "0 0 24px 0",
            }}>
            <div className={classes.titleCard}>Nosotros</div>
          </Box> */}
          <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={2}>
                  <AboutComponent />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
