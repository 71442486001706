import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { PriceFormat } from "../../utils/FormatPrice";
import { DetailIcon } from "../icons/DetailIcon";
import { TicketIcon } from "../icons/TicketIcon";
import { Grid, IconButton, Tooltip } from "@mui/material";
import SalesFisicContext from "../../context/SalesFisic/SalesFisicContext";
import { useContext } from "react";
import DetailSale from "../../containers/SalesFisic/Detail_sale";
import { useState } from "react";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
export default function CardSell({ sale }) {
  /**Show modal */
  const [openShow, setOpenShow] = useState(false);

  const handleClickOpenShow = () => {
    setOpenShow(true);
  };

  const handleCloseShow = () => {
    setOpenShow(false);
  };
  const { SaveCurrentSaleFisic, DownloadPdf, DownloadTicket } =
    useContext(SalesFisicContext);
  const [id_sale, setId] = useState(null);
  const SelectShowSale = (sale) => {
    SaveCurrentSaleFisic(sale);
  };
  return (
    <>
      <Card sx={{ boxShadow: 10 }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Venta: # {sale.no_folio}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Cliente: {sale?.customer_id?.fullname}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Subtotal: $ {PriceFormat(sale?.subtotal)}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            total: $ {PriceFormat(sale?.total)}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Metodo de pago:{" "}
            {sale?.type_sale === 1
              ? "Efectivo"
              : sale?.type_sale === 2
              ? "Transferencia Electronica de fondos"
              : sale?.type_sale === 3
              ? "Tarjeta de crédito o débito"
              : "Efect. | TB | TC ó TD"}
          </Typography>
        </CardContent>
        <CardActions>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              display="flex"
              justifyContent="space-between">
              <Tooltip title="Detalle venta" placement="top">
                <IconButton
                  size="medium"
                  onClick={() => {
                    setId(sale._id);
                    handleClickOpenShow();
                  }}>
                  <RemoveRedEyeIcon className="button_dark" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Pdf venta" placement="top">
                <IconButton size="medium" onClick={() => DownloadPdf(sale)}>
                  <PictureAsPdfIcon className="button_danger" />
                </IconButton>
              </Tooltip>
              <Tooltip title="ticket venta" placement="top">
                <IconButton size="medium" onClick={() => DownloadTicket(sale)}>
                  <BookOnlineIcon className="button_primary" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
      {id_sale && (
        <DetailSale
          open={openShow}
          handleClose={handleCloseShow}
          id={id_sale}
        />
      )}
    </>
  );
}
