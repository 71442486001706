import React, { useReducer } from "react";
import MethodGet, {
  MethodDelete,
  MethodPost,
  MethodPut,
} from "../../config/Service";
import ProviderReducer from "./ProviderReducer";
import {
  ADD_PAY_PROVIDER,
  ADD_PROVIDER,
  CREATE_TICKET_PROVIDER,
  DELETE_PROVIDER,
  GET_PROVIDERS,
  UPDATE_PROVIDER,
} from "./../../types/index";
import Swal from "sweetalert2";
import ProvidersContext from "./ProviderContext";
import imageHeaders from "./../../config/imageHeaders";
const ProviderState = ({ children }) => {
  const initialState = {
    providers: [],
    listProviders: [],
    provider: {},
    success: false,
    ErrorsApi: [],
  };
  const [state, dispatch] = useReducer(ProviderReducer, initialState);

  const GetProviders = () => {
    let url = "/providers";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_PROVIDERS,
          payload: {
            listProviders: res.data.providers,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const AddProvider = (data) => {
    let url = "/providers";
    const formData = new FormData();
    formData.append("constancy", data.constancy);
    formData.append("name", data.name);
    formData.append("phone", data.phone);
    formData.append("direction", data.direction);
    formData.append("rfc", data.rfc);
    formData.append("state", data.state);
    formData.append("municipality", data.municipality);
    formData.append("company", data.company);
    formData.append("credit_limit", data.credit_limit);
    MethodPost(url, formData, { imageHeaders })
      .then((res) => {
        dispatch({
          type: ADD_PROVIDER,
          payload: res.data.provider,
        });
        Swal.fire({
          title: "Registrado",
          text: res.data.message,
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const UpdateProvider = (data) => {
    let url = `/providers/${data.id}`;
    const formData = new FormData();
    formData.append("constancy", data.constancy);
    formData.append("name", data.name);
    formData.append("phone", data.phone);
    formData.append("direction", data.direction);
    formData.append("rfc", data.rfc);
    formData.append("state", data.state);
    formData.append("municipality", data.municipality);
    formData.append("company", data.company);
    formData.append("credit_limit", data.credit_limit);
    MethodPut(url, formData, { imageHeaders })
      .then((res) => {
        dispatch({
          type: UPDATE_PROVIDER,
          payload: res.data.provider,
        });
        Swal.fire({
          title: "Actualizado",
          text: res.data.message,
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const AddTicketProvider = (data) => {
    let url = `/${data.id}`;
    MethodPut(url, data)
      .then((res) => {
        dispatch({
          type: CREATE_TICKET_PROVIDER,
          payload: res.data.provider,
        });
        Swal.fire({
          title: "Creado",
          text: res.data.message,
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const AddPaymentProvider = (data) => {
    let url = `/${data.id}`;
    MethodPut(url, data)
      .then((res) => {
        dispatch({
          type: ADD_PAY_PROVIDER,
          payload: res.data.provider,
        });
        Swal.fire({
          title: "Actualizado",
          text: res.data.message,
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //Eliminar  usuario
  const DeleteProvider = (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "El proveedor seleccionado será Eliminado",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/providers/${id}`;
        MethodDelete(url)
          .then((res) => {
            Swal.fire({
              title: "Eliminado",
              text: res.data.message,
              icon: "success",
              timer: 2000,
              showConfirmButton: false,
            });
            dispatch({
              type: DELETE_PROVIDER,
              payload: id,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              showConfirmButton: false,
              timer: 2000,
            });
          });
      }
    });
  };
  return (
    <ProvidersContext.Provider
      value={{
        providers: state.providers,
        listProviders: state.listProviders,
        provider: state.provider,
        success: state.success,
        ErrorsApi: state.ErrorsApi,
        GetProviders,
        AddProvider,
        UpdateProvider,
        AddTicketProvider,
        AddPaymentProvider,
        DeleteProvider,
      }}>
      {children}
    </ProvidersContext.Provider>
  );
};

export default ProviderState;
