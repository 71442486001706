import React, { useEffect, useContext } from "react";
import Layout from "../../../components/layout/Layout";
import LogoContext from "../../../context/Logo/LogoContext";
import LogoCard from "../../../../src/components/Logo/LogoCard";

import { makeStyles } from "@mui/styles";
import { Grid, Box } from "@mui/material";

const useStyles = makeStyles({
  titleCard: {
    color: "white",
    fontSize: "35px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },

  cards: {
    marginBottom: 7,
    marginLeft: 7,
    marginRight: 7,
    marginTop: 7,
  },
});

export default function Logo() {
  const classes = useStyles();
  const { logos, GetLogo, success, SaveCurrentLogo } = useContext(LogoContext);
  const SelectLogo = (logo) => {
    SaveCurrentLogo(logo);
  };
  useEffect(() => {
    GetLogo();
  }, [success]);

  return (
    <>
      <Grid container spacing={2} sx={{ backgroundColor: "white" }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#000000",
              textAling: "center",
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
              // borderRadius: "0 0 24px 0",
            }}>
            <div className={classes.titleCard}>
              Gestor del Logo y Redes Sociales
            </div>
          </Box>
          <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={2}>
                  <LogoCard />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
