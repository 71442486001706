import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";

const TaxSystemSelect = (props) => {
  const TaxSystem = [
    { name: "General de Ley Personas Morales", value: "601" },
    { name: "Personas Morales con Fines no Lucrativos", value: "603" },
    {
      name: "Sueldos y Salarios e Ingresos Asimilados a Salarios",
      value: "605",
    },
    { name: "Arrendamiento", value: "606" },
    { name: "Demás ingresos", value: "608" },
    { name: "Consolidación", value: "609" },
    {
      name: "Residentes en el Extranjero sin Establecimiento Permanente en México",
      value: "610",
    },
    { name: "Ingresos por Dividendos (socios y accionistas)", value: "611" },
    {
      name: "Personas Físicas con Actividades Empresariales y Profesionales",
      value: "612",
    },
    { name: "Ingresos por intereses", value: "614" },
    { name: "Sin obligaciones fiscales", value: "616" },
    {
      name: "Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
      value: "620",
    },
    { name: "Incorporación Fiscal", value: "621" },
    {
      name: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
      value: "622",
    },
    { name: "Opcional para Grupos de Sociedades", value: "623" },
    { name: "Coordinados", value: "624" },
    { name: "Hidrocarburos", value: "628" },
    { name: "Régimen de Enajenación o Adquisición de Bienes", value: "607" },
    {
      name: "De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales",
      value: "629",
    },
    { name: "Enajenación de acciones en bolsa de valores", value: "630" },
    { name: "Régimen de los ingresos por obtención de premios", value: "615" },
    {
      name: "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
      value: "625",
    },
    { name: "Régimen Simplificado de Confianza", value: "626" },
  ];
  const detectarCambiosTaxSystem = (e) => {
    props.detectarCambiosTaxSystem(e.target.value);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Regimen Fiscal</InputLabel>
          {props.typeClientId ? (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Tipo de cliente "
              onChange={detectarCambiosTaxSystem}
              value={props.typeClientId}>
              {TaxSystem.map((TaxSystem, index) => (
                <MenuItem key={index} value={TaxSystem.value}>
                  {TaxSystem.name}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="tipo de cliente"
              onChange={detectarCambiosTaxSystem}>
              {TaxSystem.map((TaxSystem, index) => (
                <MenuItem key={index} value={TaxSystem.value}>
                  {TaxSystem.name}
                </MenuItem>
              ))}
            </Select>
          )}
          <FormHelperText>Compo Requerido*</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default TaxSystemSelect;
