import { Box, Button, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useContext, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import PermissionCountriesContext from "../../context/PermissionsCountries/PermissionCountriesContext";
import CountryCard from "../../components/Cards/Country/CountryCard";
import NoDataComponents from "../../components/loading/NoDataComponents";
import AddModal from "./Add";
import ModulesContext from "../../context/Permissions/PermissionsContext";
const useStyles = makeStyles(() => ({
  titleCard: {
    color: "white",
    fontSize: "35px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    margin: 5,
  },
}));

export default function Countries() {
  const classes = useStyles();
  const { countries, GetCountries, success } = useContext(
    PermissionCountriesContext
  );
  const { permissionsPerUser, GetModulesPerUser } = useContext(ModulesContext);
  //modal
  const [modalAdd, AbrirModalAdd] = useState(false);
  const handleClickOpenAdd = () => {
    AbrirModalAdd(true);
  };
  const HandleClickCloseAdd = () => {
    AbrirModalAdd(false);
  };

  useEffect(() => {
    if (localStorage.getItem("module_id")) {
      const permiss = localStorage.getItem("module_id");
      GetModulesPerUser(permiss);
      GetCountries();
    }
  }, [success]);
  return (
    <Layout>
      <Grid container spacing={2} sx={{ backgroundColor: "white", padding: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#000000",
              textAling: "center",
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            }}>
            <div className={classes.titleCard}>Permisos Paises</div>
            {permissionsPerUser.write === true && (
              <div className={classes.fab}>
                <Button
                  onClick={handleClickOpenAdd}
                  sx={{
                    color: "black",
                    backgroundColor: "#26c6da",
                    hoverColor: "white",
                    fontFamily: "monospace",
                    fontWeight: "bolder",
                    fontSize: "15px",
                    textTransform: "none",
                    "&:hover": {
                      color: "black",
                      backgroundColor: "#67cedb",
                      hoverColor: "white",
                      fontFamily: "monospace",
                      fontWeight: "bolder",
                      fontSize: "15px",
                    },
                  }}>
                  Agregar
                </Button>
              </div>
            )}
          </Box>
          <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={2}>
                  {!countries.length ? (
                    <NoDataComponents />
                  ) : (
                    countries.map((country) => (
                      <CountryCard
                        permiss={permissionsPerUser}
                        country={country}
                        key={country._id}
                      />
                    ))
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <AddModal
          modal={modalAdd}
          handleClickOpen={handleClickOpenAdd}
          handleClose={HandleClickCloseAdd}
        />
      </Grid>
    </Layout>
  );
}
