import React, { useContext, useEffect, useState } from "react";
import Layout from "../../../components/layout/Layout";
import { Button, Grid, Typography } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import SalesContext from "../../../context/Sales/SalesContext";
import DetailImportOrder from "./DetailImportOrder";
import Swal from "sweetalert2";
import { MethodPost } from "../../../config/Service";
import headerConfig from "../../../config/imageHeaders";

const ImportOrder = () => {
  const { DownloadTemplateImportOrder } = useContext(SalesContext);
  const [file, saveFile] = useState({
    file: "",
  });
  const handleChangeFile = (e) => {
    saveFile({
      ...file,
      file: e.target.files[0],
    });
  };
  const [total, saveTotal] = useState(0);
  const [subtotal, saveSubtotal] = useState(0);
  const [discount, saveDiscount] = useState(0);
  const [businessRule, saveBusinessRule] = useState({});
  const [orderProducts, saveOrderProducts] = useState([]);
  const [client, saveClient] = useState(null);
  const [clientDirection, saveClientDirection] = useState(null);
  const [account, saveAccount] = useState(null);
  const [shippingCost, saveShippingCost] = useState(0);
  useEffect(() => {
    if (file.file !== "") {
      let url = "/orders/CreateOrderFromExcel";
      const formData = new FormData();
      formData.append("excel", file.file);
      MethodPost(url, formData, { headerConfig })
        .then((res) => {
          console.log(res.data);
          saveTotal(res.data.total);
          saveSubtotal(res.data.subtotal);
          saveDiscount(res.data.discount);
          saveBusinessRule(res.data.businessRule);
          saveOrderProducts(res.data.orderProducts);
          saveShippingCost(res.data.shippment);
        })
        .catch((error) => {
          console.log(error, "el error");
          Swal.mixin({
            toast: true,
            background: "#d32f2f",
            color: "white",
            iconColor: "white",
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          }).fire({
            icon: "error",
            title: `Error `,
            text: error.response.data.message,
          });
        });
      // CreateOrderFromExcel(file.file);
    } else {
      Swal.mixin({
        toast: true,
        background: "#d32f2f",
        color: "white",
        iconColor: "white",
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire({
        icon: "error",
        title: `Error `,
        text: "Por favor selecciona un archivo",
      });
    }
  }, [file]);

  const handleResetSale = () => {
    saveTotal(0);
    saveShippingCost(0);
    saveSubtotal(0);
    saveDiscount(0);
    saveBusinessRule({});
    saveOrderProducts([]);
    saveClient(null);
    saveClientDirection(null);
    saveAccount(null);
    saveFile({ file: "" });
  };

  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ marginTop: 2 }}
        >
          <Typography
            variant="h6"
            textAlign="center"
            fontWeight="bold"
            fontFamily="monospace"
          >
            Importar venta
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          display="flex"
          justifyContent="space-between"
          sx={{ marginLeft: 3, marginRight: 3 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Button
                onClick={() => DownloadTemplateImportOrder()}
                variant="contained"
                size="large"
                fullWidth
                sx={{
                  backgroundColor: "#2e7d32",
                  color: "white",
                  "&:hover": { backgroundColor: "#2e7d32", color: "white" },
                }}
              >
                <CloudDownloadIcon /> Descargar plantilla
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Button
                variant="contained"
                component="label"
                size="large"
                fullWidth
                sx={{
                  backgroundColor: "#e91e63",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#e91e63",
                    color: "white",
                  },
                }}
              >
                <CloudUploadIcon /> Importar venta
                <input
                  hidden
                  accept=".xlsx"
                  multiple
                  type="file"
                  onChange={handleChangeFile}
                />
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Button
                variant="contained"
                size="large"
                fullWidth
                onClick={() => handleResetSale()}
                sx={{
                  backgroundColor: "#1565c0",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#1565c0",
                    color: "white",
                  },
                }}
              >
                <DeleteIcon /> Restablecer venta
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <DetailImportOrder
            total={total}
            subtotal={subtotal}
            discount={discount}
            businessRule={businessRule ?? 0}
            orderProducts={orderProducts}
            client={client}
            saveClient={saveClient}
            clientDirection={clientDirection}
            saveClientDirection={saveClientDirection}
            account={account}
            saveAccount={saveAccount}
            shippingCost={shippingCost}
            handleResetSale={handleResetSale}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default ImportOrder;
