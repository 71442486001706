import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import DateRangeIcon from '@mui/icons-material/DateRange';
import MethodGet from "../../../config/Axios";
import Swal from 'sweetalert2';
import DashboardContext from '../../../context/Dashboard/DashboardContext';


const useStyles = makeStyles(() => ({
    dollar: {
        objectFit: "cover",
    },
    div_ganancias: {
        marginRight: '5%',
        marginLeft: '5%'
    }
}));
const CardSalesOfLastWeek = () => {
    const classes = useStyles();

    const { sales_of_week, SalesOfWeek, success } = useContext(DashboardContext);
    useEffect(() => {
        SalesOfWeek();
    }, [success]);

    return (
      
            <Card style={{ backgroundColor: '#ef6c00' }} sx={{ boxShadow: 4, display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                <div>
                    <DateRangeIcon style={{ 'fontSize': 60, marginLeft: 10, marginTop: 10, marginBottom: 10, color: 'white' }} />
                </div>
                <div className={classes.div_ganancias}>
                    <Typography component="div" variant="h5" sx={{ fontWeight: 'bold', fontSize: 20, color: 'white', border: '1px', borderColor: 'green' }}>
                        Ventas de la semana
                    </Typography>
                    <Typography sx={{ fontSize: 23, color: 'white', border: '1px', borderColor: 'green' }}>{sales_of_week.orders}</Typography>

                </div>
            </Card>
      
    );
}

export default CardSalesOfLastWeek;
