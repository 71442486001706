import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";

export default function SelectQuantity(props) {
  const quantityes = [
    { id: 10, name: "10" },
    { id: 20, name: "20" },
    { id: 30, name: "30" },
    { id: 40, name: "40" },
    { id: 50, name: "50" },
    { id: 60, name: "60" },
    { id: 80, name: "80" },
    { id: 100, name: "100" },
  ];

  const detectarCambiosQuantity = (e) => {
    props.detectarCambiosQuantity(e.target.value);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">#registros</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Marca "
            onChange={detectarCambiosQuantity}
          >
            {quantityes.map((quantity, index) => (
              <MenuItem key={index} value={quantity.id}>
                {quantity.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>Compo Requerido*</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}
