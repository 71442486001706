import { useEffect, useContext } from "react";
import { Autocomplete, createFilterOptions, TextField } from "@mui/material";
import TypeCategoriesContext from "../../context/typecategories/TypeCategoriesContext";
const filter = createFilterOptions();
export const TypeCategories = ({ HandleChange, selectedValue }) => {
  const { getAllTypeCategories, typecategories, addTypeCategory } = useContext(
    TypeCategoriesContext
  );

  useEffect(() => {
    getAllTypeCategories();
  }, []);

  if (!typecategories) return "cargando...";
  return (
    <Autocomplete
      disablePortal
      disableClearable
      value={selectedValue?.name}
      options={typecategories.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      })}
      renderOption={(props, typecategory) => (
        <li {...props}>{typecategory.name}</li>
      )}
      getOptionLabel={(typecategory) => {
        // Value selected with enter, right from the input
        if (typeof typecategory === "string") {
          return typecategory;
        }
        // Add "xxx" option created dynamically
        if (typecategory.inputValue) {
          return typecategory.inputValue;
        }
        // Regular option
        return typecategory.name;
      }}
      // getOptionLabel={(typecategory) => `${typecategory.name}`}
      // getOptionSelected={(typecategory) => <>{typecategory.name}</>}
      onChange={(e, value) => {
        if (value.inputValue) {
          addTypeCategory(value.inputValue)
            .then((res) => {
              HandleChange(res);
            })
            .catch((err) => {});
        } else {
          HandleChange(value);
        }
      }}
      noOptionsText="No hay alguna coincidencia"
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;

        const isExisting = options.some((option) => inputValue === option.name);
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            name: `Agregar "${inputValue}"`,
          });
        }
        return filtered;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Tipo de Categoria"
          helperText={
            !selectedValue ? "Selecciona una opcion es requerido" : ""
          }
          error={selectedValue ? false : true}
        />
      )}
    />
  );
};
