import React, { useState, useContext, useEffect } from "react";
// dsContext fromimport Bran "../../context/Brands/BrandsContext";
import AuthContext from "../../../context/auth/authContext";
import { useForm } from "react-hook-form";
//Estilos , Icons y Alerta
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Box,
  Fab,
  TextField,
  Tooltip,
  InputLabel,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  FormControl,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
} from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

//
const useStyles = makeStyles({
  logoimagen: {
    height: "200px",
    boxShadow: "5px 5px #289FED",
    objectFit: "cover",
  },
  input: {
    display: "none",
  },
  card: {
    marginTop: 7,
    marginLeft: 7,
    marginRight: 7,
  },
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 10,
  },
});

export default function ChangePassword(props) {
  const classes = useStyles();
  const { modal, handleClose } = props;

  const [values, setValues] = useState({
    password: "",
    newpassword: "",
    confirmpassword: "",
    showPassword: false,
    newshowPassword: false,
    confirmshowPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleClickShowNewPassword = () => {
    setValues({
      ...values,
      newshowPassword: !values.newshowPassword,
    });
  };

  const handleClickShowConfirmPassword = () => {
    setValues({
      ...values,
      confirmshowPassword: !values.confirmshowPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [image, setImage] = useState({
    urlPhoto:
      "https://cdn.pngsumo.com/index-of-areaedu-wp-content-uploads-2016-02-default-png-600_600.png",
    image: "",
  });

  const { ChangePasswordUser, success } = useContext(AuthContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  useEffect(() => {
    if (success) {
      setValue("actual_password", "", { shouldDirty: true });
      setValue("new_password", "", { shouldDirty: true });
      setValue("confirm_new_password", "", { shouldDirty: true });
    }
  }, [success]);

  const onSubmit = (data, e) => {
    handleClose();
    ChangePasswordUser(data);
  };

  return (
    <Dialog fullWidth maxWidth="md" open={modal} onClose={handleClose}>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#000000",
            textAling: "center",
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            borderRadius: "0 0 24px 0",
          }}
        >
          <div className={classes.titleCard}>Cambiar contraseña</div>
          <div className={classes.fab}>
            <Tooltip title="cerrar" arrow>
              <Fab
                size="small"
                onClick={handleClose}
                sx={{
                  color: "#FFFFFF",
                  backgroundColor: "#FF0000",
                  "&:hover": {
                    color: "#FFFFFF",
                    backgroundColor: "#FF0000",
                  },
                }}
              >
                <CancelPresentationIcon />
              </Fab>
            </Tooltip>
          </div>
        </Box>
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.code === "Enter") e.preventDefault();
          }}
          autoComplete="off"
        >
          <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    htmlFor="actual_password"
                    error={errors.actual_password ? true : false}
                  >
                    Contraseña Actual
                  </InputLabel>
                  <OutlinedInput
                    error={errors.actual_password ? true : false}
                    id="actual_password"
                    name="actual_password"
                    {...register("actual_password", {
                      required: {
                        value: true,
                        message: "Este campo es requerido",
                      },
                      minLength: {
                        value: 8,
                        message: "",
                      },
                    })}
                    type={values.showPassword ? "text" : "password"}
                    value={values.password}
                    onChange={(event) => {
                      setValues({ ...values, password: event.target.value });
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Contraseña Actual"
                  />
                  {errors.actual_password ? (
                    <FormHelperText error>
                      Requerido: La contraseña tiene que ser de una longitud de
                      8 letras y numeros
                    </FormHelperText>
                  ) : (
                    false
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    htmlFor="new_password"
                    error={errors.new_password ? true : false}
                  >
                    Nueva Contraseña
                  </InputLabel>
                  <OutlinedInput
                    error={errors.new_password ? true : false}
                    id="new_password"
                    name="new_password"
                    {...register("new_password", {
                      required: {
                        value: true,
                        message: "Este campo es requerido",
                      },
                      minLength: {
                        value: 8,
                        message: "",
                      },
                    })}
                    type={values.newshowPassword ? "text" : "password"}
                    value={values.newpassword}
                    onChange={(event) => {
                      setValues({ ...values, newpassword: event.target.value });
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowNewPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.newshowPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Nueva Contraseña"
                  />
                  {errors.new_password ? (
                    <FormHelperText error>
                      Requerido: La contraseña nueva tiene que ser de una
                      longitud de 8 letras y numeros
                    </FormHelperText>
                  ) : (
                    false
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    htmlFor="confirm_new_password"
                    error={errors.confirm_new_password ? true : false}
                  >
                    Confirma Nueva Contraseña
                  </InputLabel>
                  <OutlinedInput
                    error={errors.confirm_new_password ? true : false}
                    id="confirm_new_password"
                    name="confirm_new_password"
                    {...register("confirm_new_password", {
                      required: {
                        value: true,
                        message: "Este campo es requerido",
                      },
                      minLength: {
                        value: 8,
                        message: "",
                      },
                    })}
                    type={values.confirmshowPassword ? "text" : "password"}
                    value={values.confirmpassword}
                    onChange={(event) => {
                      setValues({
                        ...values,
                        confirmpassword: event.target.value,
                      });
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.confirmshowPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Confirma Nueva Contraseña"
                  />
                  {errors.confirm_new_password ? (
                    <FormHelperText error>
                      Requerido: La contraseña nueva tiene que ser de una
                      longitud de 8 letras y numeros
                    </FormHelperText>
                  ) : (
                    false
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    textAling: "center",
                  }}
                >
                  <div className={classes.fab}>
                    <Fab
                      type="submit"
                      variant="extended"
                      sx={{
                        backgroundColor: "#00B9FF",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#00DEED",
                          color: "white",
                        },
                      }}
                    >
                      <SaveOutlinedIcon sx={{ mr: 1 }} />
                      Guardar
                    </Fab>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}
