import React, {  useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import MethodGet from "../../../config/Axios";
import Swal from 'sweetalert2';
import { PriceFormat } from '../../../utils/FormatPrice';
import DashboardContext from '../../../context/Dashboard/DashboardContext';

const useStyles = makeStyles(() => ({
    dollar: {
        objectFit: "cover",
    },
    div_ganancias: {
        marginRight: '5%',
        marginLeft: '5%'
    }
}));

const CardInputForMonth = () => {
    const classes = useStyles();
    const { income_of_month, IncomeOfMonth, success } = useContext(DashboardContext);
    useEffect(() => {
        IncomeOfMonth();
    }, [success]);
    return (
   
            <Card sx={{ boxShadow: 4, display: 'flex', alignItems: 'center', flexDirection: 'row' }} style={{ backgroundColor: '#558b2f' }} >
                <div>
                    <PointOfSaleIcon style={{ 'fontSize': 60, marginLeft: 10, marginTop: 10, marginBottom: 10, color: 'white' }} />
                </div>
                <div className={classes.div_ganancias}>
                    <Typography component="div" variant="h5" sx={{ fontWeight: 'bold', fontSize: 20, color: 'white', border: '1px', borderColor: 'white' }}>
                        Ingresos del mes
                    </Typography>
                    <Typography sx={{ fontSize: 23, color: 'white', border: '1px', borderColor: 'white' }}>$ {PriceFormat(income_of_month.total_money)}</Typography>

                </div>
            </Card>
    
    );
}

export default CardInputForMonth;
