import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ModalImage from "react-modal-image-responsive";
import { makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Fab, IconButton, TableFooter, Tooltip } from "@mui/material";
import Swal from "sweetalert2";
import { useEffect } from "react";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const useStyles = makeStyles({
  img: {
    height: 120,
    width: 120,
  },
  jss59: {
    width: "100%",
    border: "1px solid #bbb",
    height: "15%",
    outline: "none",
    padding: "15px 10px",
    marginLeft: "10px",
    borderRadius: "10px",
  },
});
export default function ProductsOfSale({
  productList,
  saveProductList,
  total,
}) {
  const classes = useStyles();

  const eliminarProductoVenta = (id) => {
    const todosProducto = productList.filter((p) => p.product_id !== id);
    saveProductList(todosProducto);
  };

  const handleChangeQuantity = (e, i) => {
    const newQuantity = Number(e.target.value);
    const todosProducto = [...productList];
    if (newQuantity > todosProducto[i].stock) {
      Swal.fire({
        title: "Error",
        text:
          "La nueva cantidad, no puede ser mayor al stock disponible!, Unicamente hay " +
          todosProducto[i].stock +
          " Disponibles",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
      return;
    }
    todosProducto[i].cantidad = newQuantity;
    todosProducto[i].final_price =
      Number(todosProducto[i].cantidad) * Number(todosProducto[i].price_unit);

    saveProductList(todosProducto);
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <div style={{ height: "230px", overflowY: "auto" }}>
          <Table stickyHeader aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Imagen</StyledTableCell>
                <StyledTableCell align="center">Producto</StyledTableCell>
                <StyledTableCell align="center">Marca</StyledTableCell>
                <StyledTableCell align="center">Precio U.</StyledTableCell>
                <StyledTableCell align="center">Cant.</StyledTableCell>
                <StyledTableCell align="center">Total</StyledTableCell>
                <StyledTableCell align="center">Opcs.</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productList.map((p, index) => (
                <StyledTableRow key={p.name}>
                  <StyledTableCell component="th" scope="row" align="center">
                    <ModalImage
                      className={classes.img}
                      small={p.image}
                      large={p.image}
                      alt={p.name}
                    />
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    {p.name}
                  </StyledTableCell>
                  <StyledTableCell align="center">{p.brand}</StyledTableCell>
                  <StyledTableCell align="center">
                    $ {p.price_unit.toFixed(2)}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <input
                      type="number"
                      step={1}
                      //onChange={detectarCambiosCantidadComprada}
                      className={classes.jss59}
                      placeholder="Agrega la Cantidad Inicial"
                      name="quantityProduct"
                      min={1}
                      max={p.stock}
                      defaultValue={p.cantidad}
                      onChange={(e) => handleChangeQuantity(e, index)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    $ {Number(p.final_price.toFixed(2))}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Box align="center">
                      <Tooltip title="Eliminar producto" placement="top">
                        <IconButton
                          sx={{ color: "red" }}
                          aria-label="delete"
                          size="medium"
                          onClick={() => eliminarProductoVenta(p.product_id)}
                        >
                          <DeleteIcon style={{ fontSize: 30, marginTop: -5 }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell rowSpan={6} />
                <TableCell colSpan={5} sx={{ fontWeight: "bold" }}>
                  Total cotización
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  ${total}
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </div>
      </TableContainer>
    </div>
  );
}
