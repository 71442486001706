import React, { useContext, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Grid,
  InputBase,
  Pagination,
  Stack,
  TextField,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { Box } from "@mui/system";
import NoDataComponents from "../../components/loading/NoDataComponents";
import SalesContext from "../../context/Sales/SalesContext";
import TablePagination from "@mui/material/TablePagination";
import SalesCancelCard from "../../components/Cards/Sales/SalesCancel";
import SearchIcon from "@mui/icons-material/Search";
import NoResultsComponent from "../../components/loading/NoResultsComponent";
import MethodGet from "../../config/Service";
import { useForm } from "react-hook-form";
import { Paginate } from "../../components/Pagination/Paginate";

const useStyles = makeStyles(() => ({
  titleCard: {
    color: "white",
    fontSize: "35px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 2,
  },
}));

//Estilos busqueda
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
  },
}));

export default function SalesCancel() {
  const classes = useStyles();
  //***Paginacion */
  var [pages, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [magicWord, setMagicWord] = useState("");
  const [filtersale, setFilterSales] = useState();
  const [salesCancelWithoutPagination, setSalesCancelWithoutPagination] =
    useState({});

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const limite = rowsPerPage;

  const params = { limite, pages };

  const {
    salesCancel,
    success,
    GetSalesCancel,
    totalDocs,
    totalPages,
    BuscarVenta,
    searchSales,
  } = useContext(SalesContext);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const onSubmit = (data, e) => {
    setMagicWord(data.search);
    data.type = "Canceled";
    BuscarVenta(data);
  };

  useEffect(() => {
    GetSalesCancel(params);
    setFilterSales(salesCancelWithoutPagination);
  }, [success, rowsPerPage, pages]);
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Grid container spacing={2}>
          {salesCancel.length ? (
            <>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  fullWidth
                  label="Buscar Orden"
                  name="search"
                  type="number"
                  //placeholder="Buscar venta por el número de Orden o por nombre de cliente"
                  inputProps={{ "aria-label": "search" }}
                  {...register("search", {
                    required: {
                      value: false,
                      message: "Ingresa una palabra para buscar",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <Button type="submit" value="Buscar" sx={{ display: "none" }}>
                  Buscar
                </Button>
              </Grid>
            </>
          ) : (
            <></>
          )}
        </Grid>
      </form>
      {!magicWord &&
        (!salesCancel.length ? (
          <NoDataComponents />
        ) : (
          <>
            <Grid container spacing={2}>
              {salesCancel.map((salesCancel, index) => (
                <Grid item xs={12} sm={3} md={4} lg={4} xl={4} key={index}>
                  <SalesCancelCard salesCancel={salesCancel} />
                </Grid>
              ))}
            </Grid>
          </>
        ))}

      {magicWord &&
        (!searchSales.length ? (
          <NoResultsComponent />
        ) : (
          <Grid container spacing={2}>
            {searchSales.map((salesCancel, index) => (
              <Grid item xs={12} sm={3} md={4} lg={4} xl={4} key={index}>
                <SalesCancelCard salesCancel={salesCancel} />
              </Grid>
            ))}
          </Grid>
        ))}
      {!magicWord &&
        (!salesCancel.length ? (
          <Box className={classes.pagination}></Box>
        ) : (
          <Box
            className={classes.pagination}
            sx={{ marginTop: 4, marginBottom: 4 }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ marginBottom: 10 }}
              display="flex"
              justifyContent="center"
            >
              <Paginate
                pages={pages}
                totalPages={totalPages}
                handleChangePage={handleChangePage}
              />
            </Grid>
          </Box>
        ))}
    </>
  );
}
