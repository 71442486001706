import { Button, Grid, Paper, Radio, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import Barcode from "../../../components/Barcode";
import Layout from "../../../components/layout/Layout";
import SelectProduct from "../../../components/SelectProduct";
import Options from "./Options";
import ListaProductos from "../../../components/ListaProductos";
import { useForm } from "react-hook-form";
import ProductsContext from "../../../context/Products/ProductsContext";
import { useContext } from "react";
import Add from "../../products/Add";
export default function AddEnterProducts(props) {
  const productsOnLocal = localStorage.getItem("EnterProducts");

  const { createEnterProducts } = useContext(ProductsContext);
  const [selectedValue, setSelectedValue] = useState("barcode");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const [productsList, saveProductsList] = useState(
    productsOnLocal ? JSON.parse(productsOnLocal) : []
  );
  const [productoID, guardarProductoID] = useState("");
  /**modal agregar productos */
  const [modalAdd, abrirModalAdd] = useState(false);
  //funcion para agregar
  const abrirModalAgregar = () => {
    abrirModalAdd(true);
  };
  const cerrarModalAgregar = () => {
    abrirModalAdd(false);
  };
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (data, e) => {
    data.products = productsList.map((p) => {
      let attribute = {
        product_id: p.product_id,
        quantity: p.quantity,
        status: p.status,
      };
      return attribute;
    });
    createEnterProducts(data);
    localStorage.removeItem("EnterProducts");
    //reset();
    props.history.push("/Entradas");
  };

  return (
    <Layout>
      <Paper elevation={3} sx={{ margin: 2, marginTop: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography
              fontWeight="bold"
              fontFamily="monospace"
              align="center"
              variant="h3"
            >
              Entrada de productos
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Options
              selectedValue={selectedValue}
              handleChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <Button
              variant="contained"
              onClick={abrirModalAdd}
              size="large"
              sx={{
                backgroundColor: "#7e57c2",

                color: "white",
                "&:hover": {
                  backgroundColor: "#7e57c2",
                  color: "white",
                },
              }}
            >
              Crear Producto
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            {productsList.length > 0 && (
              <form
                onSubmit={handleSubmit(onSubmit)}
                onKeyDown={(e) => {
                  if (e.code === "Enter" || e.code === "NumpadEnter")
                    e.preventDefault();
                }}
                autoComplete="off"
              >
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    backgroundColor: "#f06292",
                    // margin: 3,
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#f06292",
                      color: "white",
                    },
                  }}
                >
                  Terminar Entradas
                </Button>
              </form>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            display="flex"
            justifyContent="center"
            margin={4}
          >
            {selectedValue === "barcode" ? (
              <Barcode
                productsList={productsList}
                saveProductsList={saveProductsList}
                guardarProductoID={guardarProductoID}
              />
            ) : (
              <SelectProduct
                productsList={productsList}
                saveProductsList={saveProductsList}
                guardarProductoID={guardarProductoID}
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ margin: 2 }}
            display="flex"
            justifyContent="center"
          >
            <ListaProductos
              productsList={productsList}
              saveProductsList={saveProductsList}
            />
          </Grid>
        </Grid>
        <Add
          modalAgregar={modalAdd}
          handleClickOpen={abrirModalAdd}
          handleClose={cerrarModalAgregar}
        />
      </Paper>
    </Layout>
  );
}
