import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import TableAccountsReceivable from "./TableAccountsReceivable";

const AccountsReceivable = () => {
  return (
    <>
      <Grid>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="end"
          sx={{ marginRight: 2 }}>
          <Link to="/AgregarCuentaCobrar">
            <Button variant="contained" size="large">
              Agregar
            </Button>
          </Link>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        <Grid item xs={12}>
          <TableAccountsReceivable />
        </Grid>
      </Grid>
    </>
  );
};

export default AccountsReceivable;
