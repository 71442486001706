import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm } from "react-hook-form";
import TypeClientsContext from "../../../context/TypeClients/TypeClientsContext";
import { useContext } from "react";
import { useEffect } from "react";

export default function AddTypeClient({ handleClickOpen, handleClose, modal }) {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const { success, AddTypeClients } = useContext(TypeClientsContext);
  useEffect(() => {
    if (success) {
      setValue("name", "", { shouldDirty: true });
    }
  }, [success]);

  const onSubmit = (data, e) => {
    AddTypeClients(data);
    handleClose();
  };
  return (
    <div>
      <Dialog open={modal} onClose={handleClose}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter")
              e.preventDefault();
          }}
        >
          <DialogTitle>Tipo de cliente para ventas fisicas</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              id="name"
              name="name"
              label="nombre"
              sx={{ marginTop: 2 }}
              variant="outlined"
              error={errors.name ? true : false}
              helperText={errors?.name?.message}
              {...register("name", {
                required: {
                  value: true,
                  message: "Este campo es requerido",
                },
                minLength: {
                  value: 1,
                  message: "Minimo 1 caracteres",
                },
                maxLength: {
                  value: 255,
                  message: "Maximo 255 caracteres",
                },
              })}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              variant="contained"
              sx={{
                backgroundColor: "red",
                color: "white",
                "&:hover": { backgroundColor: "red", color: "white" },
              }}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              //onClick={handleClose}
              variant="contained"
              sx={{
                backgroundColor: "#0d47a1",
                color: "white",
                "&:hover": { backgroundColor: "#0d47a1", color: "white" },
              }}
            >
              Guardar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
