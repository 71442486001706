import React, { useContext, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import TypeUsersContext from "../../context/TypeUsers/TypeUsersContext";
import { Fab, Grid, Box, CardContent, Card, Typography } from "@mui/material";
import MethodGet from "../../config/Service";
import { makeStyles } from "@mui/styles";
import { useForm } from "react-hook-form";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import Swal from "sweetalert2";
import NewPermissions from "./NewPermissions";
import ModulesCOntext from "../../context/Permissions/PermissionsContext";
const useStyles = makeStyles(() => ({
  card: {
    marginTop: 7,
    marginLeft: 7,
    marginRight: 7,
  },
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 2,
  },
}));

export default function AgregatePermissions(props) {
  const classes = useStyles();
  const { success, GetPermissions } = useContext(ModulesCOntext);
  const { AddPermissions } = useContext(TypeUsersContext);
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  useEffect(() => {
    GetPermissions();
  }, [success]);

  let TypeUserStorageID = JSON.parse(sessionStorage.getItem("typeuser"));
  const [typeUser, SaveTypeUser] = useState({});

  useEffect(() => {
    let url = `/type_users/${TypeUserStorageID}`;
    MethodGet(url)
      .then((res) => {
        SaveTypeUser(res.data.type_users);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
      });
  }, [success]);

  const { handleSubmit } = useForm();
  const onSubmit = (data, e) => {
    data.modules = selectedPermissions;
    data.id = TypeUserStorageID;
    console.log(data);
    AddPermissions(data);
    props.history.goBack();
  };

  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#000000",
              textAling: "center",
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
              margin: 2,
            }}>
            <div className={classes.titleCard}>Asignacion de permisos </div>
          </Box>
          <form
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => {
              if (e.code === "Enter") e.preventDefault();
            }}
            autoComplete="off">
            <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Card>
                    <CardContent>
                      <Grid>
                        <Typography
                          variant="button"
                          display="block"
                          gutterBottom>
                          <b>Información del tipo de usuario</b>
                        </Typography>
                        <Typography
                          variant="overline"
                          display="block"
                          gutterBottom>
                          <b>Tipo de usuario: {typeUser.name}</b>
                        </Typography>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <NewPermissions
                    selectedPermissions={selectedPermissions}
                    setSelectedPermissions={setSelectedPermissions}
                  />
                </Grid>
                {selectedPermissions.length > 0 && (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                        textAling: "center",
                      }}>
                      <div className={classes.fab}>
                        <Fab
                          type="submit"
                          variant="extended"
                          sx={{
                            backgroundColor: "#00B9FF",
                            color: "white",
                            "&:hover": {
                              backgroundColor: "#00DEED",
                              color: "white",
                            },
                          }}>
                          <SaveOutlinedIcon sx={{ mr: 1 }} />
                          Guardar
                        </Fab>
                      </div>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
            <CardContent></CardContent>
          </form>
        </Grid>
      </Grid>
    </Layout>
  );
}
