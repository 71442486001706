import React from "react";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Fragment } from "react";
import { Grid } from "@mui/material";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { EditIcon } from "./../../icons/EditIcon";
import { DeleteIcon } from "./../../icons/DeleteIcon";
import { useContext } from "react";
import ProvidersContext from "../../../context/Provider/ProviderContext";

const ProvidersCard = ({ provider }) => {
  const { DeleteProvider } = useContext(ProvidersContext);

  return (
    <Fragment>
      <CardContent sx={{ boxShadow: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Typography fontWeight="bold">Nombre proveedor:</Typography>
          </Grid>
          <Grid
            display="flex"
            justifyContent="inherit"
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
            xl={6}
          >
            <Typography>{provider.name}</Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Typography fontWeight="bold">Compania:</Typography>
          </Grid>
          <Grid
            display="flex"
            justifyContent="inherit"
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
            xl={6}
          >
            <Typography>{provider.company}</Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Typography fontWeight="bold">Telefono:</Typography>
          </Grid>
          <Grid
            display="flex"
            justifyContent="inherit"
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
            xl={6}
          >
            <Typography>{provider.phone}</Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Typography fontWeight="bold">Limite de credito:</Typography>
          </Grid>
          <Grid
            display="flex"
            justifyContent="inherit"
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
            xl={6}
          >
            <Typography>$ {provider.credit_limit}</Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Typography fontWeight="bold">RFC:</Typography>
          </Grid>
          <Grid
            display="flex"
            justifyContent="inherit"
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
            xl={6}
          >
            <Typography>{provider.rfc}</Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Typography fontWeight="bold">Dirección:</Typography>
          </Grid>
          <Grid
            display="flex"
            justifyContent="inherit"
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
            xl={6}
          >
            <Typography>{provider.direction}</Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Typography fontWeight="bold">CIF:</Typography>
          </Grid>
          <Grid
            display="flex"
            justifyContent="inherit"
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
            xl={6}
          >
            <a href={provider.constancy} target="__blank">
              Ver
            </a>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            display="flex"
            justifyContent="space-between"
          >
            {/* <Link to={`/DetalleProveedor/${provider._id}`}>
              <Button>
                <DisplaySettingsIcon />
              </Button>
            </Link>
            <Button>
              <AddIcon />
            </Button> */}
            <Link to={`/ActualizarProveedor/${provider._id}`}>
              <Button>
                <EditIcon />
              </Button>
            </Link>
            <Button onClick={() => DeleteProvider(provider._id)}>
              <DeleteIcon />
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Fragment>
  );
};

export default ProvidersCard;
