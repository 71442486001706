import React, { Fragment } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import AccountsContext from "../../context/Accounts/AccountsContext";
import Select from "react-select";
export default function SelectAccountFisic(props) {
  const { accounts_fisic, GetAccountsFisic } = useContext(AccountsContext);

  useEffect(() => {
    GetAccountsFisic();
  }, []);
  const selectStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };
  const detectarCambiosAccountFisic = (value) => {
    props.detectarCambiosAccountFisic(value);
  };
  const nuevoArreglo = accounts_fisic.map((account) => {
    const accountName = `${account.beneficiary} - ${account.account_number}`;
    return { value: account._id, label: accountName };
  });
  return (
    <Fragment>
      <Select
        fullwith
        styles={selectStyles}
        onChange={(value) => detectarCambiosAccountFisic(value)}
        //className="basic-single"
        classNamePrefix="select"
        name="account"
        placeholder="Selecciona caja fisica"
        options={nuevoArreglo}
        // options={accounts_fisic.map((account) => {
        //   const accountName = `${account.beneficiary} - ${account?.number_account}`;
        //   // let attribute = {
        //   //   label: data,
        //   //   number_account: account.number_account,
        //   //   typeAccount: account.typeAccount,
        //   //   value: account._id,
        //   // };
        //   return { value: account._id, label: accountName };
        // })}
      />
    </Fragment>
  );
}
