import { CURRENT_ABOUT, UPDATE_ABOUT, GET_ALL_ABOUT } from "../../types";

export default function AboutReducer(state, action) {
  switch (action.type) {
    case GET_ALL_ABOUT:
      return {
        ...state,
        about: action.payload,
        success: false,
        ErrorsAPI: [],
      };
    case CURRENT_ABOUT:
      return {
        ...state,
        about: action.payload,
      };
    case UPDATE_ABOUT:
      return {
        ...state,
        success: true,
        ErrorsAPI: [],
      };

    default:
      return state;
  }
}
