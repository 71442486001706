import { InputLabel, MenuItem, Select, FormControl } from "@mui/material";
export default function SelectStock(props) {
  const options = [
    { value: "2", name: "Productos con bajo Stock" },
    { value: "1", name: "Productos con alto Stock" },
  ];

  const detectarCambiosStock = (e) => {
    props.detectarCambiosStock(e);
  };

  return (
    <FormControl fullWidth>
      <InputLabel>Selecciona una opción</InputLabel>
      <Select
        label="Selecciona una opción"
        onChange={detectarCambiosStock}
        defaultValue={1}
      >
        {options.map((option, index) => (
          <MenuItem value={option.value} key={index}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
