import React from "react";
import TableOnly from "../../components/Tables/Table";

const SalesNotInvoiced = () => {
  const columns = [
    { name: "Folio Venta" },
    { name: "Cliente" },
    { name: "Telefono" },
    { name: "Total Venta" },
    { name: "Gastos de envio" },
    { name: "Opciones" },
  ];
  return <TableOnly columns={columns} />;
};

export default SalesNotInvoiced;
