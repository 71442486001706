import React, { useContext, useEffect } from "react";
import UsersContext from "../../context/Users/UsersContext";
import UserCard from "../../components/Cards/users/UserCard";
import NoDataComponents from "../../components/loading/NoDataComponents";
import { Grid } from "@mui/material/";
import ModulesContext from "../../context/Permissions/PermissionsContext";

const Users = () => {
  const { usersLocked, GetUsersBlocked, success } = useContext(UsersContext);
  const { permissionsPerUser, GetModulesPerUser } = useContext(ModulesContext);

  useEffect(() => {
    if (sessionStorage.getItem("module_id")) {
      const permiss = sessionStorage.getItem("module_id");
      GetModulesPerUser(permiss);
    }
    GetUsersBlocked();
  }, [success]);

  return (
    <Grid container spacing={2}>
      {!usersLocked.length ? (
        <NoDataComponents />
      ) : (
        usersLocked.map((user) => (
          <Grid key={user.id} item xs={12} sm={12} md={3} lg={3} xl={3}>
            <UserCard permiss={permissionsPerUser} user={user} />
          </Grid>
        ))
      )}
    </Grid>
  );
};
export default Users;
