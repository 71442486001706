import React from "react";
import { useReducer } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import MethodGet, { MethodPut } from "../../config/Service";
import {
	CURRENT_SHIPPMENT_INTERNATIONAL,
	GET_SHIPPMENT_INTERNATIONAL,
	SHOW_ERRORS_API,
	UPDATE_SHIPPMENT_INTERNATIONAL,
} from "../../types";
import ShippmentInternationalContext from "./ShippmentInternationalContext";
import ShippmentInternationalReducer from "./ShippmentInternationalReducer";

const ShippmentInternationalState = ({ children }) => {
	//estado inicial
	const initialState = {
		shippment_inter: {},
		shippment_int: null,
		ErrorsApi: [],
		success: false,
	};

	const [state, dispatch] = useReducer(
		ShippmentInternationalReducer,
		initialState
	);

	const GetShippmentInternational = () => {
		sessionStorage.removeItem("shippment_int");
		let url = "/administrable/shipping-costs";
		MethodGet(url)
			.then((res) => {
				dispatch({
					type: GET_SHIPPMENT_INTERNATIONAL,
					payload: res.data.shippingCosts,
				});
			})
			.catch((error) => {
				dispatch({
					type: SHOW_ERRORS_API,
				});
			});
	};

	const UpdateShippmentInternational = (shippment) => {
		const formData = new FormData();
		formData.append("shippingCosts", shippment.shippingCosts);

		let url = "/administrable/shipping-costs";

		MethodPut(url, formData)
			.then((res) => {
				Swal.fire({
					title: "Actualizada",
					text: res.data.message,
					icon: "success",
					timer: 1500,
					showConfirmButton: false,
				});
				dispatch({
					type: UPDATE_SHIPPMENT_INTERNATIONAL,
				});
			})
			.catch((error) => {
				dispatch({
					type: SHOW_ERRORS_API,
					payload: error.response.data.message,
				});
			});
	};

	const SaveCurrentShippmentInternational = (shippment_int) => {
		sessionStorage.setItem("shippment_int", JSON.stringify(shippment_int));
		dispatch({
			type: CURRENT_SHIPPMENT_INTERNATIONAL,
			payload: shippment_int,
		});
	};
	return (
		<ShippmentInternationalContext.Provider
			value={{
				shippment_inter: state.shippment_inter,
				shippment_int: state.shippment_int,
				ErrorsApi: state.ErrorsApi,
				success: state.success,
				GetShippmentInternational,
				UpdateShippmentInternational,
				SaveCurrentShippmentInternational,
			}}
		>
			{children}
		</ShippmentInternationalContext.Provider>
	);
};

export default ShippmentInternationalState;
