import { Button, Grid, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useContext } from "react";
import ProductsContext from "../../context/Products/ProductsContext";
import SelectBrandMostSale from "./BrandSelectMostSale";
import SelectQuantity from "../../containers/SelectOptions/SelectQuantity";
import { useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import esLocale from "date-fns/locale/es";
import { formatDateNow, formatDateSelect } from "../../utils/FormatDate";
/**new pickers */
import { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers";
export default function ProductsLessSold() {
  const { ExportProductsLessSold } = useContext(ProductsContext);
  const [brand, saveBrand] = useState(null);
  const [quantity, saveQuantity] = useState(null);

  const detectarCambiosQuantity = (e) => {
    saveQuantity(e);
  };
  const detectarCambiosBrand = (e) => {
    saveBrand(e.target.value);
  };
  //fecha
  const [valueDesktopDatePickerSelected, setValueDesktopDatePickerSelected] =
    useState(new Date());
  const MINDATENOW = formatDateNow(valueDesktopDatePickerSelected);
  const [pickerFecini, SetpickerIni] = useState(null);
  const [pickerFecter, SetpickerTer] = useState(null);
  //datepicker
  const [fecha_inicio, setFechaInicio] = useState(null);
  const [fecha_fin, setFechaFin] = useState(null);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const onSubmit = (data, e) => {
    if (fecha_inicio === null) {
      Swal.fire({
        title: "Error",
        text: "Selecciona la fecha inicio",
        timer: 1500,
        icon: "error",
        // showConfirmButton: fa
      });
      return;
    }
    if (fecha_fin === null) {
      Swal.fire({
        title: "Error",
        text: "Selecciona la fecha fin",
        timer: 1500,
        icon: "error",
        // showConfirmButton: fa
      });
      return;
    }
    data.start_date = formatDateSelect(fecha_inicio);
    data.end_date = formatDateSelect(fecha_fin);
    data.brand = brand;
    // data.limit = quantity;
    ExportProductsLessSold(data);
  };

  return (
    <>
      <Typography
        textAlign="center"
        variant="h4"
        fontFamily="monospace"
        sx={{ marginBottom: 2 }}>
        Productos menos vendidos
      </Typography>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.code === "Enter" || e.code === "NumpadEnter")
            e.preventDefault();
        }}
        autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ mb: 2 }}>
            <SelectBrandMostSale detectarCambiosBrand={detectarCambiosBrand} />
          </Grid>
          {/* <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <SelectQuantity detectarCambiosQuantity={detectarCambiosQuantity} />
        </Grid> */}
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            {" "}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={3}>
                <DesktopDatePicker
                  label="Desde la fecha"
                  name="start_date"
                  todayText="Hoy"
                  showTodayButton={true}
                  disableFuture
                  okText="Aceptar"
                  cancelText="Cancelar"
                  value={pickerFecini}
                  onChange={(newValue) => {
                    SetpickerIni(newValue);
                    setFechaInicio(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={3}>
                <DesktopDatePicker
                  label="Hasta la fecha"
                  name="end_date"
                  todayText="Hoy"
                  showTodayButton={true}
                  disableFuture
                  okText="Aceptar"
                  cancelText="Cancelar"
                  value={pickerFecter}
                  onChange={(newValue) => {
                    SetpickerTer(newValue);
                    setFechaFin(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            display="flex"
            justifyContent="end">
            <Button
              variant="contained"
              type="submit"
              //onClick={() => ExportProductsMostSold()}
              sx={{
                backgroundColor: "#1b5e20",
                color: "white",
                "&:hover": {
                  backgroundColor: "#1b5e20",
                },
              }}>
              Productos menos Vendidos
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
