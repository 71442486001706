import React, { useContext, useEffect, useState } from "react";
import AccountsContext from "../../../context/Accounts/AccountsContext";
import BankSelect from "../../SelectOptions/BankSelect";
import MethodGet from "../../../config/Service";
import { useForm } from "react-hook-form";
import SelectTypeAccount from "../../SelectOptions/SelectTypeAccount";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  TextField,
  Tooltip,
} from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import Swal from "sweetalert2";
import SpinnerComponent from "../../../components/loading/SpinnerComponent";

const useStyles = makeStyles({
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 10,
  },
  input: {
    display: "none",
  },
});

export default function Edit(props) {
  const classes = useStyles();
  const { modalUpdate, handleCloseEdit, id } = props;
  const [bankId, saveBank] = useState("");
  const [typeAccount, saveTypeAccount] = useState(null);
  const { UpdateAccount } = useContext(AccountsContext);
  const [cargando, spinnerCargando] = useState(false);

  const [accounts, SaveAccount] = useState({});
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({ defaultValues: accounts });
  const detectarCambiosBank = (e) => {
    saveBank(e.target.value);
  };
  const detectarCambiosTypeAccount = (e) => {
    saveTypeAccount(e.target.value);
  };

  useEffect(() => {
    let url = `/bank-accounts/${id}`;
    MethodGet(url)
      .then((res) => {
        spinnerCargando(true);
        SaveAccount(res.data.bankAccount);
        saveBank(res.data.bankAccount.bank._id);

        // saveTypeAccount(res.data.bankAccount.typeAccount);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
        spinnerCargando(true);
      });
  }, [id]);
  useEffect(() => {
    if (accounts) {
      saveTypeAccount(accounts.typeAccount);
      setValue("account_number", accounts.account_number);
      setValue("interbank", accounts.interbank);
      setValue("beneficiary", accounts.beneficiary);
      setValue("rfc", accounts.rfc);
      setValue("card_number", accounts.card_number);
    } else {
      setValue("account_number", "");
      setValue("interbank", "");
      setValue("beneficiary", "");
      setValue("rfc", "");
      setValue("card_number", "");
    }
  }, [accounts]);

  const onSubmit = (data, e) => {
    if (!bankId) {
      Swal.fire({
        title: "Error",
        timer: 2000,
        showConfirmButton: false,
        text: "Selecciona un banco",
        icon: "error",
      });
    } else {
      data.id = id;
      data.typeAccount = typeAccount;
      data.bank = bankId;
      UpdateAccount(data);
      handleCloseEdit();
    }

    // // e.preventDefault();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={modalUpdate}
      onClose={handleCloseEdit}>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#000000",
            textAling: "center",
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            borderRadius: "0 0 24px 0",
          }}>
          <div className={classes.titleCard}>Actualizar cuenta bancaria</div>
          <div className={classes.fab}>
            <Tooltip title="cerrar" arrow>
              <Fab
                size="small"
                onClick={handleCloseEdit}
                sx={{
                  color: "#FFFFFF",
                  backgroundColor: "#FF0000",
                  "&:hover": {
                    color: "#FFFFFF",
                    backgroundColor: "#FF0000",
                  },
                }}>
                <CancelPresentationIcon />
              </Fab>
            </Tooltip>
          </div>
        </Box>
      </DialogTitle>
      {!cargando ? (
        <SpinnerComponent />
      ) : (
        <DialogContent>
          <form
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter")
                e.preventDefault();
            }}
            noValidate>
            <Box sx={{ display: "flex", paddingTop: 4, p: 3 }}>
              <Grid container spacing={2}>
                {typeAccount && (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <SelectTypeAccount
                      TypeAccount={typeAccount}
                      detectarCambiosTypeAccount={detectarCambiosTypeAccount}
                    />
                  </Grid>
                )}

                {bankId !== "" && (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <BankSelect
                      bankId={bankId}
                      detectarCambiosBank={detectarCambiosBank}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    fullWidth
                    focused
                    id="account_number"
                    name="account_number"
                    label="Numero de Cuenta"
                    variant="outlined"
                    error={errors.account_number ? true : false}
                    helperText={errors?.account_number?.message}
                    {...register("account_number", {
                      required: {
                        value: true,
                        message: "Este campo es requerido",
                      },
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    fullWidth
                    focused
                    id="interbank"
                    name="interbank"
                    label="Clabe interbancaria"
                    variant="outlined"
                    error={errors.interbank ? true : false}
                    helperText={errors?.interbank?.message}
                    {...register("interbank", {
                      required: {
                        value: true,
                        message: "Este campo es requerido",
                      },
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    fullWidth
                    id="card_number"
                    name="card_number"
                    label="Tarjeta Asociada"
                    variant="outlined"
                    error={errors.card_number ? true : false}
                    helperText={errors?.card_number?.message}
                    {...register("card_number", {
                      required: {
                        value: true,
                        message: "Este campo es requerido",
                      },
                      maxLength: {
                        value: 16,
                        message:
                          "El numero de tarjeta debe contener 16 digitos",
                      },
                      minLength: {
                        value: 16,
                        message:
                          "El numero de tarjeta debe contener 16 digitos",
                      },
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    fullWidth
                    focused
                    id="name"
                    name="beneficiary"
                    label="Beneficiario"
                    variant="outlined"
                    type="text"
                    sx={{ marginBottom: 2 }}
                    error={errors.beneficiary ? true : false}
                    helperText={errors?.beneficiary?.message}
                    {...register("beneficiary", {
                      required: {
                        value: true,
                        message: "Este campo es requerido",
                      },
                      minLength: {
                        value: 2,
                        message: "Minimo 2 caracteres para este campo",
                      },
                      maxLength: {
                        value: 50,
                        message: "Haz superado el limite de caracteres, 50 max",
                      },
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      textAling: "center",
                    }}>
                    <div className={classes.fab}>
                      <Fab
                        variant="extended"
                        type="submit"
                        sx={{
                          backgroundColor: "#00B9FF",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#00DEED",
                            color: "white",
                          },
                        }}>
                        <SaveOutlinedIcon sx={{ mr: 1 }} />
                        Guardar
                      </Fab>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </form>
        </DialogContent>
      )}
    </Dialog>
  );
}
