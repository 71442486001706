import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AccountsReceivableContext from "../../../context/AccountsReceivable/AccountsReceivableContext";
import { useContext, useEffect } from "react";
import { PriceFormat } from "./../../../utils/FormatPrice";
import { IconButton, Tooltip } from "@mui/material";
import AddCardIcon from "@mui/icons-material/AddCard";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SpinnerComponent from "./../../../components/loading/SpinnerComponent";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function TableAccountsReceivable() {
  const {
    GetAccountsReceivable,
    accountsReceivable,
    downloadPdfAllAccountsReceivable,
  } = useContext(AccountsReceivableContext);
  useEffect(() => {
    GetAccountsReceivable();
  }, []);
  console.log(accountsReceivable, "las cuentas");
  return (
    <>
      {accountsReceivable ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Cliente</StyledTableCell>
                <StyledTableCell align="center">RFC</StyledTableCell>
                <StyledTableCell align="center">
                  Total de cuenta
                </StyledTableCell>
                <StyledTableCell align="center">Total cobrado</StyledTableCell>
                <StyledTableCell align="center">opciones</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accountsReceivable.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell align="center">
                    {row.fullname}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.SATDirection?.tax_id}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    $ {PriceFormat(row.total)}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    $ {PriceFormat(row.totalPayment)}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Tooltip placement="top" title="Agregar cuenta">
                      <Link to={`/AddAccountToClient/${row._id}`}>
                        <IconButton>
                          <AddCardIcon sx={{ color: "blueviolet" }} />
                        </IconButton>
                      </Link>
                    </Tooltip>
                    <Tooltip placement="top" title="Detalle cuenta">
                      <Link to={`/DetalleCuentaXCobrar/${row._id}`}>
                        <IconButton>
                          <VisibilityIcon sx={{ color: "#2e7d32" }} />
                        </IconButton>
                      </Link>
                    </Tooltip>
                    <Tooltip title="Generar Ticket de cuentas del cliente">
                      <IconButton
                        onClick={() => downloadPdfAllAccountsReceivable(row)}>
                        <PictureAsPdfIcon sx={{ color: "red" }} />
                      </IconButton>
                    </Tooltip>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <SpinnerComponent />
      )}
    </>
  );
}
