import React, { useReducer } from "react";
import BusinessRulesReducer from "./BusinessRulesReducer";
import BusinessRulesContext from "./BusinessRulesContext";
import MethodGet, {
  MethodDelete,
  MethodPost,
  MethodPut,
} from "../../config/Service";
import {
  ADD_BUSINESS_RULES,
  CURRENT_BUSINESS_RULES,
  DELETE_BUSINESS_RULES_SALES,
  DELETE_BUSINESS_RULES_SHIPPMENT,
  DELETE_BUSINESS_RULES_SUBCATEGORY,
  GET_ALL_BUSINESS_RULES_SALES,
  GET_ALL_BUSSINESS_RULES_SUBCATEGORY,
  GET_ALL_BUSINESS_RULES_SHIPPMENT,
  GET_ALL_BUSSINESS_RULES_CATEGORY,
  DELETE_BUSSINESS_RULES_CATEGORY,
  SHOW_ERRORS_API,
  UPDATE_BUSINESS_RULES,
  ADD_BUSINESS_RULE_SUBCATEGORY,
  ADD_BUSINESS_RULE_CATEGORY,
  ADD_BUSINESS_RULE_SALES_FISIC,
  GET_BUSINESS_RULE_SALES_FISIC,
  DELETE_BUSSINESS_RULES_SALES_FISIC,
  UPDATE_BUSINESS_RULES_CATEGORY,
  UPDATE_BUSINESS_RULES_SUBCATEGORY,
  UPDATE_BUSINESS_RULES_SALES_FISIC,
  ADD_BUSINESS_RULE_SHIPPMENT,
  UPDATE_BUSINESS_RULES_SHIPPMENT,
} from "../../types";
import Swal from "sweetalert2";
const BusinessRulesState = ({ children }) => {
  //estado inicial
  const initialState = {
    business_rules_sales: [],
    business_rules_subcategory: [],
    business_rules_shippment: [],
    business_rules_category: [],
    business_rules_sales_fisic: [],
    business_rule: null,
    business_rule_sale_fisic: null,
    business_rule_subcategory: null,
    business_rule_category: null,
    ErrorsApi: [],
    success: false,
  };

  const [state, dispatch] = useReducer(BusinessRulesReducer, initialState);
  //obtener la lista
  const GetBusinessRulesSales = () => {
    sessionStorage.removeItem("business_rule");
    let url = `/business-rules/sales/index`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_BUSINESS_RULES_SALES,
          payload: res.data.business_rules,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
      });
  };
  const GetBusinessRulesSubcategory = () => {
    sessionStorage.removeItem("business_rule_subcategory");
    let url = `/business-rules/subcategory/index`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_BUSSINESS_RULES_SUBCATEGORY,
          payload: res.data.business_rules,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
      });
  };
  const GetBusinessRulesCategory = () => {
    sessionStorage.removeItem("business_rule_category");
    let url = `/business-rules/category/index`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_BUSSINESS_RULES_CATEGORY,
          payload: res.data.business_rules,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
      });
  };
  const GetBusinessRulesShippemnt = () => {
    sessionStorage.removeItem("business_rule");
    let url = `/business-rules/shippment`;
    MethodGet(url)
      .then((res) => {
        console.log(res.data);
        dispatch({
          type: GET_ALL_BUSINESS_RULES_SHIPPMENT,
          payload: res.data.business_rules,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
      });
  };
  const GetBusinessRulesSalesFisic = () => {
    sessionStorage.removeItem("business_rule_sale_fisic");
    let url = `/sales-business-rules`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_BUSINESS_RULE_SALES_FISIC,
          payload: res.data.businessRules,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
      });
  };

  //funcion para agregar regla
  const AddBusinessRules = (business_rule) => {
    const formData = new FormData();
    formData.append("minimum_money", business_rule.minimum_money);
    formData.append("maximum_money", business_rule.maximum_money);
    formData.append("type", business_rule.type);
    formData.append("discount", business_rule.discount);

    let url = `/business-rules`;
    MethodPost(url, formData)
      .then((res) => {
        Swal.fire({
          title: "Regla de negocio registrada",
          text: res.data.message,
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        dispatch({
          type: ADD_BUSINESS_RULES,
          payload: res.data.business_rule,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.res.data.error,
        });
      });
  };
  const AddBusinessRulesSubcategory = (business_rule) => {
    const formData = new FormData();
    formData.append("type", business_rule.type);
    formData.append("subcategory", business_rule.subcategory);
    formData.append("discount", business_rule.discount);

    let url = `/business-rules`;
    MethodPost(url, formData)
      .then((res) => {
        Swal.fire({
          title: "Regla de negocio registrada",
          text: res.data.message,
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        dispatch({
          type: ADD_BUSINESS_RULE_SUBCATEGORY,
          payload: res.data.business_rule,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.res.data.error,
        });
      });
  };
  const AddBusinessRulesCategory = (business_rule) => {
    const formData = new FormData();
    formData.append("type", business_rule.type);
    formData.append("category", business_rule.category);
    formData.append("discount", business_rule.discount);

    let url = `/business-rules`;
    MethodPost(url, formData)
      .then((res) => {
        Swal.fire({
          title: "Regla de negocio registrada",
          text: res.data.message,
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        dispatch({
          type: ADD_BUSINESS_RULE_CATEGORY,
          payload: res.data.business_rule,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.res.data.error,
        });
      });
  };
  const AddBusinessRulesSalesFisic = (business_rule) => {
    const formData = new FormData();
    formData.append("type", business_rule.type);
    formData.append("discount", business_rule.discount);
    formData.append("minimum_money", business_rule.minimum_money);
    formData.append("maximum_money", business_rule.maximum_money);
    formData.append("customer_type", business_rule.customerType);

    let url = `/business-rules`;
    MethodPost(url, formData)
      .then((res) => {
        Swal.fire({
          title: "Regla de negocio registrada",
          text: res.data.message,
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        dispatch({
          type: ADD_BUSINESS_RULE_SALES_FISIC,
          payload: res.data.business_rule,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.res.data.error,
        });
      });
  };
  const AddBusinessRulesShippment = (business_rule) => {
    let data = {
      minimum_weigth: Number(business_rule.minimum_weigth),
      maximum_weigth: Number(business_rule.maximum_weigth),
      shippment_costs: Number(business_rule.shipping_cost),
      type_direction: Number(business_rule.typeDirection),
    };

    let url = `/business-rules/shippment`;
    MethodPost(url, data)
      .then((res) => {
        Swal.fire({
          title: "Registrada",
          text: res.data.message,
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        dispatch({
          type: ADD_BUSINESS_RULE_SHIPPMENT,
          payload: res.data.business_rule,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.res.data.error,
        });
      });
  };

  //funcion para editar regla de negocio
  const UpdateBusinessRules = (business_rule) => {
    const formData = new FormData();

    formData.append("minimum_money", business_rule.minimum_money);
    formData.append("maximum_money", business_rule.maximum_money);
    formData.append("type", business_rule.type);
    formData.append("discount", business_rule.discount);

    let url = `/business-rules/${business_rule.id}`;
    MethodPut(url, formData)
      .then((res) => {
        Swal.fire({
          title: "Regla de negocio Actualizada",
          text: res.data.message,
          timer: 1500,
          showConfirmButton: false,
          icon: "success",
        });
        dispatch({
          type: UPDATE_BUSINESS_RULES,
          payload: res.data.business_rule,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.error,
        });
      });
  };
  const UpdateBusinessRulesSalesFisic = (business_rule) => {
    const formData = new FormData();
    formData.append("minimum_money", business_rule.minimum_money);
    formData.append("maximum_money", business_rule.maximum_money);
    formData.append("type", business_rule.type);
    formData.append("discount", business_rule.discount);
    formData.append("customer_type", business_rule.customerType);

    let url = `/business-rules/${business_rule.id}`;
    MethodPut(url, formData)
      .then((res) => {
        Swal.fire({
          title: "Regla de negocio Actualizada",
          text: res.data.message,
          timer: 1500,
          showConfirmButton: false,
          icon: "success",
        });
        dispatch({
          type: UPDATE_BUSINESS_RULES_SALES_FISIC,
          payload: res.data.business_rule,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.res.data.error,
        });
      });
  };
  const UpdateBusinessRulesSubcategory = (business_rule) => {
    const formData = new FormData();
    formData.append("type", business_rule.type);
    formData.append("subcategory", business_rule.subcategory);
    formData.append("discount", business_rule.discount);

    let url = `/business-rules/${business_rule.id}`;
    MethodPut(url, formData)
      .then((res) => {
        Swal.fire({
          title: "Regla de negocio Actualizada",
          text: res.data.message,
          timer: 1500,
          showConfirmButton: false,
          icon: "success",
        });
        dispatch({
          type: UPDATE_BUSINESS_RULES_SUBCATEGORY,
          payload: res.data.business_rule,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.res.data.error,
        });
      });
  };
  const UpdateBusinessRulesCategory = (business_rule) => {
    const formData = new FormData();
    formData.append("type", business_rule.type);
    formData.append("category", business_rule.category);
    formData.append("discount", business_rule.discount);
    let url = `/business-rules/${business_rule.id}`;
    MethodPut(url, formData)
      .then((res) => {
        Swal.fire({
          title: "Regla de negocio Actualizada",
          text: res.data.message,
          timer: 1500,
          showConfirmButton: false,
          icon: "success",
        });
        dispatch({
          type: UPDATE_BUSINESS_RULES_CATEGORY,
          payload: res.data.business_rule,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.res.data.error,
        });
      });
  };
  const UpdateBusinessRulesShippment = (business_rule) => {
    let data = {
      minimum_weigth: Number(business_rule.minimum_weigth),
      maximum_weigth: Number(business_rule.maximum_weigth),
      shippment_costs: Number(business_rule.shipping_cost),
      type_direction: Number(business_rule.typeDirection),
    };
    let url = `/business-rules/shippment/${business_rule.id}`;
    MethodPut(url, data)
      .then((res) => {
        Swal.fire({
          title: "Regla de negocio Actualizada",
          text: res.data.message,
          timer: 1500,
          showConfirmButton: false,
          icon: "success",
        });
        dispatch({
          type: UPDATE_BUSINESS_RULES_SHIPPMENT,
          payload: res.data.business_rule,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.res.data.error,
        });
      });
  };

  //eliminar regla
  const DeleteBusinessRulesSales = (id) => {
    Swal.fire({
      title: "¿Estas Seguro?",
      text: "La regla de negocio seleccionada sera eliminada",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/business-rules/${id}`;

        MethodDelete(url)
          .then((res) => {
            Swal.fire({
              title: "Eliminado",
              text: res.data.message,
              icon: "success",
              timer: 2000,
              showConfirmButton: false,
            });
            dispatch({
              type: DELETE_BUSINESS_RULES_SALES,
              payload: id,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              timer: 1500,
              showConfirmButton: false,
            });
          });
      }
    });
  };

  const DeleteBusinessRulesSubcategory = (id) => {
    Swal.fire({
      title: "¿Estas Seguro?",
      text: "La regla de negocio seleccionada sera eliminada",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/business-rules/${id}`;

        MethodDelete(url)
          .then((res) => {
            Swal.fire({
              title: "Eliminado",
              text: res.data.message,
              icon: "success",
              timer: 2000,
              showConfirmButton: false,
            });
            dispatch({
              type: DELETE_BUSINESS_RULES_SUBCATEGORY,
              payload: id,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              timer: 1500,
              showConfirmButton: false,
            });
          });
      }
    });
  };

  const DeleteBusinessRulesCategory = (id) => {
    Swal.fire({
      title: "¿Estas Seguro?",
      text: "La regla de negocio seleccionada sera eliminada",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/business-rules/${id}`;
        MethodDelete(url)
          .then((res) => {
            Swal.fire({
              title: "Eliminado",
              text: res.data.message,
              icon: "success",
              timer: 2000,
              showConfirmButton: false,
            });
            dispatch({
              type: DELETE_BUSSINESS_RULES_CATEGORY,
              payload: id,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              timer: 1500,
              showConfirmButton: false,
            });
          });
      }
    });
  };

  const DeleteBusinessRulesShippment = (id) => {
    Swal.fire({
      title: "¿Estas Seguro?",
      text: "La regla de negocio seleccionada sera eliminada",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/business-rules/${id}`;

        MethodDelete(url)
          .then((res) => {
            Swal.fire({
              title: "Eliminado",
              text: res.data.message,
              icon: "success",
              timer: 2000,
              showConfirmButton: false,
            });
            dispatch({
              type: DELETE_BUSINESS_RULES_SHIPPMENT,
              payload: id,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              timer: 1500,
              showConfirmButton: false,
            });
          });
      }
    });
  };

  const DeleteBusinessRulesSalesFisic = (id) => {
    Swal.fire({
      title: "¿Estas Seguro?",
      text: "La regla de negocio seleccionada sera eliminada",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/sales-business-rules/${id}`;
        MethodPost(url)
          .then((res) => {
            Swal.fire({
              title: "Eliminado",
              text: res.data.message,
              icon: "success",
              timer: 2000,
              showConfirmButton: false,
            });
            dispatch({
              type: DELETE_BUSSINESS_RULES_SALES_FISIC,
              payload: id,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              timer: 1500,
              showConfirmButton: false,
            });
          });
      }
    });
  };

  //funcion para seleccionar una regla
  const SaveCurrentBusinessRules = (business_rule) => {
    sessionStorage.setItem("business_rule", JSON.stringify(business_rule));
    dispatch({
      type: CURRENT_BUSINESS_RULES,
      payload: business_rule,
    });
  };

  return (
    <BusinessRulesContext.Provider
      value={{
        business_rules_sales: state.business_rules_sales,
        business_rules_subcategory: state.business_rules_subcategory,
        business_rules_category: state.business_rules_category,
        business_rules_shippment: state.business_rules_shippment,
        business_rules_sales_fisic: state.business_rules_sales_fisic,
        business_rule: state.business_rule,
        business_rule_subcategory: state.business_rule_subcategory,
        business_rule_category: state.business_rule_category,
        business_rule_sale_fisic: state.business_rule_sale_fisic,
        ErrorsApi: state.ErrorsApi,
        success: state.success,
        GetBusinessRulesSales,
        GetBusinessRulesSalesFisic,
        GetBusinessRulesSubcategory,
        GetBusinessRulesShippemnt,
        GetBusinessRulesCategory,
        AddBusinessRules,
        AddBusinessRulesCategory,
        AddBusinessRulesSalesFisic,
        AddBusinessRulesSubcategory,
        UpdateBusinessRules,
        UpdateBusinessRulesSalesFisic,
        UpdateBusinessRulesSubcategory,
        UpdateBusinessRulesCategory,
        SaveCurrentBusinessRules,
        DeleteBusinessRulesSubcategory,
        DeleteBusinessRulesSales,
        DeleteBusinessRulesShippment,
        DeleteBusinessRulesCategory,
        DeleteBusinessRulesSalesFisic,
        AddBusinessRulesShippment,
        UpdateBusinessRulesShippment,
      }}
    >
      {children}
    </BusinessRulesContext.Provider>
  );
};

export default BusinessRulesState;
