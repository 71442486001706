import React, { useContext, useEffect } from "react";
import Layout from "../../components/layout/Layout";
import ModulesContext from "../../context/Permissions/PermissionsContext";
import OffersContext from "../../context/Offers/OffersContext";
import OffersCard from "../../components/Cards/offers/OffersCard";
import NoDataComponents from "../../components/loading/NoDataComponents";
import { Link } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import { Grid, Box, Button } from "@mui/material/";

const useStyles = makeStyles({
  titleCard: {
    color: "white",
    fontSize: "35px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 2,
    marginRight: 5,
  },
});

export default function Offers() {
  const classes = useStyles();
  const { offers, GetOffers, success } = useContext(OffersContext);
  const { permissionsPerUser, GetModulesPerUser } = useContext(ModulesContext);

  useEffect(() => {
    if (localStorage.getItem("module_id")) {
      const permiss = localStorage.getItem("module_id");
      GetModulesPerUser(permiss);
    }
    GetOffers();
  }, [success]);

  return (
    <Layout>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#000000",
              textAling: "center",
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            }}>
            <div className={classes.titleCard}>Ofertas</div>
            {permissionsPerUser.write === true && (
              <div className={classes.fab}>
                <Link to="/agrega-oferta">
                  <Button
                    sx={{
                      color: "black",
                      backgroundColor: "#26c6da",
                      hoverColor: "white",
                      fontFamily: "monospace",
                      fontWeight: "bolder",
                      fontSize: "15px",
                      textTransform: "none",
                      "&:hover": {
                        color: "black",
                        backgroundColor: "#67cedb",
                        hoverColor: "white",
                        fontFamily: "monospace",
                        fontWeight: "bolder",
                        fontSize: "15px",
                      },
                    }}>
                    Agregar
                  </Button>
                </Link>
              </div>
            )}
          </Box>
          <Box sx={{ width: "100%", paddingTop: 4, p: 3, marginBottom: 10 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={2}>
                  {!offers.length ? (
                    <NoDataComponents />
                  ) : (
                    offers.map((offer, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        xl={4}
                        key={index}>
                        <OffersCard
                          permiss={permissionsPerUser}
                          offer={offer}
                        />
                      </Grid>
                    ))
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
}
