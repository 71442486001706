import React, { useReducer } from "react";
import Swal from "sweetalert2";
import MethodGet, { MethodPost, MethodPut } from "../../config/Service";
import headerConfig from "../../config/imageHeaders";

import {
  CURRENT_ORDER,
  GET_ALL_SHIPPING_ACEPTED,
  GET_ALL_SHIPPING_DISPUTED,
  GET_ALL_SHIPPING_PENDING,
  GET_ALL_SHIPPING_SHIPPED,
  MAKE_AS_DISPUTE,
  REMOVE_FROM_DISPUTE,
  SHOW_ERRORS_API,
  UPDATE_SHIPPING,
  WAREHOUSE_SHIPPING_ACCEPT_ORDER,
  WAREHOUSE_SHIPPING_SHIPPED_ORDER,
} from "../../types";
import WarehouseContext from "./WarehouseContext";
import WarehouseReducer from "./WarehouseReducer";
import fileDownload from "js-file-download";

const WarehouseState = ({ children }) => {
  const initialState = {
    shippingsPending: [],
    shippingsAccepted: [],
    shippingsShipped: [],
    shippingsDisputed: [],
    shipping: null,
    ErrorsApi: [],
    success: false,
  };

  const [state, dispatch] = useReducer(WarehouseReducer, initialState);

  //obtner la lista de ordenes pendientes de aceptar
  const GetWarehousePending = (params) => {
    sessionStorage.removeItem("shipping");
    //params.pages += 1;
    let url = `/warehouse-shipping/status/pending?limit=${params.limite}&page=${params.pages}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_SHIPPING_PENDING,
          payload: {
            shippingsPending: res.data.shippings,
            totalDocs: res.data.totalDocs,
            limit: res.data.limit,
            page: res.data.page,
            paginCounter: res.data.paginCounter,
            hasPrevPage: res.data.hasPrevPage,
            hasNextPage: res.data.hasNextPage,
            prevPage: res.data.prevPage,
            nextPage: res.data.nextPage,
            totalPages: res.data.totalPages,
          },
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
      });
  };

  const GetWarehouseAccepted = (params) => {
    sessionStorage.removeItem("shipping");
    //params.pages += 1;
    let url = `/warehouse-shipping/status/accepted?limit=${params.limite}&page=${params.pages}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_SHIPPING_ACEPTED,
          payload: {
            shippingsAccepted: res.data.shippings,
            totalDocs: res.data.totalDocs,
            limit: res.data.limit,
            page: res.data.page,
            paginCounter: res.data.paginCounter,
            hasPrevPage: res.data.hasPrevPage,
            hasNextPage: res.data.hasNextPage,
            prevPage: res.data.prevPage,
            nextPage: res.data.nextPage,
            totalPages: res.data.totalPages,
          },
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
      });
  };

  const GetWarehouseShipped = (params) => {
    sessionStorage.removeItem("shipping");
    //params.pages += 1;
    let url = `/warehouse-shipping/status/shipped?limit=${params.limite}&page=${params.pages}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_SHIPPING_SHIPPED,
          payload: {
            shippingsShipped: res.data.shippings,
            totalDocs: res.data.totalDocs,
            limit: res.data.limit,
            page: res.data.page,
            paginCounter: res.data.paginCounter,
            hasPrevPage: res.data.hasPrevPage,
            hasNextPage: res.data.hasNextPage,
            prevPage: res.data.prevPage,
            nextPage: res.data.nextPage,
            totalPages: res.data.totalPages,
          },
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
      });
  };
  const GetWarehouseDisputed = (params) => {
    let url = `/warehouse-shipping/status/disputes?limit=${params.limite}&page=${params.pages}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_SHIPPING_DISPUTED,
          payload: {
            shippingsDisputed: res.data.shippings,
            totalDocs: res.data.totalDocs,
            limit: res.data.limit,
            page: res.data.page,
            paginCounter: res.data.paginCounter,
            hasPrevPage: res.data.hasPrevPage,
            hasNextPage: res.data.hasNextPage,
            prevPage: res.data.prevPage,
            nextPage: res.data.nextPage,
            totalPages: res.data.totalPages,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const NotifyClientSms = (shipp) => {
    console.log(shipp, "el state");
    Swal.fire({
      title: "Notificar al cliente",
      text: "Se enviara un sms al cliente para notificarle del riesgo de su compra",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Aceptar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        const formData = new FormData();
        formData.append("prefix", shipp.order_id.client_id.phone.prefix);
        formData.append("phone", shipp.order_id.client_id.phone.phone_number);
        formData.append("folio", shipp.order_id.folio);

        let url = "/warehouse-shipping/NotifyClient";
        Swal.fire({
          title: "Enviando...!",
          showConfirmButton: false,
          showLoading: true,
        });
        Swal.showLoading();
        MethodPost(url, formData)
          .then((res) => {
            Swal.fire({
              title: "Enviado",
              text: res.data.message,
              icon: "success",
              timer: 2000,
              showConfirmButton: false,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              timer: 1500,
              showConfirmButton: false,
            });
          });
      }
    });
  };

  const TakeOrder = (id) => {
    Swal.fire({
      title: "Tomar Pedido",
      text: "Se tomara el pedido Seleccionado",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Aceptar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/warehouse-shipping/accept/${id}`;
        MethodPost(url)
          .then((res) => {
            Swal.fire({
              title: "Aceptado",
              text: "El pedido seleccionado, se ha tomado correctamente, ahora lo podras ver en la seccion de pedidos Aceptados",
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            dispatch({
              type: WAREHOUSE_SHIPPING_ACCEPT_ORDER,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              timer: 2000,
              showConfirmButton: false,
            });
          });
      }
    });
  };

  const MakeAsDispute = (id) => {
    Swal.fire({
      title: "Marcar como venta en disputa",
      text: "Se tomara el pedido Seleccionado",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Aceptar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/warehouse-shipping/MakeAsDispute`;
        const formData = new FormData();
        formData.append("shippment_id", id);
        MethodPost(url, formData)
          .then((res) => {
            dispatch({
              type: MAKE_AS_DISPUTE,
              payload: id,
            });
            Swal.fire({
              title: "En disputa",
              text: "El pedido seleccionado, se ha enviado a pedidos en disputa",
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              timer: 2000,
              showConfirmButton: false,
            });
          });
      }
    });
  };

  const RemoveFromDispute = (id) => {
    Swal.fire({
      title: "Borrar de disputa",
      text: "El pedido se quitara de pedidos en disputa para poder enviarse",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Aceptar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/warehouse-shipping/RemoveFromDispute`;
        const formData = new FormData();
        formData.append("shippment_id", id);
        MethodPost(url, formData)
          .then((res) => {
            dispatch({
              type: REMOVE_FROM_DISPUTE,
              payload: id,
            });
            Swal.fire({
              title: "Listo para enviarse",
              text: "El pedido seleccionado, se ha quitado de pedidos en disputa y ahora esta listo para enviarse",
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              timer: 2000,
              showConfirmButton: false,
            });
          });
      }
    });
  };

  const ShippOrder = (order) => {
    const formData = new FormData();
    formData.append("no_guide", order.no_guide);
    formData.append("shipment_id", order.shipment_id);
    formData.append("pdf", order.pdf);
    formData.append("comment", order.comments);
    let url = `/warehouse-shipping/shipp/${order.id}`;
    Swal.fire({
      title: "Enviando...!",
      showConfirmButton: false,
      showLoading: true,
    });
    Swal.showLoading();
    MethodPost(url, formData, { headerConfig })
      .then((res) => {
        Swal.fire({
          title: "Pedido Enviado",
          text: res.data.message,
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
          showCancelButton: false,
        });
        dispatch({
          type: WAREHOUSE_SHIPPING_SHIPPED_ORDER,
        });
      })
      .catch((error) => {
        Swal.mixin({
          toast: true,
          background: "red",
          color: "white",
          iconColor: "white",
          position: "top-end",
          showConfirmButton: false,
          timer: 2400,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        }).fire({
          title: "Error",
          icon: "error",
          text: error.response.data.message,
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.res.data.error,
        });
      });
  };

  const PdfOrder = (order) => {
    Swal.fire({
      title: "¿Descargar PDF de la orden?",
      text: "Se descargará el pdf de la orden",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, descargar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/orders/create-pdf/${order._id}`;
        MethodGet(url, { responseType: "blob" })
          .then((res) => {
            let timerInterval;
            Swal.fire({
              title: "Generando",
              html: "El PDF se esta generando!",
              timer: 2000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const b = Swal.getHtmlContainer().querySelector("b");
                timerInterval = setInterval(() => {
                  b.textContent = Swal.getTimerLeft();
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              fileDownload(res.data, `Orden_${order.folio}.pdf`);
              Swal.fire({
                title: "Descargado",
                text: "El PDF de la orden se ha descargado correctamente",
                icon: "success",
                timer: 1500,
                showConfirmButton: false,
              });
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              icon: "error",
              text: "Se ha producido un error al descargar el PDF",
            });
            dispatch({
              type: SHOW_ERRORS_API,
            });
          });
      }
    });
  };

  const UpdateShipping = (data) => {
    Swal.fire({
      title: "¿Estas seguro de actualizar la guía?",
      text: "Se actualizará, la informacion de envio del pedido seleccionado",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Actualizar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        const formData = new FormData();
        formData.append("no_guide", data.no_guide);
        formData.append("shipment_id", data.shipment_id);
        if (data.pdf) {
          formData.append("pdf", data.pdf);
        }
        let url = `/warehouse-shipping/${data.id}`;
        MethodPut(url, formData, { headerConfig })
          .then((res) => {
            Swal.fire({
              title: "Actualizado",
              text: res.data.message,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            dispatch({
              type: UPDATE_SHIPPING,
              payload: res.data.shipping,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              showConfirmButton: false,
              timer: 2000,
            });
          });
      }
    });
  };

  const SaveCurrentOder = (shipping) => {
    sessionStorage.setItem("shipping", JSON.stringify(shipping));
    dispatch({
      type: CURRENT_ORDER,
      payload: shipping,
    });
  };

  return (
    <WarehouseContext.Provider
      value={{
        shippingsPending: state.shippingsPending,
        shippingsAccepted: state.shippingsAccepted,
        shippingsShipped: state.shippingsShipped,
        shippingsDisputed: state.shippingsDisputed,
        shipping: state.shipping,
        ErrorsApi: state.ErrorsApi,
        success: state.success,
        totalDocs: state.totalDocs,
        limit: state.limit,
        page: state.page,
        paginCounter: state.paginCounter,
        hasPrevPage: state.hasPrevPage,
        prevPage: state.prevPage,
        hasNextPage: state.hasNextPage,
        nextPage: state.nextPage,
        totalPages: state.totalPages,
        GetWarehousePending,
        GetWarehouseAccepted,
        GetWarehouseShipped,
        TakeOrder,
        ShippOrder,
        SaveCurrentOder,
        PdfOrder,
        UpdateShipping,
        GetWarehouseDisputed,
        NotifyClientSms,
        RemoveFromDispute,
        MakeAsDispute,
      }}
    >
      {children}
    </WarehouseContext.Provider>
  );
};

export default WarehouseState;
