import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CircleIcon from "@mui/icons-material/Circle";
import invoicesContext from "../../context/Invoices/InvoicesContext";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import SpinnerComponent from "../../components/loading/SpinnerComponent";
import { PriceFormat } from "../../utils/FormatPrice";
import { Paginate } from "../../components/Pagination/Paginate";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function TableRequest() {
  const {
    CancelInvoice,
    GetRequestForCancelInvoice,
    requestCancel,
    totalPages,
  } = useContext(invoicesContext);

  var [pages, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const limite = rowsPerPage;
  const params = { limite, pages };
  useEffect(() => {
    GetRequestForCancelInvoice(params);
  }, [rowsPerPage, pages]);
  console.log(totalPages);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {requestCancel ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Folio venta</StyledTableCell>
                  <StyledTableCell align="center">cliente</StyledTableCell>
                  <StyledTableCell align="center">Total Venta</StyledTableCell>
                  <StyledTableCell align="center">
                    Gastos de envios
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Motivo de cancelacion
                  </StyledTableCell>
                  <StyledTableCell align="center">Opciones</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {requestCancel.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="center">
                      {row.folio}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.client_id.fullname}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      $ {PriceFormat(row.total)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      $ {PriceFormat(row.shippment)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.motive_invoice_canceled === "01" ? (
                        <Typography>01: Errores con relacion</Typography>
                      ) : row.motive_invoice_canceled === "02" ? (
                        <Typography>02 : Errores sin relacion</Typography>
                      ) : row.motive_invoice_canceled === "03" ? (
                        <Typography>
                          {" "}
                          03: No se llevo a cabo la operación
                        </Typography>
                      ) : (
                        row.motive_invoice_canceled === "04" && (
                          <Typography>
                            {"04: "}
                            Operacion nominativa, relacionada en una factura
                            global
                          </Typography>
                        )
                      )}
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      <Tooltip title="Aprobar Cancelacion" placement="top">
                        <IconButton onClick={() => CancelInvoice(row._id)}>
                          <CheckCircleOutlineIcon sx={{ color: "green" }} />
                        </IconButton>
                      </Tooltip>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <SpinnerComponent />
        )}
      </Grid>
      {requestCancel.length > 0 && (
        <Grid item xs={12} display="flex" justifyContent="center">
          <Paginate
            pages={pages}
            totalPages={totalPages}
            handleChangePage={handleChangePage}
          />
        </Grid>
      )}
    </Grid>
  );
}
