import * as React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import MethodGet from "../../config/Service";
import { useState } from "react";
import { useEffect } from "react";
import { Box, Card, Grid, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Marquee from "react-fast-marquee";
import CategoriesContext from "../../context/CategoriesP/CategoriesContext";
import { useContext } from "react";
import NoDataComponents from "../../components/loading/NoDataComponents";
import ShopProductCard from "../../components/Cards/products/ProductCard";
import ModalImage from "react-modal-image-responsive";
import { Fade } from "react-slideshow-image";
import CardP from "../../components/Cards/products/Card";
import NoResultsComponent from "../../components/loading/NoResultsComponent";
import Swal from "sweetalert2";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  titleProduct: {
    color: "white",
  },
  ojito: {
    color: "red",
  },
  imgproduct: {
    top: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
  },
});

export default function ProductsPerCategorieModal(props) {
  const classes = useStyles();
  const { modalProductsCategory, handleCloseModal, id, name } = props;
  const [cargando, spinnerCargando] = useState(false);
  const [products, saveProduct] = useState({});
  const { multimedia } = products;

  //const [productsFilter, setProducts] = useState({});
  const [FilterP, setFilter] = useState();
  const [magicWord, setMagicWord] = useState("");

  useEffect(() => {
    let url = `/products/category/${id}`;
    MethodGet(url)
      .then((res) => {
        saveProduct(res.data.products);
        spinnerCargando(true);
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          text: error.response.data.message,
          icon: "error",
        });
        spinnerCargando(true);
      });
  }, [id]);

  const handleSearchProduct = ({ target }) => {
    setMagicWord(target.value);
    const palabra = target.value;
    try {
      let resultFilter = products.filter((p) =>
        p.name.toString().toLowerCase().includes(palabra.toLowerCase())
      );
      setFilter(resultFilter);
    } catch (error) {}
  };

  useEffect(() => {
    setFilter(products);
  }, []);

  return (
    <Dialog
      fullScreen
      open={modalProductsCategory}
      onClose={handleCloseModal}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{ position: "relative" }}
        style={{ backgroundColor: "black" }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseModal}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Productos de categoría: {name}
          </Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container spacing={2}>
            {!products.length ? (
              <></>
            ) : (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  fullWidth
                  label="Buscar producto"
                  placeholder="Buscar producto"
                  style={{ marginBottom: 15 }}
                  onChange={handleSearchProduct}
                />
              </Grid>
            )}

            {magicWord &&
              (!FilterP.length ? (
                <NoResultsComponent />
              ) : (
                FilterP.map((product) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    lg={3}
                    xl={3}
                    key={product._id}
                  >
                    <CardP product={product} />
                  </Grid>
                ))
              ))}

            {!magicWord &&
              (!products.length ? (
                <NoDataComponents />
              ) : (
                products.map((product) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    lg={3}
                    xl={3}
                    key={product._id}
                  >
                    <CardP product={product} />
                  </Grid>
                ))
              ))}
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}
