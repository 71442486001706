import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import MethodGet from "../../config/Service";
import ModalImage from "react-modal-image-responsive";
import Swal from "sweetalert2";
import LoadingComponent from "../loading/SpinnerComponent";

const useStyles = makeStyles({
  titleProduct: {
    color: "white",
  },
  ojito: {
    color: "red",
  },
  img: {
    width: 350,
    height: 350,
  },
  imgproduct: {
    top: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
  },
  input: {
    height: "30px",
    border: "0px",
    borderColor: "white",
  },
  jss59: {
    width: "100%",
    border: "1px solid #bbb",
    height: "15%",
    outline: "none",
    padding: "15px 10px",
    marginLeft: "10px",
    borderRadius: "10px",
  },
  jss54: {
    color: "#fff",
    width: "100%",
    border: "0",
    margin: "5px 8px",
    padding: "10px",
    fontSize: "17px",
    marginTop: "20px",
    borderRadius: "4px",
    backgroundColor: "#e91e63",
    "&:hover": {
      color: "white",
      backgroundColor: "#e91e63",
      //hoverColor: "white",
      //fontFamily: "monospace",
      //fontWeight: "bolder",
      //fontSize: "15px",
    },
  },
});
export default function CardProduct({
  id,
  productList,
  saveProductList,
  // product,
  // saveProduct,
}) {
  const classes = useStyles();
  const [product, saveProduct] = useState();
  const [cargando, spinnerCargando] = useState();
  //obtener la informacion del producto
  useEffect(() => {
    let url = `/products/admin/${id}`;
    MethodGet(url)
      .then((res) => {
        saveProduct(res.data.product);
        spinnerCargando(true);
      })
      .catch((error) => {
        spinnerCargando(true);
      });
  }, [id]);
  //const { name, price } = product;
  const [producto, guardarProducto] = useState({});
  const [cantidad, guardarCantidad] = useState(0);

  const AgregarProductoState = () => {
    if (cantidad > product.quantity) {
      Swal.fire({
        title: "Error",
        text: "El stock del producto es insuficiente!",
        icon: "error",
        timer: 2500,
        showConfirmButton: false,
      });
      return;
    }
    if (cantidad === 0) {
      Swal.fire({
        title: "Error",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
        html: `<p>La cantidad debe de ser mayor a <b>0</b> pzas,  pero no mayor a <b style="color: red;">${product.quantity}</b> pzas</p>`,
      });
      return;
    }
    let productoResultado = producto;
    productoResultado.product_id = id;
    productoResultado.stock = Number(product.quantity);
    productoResultado.cantidad = Number(cantidad);
    productoResultado.price_unit = Number(product.price);
    productoResultado.final_price = Number(cantidad) * Number(product.price);
    productoResultado.name = product.name;
    productoResultado.brand = product.brand.name;
    productoResultado.category = product.category.name;
    productoResultado.image = product.multimedia[0].path;
    productoResultado.product_type = product.product_type;
    productoResultado.discount = product.discount;

    var exist = productList.filter((p) => p.product_id === id);
    if (exist.length) {
      Swal.fire({
        title: "No permitido",
        icon: "error",
        text: "El producto ya ha sido agregado a la venta, por favor modifica la cantidad si deseas agregar más productos!",
        timer: 2000,
        showConfirmButton: false,
      });
      return;
    }

    saveProductList([...productList, productoResultado]);
    Swal.fire({
      title: "Agregado",
      icon: "success",
      text: "el producto se agrego a la venta correctamente!",
      timer: 1000,
      showConfirmButton: false,
    });
    guardarProducto({});
    //saveProduct("");
    guardarCantidad(0);
  };

  return (
    <Grid container spacing={2}>
      {!cargando ? (
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          display="flex"
          justifyContent="center"
        >
          <LoadingComponent />
        </Grid>
      ) : (
        <>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            display="flex"
            justifyContent="center"
          >
            <ModalImage
              className={classes.img}
              small={product.multimedia[0].path}
              large={product.multimedia[0].path}
              alt={product.name}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingX: 2,
                paddingY: 2,
                marginBottom: 1,
              }}
            >
              <div>
                <Typography sx={{ fontWeight: "bold", fontSize: "17px" }}>
                  {product.name}
                </Typography>
              </div>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingX: 2,
                paddingY: 2,
                marginBottom: 1,
              }}
            >
              <div>
                <Typography sx={{ fontWeight: "bold", fontSize: "17px" }}>
                  Precio:
                </Typography>
              </div>
              <div>
                <Typography sx={{ fontSize: "14px" }}>
                  $ {product.price.toFixed(2)}
                </Typography>
              </div>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingX: 2,
                paddingY: 2,
                marginBottom: 1,
              }}
            >
              <div>
                <Typography sx={{ fontWeight: "bold", fontSize: "17px" }}>
                  Stock:
                </Typography>
              </div>
              <div style={{ color: "green" }}>
                {product.quantity + " Disponibles!"}
              </div>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingX: 2,
                paddingY: 2,
                marginBottom: 1,
              }}
            >
              <div>
                <Typography sx={{ fontWeight: "bold", fontSize: "17px" }}>
                  Cantidad:
                </Typography>
              </div>
              <div>
                <input
                  type="number"
                  //onChange={detectarCambiosCantidadComprada}
                  className={classes.jss59}
                  placeholder="Agrega la Cantidad Inicial"
                  name="quantityProduct"
                  min="1"
                  value={cantidad}
                  onChange={(e) => guardarCantidad(e.target.value)}
                />
              </div>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingX: 2,
                paddingY: 2,
                marginBottom: 1,
              }}
            >
              <div>
                <Button
                  sx={{
                    color: "#fff",
                    width: "100%",
                    border: "0",
                    margin: "5px 8px",
                    padding: "10px",
                    fontSize: "17px",
                    marginTop: "20px",
                    borderRadius: "4px",
                    backgroundColor: "#e91e63",
                    "&:hover": {
                      color: "white",
                      backgroundColor: "#e91e63",
                      //hoverColor: "white",
                      //fontFamily: "monospace",
                      //fontWeight: "bolder",
                      //fontSize: "15px",
                    },
                  }}
                  size="large"
                  //type="submit"
                  variant="contained"
                  onClick={AgregarProductoState}

                  //onClick={clickAdd(product.id)}
                >
                  Agregar producto
                </Button>
              </div>
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );
}
