import React, { useContext, useEffect, useState } from "react";
import FaqCategoriesContext from "../../context/FaqCategories/FaqCategoriesContext";
import MethodGet from "../../config/Service";
import { useForm } from "react-hook-form";

import { makeStyles } from "@mui/styles";
import {
  Grid,
  Box,
  Fab,
  TextField,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import Swal from "sweetalert2";

const useStyles = makeStyles({
  titleCard: {
    color: "white",
    fontSize: "20px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 10,
  },
});

export default function Edit(props) {
  const classes = useStyles();
  const { modalUpdate, handleCloseEdit, id } = props;
  const { UpdateFaq } = useContext(FaqCategoriesContext);
  const [Faq, SaveFaq] = useState({});
  //   const [cargando, spinnerCargando] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  //

  useEffect(() => {
    let url = `/faq-category/${id}`;
    MethodGet(url)
      .then((res) => {
        SaveFaq(res.data.faqCategory);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
      });
  }, [id]);

  useEffect(() => {
    if (Faq) {
      setValue("name", Faq.name);
    } else {
      setValue("name", "");
    }
  }, [Faq]);

  const onSubmit = (data, e) => {
    // e.preventDefault();
    data.id = id;
    UpdateFaq(data);
    handleCloseEdit();
  };
  return (
    <Dialog open={modalUpdate} onClose={handleCloseEdit}>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#000000",
            textAling: "center",
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            borderRadius: "0 0 24px 0",
          }}>
          <div className={classes.titleCard}>
            Editar Categoria de Pregunta Frecuente
          </div>
          <div className={classes.fab}>
            <Tooltip title="cerrar" arrow>
              <Fab
                size="small"
                onClick={handleCloseEdit}
                sx={{
                  color: "#FFFFFF",
                  backgroundColor: "#FF0000",
                  "&:hover": {
                    color: "#FFFFFF",
                    backgroundColor: "#FF0000",
                  },
                }}>
                <CancelPresentationIcon />
              </Fab>
            </Tooltip>
          </div>
        </Box>
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter")
              e.preventDefault();
          }}
          autoComplete="off">
          <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  focused
                  fullWidth
                  id="name"
                  name="name"
                  label="Define la categoria de la pregunta"
                  variant="outlined"
                  error={errors.name ? true : false}
                  helperText={errors?.name?.message}
                  {...register("name", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                    minLength: {
                      value: 4,
                      message: "Minimo 4 caracteres",
                    },
                    maxLength: {
                      value: 255,
                      message: "Maximo 255 caracteres",
                    },
                  })}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    textAling: "center",
                  }}>
                  <div className={classes.fab}>
                    <Fab
                      type="submit"
                      variant="extended"
                      sx={{
                        backgroundColor: "#00B9FF",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#00DEED",
                          color: "white",
                        },
                      }}>
                      <SaveOutlinedIcon sx={{ mr: 1 }} />
                      Guardar
                    </Fab>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}
