import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FormatDate from "../../utils/FormatDate";
import { PriceFormat } from "../../utils/FormatPrice";
import ModalImage from "react-modal-image-responsive";
import { makeStyles } from "@mui/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const useStyles = makeStyles({
  img: {
    width: 200,
    height: 150,
  },
});

export default function Payments({ payments }) {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Fecha</StyledTableCell>
            <StyledTableCell align="center">Cantidad</StyledTableCell>
            <StyledTableCell align="center">Cuenta bancaria</StyledTableCell>
            <StyledTableCell align="center">Comprobante</StyledTableCell>
            <StyledTableCell align="center">Forma de pago</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payments.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row" align="center">
                {FormatDate(row.createdAt)}
              </StyledTableCell>
              <StyledTableCell align="center">
                $ {PriceFormat(row.amount)}
              </StyledTableCell>
              <StyledTableCell align="center">
                {row.bank_account_id
                  ? row.bank_account_id.account_number
                  : "N/A"}
              </StyledTableCell>
              <StyledTableCell align="center">
                {row.image ? (
                  <ModalImage
                    small={row.image}
                    large={row.image}
                    className={classes.img}
                  />
                ) : (
                  "N/A"
                )}
              </StyledTableCell>
              <StyledTableCell align="center">
                {row.type === 1
                  ? "Efectivo"
                  : row.type === 2
                  ? "Tranferencia bancaria"
                  : row.type === 3 && "Tarjeta de crédito y/o débito"}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
