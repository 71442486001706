import React, { useContext, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import { Grid, Chip, Box, Divider, Typography, Button } from "@mui/material";
import CardUserLogged from "../../components/Dashboard/UserLoged";
import TableUserDistribuidor from "../../components/Dashboard/UserDistribuidor/TableUserDistribuidor";
import TableBussinessRules from "../../components/Dashboard/TableBussinessRules";
import ModalCotizations from "../../components/Dashboard/UserDistribuidor/ModalCotizations";
import ClientSelect from "../../containers/SelectOptions/SelectClients";
import CustomizedTables from "./Indexcotizations";
export default function Cotizations() {
  const [modal, abrirModal] = useState(false);
  const [id_client, setClientId] = useState(null);
  const detectarCambiosClient = (value) => {
    setClientId(value.value);
  };

  return (
    <>
      <Grid container spacing={2} sx={{ padding: 1 }}>
        {/* <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <TableBussinessRules />
        </Grid> */}
        {/* <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
        </Grid> */}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          display="flex"
          justifyContent="center"
        >
          <Typography variant="h4" fontWeight="bold" fontFamily="monospace">
            Realizar nueva cotización
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ClientSelect detectarCambiosClient={detectarCambiosClient} />
          <Typography textAlign="center" fontWeight="bold" variant="button">
            Nota: Para conocer los precios de los productos con descuentos es
            necesario generar una cotización, después dando click en el botón
            ver mis cotizaciones realizadas
          </Typography>
          <TableUserDistribuidor id_client={id_client} />
        </Grid>
      </Grid>
      <ModalCotizations modal={modal} />
    </>
  );
}
