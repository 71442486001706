import { Box, Chip, Divider, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import AccountSelectFisic from "../../containers/SelectOptions/AccountsSelectFisic";
export default function InputCash({
  detectarCambiosAccountFisic,
  handleChangePaymentCash,
  paymentCash,
}) {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        paddingTop: 2,
        p: 1,
        marginBottom: 1,
      }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Divider sx={{ marginBottom: 1 }}>
            <Chip
              label={
                <Typography sx={{ fontWeight: "bold" }}>Efectivo</Typography>
              }
            />
          </Divider>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <label>Seleccionar caja: </label>
            <AccountSelectFisic
              detectarCambiosAccountFisic={detectarCambiosAccountFisic}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <label sx={{ marginBottom: 1 }}>Ingresa Monto (Efectivo)</label>
            <TextField
              onChange={handleChangePaymentCash}
              defaultValue={paymentCash}
              //className={classes.jss59}
              fullWidth
              id="outlined-number"
              label="Monto "
              placeholder="Cantidad a pagar"
              type="text"
              name="amount_cash"
              min="0"
              // error={errors.amount ? true : false}
              // helperText={errors?.amount?.message}
              // {...register("amount", {
              //   required: {
              //     value: true,
              //     message: "Este campo es requerido",
              //   },
              // })}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
