import { MIDDLEWARE_TOGGLE, MIDDLEWARE, ERROR_MIDDLEWARE } from "../../types";

export default function SettingsReducer(state, action) {
  switch (action.type) {
    case MIDDLEWARE:
      return {
        ...state,
        maintainment: action.payload,
      };

    case MIDDLEWARE_TOGGLE:
      return {
        ...state,
        maintainment: !state.maintainment,
      };
    case ERROR_MIDDLEWARE:
      return {
        ...state,
        maintainment: action.payloads,
      };

    default:
      return state;
  }
}
