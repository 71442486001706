import { Button, Grid } from "@mui/material";
import TableGlobalInvoice from "./TableGlobalInvoice";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";

import invoicesContext from "../../context/Invoices/InvoicesContext";
import SelectMethodPayment from "../SelectOptions/SelectMethodPayment";
import NoDataComponents from "../../components/loading/NoDataComponents";
const InvoiceGlobal = () => {
  const { globalInvoice, GetSalesForGlobalInvoice } =
    useContext(invoicesContext);
  const [paymentMethod, savePaymentMethod] = useState(null);

  const detectarCambiosPaymentMethods = (e) => {
    savePaymentMethod(e);
  };
  const { handleSubmit } = useForm();
  const onSubmit = (data) => {
    data.paymentForm = paymentMethod;
    GetSalesForGlobalInvoice(data);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={9} md={9} lg={9} xl={9} sm={9}>
        <SelectMethodPayment
          detectarCambiosPaymentMethods={detectarCambiosPaymentMethods}
        />
      </Grid>
      <Grid
        item
        xs={3}
        md={3}
        lg={3}
        xl={3}
        sm={3}
        display="flex"
        justifyContent="center">
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter")
              e.preventDefault();
          }}
          autoComplete="off">
          <Button type="submit" variant="contained" size="large">
            Consultar
          </Button>
        </form>
      </Grid>
      {globalInvoice.length > 0 ? (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TableGlobalInvoice
            orders={globalInvoice}
            paymentMethod={paymentMethod}
          />
        </Grid>
      ) : (
        <NoDataComponents />
      )}
    </Grid>
  );
};

export default InvoiceGlobal;
