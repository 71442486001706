import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import Layout from "../../components/layout/Layout";
import SelectMunicipality from "../SelectOptions/SelectMunicipality";
import SelectState from "../SelectOptions/SelectState";
import { TextField } from "@mui/material/";
import { useForm } from "react-hook-form";
import ProviderContext from "../../context/Provider/ProviderContext";
import Swal from "sweetalert2";
import { makeStyles } from "@mui/styles";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useContext } from "react";
import { useEffect } from "react";
import MethodGet from "../../config/Service";
import SpinnerComponent from "../../components/loading/SpinnerComponent";
const useStyles = makeStyles({
  logoimagen: {
    height: "200px",
    boxShadow: "5px 5px #289FED",
    objectFit: "cover",
  },
  input: {
    display: "none",
  },
  card: {
    marginTop: 7,
    marginLeft: 7,
    marginRight: 7,
  },
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 10,
  },
});
const UpdateProviders = (props) => {
  const id = props.match.params.id;
  const classes = useStyles();
  /**Obtener informacion del proveedor */
  const [cargando, spinnerCargando] = useState(false);
  const [provider, saveProvider] = useState(null);
  const [state, saveState] = useState(null);
  const [municipality, saveMunicipality] = useState(null);
  const detectarCambiosState = (e) => {
    saveState(e);
  };
  const detectarCambiosMunicipality = (e) => {
    saveMunicipality(e);
  };
  const [file_first, setFile] = useState({
    file: "",
  });
  const handleChangeFile = (e) => {
    setFile({
      ...file_first,
      file: e.target.files[0],
    });
  };
  useEffect(() => {
    let url = `/providers/${id}`;
    MethodGet(url)
      .then((res) => {
        saveProvider(res.data.provider);
        saveState(res.data.provider.state._id);
        saveMunicipality(res.data.provider.municipality._id);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
        spinnerCargando(true);
      });
  }, [id]);
  const { UpdateProvider } = useContext(ProviderContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const onSubmit = (data, e) => {
    if (state === null) {
      Swal.fire({
        title: "Error",
        text: "selecciona un estado para continuar",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }
    if (municipality === null) {
      Swal.fire({
        title: "Error",
        text: "Selecciona un municipio para continuar",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }
    data.state = state;
    data.id = id;
    data.municipality = municipality;
    data.constancy = file_first.file;
    //console.log(data);
    UpdateProvider(data);
    props.history.goBack();
  };

  return (
    <Layout>
      {!cargando ? (
        <SpinnerComponent />
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter")
              e.preventDefault();
          }}
          autoComplete="off">
          <Grid container spacing={2} sx={{ padding: 2 }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ backgroundColor: "black", color: "white" }}>
              <Typography fontWeight="bold" fontSize="30px">
                Actualizar información del Proveedor
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                label="Proveedor"
                name="name"
                type="text"
                fullWidth
                variant="outlined"
                defaultValue={provider.name}
                error={errors.name ? true : false}
                helperText={errors?.name?.message}
                {...register("name", {
                  required: {
                    value: true,
                    message: "Este campo es requerido",
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                label="Compania"
                name="company"
                type="text"
                fullWidth
                variant="outlined"
                defaultValue={provider.company}
                error={errors.company ? true : false}
                helperText={errors?.company?.message}
                {...register("company", {
                  required: {
                    value: true,
                    message: "Este campo es requerido",
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                label="Telefono"
                name="phone"
                type="tel"
                fullWidth
                variant="outlined"
                defaultValue={provider.phone}
                error={errors.phone ? true : false}
                helperText={errors?.phone?.message}
                {...register("phone", {
                  required: {
                    value: true,
                    message: "Este campo es requerido",
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                label="RFC"
                name="rfc"
                type="text"
                fullWidth
                variant="outlined"
                defaultValue={provider.rfc}
                error={errors.rfc ? true : false}
                helperText={errors?.rfc?.message}
                {...register("rfc", {
                  required: {
                    value: true,
                    message: "Este campo es requerido",
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                label="Direccion"
                name="direction"
                type="text"
                fullWidth
                variant="outlined"
                defaultValue={provider.direction}
                error={errors.direction ? true : false}
                helperText={errors?.direction?.message}
                {...register("direction", {
                  required: {
                    value: true,
                    message: "Este campo es requerido",
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                label="Limite de credito"
                name="credit_limit"
                type="number"
                fullWidth
                variant="outlined"
                defaultValue={provider.credit_limit}
                error={errors.credit_limit ? true : false}
                helperText={errors?.credit_limit?.message}
                {...register("credit_limit", {
                  required: {
                    value: true,
                    message: "Este campo es requerido",
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <SelectState
                detectarCambiosState={detectarCambiosState}
                state_id={state}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <SelectMunicipality
                detectarCambiosMunicipality={detectarCambiosMunicipality}
                state_id={state}
                municipality_id={municipality}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              display="flex"
              justifyContent="center">
              <Button
                variant="contained"
                component="label"
                startIcon={<AttachFileIcon />}
                onChange={handleChangeFile}
                sx={{
                  color: "white",
                  backgroundColor: "#ad1457",
                  "&:hover": { backgroundColor: "#ad1457", color: "white" },
                }}>
                Adjuntar Constancia de situacion fiscal
                <input hidden accept="application/pdf" multiple type="file" />
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              display="flex"
              justifyContent="center">
              <Typography variant="overline" display="block" gutterBottom>
                Archivo Seleccionado: {""}
              </Typography>
              <Typography variant="overline" display="block" gutterBottom>
                {file_first.file !== ""
                  ? file_first?.file?.name
                  : " No se ha seleccionado ningun archivo"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Button variant="contained" fullWidth type="submit">
                Actualizar proveedor
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Layout>
  );
};

export default UpdateProviders;
