import React, { useContext, useEffect, useState } from "react";
import PendingCanvasCard from "../../components/Cards/WarehouseCanvas/PendingCanvasCard";
import NoDataComponents from "../../components/loading/NoDataComponents";
import WarehouseCanvasContext from "../../context/WarehouseCanvas/WarehouseCanvasContext";
import ModulesContext from "../../context/Permissions/PermissionsContext";
import { makeStyles } from "@mui/styles";
import { styled, alpha } from "@mui/material/styles";
import { Box, CardContent, Grid, InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import NoResultsComponent from "../../components/loading/NoResultsComponent";
import MethodGet from "../../config/Service";
import Swal from "sweetalert2";
import { Paginate } from "../../components/Pagination/Paginate";
const useStyles = makeStyles({
  titleCard: {
    color: "white",
    fontSize: "35px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 2,
  },
  pagination: {
    marginBottom: 25,
    marginRight: 25,
    fontFamily: "cursive",
    position: "relative",
    fontSize: "40px",
  },
});

//Estilos busqueda
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
  },
}));

export default function Pending() {
  const classes = useStyles();
  /**Paginacion */
  var [pages, setPage] = useState(1);
  const [magicWord, setMagicWord] = useState("");
  const [filterWarehouse, setFilterWarehouse] = useState();
  const [pending, setPending] = useState({});

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const limite = 50;
  const params = { limite, pages };

  const {
    shippingsCanvasPending,
    GetWarehouseCanvasPending,
    success,
    totalPages,
  } = useContext(WarehouseCanvasContext);

  useEffect(() => {
    let url = "/warehouse-shipping/no-pagination-canvas/pending";
    MethodGet(url)
      .then((res) => {
        setPending(res.data.shippings);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
      });
  }, []);
  const handleSearchWarehose = ({ target }) => {
    setMagicWord(target.value);
    const palabra = target.value;

    try {
      let resultsearchwarehouse = pending.filter((shippingf) =>
        shippingf.order_id.folio
          .toString()
          .toLowerCase()
          .includes(palabra.toLowerCase())
      );
      setFilterWarehouse(resultsearchwarehouse);
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error.response.data.message,
        icon: "error",
      });
    }
  };
  const { permissionsPerUser, GetModulesPerUser } = useContext(ModulesContext);

  useEffect(() => {
    if (localStorage.getItem("module_id")) {
      const permiss = localStorage.getItem("module_id");
      GetModulesPerUser(permiss);
      // GetTypeUsers();
    }
  }, [success]);
  useEffect(() => {
    GetWarehouseCanvasPending(params);
    setFilterWarehouse(pending);
  }, [success, limite, pages]);
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Box sx={{ width: "100%", display: "flex" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container spacing={2}>
                {shippingsCanvasPending.length ? (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Search>
                      <SearchIconWrapper>
                        <SearchIcon />
                      </SearchIconWrapper>
                      <StyledInputBase
                        fullWidth
                        placeholder="Buscar pedido por el número de pedido"
                        inputProps={{ "aria-label": "search" }}
                        onChange={handleSearchWarehose}
                      />
                    </Search>
                  </Grid>
                ) : (
                  <></>
                )}

                {!magicWord &&
                  (!shippingsCanvasPending.length ? (
                    <NoDataComponents />
                  ) : (
                    shippingsCanvasPending.map((shipping) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={4}
                        key={shipping._id}
                      >
                        <PendingCanvasCard
                          shipping={shipping}
                          permiss={permissionsPerUser}
                        />
                      </Grid>
                    ))
                  ))}

                {magicWord &&
                  (!filterWarehouse.length ? (
                    <NoResultsComponent />
                  ) : (
                    filterWarehouse.map((shipping) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={4}
                        key={shipping._id}
                      >
                        <PendingCanvasCard
                          shipping={shipping}
                          permiss={permissionsPerUser}
                        />
                      </Grid>
                    ))
                  ))}
              </Grid>
              <Box className={classes.pagination} sx={{ marginBottom: 10 }}>
                {!magicWord ? (
                  shippingsCanvasPending.length ? (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      display="flex"
                      justifyContent="center"
                    >
                      <Paginate
                        pages={pages}
                        handleChangePage={handleChangePage}
                        totalPages={totalPages}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
              </Box>
              <CardContent></CardContent>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
