import React, { useContext, useState } from "react";
import CanvasVariantsContext from "../../../context/canvasVariants/CanvasVariantsContext";

import { makeStyles } from "@mui/styles";
import {
	Box,
	Card,
	Typography,
	Tooltip,
	Grid,
	Menu,
	MenuItem,
	IconButton,
} from "@mui/material";
import ModalImage from "react-modal-image-responsive";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ModalEdit from "../../../containers/canvasVariants/Edit";

const useStyles = makeStyles({
	Imagelogo: {
		height: "131px",
		objectFit: "cover",
		textAlign: "center",
	},
});

const BrandCard = ({ variant, permiss }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const classes = useStyles();
	const { DeleteCanvasVariant } = useContext(CanvasVariantsContext);

	const [modalUpdate, AbrirModalUpdate] = useState(false);
	const [id_variant, setIdVariant] = useState();
	const handleClickOpenUpdate = () => {
		AbrirModalUpdate(true);
	};
	const handleClickCloseUpdate = () => {
		sessionStorage.removeItem("variant");
		AbrirModalUpdate(false);
	};

	const selectVariant = (variant) => {
		setIdVariant(variant);
		handleClickOpenUpdate();
	};

	return (
		<Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
			<Card sx={{ boxShadow: 3 }}>
				<Box
					fullWidth
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					sx={{
						paddingX: 2,
						backgroundColor: "white",
						color: "black",
					}}
				>
					<Typography
						variant="su arrowbtitle2"
						noWrap
						sx={{ fontWeight: "bold", fontSize: "17px" }}
						className="blink-1"
					>
						{variant.name}
					</Typography>
					<div>
						<Tooltip arrow title="Operaciones" placement="top">
							<IconButton
								aria-label="more"
								id="long-button"
								aria-controls={open ? "long-menu" : undefined}
								aria-expanded={open ? "true" : undefined}
								aria-haspopup="true"
								onClick={handleClick}
								sx={{ color: "black" }}
							>
								<MoreVertIcon />
							</IconButton>
						</Tooltip>
						<Menu
							id="long-menu"
							MenuListProps={{
								"aria-labelledby": "long-button",
							}}
							anchorEl={anchorEl}
							open={open}
							onClose={handleClose}
						>
							 {permiss.update === true && (
							<MenuItem
								onClick={() => {
									setAnchorEl(null);
									selectVariant(variant._id);
								}}
							>
								Editar
							</MenuItem>
							 )}
							  {permiss.delete === true && (
							<MenuItem
								onClick={() => {
									setAnchorEl(null);
									DeleteCanvasVariant(variant._id);
								}}
							>
								Eliminar
							</MenuItem>
							  )}
						</Menu>
					</div>
				</Box>
				<Box
					fullWidth
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<Typography
						variant="su arrowbtitle2"
						noWrap
						sx={{
							fontWeight: "bold",
							fontSize: "17px",
							marginTop: 1,
							marginBottom: 1,
						}}
						className="blink-1"
					>
						Imagenes Requeridas: {variant.number_images}
					</Typography>
				</Box>
				<Box
					fullWidth
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<ModalImage
						className={classes.Imagelogo}
						small={variant.image}
						large={variant.image}
						alt={variant.name}
					/>
				</Box>
			</Card>
			{id_variant && (
				<ModalEdit
					modalUpdate={modalUpdate}
					id={id_variant}
					handleClickOpen={handleClickOpenUpdate}
					handleCloseEdit={handleClickCloseUpdate}
				/>
			)}
		</Grid>
	);
};

export default BrandCard;
