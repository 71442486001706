import React, { useContext, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import WarehouseContext from "../../context/Warehouse/WarehouseContext";
import AcceptedCard from "../../components/Cards/Warehouse/AcceptedCard";
import NoDataComponents from "../../components/loading/NoDataComponents";
import ModulesContext from "../../context/Permissions/PermissionsContext";

import { makeStyles } from "@mui/styles";
import { styled, alpha } from "@mui/material/styles";
import { Box, CardContent, Grid, InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import NoResultsComponent from "../../components/loading/NoResultsComponent";
import MethodGet from "../../config/Service";
import Swal from "sweetalert2";
import { Paginate } from "../../components/Pagination/Paginate";

const useStyles = makeStyles({
  titleCard: {
    color: "white",
    fontSize: "35px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 2,
  },
  pagination: {
    marginBottom: 25,
    marginRight: 25,
    fontFamily: "cursive",
    position: "relative",
    fontSize: "40px",
  },
});

//Estilos busqueda
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
  },
}));

export default function Processing() {
  const classes = useStyles();
  /**Paginacion */
  const [pages, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [magicWord, setMagicWord] = useState("");
  const [filterAccep, setfilterAccep] = useState();
  const [accepted, setAccepted] = useState({});

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };
  const limite = rowsPerPage;
  const params = { limite, pages };

  const { shippingsAccepted, GetWarehouseAccepted, success, totalPages } =
    useContext(WarehouseContext);

  useEffect(() => {
    let url = "/warehouse-shipping/no-pagination/accepted";
    MethodGet(url)
      .then((res) => {
        setAccepted(res.data.shippings);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
      });
  }, []);

  const handleSearchWarehose = ({ target }) => {
    setMagicWord(target.value);
    const palabra = target.value;

    try {
      let resultsearchwarehouse = accepted.filter(
        (shippingf) =>
          shippingf.order_id.folio
            .toString()
            .toLowerCase()
            .includes(palabra.toLowerCase()) ||
          shippingf?.order_id?.client_id?.fullname
            .toString()
            .toLowerCase()
            .includes(palabra.toLowerCase())
      );
      setfilterAccep(resultsearchwarehouse);
    } catch (error) {}
  };
  useEffect(() => {
    GetWarehouseAccepted(params);
    setfilterAccep(accepted);
  }, [success, rowsPerPage, pages]);

  const { permissionsPerUser, GetModulesPerUser } = useContext(ModulesContext);

  useEffect(() => {
    if (localStorage.getItem("module_id")) {
      const permiss = localStorage.getItem("module_id");
      GetModulesPerUser(permiss);
      // GetTypeUsers();
    }
  }, [success]);

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Box sx={{ width: "100%", display: "flex" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container spacing={2}>
                {shippingsAccepted.length ? (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Search>
                      <SearchIconWrapper>
                        <SearchIcon />
                      </SearchIconWrapper>
                      <StyledInputBase
                        fullWidth
                        placeholder="Buscar pedido por el número de pedido"
                        inputProps={{ "aria-label": "search" }}
                        onChange={handleSearchWarehose}
                      />
                    </Search>
                  </Grid>
                ) : (
                  <></>
                )}

                {!magicWord &&
                  (!shippingsAccepted.length ? (
                    <NoDataComponents />
                  ) : (
                    shippingsAccepted.map((shipping, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        xl={4}
                        key={index}
                      >
                        <AcceptedCard
                          shipping={shipping}
                          permiss={permissionsPerUser}
                        />
                      </Grid>
                    ))
                  ))}

                {magicWord &&
                  (!filterAccep.length ? (
                    <NoResultsComponent />
                  ) : (
                    filterAccep.map((shipping, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        xl={4}
                        key={index}
                      >
                        <AcceptedCard
                          shipping={shipping}
                          permiss={permissionsPerUser}
                        />
                      </Grid>
                    ))
                  ))}
              </Grid>
              <Box className={classes.pagination} sx={{ marginTop: 4 }}>
                {!magicWord &&
                  (!shippingsAccepted.length ? (
                    <Box className={classes.pagination}></Box>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      display="flex"
                      justifyContent="center"
                    >
                      <Paginate
                        totalPages={totalPages}
                        pages={pages}
                        handleChangePage={handleChangePage}
                      />
                    </Grid>
                  ))}
              </Box>
              <CardContent></CardContent>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
