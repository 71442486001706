import { MoreVert } from "@material-ui/icons";
import {
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Box,
  Card,
} from "@mui/material";
import { useContext, useState } from "react";
import Marquee from "react-fast-marquee";
import TypeCategoriesContext from "../../../context/typecategories/TypeCategoriesContext";

export default function TypeCategoriesCard({ typecategorie, setDataItem, permiss }) {
  const { deleteTypeCategory } = useContext(TypeCategoriesContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: 1,
            paddingRight: 1,
            boxShadow:
              "rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px",
          }}
        >
          <div
            style={{
              fontSize: "1rem",
              fontWeight: "bold",
              width: "90%",
            }}
          >
            <Marquee play={false} gradient={false} speed={45}>
              {typecategorie?.name}
            </Marquee>
          </div>
          <div>
            <Tooltip arrow title="Operaciones" placement="top">
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                sx={{ color: "black" }}
              >
                <MoreVert />
              </IconButton>
            </Tooltip>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              {permiss.update === true && (
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  setDataItem({
                    id: typecategorie?._id,
                    name: typecategorie?.name,
                  });
                }}
              >
                Editar
              </MenuItem>
              )}
              {permiss.delete == true && (
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  deleteTypeCategory(typecategorie._id);
                }}
              >
                Eliminar
              </MenuItem>
              )}
            </Menu>
          </div>
        </Box>
      </Card>
    </Grid>
  );
}
