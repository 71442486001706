import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
//import InfoClient from "./InfoClient";
import BusinessRulesContext from "../../context/BusinessRules/BusinessRulesContext";
import { useContext } from "react";
import { useEffect } from "react";

import { useState } from "react";
import ModalPayment from "./ModalPayments";
import AddClientModal from "../../components/SellComponents/AddClientModal";
import SelectClients from "../../containers/SelectOptions/SelectClients";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { useForm } from "react-hook-form";
import { MethodPost } from "../../config/Service";
import Swal from "sweetalert2";

/**Estilos del item para la card */
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
export default function InfoSale({ subtotal, buy_products, cart }) {
  //Agregar nuevo cliente modal
  const [modal, openModal] = useState(false);
  const handleClickOpenModal = () => {
    openModal(true);
  };
  const handleClickCloseModal = () => {
    openModal(false);
  };
  const [client, saveClient] = useState("");
  const detectarCambiosClient = (value) => {
    saveClient(value);
  };
  //modal de pagos
  const [modalPayment, openModalPayment] = useState(false);
  const handleClickOpenModalPayment = () => {
    openModalPayment(true);
  };
  const handleClickCloseModalPayment = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    openModalPayment(false);
  };
  const [total, guardarTotal] = useState(0);

  useEffect(() => {
    guardarTotal(subtotal);
  }, [subtotal]);
  //guardar respuesta
  const [subtotalVenta, saveSubtotalVenta] = useState(null);
  const [totalVenta, saveTotalVenta] = useState(null);
  const [businessRuleVenta, saveBusinessRuleVenta] = useState(null);
  const [customer, saveCustomer] = useState(null);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const onSubmit = () => {
    const data = {
      productsDiscount: cart.productsDiscount,
      productsWithoutDiscount: cart.productsWithoutDiscount,
      productsCanvas: cart.productsCanvas,
      customer_id: client.value,
    };
    let url = "/sale/calculate/totals";
    MethodPost(url, data)
      .then((res) => {
        saveBusinessRuleVenta(res.data.businessRule);
        saveSubtotalVenta(res.data.subtotal);
        saveTotalVenta(res.data.total);
        saveCustomer(res.data.customer);
        //saveResponse(res.data.sale);
      })
      .catch((error) => {
        Swal.mixin({
          toast: true,
          background: "red",
          color: "white",
          iconColor: "white",
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        }).fire({
          title: "Error",
          text: error.response.data.message,
        });
      });
  };
  return (
    <>
      <Item sx={{ background: "black" }}>
        <Typography
          fontSize="25px"
          fontStyle="italic"
          fontWeight="bold"
          sx={{ color: "white" }}
        >
          Cliente
        </Typography>
      </Item>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingX: 2,
          paddingY: 2,
          marginBottom: 1,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
            <SelectClients detectarCambiosClient={detectarCambiosClient} />
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
            <Tooltip title="Agregar cliente" placement="top">
              <IconButton
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  "&:hover": { backgroundColor: "black" },
                }}
                aria-label="delete"
                onClick={handleClickOpenModal}
              >
                <PersonAddAltIcon
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingX: 2,
          paddingY: 2,
          marginBottom: 1,
        }}
      >
        <div>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            Cliente:
          </Typography>
        </div>
        {client ? (
          <div>
            <Typography sx={{ fontSize: "20px" }} fontSize="bold">
              {client.label}
            </Typography>
          </div>
        ) : (
          "No se ha seleccionado"
        )}
      </Box>

      <AddClientModal
        modal={modal}
        handleClickOpen={handleClickOpenModal}
        handleClose={handleClickCloseModal}
      />
      {client && (
        <ModalPayment
          modal={modalPayment}
          handleClickOpen={handleClickOpenModalPayment}
          handleClose={handleClickCloseModalPayment}
          total={total}
          client={client}
          totalVenta={totalVenta}
          subtotalVenta={subtotalVenta}
          businessRuleVenta={businessRuleVenta}
          customer={customer}
          cart={cart}
          //response={response}
        />
      )}
      <Item sx={{ background: "black" }}>
        <Typography
          fontSize="25px"
          fontStyle="italic"
          fontWeight="bold"
          sx={{ color: "white" }}
        >
          Detalle Venta
        </Typography>
      </Item>
      <Item>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingX: 2,
            paddingY: 2,
            marginBottom: 1,
          }}
        >
          <div>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              P. comprados:
            </Typography>
          </div>
          <div>
            <Typography sx={{ fontSize: "20px" }}>{buy_products}</Typography>
          </div>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingX: 2,
            paddingY: 2,
            marginBottom: 1,
          }}
        >
          <div>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              Subtotal:
            </Typography>
          </div>
          <div>
            <Typography sx={{ fontSize: "20px" }}>
              $ {subtotal.toFixed(2)}
            </Typography>
          </div>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingX: 2,
            paddingY: 2,
            marginBottom: 1,
          }}
        >
          {client !== "" &&
            saveBusinessRuleVenta &&
            saveSubtotalVenta &&
            saveTotalVenta &&
            buy_products > 0 && (
              <form
                onSubmit={handleSubmit(onSubmit)}
                onKeyDown={(e) => {
                  if (e.code === "Enter" || e.code === "NumpadEnter")
                    e.preventDefault();
                }}
                autoComplete="off"
              >
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    color: "#fff",
                    width: "100%",
                    border: "0",
                    margin: "5px 8px",
                    padding: "10px",
                    fontSize: "17px",
                    marginTop: "20px",
                    borderRadius: "4px",
                    backgroundColor: "#e91e63",
                    "&:hover": {
                      color: "white",
                      backgroundColor: "#e91e63",
                      //hoverColor: "white",
                      //fontFamily: "monospace",
                      //fontWeight: "bolder",
                      //fontSize: "15px",
                    },
                  }}
                  size="large"
                  onClick={handleClickOpenModalPayment}
                >
                  Continuar
                </Button>
              </form>
            )}
        </Box>
      </Item>
    </>
  );
}
