import React, { useReducer } from "react";
import UsersReducer from "./UsersReducer";
import UsersContext from "./UsersContext";
//import { useHistory } from "react-router-dom";

import MethodGet, {
	MethodDelete,
	MethodPost,
	MethodPut,
} from "../../config/Service";
import {
	ADD_USERS,
	CURRENT_USER,
	DELETE_USERS,
	GET_ALL_USERS,
	GET_ALL_USERS_SEARCH,
	GET_USERS_BLOCKED,
	RESTORE_USER,
	SHOW_ERRORS_API,
	UPDATE_USERS,
} from "../../types";
import Swal from "sweetalert2";
import headerConfig from "../../config/imageHeaders";

//import TypeUsers from '../../containers/type_users/TypeUsers';

const UsersState = ({ children }) => {
	//estado_inicial
	const initialState = {
		users: [],
		allusers: [],
		usersLocked: [],
		user: null,
		ErrorsApi: [],
		success: false,
	};

	//dispatch que ejecuta las acciones
	const [state, dispatch] = useReducer(UsersReducer, initialState);
	//obtener la lista de los tipos de usuarios

	const GetUsers = (params) => {
		sessionStorage.removeItem("user");
		//params.pages += 1;
		let url = `/users?limit=${params.limite}&page=${params.pages}`;
		MethodGet(url)
			.then((res) => {
				dispatch({
					type: GET_ALL_USERS,
					payload: {
						users: res.data.users,
						totalDocs: res.data.totalDocs,
						limit: res.data.limit,
						page: res.data.page,
						totalPages: res.data.totalPages,
						paginCounter: res.data.paginCounter,
						hasPrevPage: res.data.hasPrevPage,
						hasNextPage: res.data.hasNextPage,
						prevPage: res.data.prevPage,
						nextPage: res.data.nextPage,
					},
				});
			})
			.catch((error) => {
				Swal.fire({
					title: "Error",
					text: error.response.data.message,
					icon: "error",
					timer: 2000,
					showConfirmButton: false,
				});
			});
	};

	const GetAllUsers = () => {
		sessionStorage.removeItem("user");
		//params.pages += 1;
		let url = `/users/noPaginate`;
		MethodGet(url)
			.then((res) => {
				dispatch({
					type: GET_ALL_USERS_SEARCH,
					payload: {
						allusers: res.data.users,
						// totalDocs: res.data.totalDocs,
						// limit: res.data.limit,
						// page: res.data.page,
						// paginCounter: res.data.paginCounter,
						// hasPrevPage: res.data.hasPrevPage,
						// hasNextPage: res.data.hasNextPage,
						// prevPage: res.data.prevPage,
						// nextPage: res.data.nextPage,
					},
				});
			})
			.catch((error) => {
				Swal.fire({
					title: "Error",
					text: error.response.data.message,
					icon: "error",
					timer: 2000,
					showConfirmButton: false,
				});
			});
	};

	const GetUsersBlocked = () => {
		sessionStorage.removeItem("user");
		let url = `/users/desactivated/blocked`;
		MethodGet(url)
			.then((res) => {
				dispatch({
					type: GET_USERS_BLOCKED,
					payload: res.data.users,
				});
			})
			.catch((error) => {
				Swal.fire({
					title: "Error",
					text: "Ocurrio un error al consultar la informacion, si el error continua comuniquese con el administrador del sistema",
					icon: "error",
					timer: 2000,
					showConfirmButton: false,
				});
			});
	};

	//funcion para crear nuevo tipo de usuario
	const AddUsers = (user) => {
		const formData = new FormData();
		formData.append("fullname", user.fullname);
		formData.append("password", user.password);
		formData.append("email", user.email);
		formData.append("type_user_id", user.type_user_id);
		formData.append("image", user.image);
		let url = "/users/";
		MethodPost(url, formData, { headerConfig })
			.then((res) => {
				Swal.fire({
					title: "Usuario creado",
					text: "El usuario se ha creado, exitosamente. Por favor verifica tu correo para poder iniciar sesión",
					icon: "success",
					timer: 3000,
					showConfirmButton: false,
				});
				dispatch({
					type: ADD_USERS,
				});
			})
			.catch((error) => {
				Swal.fire({
					title: "Error",
					text: error.response.data.error,
					icon: "error",
					timer: 2000,
					showConfirmButton: false,
				});
				dispatch({
					type: SHOW_ERRORS_API,
					payload: error.res.data.error,
				});
			});
	};

	//funcion para editar el usuario
	const UpdateUsers = (user) => {
		const formData = new FormData();
		formData.append("fullname", user.fullname);
		formData.append("email", user.email);
		formData.append("type_user_id", user.type_user_id);
		formData.append("profileImage", user.image);

		let url = `/users/${user.id}`;
		MethodPut(url, formData, { headerConfig })
			.then((res) => {
				Swal.fire({
					title: "Buen trabajo",
					text: res.data.message,
					timer: 1500,
					showConfirmButton: false,
					icon: "success",
				});
				dispatch({
					type: UPDATE_USERS,
				});
			})
			.catch((error) => {
				Swal.fire({
					title: "Error",
					text: error.response.data.message,
					icon: "erorr",
					timer: 1500,
					showConfirmButton: false,
				});
				dispatch({
					type: SHOW_ERRORS_API,
					payload: error.res.data.error,
				});
			});
	};

	//Eliminar  usuario
	const DeleteUsers = (id) => {
		Swal.fire({
			title: "¿Estas seguro?",
			text: "El tipo de usuario seleccionado será deshabilitado",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Si, eliminar",
			cancelButtonText: "No, cancelar",
		}).then((result) => {
			if (result.value) {
				let url = `/users/${id}`;
				//let history = useHistory();
				MethodDelete(url)
					.then((res) => {
						Swal.fire({
							title: "Eliminado",
							text: res.data.message,
							icon: "success",
							timer: 2000,
							showConfirmButton: false,
						});
						dispatch({
							type: DELETE_USERS,
							payload: id,
						});
					})
					.catch((error) => {
						Swal.fire({
							title: "Error",
							text: error.response.data.message,
							icon: "error",
							showConfirmButton: false,
							timer: 2000,
						});
					});
			}
		});
	};

	const RestoreUser = (id) => {
		Swal.fire({
			title: "¿Estas seguro?",
			text: "El usuario seleccionado, sera habilitado y podra hacer uso del sistema",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Si, Habilitar",
			cancelButtonText: "No, cancelar",
		}).then((result) => {
			if (result.value) {
				let url = `/users/reactivate/${id}`;
				MethodPost(url)
					.then((res) => {
						Swal.fire({
							title: "Habilitado",
							text: "El usuario se ha habilitado correctamente",
							icon: "success",
							timer: 1500,
							showConfirmButton: false,
						});
						dispatch({
							type: RESTORE_USER,
						});
					})
					.catch((error) => {
						Swal.fire({
							title: "Error",
							text: error.response.data.message,
							icon: "error",
							showConfirmButton: false,
							timer: 2000,
						});
					});
			}
		});
	};

	//funcion para seleccionar una tipo de usuario
	const SaveCurrentUser = (user) => {
		sessionStorage.setItem("user", JSON.stringify(user));
		dispatch({
			type: CURRENT_USER,
			payload: user,
		});
	};

	return (
		<UsersContext.Provider
			value={{
				users: state.users,
				allusers: state.allusers,
				user: state.user,
				usersLocked: state.usersLocked,
				ErrorsApi: state.ErrorsApi,
				success: state.success,
				totalDocs: state.totalDocs,
				limit: state.limit,
				page: state.page,
				paginCounter: state.paginCounter,
				hasPrevPage: state.hasPrevPage,
				prevPage: state.prevPage,
				hasNextPage: state.hasNextPage,
				nextPage: state.nextPage,
				totalPages: state.totalPages,
				GetUsers,
				GetAllUsers,
				GetUsersBlocked,
				AddUsers,
				UpdateUsers,
				DeleteUsers,
				SaveCurrentUser,
				RestoreUser,
			}}
		>
			{children}
		</UsersContext.Provider>
	);
};

export default UsersState;
