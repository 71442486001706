import React, { useContext, useEffect, useState } from "react";
import FaqCategoriesContext from "../../context/FaqCategories/FaqCategoriesContext";
import FaqCategoriesCard from "../../components/Cards/FaqCategories/FaqCategoriesCard";
import NoDataComponents from "../../components/loading/NoDataComponents";

import { makeStyles } from "@mui/styles";
import { Grid, Box, Button } from "@mui/material/";
import ModalAdd from "./Add";
import ModulesContext from "../../context/Permissions/PermissionsContext";

const useStyles = makeStyles({
  titleCard: {
    color: "white",
    fontSize: "35px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 2,
    marginRight: 5,
  },
});

const Faqcategories = () => {
  const classes = useStyles();
  //Funcion del Modal Para abrir
  const [modalAdd, AbrirModalAdd] = useState(false);
  const handleClickOpenAdd = () => {
    AbrirModalAdd(true);
  };
  const handleClickCloseAdd = () => {
    AbrirModalAdd(false);
  };
  //

  //Modal para Editar

  const { faq_categories, GetFaqs, success } = useContext(FaqCategoriesContext);
  const { permissionsPerUser, GetModulesPerUser } = useContext(ModulesContext);

  useEffect(() => {
    if (localStorage.getItem("module_id")) {
      const permiss = localStorage.getItem("module_id");
      GetModulesPerUser(permiss);
    }
    GetFaqs();
  }, [success]);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              textAling: "center",
              // borderRadius: "0 0 24px 0",
            }}>
            {permissionsPerUser.write === true && (
              <div className={classes.fab}>
                <Button
                  onClick={handleClickOpenAdd}
                  sx={{
                    color: "black",
                    backgroundColor: "#26c6da",
                    hoverColor: "white",
                    fontFamily: "monospace",
                    fontWeight: "bolder",
                    fontSize: "15px",
                    textTransform: "none",
                    "&:hover": {
                      color: "black",
                      backgroundColor: "#67cedb",
                      hoverColor: "white",
                      fontFamily: "monospace",
                      fontWeight: "bolder",
                      fontSize: "15px",
                    },
                  }}>
                  Agregar
                </Button>
              </div>
            )}
          </Box>

          <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={2}>
                  {!faq_categories.length ? (
                    <NoDataComponents />
                  ) : (
                    faq_categories.map((faq_categorie) => (
                      <FaqCategoriesCard
                        permiss={permissionsPerUser}
                        faq_categorie={faq_categorie}
                        key={faq_categorie._id}
                      />
                    ))
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <ModalAdd
        modal={modalAdd}
        handleClickOpen={handleClickOpenAdd}
        handleClose={handleClickCloseAdd}
      />
    </>
  );
};

export default Faqcategories;
