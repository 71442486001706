import React from "react";
import { Fragment } from "react";

import {
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { FormControl } from "@mui/material";

export default function SelectTypeTerms(props) {
  const types = [
    { value: 1, name: "Politicas de privacidad" },
    { value: 2, name: "Terminos y Condiciones" },
    { value: 3, name: "Garantias y Devoluciones" },
  ];
  const selectStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };
  const detectarCambiosTypeTerms = (e) => {
    props.detectarCambiosTypeTerms(e.target.value);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            Selecciona el tipo de terminos
          </InputLabel>
          {props.typeTermId ? (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Marca "
              value={props.typeTermId}
              onChange={detectarCambiosTypeTerms}>
              {types.map((type, index) => (
                <MenuItem key={index} value={type.value}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Marca "
              onChange={detectarCambiosTypeTerms}>
              {types.map((type, index) => (
                <MenuItem key={index} value={type.value}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          )}
          <FormHelperText>Compo Requerido*</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}
