import React, { useContext, useEffect, useState } from "react";
import SubcategoriesContext from "../../context/SubCategories/SubcategoriesContext";
import CategorySelect from "../../containers/SelectOptions/SelectCategory";
import MethodGet from "../../config/Service";
import { useForm } from "react-hook-form";

import { makeStyles } from "@mui/styles";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  TextField,
  Tooltip,
} from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import Swal from "sweetalert2";

const useStyles = makeStyles({
  card: {
    marginTop: 7,
    marginLeft: 7,
    marginRight: 7,
  },
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 10,
  },
  input: {
    display: "none",
  },
});

export default function Edit(props) {
  const classes = useStyles();
  const { modalUpdate, handleCloseEdit, id } = props;
  const [CategoryId, saveCategory] = useState("");
  const { UpdateSubcategories } = useContext(SubcategoriesContext);
  const [subcategory, SaveSubcategory] = useState({});
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({ defaultValues: subcategory });

  const detectarCambiosCategory = (e) => {
    saveCategory(e.target.value);
  };
  useEffect(() => {
    let url = `/subcategories/${id}`;
    MethodGet(url)
      .then((res) => {
        SaveSubcategory(res.data.subcategory);
        saveCategory(res.data.subcategory.category);
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          text: error.response.data.message,
          icon: "error",
        });
      });
  }, [id]);

  useEffect(() => {
    if (subcategory) {
      setValue("name", subcategory.name);
    } else {
      setValue("name", "");
    }
  }, [subcategory]);

  const onSubmit = (data, e) => {
    data.id = id;
    data.category = CategoryId;
    UpdateSubcategories(data);
    handleCloseEdit();
  };

  return (
    <Dialog
      open={modalUpdate}
      onClose={handleCloseEdit}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#000000",
            textAling: "center",
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            borderRadius: "0 0 24px 0",
          }}
        >
          <div className={classes.titleCard}>Actualizar subcategoria</div>
          <div className={classes.fab}>
            <Tooltip title="cerrar" arrow>
              <Fab
                size="small"
                onClick={handleCloseEdit}
                sx={{
                  color: "#FFFFFF",
                  backgroundColor: "#FF0000",
                  "&:hover": {
                    color: "#FFFFFF",
                    backgroundColor: "#FF0000",
                  },
                }}
              >
                <CancelPresentationIcon />
              </Fab>
            </Tooltip>
          </div>
        </Box>
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter")
              e.preventDefault();
          }}
          autoComplete="off"
        >
          <Grid container spacing={2} sx={{ marginTop: 1 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CategorySelect
                CategoryId={CategoryId}
                detectarCambiosCategory={detectarCambiosCategory}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                fullWidth
                focused
                placeholder="Ejemplo: Sanitizante Spray"
                id="name"
                label="Nombre de la subcategoria"
                variant="outlined"
                name="name"
                sx={{ marginBottom: 5 }}
                error={errors.name ? true : false}
                helperText={errors?.name?.message}
                {...register("name", {
                  required: {
                    value: true,
                    message: "El nombre de la subcategoria es requerida",
                  },
                  minLength: {
                    value: 2,
                    message: "Minimo 4 caracteres",
                  },
                  maxLength: {
                    value: 255,
                    message: "Maximo 255 caracteres",
                  },
                })}
              />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  textAling: "center",
                }}
              >
                <div className={classes.fab}>
                  <Fab
                    variant="extended"
                    type="submit"
                    sx={{
                      backgroundColor: "#00B9FF",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#00DEED",
                        color: "white",
                      },
                    }}
                  >
                    <SaveOutlinedIcon sx={{ mr: 1 }} />
                    Guardar
                  </Fab>
                </div>
              </Box>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      )
    </Dialog>
  );
}
