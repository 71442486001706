import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useContext, useEffect } from "react";
import ProductsContext from "../../context/Products/ProductsContext";

export default function ProductSelect(props) {
  const { products, productsSelect } = useContext(ProductsContext);

  useEffect(() => {
    productsSelect();
  }, []);

  const detectarCambiosProducts = (e) => {
    props.detectarCambiosProducts(e);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Productos</InputLabel>
          {props.productId ? (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Tipo de regla de negocio "
              value={props.productId}
              onChange={detectarCambiosProducts}
            >
              {products.map((product, index) => (
                <MenuItem key={index} value={product._id}>
                  {product.name}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Tipo de regla de negocio "
              onChange={detectarCambiosProducts}
            >
              {products.map((product, index) => (
                <MenuItem key={index} value={product._id}>
                  {product.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
      </Grid>
    </Grid>
  );
}
