import { Box, Button, Grid, Pagination, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import Layout from "../../components/layout/Layout";
import SalesFisicContext from "../../context/SalesFisic/SalesFisicContext";
import CardSell from "../../components/SellComponents/CardSell";
import { Link } from "react-router-dom";
import NoDataComponents from "../../components/loading/NoDataComponents";
import { Paginate } from "../../components/Pagination/Paginate";
const useStyles = makeStyles(() => ({
  titleCard: {
    color: "white",
    fontSize: "35px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 2,
  },
}));
export default function SalesFisic() {
  const classes = useStyles();
  const { GetSalesFisic, sales_fisic, totalPages } =
    useContext(SalesFisicContext);
  //paginacion
  var [pages, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const limite = rowsPerPage;
  const params = { limite, pages };

  useEffect(() => {
    if (handleChangePage) {
      GetSalesFisic(params);
    }
  }, [rowsPerPage, pages]);

  return (
    <Layout>
      <Grid container spacing={2} sx={{ backgroundColor: "white", padding: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#000000",
              textAling: "center",
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            }}
          >
            <div className={classes.titleCard}>Ventas de la tienda</div>
            <div>
              <Link to="/vender">
                <Button
                  variant="outlined"
                  size="medium"
                  className="button_fill_primary"
                  sx={{ marginRight: 2 }}
                >
                  Agregar Venta
                </Button>
              </Link>
            </div>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ padding: 2 }}>
        {sales_fisic.length === 0 ? (
          <NoDataComponents />
        ) : (
          <>
            {sales_fisic.map((sale, index) => (
              <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={index}>
                <CardSell sale={sale} key={index} />
              </Grid>
            ))}
          </>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
          {sales_fisic.length === 0 ? (
            <></>
          ) : (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              display="flex"
              justifyContent="center"
            >
              <Paginate
                pages={pages}
                totalPages={totalPages}
                handleChangePage={handleChangePage}
              />
            </Grid>
          )}
        </Box>
      </Grid>
    </Layout>
  );
}
