import React from "react";
import {
	ADD_COUNTRY,
	CURRENT_COUNTRY,
	DELETE_COUNTRY,
	GET_ALL_COUNTRIES,
	SHOW_ERRORS_API,
	UPDATE_COUNTRY,
	UPDATE_FILES,
} from "../../types";

const PermissionCountriesReducer = (state, action) => {
	switch (action.type) {
		case GET_ALL_COUNTRIES:
			return {
				...state,
				countries: action.payload,
				success: false,
				ErrorsAPI: [],
			};
		case ADD_COUNTRY:
			return {
				...state,
				success: true,
				ErrorsAPI: [],
			};
		case SHOW_ERRORS_API:
			return {
				...state,
				success: true,
				ErrorsAPI: [],
			};
		case UPDATE_COUNTRY:
			return {
				...state,
				success: true,
				ErrorsAPI: [],
			};
		case DELETE_COUNTRY:
			return {
				...state,
				success: true,
				countries: state.countries.filter((c) => c.id !== action.payload),
			};
		case CURRENT_COUNTRY:
			return {
				...state,
				country: action.payload,
			};
		case UPDATE_FILES:
			return {
				...state,
				success: true,
				ErrorsAPI: [],
			};
		default:
			return state;
	}
};

export default PermissionCountriesReducer;
