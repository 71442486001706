import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";

export const SelectTypeDirection = (props) => {
  const typeDirection = [
    { name: "Nacional", value: 1 },
    { name: "Internacional", value: 2 },
  ];
  const detectarCambiosTypeDirection = (e) => {
    props.detectarCambiosTypeDirection(e);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Tipo de envio</InputLabel>
          {props.typeDirection ? (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Tipo de cliente "
              onChange={detectarCambiosTypeDirection}
              value={props.typeDirection}
            >
              {typeDirection.map((typeDirection, index) => (
                <MenuItem key={index} value={typeDirection.value}>
                  {typeDirection.name}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="tipo de cliente"
              onChange={detectarCambiosTypeDirection}
            >
              {typeDirection.map((typeDirection, index) => (
                <MenuItem key={index} value={typeDirection.value}>
                  {typeDirection.name}
                </MenuItem>
              ))}
            </Select>
          )}
          <FormHelperText>Compo Requerido*</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
};
