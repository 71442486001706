import React, { useReducer } from "react";
import Swal from "sweetalert2";
import MethodGet, {
	MethodDelete,
	MethodPost,
	MethodPut,
} from "../../config/Service";
import {
	ADD_BRANDS,
	CURRENT_BRAND,
	DELETE_BRANDS,
	GET_ALL_BRANDS,
	SHOW_ERRORS_API,
	UPDATE_BRANDS,
} from "../../types";
import headerConfig from "../../config/imageHeaders";
import BrandsReducer from "./BrandsReducer";
import BrandsContext from "./BrandsContext";

const BrandsState = ({ children }) => {
	//estado inicial
	const initialState = {
		brands: [],
		brand: null,
		ErrorsApi: [],
		success: false,
	};

	const [state, dispatch] = useReducer(BrandsReducer, initialState);

	//obtener la lista de marcas
	const GetBrands = () => {
		sessionStorage.removeItem("brand");
		let url = "/brands";
		MethodGet(url)
			.then((res) => {
				dispatch({
					type: GET_ALL_BRANDS,
					payload: res.data.brands,
				});
			})
			.catch((error) => {
				dispatch({
					type: SHOW_ERRORS_API,
					payload: error.response.data.message,
				});
			});
	};

	//funcion para registrar marca
	const AddBrands = (brand) => {
		const formData = new FormData();
		formData.append("name", brand.name);
		formData.append("image", brand.image);

		let url = "/brands/";
		MethodPost(url, formData, { headerConfig })
			.then((res) => {
				Swal.fire({
					title: "Marca Agregada",
					text: "La marca se ha registrado exitosamente",
					icon: "success",
					timer: 1000,
					showConfirmButton: false,
				});
				dispatch({
					type: ADD_BRANDS,
				});
			})
			.catch((error) => {
				Swal.fire({
					title: "Error",
					text: error.response.data.message,
					showConfirmButton: false,
					timer: 2000,
					icon: "error",
				});
				dispatch({
					type: SHOW_ERRORS_API,
					payload: error.response.data.error,
				});
			});
	};

	//funcion para editar marca
	const UpdateBrands = (brand) => {
		const formData = new FormData();
		formData.append("name", brand.name);
		formData.append("image", brand.image);

		let url = `/brands/${brand.id}`;
		MethodPut(url, formData, { headerConfig })
			.then((res) => {
				Swal.fire({
					title: "Marca Actualizada",
					text: res.data.message,
					timer: 3000,
					showConfirmButton: false,
					icon: "success",
				});
				dispatch({
					type: UPDATE_BRANDS,
				});
			})
			.catch((error) => {
				Swal.fire({
					title: "Error",
					text: error.response.data.message,
					icon: "error",
				});
				dispatch({
					type: SHOW_ERRORS_API,
					payload: error.res.data.error,
				});
			});
	};

	//eliminar marca
	const DeleteBrands = (id) => {
		Swal.fire({
			title: "¿Estas Seguro?",
			text: "La marca seleccionada sera eliminada",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Si, eliminar",
			cancelButtonText: "No, cancelar",
		}).then((result) => {
			if (result.value) {
				let url = `/brands/${id}`;

				MethodDelete(url)
					.then((res) => {
						Swal.fire({
							title: "Eliminado",
							text: res.data.message,
							icon: "success",
							timer: 2000,
							showConfirmButton: false,
						});
						dispatch({
							type: DELETE_BRANDS,
							payload: id,
						});
					})
					.catch((error) => {
						Swal.fire({
							title: "Error",
							text: error.response.data.message,
							icon: "error",
						});
					});
			}
		});
	};

	//funcion para seleccionar una marca
	const SaveCurrentBrand = (brand) => {
		sessionStorage.setItem("brand", JSON.stringify(brand));
		dispatch({
			type: CURRENT_BRAND,
			payload: brand,
		});
	};
	return (
		<BrandsContext.Provider
			value={{
				brands: state.brands,
				brand: state.brand,
				ErrorsApi: state.ErrorsApi,
				success: state.success,
				GetBrands,
				AddBrands,
				UpdateBrands,
				DeleteBrands,
				SaveCurrentBrand,
			}}
		>
			{children}
		</BrandsContext.Provider>
	);
};

export default BrandsState;
