import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDebounce } from "use-debounce";
import ProductsContext from "../context/Products/ProductsContext";
import CropFreeIcon from "@mui/icons-material/CropFree";
import Swal from "sweetalert2";
//Este compónente igual solo funcionaria para las entradas del producto
export default function Barcode({
  saveProductsList,
  productsList,
  guardarProductoID,
}) {
  const [barcode, saveBarcode] = useState("");
  const [debouncedBarcode] = useDebounce(barcode, 500);
  const { products, GetAllProductsSelect, success } =
    useContext(ProductsContext);
  useEffect(() => {
    GetAllProductsSelect();
  }, [success]);
  const handleChangeBarcode = (e) => {
    saveBarcode(e.target.value);
  };
  useEffect(() => {
    filterProductExist();
  }, [debouncedBarcode]);
  const filterProductExist = () => {
    if (debouncedBarcode !== "") {
      var product = [];
      product = products.filter((p) => p.barcode === debouncedBarcode);
      if (!product.length) {
        Swal.mixin({
          toast: true,
          background: "red",
          color: "white",
          iconColor: "white",
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        }).fire({
          icon: "error",
          title: `¡Lo sentimos!`,
          text: "No existe ningun producto, con el codigo de barras ingresado!",
        });
        saveBarcode("");
        return;
      }
      product[0].value = product[0]._id;
      guardarProductoID(product[0]);
      //obtener la posicion del producto
      const position = productsList
        .map(function (e) {
          return e.product_id;
        })
        .indexOf(product[0]._id);

      let productoResultado = {};
      productoResultado.barcode = product[0].barcode;
      //productoResultado.image = product[0].multimedia[0].path;
      productoResultado.name = product[0].name;
      //productoResultado.brand = product[0].brand.name;
      productoResultado.quantity = 1;
      productoResultado.price = product[0].price;
      productoResultado.product_id = product[0]._id;
      //productoResultado.price = product[0].price;
      productoResultado.stock_product = product[0].quantity;
      productoResultado.status = product[0].status;
      if (position === -1) {
        productoResultado.product_id = product[0]._id;
        productoResultado.quantity = 1;
        localStorage.setItem(
          "EnterProducts",
          JSON.stringify([productoResultado, ...productsList])
        );
        saveProductsList([productoResultado, ...productsList]);
        saveBarcode("");
        Swal.mixin({
          toast: true,
          background: "#388e3c",
          color: "white",
          iconColor: "white",
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        }).fire({
          icon: "success",
          title: `El producto se agregó correctamente `,
        });
      } else {
        let quantityProduct = productsList[position].quantity;
        var new_quantity_prod = (quantityProduct += 1);
        productsList[position].quantity = new_quantity_prod;
        saveProductsList([...productsList]);
        saveBarcode("");
        Swal.mixin({
          toast: true,
          background: "#388e3c",
          color: "white",
          iconColor: "white",
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        }).fire({
          icon: "success",
          title: `El producto se agregó correctamente  `,
        });
      }
    }
  };

  return (
    <TextField
      placeholder="Usa el lector de codigos de barra"
      variant="outlined"
      required
      fullWidth
      value={barcode}
      id="barcode"
      label="Código De Barras"
      name="barcode"
      autoComplete="off"
      autoFocus
      onChange={(e) => {
        handleChangeBarcode(e);
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <CropFreeIcon />
          </InputAdornment>
        ),
      }}
    />
  );
}
