import { Button, Grid } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import ProductSelectAllProductsInventory from "../SelectOptions/ProductSelectAllProductsInventory";
import ProductsContext from "../../context/Products/ProductsContext";
import { useContext } from "react";
import InventoriesContext from "../../context/Inventories/InventoriesContext";

export default function SelectProduct({
  guardarProductoID,
  cantidad,
  inventory,
  productsOnInventory,
}) {
  const [product, saveProduct] = useState(null);
  // const [producto, setInfoProduct] = useState();
  const [cargando, spinnerCargando] = useState();
  const { GetAllProductsSelect, products, success } =
    useContext(ProductsContext);
  useEffect(() => {
    GetAllProductsSelect();
  }, []);
  const detectarCambiosProduct = (value) => {
    saveProduct(value.value);
  };

  const { AddProductInventory } = useContext(InventoriesContext);
  const AgregarProductoState = () => {
    var producto = [];
    producto = products.filter((p) => p._id === product);
    // if (!producto.length) {
    //   Swal.fire({
    //     title: "Error",
    //     text: "Ocurrio un error al agregar el producto",
    //     icon: "error",
    //     showConfirmButton: false,
    //     timer: 1500,
    //   });
    //   saveProduct(null);
    //   return;
    // }
    producto[0].value = producto[0]._id;
    guardarProductoID(producto[0]);
    var data = {};
    data.inventory_id = inventory._id;
    data.product_id = producto[0]._id;
    data.stock_disponible = producto[0].quantity;

    AddProductInventory(data);
    saveProduct(null);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={9} sm={9} md={9} lg={9} xl={9} sx={{ marginTop: 2 }}>
          <ProductSelectAllProductsInventory
            detectarCambiosProduct={detectarCambiosProduct}
            saveProduct={saveProduct}
            productsOnInventory={productsOnInventory}
          />
        </Grid>
        {product !== null && (
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <Button
              variant="contained"
              sx={{ marginTop: 2 }}
              onClick={AgregarProductoState}
            >
              Agregar
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
}
