import React, { useContext, useState } from "react";
import WarehouseContext from "../../../context/Warehouse/WarehouseContext";
import formatDate from "../../../utils/FormatDate";
import ModalShipped from "../../../containers/Warehouse/ModalShipped";
import Marquee from "react-fast-marquee";
import { makeStyles } from "@mui/styles";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SendIcon from "@mui/icons-material/Send";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import {
  Box,
  Card,
  Typography,
  Divider,
  Tooltip,
  Menu,
  MenuItem,
  IconButton,
  Button,
  Grid,
  Alert,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  PhotoPerfil: {
    width: "120px",
    height: "120px",
    objectFit: "cover",
    padding: "2px",
    borderRadius: "50%",
    margin: "auto",
    marginTop: 10,
    marginBottom: 3,
    boxShadow: "-8px 10px 19px -6px rgba(110,110,110,0.97)",
    textAlign: "center",
    lineHeight: "110px",
  },
});

const AcceptedCard = ({ shipping, permiss }) => {
  /**Modal */
  const [modalShipped, AbrirModalShipped] = useState(false);

  const handleClickOpenShipped = () => {
    AbrirModalShipped(true);
  };
  const handleClickCloseShipped = () => {
    AbrirModalShipped(false);
  };

  const { ShippOrder, SaveCurrentOder, PdfOrder, MakeAsDispute } =
    useContext(WarehouseContext);
  //3 puntitos
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();
  const [id_order, setIdOrder] = useState({});

  const SelectOrder = (shipping) => {
    setIdOrder(shipping);
    handleClickOpenShipped();
  };

  const SelectShowOrder = (shipping) => {
    SaveCurrentOder(shipping);
  };
  return (
    <Card sx={{ boxShadow: 10 }}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          lg={4}
          xl={4}
          display="flex"
          justifyContent="space-between"
          sx={{ backgroundColor: "#fcd7da", color: "white" }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              display="flex"
              justifyContent="center"
            >
              <Typography
                fontWeight="bold"
                fontFamily="monospace"
                textAlign="center"
                color="black"
              >
                Empaquetando pedido
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              display="flex"
              justifyContent="center"
            >
              <div>
                <img src={require("../../../assets/gif/pakaging.gif")} />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              md={12}
              xl={12}
              display="flex"
              justifyContent="center"
            >
              <Alert severity="success" icon={false}>
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  fontFamily="monospace"
                >
                  #Pedido: {shipping.order_id.folio}
                </Typography>
              </Alert>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              md={12}
              xl={12}
              display="flex"
              justifyContent="center"
            >
              <Typography variant="h6" fontWeight="bold" fontFamily="cursive">
                Fecha compra:{""}
                {formatDate(shipping.order_id.createdAt)}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              display="flex"
              justifyContent="center"
            >
              <Alert severity="error" icon={false}>
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  fontFamily="monospace"
                >
                  Empaqueto: {shipping.user_id.fullname}
                </Typography>
              </Alert>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              md={12}
              xl={12}
              display="flex"
              justifyContent="center"
            >
              <Alert icon={false} severity="info">
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  fontFamily="monospace"
                >
                  Cliente: {shipping?.order_id?.client_id?.fullname}
                </Typography>
              </Alert>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              md={12}
              xl={12}
              display="flex"
              justifyContent="center"
            >
              <Typography variant="h6" fontWeight="bold" fontFamily="cursive">
                Productos comprados: {shipping.order_id.products_list.length}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              sx={{ marginBottom: 2 }}
              display="flex"
              justifyContent="center"
            >
              <Link to={`/Detalle_venta/${shipping.order_id._id}`}>
                <IconButton
                  onClick={() => SelectShowOrder(shipping.order_id._id)}
                >
                  <PictureAsPdfIcon sx={{ color: "#0288d1" }} />
                </IconButton>
              </Link>
            </Grid>
            {permiss.update === true && (
              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                sx={{ marginBottom: 2 }}
                display="flex"
                justifyContent="center"
              >
                <IconButton onClick={() => SelectOrder(shipping._id)}>
                  <SendIcon sx={{ color: "#388e3c" }} />
                </IconButton>
              </Grid>
            )}
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              sx={{ marginBottom: 2 }}
              display="flex"
              justifyContent="center"
            >
              <Tooltip title="Descargar PDF">
                <IconButton onClick={() => PdfOrder(shipping.order_id)}>
                  <PictureAsPdfIcon sx={{ color: "red" }} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              sx={{ marginBottom: 2 }}
              display="flex"
              justifyContent="center"
            >
              <Tooltip title="Marcar en disputa">
                <IconButton onClick={() => MakeAsDispute(shipping._id)}>
                  <ProductionQuantityLimitsIcon sx={{ color: "red" }} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ModalShipped
        modal={modalShipped}
        id={id_order}
        handleClickOpen={handleClickOpenShipped}
        handleCloseShipped={handleClickCloseShipped}
      />
    </Card>
  );
};

export default AcceptedCard;
