import React, { useReducer } from "react";
import ContactReducer from "./ContactReducer";
import ContactContext from "./ContactContext";
import MethodGet, { MethodPost, MethodPut } from "../../config/Service";
import {
  ATTEND_MESSAGE,
  GET_ALL_MESSAGES,
  GET_MESSAGES_PER_USER,
  GET_UNREAD_MESSAGES,
  MAKE_AS_READ,
} from "../../types";
import Swal from "sweetalert2";
const ContactState = ({ children }) => {
  const initialState = {
    messages: [],
    success: false,
    ErrorsAPI: [],
  };
  //dispatch que va a ejecutar las acciones
  const [state, dispatch] = useReducer(ContactReducer, initialState);
  //funcion que hace la peticion para consultar el index de la api
  const GetAllMessages = (params) => {
    let url = `/contact?limit=${params.limite}&page=${params.pages}`;
    MethodGet(url)
      .then((res) => {
        console.log(res, "la respuesta del state");
        dispatch({
          type: GET_ALL_MESSAGES,
          payload: {
            messages: res.data.contacts,
            totalDocs: res.data.contacts,
            limit: res.data.contacts,
            page: res.data.contacts,
            paginCounter: res.data.contacts,
            hasPrevPage: res.data.contacts,
            hasNextPage: res.data.contacts,
            prevPage: res.data.contacts,
            nextPage: res.data.contacts,
            totalPages: res.data.contacts,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //funcion para consultar todos los mensajes sin leer
  const GetUnreadMessages = (params) => {
    let url = `/contact/unread-messages?limit=${params.limite}&page=${params.pages}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_UNREAD_MESSAGES,
          payload: {
            messages: res.data.contacts,
            totalDocs: res.data.totalDocs,
            limit: res.data.limit,
            page: res.data.page,
            paginCounter: res.data.paginCounter,
            hasPrevPage: res.data.hasPrevPage,
            hasNextPage: res.data.hasNextPage,
            prevPage: res.data.prevPage,
            nextPage: res.data.nextPage,
            totalPages: res.data.totalPages,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //funcion para traer los mensajes atendidos por el usuario logueado
  const MessagesReadPerUser = (params) => {
    let url = `/contact/messages-user?limit=${params.limite}&page=${params.pages}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_MESSAGES_PER_USER,
          payload: {
            messages: res.data.contacts,
            totalDocs: res.data.totalDocs,
            limit: res.data.limit,
            page: res.data.page,
            paginCounter: res.data.paginCounter,
            hasPrevPage: res.data.hasPrevPage,
            hasNextPage: res.data.hasNextPage,
            prevPage: res.data.prevPage,
            nextPage: res.data.nextPage,
            totalPages: res.data.totalPages,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //funcion para tomar el mensaje
  const attendMessage = (id) => {
    Swal.fire({
      title: "Atender Mensaje",
      text: "El mensaje se marcara como mensaje en proceso de atención",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Marcar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/contact/attend-message/${id}`;
        MethodPost(url)
          .then((res) => {
            Swal.fire({
              title: "Mensaje atendido",
              text: "Se ha actualizado el status del mensaje",
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            dispatch({
              type: ATTEND_MESSAGE,
              payload: res.data.message,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  //funcion para traer los mensajes atendidos por el usuario logueado
  const MessagesCompleted = (params) => {
    let url = `/contact/messagesCompleted?limit=${params.limite}&page=${params.pages}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_MESSAGES_PER_USER,
          payload: {
            messages: res.data.contacts,
            totalDocs: res.data.totalDocs,
            limit: res.data.limit,
            page: res.data.page,
            paginCounter: res.data.paginCounter,
            hasPrevPage: res.data.hasPrevPage,
            hasNextPage: res.data.hasNextPage,
            prevPage: res.data.prevPage,
            nextPage: res.data.nextPage,
            totalPages: res.data.totalPages,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //funcion que va a actualizar el mensage y lo va a marcar como leido
  const makeAsReadMessage = (id) => {
    Swal.fire({
      title: "Marcar como leído",
      text: "El mensaje se marcará como leido",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Marcar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          title: "Seguimiento al cliente",
          html: `
            <p>Ingresa una breve descripcion de la respuesta obtenida por parte del cliente, después de darle seguimiento</p>
            <br/>
          <textarea name="textarea" rows={10} id="answer" cols={5} class="swal2-input" style="width:370px; height: 350px" placeholder=""/>
          `,
          confirmButtonText: "Continuar",
          preConfirm: () => {
            var answer = Swal.getPopup().querySelector("#answer").value;
            if (!answer) {
              Swal.showValidationMessage(
                `Por favor ingresa una respuesta la respuesta obtenida del cliente, para su seguimiento!`
              );
            }
            return { answer: answer };
          },
        }).then((result) => {
          if (result.value) {
            let url = `/contact/${id}`;
            const formData = new FormData();
            formData.append("answer", result.value.answer);
            MethodPut(url, formData).then((res) => {
              Swal.fire({
                title: "Mensaje Leido",
                text: "El mensaje se marco como leído correctamente!",
                icon: "success",
                timer: 1500,
                showConfirmButton: false,
              });
              dispatch({
                type: MAKE_AS_READ,
                payload: res.data.contact,
              });
            });
          }
        });
      }
    });
  };
  return (
    <ContactContext.Provider
      value={{
        messages: state.messages,
        success: state.success,
        ErrorsAPI: state.ErrorsAPI,
        totalDocs: state.totalDocs,
        limit: state.limit,
        page: state.page,
        paginCounter: state.paginCounter,
        hasPrevPage: state.hasPrevPage,
        prevPage: state.prevPage,
        hasNextPage: state.hasNextPage,
        nextPage: state.nextPage,
        loading: state.loading,
        totalPages: state.totalPages,
        GetAllMessages,
        makeAsReadMessage,
        GetUnreadMessages,
        MessagesReadPerUser,
        attendMessage,
        MessagesCompleted,
      }}
    >
      {children}
    </ContactContext.Provider>
  );
};

export default ContactState;
