import React, { useContext, useState } from "react";
import BanksContext from "../../../context/Banks/BanksContext";
import ModalImage from "react-modal-image-responsive";
import ModalEdit from "../../../containers/Administration/Banks/ModalEdit";

import { makeStyles } from "@mui/styles";
import {
  Box,
  Card,
  Typography,
  Tooltip,
  Grid,
  Menu,
  MenuItem,
  IconButton,
  Button,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { EditIcon } from "../../icons/EditIcon";
import { DeleteIcon } from "../../icons/DeleteIcon";

const useStyles = makeStyles({
  Imagelogo: {
    height: "131px",
    objectFit: "cover",
    textAlign: "center",
  },
});

const BankCard = ({ bank, permiss }) => {
  //Menu
  const classes = useStyles();
  const { DeleteBanks } = useContext(BanksContext);
  const [modalUpdate, AbrirModalUpdate] = useState(false);
  const [id_bank, setIdBank] = useState();
  const handleClickOpenUpdate = () => {
    AbrirModalUpdate(true);
  };
  const handleClickCloseUpdate = () => {
    sessionStorage.removeItem("bank");
    AbrirModalUpdate(false);
  };

  const selectBank = (bank) => {
    setIdBank(bank);
    handleClickOpenUpdate();
  };

  return (
    <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
      <Card sx={{ boxShadow: 3 }}>
        <Box
          fullWidth
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            paddingX: 2,
            backgroundColor: "white",
            color: "black",
          }}
        >
          <Typography
            variant="su arrowbtitle2"
            noWrap
            sx={{ fontWeight: "bold", fontSize: "17px" }}
            className="blink-1"
          >
            {bank.name}
          </Typography>
        </Box>
        <Box
          fullWidth
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <ModalImage
            className={classes.Imagelogo}
            small={bank.image}
            large={bank.image}
            alt={bank.name}
          />
        </Box>
        <Grid container spacing={2}>
        {permiss.update === true && (

          <Grid item xs={6} display="flex" justifyContent="center">
            <Tooltip title="Editar" placement="top">
              <Button
                onClick={() => {
                  selectBank(bank._id);
                }}
              >
                <EditIcon />
              </Button>
            </Tooltip>
          </Grid>
        )}
                {permiss.delete === true && (

          <Grid item xs={6} display="flex" justifyContent="center">
            <Tooltip title="Eliminar" placement="top">
              <Button
                onClick={() => {
                  DeleteBanks(bank._id);
                }}
              >
                <DeleteIcon />
              </Button>
            </Tooltip>
          </Grid>
                )}
        </Grid>
      </Card>

      {id_bank && (
        <ModalEdit
          modalUpdate={modalUpdate}
          id={id_bank}
          handleClickOpen={handleClickOpenUpdate}
          handleCloseEdit={handleClickCloseUpdate}
        />
      )}
    </Grid>
  );
};

export default BankCard;
