import React, { useContext, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import OffersContext from "../../context/Offers/OffersContext";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import CategorySelect from "../../containers/SelectOptions/SelectCategory";
import SubcategoriesSelect from "../../containers/SelectOptions/SelectSubcategories";
import ProductsSelect from "../../containers/SelectOptions/ProductSelect";

import { makeStyles } from "@mui/styles";
import {
  Grid,
  Box,
  Fab,
  TextField,
  Tooltip,
  InputLabel,
  FormHelperText,
  IconButton,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import SelectTypeOffer from "../SelectOptions/selectTypeOffer";

const useStyles = makeStyles({
  logoimagen: {
    height: "200px",
    width: "750px",
    //boxShadow: "5px 5px #289FED",
    objectFit: "cover",
  },
  card: {
    marginTop: 7,
    marginLeft: 7,
    marginRight: 7,
  },
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 2,
  },
  input: {
    display: "none",
  },
});

export default function Add(props) {
  const classes = useStyles();
  const [imageWeb, setImageWeb] = useState({
    urlPhotoWeb:
      "https://ctech-llc.com/tr/wp-content/uploads/2018/12/default.jpg",
    imageWeb: "",
  });

  const handleChangeImageWeb = (e) => {
    setImageWeb({
      ...imageWeb,
      urlPhotoWeb: URL.createObjectURL(e.target.files[0]),
      imageWeb: e.target.files[0],
    });
  };
  //movil
  const [imageMobile, setImageMobile] = useState({
    urlPhotoMobile:
      "https://ctech-llc.com/tr/wp-content/uploads/2018/12/default.jpg",
    imageMobile: "",
  });

  const handleChangeImageMobile = (e) => {
    setImageMobile({
      ...imageMobile,
      urlPhotoMobile: URL.createObjectURL(e.target.files[0]),
      imageMobile: e.target.files[0],
    });
  };

  const formAddOffersContext = useContext(OffersContext);
  const { success, AddOffers } = formAddOffersContext;
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [CategoryId, saveCategory] = useState("");
  const [SubcategoryId, saveSubcategory] = useState("");
  const [ProductId, saveProduct] = useState("");
  const [selectType, saveType] = useState("");

  const detectarCambiosCategory = (e) => {
    saveCategory(e.target.value);
  };

  const detectarCambiosSubcategory = (e) => {
    saveSubcategory(e.target.value);
  };

  const detectarCambiosProducts = (e) => {
    saveProduct(e.target.value);
  };

  const detectarCambiosTypeOffer = (e) => {
    saveType(e.target.value);
  };

  useEffect(() => {
    if (success) {
      props.history.goBack();
    }
  }, [success]);

  const onSubmit = (data, e) => {
    if (!selectType) {
    } else {
      data.type = selectType;
      if (data.type === 1) {
        data.type = selectType;
        data.category = CategoryId;
      } else if (data.type === 2) {
        data.type = selectType;
        data.subcategory = SubcategoryId;
      } else {
        data.type = selectType;
        data.product = ProductId;
      }
      data.imageWeb = imageWeb.imageWeb;
      data.imageMobile = imageMobile.imageMobile;

      AddOffers(data);
    }
  };

  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#000000",
              textAling: "center",
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
              // borderRadius: "0 0 24px 0",
            }}
          >
            <div className={classes.titleCard}>Oferta</div>
            <div className={classes.fab}>
              <Tooltip title="Regresar">
                <Link to="/Administracion-ofertas">
                  <Fab
                    size="small"
                    sx={{
                      color: "white",
                      backgroundColor: "black",
                      borderRadius: "24px",
                      marginRight: 1,
                      "&:hover": {
                        backgroundColor: "white",
                        color: "black",
                      },
                    }}
                  >
                    <ArrowCircleLeftOutlinedIcon />
                  </Fab>
                </Link>
              </Tooltip>
            </div>
          </Box>
          <form
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter")
                e.preventDefault();
            }}
            autoComplete="off"
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                paddingTop: 4,
                p: 3,
                marginBottom: 5,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    fullWidth
                    id="text"
                    name="text"
                    label="Ingresa el titulo de la oferta"
                    variant="outlined"
                    error={errors.text ? true : false}
                    helperText={errors?.text?.message}
                    {...register("text", {
                      required: {
                        value: true,
                        message: "Este campo es requerido",
                      },
                      minLength: {
                        value: 4,
                        message: "Minimo 4 caracteres",
                      },
                      maxLength: {
                        value: 255,
                        message: "Maximo 255 caracteres",
                      },
                    })}
                    sx={{ color: "#343a40" }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <SelectTypeOffer
                    detectarCambiosTypeOffer={detectarCambiosTypeOffer}
                  />
                </Grid>

                {!selectType && (
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <FormControl fullWidth disabled>
                      <InputLabel id="demo-simple-select-disabled-label">
                        Selecciona una opcion
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-disabled-label"
                        id="demo-simple-select-disabled"
                        label="Age"
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem>Ten</MenuItem>
                      </Select>
                      <FormHelperText>Disabled</FormHelperText>
                    </FormControl>
                  </Grid>
                )}

                {selectType === 1 && (
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <CategorySelect
                      detectarCambiosCategory={detectarCambiosCategory}
                    />
                  </Grid>
                )}
                {selectType === 2 && (
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <SubcategoriesSelect
                      detectarCambiosSubcategory={detectarCambiosSubcategory}
                    />
                  </Grid>
                )}
                {selectType === 3 && (
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <ProductsSelect
                      detectarCambiosProducts={detectarCambiosProducts}
                    />
                  </Grid>
                )}
                {/**Slider WEB */}
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div>
                      <img
                        src={imageWeb.urlPhotoWeb}
                        className={classes.logoimagen}
                        alt="agrega slider web"
                      />
                    </div>
                  </Box>

                  <input
                    className={classes.input}
                    id="icon-button--web"
                    type="file"
                    name="imageWeb"
                    accept="image/png, image/jpg, image/jpeg"
                    onChange={handleChangeImageWeb}
                  />
                  <InputLabel sx={{ textAlign: "center" }}>
                    Selecciona el Slider para el Ecommerce Web 1900px * 800px:
                    <label htmlFor="icon-button--web">
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <Tooltip
                          title="seleccionar imagen"
                          aria-label="seleccionar imagen"
                        >
                          <PhotoCamera />
                        </Tooltip>
                      </IconButton>
                    </label>
                  </InputLabel>
                </Grid>
                {/**Slider movile */}
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div>
                      <img
                        src={imageMobile.urlPhotoMobile}
                        className={classes.logoimagen}
                        alt="slider mobile"
                      />
                    </div>
                  </Box>

                  <input
                    className={classes.input}
                    id="icon-button-file-mobile"
                    type="file"
                    name="imageMobile"
                    accept="image/png, image/jpg, image/jpeg"
                    onChange={handleChangeImageMobile}
                  />
                  <InputLabel sx={{ textAlign: "center" }}>
                    Selecciona el Slider para el Ecommerce Movil 1500px * 550px:
                    <label htmlFor="icon-button-file-mobile">
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <Tooltip
                          title="seleccionar imagen"
                          aria-label="seleccionar imagen"
                        >
                          <PhotoCamera />
                        </Tooltip>
                      </IconButton>
                    </label>
                  </InputLabel>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      textAling: "center",
                      paddingBottom: 8,
                    }}
                  >
                    <div className={classes.fab}>
                      <Fab
                        type="submit"
                        variant="extended"
                        sx={{
                          backgroundColor: "#00B9FF",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#00DEED",
                            color: "white",
                          },
                        }}
                      >
                        <SaveOutlinedIcon sx={{ mr: 1 }} />
                        Guardar
                      </Fab>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Grid>
      </Grid>
    </Layout>
  );
}
