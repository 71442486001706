import {
  ADD_SUBCATEGORIES,
  DELETE_SUBCATEGORIES,
  GET_ALL_SUBCATEGORIES,
  GET_ALL_SUBCATEGORIES_CATEGORY,
  IMPORT_SUBCATEGORIES_EXCEL,
  IMPORT_SUBCATEGORIES_EXCEL_ERROR,
  SHOW_ERRORS_API,
  SUBCATEGORIES_NO_PAGINATE,
  UPDATE_SUBCATEGORIES,
} from "../../types";

export default function SubCategoriesReducer(state, action) {
  switch (action.type) {
    case GET_ALL_SUBCATEGORIES:
      return {
        ...state,
        subcategories: action.payload.subcategories,
        totalDocs: action.payload.totalDocs,
        limit: action.payload.limit,
        page: action.payload.page,
        paginCounter: action.payload.paginCounter,
        hasPrevPage: action.payload.hasPrevPage,
        hasNextPage: action.payload.hasNextPage,
        prevPage: action.payload.prevPage,
        nextPage: action.payload.nextPage,
        totalPages: action.payload.totalPages,
        success: false,
        ErrorsAPI: [],
      };
    case SUBCATEGORIES_NO_PAGINATE:
      return {
        ...state,
        subcategories_no_paginate: action.payload,
        ErrorsAPI: [],
      };
    case GET_ALL_SUBCATEGORIES_CATEGORY:
      return {
        ...state,
        subcategoriesCategory: action.payload,
        success: false,
        ErrorsAPI: [],
      };

    case IMPORT_SUBCATEGORIES_EXCEL:
      return {
        ...state,
        success: true,
        ErrorsApi: [],
      };
    case IMPORT_SUBCATEGORIES_EXCEL_ERROR:
      return {
        ...state,
        loading: false,
        success: true,
        ErrorsAPI: [],
      };
    case ADD_SUBCATEGORIES:
      return {
        ...state,
        success: true,
        ErrorsAPI: [],
      };
    case SHOW_ERRORS_API:
      return {
        ...state,
        success: false,
        ErrorsAPI: [],
      };

    case UPDATE_SUBCATEGORIES:
      return {
        ...state,
        success: true,
        ErrorsAPI: [],
      };

    case DELETE_SUBCATEGORIES:
      return {
        ...state,
        success: true,
        subcategories: state.subcategories.filter(
          (subcategory) => subcategory.id !== action.payload
        ),
      };
    default:
      return state;
  }
}
