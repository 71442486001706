import React, { useContext, useEffect, useState } from "react";
import Layout from "../../../components/layout/Layout";
import SliderEcommerceContext from "../../../context/SliderEccomerce/SliderEcommerceContext";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import {
  Card,
  Grid,
  Box,
  Fab,
  Tooltip,
  TextField,
  IconButton,
  InputLabel,
} from "@mui/material";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import PhotoCamera from "@mui/icons-material/PhotoCamera";

const useStyles = makeStyles({
  logoimagen: {
    height: "200px",
    width: "750px",
    // boxShadow: "5px 5px #289FED",
    objectFit: "cover",
  },
  input: {
    display: "none",
  },
  card: {
    marginTop: 7,
    marginLeft: 7,
    marginRight: 7,
  },
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 10,
  },
});

export default function Add(props) {
  const classes = useStyles();
  //web
  const [imageWeb, setImageWeb] = useState({
    urlPhotoWeb:
      "https://ctech-llc.com/tr/wp-content/uploads/2018/12/default.jpg",
    imageWeb: "",
  });

  const handleChangeImageWeb = (e) => {
    setImageWeb({
      ...imageWeb,
      urlPhotoWeb: URL.createObjectURL(e.target.files[0]),
      imageWeb: e.target.files[0],
    });
  };
  //movil
  const [imageMobile, setImageMobile] = useState({
    urlPhotoMobile:
      "https://ctech-llc.com/tr/wp-content/uploads/2018/12/default.jpg",
    imageMobile: "",
  });

  const handleChangeImageMobile = (e) => {
    setImageMobile({
      ...imageMobile,
      urlPhotoMobile: URL.createObjectURL(e.target.files[0]),
      imageMobile: e.target.files[0],
    });
  };

  const { success, AddSlider } = useContext(SliderEcommerceContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  useEffect(() => {
    if (success) {
      props.history.goBack();
    }
  }, [success]);

  const onSubmit = (data, e) => {
    e.preventDefault();
    data.imageWeb = imageWeb.imageWeb;
    data.imageMobile = imageMobile.imageMobile;
    AddSlider(data);
  };

  return (
    <Layout>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card className={classes.card}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#000000",
                textAling: "center",
                boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                borderRadius: "0 0 24px 0",
              }}
            >
              <div className={classes.titleCard}>Ecommerce Slider</div>
              <div className={classes.fab}>
                <Tooltip title="Regresar">
                  <Link to="/EcommerceSlider">
                    <Fab
                      size="small"
                      sx={{
                        backgroundColor: "#C3CAD2",
                        color: "black",
                        "&:hover": {
                          color: "white",
                          backgroundColor: "#C3CAD2",
                        },
                      }}
                    >
                      <ArrowCircleLeftOutlinedIcon />
                    </Fab>
                  </Link>
                </Tooltip>
              </div>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      fullWidth
                      id="text"
                      name="text"
                      label="Ingrese algun Titulo"
                      variant="outlined"
                      type="text"
                      error={errors.text ? true : false}
                      helperText={errors?.text?.message}
                      {...register("text", {
                        required: {
                          value: true,
                          message: "Este campo es requerido",
                        },
                        minLength: {
                          value: 4,
                          message: "Minimo 4 caracteres",
                        },
                        maxLength: {
                          value: 255,
                          message: "Maximo 255 caracteres",
                        },
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      fullWidth
                      multiline
                      id="short_text"
                      name="short_text"
                      label="Descripcion Corta"
                      error={errors.short_text ? true : false}
                      helperText={errors?.short_text?.message}
                      {...register("short_text", {
                        required: {
                          value: true,
                          message: "Este campo es requerido",
                        },
                      })}
                    />
                  </Grid>
                  {/**Slider WEB */}
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        <img
                          src={imageWeb.urlPhotoWeb}
                          className={classes.logoimagen}
                          alt="agrega slider web"
                        />
                      </div>
                    </Box>

                    <input
                      className={classes.input}
                      id="icon-button--web"
                      type="file"
                      name="imageWeb"
                      accept="image/png, image/jpg, image/jpeg"
                      onChange={handleChangeImageWeb}
                    />
                    <InputLabel sx={{ textAlign: "center" }}>
                      Selecciona el Slider para el Ecommerce Web 1920px * 800px:
                      <label htmlFor="icon-button--web">
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <Tooltip
                            title="seleccionar imagen"
                            aria-label="seleccionar imagen"
                          >
                            <PhotoCamera />
                          </Tooltip>
                        </IconButton>
                      </label>
                    </InputLabel>
                  </Grid>
                  {/**Slider movile */}
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        <img
                          src={imageMobile.urlPhotoMobile}
                          className={classes.logoimagen}
                          alt="slider mobile"
                        />
                      </div>
                    </Box>

                    <input
                      className={classes.input}
                      id="icon-button-file-mobile"
                      type="file"
                      name="imageMobile"
                      accept="image/png, image/jpg, image/jpeg"
                      onChange={handleChangeImageMobile}
                    />
                    <InputLabel sx={{ textAlign: "center" }}>
                      Selecciona el Slider para el Ecommerce Movil 1500px *
                      550px:
                      <label htmlFor="icon-button-file-mobile">
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <Tooltip
                            title="seleccionar imagen"
                            aria-label="seleccionar imagen"
                          >
                            <PhotoCamera />
                          </Tooltip>
                        </IconButton>
                      </label>
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                        textAling: "center",
                        paddingBottom: 8,
                      }}
                    >
                      <div className={classes.fab}>
                        <Fab
                          type="submit"
                          variant="extended"
                          sx={{
                            backgroundColor: "#00B9FF",
                            color: "white",
                            "&:hover": {
                              backgroundColor: "#00DEED",
                              color: "white",
                            },
                          }}
                        >
                          <SaveOutlinedIcon sx={{ mr: 1 }} />
                          Guardar
                        </Fab>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Card>
        </Grid>
      </Grid>
    </Layout>
  );
}
