import React, { useState, useContext, useEffect } from "react";
import LogoContext from "../../../context/Logo/LogoContext";
import MethodGet from "../../../config/Service";
import { useForm } from "react-hook-form";

import { makeStyles } from "@mui/styles";
import {
  Grid,
  Box,
  Fab,
  TextField,
  Tooltip,
  InputLabel,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import SpinnerComponent from "../../../components/loading/SpinnerComponent";
import Swal from "sweetalert2";

const useStyles = makeStyles({
  logoimagen: {
    height: "200px",
    boxShadow: "5px 5px #289FED",
    objectFit: "cover",
  },
  input: {
    display: "none",
  },
  card: {
    marginTop: 7,
    marginLeft: 7,
    marginRight: 7,
  },
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 10,
  },
});

export default function EditAdministrableInfo(props) {
  const classes = useStyles();
  const { modalUpdate, handleCloseEdit } = props;
  //   const formEditBrandContext = useContext(BrandsContext);
  const { UpdateLogo, success } = useContext(LogoContext);
  const [Info, setInfo] = useState();
  const [cargando, spinnerCargando] = useState(true);
  //   const [Fullname, setFullname] = useState("");

  const [photo, setImage] = useState({
    urlPhoto:
      "https://cdn.pngsumo.com/index-of-areaedu-wp-content-uploads-2016-02-default-png-600_600.png",
    image: "",
  });

  const handleChangeImage = (e) => {
    setImage({
      ...photo,
      urlPhoto: URL.createObjectURL(e.target.files[0]),
      image: e.target.files[0],
    });
  };
  const GetLogo = async () => {
    let url = await "/administrable/logo";
    MethodGet(url)
      .then(({ data }) => {
        setInfo(data);
        setImage({ urlPhoto: data.logo.logo, image: "" });
        spinnerCargando(false);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
      });
  };
  //   const [Brand, SaveBrand] = useState({}) { defaultValues: Brand }
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  useEffect(() => {
    GetLogo();
  }, []);

  const onSubmit = (data, e) => {
    data.image = photo.image;
    UpdateLogo(data);
    handleCloseEdit();
  };
  useEffect(() => {}, [Info]);
  if (!Info)
    return (
      <>
        <Dialog
          fullWidth
          maxWidth="md"
          open={modalUpdate}
          onClose={handleCloseEdit}>
          <DialogTitle>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#000000",
                textAling: "center",
                boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                borderRadius: "0 0 24px 0",
              }}>
              <div className={classes.titleCard}>Actualiza Informacion</div>
              <div className={classes.fab}>
                <Tooltip title="cerrar" arrow>
                  <Fab
                    size="small"
                    onClick={handleCloseEdit}
                    sx={{
                      color: "#FFFFFF",
                      backgroundColor: "#FF0000",
                      "&:hover": {
                        color: "#FFFFFF",
                        backgroundColor: "#FF0000",
                      },
                    }}>
                    <CancelPresentationIcon />
                  </Fab>
                </Tooltip>
              </div>
            </Box>
          </DialogTitle>

          <DialogContent>
            <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <SpinnerComponent />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        </Dialog>
      </>
    );
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={modalUpdate}
      onClose={handleCloseEdit}>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#000000",
            textAling: "center",
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            borderRadius: "0 0 24px 0",
          }}>
          <div className={classes.titleCard}>Actualiza Informacion</div>
          <div className={classes.fab}>
            <Tooltip title="cerrar" arrow>
              <Fab
                size="small"
                onClick={handleCloseEdit}
                sx={{
                  color: "#FFFFFF",
                  backgroundColor: "#FF0000",
                  "&:hover": {
                    color: "#FFFFFF",
                    backgroundColor: "#FF0000",
                  },
                }}>
                <CancelPresentationIcon />
              </Fab>
            </Tooltip>
          </div>
        </Box>
      </DialogTitle>

      <DialogContent>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.code === "Enter") e.preventDefault();
          }}
          autoComplete="off">
          <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                  <div>
                    <img
                      src={photo.urlPhoto}
                      className={classes.logoimagen}
                      alt="agrega marca"
                    />
                  </div>
                </Box>

                <input
                  className={classes.input}
                  id="icon-button-file"
                  type="file"
                  name="image"
                  accept="image/png, image/jpg, image/webp"
                  onChange={handleChangeImage}
                />
                <InputLabel sx={{ textAlign: "center" }}>
                  Selecciona Imagen o logo :
                  <label htmlFor="icon-button-file">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span">
                      <Tooltip
                        title="seleccionar imagen"
                        aria-label="seleccionar imagen">
                        <PhotoCamera />
                      </Tooltip>
                    </IconButton>
                  </label>
                </InputLabel>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  focused
                  fullWidth
                  sx={{ marginBottom: 2 }}
                  id="top_text"
                  placeholder=""
                  name="top_text"
                  defaultValue={Info.logo.top_text}
                  label="Titulo "
                  variant="outlined"
                  type="text"
                  error={errors.top_text ? true : false}
                  helperText={errors?.top_text?.message}
                  {...register("top_text", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                    minLength: {
                      value: 4,
                      message: "Minimo 4 caracteres",
                    },
                    maxLength: {
                      value: 255,
                      message: "Maximo 255 caracteres",
                    },
                  })}
                />

                <TextField
                  focused
                  fullWidth
                  sx={{ marginBottom: 2 }}
                  id="facebook"
                  placeholder=""
                  defaultValue={Info.logo.facebook}
                  name="facebook"
                  label="Link de Facebook "
                  variant="outlined"
                  error={errors.facebook ? true : false}
                  helperText={errors?.facebook?.message}
                  {...register("facebook", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                    pattern: {
                      value: /http(?:s):\/\/(?:www\.)facebook\.com\/.+/i,
                      message: "Error: No es un Link de Facebook",
                    },
                  })}
                />

                <TextField
                  focused
                  fullWidth
                  sx={{ marginBottom: 2 }}
                  id="instagram"
                  placeholder=""
                  name="instagram"
                  defaultValue={Info.logo.instagram}
                  label="Link de Instagram "
                  variant="outlined"
                  error={errors.instagram ? true : false}
                  helperText={errors?.instagram?.message}
                  {...register("instagram", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                    //   minLength: {
                    //     value: 4,
                    //     message: "Minimo 4 caracteres",
                    //   },
                    //   maxLength: {
                    //     value: 255,
                    //     message: "Maximo 255 caracteres",
                    //   },
                    pattern: {
                      value: /http(?:s):\/\/(?:www\.)instagram\.com\/.+/i,
                      message: "Error: No es un Link de Instagram",
                    },
                    //   onChange: ({ target }) => {
                    //     setFullname(target.value);
                    //   },
                  })}
                />

                <TextField
                  sx={{ marginBottom: 2 }}
                  focused
                  fullWidth
                  id="tiktok"
                  placeholder=""
                  name="tiktok"
                  defaultValue={Info.logo.tiktok}
                  label="Link de Tiktok "
                  variant="outlined"
                  error={errors.tiktok ? true : false}
                  helperText={errors?.tiktok?.message}
                  {...register("tiktok", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                    pattern: {
                      value:
                        /http(?:s):\/\/(?:www\.)tiktok\.com\/.+|http(?:s):\/\/(?:mv\.)tiktok\.com\/.+/i,
                      message: "Error: No es un Link de Tiktok",
                    },
                  })}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    textAling: "center",
                  }}>
                  <div className={classes.fab}>
                    <Fab
                      type="submit"
                      variant="extended"
                      sx={{
                        backgroundColor: "#00B9FF",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#00DEED",
                          color: "white",
                        },
                      }}>
                      <SaveOutlinedIcon sx={{ mr: 1 }} />
                      Guardar
                    </Fab>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </DialogContent>
      {/* )}  */}
    </Dialog>
  );
}
