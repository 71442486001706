import React, { useState, useEffect, useContext } from "react";
import BusinessRulesContext from "../../context/BusinessRules/BusinessRulesContext";
import SelectTypeBusiness from "../SelectOptions/TypeSelectBR";
import SubcategoriesSelect from "../SelectOptions/SelectSubcategories";
import MethodGet from "../../config/Service";
import { useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import CategoriesSelect from "../SelectOptions/SelectCategory";
import { SelectTypeDirection } from "../SelectOptions/SelectTypeDirection";
import {
  Grid,
  Box,
  Fab,
  TextField,
  Tooltip,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import SelectTypeClient from "../SelectOptions/SelectTypeClients";
import SelectSubcategories from "../SelectOptions/SelectSubcategories";

const useStyles = makeStyles({
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 1,
  },
});

export default function Edit({ modalUpdate, handleCloseEdit, id }) {
  const classes = useStyles();
  const {
    success,
    UpdateBusinessRules,
    UpdateBusinessRulesSalesFisic,
    UpdateBusinessRulesSubcategory,
    UpdateBusinessRulesCategory,
    UpdateBusinessRulesShippment,
  } = useContext(BusinessRulesContext);
  const [cargando, spinnerCargando] = useState(false);
  const [minimumMoney, setMinimumMoney] = useState(0);
  const [minimumWeigth, setMinimumWeigth] = useState(0);

  const [SubcategoryId, saveSubcategory] = useState("");
  const [typeId, setTypeId] = useState("");
  const detectarCambiosTypeBusinessRule = (e) => {
    setTypeId(e.target.value);
  };
  const detectarCambiosSubcategory = (e) => {
    saveSubcategory(e.target.value);
  };
  //select typeDirection
  const [typeDirection, saveTypeDirection] = useState(null);
  const detectarCambiosTypeDirection = (e) => {
    saveTypeDirection(e.target.value);
  };

  //select tipo de cliente
  const [typeClientId, saveTypeClientId] = useState();
  const detectarCambiosTypeClients = (e) => {
    saveTypeClientId(e.target.value);
  };
  //select categoria
  const [categoryId, saveCategoryId] = useState();
  const detectarCambiosCategory = (e) => {
    saveCategoryId(e.target.value);
  };
  const [BusinessRules, saveBusinessRules] = useState({});
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({ defaultValues: BusinessRules });
  useEffect(() => {
    let url = `/business-rules/${id}`;
    MethodGet(url)
      .then((res) => {
        saveBusinessRules(res.data.business_rule);
        setMinimumMoney(parseInt(res.data.business_rule.minimum_money) + 1);
        setMinimumWeigth(parseInt(res.data.business_rule.minimun_weigth) + 1);
        saveTypeDirection(res.data.business_rule.type_direction);
        setTypeId(res.data.business_rule.type);
        saveSubcategory(res.data.business_rule.subcategory);
        spinnerCargando(true);
      })
      .catch((error) => {
        spinnerCargando(true);
      });
  }, [id]);
  useEffect(() => {
    if (BusinessRules) {
      setValue("maximum_money", BusinessRules.maximum_money);
      setValue("minimum_money", BusinessRules.minimum_money);
      setValue("discount", BusinessRules.discount);
      setValue("maximum_weigth", BusinessRules.maximum_weigth);
      setValue("minimum_weigth", BusinessRules.minimum_weigth);
      setValue("shipping_cost", BusinessRules.shippment_costs);
    } else {
      setValue("maximum_money", "");
      setValue("minimum_money", "");
      setValue("discount", "");
    }
  }, [BusinessRules]);

  const onSubmit = (data, e) => {
    data.type = typeId;
    data.id = id;
    if (data.type === 1) {
      data.subcategory = SubcategoryId;
      data.type = typeId;
      UpdateBusinessRulesSubcategory(data);
    }
    if (data.type === 2) {
      data.type = typeId;
      UpdateBusinessRules(data);
    }
    if (data.type === 4) {
      data.type = typeId;
      data.category = categoryId;
      UpdateBusinessRulesCategory(data);
    }
    if (data.type === 5) {
      data.type = typeId;
      data.customerType = typeClientId;
      UpdateBusinessRulesSalesFisic(data);
    }
    if (data.type === 6) {
      data.type = typeId;
      data.typeDirection = typeDirection;
      UpdateBusinessRulesShippment(data);
    }
    handleCloseEdit();
  };

  if (!cargando) return "";
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={modalUpdate}
      onClose={handleCloseEdit}
    >
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#000000",
            textAling: "center",
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            borderRadius: "0 0 24px 0",
          }}
        >
          <div className={classes.titleCard}>
            Actualiza Regla de negocio de ventas
          </div>
          <div className={classes.fab}>
            <Tooltip title="cerrar" arrow>
              <Fab
                size="small"
                onClick={handleCloseEdit}
                sx={{
                  color: "#FFFFFF",
                  backgroundColor: "#FF0000",
                  "&:hover": {
                    color: "#FFFFFF",
                    backgroundColor: "#FF0000",
                  },
                }}
              >
                <CancelPresentationIcon />
              </Fab>
            </Tooltip>
          </div>
        </Box>
      </DialogTitle>
      {!cargando ? (
        <CircularProgress />
      ) : (
        <DialogContent>
          <form
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter")
                e.preventDefault();
            }}
            autoComplete="off"
          >
            <Box sx={{ display: "flex", paddingTop: 4, p: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <SelectTypeBusiness
                    TypeId={typeId}
                    detectarCambiosTypeBusinessRule={
                      detectarCambiosTypeBusinessRule
                    }
                  />
                </Grid>
                {typeId === 2 || typeId === 5 ? (
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      id="minimum_money"
                      label="Cantidad Minima"
                      name="minimum_money"
                      variant="outlined"
                      type="number"
                      error={errors.minimum_money ? true : false}
                      helperText={errors?.minimum_money?.message}
                      {...register("minimum_money", {
                        required: {
                          value: true,
                          message: "La cantidad minima es requerida",
                        },
                        pattern: {
                          value: /^([0-9])*$/,
                          message: "Error: Solo una cantidad entera",
                        },
                        onChange: ({ target }) => {
                          const minValor = parseInt(target.value) + 1;
                          setMinimumMoney(minValor);
                        },
                      })}
                    />
                  </Grid>
                ) : (
                  <></>
                )}
                {typeId === 2 || typeId === 5 ? (
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      id="maximum_money"
                      label="Cantidad Maxima"
                      name="maximum_money"
                      variant="outlined"
                      type="number"
                      error={errors.maximum_money ? true : false}
                      helperText={errors?.maximum_money?.message}
                      {...register("maximum_money", {
                        required: {
                          value: true,
                          message: "La cantidad maxima es requerida",
                        },
                        pattern: {
                          value: /^([0-9])*$/,
                          message: "Error: Solo una cantidad entera",
                        },
                        min: {
                          value: parseInt(minimumMoney),
                          message:
                            "El catidad maxima tiene que ser mayor a la cantidad minima",
                        },
                      })}
                    />
                  </Grid>
                ) : (
                  <></>
                )}
                {typeId === 5 && (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <SelectTypeClient
                      detectarCambiosTypeClients={detectarCambiosTypeClients}
                    />
                  </Grid>
                )}
                {typeId === 1 && (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <SelectSubcategories
                      detectarCambiosSubcategory={detectarCambiosSubcategory}
                    />
                  </Grid>
                )}
                {typeId === 4 && (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <CategoriesSelect
                      detectarCambiosCategory={detectarCambiosCategory}
                    />
                  </Grid>
                )}
                {typeId !== "" && typeId === 6 && (
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      id="minimum_weigth"
                      label="Peso Minimo"
                      name="minimum_weigth"
                      variant="outlined"
                      type="number"
                      error={errors.minimum_weigth ? true : false}
                      helperText={errors?.minimum_weigth?.message}
                      {...register("minimum_weigth", {
                        required: {
                          value: true,
                          message: "El peso minimo es requerido",
                        },
                        pattern: {
                          value: /^([0-9])*$/,
                          message: "Error: Solo una cantidad entera",
                        },
                        onChange: ({ target }) => {
                          const minValor = parseInt(target.value) + 1;
                          setMinimumWeigth(minValor);
                        },
                      })}
                    />
                  </Grid>
                )}
                {typeId !== "" && typeId === 6 && (
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      id="maximum_weigth"
                      label="Peso Maximo"
                      name="maximum_weigth"
                      variant="outlined"
                      type="number"
                      error={errors.maximum_weigth ? true : false}
                      helperText={errors?.maximum_weigth?.message}
                      {...register("maximum_weigth", {
                        required: {
                          value: true,
                          message: "El peso maximo es requerido",
                        },
                        pattern: {
                          value: /^([0-9])*$/,
                          message: "Error: Solo una cantidad entera",
                        },
                        min: {
                          value: parseInt(minimumWeigth),
                          message:
                            "El catidad maxima tiene que ser mayor a la cantidad minima",
                        },
                      })}
                    />
                  </Grid>
                )}
                {typeId !== "" && typeId === 6 && (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      fullWidth
                      id="shipping_cost"
                      label="Costo de envio"
                      variant="outlined"
                      name="shipping_cost"
                      type="number"
                      error={errors.shipping_cost ? true : false}
                      helperText={errors?.shipping_cost?.message}
                      {...register("shipping_cost", {
                        required: {
                          value: true,
                          message: "El costo de envio requerido",
                        },
                      })}
                    />
                  </Grid>
                )}
                {typeId !== "" && typeId === 6 && (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <SelectTypeDirection
                      detectarCambiosTypeDirection={
                        detectarCambiosTypeDirection
                      }
                      typeDirection={typeDirection}
                    />
                  </Grid>
                )}
                {typeId !== null && typeId !== 6 && (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      fullWidth
                      id="discount"
                      label="Porcentaje de Descuento"
                      variant="outlined"
                      name="discount"
                      type="number"
                      error={errors.discount ? true : false}
                      helperText={errors?.discount?.message}
                      {...register("discount", {
                        required: {
                          value: true,
                          message: "El descuento requerido",
                        },
                        min: {
                          value: 1,
                          message: "Minimo 1% de descuento",
                        },
                        max: {
                          value: 50,
                          message: "Maximo 50% de descuento",
                        },
                      })}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      textAling: "center",
                    }}
                  >
                    <div className={classes.fab}>
                      <Fab
                        variant="extended"
                        type="submit"
                        sx={{
                          backgroundColor: "#00B9FF",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#00DEED",
                            color: "white",
                          },
                        }}
                      >
                        <SaveOutlinedIcon sx={{ mr: 1 }} />
                        Guardar
                      </Fab>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </form>
        </DialogContent>
      )}
    </Dialog>
  );
}
