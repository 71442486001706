import React, { useReducer } from "react";
import Swal from "sweetalert2";
import {
  GET_ALL_CLIENTS,
  SEARCH_CLIENTS,
  SHOW_ERRORS_API,
  REGISTER_CLIENT,
  GET_CLIENTS,
  GET_CLIENTS_ACCOUNTS,
} from "../../types";
import MethodGet, { MethodPost } from "../../config/Service";

import ClientsContext from "./ClientsContext";
import ClientsReducer from "./ClientsReducer";
import { CREATE_CLIENT_ACCOUNT } from "./../../types/index";

const ClientsState = ({ children }) => {
  //state inicial
  const initialState = {
    clients: [],
    clientsAccounts: [],
    search_clients: [],
    client: null,
    ErrorsApi: [],
    success: false,
  };

  const [state, dispatch] = useReducer(ClientsReducer, initialState);

  const GetClients = (params) => {
    sessionStorage.removeItem("client");
    //params.pages += 1;
    params.pages += 1;
    let url = `/clients?limit=${params.limite}&page=${params.pages}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_CLIENTS,
          payload: {
            clients: res.data.clients,
            totalDocs: res.data.totalDocs,
            limit: res.data.limit,
            page: res.data.page,
            totalPages: res.data.totalPages,
            paginCounter: res.data.paginCounter,
            hasPrevPage: res.data.hasPrevPage,
            hasNextPage: res.data.hasNextPage,
            prevPage: res.data.prevPage,
            nextPage: res.data.nextPage,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.message,
        });
      });
  };
  const searchClient = (palabra) => {
    let url = `/search/clients?search=${palabra}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: SEARCH_CLIENTS,
          payload: res.data.results,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const GetClientsNoPagination = () => {
    sessionStorage.removeItem("client");
    let url = "/clients/no-pagination/all";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_CLIENTS,
          payload: res.data.clients,
        });
      })
      .catch((error) => {
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.message,
        });
      });
  };

  const RegisterClient = (data) => {
    const formData = new FormData();
    formData.append("fullname", data.fullname);
    formData.append("email", data.email);
    // formData.append("customer_type", data.customer_type);

    let url = "/clients/new/client";
    MethodPost(url, formData)
      .then((res) => {
        Swal.fire({
          title: "Registrado",
          icon: "success",
          text: res.data.message,
          showConfirmButton: false,
          timer: 2000,
        });
        dispatch({
          type: REGISTER_CLIENT,
          payload: res.data.client,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
          text: error.response.data.message,
        });
      });
  };

  const CreateClientAccount = (data) => {
    // const formData = new FormData();
    let url = `/clients`;
    MethodPost(url, data)
      .then((res) => {
        dispatch({
          type: CREATE_CLIENT_ACCOUNT,
          payload: res.data.user,
        });
        Swal.fire({
          title: "Registrado",
          text: res.data.message,
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text:
            "message: " +
            error.response.data.message +
            ", " +
            "Error: " +
            error.response.data.error,
          icon: "error",
          //timer: 1500,
          //showConfirmButton: false,
        });
      });
  };

  const GetClientAccount = () => {
    let url = "/clients/extern";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_CLIENTS_ACCOUNTS,
          payload: res.data.clients,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <ClientsContext.Provider
      value={{
        clients: state.clients,
        client: state.client,
        ErrorsApi: state.ErrorsApi,
        success: state.success,
        totalDocs: state.totalDocs,
        limit: state.limit,
        page: state.page,
        paginCounter: state.paginCounter,
        hasPrevPage: state.hasPrevPage,
        prevPage: state.prevPage,
        hasNextPage: state.hasNextPage,
        nextPage: state.nextPage,
        totalPages: state.totalPages,
        search_clients: state.search_clients,
        clientsAccounts: state.clientsAccounts,
        searchClient,
        GetClients,
        GetClientsNoPagination,
        RegisterClient,
        CreateClientAccount,
        GetClientAccount,
      }}>
      {children}
    </ClientsContext.Provider>
  );
};

export default ClientsState;
