import { ADD_BRANDS, CURRENT_BRAND, DELETE_BRANDS, GET_ALL_BRANDS, SHOW_ERRORS_API, UPDATE_BRANDS } from "../../types";

export default function BrandsReducer(state, action) {
    switch (action.type) {
        case GET_ALL_BRANDS:
            return {
                ...state,
                brands: action.payload,
                success: false,
                ErrorsAPI: []
            }
        case ADD_BRANDS:
            return {
                ...state,
                success: true,
                ErrorsAPI: []
            }
        case SHOW_ERRORS_API:
            return {
                ...state,
                success: true,
                ErrorsAPI: []
            }
        case CURRENT_BRAND:
            return {
                ...state,
                mark: action.payload,
            }
        case UPDATE_BRANDS:
            return {
                ...state,
                success: true,
                ErrorsAPI: []
            }
        case DELETE_BRANDS:
            return {
                ...state,
                success: true,
                brands: state.brands.filter(brand => brand.id !== action.payload)
            }
        default:
            return state;
    }
}