import React, { useContext, useState } from "react";
import TagsContext from "../../../context/Tags/TagsContext";
import ModalEdit from "../../../containers/tags/Edit";
import {
  Box,
  Card,
  Typography,
  Tooltip,
  Grid,
  IconButton,
} from "@mui/material";
import StyleIcon from "@mui/icons-material/Style";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
export default function TagCard({ tag, permiss }) {
  //Menu
  const [modalUpdate, AbrirModalUpdate] = useState(false);
  const [id_brand, setIdBrand] = useState({});
  const handleClickOpenUpdate = () => {
    AbrirModalUpdate(true);
  };
  const handleClickCloseUpdate = () => {
    sessionStorage.removeItem("tag");
    AbrirModalUpdate(false);
  };
  //
  const { DeleteTags } = useContext(TagsContext);

  const SelectTag = (tag) => {
    setIdBrand(tag);
    handleClickOpenUpdate();
  };

  return (
    <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
      <Card>
        <Box
          fullWidth
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            paddingX: 2,
            backgroundColor: "white",
            color: "black",
          }}>
          <Typography
            variant="su arrowbtitle2"
            noWrap
            sx={{ fontWeight: "bold", fontSize: "17px" }}
            className="blink-1">
            <StyleIcon /> {tag.name}
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {permiss.update === true && (
            <Grid item xs={6} display="flex" justifyContent="center">
              <Tooltip title="editar" placement="top">
                <IconButton
                  onClick={() => {
                    SelectTag(tag._id);
                  }}>
                  <CreateIcon className="button_primary" />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
          {permiss.delete === true && (
            <Grid item xs={6} display="flex" justifyContent="center">
              <Tooltip title="Eliminar" placement="top">
                <IconButton
                  onClick={() => {
                    DeleteTags(tag._id);
                  }}>
                  <DeleteIcon className="button_danger" />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Card>
      <ModalEdit
        modalUpdate={modalUpdate}
        id={tag._id}
        handleClickOpen={handleClickOpenUpdate}
        handleCloseEdit={handleClickCloseUpdate}
      />
    </Grid>
  );
}
