import React, { useContext, useEffect, useState } from "react";
import BanksContext from "../../../context/Banks/BanksContext";
import BankCard from "../../../components/Cards/bank/BankCard";
import ModalAdd from "./AddModal";

import { makeStyles } from "@mui/styles";
import { Button, CardContent, Grid } from "@mui/material";
import { Box } from "@mui/system";
import ModulesContext from "../../../context/Permissions/PermissionsContext";

const useStyles = makeStyles(() => ({
  titleCard: {
    color: "white",
    fontSize: "35px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 2,
    marginRight: 5,
  },
  img_data: {
    margin: "auto",
    height: "90%",
  },
}));

export default function Banks() {
  const classes = useStyles();

  const [modalAdd, AbrirModalAdd] = useState(false);
  const handleClickOpenAdd = () => {
    AbrirModalAdd(true);
  };
  const handleClickCloseAdd = () => {
    AbrirModalAdd(false);
  };

  const { banks, GetBanks, success } = useContext(BanksContext);
  const { permissionsPerUser, GetModulesPerUser } = useContext(ModulesContext);

  useEffect(() => {
    if (localStorage.getItem("module_id")) {
      const permiss = localStorage.getItem("module_id");
      GetModulesPerUser(permiss);
    }
    GetBanks();
  }, [success]);

  return (
    <>
      <Grid container spacing={2} sx={{ backgroundColor: "white" }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            display="flex"
            justifyContent="end">
            {permissionsPerUser.write === true && (
              <div className={classes.fab}>
                <Button
                  onClick={handleClickOpenAdd}
                  sx={{
                    color: "black",
                    backgroundColor: "#26c6da",
                    hoverColor: "white",
                    fontFamily: "monospace",
                    fontWeight: "bolder",
                    fontSize: "15px",
                    textTransform: "none",
                    "&:hover": {
                      color: "black",
                      backgroundColor: "#67cedb",
                      hoverColor: "white",
                      fontFamily: "monospace",
                      fontWeight: "bolder",
                      fontSize: "15px",
                    },
                  }}>
                  Agregar
                </Button>
              </div>
            )}
          </Grid>
          <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={2}>
                  {!banks.length ? (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <img
                        src={require("../../../assets/gif/noData.gif")}
                        className={classes.img_data}
                        alt="NoData"
                      />
                    </Grid>
                  ) : (
                    banks.map((bank) => (
                      <BankCard
                        permiss={permissionsPerUser}
                        bank={bank}
                        key={bank._id}
                      />
                    ))
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <CardContent></CardContent>
      </Grid>
      <ModalAdd
        modal={modalAdd}
        handleClickOpen={handleClickOpenAdd}
        handleClose={handleClickCloseAdd}
      />
    </>
  );
}
