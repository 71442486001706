import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, TextField } from "@mui/material";
import InventoriesContext from "../../context/Inventories/InventoriesContext";
import { useContext } from "react";
import { useForm } from "react-hook-form";

export default function ModalChangeQuantity({
  open,
  handleClose,
  p_id,
  p_name,
  id_inventory,
}) {
  const { UpdateQuantityProduct } = useContext(InventoriesContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const reset = () => {
    setValue("quantity", "", { shouldDirty: true });
  };
  const onSubmit = (data, e) => {
    data.product_id = p_id;
    data.inventory_id = id_inventory;

    UpdateQuantityProduct(data);
    handleClose();
    reset();
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Remplazar Stock ${p_name}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(e) => {
                if (e.code === "Enter" || e.code === "NumpadEnter")
                  e.preventDefault();
              }}
              autoComplete="off"
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ marginTop: 2 }}
                >
                  <TextField
                    type="number"
                    fullWidth
                    name="quantity"
                    label="Ingresa la nueva cantidad"
                    placeholder="99"
                    error={errors.quantity ? true : false}
                    helperText={errors?.quantity?.message}
                    {...register("quantity", {
                      required: {
                        value: true,
                        message: "Este campo es requerido",
                      },
                    })}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  display="flex"
                  justifyContent="end"
                >
                  <Button
                    variant="contained"
                    onClick={handleClose}
                    sx={{
                      backgroundColor: "#b71c1c",
                      color: "white",
                      "&:hover": { backgroundColor: "#b71c1c", color: "white" },
                    }}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  display="flex"
                  justifyContent="start"
                >
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#2e7d32",
                      color: "white",
                      "&:hover": { backgroundColor: "#2e7d32", color: "white" },
                    }}
                    type="submit"
                  >
                    Actualizar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
