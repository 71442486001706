import {
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import TagsContext from "../../context/Tags/TagsContext";
import Select from "react-select";

export default function SelectTags(props) {
  const GetTagsList = useContext(TagsContext);
  const { tags_no_paginate, GetTagsNoPaginate, success } = GetTagsList;
  const [tagName, setTagName] = useState({
    tags: [],
    tagsSelected: [],
  });
  useEffect(() => {
    GetTagsNoPaginate();
  }, [success]);
  useEffect(() => {
    if (tags_no_paginate.length > 0) {
      const tagsList = tags_no_paginate.map((tag) => {
        let attribute = {
          label: tag.name,
          value: tag._id,
        };
        return attribute;
      });

      setTagName({ tags: tagsList });
    }
  }, [tags_no_paginate]);

  const detectarCambiosTagsAdd = (value) => {
    props.detectarCambiosTagsAdd(value);
  };

  const detectarCambiosTagEdit = (value) => {
    setTagName({
      ...tagName,
      tagsSelected: value,
    });
    props.savePruebaTags(value);
  };

  const productsTags = props.tagId
    ? props.tagId.map(({ tag_id }) => {
        let TagSelected = {
          label: tag_id.name,
          value: tag_id._id,
        };
        return TagSelected;
      })
    : [];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        {props.tagId ? (
          <Select
            onChange={(value) => detectarCambiosTagEdit(value)}
            isMulti
            name="tags"
            autoFocus
            options={tagName.tags}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Selecciona Etiquetas"
            defaultValue={productsTags}
          />
        ) : (
          <Select
            onChange={(value) => detectarCambiosTagsAdd(value)}
            isMulti
            name="tags"
            styles={{ height: 60, backgroundColor: "black" }}
            placeholder="Selecciona Etiquetas"
            autoFocus
            options={tags_no_paginate.map((tag) => {
              let attribute = {
                label: tag.name,
                value: tag._id,
              };
              return attribute;
            })}
            className="basic-multi-select"
            classNamePrefix="select"
          />
        )}
      </Grid>
    </Grid>
  );
}
