import * as React from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material";
import ProductsContext from "../../context/Products/ProductsContext";
import { useContext, useState } from "react";
import ModalImportProducts from "./Imports/ModalImportProducts";
import ModalUpdateProducts from "./Imports/ModalUpdateProducts";

export default function TabsOptions() {
  const {
    ExportProductsExcel,
    ExportUpdateProductsExcel,
    DownloadReportStockActual,
    ExportAllProductsStockExcel,
  } = useContext(ProductsContext);
  //modal para importar
  const [modalImport, AbrirModalImport] = useState(false);
  const handleClickOpenImport = () => {
    AbrirModalImport(true);
  };
  const handleClickCloseImport = () => {
    AbrirModalImport(false);
  };
  //Modal para actualizar
  const [modalUpdate, AbrirModalUpdate] = useState(false);
  const handleClickOpenUpdate = () => {
    AbrirModalUpdate(true);
  };
  const handleClickCloseUpdate = () => {
    AbrirModalUpdate(false);
  };
  return (
    <>
      <Grid container spacing={2} display="flex" justifyContent="space-between">
        {/* <Stack direction="row" spacing={1}> */}
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={2}
          xl={2}
          display="flex"
          justifyContent="center"
        >
          <Chip
            onClick={() => ExportProductsExcel()}
            sx={{
              backgroundColor: "#388e3c",
              color: "white",
              "&:hover": { backgroundColor: "#388e3c", color: "white" },
            }}
            label="Descargar Plantilla"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={2}
          xl={2}
          display="flex"
          justifyContent="center"
        >
          <Chip
            onClick={() => handleClickOpenImport()}
            sx={{
              backgroundColor: "#43a047",
              color: "white",
              "&:hover": {
                backgroundColor: "#43a047",
                color: "white",
              },
            }}
            label="Importar Productos"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={2}
          xl={2}
          display="flex"
          justifyContent="center"
        >
          <Chip
            onClick={() => ExportUpdateProductsExcel()}
            sx={{
              backgroundColor: "#03a9f4",
              color: "white",
              "&:hover": {
                backgroundColor: "#03a9f4",
                color: "white",
              },
            }}
            label="Plantilla Actualizar"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={2}
          xl={2}
          display="flex"
          justifyContent="center"
        >
          <Chip
            onClick={() => handleClickOpenUpdate()}
            sx={{
              backgroundColor: "#ec407a",
              color: "white",
              "&:hover": {
                backgroundColor: "#ec407a",
                color: "white",
              },
            }}
            label="Importar Actualizar"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={2}
          xl={2}
          display="flex"
          justifyContent="center"
        >
          <Chip
            onClick={() => DownloadReportStockActual()}
            sx={{
              backgroundColor: "#2e7d32",
              color: "white",
              "&:hover": {
                backgroundColor: "#2e7d32",
                color: "white",
              },
            }}
            label="Stock PDF"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={2}
          xl={2}
          display="flex"
          justifyContent="center"
        >
          <Chip
            onClick={() => ExportAllProductsStockExcel()}
            sx={{
              backgroundColor: "#f48fb1",
              color: "white",
              "&:hover": {
                backgroundColor: "#f48fb1",
                color: "white",
              },
            }}
            label="Stock Excel "
          />
        </Grid>
        {/* </Stack> */}
      </Grid>
      <ModalImportProducts
        modalImport={modalImport}
        handleClickOpen={handleClickOpenImport}
        handleClickClose={handleClickCloseImport}
      />
      <ModalUpdateProducts
        modalUpdate={modalUpdate}
        handleClickOpen={handleClickOpenUpdate}
        handleClickClose={handleClickCloseUpdate}
      />
    </>
  );
}
