import React, { Fragment } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import ProductsContext from "../../context/Products/ProductsContext";
import Select from "react-select";
export default function ProductSelectAllProductsInventory(props) {

  const { productsOnInventory } = props;
  const { products, GetAllProductsSelect } = useContext(ProductsContext);
  const productsAddes = productsOnInventory.map((product) => {
    let attribute = {
      _id: product.product_id._id,
    };
    return attribute;
  });
  useEffect(() => {
    GetAllProductsSelect();
  }, []);

  let setNewProducts = [];
  products.map((product) => {
    let test = productsAddes.find((pro) => pro._id == product._id);
    if (test) {
    } else {
      setNewProducts.push(product);
    }
  });

  const detectarCambiosProduct = (value) => {
    props.detectarCambiosProduct(value);
  };

  return (
    <Fragment>
      <Select
        onChange={(value) => detectarCambiosProduct(value)}
        className="basic-single"
        classNamePrefix="select"
        name="product"
        defaultValue={{ label: "Selecciona un producto ", value: "" }}
        placeholder="Selecciona producto"
        options={setNewProducts.map((product) => {
          let attribute = {
            label: product.name,
            value: product._id,
          };
          return attribute;
        })}
      />
    </Fragment>
  );
}
