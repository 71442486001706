import React, { useContext, useEffect, useState } from "react";
import TypeUsersContext from "../../context/TypeUsers/TypeUsersContext";
import TypeUsersCard from "../../components/Cards/type_users/TypeUsersCard";
import ModalAdd from "./Add";
import NoDataComponents from "../../components/loading/NoDataComponents";
import { Grid, Tooltip, Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 2,
  },
}));

const TypeUsers = () => {
  //abrir modal
  const [modalAdd, AbrirModalAdd] = useState(false);
  const handleClickOpenAdd = () => {
    AbrirModalAdd(true);
  };
  const handleClickCloseAdd = () => {
    AbrirModalAdd(false);
  };

  const classes = useStyles();
  const { type_users, GetTypeUsers } = useContext(TypeUsersContext);

  useEffect(() => {
    GetTypeUsers();
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              textAling: "center",
            }}>
            <div className={classes.fab}>
              <Tooltip title="Agregar">
                <Button
                  onClick={handleClickOpenAdd}
                  size="large"
                  sx={{
                    color: "white",
                    backgroundColor: "#039be5",
                    borderRadius: "24px",
                    marginRight: 1,
                    "&:hover": {
                      backgroundColor: "#039be5",
                      color: "white",
                    },
                  }}>
                  Agregar
                </Button>
              </Tooltip>
            </div>
          </Box>
          <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={2}>
                  {!type_users.length ? (
                    <NoDataComponents />
                  ) : (
                    type_users.map((typeuser) => (
                      <TypeUsersCard typeuser={typeuser} key={typeuser._id} />
                    ))
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <ModalAdd
        modal={modalAdd}
        handleClickOpen={handleClickOpenAdd}
        handleClose={handleClickCloseAdd}
      />
    </>
  );
};

export default TypeUsers;
