import { Button, Grid, Stack, TextField } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import esLocale from "date-fns/locale/es";
import { formatDateNow, formatDateSelect } from "../../utils/FormatDate";
/**new pickers */
import { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
function ExpensesReport() {
  //fecha
  const [valueDesktopDatePickerSelected, setValueDesktopDatePickerSelected] =
    useState(new Date());
  const MINDATENOW = formatDateNow(valueDesktopDatePickerSelected);
  const [pickerFecini, SetpickerIni] = useState(null);
  const [pickerFecter, SetpickerTer] = useState(null);
  //datepicker
  const [fecha_inicio, setFechaInicio] = useState("");
  const [fecha_fin, setFechaFin] = useState("");
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const onSubmit = (data, e) => {};
  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.code === "Enter" || e.code === "NumpadEnter")
            e.preventDefault();
        }}
        autoComplete="off"
      >
        <Grid container spacing={2} sx={{ marginTop: 1 }}>
          <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <LocalizationProvider dateAdapter={AdapterDayjs} locale={esLocale}>
              <Stack spacing={3}>
                <MobileDatePicker
                  label="Desde la fecha"
                  name="end_date"
                  todayText="Hoy"
                  showTodayButton={true}
                  disableFuture
                  okText="Aceptar"
                  cancelText="Cancelar"
                  value={pickerFecini}
                  onChange={(newValue) => {
                    SetpickerIni(newValue);
                    setFechaInicio(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={errors.start_date ? true : false}
                      helperText={errors?.start_date?.message}
                      {...register("start_date", {
                        required: {
                          value: true,
                          message: "Este campo es requerido",
                        },
                      })}
                    />
                  )}
                />
              </Stack>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <LocalizationProvider dateAdapter={AdapterDayjs} locale={esLocale}>
              <Stack spacing={3}>
                <MobileDatePicker
                  label="Hasta la fecha"
                  name="end_date"
                  todayText="Hoy"
                  showTodayButton={true}
                  disableFuture
                  okText="Aceptar"
                  cancelText="Cancelar"
                  value={pickerFecter}
                  onChange={(newValue) => {
                    SetpickerTer(newValue);
                    setFechaFin(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={errors.end_date ? true : false}
                      helperText={errors?.end_date?.message}
                      {...register("end_date", {
                        required: {
                          value: true,
                          message: "Este campo es requerido",
                        },
                      })}
                    />
                  )}
                />
              </Stack>
            </LocalizationProvider>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            xl={2}
            display="flex"
            justifyContent="center"
          >
            <Button variant="contained" size="large">
              Consultar
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default ExpensesReport;
