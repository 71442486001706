import React, { useReducer } from 'react';
import { ADD_COUPON, COUPONS_EXPIRED, DELETE_COUPON, GET_ALL_COUPONS, SHOW_ERRORS_API, UPDATE_COUPON } from '../../types';
import CouponsReducer from './CouponsReducer';
import CouponsContext from './CouponsContext';
import Swal from 'sweetalert2';
import MethodGet, { MethodDelete, MethodPost, MethodPut } from '../../config/Service';

const CouponsState = ({ children }) => {
    //estado inicial
    const initialState = {
        coupons: [],
        coupon: null,
        ErrorsApi: [],
        success: false
    };
    const [state, dispatch] = useReducer(CouponsReducer, initialState);

    const GetCupons = () => {
        sessionStorage.removeItem('coupon');
        let url = `/coupons/`;
        MethodGet(url).then((res) => {
            dispatch({
                type: GET_ALL_COUPONS,
                payload: res.data.coupons,
            });
        })
            .catch((error) => {
                Swal.fire({
                    title: 'Error',
                    text: 'Ocurrio un error, al consultar la información, si el error continua comuniquese con el administardor del sistema',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 2000
                })
            });
    };

    const GetCouponsExpired = () => {
        sessionStorage.removeItem('coupon');
        let url = `/coupons/expired/date`;
        MethodGet(url)
            .then((res) => {
                dispatch({
                    type: COUPONS_EXPIRED,
                    payload: res.data.coupons,
                });
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Error',
                    text: error.response.data.message,
                    icon: 'error'
                })
            });
    }

    //agregar nuevo cupon
    const AddCoupon = (coupon) => {
        const formData = new FormData();
        formData.append('code', coupon.code);
        formData.append('expiration_date', coupon.expiration_date);
        formData.append('discount', coupon.discount);


        let url = `/coupons/`;
        MethodPost(url, formData)
            .then((res) => {
                Swal.fire({
                    title: 'Cupon registrado',
                    text: 'El cupon se registro correctamente',
                    icon: 'success',
                    timer: 1500,
                    showConfirmButton: false,
                })
                dispatch({
                    type: ADD_COUPON,
                });
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Error',
                    text: 'Ocurrio un error al guardar el cupón, si el error continua comuniquese con el administrador del sistema',
                    icon: 'error',
                    timer: 2000,
                    showConfirmButton: false,
                });
                dispatch({
                    type: SHOW_ERRORS_API,
                    payload: error.res.data.message
                });
            });
    };





    //agregar nuevo cupon
    const UpdateCoupon = (coupon) => {
        const formData = new FormData();
        formData.append('code', coupon.code);
        formData.append('expiration_date', coupon.expiration_date);
        formData.append('discount', coupon.discount);


        let url = `/coupons/${coupon.id}`;
        MethodPut(url, formData)
            .then((res) => {
                Swal.fire({
                    title: 'Cupon Actualizado',
                    text: 'El cupon se actualizo correctamente',
                    icon: 'success',
                    timer: 1500,
                    showConfirmButton: false,
                })
                dispatch({
                    type: UPDATE_COUPON,
                });
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Error',
                    text: 'Ocurrio un error al guardar el cupón, si el error continua comuniquese con el administrador del sistema',
                    icon: 'error',
                    timer: 2000,
                    showConfirmButton: false,
                });
                dispatch({
                    type: SHOW_ERRORS_API,
                    payload: error.res.data.message
                });
            });
    };

    //Eliminar cupon
    const DeleteCoupon = (id) => {
        Swal.fire({
            title: '¿Estas seguro?',
            text: 'El Cupon seleccionada, sera eliminado',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar",
            cancelButtonText: "No, cancelar",
        }).then((result) => {
            if (result.value) {
                let url = `/coupons/${id}`;
                MethodDelete(url)
                    .then((res) => {
                        Swal.fire({
                            title: 'Eliminado',
                            text: res.data.message,
                            icon: 'success',
                            timer: 2500,
                            showConfirmButton: false,
                        })
                        dispatch({
                            type: DELETE_COUPON,
                            payload: id
                        });
                    })
                    .catch((error) => {
                        Swal.fire({
                            title: 'Error',
                            text: 'Ocurrio un error al eliminar el cupón, si el error continua comuniquese con el administrador del sistema',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 2000
                        })
                    });
            }
        });
    };

    return (
        <CouponsContext.Provider
            value={{
                coupons: state.coupons,
                coupon: state.coupon,
                ErrorsApi: state.ErrorsApi,
                success: state.success,
                GetCupons,
                AddCoupon,
                DeleteCoupon,
                UpdateCoupon,
                GetCouponsExpired
            }}
        >
            {children}
        </CouponsContext.Provider>

    );
}

export default CouponsState;
