import React from "react";
import { useReducer } from "react";
import WarehouseCanvasReducer from "./WarehouseCanvasReducer";
import WarehouseCanvasContext from "./WarehouseCanvasContext";
import MethodGet, { MethodPost, MethodPut } from "../../config/Service";
import {
  CURRENT_ORDER_CANVAS,
  GET_ALL_SHIPPING_CANVAS_ACCEPTED,
  GET_ALL_SHIPPING_CANVAS_PENDING,
  GET_ALL_SHIPPING_CANVAS_SHIPPED,
  SHOW_ERRORS_API,
  UPDATE_SHIPPING_CANVAS,
  WAREHOUSE_SHIPPING_CANVAS_ACCEPT_ORDER,
  WAREHOUSE_SHIPPING_CANVAS_SHIPPED_ORDER,
} from "../../types";
import Swal from "sweetalert2";
import fileDownload from "js-file-download";
import headerConfig from "../../config/imageHeaders";

const WarehouseCanvasState = ({ children }) => {
  const initialState = {
    shippingsCanvasPending: [],
    shippingsCanvasAccepted: [],
    shippingsCanvasShipped: [],
    shipping: null,
    ErrorsApi: [],
    success: false,
  };

  const [state, dispatch] = useReducer(WarehouseCanvasReducer, initialState);

  //obtener la lista de pedidos de canvas pendientes de aceptar
  const GetWarehouseCanvasPending = (params) => {
    sessionStorage.removeItem("shipping");
    //params.pages += 1;
    let url = `/warehouse-shipping/status-canvas/pending?limit=${params.limite}&page=${params.pages}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_SHIPPING_CANVAS_PENDING,
          payload: {
            shippingsCanvasPending: res.data.shippings,
            totalDocs: res.data.totalDocs,
            limit: res.data.limit,
            page: res.data.page,
            paginCounter: res.data.paginCounter,
            hasPrevPage: res.data.hasPrevPage,
            hasNextPage: res.data.hasNextPage,
            prevPage: res.data.prevPage,
            nextPage: res.data.nextPage,
            totalPages: res.data.totalPages,
          },
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
      });
  };

  const GetWarehouseCanvasAccepted = (params) => {
    sessionStorage.removeItem("shipping");
    //params.pages += 1;

    let url = `/warehouse-shipping/status-canvas/accepted?limit=${params.limite}&page=${params.pages}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_SHIPPING_CANVAS_ACCEPTED,
          payload: {
            shippingsCanvasAccepted: res.data.shippings,
            totalDocs: res.data.totalDocs,
            limit: res.data.limit,
            page: res.data.page,
            paginCounter: res.data.paginCounter,
            hasPrevPage: res.data.hasPrevPage,
            hasNextPage: res.data.hasNextPage,
            prevPage: res.data.prevPage,
            nextPage: res.data.nextPage,
            totalPages: res.data.totalPages,
          },
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.res.data.message,
          icon: "error",
        });
      });
  };

  const GetWarehouseCanvasShipped = (params) => {
    sessionStorage.removeItem("shipping");
    //params.pages += 1;
    let url = `/warehouse-shipping/status-canvas/shipped?limit=${params.limite}&page=${params.pages}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_SHIPPING_CANVAS_SHIPPED,
          payload: {
            shippingsCanvasShipped: res.data.shippings,
            totalDocs: res.data.totalDocs,
            limit: res.data.limit,
            page: res.data.page,
            paginCounter: res.data.paginCounter,
            hasPrevPage: res.data.hasPrevPage,
            hasNextPage: res.data.hasNextPage,
            prevPage: res.data.prevPage,
            nextPage: res.data.nextPage,
            totalPages: res.data.totalPages,
          },
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.res.data.message,
          icon: "error",
        });
      });
  };

  const TakeOrderCanvas = (id) => {
    Swal.fire({
      title: "Tomar Pedido",
      text: "Se tomara el pedido Seleccionado",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Aceptar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/warehouse-shipping/accept/${id}`;
        MethodPost(url)
          .then((res) => {
            Swal.fire({
              title: "Aceptado",
              text: "El pedido seleccionado, se ha tomado correctamente, ahora lo podras ver en la seccion de pedidos Aceptados",
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            dispatch({
              type: WAREHOUSE_SHIPPING_CANVAS_ACCEPT_ORDER,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.res.data.message,
              icon: "error",
              timer: 2000,
              showConfirmButton: false,
            });
          });
      }
    });
  };

  const ShippOrderCanvas = (order) => {
    const formData = new FormData();
    formData.append("no_guide", order.no_guide);
    formData.append("shipment_id", order.shipment_id);
    formData.append("pdf", order.pdf);
    formData.append("comments", order.comments);
    let url = `/warehouse-shipping/shipp/${order.id}`;

    MethodPost(url, formData)
      .then((res) => {
        Swal.fire({
          title: "Pedido Enviado",
          text: "El pedido Seleccionado se ha enviado correctamente",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
          showCancelButton: false,
        });
        dispatch({
          type: WAREHOUSE_SHIPPING_CANVAS_SHIPPED_ORDER,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error,
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.res.data.error,
        });
      });
  };

  const PdfOrderCanvas = (id) => {
    Swal.fire({
      title: "¿Descargar PDF de la orden?",
      text: "Se descargará el pdf de la orden",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, descargar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/orders/create-pdf/${id}`;
        MethodGet(url, { responseType: "blob" })
          .then((res) => {
            fileDownload(res.data, "Orden.pdf");
            Swal.fire({
              title: "Descargado",
              text: "El PDF de la orden se ha descargado correctamente",
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              icon: "error",
              text: "Se ha producido un error al descargar el PDF",
            });
            dispatch({
              type: SHOW_ERRORS_API,
            });
          });
      }
    });
  };
  const UpdateShippingCanvas = (data) => {
    Swal.fire({
      title: "¿Estas seguro de actualizar la guía?",
      text: "Se actualizará, la informacion de envio del pedido seleccionado",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Actualizar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        const formData = new FormData();
        formData.append("no_guide", data.no_guide);
        formData.append("shipment_id", data.shipment_id);
        if (data.pdf) {
          formData.append("pdf", data.pdf);
        }
        let url = `/warehouse-shipping/${data.id}`;
        MethodPut(url, formData, { headerConfig })
          .then((res) => {
            Swal.fire({
              title: "Actualizado",
              text: res.data.message,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            dispatch({
              type: UPDATE_SHIPPING_CANVAS,
              payload: res.data.shipping,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              showConfirmButton: false,
              timer: 2000,
            });
          });
      }
    });
  };
  const SaveCurrentOderCanvas = (shipping) => {
    sessionStorage.setItem("shipping", JSON.stringify(shipping));
    dispatch({
      type: CURRENT_ORDER_CANVAS,
      payload: shipping,
    });
  };

  return (
    <WarehouseCanvasContext.Provider
      value={{
        shippingsCanvasPending: state.shippingsCanvasPending,
        shippingsCanvasAccepted: state.shippingsCanvasAccepted,
        shippingsCanvasShipped: state.shippingsCanvasShipped,
        shipping: state.shipping,
        ErrorsApi: state.ErrorsApi,
        success: state.success,
        totalDocs: state.totalDocs,
        limit: state.limit,
        page: state.page,
        paginCounter: state.paginCounter,
        hasNextPage: state.hasNextPage,
        hasPrevPage: state.hasPrevPage,
        prevPage: state.prevPage,
        nextPage: state.nextPage,
        totalPages: state.totalPages,
        GetWarehouseCanvasPending,
        GetWarehouseCanvasAccepted,
        GetWarehouseCanvasShipped,
        TakeOrderCanvas,
        ShippOrderCanvas,
        SaveCurrentOderCanvas,
        PdfOrderCanvas,
        UpdateShippingCanvas,
      }}
    >
      {children}
    </WarehouseCanvasContext.Provider>
  );
};

export default WarehouseCanvasState;
