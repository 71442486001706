import React, { useContext, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import ShippmentsContext from "../../context/Shippments/ShippmentsContext";
import ShippmentsCard from "../../components/Cards/Shippments/ShippmentsCard";
import AddModal from "./ModalAdd";
import NoDataComponents from "../../components/loading/NoDataComponents";

import { makeStyles } from "@mui/styles";
import { Grid, Box, Button } from "@mui/material";
import ModulesContext from "../../context/Permissions/PermissionsContext";

const useStyles = makeStyles(() => ({
  titleCard: {
    color: "white",
    fontSize: "35px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 2,
    marginRight: 5,
  },
}));

export default function Shippments() {
  const classes = useStyles();

  const [modalAdd, AbrirModalAdd] = useState(false);
  const handleClickOpenAdd = () => {
    AbrirModalAdd(true);
  };
  const handleClickCloseAdd = () => {
    AbrirModalAdd(false);
  };

  const { shippments, GetShippments, success, SaveCurrentShippment } =
    useContext(ShippmentsContext);
  const { permissionsPerUser, GetModulesPerUser } = useContext(ModulesContext);

  useEffect(() => {
    if (sessionStorage.getItem("module_id")) {
      const permiss = sessionStorage.getItem("module_id");
      GetModulesPerUser(permiss);
    }
    GetShippments();
  }, [success]);

  return (
    <Layout>
      <Grid container spacing={2} sx={{ backgroundColor: "white", padding: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#000000",
              textAling: "center",
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
              // borderRadius: "0 0 24px 0",
            }}>
            <div className={classes.titleCard}>Paqueterias</div>
            {permissionsPerUser.write === true && (
              <div className={classes.fab}>
                <Button
                  onClick={handleClickOpenAdd}
                  sx={{
                    color: "black",
                    backgroundColor: "#26c6da",
                    hoverColor: "white",
                    fontFamily: "monospace",
                    fontWeight: "bolder",
                    fontSize: "15px",
                    textTransform: "none",
                    "&:hover": {
                      color: "black",
                      backgroundColor: "#67cedb",
                      hoverColor: "white",
                      fontFamily: "monospace",
                      fontWeight: "bolder",
                      fontSize: "15px",
                    },
                  }}>
                  Agregar
                </Button>
              </div>
            )}
          </Box>
          <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={2}>
                  {!shippments.length ? (
                    <NoDataComponents />
                  ) : (
                    shippments.map((shippment) => (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={3}
                        lg={3}
                        xl={3}
                        key={shippment._id}>
                        <ShippmentsCard
                          permiss={permissionsPerUser}
                          shippment={shippment}
                        />
                      </Grid>
                    ))
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <AddModal
          modal={modalAdd}
          handleClickOpen={handleClickOpenAdd}
          handleClose={handleClickCloseAdd}
        />
      </Grid>
    </Layout>
  );
}
