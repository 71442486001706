import ContactContext from "../../context/Contact/ContactContext";
import { useContext, useEffect } from "react";
import Layout from "../../components/layout/Layout";
import PropTypes from "prop-types";
import { AppBar, Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import DateFormat from "../../utils/FormatDate";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import MessagesUnread from "./MessagesUnread";
import MessagesProcessUser from "./MessagesProcessUser";
import MessagesFinalized from "./MessagesFinalized";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function ListContact() {
  const { messages, GetAllMessages, makeAsReadMessage, totalPages } =
    useContext(ContactContext);
  console.log(totalPages, "el total de paginas");
  //paginacion
  var [pages, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const limite = rowsPerPage;
  const params = { limite, pages };
  useEffect(() => {
    if (params.limite !== null && params.pages !== null) {
      GetAllMessages(params);
    }
  }, [rowsPerPage, pages]);

  const [value, setValue] = useState(0);
  const history = useHistory();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Layout>
      <Grid container spacing={2} sx={{ marginTop: 2, padding: 1 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography
            sx={{
              backgroundColor: "black",
              color: "white",
              fontSize: 30,
              padding: 1,
            }}
            fontWeight="bold"
            fontFamily="monospace"
          >
            Buzon de Entrada
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          display="flex"
          justifyContent="center"
        >
          <Box sx={{ bgcolor: "background.paper", width: "100%" }}>
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                //indicatorColor="secondary"
                textColor="inherit"
                //centered
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
                sx={{ backgroundColor: "black" }}
              >
                <Tab label="Mensajes no leidos" {...a11yProps(0)} />
                <Tab
                  label="Mensajes en proceso(atendiendo)"
                  {...a11yProps(1)}
                />
                <Tab label="Mensajes finalizados" {...a11yProps(2)} />
              </Tabs>
            </AppBar>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TabPanel value={value} index={0}>
            <MessagesUnread />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <MessagesProcessUser />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <MessagesFinalized />
          </TabPanel>
        </Grid>
      </Grid>
      {/** <Grid
        container
        spacing={2}
        sx={{ marginTop: 0.1, padding: 2, marginBottom: 10 }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card sx={{ boxShadow: 10 }}>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>Nombre</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="left">
                      Mensaje
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      Remitente
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      Telefono
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      Fecha
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      Opción
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {messages &&
                    messages.map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="left">{row.message}</TableCell>
                        <TableCell align="left">{row.email}</TableCell>
                        <TableCell align="left">{row.phone}</TableCell>
                        <TableCell align="center">
                          {DateFormat(row.createdAt)}
                        </TableCell>
                        <TableCell align="center">
                          <IconButton>
                            <CheckCircleOutlineIcon
                              sx={{ color: "green" }}
                              onClick={() => makeAsReadMessage(row._id)}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ display: "flex", justifyContent: "end", padding: 4 }}>
              <Stack spacing={2} sx={{ marginBottom: 4, marginRight: 2 }}>
                <Paginate
                  pages={pages}
                  handleChangePage={handleChangePage}
                  totalPages={totalPages}
                />
              </Stack>
            </div>
          </Card>
        </Grid>
                      </Grid>**/}
    </Layout>
  );
}
