import React, { useState } from "react";
import formatDate from "../../../utils/FormatDate";
import ModalImage from "react-modal-image-responsive";
import SalesContext from "../../../context/Sales/SalesContext";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Card,
  Typography,
  Tooltip,
  Menu,
  MenuItem,
  IconButton,
  Divider,
  Button,
} from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link } from "react-router-dom";
import { useContext } from "react";
import ModalUpdateShipping from "../Warehouse/ModalUpdateShipping";
const useStyles = makeStyles({
  Imagelogo: {
    width: "50%",
    height: "140px",
    objectFit: "fill",
    margin: "auto",
    textAlign: "center",
    lineHeight: "110px",
  },
  more: {
    position: "absolute",
    right: 0,
    top: 0,
  },
});

const ShippedCanvasCard = ({ shipping, permiss }) => {
  //3 puntitos
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();
  const { SaveCurrentSale } = useContext(SalesContext);
  const SelectShowOrder = (shipping) => {
    SaveCurrentSale(shipping);
  };
  /**MODAL UPDATE SHIPPING */
  const [openUpdate, setOpenUpdate] = React.useState(false);
  const handleClickOpenUpdate = () => {
    setOpenUpdate(true);
  };
  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };

  return (
    <>
      <Card sx={{ boxShadow: 3, position: "relative" }}>
        <ModalImage
          className={classes.Imagelogo}
          small={shipping.shipment_id.image}
          large={shipping.shipment_id.image}
          alt={shipping.shipment_id.name}
        />
        <Box
          display="flex"
          alignItems="baseline"
          justifyContent="space-between"
          sx={{
            paddingX: 2,
            backgroundColor: "white",
            color: "black",
          }}
        >
          <Typography
            variant="su arrowbtitle2"
            noWrap
            sx={{ fontWeight: "bold", fontSize: "17px" }}
            className="blink-1"
          >
            N° Orden:
          </Typography>{" "}
          <Typography
            variant="su arrowbtitle2"
            noWrap
            sx={{ fontWeight: "bold", fontSize: "17px" }}
            className="blink-1"
          >
            {shipping.order_id.folio}
          </Typography>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
              }}
            >
              Enviar Pedido
            </MenuItem>
          </Menu>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between",
            paddingX: 2,
            marginBottom: 1,
          }}
        >
          <div>
            <Typography sx={{ fontWeight: "bold", fontSize: "17px" }}>
              Fecha de compra:
            </Typography>
          </div>
          <div>
            <Typography sx={{ fontSize: "14px" }}>
              {shipping.order_id.dateFormatted ?? shipping.order_id.createdAt}
            </Typography>
          </div>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between",
            paddingX: 2,
            marginBottom: 1,
          }}
        >
          <div>
            <Typography sx={{ fontWeight: "bold", fontSize: "17px" }}>
              Empaqueto Pedido:
            </Typography>
          </div>
          <div>
            <Typography sx={{ fontSize: "14px" }}>
              {shipping.user_id.fullname}
              {/* {shipping.user_id.fullname} */}
            </Typography>
          </div>
        </Box>

        <Divider />
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between",
            paddingX: 2,
            marginBottom: 1,
          }}
        >
          <div>
            <Typography sx={{ fontWeight: "bold", fontSize: "17px" }}>
              Cliente:
            </Typography>
          </div>
          <div>
            <Typography sx={{ fontSize: "14px" }}>
              {shipping.order_id.client_id.fullname}
            </Typography>
          </div>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between",
            paddingX: 2,
            marginBottom: 1,
          }}
        >
          <div>
            <Typography sx={{ fontWeight: "bold", fontSize: "17px" }}>
              Cantidad de productos:
            </Typography>
          </div>
          <div>
            <Typography sx={{ fontSize: "14px" }}>
              {shipping.order_id.products_list.length}
            </Typography>
          </div>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingX: 2,
            marginBottom: 1,
          }}
        >
          <div>
            <Typography sx={{ fontWeight: "bold", fontSize: "17px" }}>
              Numero de guía:
            </Typography>
          </div>
          <div>
            <Typography sx={{ fontSize: "14px" }}>
              {shipping.no_guide}
            </Typography>
          </div>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingX: 2,
            marginTop: 2,
            marginBottom: 1,
          }}
        >
          <Link to={`/Detalle_venta/${shipping?.order_id?._id}`}>
            <Button
              target="_blank"
              //startIcon={<LocalShippingIcon />}
              sx={{
                backgroundColor: "#b71c1c",
                color: "white",
                marginRight: 2,
                "&:hover": {
                  backgroundColor: "#b71c1c",
                  color: "white",
                },
              }}
              // onClick={() => SelectShowOrder(shipping?.order_id?._id)}
            >
              Ver detalle
            </Button>
          </Link>
          {permiss.update === true && (
            <Button
              //target="_blank"
              //startIcon={<LocalShippingIcon />}
              sx={{
                backgroundColor: "#1976d2",
                color: "white",
                "&:hover": {
                  backgroundColor: "#1976d2",
                  color: "white",
                },
              }}
              onClick={() => {
                SelectShowOrder(shipping?.order_id?._id);
                handleClickOpenUpdate();
              }}
            >
              Editar Guía
            </Button>
          )}
        </Box>
        <Divider />
      </Card>
      <ModalUpdateShipping
        open={openUpdate}
        handleClose={handleCloseUpdate}
        id={shipping?._id}
        shippment_id={shipping?.shipment_id?._id}
        no_guide={shipping?.no_guide}
        canvas={true}
      />
    </>
  );
};

export default ShippedCanvasCard;
