import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Grid, IconButton, Pagination, Stack, Tooltip } from "@mui/material";
import invoicesContext from "../../context/Invoices/InvoicesContext";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReceiptIcon from "@mui/icons-material/Receipt";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import ModalFiscalData from "../../containers/Invoices/salesForInvoice/ModalFiscalData";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function TableOnly({ columns }) {
  const { GetSalesForInvoice, ordersForInvoice, totalPages, OrderInvoice } =
    useContext(invoicesContext);
  var [pages, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const limite = rowsPerPage;
  const params = { limite, pages };
  useEffect(() => {
    GetSalesForInvoice(params);
  }, [rowsPerPage, pages]);
  /**modal datos fiscales */
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [idClient, setIdClient] = useState();
  const openModal = (id) => {
    setIdClient(id);
    handleClickOpen();
  };
  /**termina modal */

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <StyledTableCell key={index} align="center">
                  {column.name}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {ordersForInvoice &&
              ordersForInvoice.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell align="center">{row.folio}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row.client_id.fullname}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.type === 1
                      ? "Tarjeta(stripe)"
                      : "Transferencia bancaria"}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.total}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row.shippment}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.dateFormatted}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Tooltip title="Facturar Venta" placement="top">
                      <IconButton onClick={() => OrderInvoice(row._id)}>
                        <ReceiptIcon sx={{ color: "#2e7d32" }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title="Registrar datos fiscales cliente"
                      placement="top">
                      <IconButton onClick={() => openModal(row._id)}>
                        <FilePresentIcon sx={{ color: "#0d47a1" }} />
                      </IconButton>
                    </Tooltip>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {ordersForInvoice.length > 0 && (
        <Grid container spacing={2}>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Stack spacing={2} sx={{ marginLeft: 2, marginTop: 2 }}>
              <Pagination
                count={totalPages}
                variant="outlined"
                shape="rounded"
                onChange={handleChangePage}
                color="secondary"
                page={pages}
                size="large"
                sx={{
                  color: "#880e4f",
                  "&:hover": { color: "#f06292" },
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      )}
      {idClient && (
        <ModalFiscalData
          open={open}
          handleClose={handleClose}
          idClient={idClient}
        />
      )}
    </>
  );
}
