import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Chip, Grid, Stack, TextField } from "@mui/material";
import TaxSystemSelect from "../../SelectOptions/TaxSystemSelect";
import { useState } from "react";
import SelectState from "./../../SelectOptions/SelectState";
import SelectMunicipality from "./../../SelectOptions/SelectMunicipality";
import ClientsContext from "../../../context/Clients/ClientsContext";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import SelectTypeClient from "../../SelectOptions/SelectTypeClients";
export default function ModalAddClientAccount({ open, handleClose }) {
  const [typeClient, saveTypeClient] = useState();
  const detectarCambiosTypeClients = (e) => {
    saveTypeClient(e.target.value);
  };
  const [state, saveState] = useState();
  const detectarCambiosState = (e) => {
    saveState(e);
  };
  const [municipality, saveMunicipality] = useState();
  const detectarCambiosMunicipality = (e) => {
    saveMunicipality(e);
  };
  const [taxSystem, saveTaxSystem] = useState();
  const detectarCambiosTaxSystem = (e) => {
    saveTaxSystem(e);
  };
  const { CreateClientAccount } = useContext(ClientsContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  const reset = () => {
    setValue("name_client", "", { shouldDirty: true });
    //setValue("email_client", "", { shouldDirty: true });
    setValue("phone_client", "", { shouldDirty: true });
    setValue("legal_name", "", { shouldDirty: true });
    setValue("email", "", { shouldDirty: true });
    setValue("phone", "", { shouldDirty: true });
    setValue("zip", "", { shouldDirty: true });
    setValue("street", "", { shouldDirty: true });
    setValue("exterior", "", { shouldDirty: true });
    setValue("interior", "", { shouldDirty: true });
    setValue("neighborhood", "", { shouldDirty: true });
    setValue("city", "", { shouldDirty: true });
    setValue("municipality", "", { shouldDirty: true });
  };
  const onSubmit = (data, e) => {
    data.municipality = municipality;
    data.state = state;
    data.tax_system = taxSystem;
    data.country = "MEX";
    data.customer_type = typeClient;
    console.log(data, "la data");

    CreateClientAccount(data);
    handleClose();
    reset();
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {"Registrar nuevo cliente"}
        </DialogTitle>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter")
              e.preventDefault();
          }}
          autoComplete="off">
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  display="flex"
                  justifyContent="center">
                  <Stack direction="row" spacing={1}>
                    <Chip label="Datos del cliente" />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    fullWidth
                    label="Nombre Completo"
                    name="fullname"
                    error={errors.fullname ? true : false}
                    helperText={errors?.fullname?.message}
                    {...register("fullname", {
                      required: {
                        value: true,
                        message: "Este campo es requerido",
                      },
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    fullWidth
                    label="Telefono"
                    name="phone_number"
                    error={errors.phone_number ? true : false}
                    helperText={errors?.phone_number?.message}
                    {...register("phone_number", {
                      required: {
                        value: true,
                        message: "Este campo es requerido",
                      },
                    })}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  display="flex"
                  justifyContent="center">
                  <Stack direction="row" spacing={1}>
                    <Chip label="Datos del facturación" />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    fullWidth
                    label="Nombre Fiscal o Razón social"
                    name="legal_name"
                    type="text"
                    error={errors.legal_name ? true : false}
                    helperText={errors?.legal_name?.message}
                    {...register("legal_name", {
                      required: {
                        value: true,
                        message: "Este campo es requerido",
                      },
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    fullWidth
                    label="RFC"
                    name="tax_id"
                    type="text"
                    error={errors.tax_id ? true : false}
                    helperText={errors?.tax_id?.message}
                    {...register("tax_id", {
                      required: {
                        value: true,
                        message: "Este campo es requerido",
                      },
                      pattern: {
                        value:
                          /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
                        message: "El formato es incorrecto",
                      },
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TaxSystemSelect
                    detectarCambiosTaxSystem={detectarCambiosTaxSystem}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    fullWidth
                    label="Correo Electronico"
                    name="email"
                    type="email"
                    error={errors.email ? true : false}
                    helperText={errors?.email?.message}
                    {...register("email", {
                      required: {
                        value: true,
                        message: "Este campo es requerido",
                      },
                    })}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    fullWidth
                    label="Telefono"
                    name="phone"
                    type="tel"
                    error={errors.phone ? true : false}
                    helperText={errors?.phone?.message}
                    {...register("phone", {
                      required: {
                        value: true,
                        message: "Este campo es requerido",
                      },
                    })}
                  />
                </Grid> */}
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    fullWidth
                    label="C.P"
                    name="zip"
                    type="number"
                    error={errors.zip ? true : false}
                    helperText={errors?.zip?.message}
                    {...register("zip", {
                      required: {
                        value: true,
                        message: "Este campo es requerido",
                      },
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    fullWidth
                    label="Calle"
                    name="street"
                    type="text"
                    error={errors.street ? true : false}
                    helperText={errors?.street?.message}
                    {...register("street", {
                      required: {
                        value: true,
                        message: "Este campo es requerido",
                      },
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    fullWidth
                    label="no_exterior"
                    name="exterior"
                    type="text"
                    error={errors.exterior ? true : false}
                    helperText={errors?.exterior?.message}
                    {...register("exterior", {
                      required: {
                        value: false,
                        message: "El campo es opcional",
                      },
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    fullWidth
                    label="no_interior"
                    name="interior"
                    type="text"
                    error={errors.interior ? true : false}
                    helperText={errors?.interior?.message}
                    {...register("interior", {
                      required: {
                        value: false,
                        message: "Campo opcional",
                      },
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    fullWidth
                    label="Colonia"
                    name="neighborhood"
                    type="text"
                    error={errors.neighborhood ? true : false}
                    helperText={errors?.neighborhood?.message}
                    {...register("neighborhood", {
                      required: {
                        value: true,
                        message: "Este campo es requerido",
                      },
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    fullWidth
                    label="Ciudad"
                    name="city"
                    type="text"
                    error={errors.city ? true : false}
                    helperText={errors?.city?.message}
                    {...register("city", {
                      required: {
                        value: true,
                        message: "Este campo es requerido",
                      },
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <SelectState detectarCambiosState={detectarCambiosState} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <SelectMunicipality
                    detectarCambiosMunicipality={detectarCambiosMunicipality}
                    state_id={state}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                  <SelectTypeClient
                    detectarCambiosTypeClients={detectarCambiosTypeClients}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Button type="submit" variant="contained" size="large">
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
        </form>
      </Dialog>
    </div>
  );
}
