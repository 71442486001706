import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
// import TableOnly from "../../components/Tables/Table";
import TableConciliations from "./TableConciliations";
import invoicesContext from "../../context/Invoices/InvoicesContext";
import NoDataComponents from "../../components/loading/NoDataComponents";
const Conciliations = () => {
  const columns = [
    { name: "Fecha" },
    { name: "Forma de pago" },
    { name: "Organizacion" },
    { name: "Opciones" },
  ];
  const { GetConciliations, conciliations, totalPages } =
    useContext(invoicesContext);

  useEffect(() => {
    GetConciliations();
  }, []);

  const rows = conciliations.map((p) => {
    let attribute = {
      date: p.createdAt,
      payment: p.payment_form_sat,
      organization: "Wapi Online School",
      facturapi_id: p.facturapi_id,
    };
    return attribute;
  });

  return (
    <>
      {rows.length > 0 ? (
        <TableConciliations columns={columns} rows={rows} />
      ) : (
        <NoDataComponents />
      )}
    </>
  );
};

export default Conciliations;
