import React, { useReducer } from 'react';
import Swal from 'sweetalert2';
import MethodGet, { MethodPut } from '../../config/Service';
import { CURRENT_COUPON_NEW_USER, GET_COUPON_NEW_USER, SHOW_ERRORS_API, UPDATE_COUPON_NEW_USER } from '../../types';
import CouponNewUserContext from './CouponNewUserContext';
import CouponNewUserReducer from './CouponNewUserReducer';

const CouponNewUserState = ({ children }) => {
    //estado inicial
    const initialState = {
        couponsnu: {},
        couponnu: null,
        ErrorsApi: [],
        success: false
    };

    const [state, dispatch] = useReducer(CouponNewUserReducer, initialState);

    const GetCouponNu = () => {
        sessionStorage.removeItem('couponnu');
        let url = `/business-rules/new-user/coupon`;
        MethodGet(url).then((res) => {
            dispatch({
                type: GET_COUPON_NEW_USER,
                payload: res.data.business_rule,
            });
        })
            .catch((error) => {
                dispatch({
                    type: SHOW_ERRORS_API,
                })
            });
    };

    const UpdateCouponNu = (couponnu) => {
        const formData = new FormData();
        formData.append('discount', couponnu.discount);

        let url = `/business-rules/new-user-coupon/update`;
        MethodPut(url, formData)
            .then((res) => {
                Swal.fire({
                    title: 'Cupón Actualizado',
                    text: res.data.message,
                    timer: 1000,
                    showConfirmButton: false,
                    icon: 'success'
                });
                dispatch({
                    type: UPDATE_COUPON_NEW_USER,
                });
            })
            .catch((error) => {
                dispatch({
                    type: SHOW_ERRORS_API,
                    payload: error.res.data.error
                });
            });
    };

    const saveCurrentCouponNewUser = (couponnu) => {
        sessionStorage.setItem('couponnu', JSON.stringify(couponnu));
        dispatch({
            type: CURRENT_COUPON_NEW_USER,
            payload: couponnu
        });
    };


    return (
        <CouponNewUserContext.Provider
            value={{
                couponsnu: state.couponsnu,
                couponnu: state.couponnu,
                ErrorsApi: state.ErrorsApi,
                success: state.success,
                GetCouponNu,
                UpdateCouponNu,
                saveCurrentCouponNewUser
            }}
        >
            {children}
        </CouponNewUserContext.Provider>
    );
}

export default CouponNewUserState;
