import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ModalImage from "react-modal-image-responsive";
import {
  Button,
  ButtonGroup,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
const useStyles = makeStyles({
  img: {
    width: 100,
    height: 100,
  },
  jss59: {
    width: "100%",
    border: "1px solid #bbb",
    height: "15%",
    outline: "none",
    padding: "15px 10px",
    marginLeft: "10px",
    borderRadius: "10px",
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function ProductsList({ salida, guardarSalida }) {
  const classes = useStyles();
  // const eliminarProducto = (value) => {
  //   const todosProductos = salida.filter(
  //     (product) => product.product_id !== value
  //   );
  //   guardarSalida(todosProductos);
  // };

  const eliminarProducto = (value) => {
    const todosProducto = salida.filter((p) => p.product_id !== value);
    guardarSalida(todosProducto);
  };

  const handleChangeQuantity = (e, i) => {
    const newQuantity = Number(e.target.value);
    const todosProducto = [...salida];
    if (newQuantity > todosProducto[i].stock_product) {
      Swal.fire({
        title: "Error",
        text:
          "La cantidad no puede ser mayor al stock disponible, unicamente hay " +
          todosProducto[i].stock_product +
          " productos Disponibles",
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }
    todosProducto[i].quantity = newQuantity;
    guardarSalida(todosProducto);
  };
  return (
    <TableContainer component={Paper}>
      <div style={{ height: "400px", overflowY: "auto" }}>
        <Table stickyHeader aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Imagen</StyledTableCell>
              <StyledTableCell align="center">Codigo de barras</StyledTableCell>
              <StyledTableCell align="center">Producto</StyledTableCell>
              <StyledTableCell align="center">Marca</StyledTableCell>
              <StyledTableCell align="center">Precio</StyledTableCell>
              <StyledTableCell align="center">existentes</StyledTableCell>
              <StyledTableCell align="center">Cantidad</StyledTableCell>
              <StyledTableCell align="center">Opciones</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {salida.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row" align="center">
                  <ModalImage
                    className={classes.img}
                    small={row.image}
                    large={row.image}
                    alt={row.name}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">{row.barcode}</StyledTableCell>
                <StyledTableCell align="center">{row.name}</StyledTableCell>
                <StyledTableCell align="center">{row.brand}</StyledTableCell>
                <StyledTableCell align="center">$ {row.price}</StyledTableCell>
                <StyledTableCell align="center">
                  {row.stock_product}
                </StyledTableCell>
                {/* <StyledTableCell align="center">{row.cantidad}</StyledTableCell> */}
                <StyledTableCell align="center">
                  <input
                    //type="number"
                    step={1}
                    //onChange={detectarCambiosCantidadComprada}
                    className={classes.jss59}
                    placeholder="Agrega la Cantidad Inicial"
                    name="quantityProduct"
                    min={1}
                    max={row.stock_product}
                    value={row.quantity}
                    onChange={(e) => handleChangeQuantity(e, index)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Tooltip title="Borrar" placement="top">
                    <IconButton
                      onClick={() => eliminarProducto(row.product_id)}
                      variant="outlined"
                      sx={{
                        color: "red",
                        borderColor: "red",
                        "&:hover": { color: "red", borderColor: "red" },
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </TableContainer>
  );
}
