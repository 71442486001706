import React, { useContext, useEffect, useState } from "react";
import LogoContext from "../../context/Logo/LogoContext";
import ModalImage from "react-modal-image-responsive";
import EditAdministrableInfo from "../../containers/GeneralSlider/logo/EditAdministrableInfo";

// material
import {
	Box,
	Typography,
	Tooltip,
	Grid,
	IconButton,
	Menu,
	MenuItem,
} from "@mui/material";

//Estilos , Icons y Alerta
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SpinnerComponent from "../loading/SpinnerComponent";

export default function LogoCard() {
	const GetLogoList = useContext(LogoContext);
	const [modalUpdate, AbrirModalUpdate] = useState(false);

	const { logos, GetLogo, success } = GetLogoList;

	const { facebook, tiktok, instagram, top_text, logo } = logos;

	useEffect(() => {
		GetLogo();
	}, [success]);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleClickOpenUpdate = () => {
		AbrirModalUpdate(true);
	};
	const handleClickCloseUpdate = () => {
		// sessionStorage.removeItem("brand");
		AbrirModalUpdate(false);
	};

	if (!logos)
		return (
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<Box sx={{ boxShadow: 3, backgroundColor: "white" }}>
					<Box
						fullWidth
						display="flex"
						alignItems="center"
						justifyContent="space-between"
						sx={{
							paddingX: 2,
							backgroundColor: "white",
							color: "black",
						}}
					>
						<SpinnerComponent />
					</Box>
				</Box>
			</Grid>
		);

	return (
		<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
			<Box sx={{ boxShadow: 3, backgroundColor: "white" }}>
				<Box
					fullWidth
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					sx={{
						paddingX: 2,

						backgroundColor: "white",
						color: "black",
					}}
				>
					<Typography
						variant="su arrowbtitle2"
						noWrap
						alignContent="center"
						sx={{ fontWeight: "bold", fontSize: "18px" }}
						className="blink-1"
					>
						{top_text}
					</Typography>
					<div>
						<Tooltip arrow title="Operaciones" placement="top">
							<IconButton
								aria-label="more"
								id="long-button"
								aria-controls={open ? "long-menu" : undefined}
								aria-expanded={open ? "true" : undefined}
								aria-haspopup="true"
								onClick={handleClick}
								sx={{ color: "black" }}
							>
								<MoreVertIcon />
							</IconButton>
						</Tooltip>
						<Menu
							id="long-menu"
							MenuListProps={{
								"aria-labelledby": "long-button",
							}}
							anchorEl={anchorEl}
							open={open}
							onClose={handleClose}
						>
							<MenuItem
								onClick={() => {
									setAnchorEl(null);
									handleClickOpenUpdate();
								}}
							>
								Editar
							</MenuItem>
						</Menu>
					</div>
				</Box>
				<Box display="flex" justifyContent="center" alignItems="center">
					<ModalImage small={logo} large={logo} alt="logo" />
				</Box>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-around",
						paddingBottom: 10,
					}}
				>
					<div>
						<Tooltip
							title={`${facebook}`}
							disableInteractive
							arrow
							placement="top"
						>
							<a href={facebook} target="_blank" rel="noreferrer">
								<IconButton
									color="primary"
									sx={{ fontSize: "30px" }}
									aria-label="upload picture"
									component="span"
								>
									<i className="fab fa-facebook-f"></i>
								</IconButton>
							</a>
						</Tooltip>
					</div>
					<div>
						<Tooltip
							title={`${instagram}`}
							disableInteractive
							arrow
							placement="top"
						>
							<a href={instagram} target="_blank" rel="noreferrer">
								<IconButton
									sx={{ color: "#FF0080", fontSize: "30px" }}
									aria-label="upload picture"
									component="span"
								>
									<i className="fab fa-instagram"></i>
								</IconButton>
							</a>
						</Tooltip>
					</div>
					<div>
						<Tooltip
							title={`${tiktok}`}
							disableInteractive
							arrow
							placement="top"
						>
							<a href={tiktok} target="_blank" rel="noreferrer">
								<IconButton
									sx={{ color: "black", fontSize: "30px" }}
									aria-label="upload picture"
									component="span"
								>
									<i className="fab fa-tiktok"></i>
								</IconButton>
							</a>
						</Tooltip>
					</div>
				</Box>
			</Box>
			<EditAdministrableInfo
				modalUpdate={modalUpdate}
				handleClickOpen={handleClickOpenUpdate}
				handleCloseEdit={handleClickCloseUpdate}
			/>
		</Grid>
	);
}
