import React, { useContext, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import SubcategoriesContext from "../../context/SubCategories/SubcategoriesContext";
import SubCategoriesCard from "../../components/Cards/subcategories/SubCategoriesCard";
import ModalAdd from "./ModalAdd";
import NoDataComponents from "../../components/loading/NoDataComponents";

import { makeStyles } from "@mui/styles";
import {
  Grid,
  Box,
  Fab,
  Tooltip,
  TextField,
  Button,
  Menu,
  MenuItem,
  Pagination,
  Stack,
} from "@mui/material/";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ModulesContext from "../../context/Permissions/PermissionsContext";
import MethodGet from "../../config/Service";
import NoResultsComponent from "../../components/loading/NoResultsComponent";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ModalUpdateSubcategories from "./ModalUpdateSubcategories";
import Swal from "sweetalert2";
const useStyles = makeStyles({
  titleCard: {
    color: "white",
    fontSize: "35px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 2,
    marginRight: 5,
  },
  img_data: {
    margin: "auto",
    height: "90%",
  },
});

export default function CategoryS() {
  const classes = useStyles();
  //menu para opciones excel
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //modalimportar
  //modal para importar
  const [modalUpdate, AbrirModalUpdate] = useState(false);
  const handleClickOpenUpdate = () => {
    AbrirModalUpdate(true);
  };
  const handleClickCloseUpdate = () => {
    AbrirModalUpdate(false);
  };
  //buscador
  const [searchWord, setSearchWord] = useState("");
  const [allSubcategories, setAllSubcategories] = useState();
  //abrir modal
  const [modalAdd, AbrirModalAdd] = useState(false);

  const handleClickOpenAdd = () => {
    AbrirModalAdd(true);
  };
  const handleClickCloseAdd = () => {
    AbrirModalAdd(false);
  };
  //paginacion
  var [pages, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const limite = rowsPerPage;
  const params = { limite, pages };
  const {
    subcategories,
    GetSubcategories,
    success,
    DowloadUpdateExcelFile,
    totalPages,
  } = useContext(SubcategoriesContext);
  const { permissionsPerUser, GetModulesPerUser } = useContext(ModulesContext);

  const FilterSubcategories = async (palabra) => {
    if (palabra !== "") {
      let url = `/search/subcategories?search=${palabra}`;
      await MethodGet(url)
        .then((res) => {
          setAllSubcategories(res.data.results);
        })
        .catch((error) => {
          Swal.fire({
            title: "error",
            text: error.response.data.message,
            icon: "error",
          });
        });
    }
  };

  useEffect(() => {
    if (localStorage.getItem("module_id")) {
      const permiss = localStorage.getItem("module_id");
      GetModulesPerUser(permiss);
      GetSubcategories(params);
    }
    setAllSubcategories(subcategories);
    GetSubcategories(params);
  }, [success, rowsPerPage, pages]);

  return (
    <Layout>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#000000",
              textAling: "center",
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
              // borderRadius: "0 0 24px 0",
            }}>
            <div className={classes.titleCard}>SubCategorias</div>
            {permissionsPerUser.write === true && (
              <div className={classes.fab}>
                <Button
                  onClick={handleClickOpenAdd}
                  sx={{
                    color: "black",
                    backgroundColor: "#26c6da",
                    hoverColor: "white",
                    fontFamily: "monospace",
                    fontWeight: "bolder",
                    fontSize: "15px",
                    textTransform: "none",
                    "&:hover": {
                      color: "black",
                      backgroundColor: "#67cedb",
                      hoverColor: "white",
                      fontFamily: "monospace",
                      fontWeight: "bolder",
                      fontSize: "15px",
                    },
                  }}>
                  Agregar
                </Button>
              </div>
            )}
          </Box>
          <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                {!subcategories.length ? (
                  <></>
                ) : (
                  <>
                    <TextField
                      fullWidth
                      label="Buscar"
                      id="filled-size-small"
                      variant="outlined"
                      size="small"
                      onChange={({ target }) => {
                        setSearchWord(target.value);
                        FilterSubcategories(target.value);
                      }}
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                <Button
                  id="basic-button"
                  variant="contained"
                  sx={{
                    background: "#0d47a1",
                    color: "white",
                    "&:hover": {
                      background: "#0d47a1",
                      color: "white",
                    },
                  }}
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}>
                  Opciones
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}>
                  <MenuItem>
                    <Button
                      variant="outlined"
                      startIcon={<CloudDownloadIcon />}
                      onClick={() => DowloadUpdateExcelFile()}
                      sx={{
                        background: "#fbc02d",
                        color: "white",
                        borderColor: "#fbc02d",
                        "&:hover": {
                          background: "#fbc02d",
                          color: "white",
                          borderColor: "#fbc02d",
                        },
                      }}>
                      Descargar actualizar
                    </Button>
                  </MenuItem>
                  <MenuItem>
                    <Button
                      onClick={() => handleClickOpenUpdate()}
                      sx={{
                        background: "#1b5e20",
                        color: "white",
                        borderColor: "#1b5e20",
                        "&:hover": {
                          background: "#1b5e20",
                          color: "white",
                          borderColor: "#1b5e20",
                        },
                      }}
                      variant="outlined"
                      startIcon={<CloudUploadIcon />}>
                      Importar actualizar
                    </Button>
                  </MenuItem>
                </Menu>
              </Grid>
              {!searchWord &&
                (!subcategories.length ? (
                  <NoDataComponents />
                ) : (
                  subcategories.map((subcategorie, index) => (
                    <SubCategoriesCard
                      permiss={permissionsPerUser}
                      subcategorie={subcategorie}
                      key={index}
                    />
                  ))
                ))}

              {searchWord &&
                (!allSubcategories.length ? (
                  <NoResultsComponent />
                ) : (
                  allSubcategories.map((subcategorie, index) => (
                    <SubCategoriesCard
                      permiss={permissionsPerUser}
                      subcategorie={subcategorie}
                      key={index}
                    />
                  ))
                ))}
            </Grid>
          </Box>
          <Box>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              display="flex"
              justifyContent="center">
              <Stack spacing={2} sx={{ marginLeft: 2 }}>
                <Pagination
                  count={totalPages}
                  variant="outlined"
                  shape="rounded"
                  onChange={handleChangePage}
                  color="secondary"
                  page={pages}
                  size="large"
                  sx={{
                    color: "#880e4f",
                    "&:hover": { color: "#f06292" },
                  }}
                />
              </Stack>
            </Grid>
            {/* <AddExitProducts open={open} handleClose={handleClose} /> */}
          </Box>
          <ModalAdd
            modal={modalAdd}
            handleClickOpen={handleClickOpenAdd}
            handleClose={handleClickCloseAdd}
          />
          <ModalUpdateSubcategories
            modalUpdate={modalUpdate}
            handleClickOpen={handleClickOpenUpdate}
            handleClickClose={handleClickCloseUpdate}
          />
        </Grid>
      </Grid>
    </Layout>
  );
}
