import { useReducer } from "react";
import Swal from "sweetalert2";
import MethodGet, { MethodPost } from "../../config/Service";
import { REPORT_UTILITY, SHOW_ERRORS_API } from "../../types";
import fileDownload from "js-file-download";
import ReportUtilityContext from "./ReportUtilityContext";
import ReportUtilityReducer from "./ReportUtilityReducer";

const ReportUtilityState = ({ children }) => {
  const initialState = {
    utility: [],
  };
  const [state, dispatch] = useReducer(ReportUtilityReducer, initialState);

  const ExportReportUtiityGeneral = (data) => {
    Swal.fire({
      title: "Descargar",
      text: "Se desacargara un reporte de los productos mas vendidos",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, descargar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/products/utility/general?start_date=${data.start_date}&end_date=${data.end_date}`;
        MethodGet(url, { responseType: "blob" })
          .then((res) => {
            let timerInterval;
            Swal.fire({
              title: "Generando",
              html: "El reporte se esta generando!",
              timer: 2000,
              timerProgressBar: true,
              showConfirmButton: false,
              // },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              fileDownload(
                res.data,
                `utilidad_general_${data.start_date}_al_${data.end_date}.xlsx`
              );
              Swal.fire({
                title: "Descargado",
                text: "Se ha descargado correctamente el archivo",
                icon: "success",
                timer: 2000,
                showConfirmButton: false,
              });
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: "Ocurrio un error, al descargar el archivo",
              icon: "error",
            });
          });
      }
    });
  };
  const ExportReportUtiity = (data) => {
    Swal.fire({
      title: "Descargar",
      text: "Se desacargara un reporte de los productos mas vendidos",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, descargar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/products/utility/Brand?start_date=${data.start_date}&end_date=${data.end_date}&brand_id=${data.brand}`;
        MethodGet(url)
          .then((res) => {
            let timerInterval;
            console.log(res.data);
            Swal.fire({
              title: "Generando",
              html: "El reporte se esta generando!",
              timer: 2000,
              timerProgressBar: true,
              showConfirmButton: false,
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              fileDownload(
                res.data,
                `utilidad_general_por_marca_${data.start_date}_al_${data.end_date}.xlsx`
              );
              console.log(res.data);

              Swal.fire({
                title: "Descargado",
                text: "Se ha descargado correctamente el archivo",
                icon: "success",
                timer: 2000,
                showConfirmButton: false,
              });
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: "Ocurrio un error, al descargar el archivo",
              icon: "error",
            });
          });
      }
    });
  };
  return (
    <ReportUtilityContext.Provider
      value={{
        ExportReportUtiityGeneral,
        ExportReportUtiity,
      }}>
      {children}
    </ReportUtilityContext.Provider>
  );
};
export default ReportUtilityState;
