import { ADD_TYPE_USERS, GET_ALL_TYPE_USERS, UPDATE_TYPE_USERS, DELETE_TYPE_USERS, SHOW_ERRORS_API, CURRENT_TYPE_USER, AGREGATE_PERMISSIONS, UPDATE_PERMISSIONS } from "../../types";

export default function TypeUsersReducer(state, action) {

    switch (action.type) {
        case GET_ALL_TYPE_USERS:

            return {
                ...state,
                type_users: action.payload,
                success: false,
                ErrorsAPI: []
            }
        case ADD_TYPE_USERS:
            return {
                ...state,
                success: true,
                ErrorsAPI: []
            }
        case AGREGATE_PERMISSIONS:
            return {
                ...state,
                success: true,
                ErrorsAPI: []
            }
        case UPDATE_PERMISSIONS:
            return {
                ...state,
                success: true,
                ErrorsAPI: []
            }
        case SHOW_ERRORS_API:
            return {
                ...state,
                success: true,
                ErrorsAPI: []
            }

        case CURRENT_TYPE_USER:
            return {
                ...state,
                typeuser: action.payload,
            }

        case UPDATE_TYPE_USERS:
            return {
                ...state,
                success: true,
                ErrorsAPI: []
            }

        case DELETE_TYPE_USERS:

            return {
                ...state,
                success: true,
                type_users: state.type_users.filter(type_user => type_user.id !== action.payload)
            }


        default:
            return state;
    }
}

