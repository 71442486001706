import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material/";

const useStyles = makeStyles({
  img_data: {
    margin: "auto",
    height: "40%",
  },
});

export default function NotFoundComponent() {
  const classes = useStyles();
  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <img
        src={require("../../assets/gif/404.gif")}
        className={classes.img_data}
        alt="notFound"
      />
    </Grid>
  );
}
