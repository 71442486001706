import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SpinnerComponent from "../../components/loading/SpinnerComponent";
import { IconButton, Tooltip } from "@mui/material/";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import formatDate from "./../../utils/FormatDate";
import { PriceFormat } from "./../../utils/FormatPrice";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import AccountsForPaymentContext from "../../context/AccountsForPayment/AccountsForPaymentContext";
import { useContext } from "react";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function TableAccountsProvider({ data, provider }) {
  const { downloadPdfAccountDetail } = useContext(AccountsForPaymentContext);
  return (
    <>
      {data && provider ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Folio Factura</StyledTableCell>
                <StyledTableCell align="center">
                  Tipo de factura
                </StyledTableCell>
                <StyledTableCell align="center">Total Factura</StyledTableCell>
                <StyledTableCell align="center">
                  T. Pagado Factura
                </StyledTableCell>
                <StyledTableCell align="center">Fecha</StyledTableCell>
                <StyledTableCell align="center">Opciones</StyledTableCell>
              </TableRow>
            </TableHead>
            {!data ? (
              <SpinnerComponent />
            ) : (
              <TableBody>
                {data.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="center">
                      {row.NoFolio}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.typeInvoice}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      $ {PriceFormat(row.total)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      $ {PriceFormat(row.totalPayment)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {formatDate(row.createdAt)}
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      {row.total > row.totalPayment && (
                        <Tooltip title="Agregar Pago" placement="top">
                          <Link
                            to={`/AgregarPagoACuenta/${provider._id}/${row._id}`}
                          >
                            <IconButton>
                              <AddIcon sx={{ color: "green" }} />
                            </IconButton>
                          </Link>
                        </Tooltip>
                      )}
                      <Tooltip title="Detalle de cuenta" placement="top">
                        <Link to={`/DetalleCuenta/${row._id}/${provider._id}`}>
                          <IconButton>
                            <VisibilityIcon sx={{ color: "#03a9f4" }} />
                          </IconButton>
                        </Link>
                      </Tooltip>
                      <Tooltip title="Editar cuenta" placement="top">
                        <Link to={`/UpdateAccount/${provider._id}/${row._id}`}>
                          <IconButton>
                            <EditIcon sx={{ color: "#0d47a1" }} />
                          </IconButton>
                        </Link>
                      </Tooltip>
                      <Tooltip
                        title="Generar ticket detalle de cuenta"
                        placement="top"
                      >
                        <IconButton
                          onClick={() => downloadPdfAccountDetail(row)}
                        >
                          <PictureAsPdfIcon sx={{ color: "red" }} />
                        </IconButton>
                      </Tooltip>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      ) : (
        <SpinnerComponent />
      )}
    </>
  );
}
