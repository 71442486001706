import React, {useState, useEffect, useContext} from "react";
import FaqCategoriesContext from "../../../context/FaqCategories/FaqCategoriesContext";
import ModalEdit from "../../../containers/faqscategories/Edit";

import {makeStyles} from "@mui/styles";
import {
  Box,
  Card,
  Typography,
  Tooltip,
  Grid,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";

export default function FaqCategoriesCard({faq_categorie, permiss}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [modalUpdate, AbrirModalUpdate] = useState(false);

  const [IdFaqCategory, setIdFaqCategory] = useState();
  const handleClickOpenUpdate = () => {
    AbrirModalUpdate(true);
  };
  const handleClickCloseUpdate = () => {
    sessionStorage.removeItem("faqcategory");
    AbrirModalUpdate(false);
  };

  const selectFaq = (faqcategory) => {
    setIdFaqCategory(faqcategory);
    handleClickOpenUpdate();
    // SaveCurrentFaq(faqcategory);
  };
  const {DeleteFaq} = useContext(FaqCategoriesContext);

  //
  return (
    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
      <Card sx={{boxShadow: 3}}>
        <Box
          fullWidth
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            paddingX: 2,
            backgroundColor: "white",
            color: "black",
          }}
        >
          <Typography
            variant="su arrowbtitle2"
            noWrap
            sx={{fontWeight: "bold", fontSize: "17px"}}
            className="blink-1"
          >
            <LiveHelpIcon /> {faq_categorie.name}
          </Typography>
          <div>
            <Tooltip arrow title="Operaciones" placement="top">
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                sx={{color: "black"}}
              >
                <MoreVertIcon />
              </IconButton>
            </Tooltip>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              {permiss.update === true && (
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    selectFaq(faq_categorie._id);
                  }}
                >
                  Editar
                </MenuItem>
              )}
              {permiss.delete === true && (
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    DeleteFaq(faq_categorie._id);
                  }}
                >
                  Eliminar
                </MenuItem>
              )}
            </Menu>
          </div>
        </Box>
      </Card>
      {IdFaqCategory && (
        <ModalEdit
          modalUpdate={modalUpdate}
          id={IdFaqCategory}
          handleClickOpen={handleClickOpenUpdate}
          handleCloseEdit={handleClickCloseUpdate}
        />
      )}
    </Grid>
  );
}
