import React, { useReducer } from "react";
import GraphsReducer from "./GraphsReducer";
import GraphsContext from "./GraphsContext";
import MethodGet, { MethodPost } from "../../config/Service";
import {
  PRODUCTS_MOST_SOLD,
  PRODUCTS_LESS_SOLD,
  PRODUCTS_STOCK,
  SHOW_ERRORS_API,
} from "../../types";

const GraphsState = ({ children }) => {
  //estado inicial
  const initialState = {
    products: [],
    ErrorsApi: [],
    success: false,
  };

  const [state, dispatch] = useReducer(GraphsReducer, initialState);

  const GetStockProducts = (stock) => {
    let url = `/products/graphics-stock/${stock}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: PRODUCTS_STOCK,
          payload: res.data.products,
        });
      })
      .catch((error) => {
        dispatch({
          type: SHOW_ERRORS_API,
        });
      });
  };

  const GetProductsMostSale = (brand_id) => {
    if (brand_id === undefined) {
    } else {
      let url = `/products/graphics/most-sold/${brand_id}`;
      MethodGet(url)
        .then((res) => {
          dispatch({
            type: PRODUCTS_MOST_SOLD,
            payload: res.data.products,
          });
        })
        .catch((error) => {
          dispatch({
            type: SHOW_ERRORS_API,
          });
        });
    }
  };
  const GetProductsLessSale = (brand_id) => {
    if (brand_id === undefined) {
    } else {
      let url = `/products/graphics/less-sold/${brand_id}`;
      MethodGet(url)
        .then((res) => {
          dispatch({
            type: PRODUCTS_LESS_SOLD,
            payload: res.data.products,
          });
        })
        .catch((error) => {
          dispatch({
            type: SHOW_ERRORS_API,
          });
        });
    }
  };

  return (
    <GraphsContext.Provider
      value={{
        products: state.products,
        ErrorsApi: state.ErrorsApi,
        success: state.success,
        GetStockProducts,
        GetProductsMostSale,
        GetProductsLessSale,
      }}
    >
      {children}
    </GraphsContext.Provider>
  );
};

export default GraphsState;
