import * as React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Layout from "./../../components/layout/Layout";
import Coupons from "./Coupons";
import CouponesExpired from "./CouponesExpired";
import { Grid } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function FullWidthTabs() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Layout>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              //indicatorColor="black"
              textColor="inherit"
              variant="fullWidth"
              sx={{ backgroundColor: "black" }}
              aria-label="full width tabs example">
              <Tab label="Mis Cupones" {...a11yProps(0)} />
              <Tab label="Cupones Vencidos" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}>
            <TabPanel value={value} index={0} dir={theme.direction}>
              <Coupons />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <CouponesExpired />
            </TabPanel>
          </SwipeableViews>
        </Grid>
      </Grid>
    </Layout>
  );
}
