import { Grid, Typography } from "@mui/material";

import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const InfoClient = ({
  client,
  shippmentDirection,
  currentTotal,
  newTotal,
  totalOrder,
  shippingCost,
  order,
}) => {
  return (
    <Grid container spacing={2}>
      {shippmentDirection && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              Dirección de envio: {shippmentDirection.name}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ marginTop: 4 }}
            >
              <Grid container spacing={2}>
                {client && (
                  <>
                    <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                      <Typography fontWeight="bold">N° Folio:</Typography>
                      {order.folio}
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                      <Typography fontWeight="bold">Cliente:</Typography>
                      {client.fullname}
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                      <Typography fontWeight="bold">Email:</Typography>
                      {client.email}
                    </Grid>
                  </>
                )}
                <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                  <Typography fontWeight="bold">Estado:</Typography>
                  {shippmentDirection.state.name}
                </Grid>
                <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                  <Typography fontWeight="bold">Ciudad:</Typography>
                  {shippmentDirection.city}
                </Grid>
                <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                  <Typography fontWeight="bold">Municipio:</Typography>
                  {shippmentDirection.municipality.name}{" "}
                </Grid>
                <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                  <Typography fontWeight="bold">CP:</Typography>
                  {shippmentDirection.postalcode}
                </Grid>
                <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                  <Typography fontWeight="bold">Colonia:</Typography>
                  {shippmentDirection.colony}
                </Grid>
                <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                  <Typography fontWeight="bold">Calle:</Typography>
                  {shippmentDirection.street}
                </Grid>
                <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                  <Typography fontWeight="bold">Entre calle:</Typography>
                  {shippmentDirection.between_street}{" "}
                </Grid>
                <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                  <Typography fontWeight="bold">Referencias:</Typography>
                  {shippmentDirection.references}{" "}
                </Grid>
                <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                  <Typography fontWeight="bold">Telefono:</Typography>
                  {shippmentDirection.phone_number}{" "}
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} lg={3} md={3} xl={3}>
            <Typography fontWeight="bold">
              Gastos de envio: $ {shippingCost}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={3} md={3} xl={3}>
            <Typography fontWeight="bold">
              Total pagado: $ {totalOrder}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={3} md={3} xl={3}>
            <Typography fontWeight="bold">
              Por pagar: $ {Number(newTotal.toFixed(2) - totalOrder.toFixed(2))}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={3} md={3} xl={3}>
            <Typography fontWeight="bold">
              Total:$ {newTotal.toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InfoClient;
