import React, { useContext, useState } from "react";
import UsersContext from "../../../context/Users/UsersContext";
import ModalEdit from "../../../containers/users/ModalEdit";
import ModalImage from "react-modal-image-responsive";
import Marquee from "react-fast-marquee";

import { makeStyles } from "@mui/styles";
import {
  Box,
  Card,
  Typography,
  Tooltip,
  Menu,
  MenuItem,
  IconButton,
  CardContent,
  Avatar,
  Grid,
} from "@mui/material";
import DangerousIcon from "@mui/icons-material/Dangerous";
import CheckIcon from "@mui/icons-material/Check";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import CreateIcon from "@mui/icons-material/Create";
const useStyles = makeStyles({
  PhotoPerfil: {
    width: "120px",
    height: "120px",
    objectFit: "cover",
    padding: "2px",
    borderRadius: "50%",
    margin: "auto",
    marginTop: 10,
    marginBottom: 3,
    boxShadow: "-8px 10px 19px -6px rgba(110,110,110,0.97)",
    textAlign: "center",
    lineHeight: "110px",
  },
});

const UserCard = ({ user, permiss }) => {
  const classes = useStyles();
  const [modalUpdate, AbrirModalUpdate] = useState(false);
  const [id_user, setIdUser] = useState();

  const handleClickOpenUpdate = () => {
    AbrirModalUpdate(true);
  };

  const handleClickCloseUpdate = () => {
    sessionStorage.removeItem("user");
    AbrirModalUpdate(false);
  };

  const { DeleteUsers, RestoreUser } = useContext(UsersContext);

  const SelectUser = (user) => {
    setIdUser(user);
    handleClickOpenUpdate();
  };

  return (
    <Card sx={{ boxShadow: 3 }}>
      <CardContent>
        <Box
          fullWidth
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            paddingX: 2,
            backgroundColor: "white",
            color: "black",
          }}>
          <Typography
            variant="su arrowbtitle2"
            noWrap
            fullWidth
            sx={{ fontWeight: "bold", fontSize: "17px" }}
            className="blink-1">
            <Marquee play={false} gradient={false} speed={48}>
              {user.fullname}
            </Marquee>
          </Typography>
        </Box>

        <Box>
          {!user.profileImage ? (
            <Avatar
              className={classes.PhotoPerfil}
              sx={{ width: 120, height: 120 }}>
              {user.fullname}
            </Avatar>
          ) : (
            <ModalImage
              className={classes.PhotoPerfil}
              small={user.profileImage ? user.profileImage : ""}
              large={user.profileImage ? user.profileImage : ""}
              alt="profile_image"
            />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 2,
          }}>
          <Typography
            component="span"
            variant="body1"
            sx={{ fontSize: "14px" }}>
            {user.email}
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            display="flex"
            justifyContent="space-between">
            <Tooltip title="Editar" placement="top">
              <IconButton
                onClick={() => {
                  SelectUser(user.id);
                }}>
                <CreateIcon className="button_primary" />
              </IconButton>
            </Tooltip>
            {user.status === true && permiss.delete === true ? (
              <Tooltip title="Deshabilitar usuario " placement="top">
                <IconButton
                  onClick={() => {
                    DeleteUsers(user.id);
                  }}>
                  <LockIcon className="button_danger" />
                </IconButton>
              </Tooltip>
            ) : (
              user.status === false &&
              permiss.update === true && (
                <Tooltip title="Habilitar usuario" placement="top">
                  <IconButton
                    onClick={() => {
                      RestoreUser(user.id);
                    }}>
                    <LockOpenIcon className="button_success" />
                  </IconButton>
                </Tooltip>
              )
            )}
          </Grid>
        </Grid>
      </CardContent>
      {id_user && (
        <ModalEdit
          modalUpdate={modalUpdate}
          id={id_user}
          handleClickOpen={handleClickOpenUpdate}
          handleClickClose={handleClickCloseUpdate}
        />
      )}
    </Card>
  );
};

export default UserCard;
