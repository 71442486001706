import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../../components/layout/Layout";
import AccountPaymentTable from "../../../components/Tables/AccountPaymentTable";
const AccountsPayment = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="end"
          sx={{ marginRight: 2 }}>
          <Link to="/AgregarCuentaXpagar">
            <Button variant="contained" size="large">
              Agregar
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12} sx={{ padding: 2 }}>
          <AccountPaymentTable />
        </Grid>
      </Grid>
    </>
  );
};

export default AccountsPayment;
