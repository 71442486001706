import React from "react";
import {
  ADD_MEASURE,
  CURRENT_MEASURE,
  DELETE_MEASURE,
  GET_ALL_MEASURES,
  SHOW_ERRORS_API,
  UPDATE_MEASURE,
} from "../../types";

export default function MeasuresReducer(state, action) {
  switch (action.type) {
    case GET_ALL_MEASURES:
      return {
        ...state,
        measures: action.payload,
        success: false,
        ErrorsAPI: [],
      };
    case ADD_MEASURE:
      return {
        ...state,
        success: true,
        ErrorsAPI: [],
      };
    case SHOW_ERRORS_API:
      return {
        ...state,
        success: true,
        ErrorsAPI: [],
      };
    case CURRENT_MEASURE:
      return {
        ...state,
        ErrorsAPI: [],
      };
    case UPDATE_MEASURE:
      return {
        ...state,
        success: true,
        measure: action.payload,
      };
    case DELETE_MEASURE:
      return {
        ...state,
        success: true,
        measures: state.measures.filter((m) => m.id !== action.payload),
      };
    default:
      return state;
  }
}
