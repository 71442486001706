import React from "react";
import PropTypes from "prop-types";
//import { formatPrice } from "../../../helpers/helpers";
import { TableCell, TableRow } from "@material-ui/core";
import { CalUnitPrice } from "../../utils/CalUnitPrice";
import { CalDiscount } from "../../utils/CalDiscount";
import { Button, Stack, Typography } from "@mui/material";

import ImageIcon from "@mui/icons-material/Image";
import ModalForImage from "./ModalImage";
import { useState } from "react";
import { PriceFormat } from "../../utils/FormatPrice";
const TableProductSale = ({ product, activeTab }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <TableRow>
        <TableCell align="center">
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              onClick={handleClickOpen}
              sx={{
                backgroundColor: "#f06292",
                color: "white",
                "&:hover": { backgroundColor: "#f06292", color: "white" },
              }}
              endIcon={<ImageIcon />}
            >
              Ver
            </Button>
          </Stack>
        </TableCell>
        <TableCell align="center">{product?.product?.barcode}</TableCell>
        <TableCell align="center">{product?.product?.name}</TableCell>
        <TableCell align="center">{product?.product?.product_key}</TableCell>
        <TableCell align="center">
          {/* {product.product.discount === 0 ? ( */}
          <>
            <Typography> $ {PriceFormat(product.product.price)}</Typography>
            {product.priceCurrency && (
              <Typography>
                {/* / {CalUnitPrice(product.subtotalInCurrency, product.quantity)} */}
                / $ {PriceFormat(product.priceCurrency)}
              </Typography>
            )}
          </>
          {/* ) : (
            <>
              <Typography
                sx={{
                  textDecoration: "line-through",
                  color: "#A9A9A9",
                }}>
                {CalUnitPrice(product.subtotal, product.quantity)}
              </Typography>
              <Typography sx={{ fontWeight: "bold", fontSize: 14 }}>
                {CalDiscount(product.product.price, product.product.discount)}
              </Typography>
              {product.subtotalInCurrency && (
                <>
                  <Typography
                    sx={{
                      textDecoration: "line-through",
                      color: "#A9A9A9",
                    }}>
                    {CalUnitPrice(product.subtotalInCurrency, product.quantity)}
                  </Typography>
                  <Typography sx={{ fontWeight: "bold", fontSize: 14 }}>
                    {CalDiscount(
                      product.priceCurrency,
                      product.product.discount
                    )}
                  </Typography>
                </>
              )}
            </>
          )} */}
        </TableCell>
        <TableCell align="center">
          <Typography fontWeight="bold">{product?.quantity} </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography> $ {PriceFormat(product?.subtotal)}</Typography>
          {product.subtotalInCurrency && (
            <Typography>
              / $ {PriceFormat(product?.subtotalInCurrency)}
            </Typography>
          )}
        </TableCell>
        <TableCell align="center">
          {product.product.discount === 0 ? (
            <Typography sx={{ fontWeight: "bold", fontSize: 14 }}>
              $ {PriceFormat(product.subtotal - product.total)}
              {product.totalInCurrency &&
                "  /  $ " +
                  PriceFormat(
                    product.subtotalInCurrency - product.totalInCurrency
                  )}
            </Typography>
          ) : (
            <>
              <Typography
                sx={{
                  textDecoration: "line-through",
                  color: "#A9A9A9",
                }}
              >
                $ {PriceFormat(product.discount * product.quantity)}
                {product.discountInCurrency &&
                  " /  $ " +
                    PriceFormat(product.discountInCurrency * product.quantity)}
              </Typography>
              <Typography sx={{ fontWeight: "bold", fontSize: 14 }}>
                $ {product.total.toFixed(2)}
              </Typography>
            </>
          )}
        </TableCell>
        <TableCell align="center">
          <Typography sx={{ fontWeight: "bold", fontSize: 14 }}>
            $ {product.total.toFixed(2)}
            {product.totalInCurrency &&
              " / $ " + product.totalInCurrency.toFixed(2)}
          </Typography>
        </TableCell>
      </TableRow>
      <ModalForImage
        open={open}
        handleClose={handleClose}
        image={product?.product?.multimedia}
      />
    </>
  );
};
TableProductSale.propTypes = {
  product: PropTypes.object.isRequired,
};
export default TableProductSale;
