import React, { useContext, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import FaqsContext from "../../context/Faqs/FaqsContext";
import MethodGet from "../../config/Service";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import BuildingComponent from "../../components/loading/BuildingComponent";
import { Editor } from "@tinymce/tinymce-react";
import { makeStyles } from "@mui/styles";
import { Grid, Box, Fab, TextField, Tooltip } from "@mui/material";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import SelectCategoriesFaqs from "../SelectOptions/SelectCategoriesFaqs";

const useStyles = makeStyles({
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 2,
  },
});

export default function Edit(props) {
  const classes = useStyles();
  const { success, UpdateFaqs } = useContext(FaqsContext);
  const [cargando, spinnerCargando] = useState(false);

  const [respuesta, setAnswer] = useState("");
  const [FaqCategoryId, saveFaqCategoryId] = useState();

  const detectarCambiosFaqCategory = (e) => {
    saveFaqCategoryId(e.target.value);
  };

  const detectarCambiosAnswer = (e) => {
    setAnswer(e.target.value);
  };
  const [RichText, setRichText] = useState();
  const handleEditorChange = (e) => {
    setRichText(e.target.getContent());
  };
  const [Faq, saveFaq] = useState({});
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  let FaqStorageID = JSON.parse(sessionStorage.getItem("faq"));
  useEffect(() => {
    let url = `/faq/${FaqStorageID}`;
    MethodGet(url)
      .then((res) => {
        saveFaq(res.data.faq);
        setRichText(res.data.faq.answer);
        saveFaqCategoryId(res.data.faq.category._id);
        spinnerCargando(true);
      })
      .catch((error) => {
        spinnerCargando(true);
      });
  }, []);

  const { question, answer } = Faq;

  useEffect(() => {
    if (success) {
      props.history.goBack();
    }
  }, [success]);

  const onSubmit = (data, e) => {
    data.id = FaqStorageID;
    data.answer = RichText;
    data.category = FaqCategoryId;
    UpdateFaqs(data);
  };
  if (!cargando) return <BuildingComponent />;

  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#000000",
              textAling: "center",
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            }}
          >
            <div className={classes.titleCard}>Editar Pregunta Frecuente</div>
            <div className={classes.fab}>
              <Tooltip title="Regresar">
                <Link to="/preguntas-frecuentes">
                  <Fab
                    size="small"
                    sx={{
                      color: "white",
                      backgroundColor: "black",
                      borderRadius: "24px",
                      marginRight: 1,
                      "&:hover": {
                        backgroundColor: "white",
                        color: "black",
                      },
                    }}
                  >
                    <ArrowCircleLeftOutlinedIcon />
                  </Fab>
                </Link>
              </Tooltip>
            </div>
          </Box>
          <form
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter")
                e.preventDefault();
            }}
            autoComplete="off"
          >
            <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    fullWidth
                    id="question"
                    name="question"
                    placeholder="Ejemplo 1: ¿Como registrarse? Ejemplo 2: Registro Influencer"
                    defaultValue={question}
                    label="Define la pregunta"
                    variant="outlined"
                    type="text"
                    error={errors.question ? true : false}
                    helperText={errors?.question?.message}
                    {...register("question", {
                      required: {
                        value: true,
                        message: "Este campo es requerido",
                      },
                      maxLength: {
                        value: 255,
                        message: "Maximo 255 caracteres",
                      },
                    })}
                  />
                </Grid>
                {FaqCategoryId !== "" && (
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <SelectCategoriesFaqs
                      FaqCategoryId={FaqCategoryId}
                      detectarCambiosFaqCategory={detectarCambiosFaqCategory}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Editor
                    initialValue={answer}
                    apiKey="4oemglfeurpxtmylg59yopah4kn16o7e3ma4ryvm44spbnlr"
                    onChange={handleEditorChange}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      textAling: "center",
                      paddingBottom: 12,
                    }}
                  >
                    <div className={classes.fab}>
                      <Fab
                        type="submit"
                        variant="extended"
                        sx={{
                          backgroundColor: "#00B9FF",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#00DEED",
                            color: "white",
                          },
                        }}
                      >
                        <SaveOutlinedIcon sx={{ mr: 1 }} />
                        Guardar
                      </Fab>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Grid>
      </Grid>
    </Layout>
  );
}
