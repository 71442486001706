import React, { useState, useContext, useEffect } from "react";
import SubcategoriesContext from "../../context/SubCategories/SubcategoriesContext";
import CategorySelect from "../../containers/SelectOptions/SelectCategory";
import { useForm } from "react-hook-form";

import { makeStyles } from "@mui/styles";
import {
  Grid,
  Box,
  Fab,
  TextField,
  Tooltip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";

const useStyles = makeStyles({
  card: {
    marginTop: 7,
    marginLeft: 7,
    marginRight: 7,
  },
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 10,
  },
  input: {
    display: "none",
  },
});

export default function Add(props) {
  const classes = useStyles();
  const { modal, handleClose } = props;

  const { AddSubcategories, success } = useContext(SubcategoriesContext);
  const [categoryId, saveCategory] = useState("");
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  useEffect(() => {
    if (success) {
      setValue("name", "", { shouldDirty: true });
    }
  }, [success]);

  const detectarCambiosCategory = (e) => {
    saveCategory(e.target.value);
  };

  const onSubmit = (data, e) => {
    data.category = categoryId;
    AddSubcategories(data);
    handleClose();
  };

  return (
    <Dialog open={modal} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#000000",
            textAling: "center",
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            borderRadius: "0 0 24px 0",
          }}
        >
          <div className={classes.titleCard}>Agregar Subcategoria</div>
          <div className={classes.fab}>
            <Tooltip title="cerrar" arrow>
              <Fab
                size="small"
                onClick={handleClose}
                sx={{
                  color: "#FFFFFF",
                  backgroundColor: "#FF0000",
                  "&:hover": {
                    color: "#FFFFFF",
                    backgroundColor: "#FF0000",
                  },
                }}
              >
                <CancelPresentationIcon />
              </Fab>
            </Tooltip>
          </div>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Aqui podras agregar nuevas sucategorias para tus productos.
        </DialogContentText>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter")
              e.preventDefault();
          }}
          autoComplete="off"
        >
          <Grid container spacing={2} sx={{ marginTop: 1 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CategorySelect
                detectarCambiosCategory={detectarCambiosCategory}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <TextField
                fullWidth
                id="name"
                name="name"
                placeholder="Ejemplo: Sanitizante Spray"
                label="Nombre de la subcategoria"
                variant="outlined"
                sx={{ marginBottom: 2 }}
                error={errors.name ? true : false}
                helperText={errors?.name?.message}
                {...register("name", {
                  required: {
                    value: true,
                    message: "Este campo es requerido",
                  },
                  minLength: {
                    value: 2,
                    message: "Minimo 2 caracteres",
                  },
                  maxLength: {
                    value: 255,
                    message: "Maximo 255 caracteres",
                  },
                })}
              />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  textAling: "center",
                }}
              >
                <div className={classes.fab}>
                  <Fab
                    variant="extended"
                    type="submit"
                    sx={{
                      backgroundColor: "#00B9FF",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#00DEED",
                        color: "white",
                      },
                    }}
                  >
                    <SaveOutlinedIcon sx={{ mr: 1 }} />
                    Guardar
                  </Fab>
                </div>
              </Box>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}
