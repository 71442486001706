import {
  ADD_TAGS,
  CURRENT_TAG,
  DELETE_TAGS,
  GET_ALL_TAGS,
  SHOW_ERRORS_API,
  TAGS_NO_PAGINATE,
  UPDATE_TAGS,
} from "../../types";

export default function TagsReducer(state, action) {
  switch (action.type) {
    case GET_ALL_TAGS:
      return {
        ...state,
        tags: action.payload.tags,
        totalDocs: action.payload.totalDocs,
        limit: action.payload.limit,
        page: action.payload.page,
        paginCounter: action.payload.paginCounter,
        hasPrevPage: action.payload.hasPrevPage,
        hasNextPage: action.payload.hasNextPage,
        prevPage: action.payload.prevPage,
        nextPage: action.payload.nextPage,
        totalPages: action.payload.totalPages,
        success: false,
        ErrorsAPI: [],
      };
    case TAGS_NO_PAGINATE:
      return {
        ...state,
        success: false,
        tags_no_paginate: action.payload,
        ErrorsAPI: [],
      };
    case ADD_TAGS:
      return {
        ...state,
        success: true,
        ErrorsAPI: [],
      };
    case SHOW_ERRORS_API:
      return {
        ...state,
        success: true,
        ErrorsAPI: [],
      };
    case CURRENT_TAG:
      return {
        ...state,
        ErrorsAPI: [],
      };
    case UPDATE_TAGS:
      return {
        ...state,
        success: true,
        tag: action.payload,
      };
    case DELETE_TAGS:
      return {
        ...state,
        success: true,
        tags: state.tags.filter((tag) => tag.id !== action.payload),
      };

    default:
      return state;
  }
}
