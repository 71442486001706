import { Card, Box, Typography, Button, Grid } from "@mui/material";
import { useState } from "react";
import Marquee from "react-fast-marquee";
import ModalImage from "react-modal-image-responsive";
import { makeStyles } from "@mui/styles";
import ShowModal from "../../../containers/products/ProductShow";

const useStyles = makeStyles({
  titleProduct: {
    color: "white",
  },
  ojito: {
    color: "red",
  },
  imgproduct: {
    top: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
  },
});

export default function BySubCategory({ items }) {
  const classes = useStyles();
  const { name, multimedia, _id } = items;
  const [modalShow, AbrirModalShow] = useState();
  const [id_product, setIdproduct] = useState();

  const handleClickOpenShow = () => {
    AbrirModalShow(true);
  };

  const SelectProduct = (product) => {
    setIdproduct(product);
    handleClickOpenShow();
  };

  const handleClickCloseShow = () => {
    sessionStorage.removeItem("product");
    AbrirModalShow(false);
  };

  return (
    <Card sx={{ boxShadow: 3, padding: 1 }}>
      <Box
        fullWidth
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          paddingX: 2,
          backgroundColor: "white",
          color: "black",
        }}
      >
        <Typography
          variant="su arrowbtitle2"
          noWrap
          sx={{ fontWeight: "bold", fontSize: "17px" }}
          className="blink-1"
        >
          <Marquee play={false} gradient={false} speed={45}>
            {name}
          </Marquee>
        </Typography>
        {/* <div> {brand.name}</div> */}
      </Box>
      {multimedia.length > 0 ? (
        <Box sx={{ width: "100%", display: "flex" }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box sx={{ pt: "100%", position: "relative" }}>
                <ModalImage
                  className={classes.imgproduct}
                  small={multimedia[0].path}
                  large={multimedia[0].path}
                  alt={name}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box sx={{ position: "static" }}>
          <img src={require("../../../assets/gif/image-search.gif")} />
        </Box>
      )}
      <div>
        <Box
          fullWidth
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            backgroundColor: "white",
            alignItems: "center",
            color: "black",
          }}
        >
          <Button
            style={{
              backgroundColor: "#303f9f",
              color: "white",
              marginTop: 5,
              marginBottom: 5,
            }}
            onClick={() => {
              SelectProduct(_id);
            }}
          >
            Ver Producto
          </Button>
        </Box>
      </div>
      {id_product && (
        <ShowModal
          modalShow={modalShow}
          id={id_product}
          product={items}
          handleClickOpen={handleClickOpenShow}
          handleCloseShow={handleClickCloseShow}
        />
      )}
    </Card>
  );
}
