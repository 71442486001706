import React from 'react';
import { GET_ALL_PERMISSIONS, GET_MODULES_PER_USER, GET_PERMISSIONS, GET_PERMISSIONS_TYPE_USER, UPDATE_PERMISSIONS } from '../../types';

export default function PermissionsReducer(state, action) {
    switch (action.type) {
        case GET_PERMISSIONS:
            return {
                ...state,
                permissionsUser: action.payload,
                success: false,
                ErrorsApi: []
            }
        case UPDATE_PERMISSIONS:
            return {
                ...state,
                success: true,
                ErrorsApi: []
            }
        case GET_ALL_PERMISSIONS:
            return {
                ...state,
                permissions: action.payload,
                success: false,
                ErrorsApi: []
            }
        case GET_PERMISSIONS_TYPE_USER:
            return {
                ...state,
                permissionsTypeUser: action.payload,
                success: false,
                ErrorsApi: [],
            }
        case GET_MODULES_PER_USER:
            return {
                ...state,
                permissionsPerUser: action.payload,
                succes: false,
                ErrorsApi: [],
            }
        default:
            return state;
    }
}
