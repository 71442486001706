import React, { useReducer } from "react";
import Swal from "sweetalert2";
import MethodGet, {
  MethodDelete,
  MethodPost,
  MethodPut,
} from "../../config/Service";
import {
  ADD_POLICIES,
  DELETE_POLICIE,
  GET_POLICIES,
  UPDATE_POLICIES,
} from "../../types";
import PoliciesContext from "./PoliciesContext";
import PoliciesReducer from "./PoliciesReducer";

const PoliciesState = ({ children }) => {
  //estado inicial
  const initialState = {
    policies: [],
    policie: [],
    ErrorsAPI: [],
    success: false,
  };
  const [state, dispatch] = useReducer(PoliciesReducer, initialState);

  const GetPolicies = () => {
    let url = "/policies";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_POLICIES,
          payload: res.data.policies,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const AddPolicies = (data) => {
    let url = "/policies";
    MethodPost(url, data)
      .then((res) => {
        Swal.fire({
          title: "Agregado",
          text: res.data.message,
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        dispatch({
          type: ADD_POLICIES,
          payload: res.data.policie,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const UpdatePolicies = (data) => {
    let url = `/policies/${data.id}`;
    MethodPut(url, data)
      .then((res) => {
        dispatch({
          type: UPDATE_POLICIES,
          payload: res.data.policie,
        });
        Swal.fire({
          title: "Actualizado",
          text: res.data.message,
          timer: 1500,
          icon: "success",
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const DeletePolicie = (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "Se eliminaran las politicas seleccionadas",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/policies/${id}`;
        MethodDelete(url)
          .then((res) => {
            Swal.fire({
              title: "Eliminado",
              text: res.data.message,
              timer: 1500,
              showConfirmButton: false,
              icon: "success",
            });
            dispatch({
              type: DELETE_POLICIE,
              payload: id,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              timer: 1500,
              showConfirmButton: false,
            });
          });
      }
    });
  };
  return (
    <PoliciesContext.Provider
      value={{
        policies: state.policies,
        policie: state.policie,
        ErrorsAPI: state.ErrorsAPI,
        success: state.success,
        GetPolicies,
        AddPolicies,
        UpdatePolicies,
        DeletePolicie,
      }}
    >
      {children}
    </PoliciesContext.Provider>
  );
};

export default PoliciesState;
