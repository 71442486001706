import {
  ADD_SLIDER,
  DELETE_SLIDER,
  GET_ALL_SLIDERS,
  SHOW_ERRORS_API,
  UPDATE_SLIDER,
} from "../../types";

export default function SliderEcommerceReducer(state, action) {
  switch (action.type) {
    case GET_ALL_SLIDERS:
      return {
        ...state,
        sliders: action.payload,
        success: false,
        ErrorsAPI: [],
      };
    case ADD_SLIDER:
      return {
        ...state,
        success: true,
        ErrorsAPI: [],
      };
    case SHOW_ERRORS_API:
      return {
        ...state,
        success: false,
        ErrorsAPI: [],
      };
    case UPDATE_SLIDER:
      return {
        ...state,
        success: true,
        ErrorsAPI: [],
      };

    case DELETE_SLIDER:
      return {
        ...state,
        success: true,
        sliders: state.sliders.filter(
          (slider) => slider._id !== action.payload
        ),
      };
    default:
      return state;
  }
}
