import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import invoicesContext from "../../context/Invoices/InvoicesContext";
import { useContext } from "react";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function TableArray({
  globalInvoice,
  saveGlobalInvoice,
  paymentMethod,
  totalFinal,
}) {
  const eliminarOrder = (id) => {
    const todosProducto = globalInvoice.filter((p) => p._id !== id);
    saveGlobalInvoice(todosProducto);
  };
  const { CreateGlobalInvocie } = useContext(invoicesContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const onSubmit = (data, e) => {
    Swal.fire({
      title: "Las ventas seleccionadas seran facturadas, ¿Quieres continuar?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, facturar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        console.log(data);
        data.orders = globalInvoice;
        data.paymentForm = paymentMethod;
        data.total = totalFinal;
        CreateGlobalInvocie(data);
        saveGlobalInvoice([]);
      }
    });
  };

  return (
    <>
      <div>
        <Typography fontWeight="bold">
          Total a facturar: $ {totalFinal}
        </Typography>
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Folio de venta</StyledTableCell>
              <StyledTableCell align="center">Opciones</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {globalInvoice.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell align="center">{row.folio}</StyledTableCell>
                <StyledTableCell align="center">
                  <IconButton onClick={() => eliminarOrder(row._id)}>
                    <Tooltip title="eliminar">
                      <DeleteIcon sx={{ color: "red" }} />
                    </Tooltip>
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.code === "Enter" || e.code === "NumpadEnter")
            e.preventDefault();
        }}
        autoComplete="off">
        <div style={{ justifyContent: "center", margin: 5, display: "flex" }}>
          <Button variant="contained" size="small" type="submit">
            Faturar Ventas
          </Button>
        </div>
      </form>
    </>
  );
}
