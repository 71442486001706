import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../context/auth/authContext";
import LogoContext from "../../../context/Logo/LogoContext";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import {
  Button,
  TextField,
  Grid,
  Box,
  Typography,
  InputLabel,
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
} from "@mui/material/";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Swal from "sweetalert2";
import { useCheckInternet } from "../../../hooks/tools/useCheckInternet";
import { useLayoutEffect } from "react";
import { Loading } from "@nextui-org/react";

const useStyles = makeStyles({
  textlogin: {
    fontSize: "15px",
    color: "black",
    fontWeight: "bold",
    fontStyle: "oblique",
    letterSpacing: "1px",
  },
  logo: {
    margin: "auto",
  },
  backgroundLogin: {
    //backgroundColor: "#ec407a",
    height: "100vh",
    width: "100%",
    //backgroundImage: `url(${Image})`,
    backgroundImage:
      // "url(https://images.pexels.com/photos/531880/pexels-photo-531880.jpeg)",
      "url(https://source.unsplash.com/random/2560x1440)",
    // backgroundImage:
    //   "url(https://i.pinimg.com/564x/d3/1b/b8/d31bb87ec92c82e05f5afa30f03eae2a.jpg)",
    backgroundRepeat: "no-repeat",
    opacity: 1,
    overflowY: "none",
    //filter: "blur(4px)",
  },
  // caja: {
  // 	backgroundColor: "white !important",
  // },
  caja: {
    background: "rgba(255, 255, 255, 0.46)",
    borderRadius: "16px",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(20px)",
    border: "1px solid rgba(255, 255, 255, 0.3)",
  },
});

function Copyright(props) {
  return (
    <Typography variant="body2" color="white" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" to="https://digital-pineapple.com.mx/">
        Digital Pineapple
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// const AlertaInternet = (val) => {
//   if (val) {
//     const Toast = Swal.mixin({
//       toast: true,
//       position: "top-end",
//       showConfirmButton: false,
//       timer: 3000,
//       timerProgressBar: true,
//       didOpen: (toast) => {
//         toast.addEventListener("mouseenter", Swal.stopTimer);
//         toast.addEventListener("mouseleave", Swal.resumeTimer);
//       },
//     });
//     Toast.fire({
//       icon: "success",
//       title: "Signed in successfully",
//     });
//   } else {
//     const Toast = Swal.mixin({
//       toast: true,
//       position: "top-end",
//       showConfirmButton: false,
//       timer: 3000,
//       timerProgressBar: true,
//       didOpen: (toast) => {
//         toast.addEventListener("mouseenter", Swal.stopTimer);
//         toast.addEventListener("mouseleave", Swal.resumeTimer);
//       },
//     });
//     Toast.fire({
//       icon: "error",
//       title: "Signed in successfully",
//     });
//   }
// };
export default function LoginView() {
  const { isOnline } = useCheckInternet();
  const HandleShowAlert = () => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    if (isOnline) {
      Toast.fire({
        icon: "success",
        title: "Se ha restablecido la conexión",
      });
    } else {
      Toast.fire({
        icon: "error",
        title: "Verifica tu conexión",
      });
    }
  };
  const classes = useStyles();

  const { iniciarSesion, autenticado, cerrarSesion } = useContext(AuthContext);
  const { logos, GetLogo, success } = useContext(LogoContext);
  const history = useHistory();

  //Contraseña
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    GetLogo();
  }, []);

  useEffect(() => {
    if (autenticado) {
      history.push("/dashboard");
    }
  }, [autenticado, history]);

  useLayoutEffect(() => {
    HandleShowAlert();
  }, [isOnline]);

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: (formData) => {
      iniciarSesion(formData);
    },
  });

  if (!logos)
    return (
      <>
        (<p>Spinner</p>)
      </>
    );

  return (
    <div className={classes.backgroundLogin}>
      {/* <AlertaInternet isOnline={isOnline} /> */}
      <Grid container justifyContent="center">
        <div
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              boxShadow: 3,
              m: 5,
              padding: 4,
              position: "relative",
              marginTop: 25,
            }}
            className={classes.caja}
          >
            {!logos.logo ? (
              <></>
            ) : (
              <Typography
                variant="h6"
                sx={{
                  position: "absolute",
                  textAlign: "center",
                  top: 140,
                  right: 0,
                  left: 0,
                }}
              >
                Iniciar Sesion
              </Typography>
            )}

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {!logos.logo ? (
                <>
                  <Loading size="large" type="spinner" />
                </>
              ) : (
                <>
                  {" "}
                  <img src={logos.logo} alt=" " className={classes.logo} />
                </>
              )}
            </Box>
            <Box
              component="form"
              onSubmit={formik.handleSubmit}
              sx={{
                mt: 3,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                  <TextField
                    fullWidth
                    id="email"
                    label="Correo Electronico"
                    name="email"
                    autoComplete="off"
                    onChange={formik.handleChange}
                    error={formik.errors?.email ? true : false}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel
                      htmlFor="password"
                      error={formik.errors?.password ? true : false}
                    >
                      Contraseña
                    </InputLabel>
                    <OutlinedInput
                      error={formik.errors?.password ? true : false}
                      id="password"
                      name="password"
                      type={values.showPassword ? "text" : "password"}
                      value={values.password}
                      onChange={(event) => {
                        setValues({ ...values, password: event.target.value });
                        formik.handleChange(event);
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Contraseña"
                    />
                  </FormControl>
                  {/* <TextField
                    fullWidth
                    name="password"
                    label="Contraseña"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    onChange={(event)=>{formik.handleChange(event)}}
                    error={formik.errors?.password ? true : false}
                  /> */}
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  backgroundColor: "#15c0cc",
                  color: "white",
                  fontWeight: "bold",
                  mt: 3,
                  mb: 2,
                  "&:hover": {
                    backgroundColor: "#31cdd8",
                  },
                }}
              >
                Iniciar Sesion
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link to="/Olvide-mi-Acceso">
                    ¿Has olvidado la contraseña?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Copyright />
        </div>
      </Grid>
    </div>
  );
}

function initialValues() {
  return {
    email: "",
    password: "",
  };
}

function validationSchema() {
  return {
    email: Yup.string().email(true).required("Correo requerido"),
    password: Yup.string().min(8).required("Contraseña requerida"),
  };
}
