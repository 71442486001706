import React, { useReducer } from "react";
import MethodGet from "../../config/Service";
import {
  GET_ALL_PERMISSIONS,
  GET_MODULES_PER_USER,
  GET_PERMISSIONS,
  SHOW_ERRORS_API,
} from "../../types";
import PermissionsContext from "./PermissionsContext";
import PermissionsReducer from "./PermissionsReducer";

const PermissionsState = ({ children }) => {
  //estado inicial
  const initialState = {
    permissionsUser: [],
    permissionsPerUser: [],
    permissions: [],
    permission: null,
    ErrorsApi: [],
    success: false,
  };
  const [state, dispatch] = useReducer(PermissionsReducer, initialState);

  const GetPermissionsUser = () => {
    localStorage.removeItem("permissionsUser");

    let url = "/permissions/user/modules";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_PERMISSIONS,
          payload: res.data.modules,
        });
      })
      .catch((error) => {
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.message,
        });
      });
  };

  const GetPermissions = () => {
    localStorage.removeItem("permissions");
    let url = "/permissions";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_PERMISSIONS,
          payload: res.data.modules,
        });
      })
      .catch((error) => {
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.message,
        });
      });
  };

  const GetModulesPerUser = (id) => {
    let url = `/permissions/module/${id}`;
    sessionStorage.removeItem("permissions");
    localStorage.removeItem("module_id");
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_MODULES_PER_USER,
          payload: res.data.permissions,
        });
        localStorage.setItem("module_id", id);
      })
      .catch((error) => {
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.message,
        });
      });
  };

  return (
    <PermissionsContext.Provider
      value={{
        permissionsUser: state.permissionsUser,
        permissionsPerUser: state.permissionsPerUser,
        permissions: state.permissions,
        permission: state.permission,
        ErrorsApi: state.ErrorsApi,
        success: state.success,
        GetPermissionsUser,
        GetPermissions,
        GetModulesPerUser,
      }}>
      {children}
    </PermissionsContext.Provider>
  );
};

export default PermissionsState;
