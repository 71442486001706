import React from "react";
import Layout from "../../components/layout/Layout";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Button, Radio, Typography } from "@mui/material";
import ProductSelectReactSelect from "../SelectOptions/ProductSelectReactSelect";
import { useState } from "react";
import CardProduct from "../../components/SellComponents/CardProduct";
import ProductsOfSale from "../../components/SellComponents/ProductsOfSale";
import InfoSale from "../../components/SellComponents/InfoSale";
import Barcode from "../../components/SellComponents/Barcode";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
export default function Sell() {
  //radio button
  const [selectedValueRadio, setSelectedValue] = React.useState("select");
  const handleChangeRadio = (event) => {
    setSelectedValue(event.target.value);
  };
  const [product, saveProduct] = useState("");

  const detectarCambiosProductSelect = (value) => {
    saveProduct(value.value);
    //saveProduct("");
  };
  //carrito
  const [productList, saveProductList] = useState([]);
  const buy_products = productList.length;
  const [subtotal, saveSubtotal] = useState(0);
  const [total, saveTotal] = useState(0);
  const [discount, saveDiscount] = useState(0);

  //creacion de arreglos de productos
  const cart = productList.reduce(
    (acc, item) => {
      const { product_id } = item;

      if (item.discount > 0 && item.product_type === "1") {
        acc.productsDiscount.push({
          product_id: item.product_id,
          quantity: item.cantidad,
        });
      } else if (item.discount === 0 && item.product_type === "1") {
        acc.productsWithoutDiscount.push({
          product_id: item.product_id,
          quantity: item.cantidad,
        });
      } else {
        acc.productsCanvas.push({
          product_id: item.product_id,
          quantity: item.cantidad,
        });
      }
      return acc;
    },
    { productsDiscount: [], productsWithoutDiscount: [], productsCanvas: [] }
  );

  return (
    <Layout>
      <Box sx={{ flexGrow: 1, margin: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8}>
            <Item sx={{ background: "black" }}>
              <Typography
                fontSize="25px"
                fontStyle="italic"
                fontWeight="bold"
                sx={{ color: "white" }}
              >
                Nueva Venta de tienda
              </Typography>
            </Item>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              display="flex"
              justifyContent="center"
              sx={{ marginTop: 2 }}
            >
              <label style={{ fontSize: 20, marginTop: 5 }}>
                Codigo de barras
              </label>
              <Radio
                checked={selectedValueRadio === "barcode"}
                onChange={handleChangeRadio}
                value="barcode"
                name="radio-buttons"
                inputProps={{ "aria-label": "A" }}
              />
              <label style={{ fontSize: 20, marginTop: 5 }}>
                Seleccionar Producto
              </label>
              <Radio
                checked={selectedValueRadio === "select"}
                onChange={handleChangeRadio}
                value="select"
                name="radio-buttons"
                inputProps={{ "aria-label": "B" }}
              />
            </Grid>
            <Grid container spacing={2} sx={{ marginTop: 2 }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {selectedValueRadio === "select" ? (
                  <Item>
                    <ProductSelectReactSelect
                      detectarCambiosProductSelect={
                        detectarCambiosProductSelect
                      }
                    />
                  </Item>
                ) : (
                  <Item>
                    <Barcode
                      product={product}
                      saveProduct={saveProduct}
                      productList={productList}
                      saveProductList={saveProductList}
                    />
                  </Item>
                )}
                {product && (
                  <Item>
                    <CardProduct
                      id={product}
                      productList={productList}
                      saveProductList={saveProductList}
                      saveProduct={saveProduct}
                      product={product}

                      //cantidadComprada={cantidadComprada}
                      //saveCantidadComprada={saveCantidadComprada}
                    />
                  </Item>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <InfoSale
              cart={cart}
              total={total}
              subtotal={subtotal}
              discount={discount}
              buy_products={buy_products}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {!productList.length ? (
            <></>
          ) : (
            <Item>
              <ProductsOfSale
                productList={productList}
                saveProductList={saveProductList}
                subtotal={subtotal}
                saveSubtotal={saveSubtotal}
                total={total}
                saveTotal={saveTotal}
                discount={discount}
                saveDiscount={saveDiscount}
                buy_products={buy_products}
              />
            </Item>
          )}
        </Grid>
      </Box>
    </Layout>
  );
}
