import React, { useState, useEffect, useContext } from "react";
import BusinessRulesContext from "../../context/BusinessRules/BusinessRulesContext";
import { useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Box,
  Fab,
  TextField,
  Tooltip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import SelectTypeBusiness from "../SelectOptions/TypeSelectBR";
import SelectSubcategories from "../SelectOptions/SelectSubcategories";
import CategoriesSelect from "../SelectOptions/SelectCategory";
import SelectTypeClient from "../SelectOptions/SelectTypeClients";
import { SelectTypeDirection } from "../SelectOptions/SelectTypeDirection";
const useStyles = makeStyles({
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 2,
  },
});

export default function Add(props) {
  const classes = useStyles();
  const { modal, handleClose } = props;
  //selectTypeDirection
  const [typeDirection, saveTypeDirection] = useState(null);
  const detectarCambiosTypeDirection = (e) => {
    saveTypeDirection(e.target.value);
  };
  //select type
  const [selectType, setSelectType] = useState(null);
  const detectarCambiosTypeBusinessRule = (e) => {
    setSelectType(e.target.value);
  };
  const [minimumMoney, setMinimumMoney] = useState(0);
  const [minimumWeigth, setMinimumWeigth] = useState(0);
  const {
    success,
    AddBusinessRules,
    AddBusinessRulesSubcategory,
    AddBusinessRulesCategory,
    AddBusinessRulesSalesFisic,
    AddBusinessRulesShippment,
  } = useContext(BusinessRulesContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  //select subcategorias
  const [SubcategoryId, saveSubcategory] = useState(null);
  const detectarCambiosSubcategory = (e) => {
    saveSubcategory(e.target.value);
  };
  //select categoria
  const [categoryId, saveCategoryId] = useState(null);
  const detectarCambiosCategory = (e) => {
    saveCategoryId(e.target.value);
  };
  //select tipo de cliente
  const [typeClientId, saveTypeClientId] = useState(null);
  const detectarCambiosTypeClients = (e) => {
    saveTypeClientId(e.target.value);
  };

  const reset = () => {
    setValue("minimum_money", "", { shouldDirty: true });
    setValue("maximum_money", "", { shouldDirty: true });
    setValue("discount", "", { shouldDirty: true });
    setValue("minimum_weigth", "", { shouldDirty: true });
    setValue("maximum_weigth", "", { shouldDirty: true });
    setValue("shipping_cost", "", { shouldDirty: true });
    setSelectType(null);
    saveSubcategory(null);
    saveCategoryId(null);
    saveTypeClientId(null);
    saveTypeDirection(null);
  };

  const onSubmit = (data, e) => {
    data.type = selectType;
    if (data.type === 1) {
      data.subcategory = SubcategoryId;
      data.type = selectType;
      AddBusinessRulesSubcategory(data);
    }
    if (data.type === 2) {
      data.type = selectType;
      AddBusinessRules(data);
    }
    if (data.type === 4) {
      data.type = selectType;
      data.category = categoryId;
      AddBusinessRulesCategory(data);
    }
    if (data.type === 5) {
      data.type = selectType;
      data.customerType = typeClientId;
      AddBusinessRulesSalesFisic(data);
    }
    if (data.type === 6) {
      data.type = selectType;
      data.typeDirection = typeDirection;
      AddBusinessRulesShippment(data);
    }

    handleClose();
    reset();
  };

  return (
    <Dialog fullWidth maxWidth="md" open={modal} onClose={handleClose}>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#000000",
            textAling: "center",
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            borderRadius: "0 0 24px 0",
          }}
        >
          <div className={classes.titleCard}>Agrega Regla de negocio</div>
          <div className={classes.fab}>
            <Tooltip title="cerrar" arrow>
              <Fab
                size="small"
                onClick={handleClose}
                sx={{
                  color: "#FFFFFF",
                  backgroundColor: "#FF0000",
                  "&:hover": {
                    color: "#FFFFFF",
                    backgroundColor: "#FF0000",
                  },
                }}
              >
                <CancelPresentationIcon />
              </Fab>
            </Tooltip>
          </div>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginBottom: "6px" }}>
          Aqui podras agregar nuevas reglas de negocio.
        </DialogContentText>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter")
              e.preventDefault();
          }}
          autoComplete="off"
        >
          <Box sx={{ display: "flex", paddingTop: 4, p: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SelectTypeBusiness
                  TypeId={selectType}
                  detectarCambiosTypeBusinessRule={
                    detectarCambiosTypeBusinessRule
                  }
                />
              </Grid>
              {selectType === 2 || selectType === 5 ? (
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    fullWidth
                    id="minimum_money"
                    label="Cantidad Minima"
                    name="minimum_money"
                    variant="outlined"
                    type="number"
                    error={errors.minimum_money ? true : false}
                    helperText={errors?.minimum_money?.message}
                    {...register("minimum_money", {
                      required: {
                        value: true,
                        message: "La cantidad minima es requerida",
                      },
                      pattern: {
                        value: /^([0-9])*$/,
                        message: "Error: Solo una cantidad entera",
                      },
                      onChange: ({ target }) => {
                        const minValor = parseInt(target.value) + 1;
                        setMinimumMoney(minValor);
                      },
                    })}
                  />
                </Grid>
              ) : (
                <></>
              )}
              {selectType === 2 || selectType === 5 ? (
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    fullWidth
                    id="maximum_money"
                    label="Cantidad Maxima"
                    name="maximum_money"
                    variant="outlined"
                    type="number"
                    error={errors.maximum_money ? true : false}
                    helperText={errors?.maximum_money?.message}
                    {...register("maximum_money", {
                      required: {
                        value: true,
                        message: "La cantidad maxima es requerida",
                      },
                      pattern: {
                        value: /^([0-9])*$/,
                        message: "Error: Solo una cantidad entera",
                      },
                      min: {
                        value: parseInt(minimumMoney),
                        message:
                          "El catidad maxima tiene que ser mayor a la cantidad minima",
                      },
                    })}
                  />
                </Grid>
              ) : (
                <></>
              )}
              {selectType === 5 && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <SelectTypeClient
                    detectarCambiosTypeClients={detectarCambiosTypeClients}
                  />
                </Grid>
              )}
              {selectType === 1 && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <SelectSubcategories
                    detectarCambiosSubcategory={detectarCambiosSubcategory}
                  />
                </Grid>
              )}
              {selectType === 4 && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <CategoriesSelect
                    detectarCambiosCategory={detectarCambiosCategory}
                  />
                </Grid>
              )}
              {selectType !== "" && selectType === 6 && (
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    fullWidth
                    id="minimum_weigth"
                    label="Peso Minimo"
                    name="minimum_weigth"
                    variant="outlined"
                    type="number"
                    error={errors.minimum_weigth ? true : false}
                    helperText={errors?.minimum_weigth?.message}
                    {...register("minimum_weigth", {
                      required: {
                        value: true,
                        message: "El peso minimo es requerido",
                      },
                      pattern: {
                        value: /^([0-9])*$/,
                        message: "Error: Solo una cantidad entera",
                      },
                      onChange: ({ target }) => {
                        const minValor = parseInt(target.value) + 1;
                        setMinimumWeigth(minValor);
                      },
                    })}
                  />
                </Grid>
              )}
              {selectType !== "" && selectType === 6 && (
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    fullWidth
                    id="maximum_weigth"
                    label="Peso Maximo"
                    name="maximum_weigth"
                    variant="outlined"
                    type="number"
                    error={errors.maximum_weigth ? true : false}
                    helperText={errors?.maximum_weigth?.message}
                    {...register("maximum_weigth", {
                      required: {
                        value: true,
                        message: "El peso maximo es requerido",
                      },
                      pattern: {
                        value: /^([0-9])*$/,
                        message: "Error: Solo una cantidad entera",
                      },
                      min: {
                        value: parseInt(minimumWeigth),
                        message:
                          "El catidad maxima tiene que ser mayor a la cantidad minima",
                      },
                    })}
                  />
                </Grid>
              )}
              {selectType !== "" && selectType === 6 && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    fullWidth
                    id="shipping_cost"
                    label="Costo de envio"
                    variant="outlined"
                    name="shipping_cost"
                    type="number"
                    error={errors.shipping_cost ? true : false}
                    helperText={errors?.shipping_cost?.message}
                    {...register("shipping_cost", {
                      required: {
                        value: true,
                        message: "El costo de envio requerido",
                      },
                    })}
                  />
                </Grid>
              )}
              {selectType !== "" && selectType === 6 && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <SelectTypeDirection
                    detectarCambiosTypeDirection={detectarCambiosTypeDirection}
                  />
                </Grid>
              )}
              {selectType !== null && selectType !== 6 && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    fullWidth
                    id="discount"
                    label="Porcentaje de Descuento"
                    variant="outlined"
                    name="discount"
                    type="number"
                    error={errors.discount ? true : false}
                    helperText={errors?.discount?.message}
                    {...register("discount", {
                      required: {
                        value: true,
                        message: "El descuento requerido",
                      },
                      min: {
                        value: 1,
                        message: "Minimo 1% de descuento",
                      },
                      max: {
                        value: 50,
                        message: "Maximo 50% de descuento",
                      },
                    })}
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    textAling: "center",
                  }}
                >
                  <div className={classes.fab}>
                    <Fab
                      variant="extended"
                      type="submit"
                      sx={{
                        backgroundColor: "#00B9FF",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#00DEED",
                          color: "white",
                        },
                      }}
                    >
                      <SaveOutlinedIcon sx={{ mr: 1 }} />
                      Guardar
                    </Fab>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}
