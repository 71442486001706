import React from 'react';
import { GET_LOGO, UPDATE_LOGO } from '../../types';

export default function LogoReducer(state, action) {
    switch (action.type) {
        case GET_LOGO:
            return {
                ...state,
                logos: action.payload,
                success: false,
                ErrorsAPI: []
            }
        case UPDATE_LOGO:
            return {
                ...state,
                success: true,
                ErrorsAPI: []
            }
        default:
            return state;
    }
}
