import React, { useContext, useState } from "react";
import FaqsContext from "../../../context/Faqs/FaqsContext";
import { Link } from "react-router-dom";

import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Typography, Box, IconButton, Tooltip } from "@mui/material/";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Editor } from "@tinymce/tinymce-react";
//Acordion

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function FaqCard({ faqitems, permiss }) {
  const { DeleteFaqs, SaveCurrentFaq } = useContext(FaqsContext);
  const SelectFaq = (faq) => {
    SaveCurrentFaq(faq);
  };
  const [anchorEl, setAnchorEl] = useState(null);

  //-------------Accordion
  const [expanded, setExpanded] = useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      {faqitems.map((faqitem) => {
        return (
          <Accordion
            expanded={expanded === faqitem._id}
            onChange={handleChange(faqitem._id)}
            key={faqitem._id}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div className="tittleFaq">
                  <Typography
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    {faqitem.question}
                  </Typography>
                </div>
                <div className="categoryFaq">
                  {faqitem.category.name}

                  {permiss.update === true && (
                    <Tooltip title="Editar" arrow>
                      <Link to={`/edita-pregunta-frecuente`}>
                        <IconButton
                          color="primary"
                          size="small"
                          onClick={() => {
                            setAnchorEl(null);
                            SelectFaq(faqitem._id);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Link>
                    </Tooltip>
                  )}
                  {permiss.delete === true && (
                    <Tooltip title="Elimnar">
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={() => {
                          setAnchorEl(null);
                          DeleteFaqs(faqitem._id);
                        }}
                        sx={{ color: "red" }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Editor
                apiKey="4oemglfeurpxtmylg59yopah4kn16o7e3ma4ryvm44spbnlr"
                initialValue={faqitem.answer}
                inline={true}
                disabled={true}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}
