import { Card, Divider, Grid, Paper, Typography, Button } from "@mui/material";
import React from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import ModalImage from "react-modal-image-responsive";
import SalesContext from "../../context/Sales/SalesContext";
import { useContext } from "react";
import { useForm } from "react-hook-form";
const useStyles = makeStyles(() => ({
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },

  fab: {
    position: "relative",
    marginTop: 2,
  },
  img: {
    height: "200px",
    width: "150px",
  },
}));

export default function DetailsCanvas({ product, multimedia, order_id }) {
  const classes = useStyles();
  const { product_id } = product;
  const { variation } = product_id;

  const { requestImages } = useContext(SalesContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const onSubmit = (data, e) => {
    data.order_id = order_id;
    data.product_id = product_id._id;
    requestImages(data);
  };
  return (
    <>
      <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#000000",
              textAling: "center",
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            }}>
            <div className={classes.titleCard}>
              Detalle de canvas personalizado
            </div>
          </Box>
        </Grid>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          paddingX: 3,
          marginBottom: 4,
        }}>
        {variation && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Divider>
              <Chip
                sx={{ fontWeight: "bold", fontSize: 18 }}
                label={`Imagenes requeridas: ${variation.number_images}`}
                icon={<PhotoLibraryIcon />}
              />
            </Divider>
          </Grid>
        )}
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          paddingX: 3,
          marginBottom: 4,
        }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Paper elevation={2}>
              <Box textAlign="center">
                {variation && (
                  <Typography variant="h5" fontStyle="initial">
                    Modelo: {variation.name}
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  alignContent: "center",
                  flexDirection: "column",
                  flexWrap: "nowrap",
                  justifyContent: "center",
                }}>
                {variation && (
                  <ModalImage
                    small={variation.image}
                    large={variation.image}
                    alt={variation.name}
                  />
                )}
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Paper elevation={2}>
              {product.canvasStatus === true && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    alignContent: "flex-end",
                    flexDirection: "column",
                    flexWrap: "nowrap",
                    justifyContent: "flex-end",
                    marginBottom: 2,
                  }}>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    autoComplete="off"
                    onKeyDown={(e) => {
                      if (e.code === "Enter" || e.code === "NumpadEnter")
                        e.preventDefault();
                    }}>
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{ background: "#0d47a1", color: "white" }}>
                      volver a solicitar imagenes
                    </Button>
                  </form>
                </Box>
              )}
              <Box textAlign="center">
                <Typography variant="h5">Imagenes a utilizar</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  alignContent: "center",
                  flexDirection: "column",
                  flexWrap: "nowrap",
                  justifyContent: "center",
                }}>
                {multimedia &&
                  multimedia.map((m, index) => (
                    <>
                      <Typography>#imagen: {index + 1}</Typography>
                      <ModalImage
                        className={classes.img}
                        small={m.path}
                        large={m.path}
                        alt={m.path}
                      />
                    </>
                  ))}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
