import React, { Fragment } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import ReactSelect from "react-select";
import StatesContext from "../../context/States/StatesContext";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

const SelectMunicipality = (props) => {
  const { municipalities, GetMunicipalities } = useContext(StatesContext);
  useEffect(() => {
    GetMunicipalities(props.state_id);
  }, [props.state_id]);
  const selectStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };
  const detectarCambiosMunicipality = (e) => {
    props.detectarCambiosMunicipality(e.target.value);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Municipios</InputLabel>
          {props.municipality_id ? (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Categorias"
              onChange={detectarCambiosMunicipality}
              value={props.municipality_id}>
              {municipalities.map((municipality) => (
                <MenuItem key={municipality._id} value={municipality._id}>
                  {municipality.name}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Categorias"
              onChange={detectarCambiosMunicipality}>
              {municipalities.map((municipality) => (
                <MenuItem key={municipality._id} value={municipality._id}>
                  {municipality.name}
                </MenuItem>
              ))}
            </Select>
          )}
          <FormHelperText>Campo Requerido*</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default SelectMunicipality;
