import { Button, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import PoliciesContext from "../../context/Policies/PoliciesContext";
import CardPolicies from "./CardPolicies";
import NoDataComponents from "../../components/loading/NoDataComponents";
const Policies = () => {
  const { GetPolicies, policies } = useContext(PoliciesContext);
  useEffect(() => {
    GetPolicies();
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        {/* <Grid
          item
          xs={12}
          sm={12}
          lg={12}
          md={12}
          xl={12}
          sx={{ backgroundColor: "black", color: "white" }}>
          <Typography variant="h4">Politicas de privacidad</Typography>
        </Grid> */}
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="end"
          sx={{ marginRight: 2 }}>
          <Link to="/AgregarPoliticas">
            <Button variant="contained" size="large">
              Agregar
            </Button>
          </Link>
        </Grid>
        <Grid container spacing={2} sx={{ margin: 2 }}>
          {!policies.length ? (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
              <NoDataComponents />
            </Grid>
          ) : (
            <>
              {policies.map((policie, index) => (
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={index}>
                  <CardPolicies policie={policie} />
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Policies;
