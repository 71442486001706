import React, { useState } from "react";
import formatDate from "../../../utils/FormatDate";
import { PriceFormat } from "../../../utils/FormatPrice";
import { makeStyles } from "@mui/styles";
import ModalImage from "react-modal-image-responsive";

import {
  Card,
  Box,
  Typography,
  Divider,
  Menu,
  MenuItem,
  Tooltip,
  IconButton,
  Grid,
  Button,
} from "@mui/material";
import { Payment } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  fechastyle: {
    textAlign: "center",
  },
}));

export default function WarehouseTickets({ payments }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialogDetailPayment, setOpenDialogDetailPayment] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpenDialogPayment = () => {
    setOpenDialogDetailPayment(true);
  };

  const handleCloseDialogPayment = () => {
    setOpenDialogDetailPayment(false);
  };

  return (
    <Card sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <Typography fontWeight="bold">Fecha de pago: </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          {formatDate(payments.createdAt)}
        </Grid>
        <Divider />
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <Typography fontWeight="bold">Monto: </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          $ {PriceFormat(payments.amount)}
        </Grid>
        <Divider />
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <Typography fontWeight="bold">Método de pago: </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          {payments.type === 1
            ? "Tarjeta(STRIPE)"
            : payments.type === 2 && "Transferencia electronica de fondos"}
        </Grid>
        <Divider />
        {payments.bank_account_id && (
          <>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography fontWeight="bold">Banco:</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              {payments.bank_account_id.bank.name}
            </Grid>
          </>
        )}
        <Divider />
        {payments.reference && (
          <>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography fontWeight="bold">Referencia:</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              {payments.reference}
            </Grid>
          </>
        )}
        {payments.image && (
          <>
            <Divider />
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography fontWeight="bold">Comprobante:</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <ModalImage
                large={payments.image}
                small={payments.image}
                alt={"comprobante"}
              />
            </Grid>
            <Divider />
          </>
        )}
      </Grid>
    </Card>
  );
}
