import {
  GET_ALL_SHIPPING_ACEPTED,
  GET_ALL_SHIPPING_PENDING,
  GET_ALL_SHIPPING_SHIPPED,
  WAREHOUSE_SHIPPING_ACCEPT_ORDER,
  WAREHOUSE_SHIPPING_SHIPPED_ORDER,
  PDF_ORDER,
  UPDATE_SHIPPING,
  GET_ALL_SHIPPING_DISPUTED,
  REMOVE_FROM_DISPUTE,
  MAKE_AS_DISPUTE,
} from "../../types";

export default function WarehouseReducer(state, action) {
  switch (action.type) {
    case GET_ALL_SHIPPING_PENDING:
      return {
        ...state,
        shippingsPending: action.payload.shippingsPending,
        totalDocs: action.payload.totalDocs,
        limit: action.payload.limit,
        page: action.payload.page,
        paginCounter: action.payload.paginCounter,
        hasPrevPage: action.payload.hasPrevPage,
        hasNextPage: action.payload.hasNextPage,
        prevPage: action.payload.prevPage,
        nextPage: action.payload.nextPage,
        totalPages: action.payload.totalPages,
        success: false,
        ErrorsApi: [],
      };
    case GET_ALL_SHIPPING_ACEPTED:
      return {
        ...state,
        shippingsAccepted: action.payload.shippingsAccepted,
        totalDocs: action.payload.totalDocs,
        limit: action.payload.limit,
        page: action.payload.page,
        paginCounter: action.payload.paginCounter,
        hasPrevPage: action.payload.hasPrevPage,
        hasNextPage: action.payload.hasNextPage,
        prevPage: action.payload.prevPage,
        nextPage: action.payload.nextPage,
        totalPages: action.payload.totalPages,
        success: false,
        ErrorsApi: [],
      };
    case GET_ALL_SHIPPING_SHIPPED:
      return {
        ...state,
        shippingsShipped: action.payload.shippingsShipped,
        totalDocs: action.payload.totalDocs,
        limit: action.payload.limit,
        page: action.payload.page,
        paginCounter: action.payload.paginCounter,
        hasPrevPage: action.payload.hasPrevPage,
        hasNextPage: action.payload.hasNextPage,
        prevPage: action.payload.prevPage,
        nextPage: action.payload.nextPage,
        totalPages: action.payload.totalPages,
        success: false,
        ErrorsApi: [],
      };
    case GET_ALL_SHIPPING_DISPUTED:
      return {
        ...state,
        shippingsDisputed: action.payload.shippingsDisputed,
        totalDocs: action.payload.totalDocs,
        limit: action.payload.limit,
        page: action.payload.page,
        paginCounter: action.payload.paginCounter,
        hasPrevPage: action.payload.hasPrevPage,
        hasNextPage: action.payload.hasNextPage,
        prevPage: action.payload.prevPage,
        nextPage: action.payload.nextPage,
        totalPages: action.payload.totalPages,
        success: false,
        ErrorsApi: [],
      };
    case WAREHOUSE_SHIPPING_ACCEPT_ORDER:
      return {
        ...state,
        success: true,
        ErrorsTrue: [],
      };
    case WAREHOUSE_SHIPPING_SHIPPED_ORDER:
      return {
        ...state,
        success: true,
        ErrorsApi: [],
      };
    case PDF_ORDER:
      return {
        ...state,
        success: true,
        ErrorsApi: [],
      };
    case UPDATE_SHIPPING:
      return {
        ...state,
        success: true,
        shippingsShipped: state.shippingsShipped?.map((shipp) =>
          shipp._id === action.payload._id ? { ...action.payload } : shipp
        ),
        ErrorsApi: [],
      };
    case REMOVE_FROM_DISPUTE:
      return {
        ...state,
        success: true,
        shippingsDisputed: state.shippingsDisputed.filter(
          (ship) => ship._id !== action.payload
        ),
        ErrorsApi: [],
      };
    case MAKE_AS_DISPUTE:
      return {
        ...state,
        success: true,
        shippingsAccepted: state.shippingsAccepted.filter(
          (shipp) => shipp._id !== action.payload
        ),
        ErrorsApi: [],
      };
    default:
      return state;
  }
}
