import React, { useEffect, useContext } from "react";
import SubcategoriesContext from "../../context/SubCategories/SubcategoriesContext";

import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";

export default function SelectSubcategories(props) {
  const { GetSubcategoriesNoPaginate, subcategories_no_paginate, success } =
    useContext(SubcategoriesContext);

  useEffect(() => {
    GetSubcategoriesNoPaginate();
  }, [success]);

  const detectarCambiosSubcategory = (e) => {
    props.detectarCambiosSubcategory(e);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Subcategorias</InputLabel>
          {props.SubcategoryId ? (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Subcategorias"
              onChange={detectarCambiosSubcategory}
              value={props.SubcategoryId}
            >
              {subcategories_no_paginate.map((subcategory) => (
                <MenuItem key={subcategory._id} value={subcategory._id}>
                  {subcategory.name}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Subcategorias"
              onChange={detectarCambiosSubcategory}
            >
              {subcategories_no_paginate.map((subcategory) => (
                <MenuItem key={subcategory._id} value={subcategory._id}>
                  {subcategory.name}
                </MenuItem>
              ))}
            </Select>
          )}
          <FormHelperText>Compo Requerido*</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}
