import React, { useContext, useState } from "react";
import WarehouseCanvasContext from "../../../context/WarehouseCanvas/WarehouseCanvasContext";
import formatDate from "../../../utils/FormatDate";
import ModalCanvasShipped from "../../../containers/WarehouseCanvas/ModalCanvasShipped";
import Marquee from "react-fast-marquee";
import { makeStyles } from "@mui/styles";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SendIcon from "@mui/icons-material/Send";
import {
  Box,
  Card,
  Typography,
  Divider,
  Tooltip,
  Menu,
  MenuItem,
  IconButton,
  Button,
  Grid,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  PhotoPerfil: {
    width: "120px",
    height: "120px",
    objectFit: "cover",
    padding: "2px",
    borderRadius: "50%",
    margin: "auto",
    marginTop: 10,
    marginBottom: 3,
    boxShadow: "-8px 10px 19px -6px rgba(110,110,110,0.97)",
    textAlign: "center",
    lineHeight: "110px",
  },
});

const AcceptedCanvasCard = ({ shipping }) => {
  /**Modal */
  const [modalShipped, AbrirModalShipped] = useState(false);

  const handleClickOpenShipped = () => {
    AbrirModalShipped(true);
  };
  const handleClickCloseShipped = () => {
    AbrirModalShipped(false);
  };

  const { ShippOrderCanvas, SaveCurrentOderCanvas, PdfOrderCanvas } =
    useContext(WarehouseCanvasContext);
  //3 puntitos
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();
  const [id_order, setIdOrder] = useState({});

  const SelectOrderCanvas = (shipping) => {
    setIdOrder(shipping);
    handleClickOpenShipped();
  };

  const SelectShowOrder = (shipping) => {
    SaveCurrentOderCanvas(shipping);
  };
  return (
    <Card sx={{ boxShadow: 3 }}>
      <Box
        fullWidth
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          paddingX: 2,
          backgroundColor: "white",
          color: "black",
        }}
      >
        <Marquee play={false} gradient={false} speed={45}>
          <Typography
            variant="su arrowbtitle2"
            noWrap
            sx={{ fontWeight: "bold", fontSize: "17px" }}
            className="blink-1"
          >
            N° Pedido Aceptado: {shipping.order_id.folio}
          </Typography>
        </Marquee>
      </Box>
      <Box
        fullWidth
        display="flex"
        alignItems="baseline"
        justifyContent="center"
        sx={{ paddingX: 2 }}
      >
        <div>
          <Typography
            variant="su arrowbtitle2"
            noWrap
            sx={{ fontWeight: "bold", fontSize: "17px", marginRight: 1 }}
          >
            Fecha de compra:
          </Typography>
        </div>
        <div>
          <Typography sx={{ fontSize: "14px" }}>
            {shipping.order_id.dateFormatted ?? shipping.order_id.createdAt}
          </Typography>
        </div>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "start",
          justifyContent: "space-between",
          paddingX: 2,
          marginBottom: 1,
        }}
      >
        <div>
          <Typography sx={{ fontWeight: "bold", fontSize: "17px" }}>
            Empaqueto Pedido:
          </Typography>
        </div>
        <div>
          <Typography sx={{ fontSize: "14px" }}>
            {shipping.user_id.fullname}
          </Typography>
        </div>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          alignItems: "start",
          justifyContent: "space-between",
          paddingX: 2,
          marginBottom: 1,
        }}
      >
        <div>
          <Typography sx={{ fontWeight: "bold", fontSize: "17px" }}>
            Cliente:
          </Typography>
        </div>
        <div>
          <Typography sx={{ fontSize: "14px" }}>
            {shipping.order_id.client_id.fullname}
          </Typography>
        </div>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "start",
          justifyContent: "space-between",
          paddingX: 2,
          marginBottom: 1,
        }}
      >
        <div>
          <Typography sx={{ fontWeight: "bold", fontSize: "17px" }}>
            Cantidad de productos:
          </Typography>
        </div>
        <div>
          <Typography sx={{ fontSize: "14px" }}>
            {shipping.order_id.products_list.length}
          </Typography>
        </div>
      </Box>

      <Divider />
      <Box
        sx={{
          paddingX: 2,
          marginBottom: 1,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <Link to={`/Detalle_venta/${shipping.order_id._id}`}>
              <Button
                variant="outlined"
                size="small"
                fullWidth
                style={{
                  backgroundColor: "#1769aa",
                  borderColor: "#1769aa",
                  color: "white",
                  marginTop: 5,
                  textTransform: "none",
                }}
                startIcon={<PictureAsPdfIcon />}
                // onClick={() => SelectShowOrder(shipping.order_id._id)}
              >
                Ver
              </Button>
            </Link>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <Button
              variant="outlined"
              fullWidth
              size="small"
              style={{
                backgroundColor: "#43a047",
                borderColor: "#43a047",
                color: "white",
                marginTop: 5,
                textTransform: "none",
              }}
              endIcon={<SendIcon />}
              onClick={() => SelectOrderCanvas(shipping._id)}
            >
              Enviar
            </Button>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <Tooltip title="Descargar PDF">
              <Button
                size="small"
                fullWidth
                variant="outlined"
                style={{
                  backgroundColor: "#d50000",
                  borderColor: "#d50000",
                  color: "white",
                  marginTop: 5,
                }}
                endIcon={<PictureAsPdfIcon />}
                onClick={() => PdfOrderCanvas(shipping.order_id._id)}
              >
                PDF
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
      <ModalCanvasShipped
        modal={modalShipped}
        id={id_order}
        handleClickOpen={handleClickOpenShipped}
        handleCloseShipped={handleClickCloseShipped}
      />
    </Card>
  );
};

export default AcceptedCanvasCard;
