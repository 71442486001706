import React from "react";
import ReactDOM from "react-dom";
import EcommerceApp from "./EcommerceAPP";
import "./tailwind.output.css";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

// import { Datadog } from "./config/Datadog";
// import { datadogRum } from "@datadog/browser-rum";
// datadogRum.init(Datadog);
// datadogRum.startSessionReplayRecording();

ReactDOM.render(<EcommerceApp />, document.getElementById("root"));
