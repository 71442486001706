import React, { useContext, useState } from "react";
import formatDate from "../../../utils/FormatDate";
import Marquee from "react-fast-marquee";

import {
  Box,
  Card,
  Typography,
  Tooltip,
  Menu,
  MenuItem,
  IconButton,
  Divider,
  Grid,
  Button,
  Alert,
} from "@mui/material";
import SalesContext from "../../../context/Sales/SalesContext";
import { Link } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import ArticleIcon from "@mui/icons-material/Article";
import { PriceFormat } from "../../../utils/FormatPrice";

const SalesCancelCard = ({ salesCancel }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [id_order, setIdOrder] = useState({});
  const { SaveCurrentSale, RestoreSale } = useContext(SalesContext);

  const SelectShowOrder = (salesCancel) => {
    SaveCurrentSale(salesCancel);
  };
  return (
    <Card
    // sx={
    //   !salesCancel.super_order
    //     ? { boxShadow: 10 }
    //     : { boxShadow: 10, border: "1px solid black" }
    // }
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          lg={4}
          xl={4}
          sx={{ backgroundColor: "#b71c1c", color: "white" }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              display="flex"
              justifyContent="center"
            >
              <Typography fontFamily="monospace">Venta cancelada</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div>
                <img src={require("../../../assets/gif/order_cancel.gif")} />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={8} lg={8} md={8} xl={8}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              md={12}
              xl={12}
              display="flex"
              justifyContent="center"
            >
              <Alert severity="success" icon={false}>
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  fontFamily="monospace"
                >
                  #Pedido: {salesCancel.folio}
                </Typography>
              </Alert>
            </Grid>
            <Grid item xs={12} sm={12} lg={12} md={12} xl={12}>
              <Typography variant="h6" fontWeight="bold" fontFamily="inherit">
                Fecha compra: {salesCancel.dateFormatted}
              </Typography>
            </Grid>
            <Grid item xs={11} sm={11} lg={11} md={11} xl={11}>
              <Alert icon={false} severity="info">
                <Typography variant="h6" fontWeight="bold" fontFamily="inherit">
                  Cliente: {salesCancel.client_id.fullname}
                </Typography>
              </Alert>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              display="flex"
              justifyContent="center"
              sx={{ marginBottom: 3 }}
            >
              <Link to={`/Detalle_venta/${salesCancel._id}`}>
                <Button
                  fullWidth
                  size="small"
                  variant="outlined"
                  style={{
                    backgroundColor: "#0d47a1",
                    borderColor: "#0d47a1",
                    color: "white",
                    textTransform: "none",
                  }}
                  startIcon={<ArticleIcon />}
                  onClick={() => SelectShowOrder(salesCancel._id)}
                >
                  Ver orden
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
    // <Card
    //   sx={
    //     !salesCancel.super_order
    //       ? { boxShadow: 3 }
    //       : { boxShadow: 3, border: "1px solid black" }
    //   }>
    //   <Box
    //     fullWidth
    //     display="flex"
    //     alignItems="center"
    //     justifyContent="space-between"
    //     sx={{
    //       paddingX: 2,
    //       backgroundColor: "white",
    //       color: "black",
    //     }}>
    //     <Marquee play={false} gradient={false} speed={45}>
    //       <Typography
    //         variant="su arrowbtitle2"
    //         noWrap
    //         sx={{ fontWeight: "bold", fontSize: "17px" }}
    //         className="blink-1">
    //         N° Orden: {salesCancel.folio}
    //       </Typography>
    //     </Marquee>
    //   </Box>
    //   <Divider />
    //   <Box
    //     sx={{
    //       display: "flex",
    //       alignItems: "center",
    //       justifyContent: "space-between",
    //       paddingX: 2,
    //       marginBottom: 1,
    //     }}>
    //     <div>
    //       <Typography sx={{ fontWeight: "bold", fontSize: "17px" }}>
    //         Fecha de compra:
    //       </Typography>
    //     </div>
    //     <div>
    //       <Typography sx={{ fontSize: "14px" }}>
    //         {salesCancel.dateFormatted}
    //       </Typography>
    //     </div>
    //   </Box>
    //   <Divider />
    //   <Box
    //     sx={{
    //       display: "flex",
    //       alignItems: "start",
    //       justifyContent: "space-between",
    //       paddingX: 2,
    //       marginBottom: 1,
    //     }}>
    //     <div>
    //       <Typography sx={{ fontWeight: "bold", fontSize: "17px" }}>
    //         Cliente:
    //       </Typography>
    //     </div>
    //     <div>
    //       <Typography sx={{ fontSize: "14px" }}>
    //         {salesCancel.client_id.fullname}
    //       </Typography>
    //     </div>
    //   </Box>
    //   {/* <Box
    //     sx={{
    //       display: "flex",
    //       alignItems: "start",
    //       justifyContent: "space-between",
    //       paddingX: 2,
    //       marginBottom: 1,
    //     }}
    //   >
    //     <div>
    //       <Typography sx={{ fontWeight: "bold", fontSize: "17px" }}>
    //         Cantidad de productos:
    //       </Typography>
    //     </div>
    //     <div>
    //       <Typography sx={{ fontSize: "14px" }}>
    //         {salesCancel.products_list.length}
    //       </Typography>
    //     </div>
    //   </Box> */}

    //   <Divider />
    //   <Box
    //     sx={{
    //       paddingX: 2,
    //       marginBottom: 2,
    //       marginTop: 2,
    //     }}>
    //     <Grid container spacing={2}>
    //       <Grid
    //         item
    //         xs={12}
    //         sm={12}
    //         md={12}
    //         lg={12}
    //         xl={12}
    //         display="flex"
    //         justifyContent="center">
    //         <Link to={`/Detalle_venta/${salesCancel._id}`}>
    //           <Button
    //             fullWidth
    //             size="small"
    //             variant="outlined"
    //             style={{
    //               backgroundColor: "#0d47a1",
    //               borderColor: "#0d47a1",
    //               color: "white",
    //               textTransform: "none",
    //             }}
    //             startIcon={<ArticleIcon />}
    //             onClick={() => SelectShowOrder(salesCancel._id)}>
    //             Ver orden
    //           </Button>
    //         </Link>
    //       </Grid>
    //     </Grid>
    //   </Box>
    // </Card>
  );
};

export default SalesCancelCard;
