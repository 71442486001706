import React, { useState, useContext, useEffect } from "react";
import PermissionCountriesContext from "../../context/PermissionsCountries/PermissionCountriesContext";
import { useForm } from "react-hook-form";
//Estilos , Icons y Alerta
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Box,
  TextField,
  Tooltip,
  InputLabel,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
} from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import { DropzoneDialog } from "material-ui-dropzone";

//
const useStyles = makeStyles({
  logoimagen: {
    height: "200px",
    objectFit: "cover",
  },
  input: {
    display: "none",
  },
  card: {
    marginTop: 7,
    marginLeft: 7,
    marginRight: 7,
  },
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 10,
  },
});

export default function Add(props) {
  const classes = useStyles();
  const { modal, handleClose } = props;
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState([]);
  const [files, setFiles] = useState("");

  const [image, setImage] = useState({
    urlPhoto:
      "https://cdn.pngsumo.com/index-of-areaedu-wp-content-uploads-2016-02-default-png-600_600.png",
    image: "",
  });

  const handleChangeImage = (e) => {
    setImage({
      ...image,
      urlPhoto: URL.createObjectURL(e.target.files[0]),
      image: e.target.files[0],
    });
  };

  const handleChangeFile = (e) => {
    setFile({
      ...file,
      file: e.target.files[0],
    });
  };

  const { success, AddCountries } = useContext(PermissionCountriesContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  useEffect(() => {
    if (success) {
      setValue("country", "", { shouldDirty: true });
      setValue("image", "", { shouldDirty: true });
      setImage({
        urlPhoto:
          "https://cdn.pngsumo.com/index-of-areaedu-wp-content-uploads-2016-02-default-png-600_600.png",
        image: "",
      });
    }
  }, [success]);

  const onSubmit = (data, e) => {
    data.image = image.image;
    //data.file = file;
    data.attachments = files;
    AddCountries(data);
    handleClose();
  };

  return (
    <Dialog open={modal} onClose={handleClose}>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#000000",
            textAling: "center",
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            borderRadius: "0 0 24px 0",
          }}>
          <div className={classes.titleCard}>Agrega permisos de paises</div>
          <Tooltip title="cerrar" arrow>
            <IconButton size="small" onClick={handleClose}>
              <CancelPresentationIcon
                className="button_danger"
                sx={{ fontSize: "30px" }}
              />
            </IconButton>
          </Tooltip>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Aquí, podras registrar marcas para nuevos productos
        </DialogContentText>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter")
              e.preventDefault();
          }}
          autoComplete="off">
          <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                  <div>
                    <img
                      src={image.urlPhoto}
                      className={classes.logoimagen}
                      alt="agrega marca"
                    />
                  </div>
                </Box>

                <input
                  className={classes.input}
                  id="icon-button-file"
                  type="file"
                  name="image"
                  accept="image/png, image/jpg, image/jpeg, image/webp"
                  onChange={handleChangeImage}
                />
                <InputLabel sx={{ textAlign: "center" }}>
                  Selecciona la bandera del país:
                  <label htmlFor="icon-button-file">
                    <IconButton aria-label="upload picture" component="span">
                      <Tooltip
                        title="seleccionar imagen"
                        aria-label="seleccionar imagen">
                        <PhotoCamera className="button_primary" />
                      </Tooltip>
                    </IconButton>
                  </label>
                </InputLabel>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  fullWidth
                  id="country"
                  placeholder="Ejemplo: Mexico"
                  name="country"
                  label="Nombre del pais"
                  variant="outlined"
                  error={errors.country ? true : false}
                  helperText={errors?.country?.message}
                  {...register("country", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box component="span">
                  <Tooltip title=" sube tus archivos">
                    <Button
                      fullWidth
                      variant="outlined"
                      //endIcon={<CloudUploadTwoToneIcon />}
                      className="button_fill_primary"
                      sx={{ height: "50px" }}
                      onClick={() => setOpen(true)}>
                      Agregar archivos
                    </Button>
                  </Tooltip>
                </Box>
                <DropzoneDialog
                  dialogTitle={"Sube los archivos con los permisos"}
                  //acceptedFiles={["file/pdf*"]}
                  filesLimit={5}
                  previewText="Visualizacion de archivos:"
                  maxFileSize={5000000}
                  dropzoneText={
                    "Arrastra o selecciona los archivos de los permisos"
                  }
                  cancelButtonText={"Cerrar"}
                  sub
                  submitButtonText={"Guardar Archivos"}
                  mitButtonText={"Agregar"}
                  open={open}
                  onClose={() => setOpen(false)}
                  onSave={(files) => {
                    setFiles(files);
                    setOpen(false);
                  }}
                  showFileNamesInPreview={false}
                  getFileAddedMessage={(filename) => {
                    return `Archivo(s) añadido(s) ${filename}`;
                  }}
                  getFileRemovedMessage={(filename) => {
                    return `Archivo decartado corrrectamente: ${filename}`;
                  }}
                  getFileLimitExceedMessage={(filename) => {
                    return `Lo siento esto accede el limite de 4 archivos`;
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    textAling: "center",
                  }}>
                  <Button
                    type="submit"
                    variant="outlined"
                    className="button_primary">
                    <SaveOutlinedIcon sx={{ mr: 1 }} />
                    Guardar
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}
