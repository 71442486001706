import * as React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import MethodGet from "../../config/Service";
import Swal from "sweetalert2";
import SpinnerComponent from "../../components/loading/SpinnerComponent";
import { useEffect } from "react";
import ModalImage from "react-modal-image-responsive";
import { makeStyles } from "@mui/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles({
  img: {
    width: 150,
    height: 150,
  },
  jss59: {
    width: "100%",
    border: "0px solid #bbb",
    height: "15%",
    outline: "none",
    padding: "15px 10px",
    marginLeft: "10px",
    borderRadius: "10px",
    textAlign: "center",
  },
});
export default function ModalProductsPending({
  open,
  handleClose,
  id_inventory,
  inventoried,
}) {
  const classes = useStyles();
  const [products, saveProducts] = useState([]);
  const [cargando, spinnerCargando] = useState(false);
  useEffect(() => {
    let url = `/inventory/rest/products/inventory/${id_inventory._id}`;
    MethodGet(url)
      .then((res) => {
        saveProducts(res.data.restOfProducts);
        spinnerCargando(true);
      })
      .catch((error) => {
        spinnerCargando(true);
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
      });
  }, [inventoried]);

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative", backgroundColor: "black" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Productos Pendientes de inventariar
            </Typography>
          </Toolbar>
        </AppBar>
        {!cargando ? (
          <SpinnerComponent />
        ) : (
          <TableContainer component={Paper} sx={{ padding: 2 }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Imagen</StyledTableCell>
                  <StyledTableCell align="center">
                    Código de barras
                  </StyledTableCell>
                  <StyledTableCell align="center">Nombre</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row" align="center">
                      <ModalImage
                        className={classes.img}
                        small={row.multimedia[0].path}
                        large={row.multimedia[0].path}
                        alt={row.name}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.barcode}
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.name}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Dialog>
    </div>
  );
}
