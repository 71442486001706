import React from "react";
import {
  ADD_NEW_INVENTORY,
  ADD_PRODUCT_INVENTORY,
  CURRENT_INVENTORY,
  DELETE_INVENTORY,
  END_INVENTORY,
  GET_ALL_INVENTORIES,
  GET_ALL_PRODUCTS_INVENTORIES,
  UPDATE_INVENTORY,
  UPDATE_STOCK_ERRORS,
  UPDATE_STOCK_PRODUCTS,
} from "../../types";

export default function InventoriesReducer(state, action) {
  switch (action.type) {
    case GET_ALL_INVENTORIES:
      return {
        ...state,
        inventories: action.payload,
        success: false,
        ErrorsAPI: [],
      };

    case GET_ALL_PRODUCTS_INVENTORIES:
      return {
        ...state,
        products: action.payload,
        succcess: false,
        ErrorsAPI: [],
      };
    case ADD_NEW_INVENTORY:
      return {
        ...state,
        success: true,
        ErrorsAPI: [],
      };
    case END_INVENTORY:
      return {
        ...state,
        succcess: true,
        ErrorsAPI: [],
      };
    case ADD_PRODUCT_INVENTORY:
      return {
        ...state,
        success: false,
        ErrorsAPI: [],
      };
    case CURRENT_INVENTORY:
      return {
        ...state,
        success: false,
        inventory: action.payload,
      };
    case UPDATE_INVENTORY:
      return {
        ...state,
        success: true,
        ErrorsAPI: [],
      };
    case DELETE_INVENTORY:
      return {
        ...state,
        inventories: state.inventories.filter(
          (inventory) => inventory.id !== action.payload
        ),
      };
    case UPDATE_STOCK_PRODUCTS:
      return {
        ...state,
        //products: action.payload,
        success: true,
        loading: false,
        ErrorsApi: [],
      };
    case UPDATE_STOCK_ERRORS:
      return {
        ...state,
        loading: false,
        success: true,
        ErrorsAPI: [],
      };
    default:
      return state;
  }
}
