import React, { useEffect } from "react";
import { Fragment } from "react";
import { useContext } from "react";
import StateContext from "../../context/States/StatesContext";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
const SelectState = (props) => {
  const { states, GetStates } = useContext(StateContext);
  useEffect(() => {
    GetStates();
  }, []);
  const detectarCambiosState = (e) => {
    props.detectarCambiosState(e.target.value);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Estados</InputLabel>
          {props.state_id ? (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Categorias"
              onChange={detectarCambiosState}
              value={props.state_id}>
              {states.map((state) => (
                <MenuItem key={state._id} value={state._id}>
                  {state.name}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Categorias"
              onChange={detectarCambiosState}>
              {states.map((state) => (
                <MenuItem key={state._id} value={state._id}>
                  {state.name}
                </MenuItem>
              ))}
            </Select>
          )}
          <FormHelperText>Campo Requerido*</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default SelectState;
