import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

export default function SelectTypeAccount(props) {
  const options = [
    { name: "Ecommerce", value: 1 },
    { name: "Caja Fisica", value: 2 },
  ];

  const detectarCambiosTypeAccount = (e) => {
    props.detectarCambiosTypeAccount(e);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <FormControl fullWidth>
          {props.TypeAccount ? (
            <>
              <InputLabel id="demo-simple-select-label">
                Selecciona el tipo de cuenta
              </InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Tipo de cuenta "
                defaultValue={props.TypeAccount}
                onChange={detectarCambiosTypeAccount}
              >
                {options.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </>
          ) : (
            <>
              <InputLabel id="demo-simple-select-label">
                Seleccionar Tipo de cuenta
              </InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Tipo de cuenta "
                onChange={detectarCambiosTypeAccount}
              >
                {options.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
          <FormHelperText>Campo Requerido*</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}
