import React, { useContext, useState } from "react";
import ExpensesContext from "../../../context/Expenses/ExpensesContext";
import ModalEdit from "../../../containers/expenses/ModalEdit";
import { EditIcon } from "../../icons/EditIcon";
import { DeleteIcon } from "../../icons/DeleteIcon";
import { makeStyles } from "@mui/styles";
import { Box, Card, Typography, Tooltip, Grid, Button } from "@mui/material";
import ResponsiveImgMaterialUi from "responsive-responsive-img-material-ui";
import { PriceFormat } from "../../../utils/FormatPrice";
const useStyles = makeStyles({
  Imagelogo: {
    height: "131px",
    objectFit: "cover",
    textAlign: "center",
  },
  img: {
    height: "250px",
    width: "100%",
  },
});
const ExpenseCard = ({ expense, permiss }) => {
  const { images } = expense;
  const classes = useStyles();
  const { DeleteExpenses } = useContext(ExpensesContext);
  const [modalUpdate, AbrirModalUpdate] = useState(false);
  const [id_expense, setIdExpense] = useState();
  const handleClickOpenUpdate = () => {
    AbrirModalUpdate(true);
  };
  const handleClickCloseUpdate = () => {
    sessionStorage.removeItem("expense");
    AbrirModalUpdate(false);
  };

  const selectExpense = (expense) => {
    setIdExpense(expense);
    handleClickOpenUpdate();
  };

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
      <Card sx={{ boxShadow: 3 }}>
        <Box>
          <img src={expense.image} className={classes.img} />
        </Box>
        <Box
          fullWidth
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            paddingX: 2,
            marginTop: 2,
            backgroundColor: "white",
            color: "black",
          }}
        >
          <Typography
            variant="su arrowbtitle2"
            noWrap
            sx={{ fontWeight: "bold", fontSize: "17px" }}
            className="blink-1"
          >
            {expense.name}
          </Typography>
        </Box>
        <Box
          fullWidth
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            paddingX: 2,
            marginTop: 2,
            backgroundColor: "white",
            color: "black",
          }}
        >
          <Typography
            variant="su arrowbtitle2"
            noWrap
            sx={{ fontWeight: "bold", fontSize: "17px" }}
            className="blink-1"
          >
            {expense.description}
          </Typography>
        </Box>
        <Box
          fullWidth
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            paddingX: 2,
            marginTop: 2,
            backgroundColor: "white",
            color: "black",
          }}
        >
          <Typography
            variant="su arrowbtitle2"
            noWrap
            sx={{ fontWeight: "bold", fontSize: "17px" }}
            className="blink-1"
          >
            $ {PriceFormat(expense.price)}
          </Typography>
        </Box>

        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          {permiss.update === true && (
            <Grid item xs={6} display="flex" justifyContent="center">
              <Tooltip title="Editar" placement="top">
                <Button onClick={() => selectExpense(expense._id)}>
                  <EditIcon />
                </Button>
              </Tooltip>
            </Grid>
          )}
          {permiss.delete === true && (
            <Grid item xs={6} display="flex" justifyContent="center">
              <Tooltip title="Eliminar" placement="top">
                <Button onClick={() => DeleteExpenses(expense._id)}>
                  <DeleteIcon />
                </Button>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Card>
      {id_expense && (
        <ModalEdit
          modalUpdate={modalUpdate}
          id={id_expense}
          handleClickOpen={handleClickOpenUpdate}
          handleCloseEdit={handleClickCloseUpdate}
        />
      )}
    </Grid>
  );
};

export default ExpenseCard;
