import React, { useContext, useEffect } from "react";
import Layout from "../../../components/layout/Layout";
import SliderContext from "../../../context/SliderEccomerce/SliderEcommerceContext";
import SliderCard from "../../../components/Cards/Slider/SliderCard";
import { Link } from "react-router-dom";
import NoDataComponents from "../../../components/loading/NoDataComponents";

import { makeStyles } from "@mui/styles";
import { Grid, Box, Button } from "@mui/material";
import ModulesContext from "../../../context/Permissions/PermissionsContext";

const useStyles = makeStyles({
  titleCard: {
    color: "white",
    fontSize: "35px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 2,
  },
});

export default function EcommerceSlider() {
  const classes = useStyles();
  const { sliders, GetSliders, success } = useContext(SliderContext);
  const { permissionsPerUser, GetModulesPerUser } = useContext(ModulesContext);

  useEffect(() => {
    if (localStorage.getItem("module_id")) {
      const permiss = localStorage.getItem("module_id");
      GetModulesPerUser(permiss);
    }
    GetSliders();
  }, [success]);

  return (
    <Layout>
      <Grid container spacing={2} sx={{ backgroundColor: "white", padding: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#000000",
              textAling: "center",
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
              // borderRadius: "0 0 24px 0",
            }}>
            <div className={classes.titleCard}>Sliders Ecommerce / Movil</div>
            {permissionsPerUser.write == true && (
              <div className={classes.fab}>
                <Link to="/agregar-EcommerceSlider">
                  <Button
                    sx={{
                      color: "black",
                      backgroundColor: "#26c6da",
                      hoverColor: "white",
                      fontFamily: "monospace",
                      fontWeight: "bolder",
                      fontSize: "15px",
                      textTransform: "none",
                      marginRight: 3,
                      "&:hover": {
                        color: "black",
                        backgroundColor: "#67cedb",
                        hoverColor: "white",
                        fontFamily: "monospace",
                        fontWeight: "bolder",
                        fontSize: "15px",
                      },
                    }}>
                    Agregar
                  </Button>
                </Link>
              </div>
            )}
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              paddingTop: 4,
              p: 3,
              backgroundColor: "white",
            }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={2}>
                  {!sliders.length ? (
                    <NoDataComponents />
                  ) : (
                    sliders.map((slider) => (
                      <SliderCard
                        permiss={permissionsPerUser}
                        slider={slider}
                        key={slider._id}
                      />
                    ))
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
}
