import React, { useEffect, useContext, useState } from "react";
import CouponsContext from "../../context/coupons/CouponsContext";

import MethodGet from "../../config/Service";
import { useForm } from "react-hook-form";

import { makeStyles } from "@mui/styles";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  TextField,
  Tooltip,
  Stack,
  Box,
} from "@mui/material";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

/**new pickers */
import esLocale from "date-fns/locale/es";
import { formatDateNow, formatDateSelect } from "../../utils/FormatDate";
import { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import Swal from "sweetalert2";
const useStyles = makeStyles({
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 10,
  },
});

export default function EditCoupon({ id_coupon, modal, handleClose }) {
  const classes = useStyles();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  const [couponData, setCouponData] = useState();
  const valueDesktopDatePicker = new Date();
  const [valueDesktopDatePickerSelected, setValueDesktopDatePickerSelected] =
    useState(new Date());
  const MINDATENOW = formatDateNow(valueDesktopDatePickerSelected);
  const [picker, Setpicker] = useState();
  const [fecha, setFecha] = useState("");

  const { UpdateCoupon } = useContext(CouponsContext);

  useEffect(() => {
    let url = `/coupons/${id_coupon}`;
    MethodGet(url)
      .then((res) => {
        setCouponData(res.data.coupon);
        Setpicker(res.data.coupon.expiration_date);
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          text: error.response.data.message,
          icon: "error",
        });
      });
  }, [id_coupon]);

  const onSubmit = (data, e) => {
    data.expiration_date = !fecha ? couponData.expiration_date : fecha;
    data.code = "WAPI" + data.code;
    data.id = id_coupon;
    UpdateCoupon(data);
    handleClose();
  };

  if (!couponData) {
    return <></>;
  }

  return (
    <Dialog fullWidth maxWidth="md" open={modal} onClose={handleClose}>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#000000",
            textAling: "center",
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            borderRadius: "0 0 24px 0",
          }}>
          <div className={classes.titleCard}>Editar Cupon</div>
          <div className={classes.fab}>
            <Tooltip title="cerrar" arrow>
              <Fab
                size="small"
                onClick={handleClose}
                sx={{
                  color: "#FFFFFF",
                  backgroundColor: "#FF0000",
                  "&:hover": {
                    color: "#FFFFFF",
                    backgroundColor: "#FF0000",
                  },
                }}>
                <CancelPresentationIcon />
              </Fab>
            </Tooltip>
          </div>
        </Box>
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter")
              e.preventDefault();
          }}
          autoComplete="off">
          <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <TextField
                  fullWidth
                  name="pre"
                  variant="outlined"
                  disabled
                  label="prefijo de codigo"
                  InputProps={{
                    readOnly: true,
                  }}
                  value="WAPI"
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <TextField
                  id="code"
                  fullWidth
                  placeholder="Ejemplo: Bienvenido"
                  defaultValue={couponData.code.split("WAPI")[1]}
                  name="code"
                  label="Codigo"
                  variant="outlined"
                  error={errors.code ? true : false}
                  helperText={errors?.code?.message}
                  {...register("code", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                    pattern: {
                      value: /^[A-Záéíóúñ ]*$/i,
                      message: "Error: No se permiten caracteres especiales",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <TextField
                  fullWidth
                  id="Discount"
                  defaultValue={couponData.discount}
                  name="discount"
                  label="Descuento"
                  variant="outlined"
                  type="number"
                  error={errors.discount ? true : false}
                  helperText={errors?.discount?.message}
                  {...register("discount", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                    min: {
                      value: 1,
                      message: "Minimo 1% de descuento",
                    },
                    max: {
                      value: 50,
                      message: "Maximo 50% de descuento",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  locale={esLocale}>
                  <Stack spacing={3}>
                    <MobileDatePicker
                      id="exp"
                      showTodayButton={true}
                      todayText={"Hoy"}
                      name="exp"
                      okText="Aceptar"
                      cancelText=""
                      label="Fecha de Experacion"
                      minDate={new Date(MINDATENOW)}
                      value={picker}
                      onChange={(newValue) => {
                        Setpicker(newValue);
                        setFecha(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={errors.exp ? true : false}
                          helperText={errors?.exp?.message}
                          {...register("exp", {
                            required: {
                              value: true,
                              message: "Este campo es requerido",
                            },
                          })}
                        />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    textAling: "center",
                  }}>
                  <div className={classes.fab}>
                    <Fab
                      type="submit"
                      variant="extended"
                      sx={{
                        backgroundColor: "#00B9FF",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#00DEED",
                          color: "white",
                        },
                      }}>
                      <SaveOutlinedIcon sx={{ mr: 1 }} />
                      Guardar
                    </Fab>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}
