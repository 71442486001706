import React, { useContext, useEffect, useRef, useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	InputLabel,
	Tooltip,
	Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import SubcategoriesContext from "../../context/SubCategories/SubcategoriesContext";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import "react-toastify/dist/ReactToastify.css";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles({
	buttonProgress: {
		color: "indigo",
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	container: {
		paddingTop: 4,
		paddingBottom: 4,
		paddingLeft: 10,
		//marginBottom: theme.spacing(10),
	},
	typography: {
		margin: 1,
		//paddingLeft: theme.spacing(30),
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: 9,
		paddingLeft: 2,
		paddingRight: 2,
		marginBlockEnd: 1,
	},
	submit: {
		background: "indigo",
		"&:hover": {
			background: "#5902CF",
		},
		color: "white",
	},
	formControl: {
		margin: 1,
		width: "100%",
	},
	selectEmpty: {
		marginTop: 2,
	},
	input: {
		display: "none",
	},
	containerImageProfile: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "space-around",
	},
	PaperFormAdd: {
		background: "white",
		color: "black",
		boxShadow: "2px 4px 4px #cacaca",
	},
});
export default function ModalUpdateSubcategories({
	modalUpdate,
	handleClickClose,
}) {
	const classes = useStyles();
	const { handleSubmit } = useForm({});
	const [archivo, guardarArchivo] = useState("");
	const [loading2, setLoading2] = useState(false);
	const timer = useRef();
	const leerArchivo = (e) => {
		guardarArchivo(e.target.files[0]);
	};

	const { success, ImportSubcategoriesExcel, loading } =
		useContext(SubcategoriesContext);

	useEffect(() => {
		return () => {
			clearTimeout(timer.current);
		};
	}, []);

	useEffect(() => {
		if (success) {
			handleClickClose();
			setLoading2(false);
		}
	}, [success]);

	const onSubmit = (data, e) => {
		e.preventDefault();
		setLoading2(true);
		data.file = archivo;
		if (archivo !== "") {
			ImportSubcategoriesExcel(data);
			handleClickClose();
		} else {
			toast.error("Introduce un archivo de excel válido", {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
				theme: "colored",
			});
			handleClickClose();
		}
		//UpdateProductsExcel(data);
	};

	return (
		<>
			<Dialog
				open={modalUpdate}
				onClose={handleClickClose}
				aria-labelledby="responsive-dialog-title"
			>
				<DialogTitle id="responsive-dialog-title">
					{"Actualizar subcategorias desde excel"}
				</DialogTitle>
				<form
					onSubmit={handleSubmit(onSubmit)}
					onKeyDown={(e) => {
						if (e.code === "Enter" || e.code === "NumpadEnter")
							e.preventDefault();
					}}
				>
					<DialogContent>
						<InputLabel sx={{ textAlign: "center" }}>
							Selecciona el archivo para actualizar las subcategorias
						</InputLabel>
						<input
							className={classes.input}
							id="icon-button-file"
							type="file"
							name="file"
							accept=".xlsx"
							onChange={leerArchivo}
						/>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<label htmlFor="icon-button-file">
								<IconButton
									color="primary"
									aria-label="upload picture"
									component="span"
									style={{ align: "center" }}
								>
									<Tooltip
										style={{ fontSize: "70px" }}
										title="seleccionar archivo excel a importar"
										aria-label="selecciona archivo"
									>
										<CloudUploadIcon />
									</Tooltip>
								</IconButton>
							</label>
						</Box>
					</DialogContent>

					<DialogActions
						sx={{
							display: "flex",
							alignItems: "baseline",
							justifyContent: "space-between",
						}}
					>
						<Button
							autoFocus
							onClick={handleClickClose}
							endIcon={<CloseIcon />}
							sx={{
								backgroundColor: "#b71c1c",
								color: "white",
								textTransform: "none",
								fontWeight: "bold",
								"&:hover": {
									backgroundColor: "#CF2121",
								},
							}}
						>
							Cancelar
						</Button>
						<Button
							type="submit"
							color="primary"
							disabled={loading}
							endIcon={<SaveIcon />}
							sx={{
								backgroundColor: "#1b5e20",
								color: "white",
								textTransform: "none",
								fontWeight: "bold",
								"&:hover": {
									backgroundColor: "#22992B",
								},
							}}
						>
							Importar
							{/* {loading2 ? "Importando" : "Guardar"} */}
							{/* {!loading2 && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )} */}
						</Button>
					</DialogActions>
				</form>
			</Dialog>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</>
	);
}
