import React, { useReducer } from "react";
import Swal from "sweetalert2";
import MethodGet, {
  MethodDelete,
  MethodPost,
  MethodPut,
} from "../../config/Service";
import {
  ADD_TAGS,
  CURRENT_TAG,
  DELETE_TAGS,
  GET_ALL_TAGS,
  SHOW_ERRORS_API,
  TAGS_NO_PAGINATE,
  UPDATE_TAGS,
} from "../../types";
import TagsReducer from "./TagsReducer";
import TagsContext from "./TagsContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const TagsState = ({ children }) => {
  //estado inicial
  const initialState = {
    tags: [],
    tags_no_paginate: [],
    tag_no_paginate: null,
    tag: null,
    ErrorsApi: [],
    success: false,
  };

  const [state, dispatch] = useReducer(TagsReducer, initialState);

  //obtener la lista de tags
  const GetTags = (params) => {
    sessionStorage.removeItem("tag");
    let url = `/tags?limit=${params.limite}&page=${params.pages}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_TAGS,
          payload: {
            tags: res.data.tags,
            totalDocs: res.data.totalDocs,
            limit: res.data.limit,
            page: res.data.page,
            paginCounter: res.data.paginCounter,
            hasPrevPage: res.data.hasPrevPage,
            hasNextPage: res.data.hasNextPage,
            prevPage: res.data.prevPage,
            nextPage: res.data.nextPage,
            totalPages: res.data.totalPages,
          },
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "Ocurrio un error al consultar la información",
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
      });
  };

  const GetTagsNoPaginate = () => {
    sessionStorage.removeItem("tag_no_paginate");
    let url = `/tags/without/pagination`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: TAGS_NO_PAGINATE,
          payload: res.data.tags,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "Ocurrio un error al consultar la información",
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
      });
  };

  //funcion para registrar nuevos tags
  const AddTags = (tag) => {
    const formData = new FormData();
    formData.append("name", tag.name);

    let url = `/tags/`;
    MethodPost(url, formData)
      .then((res) => {
        toast.success("Etiqueta creada correctamente!", {
          autoClose: 1500,
          hideProgressBar: false,
          newestOnTop: false,
          closeOnClick: true,
          rtl: false,
          pauseOnFocusLoss: false,
          draggable: false,
          pauseOnHover: false,
          theme: "colored",
        });
        // Swal.fire({
        // 	title: "Etiqueta registrada!",
        // 	text: "La etiqueta se ha registrado correcatamente.",
        // 	icon: "success",
        // 	timer: 2000,
        // 	showConfirmButton: false,
        // });
        dispatch({
          type: ADD_TAGS,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error,
          icon: "error",
          timer: 2500,
          showConfirmButton: false,
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.res.data.error,
        });
      });
  };

  //funcion para editar tag
  const UpdateTags = (tag) => {
    const formData = new FormData();
    formData.append("name", tag.name);

    let url = `/tags/${tag.id}`;
    MethodPut(url, formData)
      .then((res) => {
        Swal.fire({
          title: "Etiqueta Actualizada",
          text: res.data.message,
          timer: 2500,
          showConfirmButton: false,
          icon: "success",
        });
        dispatch({
          type: UPDATE_TAGS,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.res.data.error,
        });
      });
  };

  //eliminar tag
  const DeleteTags = (id) => {
    Swal.fire({
      title: "¿Estas Seguro?",
      text: "La Etiqueta seleccionada sera eliminada",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/tags/${id}`;

        MethodDelete(url)
          .then((res) => {
            Swal.fire({
              title: "Eliminado",
              text: res.data.message,
              icon: "success",
              timer: 2000,
              showConfirmButton: false,
            });
            dispatch({
              type: DELETE_TAGS,
              payload: id,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              timer: 2000,
              showConfirmButton: false,
            });
          });
      }
    });
  };

  //funcion para seleccionar una marca
  const SaveCurrentTag = (tag) => {
    sessionStorage.setItem("tag", JSON.stringify(tag));
    dispatch({
      type: CURRENT_TAG,
      payload: tag,
    });
  };
  return (
    <TagsContext.Provider
      value={{
        tags: state.tags,
        tag: state.tag,
        ErrorsApi: state.ErrorsApi,
        success: state.success,
        totalDocs: state.totalDocs,
        limit: state.limit,
        page: state.page,
        paginCounter: state.paginCounter,
        hasPrevPage: state.hasPrevPage,
        prevPage: state.prevPage,
        hasNextPage: state.hasNextPage,
        nextPage: state.nextPage,
        loading: state.loading,
        totalPages: state.totalPages,
        tags_no_paginate: state.tags_no_paginate,
        GetTags,
        AddTags,
        UpdateTags,
        DeleteTags,
        SaveCurrentTag,
        GetTagsNoPaginate,
      }}
    >
      {children}
    </TagsContext.Provider>
  );
};

export default TagsState;
