import React, { useReducer } from "react";
import Swal from "sweetalert2";
import MethodGet, {
  MethodDelete,
  MethodPost,
  MethodPut,
} from "../../config/Service";
import {
  ADD_COMMENTS_ORDER,
  ADD_EVIDENCE,
  ADD_PAYMENT,
  AUTHORIZE_SALE,
  CANCEL_SALE,
  COMPLETE_ORDER_EXCEL,
  CREATE_ORDER_FROM_EXCEL,
  CURRENT_SALE,
  DISABLED_UPDATE_SALE,
  DISABLE_UPDATE_SALE_PENDING_PAY,
  ENABLE_UPDATE_SALE,
  ENABLE_UPDATE_SALE_PENDING_PAY,
  GET_ALL_SALES,
  GET_ALL_SALES_PENDING_PAYMENT_WITHOUT_PAGINATION,
  RESTORE_SALE,
  SALES_CANCEL,
  SALES_PENDING_PAYMENT,
  SEARCH_SALES,
  SHOW_ERRORS_API,
  UPDATE_EVIDENCE,
  UPDATE_ORDER,
  UPDATE_SHIPPING_COST,
} from "../../types";

import SalesReducer from "./SalesReducer";
import SalesContext from "./SalesContext";
import headerConfig from "../../config/imageHeaders";
import { ADD_NEW_SHIPPMENT } from "./../../types/index";
import fileDownload from "js-file-download";

const SalesState = ({ children }) => {
  //estado incial
  const initialState = {
    salesPending: [],
    salePending: null,
    salesApprove: [],
    saleApprove: null,
    salesCancel: [],
    saleCancel: null,
    sales: [],
    sale: null,
    ErrorsApi: [],
    success: false,
    evidences: [],
    searchSales: [],
    statusOrder: null,
  };

  const [state, dispatch] = useReducer(SalesReducer, initialState);

  const GetSales = (params) => {
    sessionStorage.removeItem("sale");
    //params.pages += 1;
    let url = `/payments/approved/pay?limit=${params.limite}&page=${params.pages}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_SALES,
          payload: {
            sales: res.data.orders,
            totalDocs: res.data.totalDocs,
            limit: res.data.limit,
            page: res.data.page,
            paginCounter: res.data.paginCounter,
            hasPrevPage: res.data.hasPrevPage,
            hasNextPage: res.data.hasNextPage,
            prevPage: res.data.prevPage,
            nextPage: res.data.nextPage,
            totalPages: res.data.totalPages,
          },
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.message,
        });
      });
  };

  const GetSalesPendingPaymentWithoutPagination = () => {
    sessionStorage.removeItem("salePendingWithoutPagination");
    let url = "/payments/no-pagination/pending";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_SALES_PENDING_PAYMENT_WITHOUT_PAGINATION,
          payload: res.data.orders,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
      });
  };

  const GetSalesPendingPayment = (params) => {
    sessionStorage.removeItem("salePending");
    //params.pages += 1;
    let url = `/payments?limit=${params.limite}&page=${params.pages}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: SALES_PENDING_PAYMENT,
          payload: {
            salesPending: res.data.orders,
            totalDocs: res.data.totalDocs,
            limit: res.data.limit,
            page: res.data.page,
            paginCounter: res.data.paginCounter,
            hasPrevPage: res.data.hasPrevPage,
            hasNextPage: res.data.hasNextPage,
            prevPage: res.data.prevPage,
            nextPage: res.data.nextPage,
            totalPages: res.data.totalPages,
          },
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.message,
        });
      });
  };

  const GetSalesCancel = (params) => {
    sessionStorage.removeItem("saleCancel");
    //params.pages += 1;

    let url = `/payments/canceled/pay?limit=${params.limite}&page=${params.pages}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: SALES_CANCEL,
          payload: {
            salesCancel: res.data.orders,
            totalDocs: res.data.totalDocs,
            limit: res.data.limit,
            page: res.data.page,
            paginCounter: res.data.paginCounter,
            hasPrevPage: res.data.hasPrevPage,
            hasNextPage: res.data.hasNextPage,
            prevPage: res.data.prevPage,
            nextPage: res.data.nextPage,
            totalPages: res.data.totalPages,
          },
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.message,
        });
      });
  };

  const AuthorizeSale = (orders) => {
    Swal.fire({
      title: "Autorizar Venta",
      text: "Se autorizara la venta, y será enviada a almacen",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Aceptar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        const formData = new FormData();
        formData.append("orders", orders.orders);
        let url = `/orders/authorize/payments/`;
        MethodPost(url, orders)
          .then((res) => {
            Swal.fire({
              title: "Compras Aprobada",
              text: "Las compras seleccionadas han sido aprobadas, ahora se encuentran en almacen",
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            dispatch({
              type: AUTHORIZE_SALE,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              showConfirmButton: false,
              timer: 2000,
            });
          });
      }
    });
  };

  const requestImages = (product) => {
    Swal.fire({
      title: "Solicitar imagenes",
      text: "Se solicitaran de nuevo las imagenes al usuario",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Aceptar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        const formData = new FormData();
        formData.append("product_id", product.product);
        let url = `/orders/restore-canvas/${product.order_id}`;
        MethodPost(url, product)
          .then((res) => {
            Swal.fire({
              title: "Se ha realizado la petición",
              text: res.data.message,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              showConfirmButton: false,
              timer: 2000,
            });
          });
      }
    });
  };

  const RestoreSale = (id) => {
    Swal.fire({
      title: "Restaurar Venta",
      text: "La venta será restaurada, para que se pueda realizar el pago, correspondiente de la compra, para su posterior envio.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Aceptar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/payments/restore/${id}`;
        MethodPost(url)
          .then((res) => {
            Swal.fire({
              title: "Venta Restaurada",
              text: "La venta ha sido restaurada correctamente!",
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            dispatch({
              type: RESTORE_SALE,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              showConfirmButton: false,
              timer: 2000,
            });
          });
      }
    });
  };

  const CancelSale = (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "La venta seleccionada será cancelada",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Aceptar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/orders/cancel/${id}`;
        MethodDelete(url)
          .then((res) => {
            Swal.fire({
              title: "Venta Cancelada",
              text: "La venta se cancelo correctamente",
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            dispatch({
              type: CANCEL_SALE,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    });
  };

  const UpdateOrder = (data) => {
    Swal.fire({
      title: "Se actualizarán los productos de la orden",
      text: "¿Estas segura de continuar?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, continuar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/orders/edit/order/${data.id}`;
        MethodPut(url, data)
          .then((res) => {
            Swal.fire({
              title: "Actualizado",
              icon: "success",
              showConfirmButton: false,
              text: res.data.message,
              timer: 1700,
            });
            dispatch({
              type: UPDATE_ORDER,
            });
            data.props.history.goBack();
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              icon: "error",
              text: error.response.data.message,
              timer: 2000,
              showConfirmButton: false,
            });
          });
      }
    });
  };

  const AddFileEvidence = (data) => {
    const formData = new FormData();
    formData.append("image", data.image);
    let url = `/orders/upload-evidences/${data.order_id}`;

    MethodPost(url, formData, { headerConfig })
      .then((res) => {
        Swal.fire({
          title: "Agregado",
          text: res.data.message,
          showConfirmButton: false,
          timer: 1000,
          icon: "success",
        });
        dispatch({
          type: ADD_EVIDENCE,
          payload: res.data.evidences,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: SHOW_ERRORS_API,
        });
      });
  };

  const UpdateFileEvidence = (data) => {
    Swal.fire({
      title: "Se actualizarán las evidencias de la orden",
      text: "¿Estas seguro de continuar?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, continuar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        const formData = new FormData();
        if (data.imageFirst && data.imageFirstId) {
          formData.append("multimedia_first", data.imageFirst);
          formData.append("multimedia_first_id", data.imageFirstId);
        }
        if (data.imageSecond && data.imageSecondId) {
          formData.append("multimedia_second", data.imageSecond);
          formData.append("multimedia_second_id", data.imageSecondId);
        }
        if (data.imageThird && data.imageThirdId) {
          formData.append("multimedia_third", data.imageThird);
          formData.append("multimedia_third_id", data.imageThirdId);
        }

        let url = `/orders/update-evidences/${data.id}`;
        MethodPut(url, formData, { headerConfig })
          .then((res) => {
            Swal.fire({
              title: "Actualizado",
              text: res.data.message,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            dispatch({
              type: UPDATE_EVIDENCE,
              payload: res.data.evidences,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    });
  };

  const SaveCurrentSale = (salePending) => {
    sessionStorage.setItem("salePending", JSON.stringify(salePending));
    dispatch({
      type: CURRENT_SALE,
      payload: salePending,
    });
  };

  const BuscarVenta = (data) => {
    let url = `/search/salesGeneral?type=${data.type}&search=${data.search}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: SEARCH_SALES,
          payload: res.data.results,
        });
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  const AddCommentsOrder = (data) => {
    let url = ``;
    MethodPost(url)
      .then((res) => {
        dispatch({
          type: ADD_COMMENTS_ORDER,
          payload: res.data.order,
        });
        Swal.fire({
          title: "Guardado",
          text: res.data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
      });
  };
  //funcion para habiliyar la edicion de ventas en ventas ya pagadas
  const EnableUpdateSalePayment = (id) => {
    Swal.fire({
      title: "¿Habilitar para edición?",
      text: "La venta sera habilitada para editarse",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Aceptar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `orders/enable/order-edition/${id}`;
        MethodPost(url)
          .then((res) => {
            dispatch({
              type: ENABLE_UPDATE_SALE,
              payload: res.data.order,
            });
            Swal.fire({
              title: "Habilitada",
              text: res.data.message,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
    });
  };

  //funcion para deshabilitar la edicion de ventas en ventas ya pagadas
  const DisableUpdateSalePayment = (id) => {
    Swal.fire({
      title: "Deshabilitar edición?",
      text: "Se deshabilitara la edición de está venta",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Aceptar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `orders/disable/order-edition/${id}`;
        MethodPost(url)
          .then((res) => {
            dispatch({
              type: DISABLED_UPDATE_SALE,
              payload: res.data.order,
            });
            Swal.fire({
              title: "Deshabilitada",
              text: res.data.message,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
    });
  };
  //funcion para habiliyar la edicion de ventas en ventas pendientes de pago
  const EnableUpdateSalePendingPayment = (id) => {
    Swal.fire({
      title: "¿Habilitar para edición?",
      text: "La venta sera habilitada para editarse",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Aceptar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `orders/enable/order-edition/${id}`;
        MethodPost(url)
          .then((res) => {
            dispatch({
              type: ENABLE_UPDATE_SALE_PENDING_PAY,
              payload: res.data.order,
            });
            Swal.fire({
              title: "Habilitada",
              text: res.data.message,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
    });
  };

  //funcion para deshabilitar la edicion de ventas en ventas pendientes de pago
  const DisableUpdateSalePendingPayment = (id) => {
    Swal.fire({
      title: "Deshabilitar edición?",
      text: "Se deshabilitara la edición de está venta",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Aceptar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `orders/disable/order-edition/${id}`;
        MethodPost(url)
          .then((res) => {
            dispatch({
              type: DISABLE_UPDATE_SALE_PENDING_PAY,
              payload: res.data.order,
            });
            Swal.fire({
              title: "Deshabilitada",
              text: res.data.message,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
    });
  };

  const UpdateShippingCost = (data) => {
    Swal.fire({
      title: "Se actualizará el costo de envio?",
      text: "El costo de envio tendra el nuevo valor que le asignas",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, continuar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `orders/edit/order-shipping/${data.id}`;
        MethodPut(url, data)
          .then((res) => {
            dispatch({
              type: UPDATE_SHIPPING_COST,
              payload: res.data.order,
            });
            Swal.fire({
              title: "Actualizado",
              text: res.data.message,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
    });
  };

  const AddPayment = (data) => {
    console.log(data);
    Swal.fire({
      title: "Agregar",
      text: "Se agregara, el pago",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Aceptar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        const formData = new FormData();

        formData.append("image", data.image);
        formData.append("amount", data.amount);
        formData.append("type", data.type);
        if (data.type === 2) {
          formData.append("reference", data.reference);
          formData.append("bank_account", data.bank_account);
        }
        let url = `/payments/add/${data.id}`;
        MethodPost(url, formData, { headerConfig })
          .then((res) => {
            dispatch({
              type: ADD_PAYMENT,
              payload: res.data.order,
            });
            Swal.fire({
              title: "Agregado",
              text: res.data.message,
              timer: 1500,
              showConfirmButton: false,
              icon: "success",
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
            });
            console.log(error.response.data.message);
          });
      }
    });
  };
  /**nuevo envio */
  const AddNewShippment = (id) => {
    console.log(id);
    Swal.fire({
      title: "Deseas agregar una nueva guia?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Aceptar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/warehouse-shipping/new-ship/${id}`;
        MethodPost(url)
          .then((res) => {
            dispatch({
              type: ADD_NEW_SHIPPMENT,
              payload: res.data,
            });
            Swal.fire({
              title: "Listo",
              text: "Ya puedes agregar una nueva guia",
              timer: 1500,
              showConfirmButton: false,
              icon: "success",
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  const DownloadTemplateImportOrder = () => {
    let timerInterval;
    Swal.fire({
      title: "Generando",
      html: "Se esta generando la plantilla",
      timer: 2000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        const b = Swal.getHtmlContainer().querySelector("b");
        timerInterval = setInterval(() => {
          b.textContent = Swal.getTimerLeft();
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      let url = "/orders/DownloadTemplateImportOrder";
      MethodGet(url, { responseType: "blob" })
        .then((res) => {
          fileDownload(res.data, `importar_venta.xlsx`);
          Swal.fire({
            title: "Descargado",
            text: "Se desacargo la plantilla correctamente",
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Error",
            text: error.response.data.message,
            icon: "error",
            timer: 1500,
            showConfirmButton: false,
          });
        });
    });
  };

  // const CreateOrderFromExcel = (data) => {
  //   let url = "/orders/CreateOrderFromExcel";
  //   const formData = new FormData();
  //   formData.append("excel", data);
  //   MethodPost(url, formData, { headerConfig })
  //     .then((res) => {
  //       dispatch({
  //         type: CREATE_ORDER_FROM_EXCEL,
  //         payload: res.data,
  //       });
  //     })
  //     .catch((error) => {
  //       // console.log(error.response.data.message);
  //       Swal.mixin({
  //         toast: true,
  //         background: "#d32f2f",
  //         color: "white",
  //         iconColor: "white",
  //         position: "top-end",
  //         showConfirmButton: false,
  //         timer: 2000,
  //         timerProgressBar: true,
  //         didOpen: (toast) => {
  //           toast.addEventListener("mouseenter", Swal.stopTimer);
  //           toast.addEventListener("mouseleave", Swal.resumeTimer);
  //         },
  //       }).fire({
  //         icon: "error",
  //         title: `Error `,
  //         text: error.response.data.message,
  //       });
  //     });
  // };

  const completeOrderExcel = (data) => {
    let url = "/orders/CompleteImportOrder";
    MethodPost(url, data)
      .then((res) => {
        console.log(res.data, "la data");
        dispatch({
          type: COMPLETE_ORDER_EXCEL,
          payload: {
            salesPending: res.data.order,
            statusOrder: res.data.status,
          },
        });
        Swal.fire({
          title: "Venta completada",
          text: res.data.message,
          icon: "success",
          timer: 2500,
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const CancelOrdersAtm = () => {
    let url = "/payments/no-pagination/limit/pay";
    MethodGet(url)
      .then((res) => {
        console.log(res.data, "la respuesta");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <SalesContext.Provider
      value={{
        salesPending: state.salesPending,
        salesCancel: state.salesCancel,
        sales: state.sales,
        ErrorsApi: state.ErrorsApi,
        success: state.success,
        totalDocs: state.totalDocs,
        limit: state.limit,
        page: state.page,
        paginCounter: state.paginCounter,
        hasPrevPage: state.hasPrevPage,
        prevPage: state.prevPage,
        hasNextPage: state.hasNextPage,
        nextPage: state.nextPage,
        salesPendingWithoutPagination: state.salesPendingWithoutPagination,
        totalPages: state.totalPages,
        evidences: state.evidences,
        searchSales: state.searchSales,
        statusOrder: state.statusOrder,
        GetSalesPendingPayment,
        SaveCurrentSale,
        AuthorizeSale,
        GetSalesCancel,
        RestoreSale,
        AddCommentsOrder,
        GetSales,
        CancelSale,
        GetSalesPendingPaymentWithoutPagination,
        requestImages,
        UpdateOrder,
        AddFileEvidence,
        UpdateFileEvidence,
        BuscarVenta,
        EnableUpdateSalePayment,
        DisableUpdateSalePayment,
        EnableUpdateSalePendingPayment,
        DisableUpdateSalePendingPayment,
        AddPayment,
        UpdateShippingCost,
        AddNewShippment,
        DownloadTemplateImportOrder,
        completeOrderExcel,
        CancelOrdersAtm,
        // CreateOrderFromExcel,
      }}
    >
      {children}
    </SalesContext.Provider>
  );
};

export default SalesState;
