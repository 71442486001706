import React from "react";
import {
  ATTEND_MESSAGE,
  GET_ALL_MESSAGES,
  GET_MESSAGES_PER_USER,
  GET_UNREAD_MESSAGES,
  MAKE_AS_READ,
} from "../../types";

export default function ContactReducer(state, action) {
  switch (action.type) {
    case GET_ALL_MESSAGES:
      return {
        ...state,
        messages: action.payload.messages.docs,
        totalDocs: action.payload.messages.totalDocs,
        limit: action.payload.messages.limit,
        page: action.payload.messages.page,
        paginCounter: action.payload.messages.paginCounter,
        hasPrevPage: action.payload.messages.hasPrevPage,
        hasNextPage: action.payload.messages.hasNextPage,
        prevPage: action.payload.messages.prevPage,
        nextPage: action.payload.messages.nextPage,
        totalPages: action.payload.messages.totalPages,
        success: false,
        ErrorsAPI: [],
      };
    case GET_UNREAD_MESSAGES:
      return {
        ...state,
        messages: action.payload.messages,
        totalDocs: action.payload.totalDocs,
        limit: action.payload.limit,
        page: action.payload.page,
        paginCounter: action.payload.paginCounter,
        hasPrevPage: action.payload.hasPrevPage,
        hasNextPage: action.payload.hasNextPage,
        prevPage: action.payload.prevPage,
        nextPage: action.payload.nextPage,
        totalPages: action.payload.totalPages,
        success: false,
        ErrorsAPI: [],
      };
    case ATTEND_MESSAGE:
      return {
        ...state,
        messages: state.messages.filter(
          (message) => message._id !== action.payload._id
        ),
        success: true,
        ErrorsAPI: [],
      };
    case GET_MESSAGES_PER_USER:
      return {
        ...state,
        messages: action.payload.messages,
        totalDocs: action.payload.totalDocs,
        limit: action.payload.limit,
        page: action.payload.page,
        paginCounter: action.payload.paginCounter,
        hasPrevPage: action.payload.hasPrevPage,
        hasNextPage: action.payload.hasNextPage,
        prevPage: action.payload.prevPage,
        nextPage: action.payload.nextPage,
        totalPages: action.payload.totalPages,
        success: false,
        ErrorsAPI: [],
      };
    case MAKE_AS_READ:
      return {
        ...state,
        messages: state.messages.filter(
          (message) => message._id !== action.payload._id
        ),
        success: true,
        ErrorsAPI: [],
      };
    default:
      return state;
  }
}
