import React, { useReducer } from "react";
import Swal from "sweetalert2";
import MethodGet, {
  MethodDelete,
  MethodPost,
  MethodPut,
} from "../../config/Service";
import {
  ADD_ACCOUNTS,
  CURRENT_ACCOUNTS,
  DELETE_ACCOUNTS,
  GET_ACCOUNT_ECOMMERCE,
  GET_ACCOUNT_FISIC,
  GET_ALL_ACCOUNTS,
  SHOW_ERRORS_API,
  UPDATE_ACCOUNTS,
} from "../../types";
import AccountsContext from "./AccountsContext";
import AccountsReducer from "./AccountsReducer";

const AccountsState = ({ children }) => {
  //estado inicial
  const initialState = {
    accounts: [],
    accounts_eccomerce: [],
    accounts_fisic: [],
    account: null,
    ErrorsApi: [],
    success: false,
  };

  const [state, dispatch] = useReducer(AccountsReducer, initialState);
  //obtener la lista de cuentas bancarias
  const GetAccounts = () => {
    sessionStorage.removeItem("account");
    let url = "/bank-accounts";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_ACCOUNTS,
          payload: res.data.bankAccounts,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
      });
  };

  const GetAccountsEcommerce = () => {
    sessionStorage.removeItem("account");
    let url = "/bank-accounts/with/bank";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ACCOUNT_ECOMMERCE,
          payload: res.data.bankAccounts,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
      });
  };

  const GetAccountsFisic = () => {
    sessionStorage.removeItem("account");
    let url = "/bank-accounts/without/bank";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ACCOUNT_FISIC,
          payload: res.data.bankAccounts,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
      });
  };

  //registrar cuenta
  const AddAccounts = (account) => {
    const formData = new FormData();
    formData.append("account_number", account.account_number);
    formData.append("interbank", account.interbank);
    formData.append("beneficiary", account.beneficiary);
    if (account.typeAccount === 1) {
      formData.append("bank", account.bank);
    }
    formData.append("card_number", account.card_number);
    formData.append("typeAccount", account.typeAccount);
    let url = "/bank-accounts";
    MethodPost(url, formData)
      .then((res) => {
        Swal.fire({
          title: "Cuenta registrada",
          text: "La cuenta bancaria se registro exitosamente",
          icon: "success",
          timer: 1200,
          showConfirmButton: false,
        });
        dispatch({
          type: ADD_ACCOUNTS,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.message,
        });
      });
  };

  //fucnion para editar cuenta bancaria
  const UpdateAccount = (account) => {
    const formData = new FormData();
    formData.append("account_number", account.account_number);
    formData.append("interbank", account.interbank);
    formData.append("beneficiary", account.beneficiary);
    formData.append("bank", account.bank);
    formData.append("card_number", account.card_number);
    formData.append("typeAccount", account.typeAccount);

    let url = `/bank-accounts/${account.id}`;
    MethodPut(url, formData)
      .then((res) => {
        Swal.fire({
          title: "Cuenta Actualizada",
          text: res.data.message,
          timer: 1000,
          showConfirmButton: false,
          icon: "success",
        });
        dispatch({
          type: UPDATE_ACCOUNTS,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
      });
  };

  //eliminar cuenta
  const DeleteAccounts = (id) => {
    Swal.fire({
      title: "¿Estas Seguro?",
      text: "La cuenta bancaria seleccionada será eliminada",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/bank-accounts/${id}`;

        MethodDelete(url)
          .then((res) => {
            Swal.fire({
              title: "Eliminado",
              text: res.data.message,
              icon: "success",
              timer: 2000,
              showConfirmButton: false,
            });
            dispatch({
              type: DELETE_ACCOUNTS,
              payload: id,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              timer: 2500,
              showConfirmButton: false,
            });
          });
      }
    });
  };

  //funcion
  const SaveCurrentAccount = (account) => {
    sessionStorage.setItem("account", JSON.stringify(account));
    dispatch({
      type: CURRENT_ACCOUNTS,
      payload: account,
    });
  };

  return (
    <AccountsContext.Provider
      value={{
        accounts: state.accounts,
        accounts_eccomerce: state.accounts_eccomerce,
        accounts_fisic: state.accounts_fisic,
        account: state.account,
        ErrorsApi: state.ErrorsApi,
        success: state.success,
        GetAccounts,
        AddAccounts,
        UpdateAccount,
        DeleteAccounts,
        SaveCurrentAccount,
        GetAccountsEcommerce,
        GetAccountsFisic,
      }}>
      {children}
    </AccountsContext.Provider>
  );
};

export default AccountsState;
