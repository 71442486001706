import React, { useReducer } from "react";
import Swal from "sweetalert2";
import MethodGet, {
  MethodDelete,
  MethodPost,
  MethodPut,
} from "../../config/Service";
import {
  ADD_FAQ_CATEGORIES,
  CURRENT_FAQ_CATEGORY,
  DELETE_FAQ_CATEGORIES,
  GET_ALL_FAQ_CATEGORIES,
  SHOW_ERRORS_API,
  UPDATE_FAQ_CATEGORIES,
} from "../../types";
import FaqCategoriesReducer from "./FaqCategoriesReducer";
import FaqCategoriesContext from "./FaqCategoriesContext";

const FaqCategoriesState = ({ children }) => {
  //estado inicial
  const initialState = {
    faq_categories: [],
    faq_category: null,
    ErrorsApi: [],
    false: false,
  };

  //dispatch que ejecuta las acciones
  const [state, dispatch] = useReducer(FaqCategoriesReducer, initialState);

  //obtener la lista de faqs de categorues
  const GetFaqs = () => {
    sessionStorage.removeItem("faq-category");
    let url = `/faq-category`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_FAQ_CATEGORIES,
          payload: res.data.faqCategory,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
      });
  };

  //funcion para agregar nueva categoria faq
  const AddFaq = (faq) => {
    const formData = new FormData();
    formData.append("name", faq.name);

    let url = `/faq-category/`;
    MethodPost(url, formData)
      .then((res) => {
        Swal.fire({
          title: "Categoria de preguntas Agregada",
          text: "La categoria se ha agregado correctamente",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        dispatch({
          type: ADD_FAQ_CATEGORIES,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.res.data.error,
          icon: "error",
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.res.data.error,
        });
      });
  };

  //funcion para eidtar los faqs
  const UpdateFaq = (faq) => {
    const formData = new FormData();
    formData.append("name", faq.name);

    let url = `/faq-category/${faq.id}`;
    MethodPut(url, formData)
      .then((res) => {
        Swal.fire({
          title: "Categoria Actualizada",
          text: res.data.message,
          timer: 1000,
          showConfirmButton: false,
          icon: "success",
        });
        dispatch({
          type: UPDATE_FAQ_CATEGORIES,
        });
      })
      .catch((error) => {
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.res.data.error,
        });
      });
  };

  //eliminar faq category
  const DeleteFaq = (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "La categoria de preguntas seleccionada será eliminada",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/faq-category/${id}`;
        MethodDelete(url).then((res) => {
          Swal.fire({
            title: "Eliminado",
            text: res.data.message,
            icon: "success",
            timer: 2000,
            showConfirmButton: false,
          });
          dispatch({
            type: DELETE_FAQ_CATEGORIES,
            payload: id,
          });
        });
      }
    });
  };

  //funcion para seleccionar un faq
  const SaveCurrentFaq = (faq_category) => {
    sessionStorage.setItem("faq-category", JSON.stringify(faq_category));
    dispatch({
      type: CURRENT_FAQ_CATEGORY,
      payload: faq_category,
    });
  };

  return (
    <FaqCategoriesContext.Provider
      value={{
        faq_categories: state.faq_categories,
        faqcategory: state.faqcategory,
        ErrorsApi: state.ErrorsApi,
        success: state.success,
        GetFaqs,
        AddFaq,
        UpdateFaq,
        DeleteFaq,
        SaveCurrentFaq,
      }}>
      {children}
    </FaqCategoriesContext.Provider>
  );
};

export default FaqCategoriesState;
