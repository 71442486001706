import React, { useEffect } from "react";
import { useContext } from "react";

import ProvidersContext from "./../../context/Provider/ProviderContext";
import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
} from "@mui/material";
const SelectProviders = (props) => {
  const { listProviders, GetProviders } = useContext(ProvidersContext);
  useEffect(() => {
    GetProviders();
  }, []);
  const detectarCambiosProvider = (e) => {
    props.detectarCambiosProvider(e.target.value);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <FormControl fullWidth>
          {/* <InputLabel id="demo-simple-select-label">Proveedores</InputLabel> */}
          {props.provider_id ? (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label=""
              onChange={detectarCambiosProvider}
              value={props.provider_id}>
              {listProviders.map((provider) => (
                <MenuItem key={provider._id} value={provider._id}>
                  {provider.name} - {provider.company}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label=""
              onChange={detectarCambiosProvider}>
              {listProviders.map((provider) => (
                <MenuItem key={provider._id} value={provider._id}>
                  {provider.name} - {provider.company}
                </MenuItem>
              ))}
            </Select>
          )}
          <FormHelperText>Campo Requerido*</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default SelectProviders;
