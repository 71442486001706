import React, { useContext, useEffect } from "react";
import WarehouseContext from "../../context/Warehouse/WarehouseContext";
import NoDataComponents from "../../components/loading/NoDataComponents";
import DisputedCard from "../../components/Cards/Warehouse/DisputedCard";
import { Box, Grid } from "@mui/material";
import ModulesContext from "../../context/Permissions/PermissionsContext";
import { useState } from "react";
import { Paginate } from "../../components/Pagination/Paginate";
const Disputed = () => {
  /**paginacion */
  const [pages, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };
  const limite = rowsPerPage;
  const params = { limite, pages };
  const { GetWarehouseDisputed, shippingsDisputed, totalPages } =
    useContext(WarehouseContext);
  useEffect(() => {
    GetWarehouseDisputed(params);
  }, [rowsPerPage, pages]);
  const { permissionsPerUser, GetModulesPerUser, success } =
    useContext(ModulesContext);

  useEffect(() => {
    if (localStorage.getItem("module_id")) {
      const permiss = localStorage.getItem("module_id");
      GetModulesPerUser(permiss);
      // GetTypeUsers();
    }
  }, [success]);
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Box sx={{ width: "100%", display: "flex" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container spacing={2}>
                {!shippingsDisputed.length ? (
                  <NoDataComponents />
                ) : (
                  shippingsDisputed.map((shipping, index) => (
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} key={index}>
                      <DisputedCard
                        shipping={shipping}
                        permiss={permissionsPerUser}
                      />
                    </Grid>
                  ))
                )}
              </Grid>
            </Grid>
            <Box
              sx={{ marginTop: 4, justifyContent: "center", display: "flex" }}
            >
              {!shippingsDisputed.length ? (
                <Box></Box>
              ) : (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  display="flex"
                  justifyContent="center"
                >
                  <Paginate
                    totalPages={totalPages}
                    pages={pages}
                    handleChangePage={handleChangePage}
                  />
                </Grid>
              )}
            </Box>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Disputed;
