import React, { useState, useContext } from "react";
import formatDate from "../../../utils/FormatDate";
import ModalImage from "react-modal-image-responsive";
import ModalShipped from "../../../containers/Warehouse/ModalShipped";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Card,
  Typography,
  Divider,
  Button,
  IconButton,
  Tooltip,
  Grid,
  Alert,
} from "@mui/material";
import { Link } from "react-router-dom";
import SalesContext from "../../../context/Sales/SalesContext";
import ModalUpdateShipping from "./ModalUpdateShipping";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import ArticleIcon from "@mui/icons-material/Article";
const useStyles = makeStyles({
  Imagelogo: {
    width: "50%",
    height: "140px",
    objectFit: "fill",
    margin: "auto",
    textAlign: "center",
    lineHeight: "110px",
  },
  more: {
    position: "absolute",
    right: 0,
    top: 0,
  },
});

const ShippedCard = ({ shipping, permiss }) => {
  //3 puntitos
  const classes = useStyles();
  const { SaveCurrentSale } = useContext(SalesContext);
  const SelectShowOrder = (shipping) => {
    SaveCurrentSale(shipping);
  };
  /**MODAL UPDATE SHIPPING */
  const [openUpdate, setOpenUpdate] = React.useState(false);
  const handleClickOpenUpdate = () => {
    setOpenUpdate(true);
  };
  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };
  /**Modal */
  const [modalShipped, AbrirModalShipped] = useState(false);

  const handleClickOpenShipped = () => {
    AbrirModalShipped(true);
  };
  const handleClickCloseShipped = () => {
    AbrirModalShipped(false);
  };
  const [id_order, setIdOrder] = useState({});

  const SelectOrder = (shipping) => {
    setIdOrder(shipping);
    handleClickOpenShipped();
  };
  return (
    <>
      <Card sx={{ boxShadow: 10 }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            display="flex"
            justifyContent="space-between"
            sx={{ backgroundColor: "#388e3c", color: "white" }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                display="flex"
                justifyContent="center"
              >
                <Typography
                  fontWeight="bold"
                  fontFamily="monospace"
                  textAlign="center"
                >
                  Pedido enviado
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                display="flex"
                justifyContent="center"
              >
                <div>
                  <img
                    src={require("../../../assets/gif/sending_package.gif")}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                lg={12}
                md={12}
                xl={12}
                display="flex"
                justifyContent="center"
              >
                <Alert severity="success" icon={false}>
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    fontFamily="monospace"
                  >
                    #Pedido: {shipping.order_id.folio}
                  </Typography>
                </Alert>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                lg={12}
                md={12}
                xl={12}
                display="flex"
                justifyContent="center"
              >
                <Typography variant="h6" fontWeight="bold">
                  Fecha compra:{""}
                  {formatDate(shipping.order_id.createdAt)}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                display="flex"
                justifyContent="center"
              >
                <Alert severity="error" icon={false}>
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    fontFamily="monospace"
                  >
                    Empaqueto: {shipping.user_id.fullname}
                  </Typography>
                </Alert>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                lg={12}
                md={12}
                xl={12}
                display="flex"
                justifyContent="center"
              >
                <Alert icon={false} severity="info">
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    fontFamily="monospace"
                  >
                    Cliente: {shipping?.order_id?.client_id?.fullname}
                  </Typography>
                </Alert>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                lg={12}
                md={12}
                xl={12}
                display="flex"
                justifyContent="center"
              >
                <Alert icon={false} severity="warning">
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    fontFamily="monospace"
                  >
                    N° Guia: {shipping.no_guide}
                  </Typography>
                </Alert>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                lg={12}
                md={12}
                xl={12}
                display="flex"
                justifyContent="center"
              >
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  fontFamily="monospace"
                >
                  Paqueteria: {shipping.shipment_id.name}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{
                  display: "flex",
                  alignItems: "baseline",
                  justifyContent: "space-between",
                }}
              >
                <Tooltip title="Detalle venta" placement="top">
                  <Link to={`/Detalle_venta/${shipping.order_id._id}`}>
                    <IconButton>
                      <ArticleIcon sx={{ color: "red" }} />
                    </IconButton>
                  </Link>
                </Tooltip>
                {permiss.update === true && (
                  <Tooltip title=" Editar Guia" placement="top">
                    <IconButton
                      onClick={() => {
                        SelectShowOrder(shipping?.order_id?._id);
                        handleClickOpenUpdate();
                      }}
                    >
                      <EditIcon sx={{ color: "blue" }} />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <ModalUpdateShipping
        open={openUpdate}
        handleClose={handleCloseUpdate}
        id={shipping?._id}
        shippment_id={shipping?.shipment_id?._id}
        no_guide={shipping?.no_guide}
        canvas={false}
      />{" "}
      <ModalShipped
        modal={modalShipped}
        id={id_order}
        handleClickOpen={handleClickOpenShipped}
        handleCloseShipped={handleClickCloseShipped}
      />
      {/* <Card sx={{ boxShadow: 3, position: "relative" }}>
        <ModalImage
          className={classes.Imagelogo}
          small={shipping.shipment_id.image}
          large={shipping.shipment_id.image}
          alt={shipping.shipment_id.name}
        />
        <Box
          display="flex"
          alignItems="baseline"
          justifyContent="space-between"
          sx={{
            paddingX: 2,
            backgroundColor: "white",
            color: "black",
          }}
        >
          <Typography
            variant="su arrowbtitle2"
            noWrap
            sx={{ fontWeight: "bold", fontSize: "17px" }}
            className="blink-1"
          >
            N° Orden:
          </Typography>{" "}
          <Typography
            variant="su arrowbtitle2"
            noWrap
            sx={{ fontWeight: "bold", fontSize: "17px" }}
            className="blink-1"
          >
            {shipping.order_id.folio}
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between",
            paddingX: 2,
            marginBottom: 1,
          }}
        >
          <div>
            <Typography sx={{ fontWeight: "bold", fontSize: "17px" }}>
              Fecha de compra:
            </Typography>
          </div>
          <div>
            <Typography sx={{ fontSize: "14px" }}>
              {formatDate(shipping.order_id.createdAt)}
            </Typography>
          </div>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between",
            paddingX: 2,
            marginBottom: 1,
          }}
        >
          <div>
            <Typography sx={{ fontWeight: "bold", fontSize: "17px" }}>
              Empaqueto Pedido:
            </Typography>
          </div>
          <div>
            <Typography sx={{ fontSize: "14px" }}>
              {shipping?.user_id?.fullname}
            </Typography>
          </div>
        </Box>

        <Divider />
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between",
            paddingX: 2,
            marginBottom: 1,
          }}
        >
          <div>
            <Typography sx={{ fontWeight: "bold", fontSize: "17px" }}>
              Cliente:
            </Typography>
          </div>
          <div>
            <Typography sx={{ fontSize: "14px" }}>
              {shipping?.order_id?.client_id?.fullname}
            </Typography>
          </div>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between",
            paddingX: 2,
            marginBottom: 1,
          }}
        >
          <div>
            <Typography sx={{ fontWeight: "bold", fontSize: "17px" }}>
              Cantidad de productos:
            </Typography>
          </div>
          <div>
            <Typography sx={{ fontSize: "14px" }}>
              {shipping.order_id.products_list.length}
            </Typography>
          </div>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingX: 2,
            marginBottom: 1,
          }}
        >
          <div>
            <Typography sx={{ fontWeight: "bold", fontSize: "17px" }}>
              Numero de guía:
            </Typography>
          </div>
          <div>
            <Typography sx={{ fontSize: "14px" }}>
              {shipping.no_guide}
            </Typography>
          </div>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between",
          }}
        >
          <Tooltip title="Detalle venta" placement="top">
            <Link to={`/Detalle_venta/${shipping.order_id._id}`}>
              <IconButton>
                <ArticleIcon sx={{ color: "red" }} />
              </IconButton>
            </Link>
          </Tooltip>
          {permiss.update === true && (
            <Tooltip title=" Editar Guia" placement="top">
              <IconButton
                onClick={() => {
                  SelectShowOrder(shipping?.order_id?._id);
                  handleClickOpenUpdate();
                }}
              >
                <EditIcon sx={{ color: "blue" }} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <Divider />
      </Card> */}
      {/* <ModalUpdateShipping
        open={openUpdate}
        handleClose={handleCloseUpdate}
        id={shipping?._id}
        shippment_id={shipping?.shipment_id?._id}
        no_guide={shipping?.no_guide}
        canvas={false}
      /> */}
      {/* <ModalShipped
        modal={modalShipped}
        id={id_order}
        handleClickOpen={handleClickOpenShipped}
        handleCloseShipped={handleClickCloseShipped}
      /> */}
    </>
  );
};

export default ShippedCard;
