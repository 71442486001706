import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import formatDate from "../../utils/FormatDate";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { IconButton, Tooltip } from "@mui/material";
import { PriceFormat } from "../../utils/FormatPrice";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function TablePayments({ payments, accountId }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Folio Transaccion</StyledTableCell>
            {/* <StyledTableCell align="center">Fecha</StyledTableCell> */}
            <StyledTableCell align="center">Monto del pago</StyledTableCell>
            <StyledTableCell align="center">Comprobante</StyledTableCell>
            {/* <StyledTableCell align="center">Opciones</StyledTableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {payments.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell align="center">
                {row.folio_trans}
              </StyledTableCell>
              {/* <StyledTableCell align="center">
                {formatDate(row.createdAt)}
              </StyledTableCell> */}
              <StyledTableCell align="center">
                $ {PriceFormat(row.amount)}
              </StyledTableCell>
              <StyledTableCell align="center">
                <Tooltip label="Descargar comprobante" placement="top">
                  <IconButton>
                    <a href={row.voucher} rel="noreferrer">
                      <PictureAsPdfIcon sx={{ color: "red" }} />
                    </a>
                  </IconButton>
                </Tooltip>
              </StyledTableCell>
              {/* <StyledTableCell align="center">
                <Link to={`/UpdatePayment/${accountId}/${row._id}`}>
                  <IconButton>
                    <EditIcon sx={{ color: "#0d47a1" }} />
                  </IconButton>
                </Link>
              </StyledTableCell> */}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
