import React, { useContext, useEffect, useState } from "react";
import ContactContext from "../../context/Contact/ContactContext";
import { Grid, IconButton, Stack } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FormatDate from "../../utils/FormatDate";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Paginate } from "../../components/Pagination/Paginate";
const MessagesUnread = () => {
  const { messages, GetUnreadMessages, attendMessage, totalPages } =
    useContext(ContactContext);
  //paginacion
  var [pages, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const limite = rowsPerPage;
  const params = { limite, pages };
  useEffect(() => {
    if (params.limite !== null && params.pages !== null) {
      GetUnreadMessages(params);
    }
  }, [rowsPerPage, pages]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>Nombre</TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="justify">
                  Mensaje
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="justify">
                  Remitente
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="justify">
                  Telefono
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="justify">
                  Fecha
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="justify">
                  Opción
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {messages.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="justify">{row.message}</TableCell>
                  <TableCell align="justify">{row.email}</TableCell>
                  <TableCell align="justify">{row.phone}</TableCell>
                  <TableCell align="justify">
                    {FormatDate(row.createdAt)}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => attendMessage(row._id)}>
                      <CheckCircleOutlineIcon sx={{ color: "green" }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Stack
            spacing={2}
            sx={{ marginBottom: 4, marginRight: 2, marginTop: 4 }}
          >
            <Paginate
              pages={pages}
              handleChangePage={handleChangePage}
              totalPages={totalPages}
            />
          </Stack>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default MessagesUnread;
