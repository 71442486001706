import { useReducer } from "react";
import Swal from "sweetalert2";
import MethodGet, {
  MethodPost,
  MethodPut,
  MethodDelete,
} from "../../config/Service";
import {
  GET_ALL_TYPE_CATEGORY,
  ADD_TYPE_CATEGORY,
  UPDATE_TYPE_CATEGORY,
  DELETE_TYPE_CATEGORY,
} from "../../types";
import TypeCategoriesContext from "./TypeCategoriesContext";
import TypeCategoriesReducer from "./TypeCategoriesReducer";

export default function TypeCategoriesState({ children }) {
  const INITIAL_STATE = {
    typecategories: [],
  };

  const [state, dispatch] = useReducer(TypeCategoriesReducer, INITIAL_STATE);

  const getAllTypeCategories = () => {
    let url = `/type-categories`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_TYPE_CATEGORY,
          payload: res.data.type_categories,
        });
      })
      .catch((error) => {});
  };
  const addTypeCategory = (name) => {
    const formData = new FormData();
    formData.append("name", name);

    let url = `/type-categories`;

    MethodPost(url, formData)
      .then((res) => {
        dispatch({
          type: ADD_TYPE_CATEGORY,
          payload: res.data.type_categories,
        });
      })
      .catch((error) => {});
  };
  const updateTypeCategory = (data) => {
    const formData = new FormData();
    formData.append("name", data.name);

    let url = `/type-categories/${data.id}`;
    MethodPut(url, formData)
      .then((res) => {
        dispatch({
          type: UPDATE_TYPE_CATEGORY,
          payload: res.data.type_category,
        });
      })
      .catch((error) => {});
  };

  const deleteTypeCategory = (id) => {
    let url = `/type-categories/${id}`;
    Swal.fire({
      title: "Eliminar",
      text: "EL tipo de categoria seleccionada, sera eliminada",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        MethodDelete(url)
          .then((res) => {
            Swal.fire({
              title: "Eliminado",
              text: res.data.message,
              icon: "success",
              showConfirmButton: false,
              timer: 1000,
            });
            dispatch({
              type: DELETE_TYPE_CATEGORY,
              payload: id,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              timer: 1500,
              showConfirmButton: false,
            });
          });
      }
    });
  };

  return (
    <TypeCategoriesContext.Provider
      value={{
        ...state,
        getAllTypeCategories,
        addTypeCategory,
        updateTypeCategory,
        deleteTypeCategory,
      }}
    >
      {children}
    </TypeCategoriesContext.Provider>
  );
}
