import React, { useReducer } from "react";
import MeasuresReducer from "./MeasuresReducer";
import MeasuresContext from "./MeasuresContext";
import MethodGet, {
  MethodDelete,
  MethodPost,
  MethodPut,
} from "../../config/Service";
import {
  ADD_MEASURE,
  CURRENT_MEASURE,
  DELETE_MEASURE,
  GET_ALL_MEASURES,
  SHOW_ERRORS_API,
  UPDATE_MEASURE,
} from "../../types";
import Swal from "sweetalert2";
import { Tag } from "@mui/icons-material";

const MeasuresState = ({ children }) => {
  //estado incial
  const initialState = {
    measures: [],
    measure: null,
    ErrorsApi: [],
    success: false,
  };
  const [state, dispatch] = useReducer(MeasuresReducer, initialState);

  //obtener la lista de los tags
  const GetMeasures = () => {
    sessionStorage.removeItem("measure");
    let url = `/measurements`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_MEASURES,
          payload: res.data.measurements,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
      });
  };

  //funcion para agregar ,medidas
  const AddMeasure = (measure) => {
    const formData = new FormData();
    formData.append("measurement", measure.measure);
    let url = `/measurements`;
    MethodPost(url, formData)
      .then((res) => {
        Swal.fire({
          title: "Medida Agregada",
          text: "La medida se guardo correctamente",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: ADD_MEASURE,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 2500,
          showConfirmButton: false,
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.message,
        });
      });
  };
  const UpdateMeasure = (measure) => {
    const formData = new FormData();
    formData.append("measurement", measure.measure);

    let url = `/measurements/${measure.id}`;
    MethodPut(url, formData)
      .then((res) => {
        Swal.fire({
          title: "Medida Actualizada",
          icon: "success",
          timer: 1500,
          text: res.data.message,
          showConfirmButton: false,
        });
        dispatch({
          type: UPDATE_MEASURE,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
          text: error.response.data.message,
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.message,
        });
      });
  };

  //eliminar medida
  const DeleteMeasure = (id) => {
    Swal.fire({
      title: "¿Estas Seguro?",
      text: "La medida seleccionada será eliminada",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/measurements/${id}`;
        MethodDelete(url)
          .then((res) => {
            Swal.fire({
              title: "Eliminado",
              text: res.data.message,
              icon: "success",
              timer: 2000,
              showConfirmButton: false,
            });
            dispatch({
              type: DELETE_MEASURE,
              payload: id,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "error",
              icon: "error",
              text: error.response.data.message,
              showConfirmButton: false,
              timer: 2500,
            });
          });
      }
    });
  };

  //funcion para seleccionar una marca
  const SaveCurrentMeasure = (measure) => {
    sessionStorage.setItem("measure", JSON.stringify(measure));
    dispatch({
      type: CURRENT_MEASURE,
      payload: Tag,
    });
  };

  return (
    <MeasuresContext.Provider
      value={{
        measures: state.measures,
        measure: state.measure,
        ErrorsApi: state.ErrorsApi,
        success: state.success,
        GetMeasures,
        AddMeasure,
        UpdateMeasure,
        DeleteMeasure,
        SaveCurrentMeasure,
      }}>
      {children}
    </MeasuresContext.Provider>
  );
};

export default MeasuresState;
