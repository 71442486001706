import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";

const SelectTypeExit = (props) => {
  const options = [
    { value: 1, name: "Salida Prestamo de productos" },
    { value: 2, name: "Salida WOS" },
    { value: 3, name: "Salida Productos por venta" },
  ];
  const detectarCambiosTypeExit = (e) => {
    props.detectarCambiosTypeExit(e);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <FormControl fullWidth>
          {props.selectType ? (
            <>
              <InputLabel id="demo-simple-select-label">
                Seleccionar tipo de salida
              </InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Tipo de salida "
                defaultValue={props.selectType}
                onChange={detectarCambiosTypeExit}
              >
                {options.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </>
          ) : (
            <>
              <InputLabel id="demo-simple-select-label">
                Seleccionar Tipo de salida
              </InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Tipo de salida "
                onChange={detectarCambiosTypeExit}
              >
                {options.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
          {/* <FormHelperText>Campo Requerido*</FormHelperText> */}
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default SelectTypeExit;
