import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { Chip, Divider, Grid, TextField } from "@mui/material";
import { useState } from "react";
import StateSelect from "../../SelectOptions/SelectState";
import MunicipalitySelect from "../../SelectOptions/SelectMunicipality";
import TaxSystemSelect from "../../SelectOptions/TaxSystemSelect";
import { useForm } from "react-hook-form";
import invoicesContext from "../../../context/Invoices/InvoicesContext";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ModalFiscalData({ open, handleClose, idClient }) {
  const { RegisterSatDirection } = React.useContext(invoicesContext);
  const [state, saveState] = useState(null);
  const [municipality, saveMunicipality] = useState(null);
  const [taxSystem, saveTaxSystem] = useState(null);

  const detectarCambiosState = (e) => {
    saveState(e);
  };
  const detectarCambiosMunicipality = (e) => {
    saveMunicipality(e);
  };
  const detectarCambiosTaxSystem = (e) => {
    saveTaxSystem(e);
  };
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const reset = () => {
    saveMunicipality(null);
    saveState(null);
    saveTaxSystem(null);
    setValue("legal_name", "", { shouldDirty: true });
    setValue("tax_id", "", { shouldDirty: true });
    setValue("city", "", { shouldDirty: true });
    setValue("email", "", { shouldDirty: true });
    setValue("exterior", "", { shouldDirty: true });
    setValue("neighborhood", "", { shouldDirty: true });
    setValue("phone", "", { shouldDirty: true });
    setValue("street", "", { shouldDirty: true });
    setValue("zip", "", { shouldDirty: true });
  };
  const onSubmit = (data, e) => {
    data.state_id = state;
    data.municipality_id = municipality;
    data.tax_system = taxSystem;
    data.idClient = idClient;

    RegisterSatDirection(data);
    // props.history.goBack();
    handleClose();
    reset();
  };
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}>
          Registrar datos fiscales del cliente
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <form
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter")
                e.preventDefault();
            }}
            autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  label="Nombre o razón social*"
                  type="text"
                  fullWidth
                  name="legal_name"
                  error={errors.legal_name ? true : false}
                  helperText={errors?.legal_name?.message}
                  {...register("legal_name", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  label="RFC*"
                  type="text"
                  fullWidth
                  name="tax_id"
                  error={errors.tax_id ? true : false}
                  helperText={errors?.tax_id?.message}
                  {...register("tax_id", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  label="Correo Electronico*"
                  fullWidth
                  type="email"
                  name="email"
                  error={errors.email ? true : false}
                  helperText={errors?.email?.message}
                  {...register("email", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  label="Número Telefonico*"
                  type="tel"
                  fullWidth
                  name="phone"
                  error={errors.phone ? true : false}
                  helperText={errors?.phone?.message}
                  {...register("phone", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TaxSystemSelect
                  detectarCambiosTaxSystem={detectarCambiosTaxSystem}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                display="flex"
                justifyContent="center">
                <Chip label="Direccion Fiscal" />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  label="Calle*"
                  type="text"
                  fullWidth
                  name="street"
                  error={errors.street ? true : false}
                  helperText={errors?.street?.message}
                  {...register("street", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  label="C.P*"
                  type="number"
                  fullWidth
                  name="zip"
                  error={errors.zip ? true : false}
                  helperText={errors?.zip?.message}
                  {...register("zip", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  label="N°. Ext*"
                  type="text"
                  fullWidth
                  name="exterior"
                  error={errors.exterior ? true : false}
                  helperText={errors?.exterior?.message}
                  {...register("exterior", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField label="N°. Int" type="text" fullWidth />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  label="Colonia*"
                  type="text"
                  fullWidth
                  name="neighborhood"
                  error={errors.neighborhood ? true : false}
                  helperText={errors?.neighborhood?.message}
                  {...register("neighborhood", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  label="Ciudad*"
                  type="text"
                  fullWidth
                  name="city"
                  error={errors.city ? true : false}
                  helperText={errors?.city?.message}
                  {...register("city", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <StateSelect detectarCambiosState={detectarCambiosState} />
              </Grid>
              {state !== null && (
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <MunicipalitySelect
                    detectarCambiosMunicipality={detectarCambiosMunicipality}
                    state_id={state}
                  />
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                display="flex"
                justifyContent="center">
                <Button variant="contained" size="large" type="submit">
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
