import { forwardRef, useState, useEffect } from "react";
import {
  Grid,
  Dialog,
  Slide,
  Typography,
  Toolbar,
  AppBar,
  IconButton,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import MethodGet from "../../config/Service";
import BySubCategory from "../../components/Cards/products/BySubCategory";
import NoDataComponents from "../../components/loading/NoDataComponents";
import NoResultsComponent from "../../components/loading/NoResultsComponent";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Products({ id_subcategory, open, close, title }) {
  const [productSubcategory, setProductSubcategory] = useState({});
  const [productSubcategoryFilter, setProductSubcategoryFilter] = useState();
  const [magicWord, setMagicWord] = useState("");

  const HandleFilterProduct = ({ target }) => {
    setMagicWord(target.value);
    const palabra = target.value;
    try {
      let resultsearch = productSubcategory.filter((p) =>
        p.name.toString().toLowerCase().includes(palabra.toLowerCase())
      );

      setProductSubcategoryFilter(resultsearch);
    } catch ({ error }) {
      Swal.fire({
        title: "error",
        text: error.response.data.message,
        icon: "error",
      });
    }
  };

  useEffect(() => {
    let url = `/products/subcategory/${id_subcategory}`;
    MethodGet(url)
      .then(({ data }) => {
        setProductSubcategory(data.products);
      })
      .catch(({ message }) => {
        Swal.fire({
          title: "Error",
          text: `${message}`,
          timer: 2000,
          icon: "error",
          showConfirmButton: false,
        });
      });
  }, [id_subcategory]);

  return (
    <Dialog
      fullScreen
      open={open}
      // onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", backgroundColor: "black" }}>
        <Toolbar>
          <IconButton
            edge="end"
            color="inherit"
            onClick={close}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Productos Correspondientes de {title}
          </Typography>
        </Toolbar>
      </AppBar>

      <Grid container sx={{ paddingY: 2, paddingX: 1 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container spacing={2}>
            {!productSubcategory.length ? (
              <></>
            ) : (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  fullWidth
                  label="Buscar por nombre del Producto"
                  id="filled-size-small"
                  variant="outlined"
                  size="small"
                  onChange={HandleFilterProduct}
                />
              </Grid>
            )}

            {!magicWord &&
              (!productSubcategory.length ? (
                <NoDataComponents />
              ) : (
                productSubcategory.map((productitems) => (
                  <Grid key={productitems._id} item xs={6} sm={4} md={3} lg={3}>
                    <BySubCategory items={productitems} />
                  </Grid>
                ))
              ))}

            {magicWord &&
              (!productSubcategoryFilter.length ? (
                <NoResultsComponent />
              ) : (
                productSubcategoryFilter.map((productitems) => (
                  <Grid key={productitems._id} item xs={6} sm={4} md={3} lg={3}>
                    <BySubCategory items={productitems} />
                  </Grid>
                ))
              ))}
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}
