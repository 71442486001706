import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

export default function selectTypeOffer(props) {
  const options = [
    {name: "Categoria", value: 1},
    {name: "Subcategoria", value: 2},
    {name: "Producto", value: 3},
  ];

  const detectarCambiosTypeOffer = (e) => {
    props.detectarCambiosTypeOffer(e);
  };

  return (

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FormControl fullWidth>
            {props.selectType ? (
              <>
                <InputLabel id="demo-simple-select-label">
                  Seleccionar tipo de oferta
                </InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Tipo de oferta "
                  defaultValue={props.selectType}
                  onChange={detectarCambiosTypeOffer}
                >
                  {options.map((option , index) => (
                    <MenuItem key={index} value={option.value}>{option.name}</MenuItem>
                  ))}
                </Select>
              </>
            ) : (
              <>
                <InputLabel id="demo-simple-select-label">
                  Seleccionar Tipo de oferta
                </InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Tipo de oferta "
                  onChange={detectarCambiosTypeOffer}
                >
                  {options.map((option , index) => (
                      <MenuItem key={index} value={option.value}>{option.name}</MenuItem>
                  ))}
                </Select>
              </>
            )}
            <FormHelperText>Campo Requerido*</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
   
  );
}
