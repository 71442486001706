import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import ProductsContext from "../../context/Products/ProductsContext";
import { useDebounce } from "use-debounce";
import CropFreeIcon from "@mui/icons-material/CropFree";

export default function Barcode({
  saveProductList,
  productList,
  saveProduct,
  product,
}) {
  const [barcode, saveBarcode] = useState("");
  const [debounceBarcode] = useDebounce(barcode, 500);
  const { products, productsSelect, success } = useContext(ProductsContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    productsSelect();
  }, [success]);
  const handleChangeBarcode = (e) => {
    saveBarcode(e.target.value);
  };
  useEffect(() => {
    filterProductExist();
  }, [debounceBarcode]);
  const filterProductExist = () => {
    if (debounceBarcode !== "") {
      var producto = [];
      producto = products.filter((p) => p.barcode === debounceBarcode);
      if (!producto.length) {
        Swal.fire({
          title: "¡Lo sentimos!",
          text: "El codigo de barras, no coincide con ninguno de los productos",
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
        });
        saveBarcode("");
        return;
      }
      producto[0].value = producto[0]._id;
      saveProduct(producto[0]._id);
      saveBarcode("");
    }
  };

  return (
    <TextField
      placeholder="Usa el lector de codigos de barra"
      variant="outlined"
      required
      fullWidth
      value={barcode}
      id="barcode"
      label="Código De Barras"
      name="barcode"
      autoComplete="off"
      autoFocus
      error={errors.barcode ? true : false}
      helperText={errors?.barcode?.message}
      {...register("barcode", {
        required: {
          value: true,
          message: "Este campo es requerido",
        },
        minLength: {
          value: 2,
          message: "Minimo 2 caracteres",
        },
        maxLength: {
          value: 255,
          message: "Maximo 255 caracteres",
        },
        onChange: (e) => {
          handleChangeBarcode(e);
        },
      })}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <CropFreeIcon />
          </InputAdornment>
        ),
      }}
    />
  );
}
