import React, { useContext, useEffect, useState } from "react";
import CouponsContext from "../../context/coupons/CouponsContext";
import CuponCard from "../../components/Cards/Cupon/CuponCard";
import NoDataComponents from "../../components/loading/NoDataComponents";

import { makeStyles } from "@mui/styles";
import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import ModalAdd from "./ModalAdd";
import ModulesContext from "../../context/Permissions/PermissionsContext";

const useStyles = makeStyles({
  titleCard: {
    color: "white",
    fontSize: "35px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 2,
    marginRight: 5,
  },
});

export default function Coupons() {
  const classes = useStyles();
  const permiss = JSON.parse(sessionStorage.getItem("permissions"));
  const [modalAdd, AbrirModalAdd] = useState(false);
  const handleClickOpenAdd = () => {
    AbrirModalAdd(true);
  };
  const handleClickCloseAdd = () => {
    AbrirModalAdd(false);
  };

  const { coupons, GetCupons, success } = useContext(CouponsContext);
  const { permissionsPerUser, GetModulesPerUser } = useContext(ModulesContext);

  useEffect(() => {
    if (localStorage.getItem("module_id")) {
      const permiss = localStorage.getItem("module_id");
      GetModulesPerUser(permiss);
    }
    GetCupons();
  }, [success]);

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#000000",
            textAling: "center",
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            marginBottom: 3,
          }}>
          <div className={classes.titleCard}>Administracion de Cupones</div>
          {permissionsPerUser.write === true && (
            <div className={classes.fab}>
              <Button
                onClick={handleClickOpenAdd}
                sx={{
                  color: "black",
                  backgroundColor: "#26c6da",
                  hoverColor: "white",
                  fontFamily: "monospace",
                  fontWeight: "bolder",
                  fontSize: "15px",
                  textTransform: "none",
                  "&:hover": {
                    color: "black",
                    backgroundColor: "#67cedb",
                    hoverColor: "white",
                    fontFamily: "monospace",
                    fontWeight: "bolder",
                    fontSize: "15px",
                  },
                }}>
                Agregar
              </Button>
            </div>
          )}
        </Box>
        <Grid container spacing={2}>
          {!coupons.length ? (
            <Grid item xs={12}>
              <NoDataComponents />
            </Grid>
          ) : (
            coupons.map((coupon) => (
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} key={coupon._id}>
                <CuponCard permiss={permissionsPerUser} coupon={coupon} />
              </Grid>
            ))
          )}
        </Grid>
      </Grid>
      <ModalAdd
        modal={modalAdd}
        handleClickOpen={handleClickOpenAdd}
        handleClose={handleClickCloseAdd}
      />
    </>
  );
}
