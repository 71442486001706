import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import { PriceFormat } from "../../utils/FormatPrice";
import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  DialogContent,
  Grid,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import SalesFisicContext from "../../context/SalesFisic/SalesFisicContext";
import InputCash from "./InputCash";
import InputTransfer from "./InputTransfer";
import InputCard from "./InputCard";
import { useEffect } from "react";

export default function ModalPayment(props) {
  const history = useHistory();
  const {
    modal,
    total,
    totalVenta,
    subtotalVenta,
    businessRuleVenta,
    customer,
    handleClose,
    cart,
  } = props;

  /**Efectivo */
  const [paymentCash, savePaymentCash] = useState(0);
  const handleChangePaymentCash = (e) => {
    savePaymentCash(e.target.value);
  };
  const [accountFisic, saveAccountFisic] = useState(null);
  const detectarCambiosAccountFisic = (value) => {
    saveAccountFisic(value);
  };
  /**Transferencia */
  const [imageTransfer, setImageTransfer] = useState({
    urlPhotoTransfer:
      "https://coffective.com/wp-content/uploads/2018/06/default-featured-image.png.jpg",
    imageTransfer: "",
  });
  const handleChangeImageTransfer = (e) => {
    setImageTransfer({
      ...imageTransfer,
      urlPhotoTransfer: URL.createObjectURL(e.target.files[0]),
      imageTransfer: e.target.files[0],
    });
  };
  const [paymentTransfer, savePaymentTransfer] = useState(0);
  const handleChangePaymentTransfer = (e) => {
    savePaymentTransfer(e.target.value);
  };
  const [account, saveAccount] = useState(null);
  const detectarCambiosAccount = (value) => {
    saveAccount(value);
  };
  /**Card credit or debit */
  const [imageCard, setImageCard] = useState({
    urlPhotoCard:
      "https://coffective.com/wp-content/uploads/2018/06/default-featured-image.png.jpg",
    imageCard: "",
  });
  const handleChangeImageCard = (e) => {
    setImageCard({
      ...imageCard,
      urlPhotoCard: URL.createObjectURL(e.target.files[0]),
      imageCard: e.target.files[0],
    });
  };
  const [paymentCard, savePaymentCard] = useState(0);
  const handleChangePaymentCard = (e) => {
    savePaymentCard(e.target.value);
  };
  /**Envio de datos */
  const { CreateSale, success } = useContext(SalesFisicContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  const sumaPagado =
    Number(paymentCash) + Number(paymentCard) + Number(paymentTransfer);
  const restante = Number(totalVenta) - Number(sumaPagado);
  const payments = [];
  if (paymentCash > 0 && accountFisic !== null) {
    payments.push({
      amount: Number(paymentCash),
      bank_account_id: accountFisic.value,
      type: 1,
    });
  }
  if (paymentTransfer > 0 && account !== null) {
    payments.push({
      amount: Number(paymentTransfer),
      bank_account_id: account.value,
      // image: imageTransfer.imageTransfer,
      type: 2,
    });
  }
  if (paymentCard > 0) {
    payments.push({
      amount: Number(paymentCard),
      // image: imageCard.imageCard,
      type: 3,
    });
  }

  const onSubmit = (data, e) => {
    if (paymentTransfer > 0 && account === null) {
      Swal.mixin({
        toast: true,
        background: "red",
        color: "white",
        iconColor: "white",
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire({
        icon: "error",
        title: `Selecciona una cuenta bancaria en el pago de transferencia electronica `,
      });
      return;
    }
    if (paymentCash > 0 && accountFisic === null) {
      Swal.mixin({
        toast: true,
        background: "red",
        color: "white",
        iconColor: "white",
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire({
        icon: "error",
        title: `Selecciona una cuenta para el pago en efectivo`,
      });
      return;
    }
    if (paymentCard > 0 && imageCard.imageCard === "") {
      Swal.mixin({
        toast: true,
        background: "red",
        color: "white",
        iconColor: "white",
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire({
        icon: "error",
        title: `Sube el comprobante de pago con tarjeta`,
      });
      return;
    }
    if (paymentTransfer > 0 && imageTransfer.imageTransfer === "") {
      Swal.mixin({
        toast: true,
        background: "red",
        color: "white",
        iconColor: "white",
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire({
        icon: "error",
        title: `Sube el comprobante de pago por transferencia`,
      });
      return;
    }
    data.productsDiscount = cart.productsDiscount;
    data.productsWithoutDiscount = cart.productsWithoutDiscount;
    data.customer_id = customer.id;
    data.payments = payments;
    if (imageCard.imageCard !== "") {
      data.imageCard = imageCard.imageCard;
    }
    if (imageTransfer.imageTransfer !== "") {
      data.imageTransfer = imageTransfer.imageTransfer;
    }
    // }
    CreateSale(data);
    handleClose();
  };

  useEffect(() => {
    if (success) {
      history.goBack();
    }
  }, [success]);

  return (
    <div>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={modal}
        aria-labelledby="responsive-dialog-title">
        <Grid
          container
          spacing={2}
          sx={{ backgroundColor: "black", color: "white", marginBottom: 2 }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            display="flex"
            justifyContent="center">
            <Typography variant="button" fontSize="20px">
              Finalizar compra
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            display="flex"
            justifyContent="space-between"
            sx={{
              backgroundColor: "black",
              color: "white",
              marginBottom: 2,
            }}>
            <Typography variant="h6" fontSize="20px" sx={{ marginLeft: 3 }}>
              Cliente: {customer?.fullname}{" "}
            </Typography>
            <IconButton>
              <CloseIcon
                sx={{ color: "red", marginRight: 3 }}
                onClick={handleClose}
              />
            </IconButton>
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{ backgroundColor: "black", color: "white" }}>
            <Grid
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              display="flex"
              justifyContent="center">
              <Typography variant="h6" gutterBottom>
                Subtotal: ${" "}
                {PriceFormat(subtotalVenta)
                  ? PriceFormat(subtotalVenta)
                  : "Calculando..."}{" "}
              </Typography>
            </Grid>
            {businessRuleVenta && (
              <Grid
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                display="flex"
                justifyContent="center">
                <Typography variant="h6" gutterBottom>
                  Descuento:{" "}
                  {businessRuleVenta?.discount
                    ? businessRuleVenta.discount
                    : "N/A"}
                  {" % "}
                </Typography>
              </Grid>
            )}
            <Grid
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              display="flex"
              justifyContent="center">
              <Typography variant="h6" gutterBottom>
                Total a pagar: ${" "}
                {PriceFormat(totalVenta)
                  ? PriceFormat(totalVenta)
                  : "Calculando..."}
              </Typography>
            </Grid>
            <Grid
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              display="flex"
              justifyContent="center">
              <Typography variant="h6" gutterBottom>
                Total pagado: $ {PriceFormat(sumaPagado)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <DialogContent>
          <Box display="flex" justifyContent="space-between">
            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(e) => {
                if (e.code === "Enter" || e.code === "NumpadEnter")
                  e.preventDefault();
              }}
              autoComplete="off">
              {/**Efectivo */}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <InputCash
                    detectarCambiosAccountFisic={detectarCambiosAccountFisic}
                    paymentCash={paymentCash}
                    handleChangePaymentCash={handleChangePaymentCash}
                  />
                </Grid>
                {/**Transferencia */}
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <InputTransfer
                    detectarCambiosAccount={detectarCambiosAccount}
                    handleChangeImageTransfer={handleChangeImageTransfer}
                    handleChangePaymentTransfer={handleChangePaymentTransfer}
                    paymentTransfer={paymentTransfer}
                    imageTransfer={imageTransfer}
                  />
                </Grid>
                {/**Tarjeta */}
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <InputCard
                    handleChangeImageCard={handleChangeImageCard}
                    handleChangePaymentCard={handleChangePaymentCard}
                    imageCard={imageCard}
                    paymentCard={paymentCard}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                justifyContent="center"
                display="flex">
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  {restante === 0 ? (
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{
                        backgroundColor: "#f06292",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#f06292",
                          color: "white",
                        },
                      }}>
                      Finalizar venta
                    </Button>
                  ) : (
                    <p style={{ color: "red" }}>
                      La cantidad ingresada debe ser igual al total a pagar,
                      para poder continuar!
                    </p>
                  )}
                </Grid>
              </Grid>
            </form>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
