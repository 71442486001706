import React, { useReducer } from "react";
import Swal from "sweetalert2";
import MethodGet, {
	MethodDelete,
	MethodPost,
	MethodPut,
} from "../../config/Service";
import {
	ADD_CANVAS_VARIANT,
	DELETE_CANVAS_VARIANT,
	GET_ALL_CANVAS_VARIANTS,
	SHOW_ERRORS_API,
	UPDATE_CANVAS_VARIANT,
} from "../../types";
import CanvasVariantsContext from "./CanvasVariantsContext";
import CanvasVariantsReducer from "./CanvasVariantsReducer";
import headerConfig from "../../config/imageHeaders";

const CanvasVariantsState = ({ children }) => {
	const initialState = {
		canvasVariants: [],
		canvasVariant: null,
		ErrorsAPI: [],
		success: false,
	};
	//useReducer;
	const [state, dispatch] = useReducer(CanvasVariantsReducer, initialState);
	//obtener la lista de variantes
	const GetCanvasVariants = () => {
		sessionStorage.removeItem("canvasVariant");
		let url = "/variations";
		MethodGet(url)
			.then((res) => {
				dispatch({
					type: GET_ALL_CANVAS_VARIANTS,
					payload: res.data.variations,
				});
			})
			.catch((error) => {
				dispatch({
					type: SHOW_ERRORS_API,
					payload: error.response.data.message,
				});
			});
	};

	const AddCanvasVariant = (variant) => {
		const formData = new FormData();
		formData.append("number_images", variant.number_images);
		formData.append("image", variant.image);
		formData.append("name", variant.name);

		let url = "/variations";
		MethodPost(url, formData, { headerConfig })
			.then((res) => {
				Swal.fire({
					title: "Variante Agregada",
					text: res.data.message,
					icon: "success",
					timer: 1500,
					showConfirmButton: false,
				});
				dispatch({
					type: ADD_CANVAS_VARIANT,
				});
			})
			.catch((error) => {
				dispatch({
					type: SHOW_ERRORS_API,
					payload: error.response.data.message,
				});
			});
	};

	const UpdateCanvasVariant = (variant) => {
		const formData = new FormData();
		formData.append("number_images", variant.number_images);
		formData.append("name", variant.name);
		formData.append("image", variant.image);

		let url = `/variations/${variant.id}`;

		MethodPut(url, formData, { headerConfig })
			.then((res) => {
				Swal.fire({
					title: "Variante Actualizada",
					text: res.data.message,
					icon: "success",
					timer: 1500,
					showConfirmButton: false,
				});
				dispatch({
					type: UPDATE_CANVAS_VARIANT,
				});
			})
			.catch((error) => {
				dispatch({
					type: SHOW_ERRORS_API,
					payload: error.response.data.message,
				});
			});
	};

	const DeleteCanvasVariant = (id) => {
		Swal.fire({
			title: "¿Estas Seguro?",
			text: "La marca seleccionada sera eliminada",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Si, eliminar",
			cancelButtonText: "No, cancelar",
		}).then((result) => {
			if (result.value) {
				let url = `/variations/${id}`;

				MethodDelete(url)
					.then((res) => {
						Swal.fire({
							title: "Eliminado",
							text: res.data.message,
							icon: "success",
							timer: 2000,
							showConfirmButton: false,
						});
						dispatch({
							type: DELETE_CANVAS_VARIANT,
							payload: id,
						});
					})
					.catch((error) => {
						Swal.fire({
							title: "Error",
							text: error.response.data.message,
							icon: "error",
						});
					});
			}
		});
	};
	return (
		<CanvasVariantsContext.Provider
			value={{
				canvasVariants: state.canvasVariants,
				canvasVariant: state.canvasVariant,
				ErrorsAPI: state.ErrorsAPI,
				success: state.success,
				GetCanvasVariants,
				AddCanvasVariant,
				DeleteCanvasVariant,
				UpdateCanvasVariant,
			}}
		>
			{children}
		</CanvasVariantsContext.Provider>
	);
};

export default CanvasVariantsState;
