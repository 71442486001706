import {
  ADD_ACCOUNTS_RECEIVABLE,
  ADD_PAYMENT_ACCOUNT_RECEIVABLE,
  GET_ACCOUNTS_CLIENT,
  GET_ACCOUNTS_RECEIVABLE,
  UPDATE_ACCOUNTS_RECEIVABLE,
} from "./../../types/index";

export default function AccountsReceivableReducer(state, action) {
  switch (action.type) {
    case GET_ACCOUNTS_RECEIVABLE:
      return {
        ...state,
        accountsReceivable: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case ADD_ACCOUNTS_RECEIVABLE:
      return {
        ...state,
        accountsReceivable: state.accountsReceivable.map((account) =>
          account._id === action.payload.id ? { ...action.payload } : account
        ),
        success: false,
        ErrorsApi: [],
      };
    case UPDATE_ACCOUNTS_RECEIVABLE:
      return {
        ...state,
        accountsReceivable: state.accountsReceivable.map((account) =>
          account._id === action.payload.id ? { ...action.payload } : account
        ),
        succcess: false,
        ErrorsApi: [],
      };
    case ADD_PAYMENT_ACCOUNT_RECEIVABLE:
      return {
        ...state,
        paymentsAccountsReceivable: state.paymentsAccountsReceivable.map(
          (payment) =>
            payment._id === action.payload._id ? { ...action.payload } : payment
        ),
        success: false,
        ErrorsApi: [],
      };
    case GET_ACCOUNTS_CLIENT:
      return {
        ...state,
        paymentsAccountsReceivable: action.payload,
        success: false,
        ErrorsApi: [],
      };
    default:
      return state;
  }
}
