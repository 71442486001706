export function CalDiscount(subtotal, discount) {
  const DECIMAL_VALUE = discount / 100;
  const CANTIDAD_RESTADA = subtotal * DECIMAL_VALUE;
  const CANTIDAD_RESTADA_TOTAL = (subtotal - CANTIDAD_RESTADA).toFixed(2);
  return "$ " + CANTIDAD_RESTADA_TOTAL;
}

export const CalDiscountRest = (discount, total) => {
  const DECIMAl = discount / 100;
  const CANTIDAD = total * DECIMAl;
  return ` -  $${CANTIDAD.toFixed(2)}`;
};
