import { Grid, Typography, Divider, Chip, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SalesContext from "../../context/Sales/SalesContext";
import { useContext } from "react";
import ShippmentDetail from "./ShippmentDetail";
import ComputerIcon from "@mui/icons-material/Computer";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const useStyles = makeStyles(() => ({
  badgependiente: {
    color: "#fff",
    backgroundColor: "blue",
    display: "inline-blocl",
    padding: "0.25em 0.4em",
    fontSize: "75%",
    lineHeight: 1,
    textAlign: "center",
    whiteSpace: "baseline",
    borderRadius: "0.25rem",
  },
  badgeprocesando: {
    color: "#fff",
    backgroundColor: "rgb(255, 165, 0)",
    display: "inline-blocl",
    padding: "0.25em 0.4em",
    fontSize: "75%",
    lineHeight: 1,
    textAlign: "center",
    whiteSpace: "baseline",
    borderRadius: "0.25rem",
  },
  badgeenviado: {
    color: "#fff",
    backgroundColor: "green",
    display: "inline-blocl",
    padding: "0.25em 0.4em",
    fontSize: "75%",
    lineHeight: 1,
    textAlign: "center",
    whiteSpace: "baseline",
    borderRadius: "0.25rem",
  },
  loading: {
    color: "black",
    fontSize: "150px",
    fontFamily: "roboto",
    fontWeight: "bold",
    textAlign: "center",
  },
}));
export const SaleOrder = ({
  folio,
  orderStatus,
  shippment,
  subtotal,
  total,
  shipping,
  discount_business_rule_id,
  discount_coupon_id,
  currency,
  subtotalCurrency,
  totalCurrency,
  shippmentInCurrency,
  order_id,
  type,
  metadata,
  origin,
}) => {
  const classes = useStyles();

  const { AddNewShippment } = useContext(SalesContext);
  return (
    <>
      <Divider>
        <Chip
          sx={{ fontWeight: "bold", fontSize: 18 }}
          label="Información de la Venta"
          icon={<BusinessCenterIcon />}
        />
      </Divider>
      <Grid container sx={{ textAlign: "center", marginY: 2 }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
          xl={3}
          sx={{ marginBottom: 1 }}
        >
          <Typography sx={{ fontWeight: "bold" }}>Folio de Pedido</Typography>
          <Typography>{folio}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
          xl={3}
          sx={{ marginBottom: 1 }}
        >
          <Typography sx={{ fontWeight: "bold" }}>Estatus Pago</Typography>
          <Typography sx={{ marginX: 1 }}>
            {orderStatus === 0 || orderStatus === 1 ? (
              <Typography className={classes.badgependiente}>
                Pago Pendiente
              </Typography>
            ) : orderStatus === 2 ? (
              <Typography className={classes.badgeenviado}>Completo</Typography>
            ) : (
              orderStatus === 3 && (
                <Typography className={classes.badgeenviado}>
                  Completo
                </Typography>
              )
            )}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
          xl={3}
          sx={{ marginBottom: 1 }}
        >
          <Typography sx={{ fontWeight: "bold" }}>Estatus Envío</Typography>
          <Typography sx={{ marginX: 1 }}>
            {orderStatus === 0 || orderStatus === 1 ? (
              <Typography className={classes.badgependiente}>
                Pendiente
              </Typography>
            ) : orderStatus === 2 ? (
              <Typography className={classes.badgeprocesando}>
                Procesando
              </Typography>
            ) : (
              orderStatus === 3 && (
                <Typography className={classes.badgeenviado}>
                  Enviado
                </Typography>
              )
            )}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
          xl={3}
          sx={{ marginBottom: 1 }}
        >
          <Typography fontWeight="bold">Compra realizada desde:</Typography>
          <>
            {/***TYPE 1 === TAREJETA || TYPE === 2 TRANSFER */}
            {type === 1 && metadata ? (
              <>
                {metadata.payment_source === "web" ? (
                  <ComputerIcon />
                ) : metadata.payment_source === "mobile" ? (
                  <PhoneIphoneIcon />
                ) : (
                  metadata === null && <p>No definido</p>
                )}
              </>
            ) : (
              type === 2 && (
                <>
                  {origin === "web" ? (
                    <ComputerIcon />
                  ) : origin === "mobile" ? (
                    <PhoneIphoneIcon />
                  ) : (
                    origin === undefined && <p>No definido</p>
                  )}
                </>
              )
            )}
          </>
        </Grid>

        <Grid container spacing={2}>
          {orderStatus === 3 && (
            <>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Divider>
                  <Chip
                    sx={{ fontWeight: "bold", fontSize: 18 }}
                    label="Detalle de envio "
                    icon={<LocalShippingIcon />}
                  />
                </Divider>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                display="flex"
                justifyContent="end"
              >
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => AddNewShippment(order_id)}
                >
                  Crear nueva guía
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {/* <ShippmentDetail
                  shipping={shipping}
                  orderStatus={orderStatus}
                /> */}
              </Grid>
            </>
          )}
        </Grid>

        {shipping && shipping.comment && (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            display="flex"
            justifyContent="center"
            sx={{ marginBottom: 4, marginTop: 4 }}
          >
            <TableContainer sx={{ marginTop: 4, marginBottom: 5 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">
                      <Typography sx={{ fontWeight: "bold" }}>
                        Comentarios de la orden
                      </Typography>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {shipping.comment
                        ? shipping.comment
                        : "No se han agregado comentarios"}
                    </Typography>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ marginTop: 2 }}
        >
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">
                    Moneda / pago
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Gastos de envio
                  </StyledTableCell>
                  <StyledTableCell align="center">Subtotal</StyledTableCell>
                  <StyledTableCell align="center">Descuento</StyledTableCell>
                  <StyledTableCell align="center">Total</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell component="th" scope="row" align="center">
                    MXN {currency && "/ " + currency?.currency}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {shippment && " $ " + shippment}
                    {shippmentInCurrency && currency !== null
                      ? " /  " + shippmentInCurrency.toFixed(2)
                      : ""}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {subtotal && "$ " + subtotal}
                    {subtotalCurrency && " / $ " + subtotalCurrency.toFixed(2)}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {" "}
                    {discount_business_rule_id !== null &&
                      discount_business_rule_id !== undefined &&
                      discount_business_rule_id.length !== 0 &&
                      ` del ${discount_business_rule_id.discount} %`}
                    {discount_coupon_id !== null &&
                      discount_coupon_id !== undefined &&
                      discount_coupon_id.length !== 0 &&
                      ` del ${discount_coupon_id.discount} %`}
                    {/* {discount_business_rule_id !== null &&
											discount_business_rule_id !== undefined &&
											discount_business_rule_id.length !== 0 &&
											CalDiscountRest(
												subtotal,
												discount_business_rule_id.discount
											)}
										{discount_coupon_id !== null &&
											discount_coupon_id !== undefined &&
											discount_coupon_id.length !== 0 &&
											CalDiscountRest(subtotal, discount_coupon_id.discount)} */}
                    {discount_coupon_id === undefined &&
                      discount_business_rule_id === undefined &&
                      "No aplica"}
                    {discount_coupon_id === null &&
                      discount_business_rule_id === null &&
                      "No aplica"}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {total && "$ " + total}
                    {totalCurrency && " / $ " + totalCurrency.toFixed(2)}
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};
