import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Modal } from "bootstrap";
import { Grid, TextField } from "@mui/material";
import ClientsContext from "../../context/Clients/ClientsContext";
import { useContext } from "react";
import { useForm } from "react-hook-form";
// import SelectClientType from "../../containers/SelectOptions/SelectTypeClients";
import { useState } from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs(props) {
  const { RegisterClient } = useContext(ClientsContext);
  const { modal, handleClose } = props;
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  // const [typeClient, saveTypeClient] = useState(null);
  // const detectarCambiosTypeClients = (e) => {
  //   saveTypeClient(e.target.value);
  // };
  const onSubmit = (data, e) => {
    // data.customer_type = typeClient;
    RegisterClient(data);
    handleClose();
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={modal}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter")
              e.preventDefault();
          }}
          autoComplete="off"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Registrar nuevo cliente
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2}>
              {/* <Grid item xs={12}>
                <SelectClientType
                  detectarCambiosTypeClients={detectarCambiosTypeClients}
                />
              </Grid> */}
              <>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Nombre Completo"
                    name="fullname"
                    variant="outlined"
                    error={errors.fullname ? true : false}
                    helperText={errors?.fullname?.message}
                    {...register("fullname", {
                      required: {
                        value: true,
                        message: "Este campo es requerido",
                      },
                    })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Correo electronico"
                    name="email"
                    variant="outlined"
                    error={errors.email ? true : false}
                    helperText={errors?.email?.message}
                    {...register("email", {
                      required: {
                        value: true,
                        message: "Este campo es requerido",
                      },
                    })}
                  />
                </Grid>
              </>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{ background: "red", "&:hover": { background: "red" } }}
              autoFocus
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{ background: "green", "&:hover": { background: "green" } }}
              autoFocus
              onClick={handleClose}
            >
              Registrar usuario
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
}
