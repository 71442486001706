import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";

export default function TypeSelectBR(props) {
  const typesBusinessRules = [
    { name: "Venta Ecommerce", value: 2 },
    { name: "Categorias", value: 4 },
    { name: "Subcategoria", value: 1 },
    { name: "Costo de envio", value: 6 },
  ];
  const detectarCambiosTypeBusinessRule = (e) => {
    if (e.target.value) {
    }
    props.detectarCambiosTypeBusinessRule(e);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            Tipo regla de negocio
          </InputLabel>
          {props.TypeId ? (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Tipo de regla de negocio "
              value={props.TypeId}
              onChange={detectarCambiosTypeBusinessRule}>
              {typesBusinessRules.map((typeBusinessRule, index) => (
                <MenuItem value={typeBusinessRule.value} key={index}>
                  {typeBusinessRule.name}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Tipo de regla de negocio "
              onChange={detectarCambiosTypeBusinessRule}>
              {typesBusinessRules.map((typeBusinessRule, index) => (
                <MenuItem value={typeBusinessRule.value} key={index}>
                  {typeBusinessRule.name}
                </MenuItem>
              ))}
            </Select>
          )}
          <FormHelperText>Compo Requerido*</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}
