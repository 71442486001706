import { useContext, useEffect } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { PublicRouter } from "./PublicRoute";
import { PrivateRouter } from "./PrivateRoute";
import Login from "../components/Auth/Sign-In/Sign-In";
import AuthContext from "../context/auth/authContext";
import { EcommerceRoutes } from "./EcommerceRoutes";
import EmailVerificaction from "../components/Auth/PassFV/PassFV";
import FormNewPass from "../components/Auth/FormNewPass/FormNewPass";
import { Box, Grid } from "@mui/material";
import SpinnerComponent from "../components/loading/SpinnerComponent";
// import TypeUsers from "../containers/type_users/TypeUsers";

export default function AppRouter() {
	const { autenticado, usuarioAutenticado, cargando } = useContext(AuthContext);

	useEffect(() => {
		usuarioAutenticado();
	}, []);

	if (cargando) {
		return (
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<Box
					sx={{
						width: "105%",
						height: "177%",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<SpinnerComponent />
				</Box>
			</Grid>
		);
	}
	return (
		<>
			<Router>
				<Switch>
					<PublicRouter
						exact
						path="/Restablecer-mi-Acceso/:token/:userId"
						component={FormNewPass}
						isAuthenticated={autenticado}
					/>

					<PublicRouter
						exact
						path="/Olvide-mi-Acceso"
						component={EmailVerificaction}
						isAuthenticated={autenticado}
					/>
					<PublicRouter
						exact
						path="/iniciar-sesion"
						component={Login}
						isAuthenticated={autenticado}
					/>

					<PrivateRouter
						path="/"
						component={EcommerceRoutes}
						isAuthenticated={autenticado}
					/>
				</Switch>
			</Router>
		</>
	);
}
