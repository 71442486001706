import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { styled, alpha } from "@mui/material/styles";
import {
  Button,
  CardContent,
  Grid,
  InputBase,
  Pagination,
  Stack,
  TextField,
} from "@mui/material";
import { useDebounce } from "use-debounce";
import SalesContext from "../../context/Sales/SalesContext";
import SalesCard from "../../components/Cards/Sales/SalesCard";
import NoDataComponents from "../../components/loading/NoDataComponents";
import NoResultsComponent from "../../components/loading/NoResultsComponent";
import MethodGet from "../../config/Service";
import SearchingComponent from "../../components/loading/SearchingComponent";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { Paginate } from "../../components/Pagination/Paginate";
const useStyles = makeStyles(() => ({
  titleCard: {
    color: "white",
    fontSize: "35px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 2,
  },
}));

export default function Sales() {
  const classes = useStyles();
  const { sales, success, GetSales, totalPages } = useContext(SalesContext); //funciones del state
  //***Paginacion */
  var [pages, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const limite = rowsPerPage;
  const params = { limite, pages };
  //ventas aprobadas sin paginacion
  const [salesNoPaginate, saveSalesNoPaginate] = useState([]);
  const [cargando, spinnerCargando] = useState(false);
  useEffect(() => {
    let url = `/payments/no-pagination/approved/pay`;
    MethodGet(url)
      .then((res) => {
        saveSalesNoPaginate(res.data.orders);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  if (cargando) {
  }
  //funcion para busqueda
  const [magicWord, setMagicWord] = useState("");
  const [debounceWord] = useDebounce(magicWord, 1500);
  const [searchSales, saveSearchSales] = useState([]);

  const handleSearchSale = () => {
    try {
      // if (debounceWord !== "") {
      let searchResult = salesNoPaginate.filter(
        (sales) =>
          sales.folio
            .toString()
            .toLowerCase()
            .includes(debounceWord.toLowerCase()) ||
          sales.client_id.fullname
            .toString()
            .toLowerCase()
            .includes(debounceWord.toLowerCase())
      );
      saveSearchSales(searchResult);
      // }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "No se encontraron registros que coincidan con los criterios de busqueda",
        icon: "error",
      });
    }
  };
  useEffect(() => {
    GetSales(params);
  }, [success, rowsPerPage, pages]);

  useEffect(() => {
    saveSearchSales(salesNoPaginate);
  }, []);

  useEffect(() => {
    handleSearchSale();
  }, [debounceWord]);

  return (
    <>
      {/* <form onSubmit={handleSubmit(onSubmit)} autoComplete="off"> */}
      <Grid container spacing={2}>
        {sales.length ? (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                fullWidth
                label="Buscar orden por folio y/o cliente"
                // type="number"
                inputProps={{ "aria-label": "search" }}
                name="search"
                onChange={(e) => {
                  setMagicWord(e.target.value);
                  saveSearchSales(null);
                }}
                // {...register("search", {
                //   required: {
                //     value: false,
                //     message: "Ingresa una palabra para buscar",
                //   },
                // })}
                //onChange={handleSearchSale}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <Button type="submit" value="Buscar" sx={{ display: "none" }}>
                Buscar
              </Button>
            </Grid>
          </>
        ) : (
          <></>
        )}
      </Grid>
      {/* </form> */}
      {!magicWord &&
        (!sales.length ? (
          <NoDataComponents />
        ) : (
          <Grid container spacing={2}>
            {sales.map((sales) => (
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} key={sales._id}>
                <SalesCard sales={sales} />
              </Grid>
            ))}
          </Grid>
        ))}
      {magicWord &&
        (!searchSales ? (
          <SearchingComponent />
        ) : searchSales.length === 0 ? (
          <NoResultsComponent />
        ) : (
          <Grid container spacing={2}>
            {searchSales.map((sales) => (
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} key={sales._id}>
                <SalesCard sales={sales} />
              </Grid>
            ))}
          </Grid>
        ))}
      {magicWord ? (
        <></>
      ) : (
        sales.length > 0 && (
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              display="flex"
              justifyContent="center"
            >
              <Paginate
                totalPages={totalPages}
                pages={pages}
                handleChangePage={handleChangePage}
              />
            </Grid>
          </Grid>
        )
      )}
      <CardContent></CardContent>
    </>
  );
}
