import React, { useContext, useEffect, useState } from "react";
import WarehouseContext from "../../context/Warehouse/WarehouseContext";
import ShippedCard from "../../components/Cards/Warehouse/ShippedCard";
import NoDataComponents from "../../components/loading/NoDataComponents";
import ModulesContext from "../../context/Permissions/PermissionsContext";
import { makeStyles } from "@mui/styles";
import { styled, alpha } from "@mui/material/styles";
import { Box, CardContent, Grid, InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import NoResultsComponent from "../../components/loading/NoResultsComponent";
import MethodGet from "../../config/Service";
import Swal from "sweetalert2";
import { Paginate } from "../../components/Pagination/Paginate";
const useStyles = makeStyles({
  titleCard: {
    color: "white",
    fontSize: "35px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 2,
  },
});

//Estilos busqueda
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
  },
}));

export default function Seending() {
  const classes = useStyles();
  /**Paginacion */
  var [pages, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [magicWord, setMagicWord] = useState("");
  const [filterWarehouse, setFilterWarehouse] = useState();
  const [sending, setSending] = useState({});
  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };
  const limite = rowsPerPage;
  const params = { limite, pages };

  const { shippingsShipped, GetWarehouseShipped, success, totalPages } =
    useContext(WarehouseContext);

  useEffect(() => {
    let url = "/warehouse-shipping/no-pagination/shipped";
    MethodGet(url)
      .then((res) => {
        setSending(res.data.shippings);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
      });
  }, []);

  // const { type_users, GetTypeUsers} = useContext(WarehouseContext);
  const { permissionsPerUser, GetModulesPerUser } = useContext(ModulesContext);

  useEffect(() => {
    if (localStorage.getItem("module_id")) {
      const permiss = localStorage.getItem("module_id");
      GetModulesPerUser(permiss);
      // GetTypeUsers();
    }
  }, [success]);

  const handleSearchWarehose = ({ target }) => {
    setMagicWord(target.value);
    const palabra = target.value;
    try {
      let resulfilterwarehouse = sending.filter(
        (shipping) =>
          shipping.order_id?.folio
            .toString()
            .toLowerCase()
            .toUpperCase()
            .includes(palabra.toString().toLowerCase().toUpperCase()) ||
          shipping.order_id?.client_id?.fullname
            .toString()
            .toLowerCase()
            .toUpperCase()
            .includes(palabra.toString().toLowerCase().toUpperCase())
      );
      setFilterWarehouse(resulfilterwarehouse);
    } catch (error) {}
  };
  useEffect(() => {
    GetWarehouseShipped(params);
    setFilterWarehouse(sending);
  }, [success, rowsPerPage, pages]);

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Box sx={{ width: "100%", display: "flex" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container spacing={2}>
                {shippingsShipped.length ? (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Search>
                      <SearchIconWrapper>
                        <SearchIcon />
                      </SearchIconWrapper>
                      <StyledInputBase
                        fullWidth
                        placeholder="Buscar pedido por el número de Orden o nombre del cliente"
                        inputProps={{ "aria-label": "search" }}
                        onChange={handleSearchWarehose}
                      />
                    </Search>
                  </Grid>
                ) : (
                  <></>
                )}

                {!magicWord &&
                  (!shippingsShipped.length ? (
                    <NoDataComponents />
                  ) : (
                    shippingsShipped.map((shipping, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={4}
                        key={index}
                      >
                        <ShippedCard
                          shipping={shipping}
                          permiss={permissionsPerUser}
                        />
                      </Grid>
                    ))
                  ))}

                {magicWord &&
                  (!filterWarehouse.length ? (
                    <NoResultsComponent />
                  ) : (
                    filterWarehouse.map((shipping, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={4}
                        key={index}
                      >
                        <ShippedCard
                          shipping={shipping}
                          permiss={permissionsPerUser}
                        />
                      </Grid>
                    ))
                  ))}
              </Grid>
              <Box className={classes.pagination} sx={{ marginTop: 4 }}>
                {!magicWord &&
                  (!shippingsShipped.length ? (
                    <Box className={classes.pagination}></Box>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      display="flex"
                      justifyContent="center"
                    >
                      <Paginate
                        totalPages={totalPages}
                        handleChangePage={handleChangePage}
                        pages={pages}
                      />
                    </Grid>
                  ))}
              </Box>
              <CardContent></CardContent>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
