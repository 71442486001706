import React, { useContext, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  FormControl,
  Grid,
  Hidden,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import GraphsContext from "../../../context/Graphs/GraphsContext";
import { useForm } from "react-hook-form";
import SelectBrand from "./SelectBrand";
const useStyles = makeStyles(() => ({
  container: {
    paddingTop: 1,
    //marginLeft: theme.spacing(-1),
    paddingBottom: 1,
    paddingInlineEnd: 1,
    paddingRight: 0,
    marginLeft: "-50px",
  },
  formControl: {
    width: "100%",
    heigth: "100%",
    margin: 1,
    maxHeight: 400,
  },
  papercard: {
    padding: 2,
    margin: "auto",
    maxHeight: "470px",
    backgroundColor: "transparent",
    flexWrap: "nowrap",
    display: "relative",
    boxSizing: "border-box",
  },
  containerGraphic: {
    width: "100%",
    height: "440px",
    boxSizing: "border-box",
    position: "relative",
    display: "absolute",
  },
  GraphicDetails: {
    display: "absolute",
    width: "100%",
    height: "440px",
    maxHeight: "450px",
    position: "absolute",
    boxSizing: "border-box",
  },
}));

export default function GraphProductsMostSold() {
  const classes = useStyles();

  const [id_brand, setId_Brand] = useState();
  const detectarCambiosBrand = (e) => {
    setId_Brand(e.target.value);
  };

  const { GetProductsMostSale, products, success } = useContext(GraphsContext);
  //var p = products.map((p) => p.quantity_sold);

  const [prod, setProducts] = useState({
    labels: products.map((p) => p._id.product_name),
    datasets: [
      {
        label: "Cantidad Vendida",
        data: products.map((p) => p.quantity_sold),
        backgroundColor: [
          "#ffcdd2",
          "#f8bbd0",
          "#e1bee7",
          "#d1c4e9",
          "#c5cae9",
          "#bbdefb",
          "#b3e5fc",
          "#b2ebf2",
          "#b2dfdb",
          "#c8e6c9",
          "#dcedc8",
          "#f0f4c3",
          "#fff9c4",
          "#ffecb3",
          "#ffe0b2",
          "#81d4fa",
          "#80deea",
          "#80deea",
          "#b39ddb",
          "#f48fb1",
        ],
      },
    ],
  });

  useEffect(() => {
    GetProductsMostSale(id_brand);
  }, [id_brand]);

  useEffect(() => {
    setProducts({
      labels: products.map((p) => p._id.product_name),
      datasets: [
        {
          label: "Productos mas vendidos",
          data: products.map((p) => p.quantity_sold),
          backgroundColor: [
            "#ffcdd2",
            "#f8bbd0",
            "#e1bee7",
            "#d1c4e9",
            "#c5cae9",
            "#bbdefb",
            "#b3e5fc",
            "#b2ebf2",
            "#b2dfdb",
            "#c8e6c9",
            "#dcedc8",
            "#f0f4c3",
            "#fff9c4",
            "#ffecb3",
            "#ffe0b2",
            "#81d4fa",
            "#80deea",
            "#80deea",
            "#b39ddb",
            "#f48fb1",
          ],
        },
      ],
    });
  }, [products]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="h4" textAlign="center">
          Productos más vendidos por marca
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <SelectBrand detectarCambiosBrand={detectarCambiosBrand} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        {prod !== "" && <Bar data={prod} />}
      </Grid>
    </Grid>
  );
}
