import React from "react";
import {
  ADD_TYPE_CLIENTS,
  DELETE_TYPE_CLIENTS,
  GET_TYPE_CLIENTS,
  SHOW_ERRORS_API,
  UPDATE_TYPE_CLIENT,
} from "../../types";

export default function TypeClientsReducer(state, action) {
  switch (action.type) {
    case GET_TYPE_CLIENTS:
      return {
        ...state,
        typeClients: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case ADD_TYPE_CLIENTS:
      return {
        ...state,
        success: true,
        typeClients: [action.payload, ...state.typeClients],
        ErrorsApi: [],
      };
    case SHOW_ERRORS_API:
      return {
        ...state,
        success: true,
        ErrorsApi: [],
      };
    case UPDATE_TYPE_CLIENT:
      return {
        ...state,
        success: true,
        typeClients: state.typeClients.map((typeClient) =>
          typeClient._id === action.payload._id
            ? { ...action.payload }
            : typeClient
        ),
        // typeClient: action.payload,
      };
    case DELETE_TYPE_CLIENTS:
      return {
        ...state,
        success: true,
        typeClients: state.typeClients.filter(
          (typeClient) => typeClient._id !== action.payload
        ),
      };
    default:
      return state;
  }
}
