import React, { useContext, useState } from "react";
import formatDate from "../../../utils/FormatDate";
import Marquee from "react-fast-marquee";
import Badge from "@mui/material/Badge";
import ArticleIcon from "@mui/icons-material/Article";
import { EditIcon } from "../../icons/EditIcon";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";

import {
  Box,
  Card,
  Typography,
  Divider,
  Tooltip,
  Grid,
  IconButton,
  Alert,
} from "@mui/material";
import SalesContext from "../../../context/Sales/SalesContext";
import { Link } from "react-router-dom";
import { PriceFormat } from "../../../utils/FormatPrice";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import WarehouseContext from "../../../context/Warehouse/WarehouseContext";
import AddToHomeScreenIcon from "@mui/icons-material/AddToHomeScreen";
import BlockIcon from "@mui/icons-material/Block";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import ModalShippingCost from "./../../../containers/Sale/UpdateSale/ModalUpdateShippingCost";
const SalesCard = ({ sales }) => {
  const [modalShippingCost, openModalShippingCost] = useState(false);
  const [idOrder, saveIdOrder] = useState(null);
  const handleOpenModalShippingCost = (id) => {
    saveIdOrder(id);
    openModalShippingCost(true);
  };
  const handleCloseModalShippingCost = () => {
    openModalShippingCost(false);
  };

  const { EnableUpdateSalePayment, DisableUpdateSalePayment } =
    useContext(SalesContext);
  const { PdfOrder } = useContext(WarehouseContext);

  return (
    <Card
    // sx={
    //   !sales.super_order
    //     ? { boxShadow: 10 }
    //     : { boxShadow: 10, border: "1px solid black" }
    // }
    >
      <Grid container spacing={2}>
        {sales.orderStatus === 2 ? (
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            sx={{ backgroundColor: "#ffa726", color: "white" }}
            display="flex"
            justifyContent="space-between"
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                display="flex"
                justifyContent="center"
              >
                <Typography fontWeight="bold" fontFamily="monospace">
                  Preparando pedido
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                display="flex"
                justifyContent="center"
              >
                <div>
                  <img
                    src={require("../../../assets/gif/preparing_package.gif")}
                    // style={{ width: "70px", height: "120px" }}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          sales.orderStatus === 3 && (
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              sx={{ backgroundColor: "#388e3c", color: "white" }}
              display="flex"
              justifyContent="space-between"
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  display="flex"
                  justifyContent="center"
                >
                  <Typography fontWeight="bold" fontFamily="monospace">
                    Pedido enviado
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  display="flex"
                  justifyContent="center"
                >
                  <div>
                    <img
                      src={require("../../../assets/gif/sending_package.gif")}
                      // style={{ width: "70px", height: "120px" }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          )
        )}
        <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              md={12}
              xl={12}
              display="flex"
              justifyContent="center"
            >
              <Alert severity="success" icon={false}>
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  fontFamily="monospace"
                >
                  #Pedido: {sales.folio}
                </Typography>
              </Alert>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              md={12}
              xl={12}
              display="flex"
              justifyContent="center"
            >
              <Typography variant="h6" fontWeight="bold" fontFamily="inherit">
                Fecha compra:
                {sales.dateFormatted}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              md={12}
              xl={12}
              display="flex"
              justifyContent="center"
            >
              <Alert icon={false} severity="info">
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  fontFamily="monospace"
                >
                  Cliente: {sales.client_id.fullname}
                </Typography>
              </Alert>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              md={12}
              xl={12}
              display="flex"
              justifyContent="center"
            >
              <Typography variant="h6" fontWeight="bold" fontFamily="inherit">
                Total Compra: $ {PriceFormat(sales.total)}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              md={12}
              xl={12}
              display="flex"
              justifyContent="center"
            >
              <Typography variant="h6" fontWeight="bold" fontFamily="inherit">
                Gastos de envio: $ {PriceFormat(sales.shippment)}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              md={12}
              xl={12}
              display="flex"
              justifyContent="center"
            >
              <Typography
                variant="subtitle"
                fontWeight="bold"
                fontFamily="inherit"
              >
                {" "}
                {sales.type === 1 ? (
                  <Alert icon={false} severity="info">
                    Tipo de pago: Tarjeta(Stripe) <CreditCardIcon />
                  </Alert>
                ) : (
                  sales.type === 2 && (
                    <Alert icon={false} severity="warning">
                      Tipo de pago: Transferencia Electronica <LocalAtmIcon />
                    </Alert>
                  )
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="space-between"
              sx={{ margin: 2 }}
            >
              <Link to={`/Detalle_venta/${sales._id}`}>
                <Tooltip title="Detalle" placement="top">
                  <IconButton>
                    <ArticleIcon sx={{ color: "#3f51b5" }} />
                  </IconButton>
                </Tooltip>
              </Link>
              <Tooltip title="PDF" placement="top">
                <IconButton onClick={() => PdfOrder(sales)}>
                  <PictureAsPdfIcon sx={{ color: "red" }} />
                </IconButton>
              </Tooltip>
              {sales.orderStatus === 2 && (
                <Tooltip title="Actualizar costo de envio" placement="top">
                  <IconButton
                    onClick={() => handleOpenModalShippingCost(sales._id)}
                  >
                    <CurrencyExchangeIcon sx={{ color: "#512da8" }} />
                  </IconButton>
                </Tooltip>
              )}
              {sales.orderStatus === 2 ? (
                <>
                  {sales.edition === false ? (
                    <Tooltip title="Habilitar edición" placement="top">
                      <IconButton
                        onClick={() => EnableUpdateSalePayment(sales._id)}
                      >
                        <AddToHomeScreenIcon sx={{ color: "green" }} />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    sales.edition === true && (
                      <Tooltip title="Deshabilitar Edición" placement="top">
                        <IconButton
                          onClick={() => DisableUpdateSalePayment(sales._id)}
                        >
                          <BlockIcon sx={{ color: "red" }} />
                        </IconButton>
                      </Tooltip>
                    )
                  )}
                </>
              ) : (
                <></>
              )}
              {sales.edition === true ? (
                <Link to={`/Editar_venta/${sales._id}`}>
                  <Tooltip title="Editar" placement="top">
                    <IconButton>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </Link>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {idOrder !== null && (
        <ModalShippingCost
          open={modalShippingCost}
          handleClose={handleCloseModalShippingCost}
          id={idOrder}
        />
      )}
    </Card>
  );
};

export default SalesCard;
