import React, { useReducer } from "react";
import Swal from "sweetalert2";
import MethodGet, {
  MethodDelete,
  MethodPost,
  MethodPut,
} from "../../config/Service";
import {
  ADD_FAQS,
  CURRENT_FAQ,
  DELETE_FAQS,
  GET_ALL_FAQS,
  SHOW_ERRORS_API,
  UPDATE_FAQS,
} from "../../types";
import FaqsReducer from "./FaqsReducer";
import FaqsContext from "./FaqsContext";

const FaqsState = ({ children }) => {
  //estado inicial
  const initialState = {
    faqs: [],
    faq: null,
    ErrorsApi: [],
    success: false,
  };
  const [state, dispatch] = useReducer(FaqsReducer, initialState);

  //obtener la lista de faqs
  const GetFaqs = () => {
    sessionStorage.removeItem("faq");
    let url = `/faq/`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_FAQS,
          payload: res.data.faqs,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
      });
  };

  //agregar nuevo faq
  const AddFaqs = (faq) => {
    const formData = new FormData();
    formData.append("question", faq.question);
    formData.append("answer", faq.answer);
    formData.append("category", faq.category);

    let url = `/faq/`;
    MethodPost(url, formData)
      .then((res) => {
        Swal.fire({
          title: "Pregunta registrada",
          text: "La pregunta se registro correctamente",
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });
        dispatch({
          type: ADD_FAQS,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.res.data.error,
          icon: "error",
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.res.data.error,
        });
      });
  };

  //funcion para editar la pregunta
  const UpdateFaqs = (faq) => {
    const formData = new FormData();
    formData.append("question", faq.question);
    formData.append("answer", faq.answer);
    formData.append("category", faq.category);

    let url = `/faq/${faq.id}`;
    MethodPut(url, formData)
      .then((res) => {
        Swal.fire({
          title: "Pregunta Actualizada",
          text: res.data.message,
          timer: 2500,
          showConfirmButton: false,
          icon: "success",
        });
        dispatch({
          type: UPDATE_FAQS,
        });
      })
      .catch((error) => {
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.res.data.error,
        });
      });
  };

  //eliminar pregunta
  const DeleteFaqs = (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "La pregunta seleccionada, sera eliminada",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/faq/${id}`;
        MethodDelete(url).then((res) => {
          Swal.fire({
            title: "Eliminado",
            text: res.data.message,
            icon: "success",
            timer: 2500,
            showConfirmButton: false,
          });
          dispatch({
            type: DELETE_FAQS,
            payload: id,
          });
        });
      }
    });
  };

  //funcion para seleccionar un faq
  const SaveCurrentFaq = (faq) => {
    sessionStorage.setItem("faq", JSON.stringify(faq));
    dispatch({
      type: CURRENT_FAQ,
      payload: faq,
    });
  };

  return (
    <FaqsContext.Provider
      value={{
        faqs: state.faqs,
        faq: state.faq,
        ErrorsApi: state.ErrorsApi,
        success: state.success,
        GetFaqs,
        AddFaqs,
        UpdateFaqs,
        DeleteFaqs,
        SaveCurrentFaq,
      }}>
      {children}
    </FaqsContext.Provider>
  );
};

export default FaqsState;
