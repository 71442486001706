import { useReducer } from "react";
import Swal from "sweetalert2";
import MethodGet, {
  MethodDelete,
  MethodPost,
  MethodPut,
} from "../../config/Service";
import {
  ADD_SUBCATEGORIES,
  CURRENT_SUBCATEGORIES,
  DELETE_SUBCATEGORIES,
  GET_ALL_SUBCATEGORIES,
  GET_ALL_SUBCATEGORIES_CATEGORY,
  IMPORT_SUBCATEGORIES_EXCEL,
  IMPORT_SUBCATEGORIES_EXCEL_ERROR,
  SHOW_ERRORS_API,
  SUBCATEGORIES_NO_PAGINATE,
  UPDATE_SUBCATEGORIES,
} from "../../types";
import SubcategoriesReducer from "./SubCategoriesReducer";
import SubcategoriesContext from "./SubcategoriesContext";
import fileDownload from "js-file-download";
import headerConfig from "../../config/imageHeaders";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const SubcategoriesState = ({ children }) => {
  //estado inicial

  const initialState = {
    subcategories: [],
    subcategories_no_paginate: [],
    subcategoriesCategory: [],
    subcategory: null,
    ErrorsApi: [],
    success: false,
  };

  //dispatch que ejecuta las acciones
  const [state, dispatch] = useReducer(SubcategoriesReducer, initialState);

  //obtener la lista de subcategorias

  const GetSubcategories = (params) => {
    sessionStorage.removeItem("subcategory");

    let url = `/subcategories?limit=${params.limite}&page=${params.pages}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_SUBCATEGORIES,
          payload: {
            subcategories: res.data.subcategories,
            totalDocs: res.data.totalDocs,
            limit: res.data.limit,
            page: res.data.page,
            paginCounter: res.data.paginCounter,
            hasPrevPage: res.data.hasPrevPage,
            hasNextPage: res.data.hasNextPage,
            prevPage: res.data.prevPage,
            nextPage: res.data.nextPage,
            totalPages: res.data.totalPages,
          },
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
      });
  };

  const GetSubcategoriesNoPaginate = () => {
    sessionStorage.removeItem("subcategory");

    let url = `/subcategories/index/no-pagination`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: SUBCATEGORIES_NO_PAGINATE,
          payload: res.data.subcategories,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
      });
  };

  const GetSubcategoriesOfCategory = (id) => {
    let url = `/subcategories/category-products/${id}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_SUBCATEGORIES_CATEGORY,
          payload: res.data.subcategories,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          timer: 2000,
          icon: "error",
          showConfirmButton: false,
        });
      });
  };

  const AddSubcategories = (subcategory) => {
    const formData = new FormData();
    formData.append("name", subcategory.name);
    formData.append("category", subcategory.category);
    let url = "/subcategories/";
    MethodPost(url, subcategory)
      .then((res) => {
        Swal.fire({
          title: "subcategoria Creada",
          text: res.data.message,
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
          showCancelButton: false,
        });
        dispatch({
          type: ADD_SUBCATEGORIES,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.res.data.error,
        });
      });
  };

  //funcion para editar subcategorias
  const UpdateSubcategories = (subcategory) => {
    const formData = new FormData();

    formData.append("name", subcategory.name);
    formData.append("category", subcategory.category);
    let url = `/subcategories/${subcategory.id}`;

    MethodPut(url, subcategory)
      .then((res) => {
        Swal.fire({
          title: "Subcategoria editada",
          text: res.data.message,
          timer: 3000,
          showConfirmButton: false,
          icon: "success",
        });
        dispatch({
          type: UPDATE_SUBCATEGORIES,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          timer: 2000,
          showConfirmButton: false,
          icon: "error",
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.res.data.error,
        });
      });
  };

  //eliminar subcategoria
  const DeleteSubcategory = (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "La Subcategoria sera eliminada",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/subcategories/${id}`;

        MethodDelete(url)
          .then((res) => {
            Swal.fire({
              title: "Eliminado",
              text: res.data.message,
              icon: "success",
              timer: 2000,
              showConfirmButton: false,
            });

            dispatch({
              type: DELETE_SUBCATEGORIES,
              payload: id,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              timer: 1500,
              showConfirmButton: false,
            });
          });
      }
    });
  };

  //funcion para descargar actualizar por excel
  const DowloadUpdateExcelFile = () => {
    Swal.fire({
      title: "Descargar plantilla",
      text: "Se descargará la plantilla para actualizar subcategorias",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, descargar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = "/subcategories/export/excel";
        MethodGet(url, { responseType: "blob" })
          .then((res) => {
            fileDownload(res.data, "plantilla_actualizar_subcategorias.xlsx");
            Swal.fire({
              title: "Descargado",
              text: "La plantilla para actualizar subcategorias se ha descargado correctamente",
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
            });
            dispatch({
              type: SHOW_ERRORS_API,
            });
          });
      }
    });
  };

  const ImportSubcategoriesExcel = (subcategories) => {
    const formData = new FormData();
    formData.append("excel", subcategories.file);
    let url = "/subcategories/update/excel";
    MethodPut(url, formData, { headerConfig })
      .then((res) => {
        toast.success("Subcategorias Actualizadas Exitosamente!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        dispatch({
          type: IMPORT_SUBCATEGORIES_EXCEL,
          //payload: res.data.products,
        });
      })
      .catch((error) => {
        error.response.data.errors.map((response) =>
          toast.error(
            <div>
              <p>Error: {response.message} </p>
            </div>,
            {
              position: "top-right",
              autoClose: false,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "colored",
            }
          )
        );
        dispatch({
          type: IMPORT_SUBCATEGORIES_EXCEL_ERROR,
        });
      });
  };

  //funcion para seleccionar una subcategoria
  const SaveCurrentSubcategory = (subcategory) => {
    sessionStorage.setItem("subcategory", JSON.stringify(subcategory));
    dispatch({
      type: CURRENT_SUBCATEGORIES,
      payload: subcategory,
    });
  };

  return (
    <SubcategoriesContext.Provider
      value={{
        subcategories: state.subcategories,
        subcategoriesCategory: state.subcategoriesCategory,
        subcategory: state.subcategory,
        ErrorsApi: state.ErrorsApi,
        success: state.success,
        totalDocs: state.totalDocs,
        limit: state.limit,
        page: state.page,
        paginCounter: state.paginCounter,
        hasPrevPage: state.hasPrevPage,
        prevPage: state.prevPage,
        hasNextPage: state.hasNextPage,
        nextPage: state.nextPage,
        loading: state.loading,
        totalPages: state.totalPages,
        subcategories_no_paginate: state.subcategories_no_paginate,
        GetSubcategories,
        GetSubcategoriesOfCategory,
        AddSubcategories,
        UpdateSubcategories,
        DeleteSubcategory,
        SaveCurrentSubcategory,
        DowloadUpdateExcelFile,
        ImportSubcategoriesExcel,
        GetSubcategoriesNoPaginate,
      }}
    >
      {children}
    </SubcategoriesContext.Provider>
  );
};

export default SubcategoriesState;
