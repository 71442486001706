import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, Stack, TextField } from "@mui/material";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import ProductsContext from "../../../context/Products/ProductsContext";
import { useContext } from "react";
///datepicker
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import esLocale from "date-fns/locale/es";
import { useForm } from "react-hook-form";
/**new pickers */
import { formatDateNow, formatDateSelect } from "../../../utils/FormatDate";
import { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
const useStyles = makeStyles({
  card: {
    marginTop: 7,
    marginLeft: 7,
    marginRight: 7,
  },
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 10,
  },
});
export default function ModalMovements(props) {
  const { modal, open, handleClickClose } = props;
  //const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const valueDesktopDatePicker = new Date();
  const [valueDesktopDatePickerSelected, setValueDesktopDatePickerSelected] =
    useState(new Date());
  const [pickerFecini, SetpickerIni] = useState(null);
  const [pickerFecter, SetpickerTer] = useState(null);
  //datepicker
  const [fecha_inicio, setFechaInicio] = useState("");
  const [fecha_fin, setFechaFin] = useState("");
  const MINDATENOW = formatDateNow(valueDesktopDatePickerSelected);
  const { PdfMovements } = useContext(ProductsContext);
  /* Mi intento de Fecha chido */

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  //datepicker
  // const [fecha_inicio, setFechaInicio] = useState("");
  // const [fecha_fin, setFechaFin] = useState("");

  const onSubmit = (data, e) => {
    data.initialDate = formatDateSelect(fecha_inicio);
    data.finishDate = formatDateSelect(fecha_fin);
    PdfMovements(data);
    handleClickClose();
  };
  return (
    <div>
      <Dialog
        open={modal}
        onClose={handleClickClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Reporte de movimientos de stock"}
        </DialogTitle>
        <DialogContent sx={{ marginTop: 3 }}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter")
                e.preventDefault();
            }}
            autoComplete="off"
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ marginTop: 2 }}
            >
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                locale={esLocale}
              >
                <Stack spacing={3}>
                  <MobileDatePicker
                    label="Desde la fecha"
                    name="end_date"
                    todayText="Hoy"
                    showTodayButton={true}
                    disableFuture
                    okText="Aceptar"
                    cancelText="Cancelar"
                    value={pickerFecini}
                    onChange={(newValue) => {
                      SetpickerIni(newValue);
                      setFechaInicio(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={errors.start_date ? true : false}
                        helperText={errors?.start_date?.message}
                        {...register("start_date", {
                          required: {
                            value: true,
                            message: "Este campo es requerido",
                          },
                        })}
                      />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 2 }}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                locale={esLocale}
              >
                <Stack spacing={3}>
                  <MobileDatePicker
                    label="Hasta la fecha"
                    name="end_date"
                    todayText="Hoy"
                    showTodayButton={true}
                    disableFuture
                    okText="Aceptar"
                    cancelText="Cancelar"
                    value={pickerFecter}
                    onChange={(newValue) => {
                      SetpickerTer(newValue);
                      setFechaFin(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={errors.end_date ? true : false}
                        helperText={errors?.end_date?.message}
                        {...register("end_date", {
                          required: {
                            value: true,
                            message: "Este campo es requerido",
                          },
                        })}
                      />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 2 }}>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#01579b",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#01579b",
                  },
                }}
              >
                Generar Reporte
              </Button>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
