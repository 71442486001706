import { Button, Grid, TextField, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SelectProviders from "../SelectOptions/SelectProviders";
import Layout from "./../../components/layout/Layout";
import TypeInvoice from "../SelectOptions/TypeInvoice";
import { useForm } from "react-hook-form";
import MethodGet from "../../config/Service";
import SpinnerComponent from "../../components/loading/SpinnerComponent";
import AccountsForPaymentContext from "../../context/AccountsForPayment/AccountsForPaymentContext";
import { useContext } from "react";
const UpdateAcount = (props) => {
  const accountId = props.match.params.accountId;
  const providerId = props.match.params.providerId;
  const [provider, saveProvider] = useState();
  const detectarCambiosProvider = (e) => {
    saveProvider(e);
  };
  const [typeInvoice, saveTypeInvoice] = useState();
  const detectarCambiosPaymentsInvoice = (e) => {
    saveTypeInvoice(e);
  };
  const [account, saveAccount] = useState();
  const [cargando, spinnerCargando] = useState(false);
  useEffect(() => {
    let url = `/accounts-to-pay/${accountId}`;
    MethodGet(url)
      .then((res) => saveAccount(res.data.account), spinnerCargando(true))
      .catch((error) => console.log(error), spinnerCargando(true));
  }, [accountId]);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const reset = () => {
    setValue("folio_invoice", "", { shouldDirty: true });
    setValue("amount", "", { shouldDirty: true });
    saveProvider("");
    saveTypeInvoice("");
  };
  const { UpdateAccountPayment } = useContext(AccountsForPaymentContext);
  const onSubmit = (data, e) => {
    data.account_id = accountId;
    data.provider_id = providerId;
    data.type_invoice = typeInvoice;
    //console.log(data, "la data al actualizar");
    UpdateAccountPayment(data);
    reset();
    props.history.goBack();
  };
  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ backgroundColor: "black", color: "white" }}>
          <Typography fontWeight="bold" fontSize="35px">
            Actualizar cuenta
          </Typography>
        </Grid>
      </Grid>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.code === "Enter" || e.code === "NumpadEnter")
            e.preventDefault();
        }}>
        {!account ? (
          <SpinnerComponent />
        ) : (
          <Grid container spacing={2}>
            <Grid container spacing={2} sx={{ margin: 2 }}>
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <label>Proveedores: </label>
                <SelectProviders
                  detectarCambiosProvider={detectarCambiosProvider}
                  provider_id={providerId}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <label>Folio de factura</label>
                <TextField
                  //label="Folio de factura"
                  fullWidth
                  name="folio_invoice"
                  type="text"
                  defaultValue={account.NoFolio}
                  error={errors.folio_invoice ? true : false}
                  helperText={errors?.folio_invoice?.message}
                  {...register("folio_invoice", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <label>Tipo de factura: </label>
                <TypeInvoice
                  detectarCambiosPaymentsInvoice={
                    detectarCambiosPaymentsInvoice
                  }
                  typeInvoice={account.typeInvoice}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <label>Monto: </label>
                <TextField
                  //label="Folio de factura"
                  fullWidth
                  name="amount"
                  type="number"
                  defaultValue={account.amount}
                  error={errors.amount ? true : false}
                  helperText={errors?.amount?.message}
                  {...register("amount", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                  })}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                display="flex"
                justifyContent="center">
                <Button size="large" variant="contained" type="submit">
                  Actualizar cuenta
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </form>
    </Layout>
  );
};

export default UpdateAcount;
