import { Button, Grid, TextField, Typography } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Layout from "../../components/layout/Layout";
import MethodGet from "../../config/Service";
import PoliciesContext from "../../context/Policies/PoliciesContext";
import SelectTypeTerms from "../SelectOptions/SelectTypeTerms";
import SpinnerComponent from "../../components/loading/SpinnerComponent";
const UpdatePolicies = (props) => {
  const id = props.match.params.id;
  const [policie, savePolicie] = useState();
  const [typeTerms, saveTypeTerm] = useState();

  useEffect(() => {
    let url = `/policies/${id}`;
    MethodGet(url)
      .then((res) => {
        savePolicie(res.data.policie);
        saveTypeTerm(res.data.policie.type);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  const [RichText, setRichText] = useState();
  const handleEditorChange = (e) => {
    setRichText(e.target.getContent());
  };

  const detectarCambiosTypeTerms = (e) => {
    saveTypeTerm(e);
  };
  const { UpdatePolicies } = useContext(PoliciesContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const onSubmit = (data, e) => {
    data.text = RichText;
    data.type = typeTerms;
    data.id = id;
    // console.log(data, "la data");
    UpdatePolicies(data);
    props.history.goBack();
  };
  return (
    <Layout>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.code === "Enter" || e.code === "NumpadEnter")
            e.preventDefault();
        }}
        autoComplete="off">
        {policie && typeTerms ? (
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ backgroundColor: "black", color: "white" }}>
              <Typography variant="h4" fontWeight="bold">
                Editar Politicas de privacidad
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ margin: 2 }}>
              <SelectTypeTerms
                detectarCambiosTypeTerms={detectarCambiosTypeTerms}
                typeTermId={typeTerms}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ margin: 2 }}>
              <TextField
                label="Titulo"
                name="title"
                fullWidth
                defaultValue={policie.title}
                error={errors.title ? true : false}
                helperText={errors?.title?.message}
                {...register("title", {
                  required: {
                    value: true,
                    message: "El titulo es un campo requerido",
                  },
                })}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ margin: 2 }}>
              <Editor
                initialValue={policie.text}
                apiKey="4oemglfeurpxtmylg59yopah4kn16o7e3ma4ryvm44spbnlr"
                onChange={handleEditorChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ marginLeft: 2 }}
              display="flex"
              justifyContent="start">
              <Button variant="contained" size="large" type="submit">
                Guardar
              </Button>
            </Grid>
          </Grid>
        ) : (
          <SpinnerComponent />
        )}
      </form>
    </Layout>
  );
};

export default UpdatePolicies;
