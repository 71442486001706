import React, { Fragment } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import ClientsContext from "../../context/Clients/ClientsContext";
import Select from "react-select";
export default function SelectClient(props) {
  const { clients, GetClientsNoPagination, success } =
    useContext(ClientsContext);

  useEffect(() => {
    GetClientsNoPagination();
  }, [success]);

  const detectarCambiosClient = (value) => {
    props.detectarCambiosClient(value);
  };
  return (
    <Fragment>
      <Select
        onChange={(value) => detectarCambiosClient(value)}
        className="basic-single"
        classNamePrefix="select"
        name="client"
        placeholder="Selecciona cliente"
        options={clients.map((client) => {
          let attribute = {
            label: client?.fullname,
            email: client?.email,
            value: client?.id,
            typeClient: client?.customer_type ? client?.customer_type : null,
          };
          return attribute;
        })}
      />
    </Fragment>
  );
}
