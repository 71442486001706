import { Button, Grid, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import ProductSelectReactSelect from "../../../containers/SelectOptions/ProductSelectReactSelect";
import CardProductCotizador from "./CardProductCotizador";
import ProductsList from "./ProductsList";
import DashboardContext from "../../../context/Dashboard/DashboardContext";
import Swal from "sweetalert2";
import AuthContext from "../../../context/auth/authContext";
import SelectDirectionsUser from "./SelectDirections";
export default function TableUserDistribuidor({ id_client }) {
  const [product, saveProduct] = useState("");
  const detectarCambiosProductSelect = (value) => {
    saveProduct(value.value);
  };

  const [direction, saveDirection] = useState(null);
  const detectarCambiosDirection = (value) => {
    saveDirection(value.value);
  };
  //lista de productos
  const [productList, saveProductList] = useState([]);
  const final_prices = productList.map((p) => p.final_price);
  const total = 0;
  const totalCotization = final_prices.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    total
  );

  const { ExportExcelCotizacion } = useContext(DashboardContext);
  const { register, handleSubmit } = useForm();
  const onSubmit = (data, e) => {
    if (direction === null) {
      Swal.fire({
        title: "Error",
        text: "Selecciona una direccion para continuar",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
      return;
    }
    if (ProductsList.length < 1) {
      Swal.fire({
        title: "Error",
        text: "Para generar una cotización primero debes agregar productos",
        icon: "error",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    } else {
      data.products = productList.map((p) => {
        let attribute = {
          product_id: p.product_id,
          quantity: p.cantidad,
        };
        return attribute;
      });
      data.customer_id = id_client;
      data.address_id = direction;
      ExportExcelCotizacion(data);
    }
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          xs={6}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          display="flex"
          justifyContent="center"
        >
          <Typography variant="h6">Cotizador</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <ProductSelectReactSelect
            detectarCambiosProductSelect={detectarCambiosProductSelect}
          />
          {product && (
            <CardProductCotizador
              id={product}
              productList={productList}
              saveProductList={saveProductList}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ marginTop: -4 }}>
          <Typography align="center" variant="h5" fontSize="bold">
            Productos cotizados
          </Typography>
          {productList.length > 0 && (
            <ProductsList
              productList={productList}
              saveProductList={saveProductList}
              total={totalCotization}
            />
          )}
          <Grid container spacing={2} sx={{ marginTop: 1 }}>
            {productList.length > 0 && (
              <Grid item xs={12}>
                <SelectDirectionsUser
                  detectarCambiosDirection={detectarCambiosDirection}
                  id_client={id_client}
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}
            >
              <form
                onSubmit={handleSubmit(onSubmit)}
                onKeyDown={(e) => {
                  if (e.code === "Enter") e.preventDefault();
                }}
                autoComplete="off"
              >
                {productList.length >= 1 && (
                  <Button
                    type="submit"
                    sx={{
                      backgroundColor: "#ad1457",
                      color: "white",
                      "&:hover": { backgroundColor: "#ad1457", color: "white" },
                    }}
                  >
                    Generar Cotizacion
                  </Button>
                )}
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
