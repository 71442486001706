import React, { useState } from "react";
import PermissionsContext from "../../context/Permissions/PermissionsContext";
import CardMenu from "./CardMenu";
import { useContext } from "react";
import { useEffect } from "react";
import { Grid, TextField } from "@mui/material";
import Layout from "./Layout";
import { useDebounce } from "use-debounce";
import Swal from "sweetalert2";
import NoDataComponents from "../loading/NoDataComponents";
import SearchingComponent from "../loading/SearchingComponent";
import NoResultsComponent from "../loading/NoResultsComponent";
import UserLoged from "../Dashboard/UserLoged";
const Menu = () => {
  const { permissionsUser, success, GetPermissionsUser } =
    useContext(PermissionsContext);
  //Funcionalidad del filtrado
  const [magicWord, setMagicWord] = useState("");
  const [debounceWord] = useDebounce(magicWord, 800);
  const [searchModule, saveSearchModule] = useState([]);
  const handleSearchModule = () => {
    try {
      let searchModule = permissionsUser.filter((modules) =>
        modules.name
          .toString()
          .toLowerCase()
          .includes(debounceWord.toLocaleLowerCase())
      );
      saveSearchModule(searchModule);
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "No se econtraron resultados, con los criterios de busqueda",
        icon: "error",
      });
    }
  };
  useEffect(() => {
    GetPermissionsUser();
  }, [success]);
  useEffect(() => {
    saveSearchModule(permissionsUser);
  }, []);
  useEffect(() => {
    handleSearchModule();
  }, [debounceWord]);

  return (
    <Layout>
      <div>
        <Grid container spacing={2} sx={{ padding: 2 }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <UserLoged />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              fullWidth
              label="Buscar Modulo"
              type="text"
              onChange={(e) => {
                setMagicWord(e.target.value);
                saveSearchModule(null);
              }}
            />
          </Grid>
          {!magicWord &&
            (!permissionsUser.length ? (
              <NoDataComponents />
            ) : (
              <>
                {permissionsUser.length !== 0 &&
                  permissionsUser.map((dropdown) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                      <CardMenu data={dropdown} />
                    </Grid>
                  ))}
              </>
            ))}
          {magicWord &&
            (!searchModule ? (
              <SearchingComponent />
            ) : searchModule.length === 0 ? (
              <NoResultsComponent />
            ) : (
              <>
                {searchModule.map((dropdown) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <CardMenu data={dropdown} />
                  </Grid>
                ))}
              </>
            ))}
        </Grid>
      </div>
    </Layout>
  );
};

export default Menu;
