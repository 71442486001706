import { Grid, Typography, Divider, Chip } from "@mui/material";
import PersonPinIcon from "@mui/icons-material/PersonPin";

export const ClientInfo = ({ fullname, email, phone }) => {
	return (
		<>
			<Divider>
				<Chip
					sx={{ fontWeight: "bold", fontSize: 18 }}
					label="Información del Cliente"
					icon={<PersonPinIcon />}
				/>
			</Divider>
			<Grid container sx={{ marginY: 2, textAlign: "center" }}>
				<Grid
					item
					xs={12}
					sm={12}
					md={4}
					lg={4}
					xl={4}
					sx={{ marginBottom: 1 }}
				>
					<Typography>
						<b>Nombre: </b>
						{fullname ? fullname : "Cliente borrado"}
					</Typography>
				</Grid>
				<Grid
					item
					xs={12}
					sm={12}
					md={4}
					lg={4}
					xl={4}
					sx={{ marginBottom: 1 }}
				>
					<Typography>
						<b>Telefono: </b>
						{phone ? phone : "S/N"}
					</Typography>
				</Grid>
				<Grid
					item
					xs={12}
					sm={12}
					md={4}
					lg={4}
					xl={4}
					sx={{ marginBottom: 1 }}
				>
					<Typography>
						<b>Correo: </b>
						{email ? email : "S/correo"}
					</Typography>
				</Grid>
			</Grid>
		</>
	);
};
