import React from "react";
import {
  COUNT_SHIPPED,
  EXPORTEXCELCOTIZATION,
  GENERATE_COTIZACION,
  GET_COTIZATIONS,
  INCOME_OF_DAY,
  INCOME_OF_MONTH,
  INCOME_OF_WEEK,
  PRODUCTS_CATALOGUE,
  PRODUCTS_CATALOGUE_NO_PAGINATION,
  SALES_OF_DAY,
  SALES_OF_MONTH,
  SALES_OF_WEEK,
} from "../../types";

export default function DashboardReducer(state, action) {
  switch (action.type) {
    case SALES_OF_DAY:
      return {
        ...state,
        sales_of_day: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case SALES_OF_WEEK:
      return {
        ...state,
        sales_of_week: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case SALES_OF_MONTH:
      return {
        ...state,
        sales_of_month: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case INCOME_OF_DAY:
      return {
        ...state,
        income_of_day: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case INCOME_OF_WEEK:
      return {
        ...state,
        income_of_week: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case COUNT_SHIPPED:
      return {
        ...state,
        count_shipped: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case INCOME_OF_MONTH:
      return {
        ...state,
        income_of_month: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case PRODUCTS_CATALOGUE:
      return {
        ...state,
        products_catalogue: action.payload.products_catalogue,
        totalDocs: action.payload.totalDocs,
        limit: action.payload.limit,
        page: action.payload.page,
        paginCounter: action.payload.paginCounter,
        hasPrevPage: action.payload.hasPrevPage,
        hasNextPage: action.payload.hasNextPage,
        prevPage: action.payload.prevPage,
        nextPage: action.payload.nextPage,
        success: false,
        ErrorsApi: [],
      };
    case PRODUCTS_CATALOGUE_NO_PAGINATION:
      return {
        ...state,
        products_catalogue_no_pagination: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case GET_COTIZATIONS:
      return {
        ...state,
        cotizations: action.payload.cotizations,
        totalDocs: action.payload.totalDocs,
        limit: action.payload.limit,
        page: action.payload.page,
        paginCounter: action.payload.paginCounter,
        hasPrevPage: action.payload.hasPrevPage,
        hasNextPage: action.payload.hasNextPage,
        prevPage: action.payload.prevPage,
        nextPage: action.payload.nextPage,
        success: false,
        ErrorsApi: [],
      };
    case EXPORTEXCELCOTIZATION:
      return {
        ...state,
        cotizations: [action.payload, ...state.cotizations],
        ErrorsApi: [],
      };
    default:
      return state;
  }
}
