import * as React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import Pending from "./Pending";
import Processing from "./Processing";
import Seending from "./Seending";
import Disputed from "./Disputed";
import Layout from "../../components/layout/Layout";
import { makeStyles } from "@mui/styles";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
const useStyles = makeStyles({
  titleCard: {
    color: "white",
    fontSize: "35px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
});

export default function IndexWarehouse() {
  const theme = useTheme();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Layout>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "Center",
              alignItems: "center",
              backgroundColor: "#000000",
              textAling: "center",
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            }}
          >
            <div className={classes.titleCard}>Mi Almacen </div>
          </Box>
        </Grid>
      </Grid>
      <Grid container sx={{ padding: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box sx={{ bgcolor: "background.paper", width: "100%" }}>
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                //indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
                sx={{ backgroundColor: "black" }}
              >
                <Tab label="Pedidos Pendientes" {...a11yProps(0)} />
                <Tab
                  label="Pedidos en proceso de empaquetado"
                  {...a11yProps(1)}
                />
                <Tab label="Pedidos en proceso de disputa" {...a11yProps(2)} />
                <Tab label="Pedidos Enviados" {...a11yProps(3)} />
              </Tabs>
            </AppBar>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <Pending />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <Processing />
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <Disputed />
            </TabPanel>
            <TabPanel value={value} index={3} dir={theme.direction}>
              <Seending />
            </TabPanel>
          </SwipeableViews>
        </Grid>
      </Grid>
    </Layout>
  );
}
