import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import { Button, Grid, Typography } from "@mui/material";
import MethodGet from "../../config/Service";
import TableProducts from "./TableProducts";
import { useHistory } from "react-router-dom";

const ShowCotization = (props) => {
  const { id } = props.match.params;
  const [cotization, saveCotization] = useState(null);
  const [cargando, spinnerCargando] = useState(false);
  const { history } = useHistory();
  useEffect(() => {
    let url = `/cotizations/${id}`;
    MethodGet(url)
      .then((res) => {
        saveCotization(res.data.cotization);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
        spinnerCargando(true);
      });
  }, [id]);

  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: 2,
            marginTop: 2,
          }}
        >
          <Button
            variant="outlined"
            size="large"
            onClick={() => props.history.goBack()}
          >
            Regresar
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography fontWeight="bold" textAlign="center" fontSize="25px">
            Detalle Cotizacion
          </Typography>
        </Grid>
      </Grid>
      {cotization && (
        <Grid container spacing={2} sx={{ padding: 2 }}>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            Cliente:{cotization.customer.fullname}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            Telefono:{cotization.direction.phone_number}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            Dirección:{cotization.direction.street}
            {", "}
            {"#" + cotization.direction.no_ext}
            {", "}
            {cotization.direction.colony}
            {", "}
            {cotization.direction.postalcode}
            {", "}
            {cotization.direction.municipality.name}
            {", "}
            {cotization.direction.state.name}
          </Grid>
        </Grid>
      )}
      {cotization && (
        <Grid container spacing={2} sx={{ padding: 2 }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TableProducts products={cotization.products} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Typography>Subtotal: ${cotization.subtotal}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Typography>
              Descuento:
              {cotization.businessRules
                ? cotization.businessRules.discount
                : 0}{" "}
              %
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Typography>Gasto Envio: $ {cotization.shipping_cost}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Typography>Total: ${cotization.total}</Typography>
          </Grid>
        </Grid>
      )}
    </Layout>
  );
};

export default ShowCotization;
