import { PhotoCamera } from "@mui/icons-material";
import {
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import AccountsSelect from "../../containers/SelectOptions/AccountsSelect";
const useStyles = makeStyles({
  img: {
    width: 250,
    height: 250,
  },
  logoimagen: {
    height: "200px",
    //boxShadow: "5px 5px #289FED",
    objectFit: "cover",
  },
  jss59: {
    width: "100%",
    border: "1px solid #bbb",
    height: "15%",
    outline: "none",
    padding: "15px 10px",
    // marginLeft: "10px",
    borderRadius: "10px",
    //marginTop: 10,
  },
  input: {
    display: "none",
  },
});
export default function InputTransfer({
  detectarCambiosAccount,
  handleChangePaymentTransfer,
  handleChangeImageTransfer,
  imageTransfer,
  paymentTrasfer,
}) {
  const classes = useStyles();
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        paddingTop: 1,
        p: 1,
        marginBottom: 1,
      }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Divider sx={{ marginBottom: 1 }}>
            <Chip
              label={
                <Typography sx={{ fontWeight: "bold" }}>
                  Transferencia Electronica
                </Typography>
              }
            />
          </Divider>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <label>Selecciona una cuenta bancaria: </label>
            <AccountsSelect detectarCambiosAccount={detectarCambiosAccount} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <label>Ingresa Monto (Transferencia)</label>
            <TextField
              onChange={handleChangePaymentTransfer}
              defaultValue={paymentTrasfer}
              fullWidth
              id="outlined-number"
              label="Monto "
              placeholder="Cantidad a pagar"
              type="text"
              name="amount_transfer"
              min="0"
              // error={errors.amount ? true : false}
              // helperText={errors?.amount?.message}
              // {...register("amount", {
              //   required: {
              //     value: true,
              //     message: "Este campo es requerido",
              //   },
              // })}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <>
              <Grid>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                  <div>
                    <img
                      src={imageTransfer.urlPhotoTransfer}
                      className={classes.logoimagen}
                      alt="Comprobante transferencia"
                    />
                  </div>
                </Box>
              </Grid>
              <Grid>
                <input
                  className={classes.input}
                  id="icon-button-file-transfer"
                  type="file"
                  name="image"
                  accept="image/png, image/jpg, image/jpeg, image/webp"
                  onChange={handleChangeImageTransfer}
                />
                <InputLabel sx={{ textAlign: "center" }}>
                  Sube tu comprobante :
                  <label htmlFor="icon-button-file-transfer">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span">
                      <Tooltip
                        title="seleccionar imagen"
                        aria-label="seleccionar imagen">
                        <PhotoCamera />
                      </Tooltip>
                    </IconButton>
                  </label>
                </InputLabel>
              </Grid>
            </>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
