import React from "react";
import {
  ADD_PAYMENT_TO_ACCOUNT,
  GET_ACCOUNTS_FOR_PAYMENT,
  GET_ACCOUNTS_PROVIDER,
  UPDATE_ACCOUNTS_FOR_PAYMENT,
} from "../../types";
import { ADD_ACCOUNTS_FOR_PAYMENT } from "../../types/index";

export default function AccountsForPaymentReducer(state, action) {
  switch (action.type) {
    case GET_ACCOUNTS_FOR_PAYMENT:
      return {
        ...state,
        accountsPayment: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case GET_ACCOUNTS_PROVIDER:
      return {
        ...state,
        accountsProvider: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case ADD_ACCOUNTS_FOR_PAYMENT:
      return {
        ...state,
        accountsPayment: state.accountsPayment.map((acount) =>
          acount._id === action.payload._id ? { ...action.payload } : acount
        ),
        success: false,
        ErrorsApi: [],
      };
    case UPDATE_ACCOUNTS_FOR_PAYMENT:
      return {
        ...state,
        accountsProvider: state.accountsProvider.map((acount) =>
          acount._id === action.payload._id ? { ...action.payload } : acount
        ),
        success: false,
        ErrorsApi: [],
      };
    case ADD_PAYMENT_TO_ACCOUNT:
      return {
        ...state,
        accountsProvider: state.accountsProvider.map((acount) =>
          acount._id === action.payload._id ? { ...action.payload } : acount
        ),
        success: false,
        ErrorsApi: [],
      };
    default:
      return state;
  }
}
