import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Grid, IconButton, Pagination, Stack, Tooltip } from "@mui/material";

import ReceiptIcon from "@mui/icons-material/Receipt";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import ModalFiscalData from "./ModalFiscalData";
import invoicesContext from "../../../context/Invoices/InvoicesContext";
import { PriceFormat } from "../../../utils/FormatPrice";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function TableSalesForInvoice({ orders }) {
  const { OrderInvoice } = React.useContext(invoicesContext);
  /**modal datos fiscales */
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [idClient, setIdClient] = React.useState();
  const openModal = (id) => {
    console.log(id);
    setIdClient(id);
    handleClickOpen();
  };
  /**termina modal */
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Folio</StyledTableCell>
              <StyledTableCell align="center">Cliente</StyledTableCell>
              <StyledTableCell align="center">Forma de pago</StyledTableCell>
              <StyledTableCell align="center">Total</StyledTableCell>
              <StyledTableCell align="center">Costo de envio</StyledTableCell>
              <StyledTableCell align="center">Fecha</StyledTableCell>
              <StyledTableCell align="center">Opciones</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders &&
              orders.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell align="center">{row.folio}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row.client_id.fullname}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.type === 1
                      ? "Tarjeta(stripe)"
                      : "Transferencia bancaria"}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    $ {PriceFormat(row.total)}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    $ {PriceFormat(row.shippment)}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.dateFormatted}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Tooltip title="Facturar Venta" placement="top">
                      <IconButton onClick={() => OrderInvoice(row._id)}>
                        <ReceiptIcon sx={{ color: "#2e7d32" }} />
                      </IconButton>
                    </Tooltip>
                    {!row.client_id.SATDirection && (
                      <Tooltip
                        title="Registrar datos fiscales cliente"
                        placement="top">
                        <IconButton onClick={() => openModal(row.client_id.id)}>
                          <FilePresentIcon sx={{ color: "#0d47a1" }} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {idClient && (
        <ModalFiscalData
          open={open}
          handleClose={handleClose}
          idClient={idClient}
        />
      )}
    </>
  );
}
