import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useState } from "react";
import Layout from "../../components/layout/Layout";
import { AppBar, Grid } from "@mui/material";
import Conciliations from "./Conciliations";
import InvoiceGlobal from "./InvoiceGlobal";
import SalesForInvoice from "./salesForInvoice/SalesForInvoice";
import SalesInvoiced from "./SalesInvoiced/SalesInvoiced";
import SalesNotInvoiced from "./SalesNotInvoiced";
import RequestForCancelInvoice from "./RequestForCancelInvoice";
import InvoicesCanceled from "./InvoicesCanceled";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function IndexInvoices() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Layout>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          display="flex"
          justifyContent="center">
          <Box sx={{ bgcolor: "background.paper", width: "100%" }}>
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                //indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
                sx={{ backgroundColor: "black" }}>
                {/* <Tab label="Ventas Por facturar" {...a11yProps(0)} /> */}
                <Tab label="Ventas Facturadas" {...a11yProps(0)} />
                <Tab label="Facturar venta (indiv.)" {...a11yProps(1)} />
                <Tab label="Ventas por facturar (Global)" {...a11yProps(2)} />
                <Tab label="Conciliaciones" {...a11yProps(3)} />
                <Tab
                  label="Solicitud para cancelar factura"
                  {...a11yProps(4)}
                />
                <Tab label="Facturas Canceladas" {...a11yProps(5)} />
              </Tabs>
            </AppBar>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {/* <TabPanel value={value} index={0}>
            <SalesForInvoice />
          </TabPanel> */}
          <TabPanel value={value} index={0}>
            <SalesInvoiced />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <SalesForInvoice />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <InvoiceGlobal />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Conciliations />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <RequestForCancelInvoice />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <InvoicesCanceled />
          </TabPanel>
        </Grid>
      </Grid>
    </Layout>
  );
}
