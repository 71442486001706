import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useContext, useEffect } from "react";
import CanvasVariantsContext from "../../context/canvasVariants/CanvasVariantsContext";

export default function VariantsCanvasSelect(props) {
	const { canvasVariants, GetCanvasVariants } = useContext(
		CanvasVariantsContext
	);

	useEffect(() => {
		GetCanvasVariants();
	}, []);

	const detectarCambiosCanvasVariants = (e) => {
		props.detectarCambiosCanvasVariants(e);
	};
	return (
		<Grid container spacing={2}>
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<FormControl fullWidth>
					<InputLabel id="demo-simple-select-label">Variantes</InputLabel>
					{props.variantId ? (
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							label="Tipo de regla de negocio "
							value={props.variantId}
							onChange={detectarCambiosCanvasVariants}
						>
							{canvasVariants.map((c, index) => (
								<MenuItem key={index} value={c._id}>
									{c.name}
								</MenuItem>
							))}
						</Select>
					) : (
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							label="Tipo de regla de negocio "
							onChange={detectarCambiosCanvasVariants}
						>
							{canvasVariants.map((c, index) => (
								<MenuItem key={index} value={c._id}>
									{c.name}
								</MenuItem>
							))}
						</Select>
					)}
				</FormControl>
			</Grid>
		</Grid>
	);
}
