import { ADD_FAQ_CATEGORIES, CURRENT_FAQ_CATEGORY, DELETE_FAQ_CATEGORIES, GET_ALL_FAQ_CATEGORIES, SHOW_ERRORS_API, UPDATE_FAQ_CATEGORIES } from "../../types";

export default function FaqCategoriesReducer(state, action) {

    switch (action.type) {
        case GET_ALL_FAQ_CATEGORIES:
            return {
                ...state,
                faq_categories: action.payload,
                success: false,
                ErrorsAPI: []
            }

        case ADD_FAQ_CATEGORIES:
            return {
                ...state,
                success: true,
                ErrorsAPI: []
            }

        case SHOW_ERRORS_API:
            return {
                ...state,
                success: true,
                ErrorsAPI: []
            }

        case CURRENT_FAQ_CATEGORY:
            return {
                ...state,
                faq_category: action.payload,
            }

        case UPDATE_FAQ_CATEGORIES:
            return {
                ...state,
                success: true,
                ErrorsAPI: []
            }

        case DELETE_FAQ_CATEGORIES:
            return {
                ...state,
                success: true,
                faq_categories: state.faq_categories.filter(faq_category => faq_category._id !== action.payload)
            }

        default:
            return state;
    }
}
