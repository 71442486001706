import {
  ADD_PRODUCTS,
  ADD_PRODUCTS_EXCEL,
  CREATE_MULTIMEDIA,
  DELETE_PRODUCTS,
  EXPORT_PRODUCTS_EXCEL,
  GET_ALL_PRODUCTS,
  GET_ALL_PRODUCTS_DESACTIVATED,
  GET_PRODUCTS,
  RESTORE_PRODUCT,
  SHOW_ERRORS_API,
  UPDATE_PRODUCTS,
  UPDATE_PRODUCTS_EXCEL,
  UPLOAD_MULTIMEDIA,
  IMPORT_PRODUCTS_EXCEL,
  IMPORT_PRODUCTS_EXCEL_ERROR,
  UPDATE_PRODUCTS_EXCEL_ERROR,
  GET_PRODUCTS_CANVAS_ONLY,
  SEARCH_PRODUCT,
  DELETE_PRODUCT,
  UPDATE_STOCK,
  GET_ALL_MOVEMENTS,
  SEARCH_PRODUCTS_DESACTIVATED,
  SEARCH_PRODUCTS_MOVEMENTS,
  GET_ONE_PRODUCT,
  GET_PRODUCT_DESACTIVATED,
  GET_PRODUCT_ACTIVATED,
  CREATE_ENTER_PRODUCTS,
  GET_ENTER_PRODUCTS,
  GET_ALL_PRODUCTS_SELECT,
  EXPORT_ALL_PRODUCTS_STOCK_EXCEL,
} from "../../types";

export default function ProductsReducer(state, action) {
  switch (action.type) {
    case GET_ALL_PRODUCTS:
      return {
        ...state,
        products: action.payload.products,
        totalDocs: action.payload.totalDocs,
        limit: action.payload.limit,
        page: action.payload.page,
        paginCounter: action.payload.paginCounter,
        hasPrevPage: action.payload.hasPrevPage,
        hasNextPage: action.payload.hasNextPage,
        prevPage: action.payload.prevPage,
        nextPage: action.payload.nextPage,
        totalPages: action.payload.totalPages,
        success: false,
        ErrorsAPI: [],
      };

    case GET_ALL_PRODUCTS_DESACTIVATED:
      return {
        ...state,
        products: action.payload.productsLocked,
        totalDocs: action.payload.totalDocs,
        limit: action.payload.limit,
        page: action.payload.page,
        paginCounter: action.payload.paginCounter,
        hasPrevPage: action.payload.hasPrevPage,
        hasNextPage: action.payload.hasNextPage,
        prevPage: action.payload.prevPage,
        nextPage: action.payload.nextPage,
        totalPages: action.payload.totalPages,
        success: false,
        ErrorsApi: [],
      };
    case GET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
        success: false,
        ErrorsAPI: [],
      };
    case GET_ALL_PRODUCTS_SELECT:
      return {
        ...state,
        products: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case GET_ONE_PRODUCT:
      return {
        ...state,
        product: state.products.find(
          (product) => product._id === action.payload
        ),
        success: false,
        ErrorsAPI: [],
      };
    case SEARCH_PRODUCT:
      return {
        ...state,
        products: action.payload,
        success: false,
        ErrorsAPI: [],
      };
    case SEARCH_PRODUCTS_DESACTIVATED:
      return {
        ...state,
        products: action.payload,
        success: false,
        ErrorsAPI: [],
      };
    case SEARCH_PRODUCTS_MOVEMENTS:
      return {
        ...state,
        search_products_movements: action.payload,
        success: false,
        ErrorsAPI: [],
      };
    case GET_PRODUCTS_CANVAS_ONLY:
      return {
        ...state,
        products: action.payload,
        success: false,
        ErrorsApi: [],
      };

    case ADD_PRODUCTS:
      return {
        ...state,
        products: [action.payload, ...state.products],
        success: true,
        ErrorsAPI: [],
      };
    case CREATE_ENTER_PRODUCTS:
      return {
        ...state,
        entries: [action.payload, ...state.entries],
        products: [],
        success: true,
        ErrorsApi: [],
      };
    case GET_ENTER_PRODUCTS:
      return {
        ...state,
        entries: action.payload.entries,
        totalDocs: action.payload.totalDocs,
        limit: action.payload.limit,
        page: action.payload.page,
        paginCounter: action.payload.paginCounter,
        hasPrevPage: action.payload.hasPrevPage,
        hasNextPage: action.payload.hasNextPage,
        prevPage: action.payload.prevPage,
        nextPage: action.payload.nextPage,
        totalPages: action.payload.totalPages,
        success: false,
        ErrorsAPI: [],
      };
    case ADD_PRODUCTS_EXCEL:
      return {
        ...state,
        products: action.payload,
        //success: true,
        //products: action.payload,
        success: true,
        ErrorsApi: [],
      };

    case SHOW_ERRORS_API:
      return {
        ...state,
        success: true,
        ErrorsAPI: [],
      };

    case EXPORT_PRODUCTS_EXCEL:
      return {
        ...state,
        success: false,
        ErrorsAPI: [],
      };
    case EXPORT_ALL_PRODUCTS_STOCK_EXCEL:
      return {
        ...state,
        success: false,
        ErrorsAPI: [],
      };
    case IMPORT_PRODUCTS_EXCEL:
      return {
        ...state,
        loading: false,
        success: true,
        ErrorsAPI: [],
      };
    case UPDATE_PRODUCTS_EXCEL:
      return {
        ...state,
        //products: action.payload,
        success: true,
        loading: false,
        ErrorsApi: [],
      };

    case IMPORT_PRODUCTS_EXCEL_ERROR:
      return {
        ...state,
        loading: false,
        success: true,
        ErrorsAPI: [],
      };
    case UPDATE_PRODUCTS_EXCEL_ERROR:
      return {
        ...state,
        loading: false,
        success: true,
        ErrorsAPI: [],
      };

    case UPDATE_PRODUCTS:
      return {
        ...state,
        products: state.products.map((product) =>
          product._id === action.payload._id ? { ...action.payload } : product
        ),
        success: true,
        ErrorsAPI: [],
      };

    case UPLOAD_MULTIMEDIA:
      return {
        ...state,
        products: state.products.map((product) =>
          product._id === action.payload._id ? { ...action.payload } : product
        ),
        success: true,
        ErrorsAPI: [],
      };
    case CREATE_MULTIMEDIA:
      return {
        ...state,
        success: true,
        ErrorsApi: [],
      };

    case DELETE_PRODUCTS:
      return {
        ...state,
        success: true,
        products: state.products.filter(
          (product) => product._id !== action.payload
        ),
      };
    case RESTORE_PRODUCT:
      return {
        ...state,
        success: true,
        products: state.products.filter(
          (product) => product._id !== action.payload
        ),
      };
    case UPDATE_STOCK:
      return {
        ...state,
        success: true,
        products: state.products?.map((product) =>
          product._id === action.payload._id ? { ...action.payload } : product
        ),
        ErrorsAPI: [],
      };

    case GET_ALL_MOVEMENTS:
      return {
        ...state,
        movements: action.payload.movements,
        totalDocs: action.payload.totalDocs,
        limit: action.payload.limit,
        page: action.payload.page,
        paginCounter: action.payload.paginCounter,
        hasPrevPage: action.payload.hasPrevPage,
        hasNextPage: action.payload.hasNextPage,
        prevPage: action.payload.prevPage,
        nextPage: action.payload.nextPage,
        totalPages: action.payload.totalPages,
        success: false,
        ErrorsAPI: [],
      };
    case GET_PRODUCT_DESACTIVATED:
      return {
        ...state,
        current_product:
          state.productsLocked.find((p) => p._id === action.payload) ??
          state.search_products_desactivated.find(
            (p) => p._id === action.payload
          ) ??
          state.products.find((p) => p._id === action.payload) ??
          state.search_products.find((p) => p._id === action.payload),
      };

    default:
      return state;
  }
}
