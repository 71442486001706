import React, { useContext } from "react";
//import WarehouseContext from "../../../context/Warehouse/WarehouseContext";
import WarehouseCanvasContext from "../../../context/WarehouseCanvas/WarehouseCanvasContext";
import formatDate from "../../../utils/FormatDate";

import { Box, Card, Typography, Divider, Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import WarehouseContext from "../../../context/Warehouse/WarehouseContext";
const PendingCanvasCard = ({ shipping }) => {
  const { TakeOrderCanvas, SaveCurrentOderCanvas } = useContext(
    WarehouseCanvasContext
  );
  const { PdfOrder } = useContext(WarehouseContext);

  const SelectShowOrder = (shipping) => {
    SaveCurrentOderCanvas(shipping);
  };
  return (
    <>
      <Card sx={{ boxShadow: 3 }}>
        <Box
          fullWidth
          display="flex"
          alignItems="baseline"
          justifyContent="left"
          sx={{
            paddingX: 2,
            backgroundColor: "white",
            color: "black",
            textAlign: "center",
            marginTop: 2,
          }}
        >
          <Typography
            variant="su arrowbtitle2"
            noWrap
            sx={{ fontWeight: "bold", fontSize: "17px", textAlign: "center" }}
            className="blink-1"
          >
            Folio Venta: {shipping?.order_id?.folio}
          </Typography>
        </Box>

        <Divider />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingX: 2,
            marginBottom: 1,
          }}
        >
          <div>
            <Typography sx={{ fontWeight: "bold", fontSize: "17px" }}>
              Fecha de compra:
            </Typography>
          </div>
          <div>
            <Typography sx={{ fontSize: "14px" }}>
              {shipping.order_id.dateFormatted ?? shipping.order_id.createdAt}
            </Typography>
          </div>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            alignItems: "start",
            justifyContent: "space-between",
            paddingX: 2,
            marginBottom: 1,
          }}
        >
          <div>
            <Typography sx={{ fontWeight: "bold", fontSize: "17px" }}>
              Cliente:
            </Typography>
          </div>
          <div>
            <Typography sx={{ fontSize: "14px" }}>
              {shipping?.order_id?.client_id?.fullname}
            </Typography>
          </div>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between",
            paddingX: 2,
            marginBottom: 1,
          }}
        >
          <div>
            <Typography sx={{ fontWeight: "bold", fontSize: "17px" }}>
              Cantidad de productos:
            </Typography>
          </div>
          <div>
            <Typography sx={{ fontSize: "14px" }}>
              {shipping?.order_id?.products_list?.length}
            </Typography>
          </div>
        </Box>

        <Divider />
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "center",
            paddingX: 2,
            marginY: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Link to={`/Detalle_venta/${shipping.order_id._id}`}>
                <Button
                  variant="outlined"
                  size="small"
                  fullWidth
                  style={{
                    backgroundColor: "#1769aa",
                    borderColor: "#1769aa",
                    color: "white",
                    marginTop: 5,
                    textTransform: "none",
                  }}
                  startIcon={<PictureAsPdfIcon />}
                  // onClick={() => SelectShowOrder(shipping.order_id._id)}
                >
                  Ver
                </Button>
              </Link>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Button
                variant="outlined"
                size="small"
                onClick={(e) => {
                  PdfOrder(shipping.order_id._id);
                }}
                sx={{
                  fontWeight: "bold",
                  textTransform: "none",
                }}
                style={{
                  backgroundColor: "#b71c1c",
                  color: "white",
                  borderColor: "#b71c1c",
                  marginTop: 5,
                }}
              >
                PDF
              </Button>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Button
                variant="outlined"
                size="small"
                onClick={(e) => {
                  TakeOrderCanvas(shipping._id);
                }}
                sx={{
                  fontWeight: "bold",
                  textTransform: "none",
                }}
                style={{
                  backgroundColor: "#1769aa",
                  color: "white",
                  borderColor: "#1769aa",
                  marginTop: 5,
                }}
              >
                Tomar Pedido
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </>
  );
};

export default PendingCanvasCard;
