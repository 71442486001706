import React, { useState, useContext, useEffect } from "react";
import ModulesContext from "../../context/Permissions/PermissionsContext";
import CategoriesContext from "../../context/CategoriesP/CategoriesContext";
import CategoriesCard from "../../components/Cards/categories/CategoriesCard";
import NoDataComponents from "../../components/loading/NoDataComponents";
import NoResultsComponent from "../../components/loading/NoResultsComponent";
import ModalAdd from "./ModalAdd";

import { makeStyles } from "@mui/styles";
import { Grid, Box, Button, TextField } from "@mui/material/";
import ModalImportCategories from "./ModalImportCategories";
import BackupIcon from "@mui/icons-material/Backup";
import { useDebounce } from "use-debounce";

const useStyles = makeStyles({
  titleCard: {
    color: "white",
    fontSize: "35px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginRight: 5,
  },
});

export default function CategoryP() {
  const classes = useStyles();
  //buscador
  const [palabra, setPalabra] = useState(null);
  //ModalImportar
  const [modalImport, AbrirModalImport] = useState(false);

  const handleClickOpenImport = () => {
    AbrirModalImport(true);
  };
  const handleClickCloseImport = () => {
    AbrirModalImport(false);
  };
  const [modalAdd, AbrirModalAdd] = useState(false);
  const handleClickOpenAdd = () => {
    AbrirModalAdd(true);
  };
  const handleClickCloseAdd = () => {
    AbrirModalAdd(false);
  };

  const { categories, GetCategories, searchCategory, search_categories } =
    useContext(CategoriesContext);
  const { permissionsPerUser, GetModulesPerUser } = useContext(ModulesContext);
  const [debouncedWord] = useDebounce(palabra, 500);
  // useEffect(() => {
  //   GetCategories();
  // }, []);

  useEffect(() => {
    if (localStorage.getItem("module_id")) {
      const permiss = localStorage.getItem("module_id");
      GetModulesPerUser(permiss);
    }
    GetCategories();
  }, []);

  useEffect(() => {
    if (palabra != null) {
      searchCategory(palabra);
    }
  }, [debouncedWord]);

  useEffect(() => {
    GetCategories();
  }, []);

  return (
    <>
      <Grid container spacing={2} sx={{ backgroundColor: "white" }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              textAling: "center",
            }}>
            {permissionsPerUser.write === true && (
              <div className={classes.fab}>
                <Button
                  onClick={handleClickOpenAdd}
                  sx={{
                    color: "black",
                    backgroundColor: "#26c6da",
                    hoverColor: "white",
                    fontFamily: "monospace",
                    fontWeight: "bolder",
                    fontSize: "15px",
                    textTransform: "none",
                    "&:hover": {
                      color: "black",
                      backgroundColor: "#67cedb",
                      hoverColor: "white",
                      fontFamily: "monospace",
                      fontWeight: "bolder",
                      fontSize: "15px",
                    },
                  }}>
                  Agregar
                </Button>
              </div>
            )}
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              paddingTop: 4,
              p: 3,
              marginBottom: 10,
            }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={2}>
                  {!categories.length ? (
                    <></>
                  ) : (
                    <>
                      <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                        <TextField
                          fullWidth
                          label="Buscar"
                          id="filled-size-small"
                          variant="outlined"
                          size="samll"
                          onChange={({ target }) => {
                            setPalabra(target.value);
                            //search_categories(target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Button
                          fullWidth
                          size="small"
                          variant="outlined"
                          onClick={() => handleClickOpenImport()}
                          style={{
                            borderColor: "green",
                            color: "white",
                            backgroundColor: "green",
                            textTransform: "none",
                            height: "100%",
                          }}
                          endIcon={<BackupIcon />}>
                          Importar excel
                        </Button>
                      </Grid>
                    </>
                  )}

                  {!palabra &&
                    (!categories.length ? (
                      <NoDataComponents />
                    ) : (
                      categories.map((categorie, index) => (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={3}
                          xl={3}
                          key={index}>
                          <CategoriesCard
                            permiss={permissionsPerUser}
                            categorie={categorie}
                          />
                        </Grid>
                      ))
                    ))}

                  {palabra &&
                    (!search_categories.length ? (
                      <NoResultsComponent />
                    ) : (
                      search_categories.map((categorie, index) => (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={3}
                          xl={3}
                          key={index}>
                          <CategoriesCard
                            permiss={permissionsPerUser}
                            categorie={categorie}
                          />
                        </Grid>
                      ))
                    ))}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <ModalAdd
          modal={modalAdd}
          handleClickOpen={handleClickOpenAdd}
          handleClose={handleClickCloseAdd}
        />
      </Grid>
      <ModalImportCategories
        modalImport={modalImport}
        handleClickOpen={handleClickOpenImport}
        handleClickClose={handleClickCloseImport}
      />
    </>
  );
}
