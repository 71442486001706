import React, { useEffect, useContext, useState } from "react";
import UsersContext from "../../context/Users/UsersContext";
import UserCard from "../../components/Cards/users/UserCard";
import ModalAdd from "../../containers/users/ModalAdd";
import { Paginate } from "../../components/Pagination/Paginate";
import { makeStyles } from "@mui/styles";
import { Box, Grid, CardContent, Button, TextField } from "@mui/material";
import ModulesContext from "../../context/Permissions/PermissionsContext";
import NoDataComponents from "../../components/loading/NoDataComponents";
import NoResultsComponent from "../../components/loading/NoResultsComponent";

const useStyles = makeStyles({
  titleCard: {
    color: "white",
    fontSize: "35px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 2,
    marginRight: 5,
  },
  pagination: {
    marginBottom: 25,
    marginRight: 25,
    fontFamily: "cursive",
    position: "relative",
    fontSize: "40px",
  },
});

export default function Users() {
  const classes = useStyles();
  //Abrir modal
  const [modalAdd, AbrirModalAdd] = useState(false);
  const handleClickOpenAdd = () => {
    AbrirModalAdd(true);
  };
  const handleClickCloseAdd = () => {
    AbrirModalAdd(false);
  };
  /**Paginacion */
  var [pages, setPage] = useState(1);

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const params = { pages };

  const GetUsersList = useContext(UsersContext);
  const { users, GetUsers, success, allusers, GetAllUsers, totalPages } =
    GetUsersList;
  const { permissionsPerUser, GetModulesPerUser } = useContext(ModulesContext);
  //filtrado
  const [userFilter, setUserFilter] = useState();
  const [magicWord, setMagicWord] = useState("");

  const handleSearchUser = ({ target }) => {
    setMagicWord(target.value);
    const palabra = target.value;
    try {
      let resultFilterUser = allusers.filter((user) =>
        user.fullname.toString().toLowerCase().includes(palabra.toLowerCase())
      );
      setUserFilter(resultFilterUser);
      //setMagicWord("");
    } catch (error) {}
  };

  useEffect(() => {
    if (localStorage.getItem("module_id")) {
      const permiss = localStorage.getItem("module_id");
      GetModulesPerUser(permiss);
    }
    GetAllUsers();
    GetUsers(params);
    setUserFilter(allusers);
    setMagicWord("");
  }, [success, pages]);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              textAling: "center",
              // borderRadius: "0 0 24px 0",
            }}
          >
            {permissionsPerUser.write === true && (
              <div className={classes.fab}>
                <Button
                  onClick={handleClickOpenAdd}
                  sx={{
                    color: "black",
                    backgroundColor: "#26c6da",
                    hoverColor: "white",
                    fontFamily: "monospace",
                    fontWeight: "bolder",
                    fontSize: "15px",
                    textTransform: "none",
                    "&:hover": {
                      color: "black",
                      backgroundColor: "#67cedb",
                      hoverColor: "white",
                      fontFamily: "monospace",
                      fontWeight: "bolder",
                      fontSize: "15px",
                    },
                  }}
                >
                  Agregar
                </Button>
              </div>
            )}
          </Box>
          <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={2}>
                  {!users.length ? (
                    <></>
                  ) : (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TextField
                        fullWidth
                        label="Buscar usuario"
                        placeholder="Buscar usuario por nombre"
                        defaultValue=""
                        onChange={handleSearchUser}
                        style={{ marginBottom: 15 }}
                      />
                    </Grid>
                  )}

                  {!magicWord &&
                    (!users.length ? (
                      <NoDataComponents />
                    ) : (
                      users.map((user) => (
                        <Grid
                          key={user.id}
                          item
                          xs={12}
                          sm={12}
                          md={3}
                          lg={3}
                          xl={3}
                        >
                          <UserCard permiss={permissionsPerUser} user={user} />
                        </Grid>
                      ))
                    ))}

                  {magicWord &&
                    (!userFilter.length ? (
                      <NoResultsComponent />
                    ) : (
                      userFilter.map((user) => (
                        <Grid
                          key={user.id}
                          item
                          xs={12}
                          sm={12}
                          md={3}
                          lg={3}
                          xl={3}
                        >
                          <UserCard permiss={permissionsPerUser} user={user} />
                        </Grid>
                      ))
                    ))}
                </Grid>
                {!magicWord &&
                  (!users.length ? (
                    <Box className={classes.pagination}></Box>
                  ) : (
                    <Box
                      className={classes.pagination}
                      sx={{ marginTop: 4, marginBottom: 4 }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        display="flex"
                        justifyContent="center"
                      >
                        <Paginate
                          totalPages={totalPages}
                          pages={pages}
                          handleChangePage={handleChangePage}
                        />
                      </Grid>
                    </Box>
                  ))}
                <CardContent></CardContent>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <ModalAdd
          modal={modalAdd}
          handleClickOpen={handleClickOpenAdd}
          handleClose={handleClickCloseAdd}
        />
      </Grid>
    </>
  );
}
