import React, { useContext, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import { makeStyles } from "@mui/styles";
import ClientsContext from "../../context/Clients/ClientsContext";
import { Box, CardContent, Grid, TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import NoDataComponents from "../../components/loading/NoDataComponents";
import { styled } from "@mui/material/styles";
import NoResultsComponents from "../../components/loading/NoResultsComponent";

import { useDebounce } from "use-debounce";
import { Paginate } from "../../components/Pagination/Paginate";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles(() => ({
  titleCard: {
    color: "white",
    fontSize: "35px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 2,
  },
}));

export default function Clients() {
  const classes = useStyles();
  var [pages, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const limite = rowsPerPage;

  const params = { limite, pages };

  const {
    clients,
    GetClients,
    success,
    totalPages,
    searchClient,
    search_clients,
  } = useContext(ClientsContext);

  const [magicWord, setMagicWord] = useState("");
  const [debouncedWord] = useDebounce(magicWord, 500);

  useEffect(() => {
    if (magicWord != null) {
      searchClient(magicWord);
    }
  }, [debouncedWord]);
  useEffect(() => {
    GetClients(params);
    //setClientFilter(clientsWithoutPagination);
  }, [success, rowsPerPage, pages]);
  return (
    <Layout>
      <Grid container spacing={2} sx={{ backgroundColor: "white", padding: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#000000",
              textAling: "center",
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            }}
          >
            <div className={classes.titleCard}>Clientes</div>
          </Box>
          <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={2}>
                  {!clients.length ? (
                    <></>
                  ) : (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TextField
                        fullWidth
                        label="Buscar cliente"
                        placeholder="Buscar cliente por nombre"
                        onChange={({ target }) => {
                          setMagicWord(target.value);
                        }}
                        style={{ marginBottom: 15 }}
                      />
                    </Grid>
                  )}

                  {magicWord &&
                    (!search_clients.length ? (
                      <NoResultsComponents />
                    ) : (
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align="center">
                                Nombre
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Correo
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Telefono
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Status
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {search_clients.map((client, index) => (
                              <StyledTableRow key={index}>
                                <StyledTableCell align="center">
                                  {client.fullname}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {client.email}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {client.phone?.phone_number}
                                </StyledTableCell>
                                {client.status === true ? (
                                  <StyledTableCell align="center">
                                    Activo
                                  </StyledTableCell>
                                ) : (
                                  <StyledTableCell align="center">
                                    Inactivo
                                  </StyledTableCell>
                                )}
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ))}

                  {!magicWord &&
                    (!clients.length ? (
                      <NoDataComponents />
                    ) : (
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align="center">
                                Nombre
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Correo
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Telefono
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Status
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {clients.map((client, index) => (
                              <StyledTableRow key={index}>
                                <StyledTableCell align="center">
                                  {client.fullname}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {client.email}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {client.phone?.phone_number}
                                </StyledTableCell>
                                {client.status === true ? (
                                  <StyledTableCell align="center">
                                    Activo
                                  </StyledTableCell>
                                ) : (
                                  <StyledTableCell align="center">
                                    Inactivo
                                  </StyledTableCell>
                                )}
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ))}
                </Grid>
              </Grid>
            </Grid>
          </Box>
          {!magicWord &&
            (!clients.length ? (
              <Box className={classes.pagination}></Box>
            ) : (
              <Box>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  display="flex"
                  justifyContent="center"
                >
                  <Paginate
                    pages={pages}
                    handleChangePage={handleChangePage}
                    totalPages={totalPages}
                  />
                </Grid>
              </Box>
            ))}
          <CardContent></CardContent>
        </Grid>
      </Grid>
    </Layout>
  );
}
