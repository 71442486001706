import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import Layout from "../../components/layout/Layout";
import MethodGet from "../../config/Service";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SpinnerComponent from "../../components/loading/SpinnerComponent";
import formatDate from "../../utils/FormatDate";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const useStyles = makeStyles(() => ({
  titleCard: {
    color: "white",
    fontSize: "35px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 2,
    marginRight: 5,
    display: "flex",
    justifyContent: "end",
  },
  img_data: {
    margin: "auto",
    height: "90%",
  },
}));
const DetailDeparture = (props) => {
  const classes = useStyles();
  const id = props.match.params.id;

  const [departure, saveDeparture] = useState();
  const [products, saveProducts] = useState();

  useEffect(() => {
    let url = `/departures/${id}`;
    MethodGet(url)
      .then((res) => {
        saveDeparture(res.data.departure);
        saveProducts(res.data.departure.products);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);
  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ backgroundColor: "black", color: "white" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#000000",
              textAling: "center",
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
              // borderRadius: "0 0 24px 0",
            }}
          >
            <div className={classes.titleCard}>Detalle de la salida</div>
            <div className={classes.fab}>
              <Tooltip title="Regresar" placement="top">
                <Button
                  sx={{ color: "white" }}
                  onClick={() => props.history.goBack()}
                >
                  <ArrowBackIcon />
                </Button>
              </Tooltip>
            </div>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {departure && (
            <Grid container spacing={2} sx={{ margin: 2 }}>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                display="flex"
                justifyContent="center"
              >
                <Typography fontWeight="bold">
                  Fecha:
                  {formatDate(departure.createdAt)}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                display="flex"
                justifyContent="center"
              >
                <Typography fontWeight="bold">
                  Autorizo:
                  {departure.authotizing_client}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                display="flex"
                justifyContent="center"
              >
                <Typography fontWeight="bold">
                  Recibe:
                  {departure.receiving_customer}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                display="flex"
                justifyContent="center"
              >
                <Typography fontWeight="bold">
                  Comentario:
                  {departure.comment}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                display="flex"
                justifyContent="center"
              >
                <Typography fontWeight="bold">
                  Subtotal: $ {departure.subtotal}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                display="flex"
                justifyContent="center"
              >
                <Typography fontWeight="bold">
                  Total: $ {departure.total}
                </Typography>
              </Grid>
              {departure.typeExit && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  xl={4}
                  display="flex"
                  justifyContent="center"
                >
                  <Typography fontWeight="bold">
                    Tipo de salida: {departure.typeExit}
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} sx={{ margin: 2 }}>
          <Grid
            item
            xs={12}
            sx={{
              backgroundColor: "black",
              color: "white",
              marginBottom: 0.5,
            }}
          >
            <Typography textAlign="center" fontWeight="bold">
              Productos de la salida
            </Typography>
          </Grid>
          {!products ? (
            <SpinnerComponent />
          ) : (
            <>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">Código</StyledTableCell>
                      <StyledTableCell align="center">Producto</StyledTableCell>
                      <StyledTableCell align="center">Cantidad</StyledTableCell>
                      <StyledTableCell align="center">
                        Precio U.
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Subtototal
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Descuento
                      </StyledTableCell>
                      <StyledTableCell align="center">Total</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products.map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="center">
                          {row.barcode}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.quantity}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.price}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.subtotal}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.discount ? row.discount : 0}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.total}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Grid>
      </Grid>
    </Layout>
  );
};

export default DetailDeparture;
