import {
  Button,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
// ///datepicker
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import esLocale from "date-fns/locale/es";
import { makeStyles, styled } from "@mui/styles";
import { useState } from "react";
import { useContext } from "react";
// import CouponsContext from "../../context/coupons/CouponsContext";
import { useForm } from "react-hook-form";
// import ProductsContext from "../../context/Products/ProductsContext";
// /**new pickers */
// import { formatDateNow, formatDateSelect } from "../../utils/FormatDate";
// import { Dayjs } from "dayjs";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import BrandSelect from "../../containers/SelectOptions/BrandSelect";
import { useStyles } from "@material-ui/pickers/views/Calendar/SlideTransition";
// import Layout from "../../components/layout/Layout";
// import BrandsContext from "../../context/Brands/BrandsContext";
// import { useEffect } from "react";
import esLocale from "date-fns/locale/es";
import { formatDateNow, formatDateSelect } from "../../utils/FormatDate";
import { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import ReportUtilityContext from "../../context/reportUtility/ReportUtilityContext";
import { DesktopDatePicker, StaticDatePicker } from "@mui/x-date-pickers";
import Swal from "sweetalert2";

export default function ReportsUtility() {
  /* Mi intento de Fecha chido */
  const classes = useStyles();
  const valueDesktopDatePicker = new Date();
  const [valueDesktopDatePickerSelected, setValueDesktopDatePickerSelected] =
    useState(new Date());
  const [pickerFecini, SetpickerIni] = useState(null);
  const [pickerFecter, SetpickerTer] = useState(null);
  /* Mi intento de Fecha chido */

  const { ExportReportUtiity } = useContext(ReportUtilityContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  //datepicker
  const [fecha_inicio, setFechaInicio] = useState(null);
  const [fecha_fin, setFechaFin] = useState(null);
  const MINDATENOW = formatDateNow(valueDesktopDatePickerSelected);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  const [brand, satebrand] = React.useState();
  const detectarCambiosBrand = (e) => {
    satebrand(e.target.value);
  };
  const onSubmit = (data, e) => {
    if (fecha_inicio === null) {
      Swal.fire({
        title: "Error",
        text: "Selecciona la fecha inicio",
        timer: 1500,
        icon: "error",
        // showConfirmButton: fa
      });
      return;
    }
    if (fecha_fin === null) {
      Swal.fire({
        title: "Error",
        text: "Selecciona la fecha fin",
        timer: 1500,
        icon: "error",
        // showConfirmButton: fa
      });
      return;
    }
    data.start_date = formatDateSelect(fecha_inicio);
    data.end_date = formatDateSelect(fecha_fin);
    data.brand = brand;
    ExportReportUtiity(data);
  };

  return (
    <>
      <Typography
        textAlign="center"
        variant="h4"
        fontFamily="monospace"
        sx={{ marginBottom: 2 }}>
        Utilidad por Marca
      </Typography>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.code === "Enter" || e.code === "NumpadEnter")
            e.preventDefault();
        }}
        autoComplete="off">
        <Grid container spacing={2} sx={{ padding: 4 }}>
          <Grid item xs={12} sm={12}>
            <BrandSelect detectarCambiosBrand={detectarCambiosBrand} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                orientation="landscape"
                label="Desde la fecha"
                name="end_date"
                todayText="Hoy"
                showTodayButton={true}
                disableFuture
                okText="Aceptar"
                cancelText="Cancelar"
                value={pickerFecini}
                onChange={(newValue) => {
                  SetpickerIni(newValue);
                  setFechaInicio(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={3}>
                <DesktopDatePicker
                  label="Hasta la fecha"
                  name="end_date"
                  todayText="Hoy"
                  orientation="portrait"
                  showTodayButton={true}
                  disableFuture
                  okText="Aceptar"
                  cancelText="Cancelar"
                  value={pickerFecter}
                  onChange={(newValue) => {
                    SetpickerTer(newValue);
                    setFechaFin(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button type="submit" variant="contained" size="large">
              Generar Reporte
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
