import React from "react";
import {
  ADD_BANKS,
  CURRENT_BANKS,
  DELETE_BANKS,
  GET_ALL_BANKS,
  SHOW_ERRORS_API,
  UPDATE_BANKS,
} from "../../types";

export default function BanksReducer(state, action) {
  switch (action.type) {
    case GET_ALL_BANKS:
      return {
        ...state,
        banks: action.payload,
        success: false,
        ErrorsAPI: [],
      };
    case ADD_BANKS:
      return {
        ...state,
        success: true,
        ErrorsAPI: [],
      };

    case SHOW_ERRORS_API:
      return {
        ...state,
        success: true,
        ErrorsAPI: [],
      };
    case CURRENT_BANKS:
      return {
        ...state,
        bank: action.payload,
      };

    case UPDATE_BANKS:
      return {
        ...state,
        success: true,
        ErrorsAPI: [],
      };
    case DELETE_BANKS:
      return {
        ...state,
        success: true,
        banks: state.banks.filter((bank) => bank.id !== action.payload),
      };
    default:
      return state;
  }
}
