import React, { useState, useEffect, useContext } from "react";
import AccontsContext from "../../../context/Accounts/AccountsContext";
import BankSelect from "../../SelectOptions/BankSelect";
import { useForm } from "react-hook-form";
import SelectTypeAccount from "../../SelectOptions/SelectTypeAccount";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Box,
  Fab,
  TextField,
  Tooltip,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import Swal from "sweetalert2";

const useStyles = makeStyles({
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 10,
  },
  input: {
    display: "none",
  },
});

export default function Add(props) {
  const classes = useStyles();
  const { modal, handleClose } = props;

  const { success, AddAccounts } = useContext(AccontsContext);
  const [bankId, saveBank] = useState("");
  const [typeAccount, saveTypeAccount] = useState("");
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  const detectarCambiosBank = (e) => {
    saveBank(e.target.value);
  };

  const detectarCambiosTypeAccount = (e) => {
    saveTypeAccount(e.target.value);
  };

  useEffect(() => {
    if (success) {
      setValue("account_number", "", { shouldDirty: true });
      setValue("interbank", "", { shouldDirty: true });
      setValue("beneficiary", "", { shouldDirty: true });
    }
  }, [success]);

  const onSubmit = (data, e) => {
    data.bank = bankId;
    data.typeAccount = typeAccount;
    AddAccounts(data);
    handleClose();
  };

  return (
    <Dialog fullWidth maxWidth="md" open={modal} onClose={handleClose}>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#000000",
            textAling: "center",
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            borderRadius: "0 0 24px 0",
          }}>
          <div className={classes.titleCard}>Agregar cuenta bancaria</div>
          <div className={classes.fab}>
            <Tooltip title="cerrar" arrow>
              <Fab
                size="small"
                onClick={handleClose}
                sx={{
                  color: "#FFFFFF",
                  backgroundColor: "#FF0000",
                  "&:hover": {
                    color: "#FFFFFF",
                    backgroundColor: "#FF0000",
                  },
                }}>
                <CancelPresentationIcon />
              </Fab>
            </Tooltip>
          </div>
        </Box>
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter")
              e.preventDefault();
          }}>
          <Box sx={{ display: "flex", paddingTop: 4, p: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SelectTypeAccount
                  detectarCambiosTypeAccount={detectarCambiosTypeAccount}
                />
              </Grid>
              {typeAccount && typeAccount !== 2 && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <BankSelect detectarCambiosBank={detectarCambiosBank} />
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  fullWidth
                  id="account_number"
                  name="account_number"
                  label="Numero de Cuenta"
                  variant="outlined"
                  error={errors.account_number ? true : false}
                  helperText={errors?.account_number?.message}
                  {...register("account_number", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  fullWidth
                  id="interbank"
                  name="interbank"
                  label="Clabe interbancaria"
                  variant="outlined"
                  error={errors.interbank ? true : false}
                  helperText={errors?.interbank?.message}
                  {...register("interbank", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                    maxLength: {
                      value: 18,
                      message: "La clabe debe contener 18 digitos",
                    },
                    minLength: {
                      value: 18,
                      message:
                        "La clabe interbancaria debe tener minimo 18 digitos",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  fullWidth
                  id="card_number"
                  name="card_number"
                  label="Tarjeta Asociada"
                  variant="outlined"
                  error={errors.card_number ? true : false}
                  helperText={errors?.card_number?.message}
                  {...register("card_number", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                    maxLength: {
                      value: 16,
                      message: "El numero de tarjeta debe contener 16 digitos",
                    },
                    minLength: {
                      value: 16,
                      message: "El numero de tarjeta debe contener 16 digitos",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  fullWidth
                  id="name"
                  name="beneficiary"
                  label="Beneficiario"
                  variant="outlined"
                  type="text"
                  sx={{ marginBottom: 2 }}
                  error={errors.beneficiary ? true : false}
                  helperText={errors?.beneficiary?.message}
                  {...register("beneficiary", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    textAling: "center",
                  }}>
                  <div className={classes.fab}>
                    <Fab
                      variant="extended"
                      type="submit"
                      sx={{
                        backgroundColor: "#00B9FF",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#00DEED",
                          color: "white",
                        },
                      }}>
                      <SaveOutlinedIcon sx={{ mr: 1 }} />
                      Guardar
                    </Fab>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}
