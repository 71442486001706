import React, { useEffect, Fragment, useContext } from "react";

import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";

export default function SelectTypeProduct(props) {
  const type_products = [
    { value: "1", name: "variante" },
    { value: "2", name: "canvas" },
  ];

  const detectarCambiosTypeProduct = (e) => {
    if (e.target.value) {
    }
    props.detectarCambiosTypeProduct(e);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            Tipo de producto
          </InputLabel>
          {props.typeProductId ? (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Tipo de producto "
              onChange={detectarCambiosTypeProduct}
              defaultValue={props.typeProductId}
            >
              {type_products.map((TypeProduct, index) => (
                <MenuItem key={index} value={TypeProduct.value}>
                  {TypeProduct.name}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Tipo de producto "
              onChange={detectarCambiosTypeProduct}
            >
              {type_products.map((TypeProduct, index) => (
                <MenuItem key={index} value={TypeProduct.value}>
                  {TypeProduct.name}
                </MenuItem>
              ))}
            </Select>
          )}
          <FormHelperText>Compo Requerido*</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}
