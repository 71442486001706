import React, { useReducer } from "react";
import Swal from "sweetalert2";
import MethodGet, {
	MethodDelete,
	MethodPost,
	MethodPut,
} from "../../config/Service";
import {
	ADD_EXPENSES,
	CURRENT_EXPENSE,
	DELETE_EXPENSES,
	GET_ALL_EXPENSES,
	SHOW_ERRORS_API,
	UPDATE_EXPENSES,
} from "../../types";
import headerConfig from "../../config/imageHeaders";
import ExpensesReducer from "./ExpensesReducer";
import ExpensesContext from "./ExpensesContext";

const ExpensesState = ({ children }) => {
	//estado inicial
	const initialState = {
		expenses: [],
		expense: null,
		ErrorsApi: [],
		success: false,
	};

	const [state, dispatch] = useReducer(ExpensesReducer, initialState);

	//obtener la lista de gastos
	const GetExpenses = () => {
		sessionStorage.removeItem("expense");
		let url = "/expenses";
		MethodGet(url)
			.then((res) => {

				dispatch({
					type: GET_ALL_EXPENSES,
					payload: res.data.expenses,
				});
			})
			.catch((error) => {
				dispatch({
					type: SHOW_ERRORS_API,
					payload: error.response.data.message,
				});
			});
	};

	//funcion para registrar gastos
	const AddExpenses = (expense) => {
		const formData = new FormData();
		formData.append("name", expense.name);
		formData.append("image", expense.image);
		formData.append("price", expense.price);
		formData.append("description", expense.description);

		let url = "/expenses";
		MethodPost(url, formData, { headerConfig })
			.then((res) => {
				Swal.fire({
					title: "Gasto Agregado",
					text: res.data.message,
					icon: "success",
					timer: 1000,
					showConfirmButton: false,
				});
				dispatch({
					type: ADD_EXPENSES,
					payload: res.data.expense,
				});
			})
			.catch((error) => {
				Swal.fire({
					title: "Error",
					text: error.response.data.message,
					showConfirmButton: false,
					timer: 2000,
					icon: "error",
				});
				dispatch({
					type: SHOW_ERRORS_API,
					payload: error.response.data.error,
				});
			});
	};

	//funcion para editar gasto
	const UpdateExpenses = (expense) => {
		const formData = new FormData();
		formData.append("name", expense.name);
		formData.append("image", expense.image);
		formData.append("price", expense.price);
		formData.append("description", expense.description);

		let url = `/expenses/${expense.id}`;
		MethodPut(url, formData, { headerConfig })
			.then((res) => {
				Swal.fire({
					title: "Gasto Actualizado",
					text: res.data.message,
					timer: 3000,
					showConfirmButton: false,
					icon: "success",
				});
				dispatch({
					type: UPDATE_EXPENSES,
					payload: res.data.expense,
				});
			})
			.catch((error) => {
				Swal.fire({
					title: "Error",
					text: error.response.data.message,
					icon: "error",
				});
				dispatch({
					type: SHOW_ERRORS_API,
					payload: error.res.data.error,
				});
			});
	};

	//eliminar Gasto
	const DeleteExpenses = (id) => {
		Swal.fire({
			title: "¿Estas Seguro?",
			text: "El gasto seleccionado sera eliminado",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Si, eliminar",
			cancelButtonText: "No, cancelar",
		}).then((result) => {
			if (result.value) {
				let url = `/expenses/${id}`;
				MethodDelete(url)
					.then((res) => {
						Swal.fire({
							title: "Eliminado",
							text: res.data.message,
							icon: "success",
							timer: 2000,
							showConfirmButton: false,
						});
						dispatch({
							type: DELETE_EXPENSES,
							payload: id,
						});
					})
					.catch((error) => {
						Swal.fire({
							title: "Error",
							text: error.response.data.message,
							icon: "error",
						});
					});
			}
		});
	};

	//funcion para seleccionar un Gasto
	const SaveCurrentExpense = (expense) => {
		sessionStorage.setItem("expense", JSON.stringify(expense));
		dispatch({
			type: CURRENT_EXPENSE,
			payload: expense,
		});
	};
	return (
		<ExpensesContext.Provider
			value={{
				expenses: state.expenses,
				expense: state.expense,
				ErrorsApi: state.ErrorsApi,
				success: state.success,
				GetExpenses,
				AddExpenses,
				UpdateExpenses,
				DeleteExpenses,
				SaveCurrentExpense,
			}}
		>
			{children}
		</ExpensesContext.Provider>
	);
};

export default ExpensesState;
