import React, { useReducer } from "react";
import TypeUsersReducer from "./TypeUsersReducer";
import TypeUsersContext from "./TypeUsersContext";
//import { useHistory } from "react-router-dom";

import MethodGet, {
  MethodDelete,
  MethodPost,
  MethodPut,
} from "../../config/Service";
import {
  ADD_TYPE_USERS,
  AGREGATE_PERMISSIONS,
  CURRENT_TYPE_USER,
  DELETE_TYPE_USERS,
  GET_ALL_TYPE_USERS,
  SHOW_ERRORS_API,
  UPDATE_PERMISSIONS,
  UPDATE_TYPE_USERS,
} from "../../types";
import Swal from "sweetalert2";
//import TypeUsers from '../../containers/type_users/TypeUsers';

const TypeUsersState = ({ children }) => {
  //estado_inicial
  const initialState = {
    type_users: [],
    typeuser: null,
    ErrorsApi: [],
    success: false,
  };

  //dispatch que ejecuta las acciones
  const [state, dispatch] = useReducer(TypeUsersReducer, initialState);
  //obtener la lista de los tipos de usuarios

  const GetTypeUsers = () => {
    sessionStorage.removeItem("typeuser");
    let url = `/type_users`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_TYPE_USERS,
          payload: res.data.type_users,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
      });
  };

  //funcion para crear nuevo tipo de usuario
  const AddTypeUsers = (typeuser) => {
    const formData = new FormData();
    formData.append("name", typeuser.name);

    let url = "/type_users/";
    MethodPost(url, formData)
      .then((res) => {
        Swal.fire({
          title: "Buen trabajo",
          text: res.data.msg,
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        dispatch({
          type: ADD_TYPE_USERS,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.res.data.error,
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.res.data.error,
        });
      });
  };

  const AddPermissions = (typeuser) => {
    const formData = new FormData();
    formData.append("modules", typeuser.modules.modules);
    let url = `/permissions/${typeuser.id}`;
    MethodPost(url, typeuser)
      .then((res) => {
        Swal.fire({
          title: "Permisos Asignados",
          text: "Los permisos se asignaron correctamente, al tipo de usuario",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        dispatch({
          type: AGREGATE_PERMISSIONS,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.message,
        });
      });
  };

  const UpdatePermissions = (typeuser) => {
    console.log(typeuser, "los datos en el sttae");
    const formData = new FormData();
    formData.append("modules", typeuser.modules.modules);
    let url = `/permissions/${typeuser.id}`;
    MethodPost(url, typeuser)
      .then((res) => {
        Swal.fire({
          title: "Permisos Actualizados",
          text: "Los permisos se asignaron correctamente, al tipo de usuario",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        dispatch({
          type: UPDATE_PERMISSIONS,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.message,
        });
      });
  };

  //funcion para editar el tipo de usuario
  const UpdateTypeUsers = (typeuser) => {
    const formData = new FormData();
    formData.append("name", typeuser.name);

    let url = `/type_users/${typeuser._id}`;
    MethodPut(url, formData)
      .then((res) => {
        Swal.fire({
          title: "Buen trabajo",
          text: res.data.message,
          timer: 3000,
          showConfirmButton: false,
          icon: "success",
        });
        dispatch({
          type: UPDATE_TYPE_USERS,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.res.data.error,
        });
      });
  };

  //Eliminar un typo de usuario
  const DeleteTypeUsers = (_id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "El tipo de usuario seleccionado será eliminado",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/type_users/${_id}`;
        //let history = useHistory();
        MethodDelete(url)
          .then((res) => {
            Swal.fire({
              title: "Eliminado!",
              text: "El tipo de usuario sea eliminado",
              icon: "success",
              timer: 1000,
              showConfirmButton: false,
              showCancelButton: false,
            });
            dispatch({
              type: DELETE_TYPE_USERS,
              payload: _id,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              timer: 2000,
              showConfirmButton: false,
            });
          });
      }
    });
  };

  //funcion para seleccionar una tipo de usuario
  const SaveCurrentTypeUser = (typeuser) => {
    sessionStorage.setItem("typeuser", JSON.stringify(typeuser));
    dispatch({
      type: CURRENT_TYPE_USER,
      payload: typeuser,
    });
  };

  return (
    <TypeUsersContext.Provider
      value={{
        type_users: state.type_users,
        typeuser: state.typeuser,
        ErrorsApi: state.ErrorsApi,
        success: state.success,
        GetTypeUsers,
        AddTypeUsers,
        UpdateTypeUsers,
        DeleteTypeUsers,
        SaveCurrentTypeUser,
        AddPermissions,
        UpdatePermissions,
      }}>
      {children}
    </TypeUsersContext.Provider>
  );
};

export default TypeUsersState;
