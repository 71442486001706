import React, { useContext, useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { makeStyles } from "@mui/styles";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import DashboardContext from "../../../context/Dashboard/DashboardContext";
import { Button } from "@mui/material";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import ModalChangePriceShippment from "./ModalChanguePriceShippment";
import ShippmentInternationalContext from "../../../context/ShippmentInternational/ShippmentInternationalContext";
const useStyles = makeStyles(() => ({
	dollar: {
		objectFit: "cover",
	},
	div_ganancias: {
		marginRight: "5%",
		marginLeft: "5%",
	},
}));

const CardShippingInternational = () => {
	const classes = useStyles();
	const [modal, AbrirModal] = useState(false);

	const handleClickOpenModal = () => {
		AbrirModal(true);
	};

	const handleClickCloseModal = () => {
		AbrirModal(false);
	};

	const {
		shippment_inter,
		GetShippmentInternational,
		success,
		SaveCurrentShippmentInternational,
		shippment_int,
		UpdateShippmentInternational,
	} = useContext(ShippmentInternationalContext);

	useEffect(() => {
		GetShippmentInternational();
	}, [success]);
	return (
		<Card
			style={{ backgroundColor: "#6a1b9a" }}
			sx={{
				boxShadow: 4,
				display: "flex",
				alignItems: "center",
				flexDirection: "row",
			}}
		>
			<div>
				<FlightTakeoffIcon
					style={{
						fontSize: 60,
						marginLeft: 10,
						marginTop: 10,
						marginBottom: 10,
						color: "white",
					}}
				/>
			</div>
			<div className={classes.div_ganancias}>
				<Typography
					component="div"
					variant="h5"
					sx={{
						fontWeight: "bold",
						marginTop: 1,
						fontSize: 20,
						textAlign: "justify",
						color: "white",
						border: "1px",
						borderColor: "green",
					}}
				>
					Envio internacional
				</Typography>
				<Typography
					sx={{
						fontSize: 23,
						color: "white",
						border: "1px",
						borderColor: "green",
						//textAlign: "center",
					}}
				>
					{shippment_inter && "$ " + Number(shippment_inter).toFixed(2)}
				</Typography>
				<Typography
					sx={{
						fontSize: 10,
						color: "white",
						border: "1px",
						borderColor: "green",
					}}
				>
					<Button
						onClick={(e) => {
							//UpdateShippmentInternational(shippment_inter._id);
							handleClickOpenModal();
						}}
						variant="contained"
						fullWidth
						sx={{
							marginBottom: 1,
							backgroundColor: "#f06292",
							padding: 0,
							textTransform: "none",
							lineHeight: 0,
							"&:hover": { backgroundColor: "#f06292" },
						}}
						startIcon={<CurrencyExchangeIcon />}
					>
						Cambiar
					</Button>
				</Typography>
			</div>
			<ModalChangePriceShippment
				modal={modal}
				handleClickOpen={handleClickOpenModal}
				handleClose={handleClickCloseModal}
			/>
		</Card>
	);
};
export default CardShippingInternational;
