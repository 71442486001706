import {
  SALES_APPROVE_PAYMENT,
  SALES_CANCEL,
  SALES_PENDING_PAYMENT,
  AUTHORIZE_SALE,
  RESTORE_SALE,
  CANCEL_ALL_SALES_EXPIRED,
  GET_ALL_SALES,
  CANCEL_SALE,
  GET_ALL_SALES_PENDING_PAYMENT_WITHOUT_PAGINATION,
  UPDATE_ORDER,
  ADD_EVIDENCE,
  UPDATE_EVIDENCE,
  SEARCH_SALES,
  ADD_COMMENTS_ORDER,
  ENABLE_UPDATE_SALE,
  DISABLED_UPDATE_SALE,
  ENABLE_UPDATE_SALE_PENDING_PAY,
  DISABLE_UPDATE_SALE_PENDING_PAY,
  UPDATE_SHIPPING_COST,
  ADD_NEW_SHIPPMENT,
  CREATE_ORDER_FROM_EXCEL,
  COMPLETE_ORDER_EXCEL,
  CANCEL_ORDERS_ATM,
} from "../../types";

export default function SalesReducer(state, action) {
  switch (action.type) {
    case GET_ALL_SALES:
      return {
        ...state,
        sales: action.payload.sales,
        totalDocs: action.payload.totalDocs,
        limit: action.payload.limit,
        page: action.payload.page,
        paginCounter: action.payload.paginCounter,
        hasPrevPage: action.payload.hasPrevPage,
        hasNextPage: action.payload.hasNextPage,
        prevPage: action.payload.prevPage,
        nextPage: action.payload.nextPage,
        totalPages: action.payload.totalPages,
        success: false,
        ErrorsApi: [],
      };

    case SALES_PENDING_PAYMENT:
      return {
        ...state,
        salesPending: action.payload.salesPending,
        totalDocs: action.payload.totalDocs,
        limit: action.payload.limit,
        page: action.payload.page,
        paginCounter: action.payload.paginCounter,
        hasPrevPage: action.payload.hasPrevPage,
        hasNextPage: action.payload.hasNextPage,
        prevPage: action.payload.prevPage,
        nextPage: action.payload.nextPage,
        totalPages: action.payload.totalPages,
        success: false,
        ErrorsApi: [],
      };
    case SALES_APPROVE_PAYMENT:
      return {
        ...state,
        salesApprove: action.payload,
        success: true,
        ErrorsApi: [],
      };
    case SALES_CANCEL:
      return {
        ...state,
        success: false,
        salesCancel: action.payload.salesCancel,
        totalDocs: action.payload.totalDocs,
        limit: action.payload.limit,
        page: action.payload.page,
        paginCounter: action.payload.paginCounter,
        hasPrevPage: action.payload.hasPrevPage,
        hasNextPage: action.payload.hasNextPage,
        prevPage: action.payload.prevPage,
        nextPage: action.payload.nextPage,
        totalPages: action.payload.totalPages,
        ErrorsApi: [],
      };
    case GET_ALL_SALES_PENDING_PAYMENT_WITHOUT_PAGINATION:
      return {
        ...state,
        success: false,
        salesPendingWithoutPagination: action.payload,
        ErrorsApi: [],
      };
    case AUTHORIZE_SALE:
      return {
        ...state,
        success: true,
        ErrorsApi: [],
      };
    case ADD_COMMENTS_ORDER:
      return {
        ...state,
        success: true,
        ErrorsAPI: [],
      };
    case CANCEL_SALE:
      return {
        ...state,
        success: true,
        ErrorsApi: [],
      };
    case CANCEL_ORDERS_ATM:
      return {
        ...state,
        success: true,
        ErrorsAPI: [],
      };
    case RESTORE_SALE:
      return {
        ...state,
        success: true,
        ErrorsApi: [],
      };
    case CANCEL_ALL_SALES_EXPIRED:
      return {
        ...state,
        success: true,
        ErrorsApi: [],
      };
    case UPDATE_ORDER:
      return {
        ...state,
        success: true,
        ErrorsApi: [],
      };
    case ADD_EVIDENCE:
      return {
        ...state,
        //success: false,
        evidences: action.payload,
        ErrorsAPI: [],
      };
    case UPDATE_EVIDENCE:
      return {
        ...state,
        evidences: action.payload,
        ErrorsAPI: [],
      };
    case SEARCH_SALES:
      return {
        ...state,
        searchSales: action.payload,
        success: false,
        ErrorsAPI: [],
      };
    case ENABLE_UPDATE_SALE:
      return {
        ...state,
        sales: state.sales.map((sale) =>
          sale._id === action.payload._id ? { ...action.payload } : sale
        ),
        success: true,
        ErrorsAPI: [],
      };
    case DISABLED_UPDATE_SALE:
      return {
        ...state,
        sales: state.sales.map((sale) =>
          sale._id === action.payload._id ? { ...action.payload } : sale
        ),
        success: true,
        ErrorsAPI: [],
      };
    case UPDATE_SHIPPING_COST:
      return {
        ...state,
        sales: state.sales.map((sale) =>
          sale._id === action.payload._id ? { ...action.payload } : sale
        ),
        success: true,
        ErrorsAPI: [],
      };
    case ENABLE_UPDATE_SALE_PENDING_PAY:
      return {
        ...state,
        salesPending: state.salesPending.map((sale) =>
          sale._id === action.payload._id ? { ...action.payload } : sale
        ),
        success: true,
        ErrorsAPI: [],
      };
    case DISABLE_UPDATE_SALE_PENDING_PAY:
      return {
        ...state,
        salesPending: state.salesPending.map((sale) =>
          sale._id === action.payload._id ? { ...action.payload } : sale
        ),
        success: true,
        ErrorsAPI: [],
      };
    case ADD_NEW_SHIPPMENT: {
      return {
        ...state,
        success: true,
        ErrorsAPI: [],
      };
    }
    case CREATE_ORDER_FROM_EXCEL:
      return {
        ...state,
        success: true,
        ErrorsAPI: [],
      };
    case COMPLETE_ORDER_EXCEL:
      console.log(action.payload, "el payload");
      return {
        ...state,
        //salesPending: [action.payload.order, ...state.salesPending],
        statusOrder: action.payload.statusOrder,
        success: true,
        ErrorsAPI: [],
      };
    default:
      return state;
  }
}
