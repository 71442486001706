import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Editor } from "@tinymce/tinymce-react";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { EditIcon } from "../../components/icons/EditIcon";
import { DeleteIcon } from "../../components/icons/DeleteIcon";
import PoliciesContext from "../../context/Policies/PoliciesContext";
import { useContext } from "react";
export default function CardPolicies({ policie }) {
  const { DeletePolicie } = useContext(PoliciesContext);
  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {policie.title}
        </Typography>
        <Editor
          apiKey="4oemglfeurpxtmylg59yopah4kn16o7e3ma4ryvm44spbnlr"
          initialValue={policie.text}
          inline={true}
          disabled={true}
        />
      </CardContent>
      <CardActions>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            display="flex"
            justifyContent="end">
            <Link to={`/EditarPoliticas/${policie._id}`}>
              <Button
                size="large"
                //variant="contained"
                sx={{
                  backgroundColor: "white",
                  "&:hover": { backgroundColor: "white" },
                }}>
                <EditIcon />
              </Button>
            </Link>
            <Button size="large" onClick={() => DeletePolicie(policie._id)}>
              <DeleteIcon />
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
