import React, { useReducer } from "react";
import CategoriesContext from "./CategoriesContext";
import CategoriesReducer from "./CategoriesReducer";
import MethodGet, {
  MethodPost,
  MethodPut,
  MethodDelete,
} from "../../config/Service";
import {
  ADD_CATEGORIES,
  CURRENT_CATEGORIES,
  DELETE_CATEGORIES,
  GET_ALL_CATEGORIES,
  IMPORT_CATEGORIES_EXCEL,
  GET_CATEGORY,
  SEARCH_CATEGORY,
  SHOW_ERRORS_API,
  UPDATE_CATEGORIES,
} from "../../types";
import Swal from "sweetalert2";
import headerConfig from "../../config/imageHeaders";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CategoriesState = ({ children }) => {
  //estado_inicial
  const initialState = {
    categories: [],
    search_categories: [],
    category: null,
    ErrorsApi: [],
    success: false,
  };

  //dispatch que ejecuta las acciones
  const [state, dispatch] = useReducer(CategoriesReducer, initialState);
  //obtener la lista de categorias

  const GetCategories = () => {
    sessionStorage.removeItem("category");
    let url = `/categories`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_CATEGORIES,
          payload: res.data.categories,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  const getCategory = (id) => {
    dispatch({
      type: GET_CATEGORY,
      payload: id,
    });
  };
  const searchCategory = (palabra) => {
    let url = `search/categories?search=${palabra}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: SEARCH_CATEGORY,
          payload: res.data.results,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
      });
  };
  const AddCategories = (category) => {
    const formData = new FormData();
    formData.append("name", category.name);
    formData.append("imageWeb", category.imageWeb);
    formData.append("imageMobile", category.imageMovil);
    //formData.append("typeCategory", category.typeCategory);
    //formData.append("brand", category.brand);
    let url = "/categories/";
    MethodPost(url, formData, { headerConfig })
      .then((res) => {
        Swal.fire({
          title: "Categoria Creada",
          text: res.data.message,
          icon: "success",
          timer: 1000,
          showCancelButton: false,
          showConfirmButton: false,
        });
        dispatch({
          type: ADD_CATEGORIES,
          payload: res.data.category,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.res.data.error,
        });
      });
  };

  const ImportCategoriesExcel = (categories) => {
    const formData = new FormData();
    formData.append("excel", categories.file);

    let url = "/categories/import-excel";
    MethodPost(url, formData, { headerConfig })
      .then((res) => {
        toast.success("Categorias Importadas Exitosamente!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        dispatch({
          type: IMPORT_CATEGORIES_EXCEL,
        });
      })
      .catch((error) => {
        error.response.data.error.map((response) =>
          toast.error(
            <div>
              Error En Celda {response.row} {response.errors[0]}
            </div>,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          )
        );
      });
  };

  const ChangeCategory = (data) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("imageWeb", data.imageWeb);
    formData.append("imageMobile", data.imageMobile);
    //formData.append("typeCategory", data.typeCategory);
    //formData.append("brand", category.brand);
    let url = `/categories/${data.id}`;
    MethodPut(url, formData, { headerConfig })
      .then((res) => {
        Swal.fire({
          title: "Categoria Modificada",
          text: res.data.message,
          icon: "success",
          timer: 1000,
          showCancelButton: false,
          showConfirmButton: false,
        });
        dispatch({
          type: UPDATE_CATEGORIES,
          payload: res.data.category,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "Ocurrio un error al actualizar la categoría",
          icon: "error",
          showConfirmButton: false,
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.res.data.error,
        });
      });
  };

  //Eliminar categoria
  const DeleteCategory = (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "La categoria seleccionada será eliminada",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/categories/${id}`;
        MethodDelete(url)
          .then((res) => {
            Swal.fire({
              title: "Eliminado",
              text: res.data.message,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            dispatch({
              type: DELETE_CATEGORIES,
              payload: id,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              timer: 2000,
              showConfirmButton: false,
            });
          });
      }
    });
  };

  //funcion para seleccionar una categoria
  const SaveCurrentCategory = (category) => {
    sessionStorage.setItem("category", JSON.stringify(category));
    dispatch({
      type: CURRENT_CATEGORIES,
      payload: category,
    });
  };

  return (
    <CategoriesContext.Provider
      value={{
        categories: state.categories,
        category: state.category,
        ErrorsApi: state.ErrorsApi,
        success: state.success,
        search_categories: state.search_categories,
        GetCategories,
        AddCategories,
        ChangeCategory,
        DeleteCategory,
        SaveCurrentCategory,
        ImportCategoriesExcel,
        searchCategory,
        getCategory,
      }}>
      {children}
    </CategoriesContext.Provider>
  );
};

export default CategoriesState;
