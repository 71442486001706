import {
  GET_ALL_ACCEPTED,
  GET_ALL_PENDING,
  GET_ALL_REJECTED,
  ACCEPT_INFLUENCER,
  REJECTED_INFLUENCER,
  GET_ALL_PENDING_DASHBOARD,
  ABOUT_INFLUENCERS,
  GET_ABOUT_INFLUENCERS,
} from "../../types";

export default function InfluencersReducer(state, action) {
  switch (action.type) {
    case GET_ALL_ACCEPTED:
      return {
        ...state,
        influencersAccepted: action.payload,
        success: false,
        ErrorsApi: [],
      };

    case GET_ABOUT_INFLUENCERS:
      return {
        ...state,
        influencersAccepted: action.payload,
        success: false,
        ErrorsApi: [],
      };

    case GET_ALL_REJECTED:
      return {
        ...state,
        influencersRejected: action.payload,
        success: false,
        ErrorsApi: [],
      };

    case GET_ALL_PENDING:
      return {
        ...state,
        influencersPending: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case GET_ALL_PENDING_DASHBOARD:
      return {
        ...state,
        influencersindex: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case ACCEPT_INFLUENCER:
      return {
        ...state,
        success: true,
        ErrorsApi: [],
      };
    case ABOUT_INFLUENCERS:
      return {
        ...state,
        success: true,
        ErrorsApi: [],
      };
    case REJECTED_INFLUENCER:
      return {
        ...state,
        success: true,
        ErrorsApi: [],
      };

    default:
      return state;
  }
}
