import React from "react";
import {
  ADD_POLICIES,
  DELETE_POLICIE,
  GET_POLICIES,
  UPDATE_POLICIES,
} from "../../types";

const PoliciesReducer = (state, action) => {
  switch (action.type) {
    case GET_POLICIES:
      return {
        ...state,
        policies: action.payload,
        success: false,
        ErrorsAPI: [],
      };

    case ADD_POLICIES:
      return {
        ...state,
        policies: [action.payload, ...state.policies],
        success: true,
        ErrorsAPI: [],
      };
    case UPDATE_POLICIES:
      return {
        ...state,
        success: true,
        policies: state.policies.map((p) =>
          p._id === action.payload._id ? { ...action.payload } : p
        ),
        ErrorsAPI: [],
      };
    case DELETE_POLICIE:
      return {
        ...state,
        policies: state.policies.filter((p) => p._id !== action.payload),
        ErrorsAPI: [],
        success: true,
      };
    default:
      return state;
  }
};

export default PoliciesReducer;
