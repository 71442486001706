import React, { useState, useEffect, useContext } from "react";
import ResetPasswordContext from "../../../context/resetPassword/ResetPasswordContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import { Button, Grid, Box, TextField, Typography } from "@mui/material/";
import SendIcon from "@mui/icons-material/Send";

const useStyles = makeStyles({
  gridimg: {
    display: "flex",
    margin: "auto",
    align: "center",
    ["@media screen and (max-width: 600px)"]: {
      // ['@media screen and (min-width: 400px) and (max-width: 480px)']:{
      display: "none !important",
      margin: "0 !important",
    },
  },
  viewforgot: {
    backgroundColor: "#ec407a",
    height: "100vh",
  },
  caja: {
    backgroundColor: "white",
    borderRadius: "1.75rem",
    align: "center",
    marginTop: "5%",
  },
  btnsubmit: {
    borderRadius: "50px",
    border: "1px",
    width: "100%",
    height: "40px",
    color: "white",
    backgroundColor: "#15c0cc",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#31cdd8",
      color: "white",
      fontWeight: "bold",
    },
  },
  contenedorimg: {
    paddingTop: "0%",
  },
  textcolor: {
    color: "#F68E15",
    fontWeight: "bold",
  },
  back: {
    display: "flex",
    justifyContent: "flex-end",
  },
  flooting: {
    backgroundColor: "orange",
  },
});

function Copyright(props) {
  return (
    <Typography variant="body2" color="white" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" to="https://digital-pineapple.com.mx/">
        Digital Pineapple
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function PasswordWebValidation(props) {
  const { token, userId } = props.match.params;
  const classes = useStyles();
  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: (formData) => {},
  });

  const { resetPassword, setUserPassword } = useContext(ResetPasswordContext);

  //recuperar el token de la url
  /*const { token } = useParams();*/

  const [state, setState] = useState({
    password: "",
    confirm_password: "",
  });

  const handleChangeData = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      props.history.push("/dashboard");
    }
  }, []);

  //si retorna success redirect a login
  useEffect(() => {
    if (setUserPassword) {
      props.history.push("/login");
    }
  }, [setUserPassword]);

  //pasamos la data para el cambio de contraseña
  const handleResetPassword = (e) => {
    e.preventDefault();
    const data = { ...state };
    data.token = token;
    data.userId = userId;
    resetPassword(data);
  };

  return (
    <div className={classes.viewforgot}>
      <Grid container>
        <Grid item xs={12} lg={6} md={6} xl={6}>
          <div className={classes.contenedorimg}>
            <img
              src={require("../../../assets/gif/reset-password.gif")}
              alt="passmobile"
              className={classes.gridimg}
            />
          </div>
        </Grid>
        <Grid item xs={12} lg={6} md={6} xl={6}>
          <div
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{ boxShadow: 3, m: 5, padding: 7 }}
              className={classes.caja}
            >
              <h1 className="text-center text-2xl font-semibold text-black">
                Restablece su Contraseña
              </h1>
              <form onSubmit={handleResetPassword}>
                <div className="text-center text-principal text-sm  py-3 ">
                  <TextField
                    fullWidth
                    label="Nueva Contraseña"
                    name="password"
                    type="password"
                    onChange={(e) => handleChangeData(e)}
                    error={formik.errors?.newpasss ? true : false}
                  />
                </div>
                <div className="text-center text-principal text-sm  py-3 ">
                  <TextField
                    fullWidth
                    label="Confirma Contraseña"
                    name="confirm_password"
                    type="password"
                    onChange={(e) => handleChangeData(e)}
                    error={formik.errors?.repeatpass ? true : false}
                  />
                </div>
                <div className="mt-1">
                  <div>
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{
                        width: "100%",
                        backgroundColor: "#15c0cc",
                        color: "white",
                        fontWeight: "bold",
                        "&:hover": {
                          backgroundColor: "#31cdd8",
                        },
                      }}
                      endIcon={<SendIcon />}
                    >
                      Guardar Cambios
                    </Button>
                  </div>
                </div>
              </form>
            </Box>
          </div>
          <Copyright />
        </Grid>
      </Grid>
    </div>
  );
}

function initialValues() {
  return {
    email: "",
  };
}

function validationSchema() {
  return {
    email: Yup.string().email(true).required("Correo requerido"),
  };
}
