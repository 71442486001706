import React, { useContext, useState } from "react";
// material
import {
  Box,
  Card,
  Typography,
  Tooltip,
  Grid,
  Menu,
  MenuItem,
  IconButton,
  Button,
} from "@mui/material";
import ModalImage from "react-modal-image-responsive";
import MoreVertIcon from "@mui/icons-material/MoreVert";
//Estilos , Icons y Alerta
import { makeStyles } from "@mui/styles";
import ModalEdit from "../../../containers/Administration/Accounts/ModalEdit";
import AccountsContext from "../../../context/Accounts/AccountsContext";
import Marquee from "react-fast-marquee";
import { EditIcon } from "../../icons/EditIcon";
import { DeleteIcon } from "../../icons/DeleteIcon";

// ----------------------------------------------------------------------

const useStyles = makeStyles({
  Imagelogo: {
    height: "131px",
    objectFit: "cover",
    textAlign: "center",
  },
});

const AccountCard = ({ account, permiss }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //Menu
  const classes = useStyles();

  const [modalUpdate, AbrirModalUpdate] = useState(false);
  const handleClickOpenUpdate = () => {
    AbrirModalUpdate(true);
  };
  const handleClickCloseUpdate = () => {
    AbrirModalUpdate(false);
  };

  const [id_account, setIdAccount] = useState();
  const { DeleteAccounts } = useContext(AccountsContext);

  const selectAccount = (account) => {
    setIdAccount(account);
    handleClickOpenUpdate();
  };

  return (
    <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
      <Card sx={{ boxShadow: 3 }}>
        <Box
          fullWidth
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            paddingX: 2,
            backgroundColor: "white",
            color: "black",
          }}
        >
          <Typography
            variant="su arrowbtitle2"
            noWrap
            sx={{ fontWeight: "bold", fontSize: "17px" }}
            className="blink-1"
          >
            <Marquee play={false} gradient={false} speed={45}>
              {account.beneficiary}
            </Marquee>
          </Typography>
        </Box>
        {account.typeAccount !== 2 && (
          <Box
            fullWidth
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <ModalImage
              className={classes.Imagelogo}
              small={account.bank.image}
              large={account.bank.image}
              alt={account.bank.name}
            />
          </Box>
        )}
        <Box
          fullWidth
          justifyContent="center"
          display="flex"
          alignItems="center"
          sx={{ paddingX: 2 }}
        >
          <Typography sx={{ fontWeight: "bold", fontSize: "17px" }}>
            N°. CUENTA:
          </Typography>
        </Box>
        <Box
          fullWidth
          justifyContent="center"
          display="flex"
          alignItems="center"
          sx={{ paddingX: 2 }}
        >
          <Typography sx={{ fontSize: "14px" }}>
            {account.account_number}
          </Typography>
        </Box>
        <Box
          fullWidth
          display="flex"
          justifyContent="center"
          sx={{ paddingX: 2 }}
        >
          <Typography
            variant="su arrowbtitle2"
            noWrap
            sx={{ fontWeight: "bold", fontSize: "17px" }}
          >
            CLABE:
          </Typography>
        </Box>
        <Box
          fullWidth
          display="flex"
          justifyContent="center"
          sx={{ paddingX: 2, paddingBottom: 2 }}
        >
          <Typography
            variant="su arrowbtitle2"
            noWrap
            sx={{ fontSize: "14px" }}
          >
            {account.interbank}
          </Typography>
        </Box>
        <Box
          fullWidth
          justifyContent="center"
          display="flex"
          alignItems="center"
          sx={{ paddingX: 2 }}
        >
          <Typography sx={{ fontWeight: "bold", fontSize: "17px" }}>
            Tarjeta Asociada:
          </Typography>
        </Box>
        <Box
          fullWidth
          justifyContent="center"
          display="flex"
          alignItems="center"
          sx={{ paddingX: 2 }}
        >
          <Typography sx={{ fontSize: "14px" }}>
            {account.card_number}
          </Typography>
        </Box>
        <Box
          fullWidth
          justifyContent="center"
          display="flex"
          alignItems="center"
          sx={{ paddingX: 2 }}
        >
          <Typography sx={{ fontWeight: "bold", fontSize: "17px" }}>
            Tipo cuenta:
          </Typography>
        </Box>
        <Box
          fullWidth
          justifyContent="center"
          display="flex"
          alignItems="center"
          sx={{ paddingX: 2 }}
        >
          <Typography sx={{ fontSize: "14px" }}>
            {account.typeAccount === 1 ? "Ecommerce" : "Venta fisica"}
          </Typography>
        </Box>
        <Grid container spacing={2}>
        {permiss.update === true && (

          <Grid item xs={6} display="flex" justifyContent="center">
            <Tooltip title="Editar" placement="top">
              <Button
                onClick={() => {
                  selectAccount(account._id);
                }}
              >
                <EditIcon />
              </Button>
            </Tooltip>
          </Grid>
        )}
                {permiss.delete === true && (

          <Grid item xs={6} display="flex" justifyContent="center">
            <Tooltip title="Eliminar" placement="top">
              <Button
                onClick={() => {
                  DeleteAccounts(account._id);
                }}
              >
                <DeleteIcon />
              </Button>
            </Tooltip>
          </Grid>
                )}
        </Grid>
      </Card>

      {id_account && (
        <ModalEdit
          modalUpdate={modalUpdate}
          id={id_account}
          handleClickOpen={handleClickOpenUpdate}
          handleCloseEdit={handleClickCloseUpdate}
        />
      )}
    </Grid>
  );
};

export default AccountCard;
