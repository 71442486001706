import React, { useContext } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

import { makeStyles } from "@mui/styles";
import AuthContext from "../../context/auth/authContext";

const useStyles = makeStyles(() => ({
	dollar: {
		objectFit: "cover",
	},
	div_ganancias: {
		marginleft: "auto",
		marginright: 0,
	},
}));

const CardUserLogged = () => {
	const { usuario } = useContext(AuthContext);
	const date = new Date();
	const hour = date.getHours();

	return (
		//mañana
		<>
			{hour >= 0 && hour < 9 ? (
				<Card
					sx={{
						boxShadow: 4,
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						flexDirection: "row",
						background:
							"linear-gradient(to  bottom,#929CC4,#929CC4,#929CC4,#929CC4,#929CC4,#929CC4,#929CC4,#929CC4,#D2B4C9,#D2B4C9,#0B2067, #02040B,#02040B)",
					}}
				>
					<div>
						<Typography
							component="div"
							variant="h4"
							sx={{
								fontWeight: "bold",
								color: "white",
								border: "1px",
								borderColor: "green",
								marginLeft: 5,
							}}
						>
							Buenos días
							{usuario && usuario.user.type_user_id.name == "Distribuidor"
								? " socio distribuidor,"
								: ""}
							<br />
							{usuario && usuario.user.fullname}
						</Typography>
					</div>
					<div>
						<img
							src={require("../../assets/gif/new_morning.gif")}
							width={150}
							height={150}
						/>
					</div>
				</Card>
			) : hour >= 9 && hour < 12 ? (
				<Card
					sx={{
						boxShadow: 4,
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						flexDirection: "row",
						background:
							"linear-gradient(to right bottom, #01579b,#64b5f6, #fff9c4)",
					}}
				>
					<div>
						<Typography
							component="div"
							variant="h4"
							sx={{
								fontWeight: "bold",
								color: "white",
								border: "1px",
								borderColor: "green",
								marginLeft: 5,
							}}
						>
							Buenos días
							{usuario && usuario.user.type_user_id.name == "Distribuidor"
								? " socio distribuidor,"
								: ""}
							<br />
							{usuario && usuario.user.fullname}
						</Typography>
					</div>
					<div>
						<img
							src={require("../../assets/gif/coffee.gif")}
							width={150}
							height={150}
						/>
					</div>
				</Card>
			) : hour >= 12 && hour < 17 ? (
				//tarde
				<Card
					sx={{
						boxShadow: 4,
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						flexDirection: "row",
						background: "linear-gradient(to  bottom,#1565c0,#1565c0, #90caf9)",
					}}
				>
					<div>
						<Typography
							component="div"
							variant="h4"
							sx={{
								fontWeight: "bold",
								color: "white",
								border: "1px",
								borderColor: "green",
								marginLeft: 5,
							}}
						>
							Buenas Tardes{" "}
							{usuario && usuario.user.type_user_id.name == "Distribuidor"
								? " socio distribuidor,"
								: ""}
							<br />
							{usuario && usuario.user.fullname}
						</Typography>
					</div>
					<div>
						<img
							src={require("../../assets/gif/day.gif")}
							width={150}
							height={150}
						/>
					</div>
				</Card>
			) : hour >= 17 && hour <= 20 ? (
				<Card
					sx={{
						boxShadow: 4,
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						flexDirection: "row",
						background:
							"linear-gradient( #755EB7,#755EB7,#755EB7,#7A61B8,#7A61B8,#7A61B8,#7A61B8,#9C73B9,#9C73B9,#9C73B9,#C88CBA,#C88CBA,#FEAEB6,#FEAEB6,#FCD082,#FCD082,#FBE054,#FBE054,#FBE054,#FCC848,#FCC848,#FDA637,#FDA637,#FDA637,#FDA637,#FF5E12,#FF5E12,#FBE561,#FBE561,#FBE561,#F7A6BC,#150D49,#150D49)",
					}}
				>
					<div>
						<Typography
							component="div"
							variant="h4"
							sx={{
								fontWeight: "bold",
								color: "white",
								border: "1px",
								borderColor: "green",
								marginLeft: 5,
							}}
						>
							Buenas tardes{" "}
							{usuario && usuario.user.type_user_id.name == "Distribuidor"
								? " socio distribuidor,"
								: ""}
							<br />
							{usuario && usuario.user.fullname}
						</Typography>
					</div>
					<div>
						<img
							src={require("../../assets/gif/sunset.gif")}
							width={150}
							height={150}
						/>
					</div>
				</Card>
			) : (
				//noche
				<Card
					sx={{
						boxShadow: 4,
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						flexDirection: "row",
						background:
							"linear-gradient(to  bottom, #352173,#351D71,#396B9C,#396B9C,#396B9C,#396B9C,#2E3664,#2E3664,#181549,#181549)",
					}}
				>
					<div>
						<Typography
							component="div"
							variant="h4"
							sx={{
								fontWeight: "bold",
								color: "white",
								border: "1px",
								borderColor: "green",
								marginLeft: 5,
							}}
						>
							Buenas Noches{" "}
							{usuario && usuario.user.type_user_id.name == "Distribuidor"
								? " socio distribuidor,"
								: ""}
							<br />
							{usuario && usuario.user.fullname}
						</Typography>
					</div>
					<div>
						<img
							src={require("../../assets/gif/nigth.gif")}
							width={150}
							height={150}
						/>
					</div>
				</Card>
			)}
		</>
	);
};

export default CardUserLogged;
