import React, { useContext, useEffect, useState } from "react";
import ResetPasswordContext from "../../../context/resetPassword/ResetPasswordContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import { Button, Grid, Box, Typography, TextField } from "@mui/material/";
import SendIcon from "@mui/icons-material/Send";

const useStyles = makeStyles({
  textlogin: {
    fontSize: "15px",
    color: "black",
    fontWeight: "bold",
    fontStyle: "oblique",
    letterSpacing: "1px",
  },
  mainSignIn: {
    position: "relative",
  },
  imagen: {
    position: "absolute",
    top: "1px",
    right: "22%",
  },
  gridimg: {
    display: "flex",
    margin: "auto",
    align: "center",
    ["@media screen and (max-width: 680px)"]: {
      // ['@media screen and (min-width: 400px) and (max-width: 480px)']:{
      display: "none",
      margin: "0",
    },
  },
  viewforgot: {
    backgroundColor: "#ec407a",
    height: "100vh",
  },
  caja: {
    backgroundColor: "white",
    borderRadius: "1.75rem",
    align: "center",
    marginTop: "10%",
  },
  btnsubmit: {
    borderRadius: "50px",
    border: "1px",
    width: "100%",
    height: "40px",
    color: "white",
    backgroundColor: "#15c0cc",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#31cdd8",
      color: "white",
      fontWeight: "bold",
    },
  },
  contenedorimg: {
    paddingTop: "5%",
  },
  textcolor: {
    color: "black",
    fontWeight: "bold",
  },
  back: {
    display: "flex",
    justifyContent: "flex-end",
  },
  flooting: {
    backgroundColor: "orange",
  },
});

function Copyright(props) {
  return (
    <Typography variant="body2" color="white" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" to="https://digital-pineapple.com.mx/">
        Digital Pineapple
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function PasswordWebValidation(props) {
  const classes = useStyles();
  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: (formData) => {},
  });
  //send-email
  const { sendResetLinkEmail } = useContext(ResetPasswordContext);
  const [email, saveEmail] = useState("");

  //guardar el email en el state
  const handleStoreEmail = (e) => {
    saveEmail({
      [e.target.name]: e.target.value,
    });
  };

  //verificar que no se encuentre la sesion activa
  useEffect(() => {
    if (localStorage.getItem("token")) {
      props.histoty.push("/dashboard");
    }
  }, []);

  //pasar el valor del email para enviar el link
  const handleSubmit = (e) => {
    e.preventDefault();
    sendResetLinkEmail(email);
  };

  return (
    <div className={classes.viewforgot}>
      <Grid container>
        <Grid item xs={12} lg={6} md={6} xl={6}>
          <div className={classes.contenedorimg}>
            <img
              src={require("../../../assets/gif/forgot-password.gif")}
              alt="passmobile"
              className={classes.gridimg}
            />
          </div>
        </Grid>
        <Grid item xs={12} lg={6} md={6} xl={6}>
          <div
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{ boxShadow: 3, m: 2, padding: 7 }}
              className={classes.caja}
            >
              <h1 className="text-center text-2xl font-semibold text-black">
                Recuperacion de Contraseña
              </h1>

              <form onSubmit={handleSubmit}>
                <div className="text-center text-principal text-sm  py-2 ">
                  <TextField
                    fullWidth
                    id="email"
                    label="Correo Electronico"
                    name="email"
                    autoComplete="email"
                    onChange={handleStoreEmail}
                    error={formik.errors?.email ? true : false}
                  />
                </div>
                <div className="mt-1">
                  <div>
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{
                        width: "100%",
                        backgroundColor: "#15c0cc",
                        color: "white",
                        fontWeight: "bold",
                        "&:hover": {
                          backgroundColor: "#31cdd8",
                        },
                      }}
                      endIcon={<SendIcon />}
                    >
                      Confirmar
                    </Button>
                  </div>
                </div>
              </form>
              <div className="mt-1">
                <div className="text-center text-principal text-sm">
                  <p className={classes.textcolor}>
                    ¿No has recibido ningun correo?
                  </p>
                </div>
              </div>
              <div className="mt-1">
                <div className="text-center text-principal text-black text-sm ">
                  <p>Da click en el siguiente botón para que</p>
                  <p>te enviemos un nuevo enlace</p>
                </div>
              </div>

              <div className="mt-1">
                <div className="text-center text-principal text-sm">
                  <p className={classes.textcolor}>¿Ya la recordaste?</p>
                </div>
                <div className="m-1">
                  <Link to="/Iniciar-sesion">
                    <button className={classes.btnsubmit}>
                      Regresar al Inicio de Sesion
                    </button>
                  </Link>
                </div>
              </div>
            </Box>
          </div>
        </Grid>
      </Grid>
      <Copyright />
    </div>
  );
}

function initialValues() {
  return {
    email: "",
  };
}

function validationSchema() {
  return {
    email: Yup.string().email(true).required("Correo requerido"),
  };
}
