import {
  Grid,
  Fab,
  TextField,
  Tooltip,
  InputLabel,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CategoriesContext from "../../context/CategoriesP/CategoriesContext";
import MethodGet from "../../config/Service";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CircularProgress from "@mui/material/CircularProgress";

import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import BrandSelect from "../SelectOptions/BrandSelect";

import { useAutocompleCustom } from "../../hooks/select";
import { TypeCategories as SelectTypeCategories } from "../SelectOptions/TypeCategories";

const useStyles = makeStyles(() => ({
  logoimagen: {
    height: "200px",
    // boxShadow: "5px 5px #289FED",
    objectFit: "cover",
  },
  input: {
    display: "none",
  },
  card: {
    marginTop: 7,
    marginLeft: 7,
    marginRight: 7,
  },
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 10,
  },
}));

export default function Edit(props) {
  const classes = useStyles();
  const { modalUpdate, handleCloseEdit, id } = props;

  const { ChangeCategory, category } = useContext(CategoriesContext);

  const {
    HandleChangeValueAutoComplete,
    idSelectedValue,
    setSelectedValue,
    selectedValue,
  } = useAutocompleCustom();

  const [cargando, spinnerCargando] = useState(false);
  const [brand_id, saveBrandId] = useState();

  const [photoWeb, setImageWeb] = useState({
    urlPhotoWeb:
      "https://cdn.pngsumo.com/index-of-areaedu-wp-content-uploads-2016-02-default-png-600_600.png",
    imageWeb: "",
  });

  //Slider Movil
  const [imageMobile, setImageMobile] = useState({
    urlPhotoMobile:
      "https://cdn.pngsumo.com/index-of-areaedu-wp-content-uploads-2016-02-default-png-600_600.png",
    imageMobile: "",
  });

  const handleChangeImageWeb = (e) => {
    setImageWeb({
      ...photoWeb,
      urlPhotoWeb: URL.createObjectURL(e.target.files[0]),
      imageWeb: e.target.files[0],
    });
  };

  const handleChangeImageMobile = (e) => {
    setImageMobile({
      ...imageMobile,
      urlPhotoMobile: URL.createObjectURL(e.target.files[0]),
      imageMobile: e.target.files[0],
    });
  };
  const detectarCambiosBrand = (e) => {
    saveBrandId(e.target.value);
  };

  const [Category, SaveCategory] = useState({});
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({ defaultValues: Category });

  const SendPropertiesCategory = () => {
    setValue("name", category?.name || "");
    setImageWeb({ ...photoWeb, urlPhotoWeb: category.imageWeb });
    setImageMobile({ ...imageMobile, urlPhotoMobile: category.imageMobile });
  };

  useEffect(() => {
    if (category) {
      SendPropertiesCategory();
    }
  }, [category]);

  const onSubmit = (data, e) => {
    data.id = id;
    data.imageWeb = photoWeb.imageWeb;
    data.imageMobile = imageMobile.imageMobile;
    data.typeCategory = idSelectedValue;

    //data.brand = brand_id;
    ChangeCategory(data);
  };
  return (
    <Dialog open={modalUpdate} onClose={handleCloseEdit}>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#000000",
            textAling: "center",
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            borderRadius: "0 0 24px 0",
          }}>
          <div className={classes.titleCard}>Actualiza Categorias</div>
          <div className={classes.fab}>
            <Tooltip title="cerrar" arrow>
              <Fab
                size="small"
                onClick={handleCloseEdit}
                sx={{
                  color: "#FFFFFF",
                  backgroundColor: "#FF0000",
                  "&:hover": {
                    color: "#FFFFFF",
                    backgroundColor: "#FF0000",
                  },
                }}>
                <CancelPresentationIcon />
              </Fab>
            </Tooltip>
          </div>
        </Box>
      </DialogTitle>
      {/* {!cargando ? (
        <CircularProgress />
      ) : ( */}
      <DialogContent>
        <DialogContentText sx={{ marginBottom: "6px" }}>
          Aqui podras Editar las categorias para tus productos.
        </DialogContentText>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter")
              e.preventDefault();
          }}>
          <Box sx={{ width: "100%", display: "flex" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                  <div>
                    <img
                      src={photoWeb.urlPhotoWeb}
                      className={classes.logoimagen}
                      alt="categoria web"
                    />
                  </div>
                </Box>

                <input
                  className={classes.input}
                  id="icon-button-file"
                  type="file"
                  name="imageWeb"
                  accept="image/png, image/jpg, image/jpeg"
                  onChange={handleChangeImageWeb}
                />
                <InputLabel sx={{ textAlign: "center" }}>
                  Imagen categoria web:
                  <label htmlFor="icon-button-file">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span">
                      <Tooltip
                        title="seleccionar imagen"
                        aria-label="seleccionar imagen">
                        <PhotoCamera />
                      </Tooltip>
                    </IconButton>
                  </label>
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                  <div>
                    <img
                      src={imageMobile.urlPhotoMobile}
                      className={classes.logoimagen}
                      alt="categoria Movil"
                    />
                  </div>
                </Box>
                <input
                  className={classes.input}
                  id="icon-button-file-movile"
                  type="file"
                  name="imageMobile"
                  accept="image/png, image/jpg, image/jpeg, image/webp"
                  onChange={handleChangeImageMobile}
                />
                <InputLabel sx={{ textAlign: "center" }}>
                  Imagen Movil de categoria :
                  <label htmlFor="icon-button-file-movile">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span">
                      <Tooltip
                        title="seleccionar imagen"
                        aria-label="seleccionar imagen">
                        <PhotoCamera />
                      </Tooltip>
                    </IconButton>
                  </label>
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  fullWidth
                  focused
                  type="text"
                  placeholder="Ejemplo:Monomero M..."
                  id="outlined-basic"
                  label="Nombre de la categoria"
                  variant="outlined"
                  name="name"
                  error={errors.name ? true : false}
                  helperText={errors?.name?.message}
                  {...register("name", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                    minLength: {
                      value: 4,
                      message: "Minimo 4 caracteres",
                    },
                    maxLength: {
                      value: 255,
                      message: "Maximo 255 caracteres",
                    },
                  })}
                />
              </Grid>
              {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SelectTypeCategories
                  setSelectedValue={setSelectedValue}
                  selectedValue={selectedValue}
                  HandleChange={HandleChangeValueAutoComplete}
                />
              </Grid> */}

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    textAling: "center",
                  }}>
                  <div className={classes.fab}>
                    <Fab
                      onClick={handleCloseEdit}
                      variant="extended"
                      type="submit"
                      sx={{
                        backgroundColor: "#00B9FF",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#00DEED",
                          color: "white",
                        },
                      }}>
                      <SaveOutlinedIcon sx={{ mr: 1 }} />
                      Guardar
                    </Fab>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </DialogContent>
      {/* )} */}
    </Dialog>
  );
}
