import * as React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Swal from "sweetalert2";
import { useState } from "react";
import { useEffect } from "react";
import MethodGet from "../../../config/Service";
import SalesContext from "../../../context/Sales/SalesContext";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import TableProducts from "./TableProducts";
import ProductSelectReactSelect from "./../../SelectOptions/ProductSelectReactSelect";
import Layout from "../../../components/layout/Layout";
export default function ModalUpdateSale(props) {
  const idSale = props.match.params.idSale;
  /**Obetner la venta */
  const [order, saveOrder] = useState();
  const [productsOrder, saveProductsOrder] = useState([]);
  const [business_rule, saveBussinessRule] = useState(null);
  const [shippmentCost, saveShippmentCost] = useState();
  useEffect(() => {
    let url = `/orders/${idSale}`;
    MethodGet(url)
      .then((res) => {
        saveOrder(res.data.order);
        saveBussinessRule(res.data.order.business_rule_id);
        saveShippmentCost(res.data.order.shippment);
        saveProductsOrder(
          res.data.order.products_list.map((product) => {
            //if (res.data.order.business_rule_id !== "") {
            let rule = res.data.order.business_rule_id
              ? res.data.order.business_rule
              : 0;

            let attribute = {
              product: product.product,
              quantity: product.quantity,
              product_id: product.product_id._id,
              subtotal: product.product.price * product.quantity,

              total_final: rule
                ? product.product.price * product.quantity -
                  product.product.price *
                    product.quantity *
                    (rule.discount / 100)
                : product.product.price * product.quantity,
            };
            return attribute;
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, [idSale]);
  //context
  const { UpdateOrder } = useContext(SalesContext);
  //suma de total de productos comprados
  const quantyties = productsOrder.map((p) => p.quantity);
  const initialValue = 0;
  const sumaProductos = quantyties.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    initialValue
  );
  //select product
  const [productSelect, setProductSelect] = useState(null);
  const detectarCambiosProductSelect = (value) => {
    setProductSelect(value.value);
  };
  //obtener informacion del producto
  const [product, saveProduct] = useState({});
  const [spinner, spinnerCargando] = useState(false);
  useEffect(() => {
    if (productSelect !== null) {
      let url = `/products/${productSelect}`;
      MethodGet(url)
        .then((res) => {
          saveProduct(res.data.product);
          spinnerCargando(true);
        })
        .catch((error) => {
          spinnerCargando(true);
        });
    }
  }, [productSelect]);
  //agregar producto al arreglo de productos
  const AgregarProductoState = () => {
    let rule = business_rule?.discount;
    let productoResultado = product;
    const id = productoResultado._id;
    productoResultado.product_id = id;
    productoResultado.stock = Number(product.quantity);
    productoResultado.cantidad = 1;
    productoResultado.price = Number(product.price);
    productoResultado.discount = Number(product.discount);
    productoResultado.name = product.name;

    var exist = productsOrder.filter((p) => p.product_id === id);
    if (exist.length) {
      Swal.fire({
        title: "No permitido",
        icon: "error",
        text: "El producto ya ha sido agregado, por favor modifica la cantidad si deseas agregar más productos!",
        timer: 2000,
        showConfirmButton: false,
      });
      return;
    }

    saveProductsOrder([
      ...productsOrder,
      {
        product: productoResultado,
        quantity: productoResultado.cantidad,
        product_id: productoResultado._id,
        subtotal: productoResultado.price * productoResultado.cantidad,
        total_final: rule
          ? productoResultado.price * productoResultado.quantity -
            (productoResultado.price * rule) / 100
          : productoResultado.cantidad * productoResultado.price,
      },
    ]);
    Swal.fire({
      title: "Agregado",
      icon: "success",
      text: "el producto se agregó correctamente!",
      timer: 1000,
      showConfirmButton: false,
    });

    //product({});
    // guardarCantidad(0);
  };
  //formato de moneda al total de la orden
  var totalOrder = 0;
  if (order) {
    totalOrder = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: 2,
    }).format(order.total);
  }
  //formato nuevo total de la orden

  const new_totals = business_rule
    ? productsOrder.map(
        (p) => p.total_final - (p.total_final * business_rule.discount) / 100
      )
    : productsOrder.map((p) => p.total_final);

  const shippment = shippmentCost;
  const new_total_initial = 0 + shippment;

  const nuevaSumaTotal = new_totals.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    new_total_initial
  );

  const actual_total = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
    minimumFractionDigits: 2,
  }).format(nuevaSumaTotal);
  const { handleSubmit } = useForm();
  const onSubmit = (data, e) => {
    if (totalOrder > actual_total) {
      // handleClose();
      Swal.fire({
        title: "Error",
        text: "No puedes actualizar una orden en la que el nuevo total sea menor al total de la orden",
        icon: "error",
        showConfirmButton: true,
      });
      return;
    } else if (actual_total > totalOrder) {
      // handleClose();
      Swal.fire({
        title: "Error",
        text: "El total actual de la orden, no puede ser mayor al total de la orden",
        icon: "error",
        showConfirmButton: true,
      });
      return;
    } else if (totalOrder === actual_total) {
      data.products = productsOrder.map((p) => {
        let attribute = {
          product_id: p.product_id,
          quantity: p.quantity,
        };
        return attribute;
      });
      data.order_id = order._id;
      // handleClose();
      UpdateOrder(data);
    }
    setProductSelect();
  };

  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          display="flex"
          justifyContent="center"
          sx={{ color: "black", fontSize: "30px" }}>
          Editar venta
        </Grid>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.code === "Enter") e.preventDefault();
          }}
          autoComplete="off">
          <Grid container spacing={2} sx={{ padding: 5 }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              sx={{
                backgroundColor: "black",
                color: "white",
              }}>
              <Typography textAlign="center" variant="h5">
                T. Venta + G.E : {totalOrder}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              sx={{
                backgroundColor: "black",
                color: "white",
              }}>
              <Typography textAlign="center" variant="h5">
                N. total + G.E: {actual_total}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
              <ProductSelectReactSelect
                detectarCambiosProductSelect={detectarCambiosProductSelect}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              display="flex"
              justifyContent="center">
              <Button
                size="large"
                onClick={AgregarProductoState}
                sx={{
                  color: "white",
                  backgroundColor: "#f48fb1",
                  "&:hover": { backgroundColor: "#f48fb1", color: "white" },
                }}>
                Agregar
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TableProducts
                productsOrder={productsOrder}
                saveProductsOrder={saveProductsOrder}
                sumaProductos={sumaProductos}
                business_rule={business_rule}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              display="flex"
              justifyContent="flex-end">
              <Button variant="contained" sx={{ margin: 2 }} type="submit">
                Actualizar venta
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Layout>
  );
}
