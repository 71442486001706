import { Badge, Grid } from "@mui/material";
import React from "react";
import TableRequest from "./TableRequest";
import CircleIcon from '@mui/icons-material/Circle';
const RequestForCancelInvoice = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        Motivos de cancelacion de factura:
      </Grid>
      <Grid item xs={12} sm={3}>
        <CircleIcon sx={{color:'#43a047'}} /> 01: Errores con relacion
      </Grid>
      <Grid item xs={12} sm={3}>
      <CircleIcon sx={{color:'#9575cd'}} /> 02: Errores sin Relacion
      </Grid>
      <Grid item xs={12} sm={3}>
      <CircleIcon sx={{color:'#7986cb'}} /> 03: No se llevo a cabo la operacion
      </Grid>
      <Grid item xs={12} sm={3}>
      <CircleIcon sx={{color:'#64b5f6'}} /> 04: Operacion nominativa, relacionada en una factura global
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TableRequest />
      </Grid>
    </Grid>
  );
};

export default RequestForCancelInvoice;
