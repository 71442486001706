import { Button, Grid, TextField, Tooltip, Typography } from "@mui/material";
import React from "react";
import ProductSelectReactSelect from "../../SelectOptions/ProductSelectReactSelect";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { useEffect } from "react";
import MethodGet from "../../../config/Service";
import Swal from "sweetalert2";
import { makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
const useStyles = makeStyles({
  img: {
    height: 120,
    width: 120,
  },
  jss59: {
    width: "100%",
    border: "1px solid #bbb",
    height: "15%",
    outline: "none",
    padding: "15px 10px",
    marginLeft: "10px",
    borderRadius: "10px",
  },
});
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const AddProducts = ({
  newProducts,
  saveNewProducts,
  currentProducts,
  saveCurrentProducts,
  businessRule,
  currentTotal,
  newTotal,
  saveNewTotal,
  shippingCost,
}) => {
  const [productSelect, saveProductSelect] = useState(null);
  const detectarCambiosProductSelect = (value) => {
    saveProductSelect(value.value);
  };
  const [cargando, spinnerCargando] = useState(false);

  const [product, saveProduct] = useState();
  //obtener la informacion del producto
  useEffect(() => {
    if (productSelect !== null) {
      let url = `/products/admin/${productSelect}`;
      MethodGet(url)
        .then((res) => {
          saveProduct(res.data.product);
          spinnerCargando(true);
        })
        .catch((error) => {
          spinnerCargando(true);
        });
    }
  }, [productSelect]);
  const [producto, guardarProducto] = useState({});
  const [cantidad, guardarCantidad] = useState(1);
  const AgregarProductoState = () => {
    let productoResultado = producto;
    productoResultado.product_id = productSelect;
    productoResultado.quantity = Number(cantidad);
    productoResultado.price_unit = Number(product.price);
    productoResultado.discount = Number(product.discount);
    productoResultado.final_price =
      product.discount > 0
        ? product.price *
          productoResultado.quantity *
          (1 - product.discount / 100).toFixed(2)
        : businessRule
        ? Number(productoResultado.quantity) * Number(product.price) -
          (productoResultado.quantity * product.price * businessRule.discount) /
            100
        : productoResultado.quantity * product.price;
    productoResultado.name = product.name;
    productoResultado.barcode = product.barcode;
    productoResultado.stock = product.quantity;
    var exist = currentProducts.filter((p) => p.product._id === productSelect);
    var exist1 = newProducts.filter((p) => p.product_id === productSelect);
    if (exist.length) {
      Swal.fire({
        title: "No permitido",
        icon: "error",
        text: "El producto que intentas agregar ya existe en la orden, modifica la cantidad si necesitas agregar mas",
      });
      return;
    }
    if (exist1.length) {
      Swal.fire({
        title: "No permitido",
        icon: "error",
        text: "El producto se ha agregado recientemente a la venta modifica la cantidad si necesitas agregar mas",
      });
      return;
    }
    saveNewProducts([...newProducts, productoResultado]);
    Swal.fire({
      title: "Agregado",
      icon: "success",
      text: "El producto se agregado a la orden correctamente",
      timer: 800,
      showConfirmButton: false,
    });
    guardarProducto({});
    guardarCantidad(1);
  };
  const handleChangeQuantity = (e, i) => {
    const newQuantity = Number(e.target.value);
    const todosProducto = [...newProducts];
    if (newQuantity > todosProducto[i].stock) {
      Swal.fire({
        title: "Error",
        text:
          "La nueva cantidad, no puede ser mayor al stock disponible!, Unicamente hay " +
          todosProducto[i].stock +
          " Disponibles",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
      return;
    }
    todosProducto[i].quantity = newQuantity;
    todosProducto[i].final_price =
      todosProducto[i].discount > 0
        ? todosProducto[i].price_unit *
          todosProducto[i].quantity *
          (1 - todosProducto[i].discount / 100).toFixed(2)
        : businessRule
        ? Number(todosProducto[i].price_unit) *
            Number(todosProducto[i].quantity) -
          Number(
            todosProducto[i].price_unit *
              todosProducto[i].quantity *
              businessRule.discount
          ) /
            100
        : todosProducto[i].quantity * todosProducto[i].price_unit;
    saveNewProducts(todosProducto);
  };
  const subtotals = newProducts.map((p) => p.price_unit * p.quantity);
  const initialValue = 0;
  const sumaSubtotal = subtotals.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    initialValue
  );
  const totals = newProducts.map((p) =>
    p.discount > 0
      ? p.quantity * p.price_unit * (1 - p.discount / 100).toFixed(2)
      : businessRule
      ? p.quantity * p.price_unit -
        (p.quantity * p.price_unit * businessRule.discount) / 100
      : p.quantity * p.price_unit
  );
  const initialValueTotal = 0;
  const sumaTotal = totals.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    initialValueTotal
  );
  const eliminarProductoVenta = (id) => {
    const todosProducto = newProducts.filter((p) => p.product_id !== id);
    saveNewProducts(todosProducto);
  };
  const sumaTotales =
    Number(currentTotal) + Number(sumaTotal) + Number(shippingCost);
  saveNewTotal(sumaTotales);
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ boxShadow: 8, margin: 2 }}>
        <Typography textAlign="center">Agregar nuevos productos</Typography>
        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          <Grid item xs={8}>
            <ProductSelectReactSelect
              detectarCambiosProductSelect={detectarCambiosProductSelect}
            />
          </Grid>
          <Grid item xs={4} display="flex" justifyContent="center">
            <Button
              onClick={AgregarProductoState}
              variant="contained"
              size="large"
              sx={{
                backgroundColor: "#f06292",
                color: "white",
                "&:hover": { backgroundColor: "#f06292", color: "white" },
              }}
            >
              Agregar
            </Button>
          </Grid>
        </Grid>
        {newProducts.length ? (
          <TableContainer sx={{ marginBottom: 2, padding: 1 }}>
            <div style={{ height: "230px", overflowY: "auto" }}>
              <Table
                // stickyHeader
                sx={{ minWidth: 700 }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">Código</StyledTableCell>
                    <StyledTableCell align="center">Producto</StyledTableCell>
                    <StyledTableCell align="center">Stock</StyledTableCell>
                    <StyledTableCell align="center">Cantidad</StyledTableCell>
                    <StyledTableCell align="center">Precio u.</StyledTableCell>
                    <StyledTableCell align="center">subtotal</StyledTableCell>
                    <StyledTableCell align="center">
                      Precio final
                    </StyledTableCell>
                    <StyledTableCell align="center">Opciones</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {newProducts.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                        {row.barcode}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.stock}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <input
                          type="number"
                          step={1}
                          //onChange={detectarCambiosCantidadComprada}
                          className={classes.jss59}
                          placeholder="Agrega la Cantidad Inicial"
                          name="quantityProduct"
                          min={1}
                          max={row.stock}
                          defaultValue={row.quantity}
                          onChange={(e) => handleChangeQuantity(e, index)}
                          onWheel={(e) => e.target.blur()}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        $ {row.price_unit}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        $ {row.price_unit * row.quantity}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        $ {row.final_price}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Tooltip title="Eliminar producto" placement="top">
                          <Button
                            onClick={() =>
                              eliminarProductoVenta(row.product_id)
                            }
                          >
                            <DeleteIcon sx={{ color: "red" }} />
                          </Button>
                        </Tooltip>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <Typography fontSize="bold" fontFamily="monospace">
                      Subtotal
                    </Typography>
                  </TableCell>
                  <TableCell align="center">$ {Number(sumaSubtotal)}</TableCell>
                </TableRow>
                {businessRule && (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <Typography fontSize="bold" fontFamily="monospace">
                        Descuento
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      {businessRule.discount} %
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <Typography fontSize="bold" fontFamily="monospace">
                      Total
                    </Typography>
                  </TableCell>
                  <TableCell align="center">$ {Number(sumaTotal)}</TableCell>
                </TableRow>
              </Table>
            </div>
          </TableContainer>
        ) : (
          ""
        )}
      </Grid>
    </Grid>
  );
};

export default AddProducts;
