import React, { useReducer } from "react";
import Swal from "sweetalert2";
import MethodGet, {
	MethodDelete,
	MethodPost,
	MethodPut,
} from "../../config/Service";
import {
	ADD_SHIPPMENTS,
	CURRENT_SHIPPMENTS,
	DELETE_SHIPPMENTS,
	GET_ALL_SHIPPMENTS,
	SHOW_ERRORS_API,
	UPDATE_SHIPPMENTS,
} from "../../types";
import ShippmentsContext from "./ShippmentsContext";
import ShippmentsReducer from "./ShippmentsReducer";
import headerConfig from "../../config/imageHeaders";

const ShippmentsState = ({ children }) => {
	//estado inicial
	const initialState = {
		shippments: [],
		shippment: null,
		ErrorsApi: [],
		success: false,
	};

	const [state, dispatch] = useReducer(ShippmentsReducer, initialState);

	//obtener lista de paqueterias
	const GetShippments = () => {
		sessionStorage.removeItem("shippment");
		let url = "/shippments";
		MethodGet(url)
			.then((res) => {
				dispatch({
					type: GET_ALL_SHIPPMENTS,
					payload: res.data.shippments,
				});
			})
			.catch((error) => {
				Swal.fire({
					title: "Error",
					text: error.response.data.message,
					timer: 2000,
					icon: "error",
					showConfirmButton: false,
				});
			});
	};

	const AddShippments = (shippment) => {
		const formData = new FormData();

		formData.append("name", shippment.name);
		formData.append("image", shippment.image);
		formData.append("urlBase", shippment.urlBase);

		let url = "/shippments/";

		MethodPost(url, formData, { headerConfig })
			.then((res) => {
				Swal.fire({
					title: "Paqueteria Registrada",
					text: "La paqueteria se ha registrado exitosamente",
					icon: "success",
					timer: 1500,
					showConfirmButton: false,
				});
				dispatch({
					type: ADD_SHIPPMENTS,
				});
			})
			.catch((error) => {
				Swal.fire({
					title: "Error",
					text: error.response.data.message,
					icon: "error",
					timer: 2000,
					showConfirmButton: false,
				});
				dispatch({
					type: SHOW_ERRORS_API,
					payload: error.res.data.error,
				});
			});
	};

	const UpdateShippment = (shippment) => {
		const formData = new FormData();
		formData.append("name", shippment.name);
		formData.append("image", shippment.image);
		formData.append("urlBase", shippment.urlBase);
		let url = `/shippments/${shippment.id}`;
		MethodPut(url, formData, { headerConfig })
			.then((res) => {
				Swal.fire({
					title: "Paqueteria Actualizado",
					text: res.data.message,
					timer: 1000,
					showConfirmButton: false,
					icon: "success",
				});
				dispatch({
					type: UPDATE_SHIPPMENTS,
				});
			})
			.catch((error) => {
				Swal.fire({
					title: "Error",
					text: error.response.data.message,
					icon: "error",
					timer: 2000,
					showConfirmButton: false,
				});
				dispatch({
					type: SHOW_ERRORS_API,
					payload: error.res.data.error,
				});
			});
	};
	const DeleteShippment = (id) => {
		Swal.fire({
			title: "¿Estas Seguro?",
			text: "La paqueteria seleccionada será eliminada",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Si, eliminar",
			cancelButtonText: "No, cancelar",
		}).then((result) => {
			if (result.value) {
				let url = `/shippments/${id}`;

				MethodDelete(url)
					.then((res) => {
						Swal.fire({
							title: "Eliminado",
							text: res.data.message,
							icon: "success",
							timer: 2000,
							showConfirmButton: false,
						});
						dispatch({
							type: DELETE_SHIPPMENTS,
							payload: id,
						});
					})
					.catch((error) => {
						Swal.fire({
							title: "Error",
							text: error.response.data.message,
							icon: "error",
							timer: 2500,
							showConfirmButton: false,
						});
					});
			}
		});
	};

	const SaveCurrentShippment = (shippment) => {
		sessionStorage.setItem("shippment", JSON.stringify(shippment));
		dispatch({
			type: CURRENT_SHIPPMENTS,
			payload: shippment,
		});
	};

	return (
		<ShippmentsContext.Provider
			value={{
				shippments: state.shippments,
				shippment: state.shippment,
				ErrorsApi: state.ErrorsApi,
				success: state.success,
				GetShippments,
				AddShippments,
				UpdateShippment,
				DeleteShippment,
				SaveCurrentShippment,
			}}
		>
			{children}
		</ShippmentsContext.Provider>
	);
};

export default ShippmentsState;
