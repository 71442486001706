import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import ProvidersCard from "../../components/Cards/Providers/ProvidersCard";
import ProvidersContext from "../../context/Provider/ProviderContext";
import Layout from "./../../components/layout/Layout";
const Providers = () => {
  const { listProviders, GetProviders, success } = useContext(ProvidersContext);
  useEffect(() => {
    GetProviders();
  }, [success]);

  return (
    <Layout>
      <Grid container spacing={2} sx={{ padding: 3 }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            backgroundColor: "black",
            color: "white",
          }}>
          <Typography fontSize="30px" fontWeight="bold">
            Mis Proveedores
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          display="flex"
          justifyContent="end"
          //sx={{ backgroundColor: "black", color: "white" }}
        >
          <Typography fontSize="30px" fontWeight="bold">
            <Link to="/AgregarProveedor">
              <Button variant="contained" fullWidth>
                Agregar
              </Button>
            </Link>
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        {listProviders.map((provider, index) => (
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4} key={index}>
            <ProvidersCard provider={provider} />
          </Grid>
        ))}
      </Grid>
    </Layout>
  );
};

export default Providers;
