import React, { useState, useContext } from "react";
import BusinessRulesContext from "../../../context/BusinessRules/BusinessRulesContext";
import ModalEdit from "../../../containers/businessrules/ModalEdit";
import formatDate from "../../../utils/FormatDate";
import { EditIcon } from "../../icons/EditIcon";
import { DeleteIcon } from "../../icons/DeleteIcon";
import {
  Card,
  Grid,
  Box,
  Divider,
  Chip,
  Typography,
  Tooltip,
  Button,
} from "@mui/material";

export default function CardBusinessRules({ business_rule, permiss }) {
  const { DeleteBusinessRulesSales } = useContext(BusinessRulesContext);
  const [id_business, setIdBusiness] = useState();
  const [modalUpdate, AbrirModalUpdate] = useState(false);
  const handleClickOpenUpdate = () => {
    AbrirModalUpdate(true);
  };
  const handleClickCloseUpdate = () => {
    sessionStorage.removeItem("business_rule");
    AbrirModalUpdate(false);
  };
  const selectBusiness = (business_rule) => {
    setIdBusiness(business_rule);
    handleClickOpenUpdate();
  };
  return (
    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
      <Card sx={{ boxShadow: 3, paddingX: 2 }}>
        <Divider sx={{ marginTop: 2 }}>
          <Chip label="Tipo: Venta Ecommerce" />
        </Divider>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginX: 1,
          }}
        >
          <div>
            <Typography
              sx={{ fontWeight: "bold", fontSize: 14, marginRight: 1 }}
            >
              Cantidad Minima:
            </Typography>
          </div>
          <div>$ {business_rule?.minimum_money.toFixed(2)}</div>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginX: 1,
          }}
        >
          <div>
            <Typography
              sx={{ fontWeight: "bold", fontSize: 14, marginRight: 1 }}
            >
              Cantidad Maxima:
            </Typography>
          </div>
          <div>$ {business_rule?.maximum_money.toFixed(2)}</div>
        </Box>

        <Divider />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            marginY: 1,
            marginX: 1,
          }}
        >
          <div>
            <Typography
              sx={{ fontWeight: "bold", fontSize: 14, marginRight: 1 }}
            >
              Descuento a Aplicar:
            </Typography>
          </div>
          <div> {business_rule?.discount} %</div>
        </Box>
        <Grid container spacing={2}>
        {permiss.update === true && (

          <Grid item xs={6} display="flex" justifyContent="center">
            <Tooltip title="Editar" placement="top">
              <Button
                onClick={() => {
                  selectBusiness(business_rule._id);
                }}
              >
                <EditIcon />
              </Button>
            </Tooltip>
          </Grid>
        )}
                {permiss.delete === true && (
          <Grid item xs={6} display="flex" justifyContent="center">
            <Tooltip title="Eliminar" placement="top">
              <Button
                onClick={() => {
                  DeleteBusinessRulesSales(business_rule._id);
                }}
              >
                <DeleteIcon />
              </Button>
            </Tooltip>
          </Grid>
                )}
        </Grid>
      </Card>
      {id_business && (
        <ModalEdit
          modalUpdate={modalUpdate}
          id={id_business}
          handleClickOpen={handleClickOpenUpdate}
          handleCloseEdit={handleClickCloseUpdate}
        />
      )}
    </Grid>
  );
}
