import { ADD_EXPENSES, CURRENT_EXPENSE, DELETE_EXPENSES, GET_ALL_EXPENSES, SHOW_ERRORS_API, UPDATE_EXPENSES } from "../../types";

export default function ExpensesReducer(state, action) {
    switch (action.type) {
        case GET_ALL_EXPENSES:
            return {
                ...state,
                expenses: action.payload,
                success: false,
                ErrorsAPI: []
            }
        case ADD_EXPENSES:
            return {
              ...state,
              expenses: [action.payload, ...state.expenses],
              success: true,
              ErrorsAPI: [],
            };
        case SHOW_ERRORS_API:
            return {
                ...state,
                success: true,
                ErrorsAPI: []
            }
        case CURRENT_EXPENSE:
            return {
                ...state,
                expense: action.payload,
            }
        case UPDATE_EXPENSES:
            return {
                ...state,
                success: true,
                expenses: state.expenses.map((expense) => expense._id === action.payload._id ? {...action.payload} : expense),
                ErrorsAPI: []
            }
        case DELETE_EXPENSES:
            return {
                ...state,
                success: true,
                expenses: state.expenses.filter(expense => expense.id !== action.payload)
            }
        default:
            return state;
    }
}