import React from "react";
import Layout from "../../../components/layout/Layout";
import { Button, Grid, TextField, Tooltip, Typography } from "@mui/material";

import SelectProviders from "./../../SelectOptions/SelectProviders";
import { useState } from "react";
import TypeInvoice from "../../SelectOptions/TypeInvoice";
import AddProviderModal from "./AddProviderModal";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AccountsForPaymentContext from "../../../context/AccountsForPayment/AccountsForPaymentContext";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import Swal from "sweetalert2";
const AddAccountPayment = (props) => {
  const [provider, saveProvider] = useState();
  const detectarCambiosProvider = (e) => {
    saveProvider(e);
  };
  const [typeInvoice, saveTypeInvoice] = useState();
  const detectarCambiosPaymentsInvoice = (e) => {
    saveTypeInvoice(e);
  };
  /**MODAL */
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  /**Submit */
  const { AddAccountsPayment, success } = useContext(AccountsForPaymentContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const reset = () => {
    setValue("folio_invoice", "", { shouldDirty: true });
    setValue("amount", "", { shouldDirty: true });
    saveProvider("");
    saveTypeInvoice("");
  };
  const onSubmit = (data, e) => {
    data.provider_id = provider;
    data.typeInvoice = typeInvoice;
    if (data.amount === "0") {
      Swal.fire({
        title: "Error",
        text: "El monto de la cuenta no puede ser 0",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }
    AddAccountsPayment(data);
    reset();
    // handleClose();
    props.history.goBack();
  };
  return (
    <Layout>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.code === "Enter" || e.code === "NumpadEnter")
            e.preventDefault();
        }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{ backgroundColor: "black", color: "white", marginLeft: 2 }}>
            <Typography fontWeight="bold" fontSize="35px">
              Agregar cuenta por pagar
            </Typography>
          </Grid>
          <Grid container spacing={2} sx={{ margin: 2 }}>
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <label>Proveedores: </label>
              <SelectProviders
                detectarCambiosProvider={detectarCambiosProvider}
              />
            </Grid>
            <Grid item xs={2} md={2} sm={2} lg={2} xl={2}>
              {/* <label>Agregar Proveedor</label> */}
              <Tooltip title="Registrar Proveedor" placement="top">
                <Button onClick={handleClickOpen} sx={{ marginTop: 3 }}>
                  <PersonAddIcon
                    sx={{
                      fontSize: "55px",

                      color: "black",
                    }}
                  />
                </Button>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <label>Folio de factura</label>
              <TextField
                //label="Folio de factura"
                fullWidth
                name="folio_invoice"
                type="text"
                error={errors.folio_invoice ? true : false}
                helperText={errors?.folio_invoice?.message}
                {...register("folio_invoice", {
                  required: {
                    value: true,
                    message: "Este campo es requerido",
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <label>Tipo de factura: </label>
              <TypeInvoice
                detectarCambiosPaymentsInvoice={detectarCambiosPaymentsInvoice}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <label>Monto: </label>
              <TextField
                //label="Folio de factura"
                fullWidth
                name="amount"
                type="number"
                error={errors.amount ? true : false}
                helperText={errors?.amount?.message}
                {...register("amount", {
                  required: {
                    value: true,
                    message: "Este campo es requerido",
                  },
                })}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              display="flex"
              justifyContent="center">
              <Button size="large" variant="contained" type="submit">
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <AddProviderModal open={open} handleClose={handleClose} />
    </Layout>
  );
};

export default AddAccountPayment;
