import React, { Fragment } from "react";
import Select from "react-select";

const SelectOrderStatus = (props) => {
  const options = [
    { value: 1, name: "Pendiente de pago" },
    { value: 2, name: "Preparando Pedido" },
    { value: 3, name: "Ventas completadas" },
    // { value: 4, name: "Ventas en disputa" },
  ];
  const detectarCambiosOrderStatus = (value) => {
    props.detectarCambiosOrderStatus(value);
  };
  const selectStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };
  return (
    <Fragment>
      <Select
        onChange={(value) => detectarCambiosOrderStatus(value)}
        className="basic-single"
        classNamePrefix="select"
        name="product"
        styles={selectStyles}
        defaultValue={{ label: "Selecciona una opcion ", value: "" }}
        placeholder="Selecciona una opcion"
        options={options.map((option) => {
          let attribute = {
            label: option.name,
            value: option.value,
          };
          return attribute;
        })}
      />
    </Fragment>
  );
};

export default SelectOrderStatus;
