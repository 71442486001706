import React from "react";
import { Modal, Input, Row, Checkbox, Button, Text } from "@nextui-org/react";
import ProductsContext from "../../context/Products/ProductsContext";
import { makeStyles } from "@mui/styles";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { useState } from "react";
import MethodGet from "../../config/Service";
import { useEffect } from "react";
import BuildingComponent from "../../components/loading/BuildingComponent";
import { Box, Fab, Grid, IconButton, InputLabel, Tooltip } from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import SpinnerComponent from "../../components/loading/SpinnerComponent";
import Swal from "sweetalert2";
const useStyles = makeStyles({
  input: {
    display: "none",
  },
  logoimagen: {
    height: "200px",
    boxShadow: "5px 5px #289FED",
    objectFit: "cover",
  },
  mainaddcategory: {
    background: "white",
    marginX: "10px",
  },
  aver: {
    marginY: "20px",
  },
  card: {
    marginTop: 7,
    marginLeft: 7,
    marginRight: 7,
  },
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 2,
  },
});
export default function ModalUpdateMultimedia({
  modalMultimedia,
  handleCloseMultimedia,
  id,
}) {
  const { classes } = useStyles();
  const { success, UploadMultimedia } = useContext(ProductsContext);
  const [cargando, spinnerCargando] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const [multimedia_first_id, setMultimediaFirstId] = useState();
  const [multimedia_second_id, setMultimediaSecondId] = useState();
  const [multimedia_third_id, setMultimediaThirdId] = useState();
  const [multimedia_fourth_id, setMultimediaFourthId] = useState();

  //MUltimedia
  const [multimedia_first, setMultimedia_first] = useState({
    urlPhotoFirst:
      "https://cdn.pngsumo.com/index-of-areaedu-wp-content-uploads-2016-02-default-png-600_600.png",
    multimedia_first: "",
  });
  const [multimedia_second, setMultimedia_second] = useState({
    urlPhotoSecond:
      "https://cdn.pngsumo.com/index-of-areaedu-wp-content-uploads-2016-02-default-png-600_600.png",
    multimedia_second: "",
  });
  const [multimedia_third, setMultimedia_third] = useState({
    urlPhotoThird:
      "https://cdn.pngsumo.com/index-of-areaedu-wp-content-uploads-2016-02-default-png-600_600.png",
    multimedia_third: "",
  });
  const [multimedia_fourth, setMultimedia_fourth] = useState({
    urlPhotoFourth:
      "https://cdn.pngsumo.com/index-of-areaedu-wp-content-uploads-2016-02-default-png-600_600.png",
    multimedia_Fourth: "",
  });
  //cambios image
  const handleChangeMultimediaFirst = (e) => {
    setMultimedia_first({
      ...multimedia_first,
      urlPhotoFirst: URL.createObjectURL(e.target.files[0]),
      multimedia_first: e.target.files[0],
    });
  };

  const handleChangeMultimediaSecond = (e) => {
    setMultimedia_second({
      ...multimedia_second,
      urlPhotoSecond: URL.createObjectURL(e.target.files[0]),
      multimedia_second: e.target.files[0],
    });
  };

  const handleChangeMultimediaThird = (e) => {
    setMultimedia_third({
      ...multimedia_third,
      urlPhotoThird: URL.createObjectURL(e.target.files[0]),
      multimedia_third: e.target.files[0],
    });
  };

  const handleChangeMultimediaFourth = (e) => {
    setMultimedia_fourth({
      ...multimedia_fourth,
      urlPhotoFourth: URL.createObjectURL(e.target.files[0]),
      multimedia_fourth: e.target.files[0],
    });
  };
  const [product, saveProduct] = useState({});
  useEffect(() => {
    if (id !== null) {
      let url = `/products/admin/${id}`;
      MethodGet(url)
        .then((res) => {
          saveProduct(res.data.product);
          setMultimedia_first({
            urlPhotoFirst: res.data.product.multimedia[0].path,
            multimedia: "",
          });
          setMultimedia_second({
            urlPhotoSecond: res.data.product.multimedia[1].path,
            multimedia: "",
          });
          setMultimedia_third({
            urlPhotoThird: res.data.product.multimedia[2].path,
            multimedia: "",
          });
          setMultimedia_fourth({
            urlPhotoFourth: res.data.product.multimedia[3].path,
            multimedia: "",
          });
          setMultimediaFirstId(res.data.product.multimedia[0]._id);
          setMultimediaSecondId(res.data.product.multimedia[1]._id);
          setMultimediaThirdId(res.data.product.multimedia[2]._id);
          setMultimediaFourthId(res.data.product.multimedia[3]._id);
          spinnerCargando(true);
        })
        .catch((error) => {
          Swal.fire({
            title: "Error",
            text: error.response.data.message,
            icon: "error",
          });
          spinnerCargando(true);
        });
    }
  }, [id]);
  const onSubmit = (data, e) => {
    data.id = id;

    if (multimedia_first.multimedia_first !== undefined) {
      data.multimedia_first = multimedia_first.multimedia_first;
      data.multimedia_first_id = multimedia_first_id;
    }
    if (multimedia_second.multimedia_second !== undefined) {
      data.multimedia_second = multimedia_second.multimedia_second;
      data.multimedia_second_id = multimedia_second_id;
    }
    if (multimedia_third.multimedia_third !== undefined) {
      data.multimedia_third = multimedia_third.multimedia_third;
      data.multimedia_third_id = multimedia_third_id;
    }
    if (multimedia_fourth.multimedia_fourth !== undefined) {
      data.multimedia_fourth = multimedia_fourth.multimedia_fourth;
      data.multimedia_fourth_id = multimedia_fourth_id;
    }
    handleCloseMultimedia();
    UploadMultimedia(data);
  };

  return (
    <div>
      <Modal
        closeButton
        //blur
        width="70%"
        aria-labelledby="modal-title"
        open={modalMultimedia}
        onClose={handleCloseMultimedia}>
        <Modal.Header>
          <Text id="modal-title" size={18}>
            Actualizar Multimedia {""}
            <Text b size={18}>
              {product.name}
            </Text>
          </Text>
        </Modal.Header>
        <Modal.Body>
          {!cargando ? (
            <BuildingComponent />
          ) : (
            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(e) => {
                if (e.code === "Enter") e.preventDefault();
              }}
              autoComplete="off">
              <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}>
                      <div>
                        <img
                          src={multimedia_first.urlPhotoFirst}
                          style={{
                            height: "250px",
                            width: "250px",
                            //boxShadow: "5px 5px #289FED",
                            objectFit: "cover",
                          }}
                          alt="producto"
                        />
                      </div>
                    </Box>
                    <input
                      style={{ display: "none" }}
                      id="icon-button-file-first"
                      type="file"
                      name="image"
                      accept="image/png, image/jpg, image/jpeg, image/webp"
                      onChange={handleChangeMultimediaFirst}
                    />
                    <InputLabel sx={{ textAlign: "center" }}>
                      imagen :
                      <label htmlFor="icon-button-file-first">
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span">
                          <Tooltip
                            title="seleccionar imagen"
                            aria-label="seleccionar imagen">
                            <PhotoCamera />
                          </Tooltip>
                        </IconButton>
                      </label>
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}>
                      <div>
                        <img
                          src={multimedia_second.urlPhotoSecond}
                          style={{
                            height: "250px",
                            width: "250px",
                            //boxShadow: "5px 5px #289FED",
                            objectFit: "cover",
                          }}
                          alt="producto"
                        />
                      </div>
                    </Box>
                    <input
                      style={{ display: "none" }}
                      id="icon-button-file-second"
                      type="file"
                      name="image"
                      accept="image/png, image/jpg, image/jpeg, image/webp"
                      onChange={handleChangeMultimediaSecond}
                    />
                    <InputLabel sx={{ textAlign: "center" }}>
                      imagen :
                      <label htmlFor="icon-button-file-second">
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span">
                          <Tooltip
                            title="seleccionar imagen"
                            aria-label="seleccionar imagen">
                            <PhotoCamera />
                          </Tooltip>
                        </IconButton>
                      </label>
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}>
                      <div>
                        <img
                          src={multimedia_third.urlPhotoThird}
                          style={{
                            height: "250px",
                            width: "250px",
                            //boxShadow: "5px 5px #289FED",
                            objectFit: "cover",
                          }}
                          alt="producto"
                        />
                      </div>
                    </Box>
                    <input
                      style={{ display: "none" }}
                      id="icon-button-file-third"
                      type="file"
                      name="image"
                      accept="image/png, image/jpg, image/jpeg, image/webp"
                      onChange={handleChangeMultimediaThird}
                    />
                    <InputLabel sx={{ textAlign: "center" }}>
                      imagen :
                      <label htmlFor="icon-button-file-third">
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span">
                          <Tooltip
                            title="seleccionar imagen"
                            aria-label="seleccionar imagen">
                            <PhotoCamera />
                          </Tooltip>
                        </IconButton>
                      </label>
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}>
                      <div>
                        <img
                          src={multimedia_fourth.urlPhotoFourth}
                          style={{
                            height: "250px",
                            width: "250px",
                            // boxShadow: "5px 5px #289FED",
                            objectFit: "cover",
                          }}
                          alt="producto"
                        />
                      </div>
                    </Box>
                    <input
                      style={{ display: "none" }}
                      id="icon-button-file-fourth"
                      type="file"
                      name="image"
                      accept="image/png, image/jpg, image/jpeg, image/webp"
                      onChange={handleChangeMultimediaFourth}
                    />
                    <InputLabel sx={{ textAlign: "center" }}>
                      imagen :
                      <label htmlFor="icon-button-file-fourth">
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span">
                          <Tooltip
                            title="seleccionar imagen"
                            aria-label="seleccionar imagen">
                            <PhotoCamera />
                          </Tooltip>
                        </IconButton>
                      </label>
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                        textAling: "center",
                        paddingBottom: 8,
                      }}>
                      <div css={{ position: "relative", marginTop: 2 }}>
                        <Fab
                          type="submit"
                          variant="extended"
                          sx={{
                            backgroundColor: "#00B9FF",
                            color: "white",
                            "&:hover": {
                              backgroundColor: "#00DEED",
                              color: "white",
                            },
                          }}>
                          <SaveOutlinedIcon sx={{ mr: 1 }} />
                          Guardar Multimedia
                        </Fab>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </form>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
