import {
  ADD_BUSINESS_RULES,
  ADD_BUSINESS_RULE_CATEGORY,
  ADD_BUSINESS_RULE_SALES_FISIC,
  ADD_BUSINESS_RULE_SHIPPMENT,
  ADD_BUSINESS_RULE_SUBCATEGORY,
  CURRENT_BUSINESS_RULES,
  DELETE_BUSINESS_RULES_SALES,
  DELETE_BUSINESS_RULES_SHIPPMENT,
  DELETE_BUSINESS_RULES_SUBCATEGORY,
  DELETE_BUSSINESS_RULES_CATEGORY,
  DELETE_BUSSINESS_RULES_SALES_FISIC,
  GET_ALL_BUSINESS_RULES_SALES,
  GET_ALL_BUSINESS_RULES_SHIPPMENT,
  GET_ALL_BUSSINESS_RULES_CATEGORY,
  GET_ALL_BUSSINESS_RULES_SUBCATEGORY,
  GET_BUSINESS_RULE_SALES_FISIC,
  SHOW_ERRORS_API,
  UPDATE_BUSINESS_RULES,
  UPDATE_BUSINESS_RULES_CATEGORY,
  UPDATE_BUSINESS_RULES_SALES_FISIC,
  UPDATE_BUSINESS_RULES_SHIPPMENT,
  UPDATE_BUSINESS_RULES_SUBCATEGORY,
} from "../../types";

export default function BusinessRulesReducer(state, action) {
  switch (action.type) {
    //get
    case GET_ALL_BUSINESS_RULES_SALES:
      return {
        ...state,
        business_rules_sales: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case GET_ALL_BUSSINESS_RULES_SUBCATEGORY:
      return {
        ...state,
        business_rules_subcategory: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case GET_ALL_BUSSINESS_RULES_CATEGORY:
      return {
        ...state,
        business_rules_category: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case GET_ALL_BUSINESS_RULES_SHIPPMENT:
      return {
        ...state,
        business_rules_shippment: action.payload,
        success: false,
        ErrosApi: [],
      };
    case GET_BUSINESS_RULE_SALES_FISIC:
      return {
        ...state,
        business_rules_sales_fisic: action.payload,
        success: false,
        ErrosApi: [],
      };
    //add
    case ADD_BUSINESS_RULES:
      return {
        ...state,
        success: true,
        business_rules_sales: [action.payload, ...state.business_rules_sales],
        ErrorsApi: [],
      };
    case ADD_BUSINESS_RULE_SALES_FISIC:
      return {
        ...state,
        success: true,
        business_rules_sales_fisic: [
          action.payload,
          ...state.business_rules_sales_fisic,
        ],
        ErrorsApi: [],
      };
    case ADD_BUSINESS_RULE_CATEGORY:
      return {
        ...state,
        success: true,
        business_rules_category: [
          action.payload,
          ...state.business_rules_category,
        ],
        ErrorsApi: [],
      };
    case ADD_BUSINESS_RULE_SHIPPMENT:
      return {
        ...state,
        success: true,
        business_rules_shippment: [
          action.payload,
          ...state.business_rules_shippment,
        ],
        ErrorsApi: [],
      };
    case ADD_BUSINESS_RULE_SUBCATEGORY:
      return {
        ...state,
        success: true,
        business_rules_subcategory: [
          action.payload,
          ...state.business_rules_subcategory,
        ],
        ErrorsApi: [],
      };

    case SHOW_ERRORS_API:
      return {
        ...state,
        success: true,
        ErrorsApi: [],
      };
    case CURRENT_BUSINESS_RULES:
      return {
        ...state,
        business_rule: action.payload,
      };
    case UPDATE_BUSINESS_RULES:
      return {
        ...state,
        business_rules_sales: state.business_rules_sales.map((business_rule) =>
          business_rule._id === action.payload._id
            ? { ...action.payload }
            : business_rule
        ),
        ErrorsApi: [],
      };
    case UPDATE_BUSINESS_RULES_CATEGORY:
      return {
        ...state,
        business_rules_category: state.business_rules_category.map(
          (business_rule_category) =>
            business_rule_category._id === action.payload._id
              ? { ...action.payload }
              : business_rule_category
        ),
        success: true,
        ErrorsApi: [],
      };
    case UPDATE_BUSINESS_RULES_SALES_FISIC:
      return {
        ...state,
        business_rules_sales_fisic: state.business_rules_sales_fisic.map(
          (business_rules_sale_fisic) =>
            business_rules_sale_fisic._id === action.payload._id
              ? { ...action.payload }
              : business_rules_sale_fisic
        ),
        success: true,
        ErrorsApi: [],
      };
    case UPDATE_BUSINESS_RULES_SUBCATEGORY:
      return {
        ...state,
        business_rules_subcategory: state.business_rules_subcategory.map(
          (business_rule_subcategory) =>
            business_rule_subcategory._id === action.payload._id
              ? { ...action.payload }
              : business_rule_subcategory
        ),
        success: true,
        ErrorsApi: [],
      };
    case UPDATE_BUSINESS_RULES_SHIPPMENT:
      return {
        ...state,
        business_rules_shippment: state.business_rules_shippment.map(
          (business_rule_shippment) =>
            business_rule_shippment._id === action.payload._id
              ? { ...action.payload }
              : business_rule_shippment
        ),
        success: true,
        ErrorsApi: [],
      };
    //delete
    case DELETE_BUSINESS_RULES_SALES:
      return {
        ...state,
        success: true,
        business_rules_sales: state.business_rules_sales.filter(
          (business_rule) => business_rule._id !== action.payload
        ),
      };
    case DELETE_BUSINESS_RULES_SUBCATEGORY:
      return {
        ...state,
        success: true,
        business_rules_subcategory: state.business_rules_subcategory.filter(
          (business_rule_subcategory) =>
            business_rule_subcategory._id !== action.payload
        ),
      };
    case DELETE_BUSSINESS_RULES_CATEGORY:
      return {
        ...state,
        success: true,
        business_rules_category: state.business_rules_category.filter(
          (business_rule_category) =>
            business_rule_category._id !== action.payload
        ),
      };
    case DELETE_BUSINESS_RULES_SHIPPMENT:
      return {
        ...state,
        success: true,
        business_rules_shippment: state.business_rules_shippment.filter(
          (business_rule) => business_rule._id !== action.payload
        ),
      };
    case DELETE_BUSSINESS_RULES_SALES_FISIC:
      return {
        ...state,
        success: true,
        business_rules_sales_fisic: state.business_rules_sales_fisic.filter(
          (business_rule_sale_fisic) =>
            business_rule_sale_fisic._id !== action.payload
        ),
      };
    default:
      return state;
  }
}
