import { SHOW_ERRORS_API, REPORT_UTILITY, GRAPH_UTILITY } from "../../types";

export default function ReportUtilityReducer(state, action) {
    switch (action.type) {
        case REPORT_UTILITY:
            return {
                ...state,
                expenses: action.payload,
                success: false,
                ErrorsAPI: []
            }
            case GRAPH_UTILITY:
            return {
                ...state,
                utility: action.payload,
                success: false,
                ErrorsAPI: []
            }
        case SHOW_ERRORS_API:
            return {
                ...state,
                success: true,
                ErrorsAPI: []
            }
        default:
            return state;
    }
}