import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../../components/layout/Layout";
import ListEnterProductos from "./ListEnterProductos";
import ProductsContext from "../../../context/Products/ProductsContext";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import NoDataComponents from "../../../components/loading/NoDataComponents";
import { Paginate } from "../../../components/Pagination/Paginate";
export default function EnterProducts() {
  const { GetEnterProducts, entries, totalPages } = useContext(ProductsContext);
  //paginacion
  var [pages, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const limite = rowsPerPage;
  const params = { limite, pages };

  useEffect(() => {
    GetEnterProducts(params);
  }, [rowsPerPage, pages]);

  return (
    <Layout>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: "#000",
            color: "white",
            marginTop: 2,
            marginLeft: 2,
          }}
        >
          <Typography variant="h4" fontWeight="bold">
            Entradas de productos
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="end"
          sx={{ marginRight: 2 }}
        >
          <Link to="/Nueva-Entrada">
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#1565c0",
                color: "white",
                "&:hover": { backgroundColor: "#1565c0", color: "white" },
              }}
            >
              Agregar
            </Button>
          </Link>
        </Grid>
      </Grid>
      {entries.length > 0 ? (
        <Grid container spacing={2} sx={{ padding: 2 }}>
          <Grid item xs={12}>
            <ListEnterProductos enterProducts={entries} />
          </Grid>
        </Grid>
      ) : (
        <NoDataComponents />
      )}
      <Grid container spacing={2}>
        <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
          {!entries.length ? (
            <></>
          ) : (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              display="flex"
              justifyContent="center"
            >
              <Paginate
                totalPages={totalPages}
                pages={pages}
                handleChangePage={handleChangePage}
              />
            </Grid>
          )}
        </Box>
      </Grid>
    </Layout>
  );
}
