import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Layout from "../../components/layout/Layout";
import { Button, CardContent, Pagination, Stack, Tooltip } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Link } from "react-router-dom";
import ExitProductsContext from "../../context/ExitProducts/ExitProductsContext";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import formatDate from "../../utils/FormatDate";
import NoDataComponents from "../../components/loading/NoDataComponents";
import { EyeIcon } from "../../components/icons/EyeIcon";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import SelectTypeExit from "../SelectOptions/SelectTypeExit";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles(() => ({
  titleCard: {
    color: "white",
    fontSize: "35px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 2,
    marginRight: 5,
  },
  img_data: {
    margin: "auto",
    height: "90%",
  },
}));

export default function ExitProducts() {
  const classes = useStyles();
  //paginacion
  var [pages, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const limite = rowsPerPage;
  const params = { limite, pages };
  const {
    GetAllDepartures,
    departures,
    success,
    totalPages,
    PdfDeparture,
    TicketDeparture,
    allDepartures,
    GetAllDeparturesNoPaginate,
  } = useContext(ExitProductsContext);

  useEffect(() => {
    GetAllDepartures(params);
  }, [success, rowsPerPage, pages]);
  const [typeExit, saveTypeExit] = useState(null);
  const detectarCambiosTypeExit = (e) => {
    if (e.target.value === 1) {
      saveTypeExit("Salida Prestamo de productos");
    } else if (e.target.value === 2) {
      saveTypeExit("Salida WOS");
    } else if (e.target.value === 3) {
      saveTypeExit("Salida Productos por venta");
    }
  };
  useEffect(() => {
    GetAllDeparturesNoPaginate();
  }, []);
  //funcion para filtrado
  const [filteredDepartures, setFilterDepartures] = useState([]);
  useEffect(() => {
    const filtered = allDepartures.filter(
      (departure) => departure.typeExit === typeExit
    );
    setFilterDepartures(filtered);
  }, [typeExit, departures]);
  return (
    <Layout>
      <Grid container spacing={2} sx={{ backgroundColor: "white", padding: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#000000",
              textAling: "center",
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
              // borderRadius: "0 0 24px 0",
            }}
          >
            <div className={classes.titleCard}>Salida de productos</div>
            <div className={classes.fab}>
              <Tooltip title="Generar nueva salida" placement="top">
                <Link to="/nueva-salida-productos">
                  <Button
                    // onClick={() => handleClickOpen()}
                    sx={{
                      color: "black",
                      backgroundColor: "#26c6da",
                      hoverColor: "white",
                      fontFamily: "monospace",
                      fontWeight: "bolder",
                      fontSize: "15px",
                      textTransform: "none",
                      "&:hover": {
                        color: "black",
                        backgroundColor: "#67cedb",
                        hoverColor: "white",
                        fontFamily: "monospace",
                        fontWeight: "bolder",
                        fontSize: "15px",
                      },
                    }}
                  >
                    Agregar
                  </Button>
                </Link>
              </Tooltip>
            </div>
          </Box>
          <Grid container spacing={2}>
            <Grid
              item
              xs={10}
              sm={10}
              md={10}
              lg={10}
              xl={10}
              sx={{ mt: 5, mb: 3 }}
            >
              <SelectTypeExit
                detectarCambiosTypeExit={detectarCambiosTypeExit}
              />
            </Grid>
            {typeExit !== null && (
              <Grid
                item
                xs={2}
                sm={2}
                md={2}
                lg={2}
                xl={2}
                sx={{ mt: 5, mb: 3 }}
              >
                <Button variant="contained" onClick={() => saveTypeExit(null)}>
                  Limpiar
                </Button>
              </Grid>
            )}
          </Grid>
          <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={2}>
                  {!departures.length ? (
                    <NoDataComponents />
                  ) : (
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="center">
                              Fecha
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Autoriza
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Recibe
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Mensaje
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Opciones
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {!filteredDepartures.length ? (
                            <>
                              {departures.map((row, index) => (
                                <StyledTableRow key={index}>
                                  <StyledTableCell align="center">
                                    {formatDate(row?.createdAt)}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {row?.authotizing_client}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {row?.receiving_customer}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {row?.comment}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    <Tooltip
                                      title="Descargar PDF"
                                      placement="top"
                                    >
                                      <Button
                                        sx={{
                                          borderColor: "red",
                                          color: "red",
                                          "&:hover": {
                                            borderColor: "red",
                                            color: "red",
                                          },
                                        }}
                                        variant="outlined"
                                        onClick={() => PdfDeparture(row._id)}
                                      >
                                        <PictureAsPdfIcon />
                                      </Button>
                                    </Tooltip>
                                    <Tooltip
                                      title="Descargar Ticket"
                                      placement="top"
                                    >
                                      <Button
                                        sx={{
                                          marginLeft: 1,
                                          borderColor: "#283593",
                                          color: "#283593",
                                          "&:hover": {
                                            borderColor: "#283593",
                                            color: "#283593",
                                          },
                                        }}
                                        variant="outlined"
                                        onClick={() => TicketDeparture(row._id)}
                                      >
                                        <ConfirmationNumberIcon />
                                      </Button>
                                    </Tooltip>
                                    <Tooltip
                                      title="Ver Detalle"
                                      placement="top"
                                    >
                                      <Link to={`/DetalleSalida/${row._id}`}>
                                        <Button
                                          sx={{
                                            marginLeft: 1,
                                            borderColor: "#000",
                                            color: "#000",
                                            "&:hover": {
                                              borderColor: "#000",
                                              color: "#000",
                                            },
                                          }}
                                          variant="outlined"
                                        >
                                          <EyeIcon />
                                        </Button>
                                      </Link>
                                    </Tooltip>
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </>
                          ) : (
                            <>
                              {filteredDepartures.map((row, index) => (
                                <StyledTableRow key={index}>
                                  <StyledTableCell align="center">
                                    {formatDate(row?.createdAt)}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {row?.authotizing_client}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {row?.receiving_customer}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {row?.comment}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    <Tooltip
                                      title="Descargar PDF"
                                      placement="top"
                                    >
                                      <Button
                                        sx={{
                                          borderColor: "red",
                                          color: "red",
                                          "&:hover": {
                                            borderColor: "red",
                                            color: "red",
                                          },
                                        }}
                                        variant="outlined"
                                        onClick={() => PdfDeparture(row._id)}
                                      >
                                        <PictureAsPdfIcon />
                                      </Button>
                                    </Tooltip>
                                    <Tooltip
                                      title="Descargar Ticket"
                                      placement="top"
                                    >
                                      <Button
                                        sx={{
                                          marginLeft: 1,
                                          borderColor: "#283593",
                                          color: "#283593",
                                          "&:hover": {
                                            borderColor: "#283593",
                                            color: "#283593",
                                          },
                                        }}
                                        variant="outlined"
                                        onClick={() => TicketDeparture(row._id)}
                                      >
                                        <ConfirmationNumberIcon />
                                      </Button>
                                    </Tooltip>
                                    <Tooltip
                                      title="Ver Detalle"
                                      placement="top"
                                    >
                                      <Link to={`/DetalleSalida/${row._id}`}>
                                        <Button
                                          sx={{
                                            marginLeft: 1,
                                            borderColor: "#000",
                                            color: "#000",
                                            "&:hover": {
                                              borderColor: "#000",
                                              color: "#000",
                                            },
                                          }}
                                          variant="outlined"
                                        >
                                          <EyeIcon />
                                        </Button>
                                      </Link>
                                    </Tooltip>
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
          {!filteredDepartures.length && departures.length && (
            <Box>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                display="flex"
                justifyContent="center"
              >
                <Stack spacing={2} sx={{ marginLeft: 2 }}>
                  <Pagination
                    count={totalPages}
                    variant="outlined"
                    shape="rounded"
                    onChange={handleChangePage}
                    color="secondary"
                    page={pages}
                    size="large"
                    sx={{
                      color: "#880e4f",
                      "&:hover": { color: "#f06292" },
                    }}
                  />
                </Stack>
              </Grid>
            </Box>
          )}
          <CardContent></CardContent>
        </Grid>
      </Grid>
    </Layout>
  );
}
