import React, { useContext, useState, useEffect } from "react";
import CategoriesContext from "../../../context/CategoriesP/CategoriesContext";
import { EditIcon } from "../../icons/EditIcon";
import { DeleteIcon } from "../../icons/DeleteIcon";
import { EyeIcon } from "../../icons/EyeIcon";
// material
import { Box, Card, Typography, Tooltip, Grid, Button } from "@mui/material";
//Estilos , Icons y Alerta
import { makeStyles } from "@mui/styles";
import ModalEdit from "../../../containers/categoriesp/ModalEdit";
import ProductsPerCategorieModal from "../../../containers/categoriesp/ProductsPerCategorieModal";
import ResponsiveImgMaterialUi from "responsive-responsive-img-material-ui";
const useStyles = makeStyles({
  LogoImagen: {
    objectFit: "cover",
    margin: "auto",
    height: 350,
    width: 450,
    textAlign: "center",
  },
});
export default function CategoriesCard({ categorie, permiss }) {
  const { imagesWeb } = categorie;
  const classes = useStyles();
  const [modalUpdate, AbrirModalUpdate] = useState(false);
  const [modalProducts, AbrirModalProducts] = useState(false);
  const [id_category, setIdCategory] = useState();
  const handleClickOpenUpdate = () => {
    AbrirModalUpdate(true);
  };
  const handleClickCloseUpdate = () => {
    sessionStorage.removeItem("category");
    AbrirModalUpdate(false);
  };
  const handleClickOpenModal = () => {
    AbrirModalProducts(true);
  };
  const handleClickCloseModal = () => {
    sessionStorage.removeItem("product_per_category");
    AbrirModalProducts(false);
  };
  const { DeleteCategory, getCategory } = useContext(CategoriesContext);
  const SelectCategory = (category) => {
    setIdCategory(category);
    handleClickOpenUpdate();
  };
  const ProductsPerCategorySelect = (category) => {
    setIdCategory(category);
    handleClickOpenModal();
  };
  return (
    <Card>
      <Box
        fullWidth
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          paddingX: 2,
          backgroundColor: "white",
          color: "black",
        }}
      >
        <Typography
          variant="su arrowbtitle2"
          noWrap
          sx={{ fontWeight: "bold", fontSize: "17px" }}
          className="blink-1"
        >
          {categorie.name}
        </Typography>
      </Box>
      <Box>
        <ResponsiveImgMaterialUi
          style={{ height: "330px", width: "100%" }}
          xs={imagesWeb["400x400"]}
          md={imagesWeb["750x750"]}
          lg={imagesWeb["1000x1000"]}
          xl={imagesWeb["1400x1400"]}
        />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={4} display="flex" justifyContent="center">
          <Tooltip title="Ver productos" placement="top">
            <Button onClick={() => ProductsPerCategorySelect(categorie._id)}>
              <EyeIcon />
            </Button>
          </Tooltip>
        </Grid>
        {permiss.update === true && (
        <Grid item xs={4} display="flex" justifyContent="center">
          <Tooltip title="Editar" placement="top">
            <Button
              onClick={() => {
                SelectCategory(categorie._id);
                getCategory(categorie._id);
              }}
            >
              <EditIcon />
            </Button>
          </Tooltip>
        </Grid>
        )}
         {permiss.delete === true && (
        <Grid item xs={4} display="flex" justifyContent="center">
          <Tooltip title="Eliminar" placement="top">
            <Button onClick={() => DeleteCategory(categorie._id)}>
              <DeleteIcon />
            </Button>
          </Tooltip>
        </Grid>
         )}
      </Grid>
      {id_category && (
        <ModalEdit
          modalUpdate={modalUpdate}
          id={id_category}
          handleClickOpen={handleClickOpenUpdate}
          handleCloseEdit={handleClickCloseUpdate}
        />
      )}
      {id_category && (
        <ProductsPerCategorieModal
          modalProductsCategory={modalProducts}
          id={id_category}
          name={categorie.name}
          handleClickOpen={handleClickOpenModal}
          handleCloseModal={handleClickCloseModal}
        />
      )}
    </Card>
  );
}
