import React, { useContext, useEffect, useState } from "react";
import TypeUsersContext from "../../context/TypeUsers/TypeUsersContext";
import MethodGet from "../../config/Service";
import { useForm } from "react-hook-form";

import { makeStyles } from "@mui/styles";
import {
  Grid,
  Box,
  Fab,
  TextField,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import Swal from "sweetalert2";

const useStyles = makeStyles(() => ({
  card: {
    marginTop: 7,
    marginLeft: 7,
    marginRight: 7,
  },
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 10,
  },
}));

export default function Edit(props) {
  const { modalUpdate, handleCloseEdit, id } = props;
  const classes = useStyles();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  const [Typeuser, saveTypeUser] = useState({});
  const formEditTypeUserContext = useContext(TypeUsersContext);
  const { ErrorsAPI, success, UpdateTypeUsers } = formEditTypeUserContext;

  useEffect(() => {
    let url = `/type_users/${id}`;
    if (id !== null) {
      MethodGet(url)
        .then((res) => {
          saveTypeUser(res.data.type_users);
        })
        .catch((error) => {
          Swal.fire({
            title: "Error",
            text: error.response.data.message,
            icon: "error",
          });
        });
    }
  }, [id]);

  useEffect(() => {
    if (Typeuser) {
      setValue("name", Typeuser.name);
    } else {
      setValue("name", "");
    }
  }, [Typeuser]);

  useEffect(() => {
    if (success) {
      setValue("name", "", { shouldDirty: true });
    }
  }, [success]);

  const onSubmit = (data, e) => {
    // e.preventDefault();
    data._id = id;

    UpdateTypeUsers(data);
  };
  return (
    <Dialog open={modalUpdate} onClose={handleCloseEdit}>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#000000",
            textAling: "center",
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            borderRadius: "0 0 24px 0",
          }}>
          <div className={classes.titleCard}>Edita Tipo de usuario</div>
          <div className={classes.fab}>
            <Tooltip title="cerrar" arrow>
              <Fab
                size="small"
                onClick={handleCloseEdit}
                sx={{
                  color: "#FFFFFF",
                  backgroundColor: "#FF0000",
                  "&:hover": {
                    color: "#FFFFFF",
                    backgroundColor: "#FF0000",
                  },
                }}>
                <CancelPresentationIcon />
              </Fab>
            </Tooltip>
          </div>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Aquí, podras registrar roles para gestionar el sistema
        </DialogContentText>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter")
              e.preventDefault();
          }}
          autoComplete="off">
          <Box sx={{ width: "100%", display: "flex" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  fullWidth
                  focused
                  id="name"
                  name="name"
                  label="Define el tipo de usuario"
                  variant="outlined"
                  error={errors.name ? true : false}
                  helperText={errors?.name?.message}
                  {...register("name", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                  })}
                  sx={{ width: "100%", color: "#343a40" }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    textAling: "center",
                  }}>
                  <div className={classes.fab}>
                    <Fab
                      type="submit"
                      onClick={handleCloseEdit}
                      variant="extended"
                      sx={{
                        backgroundColor: "#00B9FF",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#00DEED",
                          color: "white",
                        },
                      }}>
                      <SaveOutlinedIcon sx={{ mr: 1 }} />
                      Guardar
                    </Fab>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}
