import {
  ADD_PAY_PROVIDER,
  ADD_PROVIDER,
  CREATE_TICKET_PROVIDER,
  DELETE_PROVIDER,
  GET_PROVIDERS,
  UPDATE_PROVIDER,
} from "./../../types/index";

export default function ProviderReducer(state, action) {
  switch (action.type) {
    case GET_PROVIDERS:
      return {
        ...state,
        listProviders: action.payload.listProviders,
        success: false,
        ErrorsAPI: [],
      };
    case ADD_PROVIDER:
      return {
        ...state,
        success: true,
        listProviders: [action.payload, ...state.listProviders],
        ErrorsAPI: [],
      };
    case UPDATE_PROVIDER:
      return {
        ...state,
        listProviders: state.listProviders.map((provider) =>
          provider._id === action.payload._id ? { ...action.payload } : provider
        ),
        ErrorsAPI: [],
        success: true,
      };
    case CREATE_TICKET_PROVIDER:
      return {
        ...state,
        success: true,
        ErrorsAPI: [],
        providers: state.providers.map((provider) =>
          provider._id === action.payload._id ? { ...action.payload } : provider
        ),
      };
    case ADD_PAY_PROVIDER:
      return {
        ...state,
        success: true,
        ErrorsAPI: [],
        providers: state.providers.map((provider) =>
          provider._id === action.payload._id ? { ...action.payload } : provider
        ),
      };
    case DELETE_PROVIDER:
      return {
        ...state,
        success: true,
        providers: state.providers.filter(
          (provider) => provider._id !== action.payload
        ),
      };
    default:
      return state;
  }
}
