import { useReducer } from "react";
import Swal from "sweetalert2";
import MethodGet, { MethodPost } from "../../config/Service";
import {
  ADD_EXIT_PRODUCTS_REPORTS,
  GET_ALL_DEPARTURES_NO_PAGINATE,
  GET_ALL_EXIT_PRODUCTS_REPORTS,
  SHOW_ERRORS_API,
} from "../../types";
import ExitProductsReducer from "./ExitProductsReducer";
import ExitProductsContext from "./ExitProductsContext";
import fileDownload from "js-file-download";

const ExitProductsState = ({ children }) => {
  const initialState = {
    departures: [],
    allDepartures: [],
    departure: {},
    ErrorsApi: [],
    success: false,
  };

  const [state, dispatch] = useReducer(ExitProductsReducer, initialState);

  const GetAllDepartures = (params) => {
    sessionStorage.removeItem("departure");
    let url = `/departures?limit=${params.limite}&page=${params.pages}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_EXIT_PRODUCTS_REPORTS,
          payload: {
            departures: res.data.departures,
            totalDocs: res.data.totalDocs,
            limit: res.data.limit,
            page: res.data.page,
            paginCounter: res.data.paginCounter,
            hasPrevPage: res.data.hasPrevPage,
            hasNextPage: res.data.hasNextPage,
            prevPage: res.data.prevPage,
            nextPage: res.data.nextPage,
            totalPages: res.data.totalPages,
          },
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: error.response.data.message,
          timer: 1500,
          showConfirmButton: false,
        });
      });
  };

  const GetAllDeparturesNoPaginate = () => {
    let url = "/departures/all";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_DEPARTURES_NO_PAGINATE,
          payload: res.data.departures,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const CreateDeparture = (departure) => {
    let url = `/departures/create-departures`;
    MethodPost(url, departure)
      .then((res) => {
        Swal.fire({
          title: "Salida generada",
          text: res.data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: ADD_EXIT_PRODUCTS_REPORTS,
          payload: res.data.departures,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.message,
        });
      });
  };

  const PdfDeparture = (id) => {
    Swal.fire({
      title: "¿Descargar reporte de salida?",
      text: "Se descargará el pdf de la salida",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, descargar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/departures/pdf/${id}`;
        MethodGet(url, { responseType: "blob" })
          .then((res) => {
            fileDownload(res.data, "ReporteSalidas.pdf");
            Swal.fire({
              title: "Descargado",
              text: "El reporte se ha descargado correctamente",
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
            });
            dispatch({
              type: SHOW_ERRORS_API,
            });
          });
      }
    });
  };

  const TicketDeparture = (id) => {
    Swal.fire({
      title: "¿Descargar Ticket de salida?",
      text: "Se descargará el ticket de la salida",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, descargar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/departures/ticket/${id}`;
        MethodGet(url, { responseType: "blob" })
          .then((res) => {
            fileDownload(res.data, "TicketSalidas.pdf");
            Swal.fire({
              title: "Descargado",
              text: "El reporte se ha descargado correctamente",
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
            });
            dispatch({
              type: SHOW_ERRORS_API,
            });
          });
      }
    });
  };

  return (
    <ExitProductsContext.Provider
      value={{
        departures: state.departures,
        allDepartures: state.allDepartures,
        departure: state.departure,
        ErrorsApi: state.ErrorsApi,
        success: state.success,
        GetAllDepartures,
        CreateDeparture,
        PdfDeparture,
        TicketDeparture,
        GetAllDeparturesNoPaginate,
        totalDocs: state.totalDocs,
        limit: state.limit,
        page: state.page,
        paginCounter: state.paginCounter,
        hasPrevPage: state.hasPrevPage,
        prevPage: state.prevPage,
        hasNextPage: state.hasNextPage,
        nextPage: state.nextPage,
        loading: state.loading,
        totalPages: state.totalPages,
      }}
    >
      {children}
    </ExitProductsContext.Provider>
  );
};

export default ExitProductsState;
