import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { DialogContentText, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ShippmentInternationalContext from "../../../context/ShippmentInternational/ShippmentInternationalContext";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { useEffect } from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const useStyles = makeStyles({
  titleProduct: {
    color: "white",
  },
  ojito: {
    color: "red",
  },
  img: {
    width: 450,
    height: 450,
  },
  imgproduct: {
    top: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
  },
  input: {
    height: "30px",
    border: "0px",
    borderColor: "white",
  },
  jss59: {
    width: "100%",
    border: "1px solid #bbb",
    height: "15%",
    outline: "none",
    padding: "15px 10px",
    marginLeft: "10px",
    borderRadius: "10px",
  },
  jss54: {
    color: "#fff",
    width: "100%",
    border: "0",
    margin: "5px 8px",
    padding: "10px",
    fontSize: "17px",
    marginTop: "20px",
    borderRadius: "4px",
    backgroundColor: "#e91e63",
    "&:hover": {
      color: "white",
      backgroundColor: "#e91e63",
      //hoverColor: "white",
      //fontFamily: "monospace",
      //fontWeight: "bolder",
      //fontSize: "15px",
    },
  },
});

export default function ModalChangePriceShippment(props) {
  const classes = useStyles();
  const { modal, handleClickOpen, handleClose } = props;
  const { success, UpdateShippmentInternational } = useContext(
    ShippmentInternationalContext
  );
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  useEffect(() => {
    if (success) {
      setValue("shippingCosts", "", { shouldDirty: true });
    }
  }, [success]);

  const onSubmit = (data, e) => {
    if (!data.shippingCosts) {
    } else {
      UpdateShippmentInternational(data);
      handleClose();
    }
  };

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
				Open dialog
			</Button> */}
      <Dialog open={modal} onClose={handleClose}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.code === "Enter") e.preventDefault();
          }}
          autoComplete="off"
        >
          <DialogTitle sx={{ backgroundColor: "black", color: "white" }}>
            Actualizar costo de envio internacional
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Aqui podras moficar el costo que se cobrará por el envio
              internacional
            </DialogContentText>
            <label>Costo de envió internacional</label>
            <TextField
              sx={{ marginTop: 2 }}
              //className={classes.jss59}
              autoFocus
              //margin="dense"
              id="name"
              type="number"
              min="1"
              name="shippingCosts"
              placeholder="Costo envio internacional"
              fullWidth
              variant="outlined"
              error={errors.shippingCosts ? true : false}
              helperText={errors?.shippingCosts?.message}
              {...register("shippingCosts", {
                required: {
                  value: true,
                  message: "Este campo es requerido",
                },
                min: {
                  value: 1,
                  message: "El valor minimo es uno",
                },
              })}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#d50000",
                color: "white",
                "&:hover": { backgroundColor: "#d50000", color: "white" },
              }}
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#2e7d32",
                color: "white",
                "&:hover": { backgroundColor: "#2e7d32", color: "white" },
              }}
              //onClick={handleClose}
            >
              Actualizar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
