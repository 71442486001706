import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Button, Typography } from "@mui/material";
import ModalProductsPending from "./ModalProductosPending";
import { useState } from "react";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function InfoInventories({ info, inventoried }) {
  const [openModal, setOpenModal] = useState(false);

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      {info && (
        <>
          <Grid container spacing={2} justifyContent="center" display="flex">
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Item>
                <Typography variant="body1">Productos inventariados</Typography>
                <Typography> {info.inventory.products.length} </Typography>
              </Item>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Item>
                    <Typography variant="body1">
                      Productos Restantes por inventariar
                    </Typography>
                    <Typography>{info.quantityProducts}</Typography>
                  </Item>
                </Grid>

                {info.inventory.status !== 2 && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    display="flex"
                    justifyContent="center"
                  >
                    <Button
                      size="large"
                      //sx={{ margin: 0, padding: "5px" }}
                      variant="contained"
                      onClick={handleClickOpenModal}
                    >
                      Productos pendientes
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>

          <ModalProductsPending
            open={openModal}
            handleClose={handleCloseModal}
            id_inventory={info.inventory}
            inventoried={inventoried}
          />
        </>
      )}
    </Box>
  );
}
