import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Card,
  Grid,
  IconButton,
  InputLabel,
  Tooltip,
} from "@mui/material";
import NoDataComponentes from "../loading/NoDataComponents";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { PhotoCamera } from "@material-ui/icons";
import SalesContext from "../../context/Sales/SalesContext";
import { useContext } from "react";
import { useForm } from "react-hook-form";
const useStyles = makeStyles({
  Imagelogo: {
    width: "250px",
    height: "250px",
    objectFit: "cover",
    textAlign: "center",
    padding: 10,
  },
});
export default function ShowFileEvidence({ open, handleClose, images, id }) {
  const { UpdateFileEvidence } = useContext(SalesContext);
  const classes = useStyles();
  const [imageFirst, saveImageFirst] = useState({
    urlPhotoFirst: images[0].path,
    imageFirst: "",
  });
  const [imageSecond, saveImageSecond] = useState({
    urlPhotoSecond: images[1].path,
    imageSecond: "",
  });
  const [imageThird, saveImageThird] = useState({
    urlPhotoThird: images[2].path,
    imageThird: "",
  });

  const imageFirstId = images[0]._id;
  const imageSecondId = images[1]._id;
  const imageThirdId = images[2]._id;

  const handleChangeMultimediaFirst = (e) => {
    saveImageFirst({
      ...imageFirst,
      urlPhotoFirst: URL.createObjectURL(e.target.files[0]),
      imageFirst: e.target.files[0],
    });
  };
  const handleChangeMultimediaSecond = (e) => {
    saveImageSecond({
      ...imageSecond,
      urlPhotoSecond: URL.createObjectURL(e.target.files[0]),
      imageSecond: e.target.files[0],
    });
  };
  const handleChangeMultimediaThird = (e) => {
    saveImageThird({
      ...imageThird,
      urlPhotoThird: URL.createObjectURL(e.target.files[0]),
      imageThird: e.target.files[0],
    });
  };
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const onSubmit = (data, e) => {
    data.id = id;
    if (imageFirst.imageFirst !== undefined) {
      data.imageFirst = imageFirst.imageFirst;
      data.imageFirstId = imageFirstId;
    }
    if (imageSecond.imageSecond !== undefined) {
      data.imageSecond = imageSecond.imageSecond;
      data.imageSecondId = imageSecondId;
    }
    if (imageThird.imageThird !== undefined) {
      data.imageThird = imageThird.imageThird;
      data.imageThirdId = imageThirdId;
    }

    //data.shipment_id = shippmentId;
    // data.pdf = file_first.file;

    UpdateFileEvidence(data);
    handleClose();
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {"Evidencia del pedido"}
        </DialogTitle>
        {images && (
          <form
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => {
              if (e.code === "Enter") e.preventDefault();
            }}
            autoComplete="off">
            <DialogContent>
              {images.length ? (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}>
                        <div>
                          <img
                            src={imageFirst.urlPhotoFirst}
                            style={{
                              height: "250px",
                              width: "250px",
                              //boxShadow: "5px 5px #289FED",
                              objectFit: "cover",
                            }}
                            alt="producto"
                          />
                        </div>
                      </Box>
                      <input
                        style={{ display: "none" }}
                        id="icon-button-file-first"
                        type="file"
                        name="image"
                        accept="image/png, image/jpg, image/jpeg, image/webp"
                        onChange={handleChangeMultimediaFirst}
                      />
                      <InputLabel sx={{ textAlign: "center" }}>
                        imagen :
                        <label htmlFor="icon-button-file-first">
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span">
                            <Tooltip
                              title="seleccionar imagen"
                              aria-label="seleccionar imagen">
                              <PhotoCamera />
                            </Tooltip>
                          </IconButton>
                        </label>
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}>
                        <div>
                          <img
                            src={imageSecond.urlPhotoSecond}
                            style={{
                              height: "250px",
                              width: "250px",
                              //boxShadow: "5px 5px #289FED",
                              objectFit: "cover",
                            }}
                            alt="producto"
                          />
                        </div>
                      </Box>
                      <input
                        style={{ display: "none" }}
                        id="icon-button-file-second"
                        type="file"
                        name="image"
                        accept="image/png, image/jpg, image/jpeg, image/webp"
                        onChange={handleChangeMultimediaSecond}
                      />
                      <InputLabel sx={{ textAlign: "center" }}>
                        imagen :
                        <label htmlFor="icon-button-file-second">
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span">
                            <Tooltip
                              title="seleccionar imagen"
                              aria-label="seleccionar imagen">
                              <PhotoCamera />
                            </Tooltip>
                          </IconButton>
                        </label>
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}>
                        <div>
                          <img
                            src={imageThird.urlPhotoThird}
                            style={{
                              height: "250px",
                              width: "250px",
                              //boxShadow: "5px 5px #289FED",
                              objectFit: "cover",
                            }}
                            alt="producto"
                          />
                        </div>
                      </Box>
                      <input
                        style={{ display: "none" }}
                        id="icon-button-file-third"
                        type="file"
                        name="image"
                        accept="image/png, image/jpg, image/jpeg, image/webp"
                        onChange={handleChangeMultimediaThird}
                      />
                      <InputLabel sx={{ textAlign: "center" }}>
                        imagen :
                        <label htmlFor="icon-button-file-third">
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span">
                            <Tooltip
                              title="seleccionar imagen"
                              aria-label="seleccionar imagen">
                              <PhotoCamera />
                            </Tooltip>
                          </IconButton>
                        </label>
                      </InputLabel>
                    </Grid>
                  </Grid>
                  {images[0].path !== imageFirst.urlPhotoFirst && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      justifyContent="end"
                      display="flex">
                      <Button
                        type="submit"
                        sx={{
                          backgroundColor: "#1976d2",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#1976d2",
                            color: "white",
                          },
                        }}
                        //onClick={handleClose}
                        autoFocus>
                        Actualizar Imagenes
                      </Button>
                    </Grid>
                  )}
                </>
              ) : (
                <NoDataComponentes />
              )}
            </DialogContent>
          </form>
        )}
      </Dialog>
    </div>
  );
}
