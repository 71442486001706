import React, {useState, useContext} from "react";
import AuthContext from "../../../context/auth/authContext";
import {useForm} from "react-hook-form";
//Estilos , Icons y Alerta
import {makeStyles} from "@mui/styles";
import {
  Grid,
  Box,
  Fab,
  Tooltip,
  InputLabel,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";

//
const useStyles = makeStyles({
  logoimagen: {
    height: "200px",
    boxShadow: "5px 5px #289FED",
    objectFit: "cover",
  },
  input: {
    display: "none",
  },
  card: {
    marginTop: 7,
    marginLeft: 7,
    marginRight: 7,
  },
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 10,
  },
});

export default function ChangePhoPerfil(props) {
  const classes = useStyles();
  const {ChangePhoto} = useContext(AuthContext);

  const {modal, handleClickClose, photoperfil} = props;
  const [image, setImage] = useState({
    urlPhoto: photoperfil,
    image: "",
  });

  const handleChangeImage = (e) => {
    setImage({
      ...image,
      urlPhoto: URL.createObjectURL(e.target.files[0]),
      image: e.target.files[0],
    });
  };

  const {
    register,
    formState: {errors},
    handleSubmit,
    setValue,
  } = useForm();

  const onSubmit = (data, e) => {
    data.image = image.image;
    ChangePhoto(data);
    handleClickClose();
    props.history.push('/');
  };
  

  return (
    <Dialog open={modal} onClose={handleClickClose}>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#000000",
            textAling: "center",
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            borderRadius: "0 0 24px 0",
          }}
        >
          <div className={classes.titleCard}>Cambia tu foto de Perfil</div>
          <div className={classes.fab}>
            <Tooltip title="cerrar" arrow>
              <Fab
                size="small"
                onClick={handleClickClose}
                sx={{
                  color: "#FFFFFF",
                  backgroundColor: "#FF0000",
                  "&:hover": {
                    color: "#FFFFFF",
                    backgroundColor: "#FF0000",
                  },
                }}
              >
                <CancelPresentationIcon />
              </Fab>
            </Tooltip>
          </div>
        </Box>
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.code === "Enter") e.preventDefault();
          }}
          autoComplete="off"
        >
          <Box sx={{width: "100%", display: "flex", paddingTop: 4, p: 3}}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <img
                      src={image.urlPhoto}
                      className={classes.logoimagen}
                      alt="agrega marca"
                    />
                  </div>
                </Box>

                <input
                  className={classes.input}
                  id="icon-button-file"
                  type="file"
                  name="image"
                  accept="image/png, image/jpg, image/jpeg, image/webp"
                  onChange={handleChangeImage}
                />
                <InputLabel sx={{textAlign: "center"}}>
                  Selecciona la Imagen de Perfil:
                  <label htmlFor="icon-button-file">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <Tooltip
                        title="seleccionar imagen"
                        aria-label="seleccionar imagen"
                      >
                        <PhotoCamera />
                      </Tooltip>
                    </IconButton>
                  </label>
                </InputLabel>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    textAling: "center",
                  }}
                >
                  <div className={classes.fab}>
                    <Fab
                      type="submit"
                      variant="extended"
                      sx={{
                        backgroundColor: "#00B9FF",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#00DEED",
                          color: "white",
                        },
                      }}
                    >
                      <SaveOutlinedIcon sx={{mr: 1}} />
                      Guardar
                    </Fab>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}
