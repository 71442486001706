import React, { useReducer } from "react";
import Swal from "sweetalert2";
import MethodGet, {
	MethodDelete,
	MethodPost,
	MethodPut,
} from "../../config/Service";
import {
	ADD_BANKS,
	CURRENT_BANKS,
	DELETE_BANKS,
	GET_ALL_BANKS,
	SHOW_ERRORS_API,
	UPDATE_BANKS,
} from "../../types";
import BanksContext from "./BanksContext";
import BanksReducer from "./BanksReducer";
import headerConfig from "../../config/imageHeaders";

const BanksState = ({ children }) => {
	//estado inciial
	const initialState = {
		banks: [],
		bank: null,
		ErrorsApi: [],
		success: false,
	};

	const [state, dispatch] = useReducer(BanksReducer, initialState);

	//obtener la lista de bancos
	const GetBanks = () => {
		sessionStorage.removeItem("bank");
		let url = "/banks";
		MethodGet(url)
			.then((res) => {
				dispatch({
					type: GET_ALL_BANKS,
					payload: res.data.banks,
				});
			})
			.catch((error) => {
				Swal.fire({
					title: "Error",
					text: error.response.data.message,
					icon: "error",
				});
				dispatch({
					type: SHOW_ERRORS_API,
					payload: error.response.data.message,
				});
			});
	};

	//registrar vbanco
	const AddBanks = (bank) => {
		const formData = new FormData();
		formData.append("name", bank.name);
		formData.append("image", bank.image);

		let url = "/banks/";
		MethodPost(url, formData, { headerConfig })
			.then((res) => {
				Swal.fire({
					title: "Banco Registrado",
					text: "El banco se ha registrado exitosamente",
					icon: "success",
					timer: 1000,
					showConfirmButton: false,
				});
				dispatch({
					type: ADD_BANKS,
				});
			})
			.catch((error) => {
				Swal.fire({
					title: "Error",
					text: "Ocurrio un error al guardar el banco, si el error continua comuniquese con el administrador del sistema",
					timer: 2000,
					showConfirmButton: false,
					icon: "error",
				});
				dispatch({
					type: SHOW_ERRORS_API,
					payload: error.response.data.error,
				});
			});
	};

	//funcion para editar banco
	const UpdateBanks = (bank) => {
		const formData = new FormData();
		formData.append("name", bank.name);
		formData.append("image", bank.image);

		let url = `/banks/${bank.id}`;
		MethodPut(url, formData, { headerConfig })
			.then((res) => {
				Swal.fire({
					title: "Banco Actualizado",
					text: res.data.message,
					timer: 1000,
					icon: "success",
					showConfirmButton: false,
				});
				dispatch({
					type: UPDATE_BANKS,
				});
			})
			.catch((error) => {
				Swal.fire({
					title: "Error",
					text: error.response.data.message,
					icon: "error",
				});
				dispatch({
					type: SHOW_ERRORS_API,
					payload: error.response.data.error,
				});
			});
	};

	//eliminar banck
	const DeleteBanks = (id) => {
		Swal.fire({
			title: "¿Estas Seguro?",
			text: "El Banco seleccionado será eliminado",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Si, eliminar",
			cancelButtonText: "No, cancelar",
		}).then((result) => {
			if (result.value) {
				let url = `/banks/${id}`;

				MethodDelete(url)
					.then((res) => {
						Swal.fire({
							title: "Eliminado",
							text: res.data.message,
							icon: "success",
							timer: 2000,
							showConfirmButton: false,
						});
						dispatch({
							type: DELETE_BANKS,
							payload: id,
						});
					})
					.catch((error) => {
						Swal.fire({
							title: "Error",
							text: error.response.data.message,
							icon: "error",
							timer: 2500,
							showConfirmButton: false,
						});
					});
			}
		});
	};

	//funcion para seleciionar ba cos
	const SaveCurrentBank = (bank) => {
		sessionStorage.setItem("bank", JSON.stringify(bank));
		dispatch({
			type: CURRENT_BANKS,
			payload: bank,
		});
	};

	return (
		<BanksContext.Provider
			value={{
				banks: state.banks,
				bank: state.bank,
				ErrorsApi: state.ErrorsApi,
				success: state.success,
				GetBanks,
				AddBanks,
				UpdateBanks,
				DeleteBanks,
				SaveCurrentBank,
			}}
		>
			{children}
		</BanksContext.Provider>
	);
};

export default BanksState;
