import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Layout from "../../components/layout/Layout";
import { Button, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import ModalAdd from "./ModalAdd";
import BusinessRulesContext from "../../context/BusinessRules/BusinessRulesContext";
import ModulesContext from "../../context/Permissions/PermissionsContext";
import { useContext } from "react";
import { useEffect } from "react";
import NoDataComponents from "../../components/loading/NoDataComponents";
import CardBusinessRules from "../../components/Cards/businessrules/CardBusinessRules";
import CardBusinessRulesSubcategory from "../../components/Cards/businessrules/CardBusinessRulesSubcategory";
import CardBusinessRulesCategory from "../../components/Cards/businessrules/CardBusinessRulesCategory";
import CardBusinessSalesFisic from "../../components/Cards/businessrules/CardBusinessSalesFisic";
import CardBusinessRulesShippment from "../../components/Cards/businessrules/CardBusinessRuleShippment";
const useStyles = makeStyles({
  titleCard: {
    color: "white",
    fontSize: "35px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 2,
    marginRight: 5,
  },
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function IndexBusinessRules() {
  const classes = useStyles();
  const { permissionsPerUser, GetModulesPerUser } = useContext(ModulesContext);
  const {
    business_rules_sales,
    GetBusinessRulesSales,
    business_rules_subcategory,
    GetBusinessRulesSubcategory,
    business_rules_category,
    GetBusinessRulesCategory,
    business_rules_shippment,
    GetBusinessRulesShippemnt,
  } = useContext(BusinessRulesContext);
  useEffect(() => {
    if (localStorage.getItem("module_id")) {
      const permiss = localStorage.getItem("module_id");
      GetModulesPerUser(permiss);
    }
    GetBusinessRulesSales();
    GetBusinessRulesSubcategory();
    GetBusinessRulesCategory();
    GetBusinessRulesShippemnt();
  }, []);
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //abrir modal
  const [modalAdd, AbrirModalAdd] = useState(false);
  const handleClickOpenAdd = () => {
    AbrirModalAdd(true);
  };
  const handleClickCloseAdd = () => {
    AbrirModalAdd(false);
  };

  return (
    <Layout>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#000000",
              textAling: "center",
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            }}>
            <div className={classes.titleCard}>Reglas de Negocio </div>
            <div className={classes.fab}>
              <Button
                onClick={handleClickOpenAdd}
                sx={{
                  color: "black",
                  backgroundColor: "#26c6da",
                  hoverColor: "white",
                  fontFamily: "monospace",
                  fontWeight: "bolder",
                  fontSize: "15px",
                  textTransform: "none",
                  "&:hover": {
                    color: "black",
                    backgroundColor: "#67cedb",
                    hoverColor: "white",
                    fontFamily: "monospace",
                    fontWeight: "bolder",
                    fontSize: "15px",
                  },
                }}>
                Agregar
              </Button>
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                variant="scrollable"
                onChange={handleChange}
                aria-label="basic tabs example">
                <Tab label="Ventas Ecommerce" {...a11yProps(0)} />
                <Tab label="Subcategorias" {...a11yProps(1)} />
                <Tab label="Categorias" {...a11yProps(2)} />
                <Tab label="Costos de envio" {...a11yProps(3)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid container spacing={2}>
                    {!business_rules_sales.length ? (
                      <NoDataComponents />
                    ) : (
                      business_rules_sales.map((business_rule, index) => (
                        <CardBusinessRules
                          business_rule={business_rule}
                          permiss={permissionsPerUser}
                          key={index}
                        />
                      ))
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Grid container spacing={2}>
                {!business_rules_shippment.length ? (
                  <NoDataComponents />
                ) : (
                  business_rules_shippment.map((business_rule, index) => (
                    <CardBusinessRulesShippment
                      business_rule={business_rule}
                      permiss={permissionsPerUser}
                      key={index}
                    />
                  ))
                )}
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Grid container spacing={2}>
                {!business_rules_subcategory.length ? (
                  <NoDataComponents />
                ) : (
                  business_rules_subcategory.map((business_rule, index) => (
                    <CardBusinessRulesSubcategory
                      business_rule={business_rule}
                      permiss={permissionsPerUser}
                      key={index}
                    />
                  ))
                )}
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Grid container spacing={2}>
                {!business_rules_category.length ? (
                  <NoDataComponents />
                ) : (
                  business_rules_category.map((business_rule, index) => (
                    <CardBusinessRulesCategory
                      business_rule={business_rule}
                      permiss={permissionsPerUser}
                      key={index}
                    />
                  ))
                )}
              </Grid>
            </TabPanel>
          </Box>
        </Grid>
        <ModalAdd
          modal={modalAdd}
          handleClickOpen={handleClickOpenAdd}
          handleClose={handleClickCloseAdd}
        />
      </Grid>
    </Layout>
  );
}
