import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import MethodGet from "../../../config/Service";
import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import SalesContext from "../../../context/Sales/SalesContext";
import { useContext } from "react";
import SpinnerSmallComponent from "../../../components/loading/SpinnerSmallComponent";
export default function ModalShippingCost({ open, handleClose, id }) {
  const [order, saveOrder] = useState();
  const [cargando, spinnerCargando] = useState(false);

  React.useEffect(() => {
    let url = `/orders/${id}`;
    if (id !== "" || id !== undefined || id !== null) {
      MethodGet(url)
        .then((res) => {
          saveOrder(res.data.order);
          spinnerCargando(true);
        })
        .catch((error) => {
          console.log(error);
          spinnerCargando(true);
        });
    }
  }, [id]);
  const { UpdateShippingCost } = useContext(SalesContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const reset = () => {
    setValue("shippment", "", { shouldDirty: true });
  };

  const onSubmit = (data, e) => {
    data.id = id;
    UpdateShippingCost(data);
    handleClose();
    reset();
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {"Modificar el costo de envio"}
        </DialogTitle>

        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter")
              e.preventDefault();
          }}
          autoComplete="off">
          <DialogContent>
            {order && (
              <>
                <label>
                  Folio venta: <b>{order?.folio}</b>
                </label>
                <br />
              </>
            )}
            {order ? (
              <>
                <label>Costo de envio</label>
                <TextField
                  fullWidth
                  name="shippment"
                  defaultValue={order.shippment ? order.shippment : 0}
                  type="number"
                  error={errors.shippment ? true : false}
                  helperText={errors?.shippment?.message}
                  {...register("shippment", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                  })}
                />
              </>
            ) : (
              <SpinnerSmallComponent />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              variant="contained"
              size="large"
              color="secondary">
              Cancelar
            </Button>
            <Button type="submit" autoFocus variant="contained" size="large">
              Actualizar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
