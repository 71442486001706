import { CardContent, Grid, Box, Fab, TextField, Tooltip } from "@mui/material";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import React, { useContext, useEffect, useState } from "react";

import Layout from "../../components/layout/Layout";
import BuildingComponent from "../../components/loading/BuildingComponent";
import SelectTypeProduct from "../SelectOptions/SelectTypeProduct";
import { useForm } from "react-hook-form";
import MethodGet from "../../config/Service";
import ProductsContext from "../../context/Products/ProductsContext";
import BrandSelect from "../SelectOptions/BrandSelect";
import SelectSubcategoriesCategories from "../SelectOptions/SelectSubcategoriesCategories";
import CategorySelect from "../SelectOptions/SelectCategory";
import { PriceFormat } from "../../utils/FormatPrice";
import { makeStyles } from "@mui/styles";
import AddCircle from "@mui/icons-material/AddCircle";
import AddModalTag from "../tags/Add";
import VariantsCanvasSelect from "../SelectOptions/VariantsCanvasSelect";
import SelectTagEdit from "../SelectOptions/SelectTagEdit";
import SpinnerComponent from "../../components/loading/SpinnerComponent";
import Swal from "sweetalert2";
const useStyles = makeStyles(() => ({
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 2,
  },
}));

export default function Edit(props) {
  const id = props.match.params.id;
  const classes = useStyles();
  const formEditProduct = useContext(ProductsContext);
  const { UpdateProduct } = formEditProduct;
  const [cargando, spinnerCargando] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const [product, saveProduct] = useState({});
  const [typeProductId, saveTypeProduct] = useState(null);
  const [CategoryId, saveCategory] = useState(null); //categoria
  const [SubcategoryId, saveSubcategory] = useState(null); //subcategoria
  const [brandId, saveBrand] = useState(null); //marca
  const [variantId, saveVariation] = useState(null);
  const [tagId, saveTag] = useState(0); //tags
  const [pruebaTags, savePruebaTags] = useState([]);
  const [minPrice, setMinPrice] = useState(0);
  const [location, saveLocation] = useState(null);
  //const [variant, saveVariant] = useState();
  //Inout Label
  const [modalAdd, AbrirModalAdd] = useState(false);

  const handleClickOpenAdd = () => {
    AbrirModalAdd(true);
  };
  const handleClickCloseAdd = () => {
    AbrirModalAdd(false);
  };
  const detectarCambiosCategory = (e) => {
    saveCategory(e.target.value);
  };
  const detectarCambiosSubcategoryCategory = (e) => {
    saveSubcategory(e.target.value);
  };
  const detectarCambiosBrand = (e) => {
    saveBrand(e.target.value);
  };

  const detectarCambiosTypeProduct = (e) => {
    saveTypeProduct(e.target.value);
  };

  const detectarCambiosTagEdit = (value) => {
    saveTag(value);
    setValue("tags", product.tags, { shouldDirty: true });
  };

  const detectarCambiosCanvasVariants = (e) => {
    saveVariation(e.target.value);
  };
  useEffect(() => {
    let url = `/products/admin/${id}`;
    MethodGet(url)
      .then((res) => {
        saveProduct(res.data.product);
        const valorPricePurchase =
          parseFloat(res.data.product.price_purchase) + 1;
        setMinPrice(valorPricePurchase);
        saveTypeProduct(res.data.product.product_type);
        saveCategory(res.data.product.category._id);
        saveBrand(res.data.product.brand._id);
        saveSubcategory(res.data.product.subcategory._id);
        saveTag(res.data.product.tags);
        saveLocation(res.data.product.location);
        if (res.data.product.variation) {
          saveVariation(res.data.product.variation._id);
        }
        //saveVariant(res.data.product.variation);
        spinnerCargando(true);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
        spinnerCargando(true);
      });
  }, [id]);

  const tagsSelected = tagId
    ? tagId.map(({ tag_id }) => {
        let TagSelected = {
          label: tag_id.name,
          value: tag_id._id,
        };
        return TagSelected;
      })
    : [];

  const onSubmit = (data, e) => {
    if (pruebaTags.length) {
      data.tags = pruebaTags;
    } else {
      data.tags = tagsSelected;
    }

    data.id = id;
    data.brand = brandId;
    //data.location = location;
    data.product_type = typeProductId;
    data.category = CategoryId;
    data.subcategory = SubcategoryId;
    data.price = Number(data.price);

    if (typeProductId === "2") {
      data.variation = variantId;
    }
    data.price_purchase = PriceFormat(data.price_purchase);

    //console.log(data, "la data que se actualiza");
    UpdateProduct(data);
    props.history.goBack();
  };

  if (!cargando) return <BuildingComponent />;

  return (
    <Layout>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#000000",
              textAling: "center",
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
              // borderRadius: "0 0 24px 0",
            }}
          >
            <div className={classes.titleCard}>Editar Producto</div>
            <div className={classes.fab}>
              <Tooltip title="Regresar">
                {/* <Link to="/Productos"> */}
                <Fab
                  size="small"
                  onClick={() => props.history.goBack()}
                  sx={{
                    color: "white",
                    backgroundColor: "black",
                    borderRadius: "24px",
                    marginRight: 1,
                    "&:hover": {
                      backgroundColor: "white",
                      color: "black",
                    },
                  }}
                >
                  <ArrowCircleLeftOutlinedIcon />
                </Fab>
                {/* </Link> */}
              </Tooltip>
            </div>
          </Box>
          {product ? (
            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(e) => {
                if (e.code === "Enter" || e.code === "NumpadEnter")
                  e.preventDefault();
              }}
              autoComplete="off"
            >
              <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    {typeProductId !== "" && (
                      <SelectTypeProduct
                        typeProductId={product?.product_type}
                        detectarCambiosTypeProduct={detectarCambiosTypeProduct}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id="barcode"
                      label="Codigo de barras"
                      name="barcode"
                      defaultValue={product.barcode}
                      error={errors.barcode ? true : false}
                      helperText={errors?.barcode?.message}
                      {...register("barcode", {
                        required: {
                          value: true,
                          message: "EL codigo de barras es necesario",
                        },
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id="sku"
                      label="SKU"
                      name="sku"
                      defaultValue={product.sku}
                      error={errors.sku ? true : false}
                      helperText={errors?.sku?.message}
                      {...register("sku", {
                        required: {
                          value: true,
                          message: "EL SKU es requerido",
                        },
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id="product_key"
                      label="Product key SAT"
                      name="product_key"
                      type="number"
                      defaultValue={product.product_key}
                      error={errors.product_key ? true : false}
                      helperText={errors?.product_key?.message}
                      {...register("product_key", {
                        required: {
                          value: true,
                          message: "EL product key SAT es requerido",
                        },
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id="google_product_category"
                      label="Product Google Category"
                      name="google_product_category"
                      defaultValue={product.google_product_category}
                      error={errors.google_product_category ? true : false}
                      helperText={errors?.google_product_category?.message}
                      {...register("google_product_category", {
                        required: {
                          value: true,
                          message: "La clave Google_Category es requerido",
                        },
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField
                      variant="outlined"
                      id="name"
                      label="Nombre"
                      name="name"
                      fullWidth
                      defaultValue={product.name}
                      error={errors.name ? true : false}
                      helperText={errors?.name?.message}
                      {...register("name", {
                        required: {
                          value: true,
                          message: "El Nombre es requerido",
                        },
                        minLength: {
                          value: 1,
                          message: "Minimo 1 caracteres",
                        },
                        maxLength: {
                          value: 255,
                          message: "Maximo 255 caracteres",
                        },
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    {brandId && (
                      <BrandSelect
                        brandId={brandId}
                        detectarCambiosBrand={detectarCambiosBrand}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    {CategoryId !== "" && (
                      <CategorySelect
                        CategoryId={CategoryId}
                        detectarCambiosCategory={detectarCambiosCategory}
                      />
                    )}
                  </Grid>
                  {CategoryId !== "" ? (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <SelectSubcategoriesCategories
                        category_id={CategoryId}
                        SubcategoryId={SubcategoryId}
                        detectarCambiosSubcategoryCategory={
                          detectarCambiosSubcategoryCategory
                        }
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}></Grid>
                  )}

                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      id="price_purchase"
                      name="price_purchase"
                      defaultValue={product.price_purchase}
                      label="Precio de compra"
                      variant="outlined"
                      error={errors.price_purchase ? true : false}
                      helperText={errors?.price_purchase?.message}
                      {...register("price_purchase", {
                        required: {
                          value: true,
                          message: "El precio de compra es requerido",
                        },
                        onChange: ({ target }) => {
                          const minValor = parseFloat(target.value) + 1;
                          setMinPrice(minValor);
                        },
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      id="price"
                      name="price"
                      label="Precio"
                      defaultValue={product.price}
                      variant="outlined"
                      error={errors.price ? true : false}
                      helperText={errors?.price?.message}
                      {...register("price", {
                        required: {
                          value: true,
                          message: "El precio es requerido",
                        },

                        min: {
                          value: parseFloat(minPrice),
                          message:
                            "El precio tiene que ser mayor al precio compra",
                        },
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      id="location"
                      placeholder="Ejemplo: 12"
                      label="Ubicación"
                      name="location"
                      defaultValue={location}
                      variant="outlined"
                      error={errors.location ? true : false}
                      helperText={errors?.location?.message}
                      {...register("location", {
                        required: {
                          value: true,
                          message: "Este campo es requerido",
                        },
                      })}
                    />
                  </Grid>
                  {typeProductId === "1" && (
                    <>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextField
                          fullWidth
                          id="weight"
                          label="Peso"
                          name="weight"
                          placeholder="Introduce el peso del producto en gramos."
                          variant="outlined"
                          type="number"
                          defaultValue={product.weight}
                          error={errors.weight ? true : false}
                          helperText={errors?.weight?.message}
                          {...register("weight", {
                            required: {
                              value: true,
                              message: "Este campo es requerido",
                            },
                          })}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextField
                      id="Discount"
                      fullWidth
                      defaultValue={product.discount}
                      name="discount"
                      label="Descuento"
                      variant="outlined"
                      type="number"
                      error={errors.discount ? true : false}
                      helperText={errors?.discount?.message}
                      {...register("discount", {
                        required: {
                          value: false,
                          message: "Este campo es requerido",
                        },
                        max: {
                          value: 50,
                          message: "Maximo 50% de descuento",
                        },
                      })}
                    />
                  </Grid>
                  {typeProductId === "2" && typeProductId !== "" && (
                    <>
                      <Grid item xs={4} md={4} sm={6} lg={6} xl={6}>
                        <VariantsCanvasSelect
                          detectarCambiosCanvasVariants={
                            detectarCambiosCanvasVariants
                          }
                          variantId={variantId}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      id="outlined-multiline-static"
                      fullWidth
                      label="Descripcion"
                      name="description"
                      multiline
                      defaultValue={product.description}
                      placeholder="Ingresa la descripcion del producto"
                      rows={4}
                      variant="outlined"
                      error={errors.description ? true : false}
                      helperText={errors?.description?.message}
                      {...register("description", {
                        required: {
                          value: true,
                          message: "La descripcion es requerida",
                        },
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <SelectTagEdit
                      tagId={tagId}
                      savePruebaTags={savePruebaTags}
                      detectarCambiosTagEdit={detectarCambiosTagEdit}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    xl={4}
                    justifyContent="center"
                    display="flex"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                        textAling: "center",
                      }}
                    >
                      <div css={{ position: "relative", marginTop: 2 }}>
                        <Fab
                          variant="extended"
                          onClick={handleClickOpenAdd}
                          sx={{
                            backgroundColor: "black",
                            color: "white",
                            "&:hover": {
                              backgroundColor: "black",
                              color: "white",
                            },
                          }}
                        >
                          <AddCircle sx={{ mr: 1 }} />
                          Agregar Etiquetas
                        </Fab>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                        textAling: "center",
                      }}
                    >
                      <div css={{ position: "relative", marginTop: 2 }}>
                        <Fab
                          type="submit"
                          variant="extended"
                          sx={{
                            backgroundColor: "#00B9FF",
                            color: "white",
                            "&:hover": {
                              backgroundColor: "#00DEED",
                              color: "white",
                            },
                          }}
                        >
                          <SaveOutlinedIcon sx={{ mr: 1 }} />
                          Guardar
                        </Fab>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </form>
          ) : (
            <SpinnerComponent />
          )}

          <CardContent></CardContent>
        </Grid>
        <AddModalTag
          modal={modalAdd}
          handleClickOpen={handleClickOpenAdd}
          handleClose={handleClickCloseAdd}
        />
      </Grid>
    </Layout>
  );
}
