import React, { useContext, useEffect } from "react";
import TypeUsersContext from "../../context/TypeUsers/TypeUsersContext";
import { useForm } from "react-hook-form";
//Estilos , Icons y Alerta
import Swal from "sweetalert2";
import { makeStyles } from "@mui/styles";
import {
	Grid,
	Box,
	Fab,
	TextField,
	Tooltip,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
} from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";

const useStyles = makeStyles({
	input: {
		display: "none",
	},
	card: {
		marginTop: 7,
		marginLeft: 7,
		marginRight: 7,
	},
	titleCard: {
		color: "white",
		fontSize: "30px",
		fontFamily: "roboto",
		fontWeight: "bolder",
		paddingLeft: 10,
	},
	fab: {
		position: "relative",
		marginTop: 10,
	},
});

export default function Add(props) {
	const classes = useStyles();
	const { modal, handleClose } = props;
	const { ErrorsAPI, success, AddTypeUsers } = useContext(TypeUsersContext);

	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue,
	} = useForm();

	useEffect(() => {
		if (success) {
			setValue("name", "", { shouldDirty: true });
		}
	}, [success]);

	const onSubmit = (data, e) => {
		// e.preventDefault();
		if (data !== "") {
			AddTypeUsers(data);
		} else {
			Swal.fire({
				title: "error",
				text: "Todos los campos son obligatorios",
				icon: "error",
			});
		}
	};

	return (
		<Dialog open={modal} onClose={handleClose}>
			<DialogTitle>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						backgroundColor: "#000000",
						textAling: "center",
						boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
						borderRadius: "0 0 24px 0",
					}}
				>
					<div className={classes.titleCard}>Agrega Tipo de usuario</div>
					<div className={classes.fab}>
						<Tooltip title="cerrar" arrow>
							<Fab
								size="small"
								onClick={handleClose}
								sx={{
									color: "#FFFFFF",
									backgroundColor: "#FF0000",
									"&:hover": {
										color: "#FFFFFF",
										backgroundColor: "#FF0000",
									},
								}}
							>
								<CancelPresentationIcon />
							</Fab>
						</Tooltip>
					</div>
				</Box>
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Aquí, podras registrar roles para gestionar el sistema
				</DialogContentText>
				<form
					onSubmit={handleSubmit(onSubmit)}
					onKeyDown={(e) => {
						if (e.code === "Enter" || e.code === "NumpadEnter")
							e.preventDefault();
					}}
					autoComplete="off"
				>
					<Box sx={{ width: "100%", display: "flex" }}>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<TextField
									fullWidth
									id="name"
									name="name"
									label="Define el tipo de usuario"
									variant="outlined"
									error={errors.name ? true : false}
									helperText={errors?.name?.message}
									{...register("name", {
										required: {
											value: true,
											message: "Este campo es requerido",
										},
									})}
									sx={{ width: "100%", color: "#343a40" }}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Box
									sx={{
										display: "flex",
										justifyContent: "end",
										alignItems: "center",
										textAling: "center",
									}}
								>
									<div className={classes.fab}>
										<Fab
											type="submit"
											onClick={handleClose}
											variant="extended"
											sx={{
												backgroundColor: "#00B9FF",
												color: "white",
												"&:hover": {
													backgroundColor: "#00DEED",
													color: "white",
												},
											}}
										>
											<SaveOutlinedIcon sx={{ mr: 1 }} />
											Guardar
										</Fab>
									</div>
								</Box>
							</Grid>
						</Grid>
					</Box>
				</form>
			</DialogContent>
		</Dialog>
	);
}
