import React from "react";
import Layout from "./../../../components/layout/Layout";
import { Button, Grid, TextField, Tooltip, Typography } from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useState } from "react";
import ModalAddClientAccount from "./ModalAddClientAccount";
import TypeInvoice from "../../SelectOptions/TypeInvoice";
import { useForm } from "react-hook-form";
import SelectClientsAccount from "../../SelectOptions/SelectClientsAccount";
import AccountsReceivableContext from "../../../context/AccountsReceivable/AccountsReceivableContext";
import { useContext } from "react";
import Swal from "sweetalert2";
const AddAccountsReceivable = (props) => {
  /**modal */
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  /**clients */
  const [client, saveClient] = useState("");
  const detectarCambiosClientAccount = (e) => {
    saveClient(e.target.value);
  };
  /**invoice */
  const [typeInvoice, saveTypeInvoice] = useState("");
  const detectarCambiosPaymentsInvoice = (e) => {
    saveTypeInvoice(e);
  };
  const { AddAccountReceivable } = useContext(AccountsReceivableContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const reset = () => {
    setValue("amount", "", { shouldDirty: true });
  };
  const onSubmit = (data, e) => {
    if (typeInvoice === "") {
      Swal.fire({
        title: "Error",
        text: "Selecciona un método de pago, antes de continuar",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }
    if (client === "") {
      Swal.fire({
        title: "Error",
        text: "Selecciona un cliente, antes de continuar",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }
    // data.client = typeClient;
    data.client_id = client;
    data.typeInvoice = typeInvoice;
    console.log(data);

    AddAccountReceivable(data);
    props.history.goBack();
    //handleClose();
    reset();
  };
  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ backgroundColor: "black", color: "white" }}>
          <Typography fontWeight="bold" fontSize="35px">
            Agregar cuenta por cobrar
          </Typography>
        </Grid>
      </Grid>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.code === "Enter" || e.code === "NumpadEnter")
            e.preventDefault();
        }}
        autoComplete="off">
        <Grid container spacing={2} sx={{ padding: 2 }}>
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            <SelectClientsAccount
              detectarCambiosClientAccount={detectarCambiosClientAccount}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            display="flex"
            justifyContent="center">
            <Tooltip title="Registrar cliente" placement="top">
              <Button onClick={handleClickOpen}>
                <PersonAddIcon sx={{ fontSize: "45px", color: "#0d47a1" }} />
              </Button>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              fullWidth
              name="amount"
              label="Monto"
              type="number"
              error={errors.amount ? true : false}
              helperText={errors?.amount?.message}
              {...register("amount", {
                required: {
                  value: true,
                  message: "Este campo es requerido",
                },
              })}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TypeInvoice
              detectarCambiosPaymentsInvoice={detectarCambiosPaymentsInvoice}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Button variant="contained" size="large" type="submit">
              Guardar
            </Button>
          </Grid>
        </Grid>
      </form>
      <ModalAddClientAccount open={open} handleClose={handleClose} />
    </Layout>
  );
};

export default AddAccountsReceivable;
