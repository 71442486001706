import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import Layout from "../../../components/layout/Layout";
import MethodGet from "../../../config/Service";
import AccountsReceivableContext from "../../../context/AccountsReceivable/AccountsReceivableContext";
import TypeInvoice from "../../SelectOptions/TypeInvoice";

const AddAccountReceivableToClient = (props) => {
  const clientId = props.match.params.clientId;
  const [client, saveClient] = useState();
  useEffect(() => {
    let url = `/Clients/${clientId}`;
    MethodGet(url)
      .then((res) => {
        saveClient(res.data.client);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [clientId]);
  const [typeInvoice, saveTypeInvoice] = useState("");
  const detectarCambiosPaymentsInvoice = (e) => {
    saveTypeInvoice(e);
  };
  const { AddAccountReceivable } = useContext(AccountsReceivableContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const reset = () => {
    setValue("amount", "", { shouldDirty: true });
    saveTypeInvoice();
  };
  const onSubmit = (data, e) => {
    // data.client = typeClient;
    if (typeInvoice === "") {
      Swal.fire({
        title: "Error",
        timer: 1500,
        showConfirmButton: false,
        icon: "error",
        text: "Selecciona un método de pago, para continuar",
      });
      return;
    }
    data.client_id = clientId;
    data.typeInvoice = typeInvoice;
    AddAccountReceivable(data);
    //handleClose();
    reset();
    props.history.goBack();
  };
  return (
    <Layout>
      <Box sx={{ backgroundColor: "black", color: "white" }}>
        <Typography fontWeight="bold" fontSize="35px" fontFamily="monospace">
          Agregar cuenta a cliente
        </Typography>
      </Box>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.code === "Enter" || e.code === "NumpadEnter")
            e.preventDefault();
        }}
        autoComplete="off">
        <Grid container spacing={2} sx={{ padding: 2 }}>
          {client && (
            <Grid item xs={12}>
              <Typography fontWeight="bold">
                Cliente: {client.fullname}
              </Typography>
            </Grid>
          )}
          <Grid container spacing={2} sx={{ padding: 2 }}>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
              {/* <SelectClientsAccount
                detectarCambiosClientAccount={detectarCambiosClientAccount}
              /> */}
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                fullWidth
                name="amount"
                label="Monto"
                type="number"
                error={errors.amount ? true : false}
                helperText={errors?.amount?.message}
                {...register("amount", {
                  required: {
                    value: true,
                    message: "Este campo es requerido",
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TypeInvoice
                detectarCambiosPaymentsInvoice={detectarCambiosPaymentsInvoice}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Button variant="contained" size="large" type="submit">
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Layout>
  );
};

export default AddAccountReceivableToClient;
