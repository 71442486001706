import React, { useContext, useEffect } from "react";
import TypeClientsContext from "../../context/TypeClients/TypeClientsContext";

import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

export default function SelectTypeClient(props) {
  const { typeClients, GetTypeClients } = useContext(TypeClientsContext);

  useEffect(() => {
    GetTypeClients();
  }, []);

  const detectarCambiosTypeClients = (e) => {
    if (e.target.value) {
    }
    props.detectarCambiosTypeClients(e);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Tipo de cliente</InputLabel>
          {props.typeClientId ? (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Tipo de cliente "
              onChange={detectarCambiosTypeClients}
              value={props.typeClientId}
            >
              {typeClients.map((type_client) => (
                <MenuItem key={type_client._id} value={type_client._id}>
                  {type_client.name}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="tipo de cliente"
              onChange={detectarCambiosTypeClients}
            >
              {typeClients.map((type_client) => (
                <MenuItem key={type_client._id} value={type_client._id}>
                  {type_client.name}
                </MenuItem>
              ))}
            </Select>
          )}
          <FormHelperText>Compo Requerido*</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}
