import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Layout from "../../../components/layout/Layout";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AccountsReceivableContext from "../../../context/AccountsReceivable/AccountsReceivableContext";
import { useContext } from "react";
import Swal from "sweetalert2";
const AddPaymentToAccountReceivable = (props) => {
  const idClient = props.match.params.idClient;
  const idAccount = props.match.params.idAccount;
  const { AddPaymentToAccountReceivable } = useContext(
    AccountsReceivableContext
  );
  const [file_first, setFile] = useState({
    file: "",
  });

  const handleChangeFile = (e) => {
    setFile({
      ...file_first,
      file: e.target.files[0],
    });
  };
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  const reset = () => {
    setValue("amount", "", { shouldDirty: true });
    setValue("folio_trans", "", { shouldDirty: true });
    setFile("");
  };
  const onSubmit = (data, e) => {
    if (file_first.file === "") {
      Swal.fire({
        title: "Error",
        text: "Selecciona un comprobante, para poder continuar",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
      return;
    }
    data.voucher = file_first.file;
    data.client_id = idClient;
    data.account_id = idAccount;
    AddPaymentToAccountReceivable(data);
    props.history.goBack();
    reset();
  };
  return (
    <Layout>
      <Box sx={{ backgroundColor: "black", color: "white" }}>
        <Typography
          fontWeight="bold"
          fontFamily="monospace"
          fontSize="35px"
          sx={{ marginLeft: 2 }}>
          Agregar pago a cuenta
        </Typography>
      </Box>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.code === "Enter" || e.code === "NumpadEnter")
            e.preventDefault();
        }}
        autoComplete="off">
        <Grid container spacing={2} sx={{ padding: 2 }}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              fullWidth
              name="folio_trans"
              label="Folio transaccion"
              error={errors.folio_trans ? true : false}
              helperText={errors?.folio_trans?.message}
              {...register("folio_trans", {
                required: {
                  value: true,
                  message: "Este campo es requerido",
                },
              })}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              fullWidth
              name="amount"
              label="Monto"
              type="number"
              error={errors.amount ? true : false}
              helperText={errors?.amount?.message}
              {...register("amount", {
                required: {
                  value: true,
                  message: "Este campo es requerido",
                },
              })}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            display="flex"
            justifyContent="center">
            <Button
              variant="contained"
              component="label"
              startIcon={<AttachFileIcon />}
              onChange={handleChangeFile}
              sx={{
                color: "white",
                backgroundColor: "#ad1457",
                "&:hover": { backgroundColor: "#ad1457", color: "white" },
              }}>
              Adjuntar Comprobante
              <input hidden accept="application/pdf" multiple type="file" />
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            display="flex"
            justifyContent="center">
            <div>
              <Typography variant="overline" display="block" gutterBottom>
                Archivo Seleccionado: {""}
                {file_first.file !== ""
                  ? file_first?.file?.name
                  : " No se ha seleccionado ningun archivo"}
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            display="flex"
            justifyContent="end">
            <Button size="large" variant="contained" type="submit">
              Guardar
            </Button>
          </Grid>
        </Grid>
      </form>
    </Layout>
  );
};

export default AddPaymentToAccountReceivable;
