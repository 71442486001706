import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IconButton, Tooltip } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import invoicesContext from "../../../context/Invoices/InvoicesContext";
import { useContext } from "react";
import { PriceFormat } from "../../../utils/FormatPrice";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function TableSalesInvoiced({ orders }) {
  const { GetZipInvoice, GetPdfInvoice } = useContext(invoicesContext);
  return (
    <>
      {orders.length > 0 && (
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Folio Venta</StyledTableCell>
                  <StyledTableCell align="center">Cliente</StyledTableCell>
                  <StyledTableCell align="center">Total Venta</StyledTableCell>
                  <StyledTableCell align="center">
                    Gastos De envio
                  </StyledTableCell>
                  <StyledTableCell align="center">Opciones</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="center">
                      {row.folio}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.client_id.fullname}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      $ {PriceFormat(row.total)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      $ {PriceFormat(row.shippment)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Tooltip title="Descargar PDF" placement="top">
                        <IconButton
                          onClick={() => GetPdfInvoice(row.invoice_id)}>
                          <PictureAsPdfIcon sx={{ color: "red" }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip>
                        <IconButton
                          onClick={() => GetZipInvoice(row.invoice_id)}>
                          <FolderZipIcon sx={{ color: "#64b5f6" }} />
                        </IconButton>
                      </Tooltip>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}
