import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useContext } from "react";
import ProvidersContext from "../../../context/Provider/ProviderContext";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Swal } from "sweetalert2";
import { Grid, TextField, Typography } from "@mui/material";
import SelectState from "../../SelectOptions/SelectState";
import SelectMunicipality from "../../SelectOptions/SelectMunicipality";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useEffect } from "react";
export default function AddProviderModal({ open, handleClose }, props) {
  const [state, saveState] = useState(null);
  const [municipality, saveMunicipality] = useState(null);
  const detectarCambiosState = (e) => {
    saveState(e);
  };
  const detectarCambiosMunicipality = (e) => {
    saveMunicipality(e);
  };
  const [file_first, setFile] = useState({
    file: "",
  });
  const handleChangeFile = (e) => {
    setFile({
      ...file_first,
      file: e.target.files[0],
    });
  };
  const { AddProvider } = useContext(ProvidersContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const reset = () => {
    setValue("name", "", { shouldDirty: true });
    setValue("company", "", { shouldDirty: true });
    setValue("phone", "", { shouldDirty: true });
    setValue("rfc", "", { shouldDirty: true });
    setValue("credit_limit", "", { shouldDirty: true });
    setValue("direction", "", { shouldDirty: true });
    setFile("");
  };
  const onSubmit = (data, e) => {
    if (state === null) {
      Swal.fire({
        title: "Error",
        text: "selecciona un estado para continuar",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }
    if (municipality === null) {
      Swal.fire({
        title: "Error",
        text: "Selecciona un municipio para continuar",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }
    data.state = state;
    data.municipality = municipality;
    data.constancy = file_first.file;
    //console.log(data);
    AddProvider(data);
    handleClose();
    reset();
    // props.history.push("/Proveedores");
  };

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        {/* <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle> */}
        <DialogContent>
          <form
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter")
                e.preventDefault();
            }}
            autoComplete="off">
            <Grid container spacing={2} sx={{ padding: 2 }}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ backgroundColor: "black", color: "white" }}>
                <Typography fontWeight="bold" fontSize="30px">
                  Registrar nuevo Proveedor
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  label="Proveedor"
                  name="name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  error={errors.name ? true : false}
                  helperText={errors?.name?.message}
                  {...register("name", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  label="Compania"
                  name="company"
                  type="text"
                  fullWidth
                  variant="outlined"
                  error={errors.company ? true : false}
                  helperText={errors?.company?.message}
                  {...register("company", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  label="Telefono"
                  name="phone"
                  type="tel"
                  fullWidth
                  variant="outlined"
                  error={errors.phone ? true : false}
                  helperText={errors?.phone?.message}
                  {...register("phone", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  label="RFC"
                  name="rfc"
                  type="text"
                  fullWidth
                  variant="outlined"
                  error={errors.rfc ? true : false}
                  helperText={errors?.rfc?.message}
                  {...register("rfc", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  label="Direccion"
                  name="direction"
                  type="text"
                  fullWidth
                  variant="outlined"
                  error={errors.direction ? true : false}
                  helperText={errors?.direction?.message}
                  {...register("direction", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  label="Limite de credito"
                  name="credit_limit"
                  type="number"
                  fullWidth
                  variant="outlined"
                  error={errors.credit_limit ? true : false}
                  helperText={errors?.credit_limit?.message}
                  {...register("credit_limit", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <SelectState detectarCambiosState={detectarCambiosState} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <SelectMunicipality
                  detectarCambiosMunicipality={detectarCambiosMunicipality}
                  state_id={state}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                display="flex"
                justifyContent="center">
                <Button
                  variant="contained"
                  component="label"
                  startIcon={<AttachFileIcon />}
                  onChange={handleChangeFile}
                  sx={{
                    color: "white",
                    backgroundColor: "#ad1457",
                    "&:hover": { backgroundColor: "#ad1457", color: "white" },
                  }}>
                  Adjuntar Constancia de situacion fiscal
                  <input hidden accept="application/pdf" multiple type="file" />
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                display="flex"
                justifyContent="center">
                <Typography variant="overline" display="block" gutterBottom>
                  Archivo Seleccionado: {""}
                </Typography>
                <Typography variant="overline" display="block" gutterBottom>
                  {file_first.file !== ""
                    ? file_first?.file?.name
                    : " No se ha seleccionado ningun archivo"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Button variant="contained" fullWidth type="submit">
                  Guardar proveedor
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
