import { ADD_FAQS, CURRENT_FAQ, DELETE_FAQS, GET_ALL_FAQS, SHOW_ERRORS_API, UPDATE_FAQS } from "../../types";


export default function FaqsReducer(state, action) {
    switch (action.type) {
        case GET_ALL_FAQS:
            return {
                ...state,
                faqs: action.payload,
                success: false,
                ErrorsAPI: []
            }

        case ADD_FAQS:
            return {
                ...state,
                success: true,
                ErrorsAPI: []
            }
        case SHOW_ERRORS_API:
            return {
                ...state,
                success: true,
                ErrorsAPI: []
            }

        case CURRENT_FAQ:
            return {
                ...state,
                faq: action.payload,
            }
        case UPDATE_FAQS:
            return {
                ...state,
                success: true,
                ErrorsAPI: []
            }

        case DELETE_FAQS:
            return {
                ...state,
                success: true,
                faqs: state.faqs.filter(faq => faq.id !== action.payload)
            }

        default:
            return state;
    }
}