import React, { useReducer } from "react";
import Swal from "sweetalert2";
import MethodGet, { MethodPut } from "../../config/Service";
import {
  CURRENT_LOGO,
  GET_LOGO,
  SHOW_ERRORS_API,
  UPDATE_LOGO,
} from "../../types";
import LogoReducer from "./LogoReducer";
import LogoContext from "./LogoContext";
import headerConfig from "../../config/imageHeaders";

const LogoState = ({ children }) => {
  //initial state

  const initialState = {
    logos: [],
    logo: null,
    ErrorsApi: [],
    success: false,
  };

  const [state, dispatch] = useReducer(LogoReducer, initialState);

  //obtener la lista de los logos
  const GetLogo = () => {
    sessionStorage.removeItem("logo");
    let url = `/administrable/logo`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_LOGO,
          payload: res.data.logo,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
      });
  };

  //funcion para editar el logo
  const UpdateLogo = (data) => {
    const formData = new FormData();
    formData.append("image", data.image);
    formData.append("facebook", data.facebook);
    formData.append("instagram", data.instagram);
    formData.append("tiktok", data.tiktok);
    formData.append("top_text", data.top_text);
    let url = `/administrable/logo`;
    MethodPut(url, formData, { headerConfig })
      .then((res) => {
        Swal.fire({
          title: "Informacion Actualizada",
          text: res.data.message,
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
          icon: "success",
        });
        dispatch({
          type: UPDATE_LOGO,
        });
      })
      .catch((error) => {
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.res.data.error,
        });
      });
  };

  //funcion para seleccionar un logo
  const SaveCurrentLogo = (logo) => {
    sessionStorage.setItem("logo", JSON.stringify(logo));
    dispatch({
      type: CURRENT_LOGO,
      payload: logo,
    });
  };

  return (
    <LogoContext.Provider
      value={{
        logos: state.logos,
        logo: state.logo,
        ErrorsApi: state.ErrorsApi,
        success: state.success,
        GetLogo,
        UpdateLogo,
        SaveCurrentLogo,
      }}>
      {children}
    </LogoContext.Provider>
  );
};

export default LogoState;
