import React, { useContext, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import TagsContext from "../../context/Tags/TagsContext";
import TagCard from "../../components/Cards/tags/TagCard";
import { styled, alpha } from "@mui/material/styles";
import AddModal from "./Add";
import NoDataComponents from "../../components/loading/NoDataComponents";

import { makeStyles } from "@mui/styles";
import {
  Grid,
  Box,
  InputBase,
  Button,
  Stack,
  Pagination,
} from "@mui/material/";
import ModulesContext from "../../context/Permissions/PermissionsContext";
import SearchIcon from "@mui/icons-material/Search";
import NoResultsComponent from "../../components/loading/NoResultsComponent";
import Swal from "sweetalert2";
import { Paginate } from "../../components/Pagination/Paginate";

const useStyles = makeStyles({
  titleCard: {
    color: "white",
    fontSize: "35px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 2,
    marginRight: 5,
  },
});

//Estilos busqueda
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
  },
}));

export default function Tags() {
  const classes = useStyles();
  //Abrir modal
  const [modalAdd, AbrirModalAdd] = useState(false);
  const handleClickOpenAdd = () => {
    AbrirModalAdd(true);
  };
  const handleClickCloseAdd = () => {
    AbrirModalAdd(false);
  };

  const { tags, GetTags, success, SaveCurrentTag, totalPages } =
    useContext(TagsContext);
  const { permissionsPerUser, GetModulesPerUser } = useContext(ModulesContext);

  const [wordMagic, setWordMagic] = useState("");
  const [tagsFilter, setTagsFilter] = useState();

  const SelectTag = (tag) => {
    SaveCurrentTag(tag);
  };
  //paginacion
  var [pages, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };
  const limite = rowsPerPage;
  const params = { limite, pages };
  const handleSearchTag = ({ target }) => {
    setWordMagic(target.value);
    const word = target.value;
    try {
      let resulttagfilter = tags.filter((tag) =>
        tag.name.toString().toLowerCase().includes(word.toLowerCase())
      );
      setTagsFilter(resulttagfilter);
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error.response.data.message,
        icon: "error",
      });
    }
  };

  useEffect(() => {
    if (localStorage.getItem("module_id")) {
      const permiss = localStorage.getItem("module_id");
      GetModulesPerUser(permiss);
    }
    GetTags(params);
    setTagsFilter(tags);
  }, [success, rowsPerPage, pages]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      <Grid container spacing={2} sx={{ backgroundColor: "white", padding: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#000000",
              textAling: "center",
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
              // borderRadius: "0 0 24px 0",
            }}
          >
            <div className={classes.titleCard}>Etiquetas(SEO)</div>
            {permissionsPerUser.write === true && (
              <div className={classes.fab}>
                <Button
                  onClick={handleClickOpenAdd}
                  sx={{
                    color: "black",
                    backgroundColor: "#26c6da",
                    hoverColor: "white",
                    fontFamily: "monospace",
                    fontWeight: "bolder",
                    fontSize: "15px",
                    textTransform: "none",
                    "&:hover": {
                      color: "black",
                      backgroundColor: "#67cedb",
                      hoverColor: "white",
                      fontFamily: "monospace",
                      fontWeight: "bolder",
                      fontSize: "15px",
                    },
                  }}
                >
                  Agregar
                </Button>
              </div>
            )}
          </Box>
          <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={2}>
                  {!tags.length ? null : (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Search>
                        <SearchIconWrapper>
                          <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                          fullWidth
                          placeholder="Buscar etiqueta por el nombre"
                          inputProps={{ "aria-label": "search" }}
                          onChange={handleSearchTag}
                        />
                      </Search>
                    </Grid>
                  )}

                  {!wordMagic &&
                    (!tags.length ? (
                      <NoDataComponents />
                    ) : (
                      tags.map((tag) => (
                        <TagCard
                          permiss={permissionsPerUser}
                          tag={tag}
                          key={tag._id}
                        />
                      ))
                    ))}

                  {wordMagic &&
                    (!tagsFilter.length ? (
                      <NoResultsComponent />
                    ) : (
                      tagsFilter.map((tag) => (
                        <TagCard
                          permiss={permissionsPerUser}
                          tag={tag}
                          key={tag._id}
                        />
                      ))
                    ))}
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              display="flex"
              justifyContent="center"
            >
              <Paginate
                totalPages={totalPages}
                pages={pages}
                handleChangePage={handleChangePage}
              />
            </Grid>
            {/* <AddExitProducts open={open} handleClose={handleClose} /> */}
          </Box>
        </Grid>
      </Grid>
      <AddModal
        modal={modalAdd}
        handleClickOpen={handleClickOpenAdd}
        handleClose={handleClickCloseAdd}
      />
    </Layout>
  );
}
