import React from "react";
import {
  ADD_CATEGORIES,
  DELETE_CATEGORIES,
  GET_ALL_CATEGORIES,
  IMPORT_CATEGORIES_EXCEL,
  SEARCH_CATEGORY,
  SHOW_ERRORS_API,
  UPDATE_CATEGORIES,
  GET_CATEGORY,
} from "../../types";

export default function CategoriesReducer(state, action) {
  switch (action.type) {
    case GET_ALL_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
        success: false,
        ErrorsAPI: [],
      };
    case ADD_CATEGORIES:
      return {
        ...state,
        // success: true,
        // ErrorsAPI: [],s
        categories: [action.payload, ...state.categories],
      };
    case IMPORT_CATEGORIES_EXCEL:
      return {
        ...state,
        ErrorsApi: [],
      };
    case SHOW_ERRORS_API:
      return {
        ...state,
        ErrorsAPI: [],
      };
    case UPDATE_CATEGORIES:
      return {
        ...state,
        ErrorsAPI: [],
        categories: state.categories.map((category) => {
          if (category._id === action.payload._id) {
            category = action.payload;
          }
          return category;
        }),
      };
    case DELETE_CATEGORIES:
      return {
        ...state,
        categories: state.categories.filter(
          (category) => category._id !== action.payload
        ),
      };
    case GET_CATEGORY:
      return {
        ...state,
        category: state.categories.find(
          (category) => category._id === action.payload
        ),
      };
    case SEARCH_CATEGORY:
      return {
        ...state,
        search_categories: action.payload,
        ErrorsAPI: [],
      };
    default:
      return state;
  }
}
