import { ADD_COUPON, COUPONS_EXPIRED, CURRENT_COUPON, DELETE_COUPON, GET_ALL_COUPONS, SHOW_ERRORS_API, UPDATE_COUPON } from '../../types';

export default function CouponsReducer(state, action) {
    switch (action.type) {
        case GET_ALL_COUPONS:
            sessionStorage.removeItem('coupon');
            //funciones que obtienen la fecha actual y valida que si el cupon de descuento esta activo
            const currentdate = new Date();
            const date = currentdate.getFullYear() + "-"
                + ((currentdate.getMonth() + 1) < 10 ? "0" + (currentdate.getMonth() + 1) : currentdate.getDate()) + "-"
                + (currentdate.getDate() < 10 ? "0" + currentdate.getDate() : currentdate.getDate());

            return {
                ...state,
                coupons: action.payload,
                success: false,
                ErrorsApi: [],
                date: date,
            }
        case COUPONS_EXPIRED:

            return {
                ...state,
                coupons: action.payload,
                success: false,
                ErrorsApi: [],
            }

        case ADD_COUPON:
            return {
                ...state,
                success: true,
                ErrorsApi: []
            }
        case SHOW_ERRORS_API:
            return {
                ...state,
                success: true,
                ErrorsApi: []
            }

        case CURRENT_COUPON:
            return {
                ...state,
                coupon: action.payload
            }
        case UPDATE_COUPON:
            sessionStorage.removeItem('coupon');
            return {
                ...state,
                success: true,
                ErrorsApi: []
            }
        case DELETE_COUPON:
            return {
                ...state,
                success: true,
                coupons: state.coupons.filter(coupon => coupon.id !== action.payload)
            }

        default:
            return state;
    }
}
