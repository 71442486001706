import React from "react";
import { FormHelperText, Grid, MenuItem, Select } from "@mui/material";
import { FormControl } from "@mui/material";
import { InputLabel } from "@mui/material";

const TypeInvoice = (props) => {
  const MethodsPaymentsInvoice = [
    {
      value: "PUE",
      name: "Pago en una sola exibicion",
      description: "Pago en una sola exhibición (de contado)",
    },
    {
      value: "PPD",
      name: "Pago en parcialidades o diferido",
      description:
        "Pago en parcialidades o diferido (total o parcialmente a crédito). Requiere expedir un comprobante de pago cuando se reciba un pago subsecuente.",
    },
  ];

  const detectarCambiosPaymentsInvoice = (e) => {
    props.detectarCambiosPaymentsInvoice(e.target.value);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            Selecciona método de pago
          </InputLabel>
          {props.typeInvoice ? (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Selecciona método de pago"
              onChange={detectarCambiosPaymentsInvoice}
              defaultValue={props.typeInvoice}>
              {MethodsPaymentsInvoice.map((invoice) => (
                <MenuItem key={invoice.value} value={invoice.value}>
                  {invoice.value}-{invoice.name}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label=""
              onChange={detectarCambiosPaymentsInvoice}>
              {MethodsPaymentsInvoice.map((invoice) => (
                <MenuItem key={invoice.value} value={invoice.value}>
                  {invoice.value} - {invoice.name}
                </MenuItem>
              ))}
            </Select>
          )}
          <FormHelperText>Campo Requerido*</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default TypeInvoice;
