import { Grid } from "@mui/material";
import React from "react";
import TableInvoiceCancel from "./TableInvoiceCancel";

const InvoicesCanceled = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TableInvoiceCancel />
      </Grid>
    </Grid>
  );
};

export default InvoicesCanceled;
