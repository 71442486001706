import { Button, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Layout from "../../../components/layout/Layout";
import MethodGet from "../../../config/Service";
import TypeInvoice from "../../SelectOptions/TypeInvoice";
import SpinnerComponent from "./../../../components/loading/SpinnerComponent";
import AccountsForPaymentContext from "./../../../context/AccountsForPayment/AccountsForPaymentContext";

const AddInvoiceProvider = (props) => {
  const id = props.match.params.id;
  const { AddAccountsPayment } = useContext(AccountsForPaymentContext);
  const [provider, saveProvider] = useState();
  useEffect(() => {
    let url = `/providers/${id}`;
    MethodGet(url)
      .then((res) => {
        saveProvider(res.data.provider);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  const [typeInvoice, saveTypeInvoice] = useState();
  const detectarCambiosPaymentsInvoice = (e) => {
    saveTypeInvoice(e);
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const reset = () => {
    setValue("folio_invoice", "", { shouldDirty: true });
    setValue("amount", "", { shouldDirty: true });
    saveTypeInvoice("");
  };

  const onSubmit = (data, e) => {
    data.provider_id = id;
    data.typeInvoice = typeInvoice;
    AddAccountsPayment(data);
    reset();
    props.history.goBack();
  };

  return (
    <Layout>
      {!provider ? (
        <SpinnerComponent />
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter")
              e.preventDefault();
          }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ backgroundColor: "black", color: "white" }}>
              <Typography
                fontWeight="bold"
                fontSize="35px"
                sx={{ marginLeft: 2 }}>
                Agregar Factura a proveedor
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ margin: 2 }}>
              <Typography fontWeight="bold">
                Proveedor: {provider.name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ margin: 2 }}>
              <Typography fontWeight="bold">
                Compania: {provider.company}
              </Typography>
            </Grid>
            <Grid container spacing={2} sx={{ margin: 2 }}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <label>Folio de factura</label>
                <TextField
                  //label="Folio de factura"
                  fullWidth
                  name="folio_invoice"
                  type="text"
                  error={errors.folio_invoice ? true : false}
                  helperText={errors?.folio_invoice?.message}
                  {...register("folio_invoice", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <label>Tipo de factura: </label>
                <TypeInvoice
                  detectarCambiosPaymentsInvoice={
                    detectarCambiosPaymentsInvoice
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <label>Monto: </label>
                <TextField
                  //label="Folio de factura"
                  fullWidth
                  name="amount"
                  type="number"
                  error={errors.amount ? true : false}
                  helperText={errors?.amount?.message}
                  {...register("amount", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                  })}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                display="flex"
                justifyContent="end">
                <Button variant="contained" size="large" type="submit">
                  Guardar factura
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </Layout>
  );
};

export default AddInvoiceProvider;
