import React from "react";
import {
	ADD_DIVISAS,
	CURRENT_DIVISAS,
	DELETE_DIVISAS,
	SHOW_ERRORS_API,
	UPDATE_DIVISAS,
	GET_ALL_DIVISAS,
} from "../../types";

export default function DivisasReducer(state, action) {
	switch (action.type) {
		case GET_ALL_DIVISAS:
			return {
				...state,
				divisas: action.payload,
				success: false,
				ErrorsAPI: [],
			};
		case ADD_DIVISAS:
			return {
				...state,
				success: true,
				ErrorsAPI: [],
			};

		case SHOW_ERRORS_API:
			return {
				...state,
				success: true,
				ErrorsAPI: [],
			};
		case CURRENT_DIVISAS:
			return {
				...state,
				divisa: action.payload,
			};

		case UPDATE_DIVISAS:
			return {
				...state,
				success: true,
				ErrorsAPI: [],
			};
		case DELETE_DIVISAS:
			return {
				...state,
				success: true,
				divisas: state.divisas.filter((divisa) => divisa.id !== action.payload),
			};
		default:
			return state;
	}
}
