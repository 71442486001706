import React, { useContext, useState } from "react";
import {
	Box,
	Tooltip,
	Menu,
	MenuItem,
	IconButton,
	Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ModalImage from "react-modal-image-responsive";
import OffersContext from "../../../context/Offers/OffersContext";
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles({
	logoImageWeb: {},
});

export default function OffersCard({ offer, permiss }) {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const [id_offer, setIdOffer] = useState({});
	const { DeleteOffers, UpdateOffers, SaveCurrentOffer } =
		useContext(OffersContext);

	const selectOffer = (offer) => {
		SaveCurrentOffer(offer);
	};

	return (
		<Box sx={{ boxShadow: 3 }}>
			<Box sx={{ margin: 1, fontWeight: "bold" }}>
				<Box
					sx={{
						display: "flex",
						alignItems: "baseline",
						justifyContent: "space-between",
					}}
				>
					<div>
						<Marquee play={false} gradient={false} speed={45}>
							{offer.text}
						</Marquee>
					</div>
					<div>
						{permiss.delete === true && (
							<div>
								<Tooltip arrow title="Operaciones" placement="top">
									<IconButton
										aria-label="more"
										id="long-button"
										aria-controls={open ? "long-menu" : undefined}
										aria-expanded={open ? "true" : undefined}
										aria-haspopup="true"
										onClick={handleClick}
										sx={{ color: "black" }}
									>
										<MoreVertIcon />
									</IconButton>
								</Tooltip>
								<Menu
									id="long-menu"
									MenuListProps={{
										"aria-labelledby": "long-button",
									}}
									anchorEl={anchorEl}
									open={open}
									onClose={handleClose}
								>
									 {permiss.update === true && (
									<MenuItem
										onClick={() => {
											setAnchorEl(null);
											selectOffer(offer._id);
										}}
									>
										<Link to="/actualizar-oferta">Editar</Link>
									</MenuItem>
									 )}
									  {permiss.delete === true && (
									<MenuItem
										onClick={() => {
											setAnchorEl(null);
											DeleteOffers(offer._id);
										}}
									>
										Eliminar
									</MenuItem>
									  )}
								</Menu>
							</div>
						)}
					</div>
				</Box>
			</Box>
			<ModalImage
				className={classes.logoImageWeb}
				small={offer.imageWeb}
				large={offer.imageWeb}
				alt="imagen Web"
			/>
		</Box>
	);
}
