import React, { useContext, useEffect } from "react";
import ShippmentsContext from "../../context/Shippments/ShippmentsContext";

import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

export default function SelectShippments(props) {
  const { shippments, GetShippments } = useContext(ShippmentsContext);

  useEffect(() => {
    GetShippments();
  }, []);

  const detectarCambiosShippment = (e) => {
    if (e.target.value) {
    }
    props.detectarCambiosShippment(e);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Paqueterias</InputLabel>
          {props.ShippmentId ? (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Paqueterias "
              onChange={detectarCambiosShippment}
              value={props.ShippmentId}
            >
              {shippments.map((shippment) => (
                <MenuItem value={shippment._id}>{shippment.name}</MenuItem>
              ))}
            </Select>
          ) : (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Paqueterias "
              onChange={detectarCambiosShippment}
            >
              {shippments.map((shippment) => (
                <MenuItem value={shippment._id}>{shippment.name}</MenuItem>
              ))}
            </Select>
          )}
          <FormHelperText>Campo Requerido*</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}
