import React, {  useContext, useEffect } from "react";
import FaqCategoriesContext from "../../context/FaqCategories/FaqCategoriesContext";
import { FormControl, Grid, InputLabel, MenuItem, Select, FormHelperText } from "@mui/material";

export default function SelectCategoriesFaqs(props) {
  const GetFaqCategoriesList = useContext(FaqCategoriesContext);
  const { faq_categories, GetFaqs, success } = GetFaqCategoriesList;

  useEffect(() => {
    GetFaqs();
  }, [success]);

  const detectarCambiosFaqCategory = (e) => {
    props.detectarCambiosFaqCategory(e);
  };

  return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Categoria pregunta frecuente
            </InputLabel>
            {props.FaqCategoryId ? (
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Categoria pregunta frecuente "
                onChange={detectarCambiosFaqCategory}
                value={props.FaqCategoryId}
              >
                {faq_categories.map((faq_categories) => (
                  <MenuItem key={faq_categories._id} value={faq_categories._id}>
                    {faq_categories.name}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Categoria pregunta frecuente "
                onChange={detectarCambiosFaqCategory}
              >
                {faq_categories.map((faq_categories) => (
                  <MenuItem key={faq_categories._id} value={faq_categories._id}>
                    {faq_categories.name}
                  </MenuItem>
                ))}
              </Select>
            )}
            <FormHelperText>Campo requerido*</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
 
  );
}
