import { Grid, InputAdornment, TextField } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import CropFreeIcon from "@mui/icons-material/CropFree";
import { useDebounce } from "use-debounce";
import ProductsContext from "../../context/Products/ProductsContext";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import InventoriesContext from "../../context/Inventories/InventoriesContext";
export default function ComponentsBarcode({
  guardarProductoID,
  id_inventory,
  guardarInventario,
  inventario,
  status_report,
  cantidad,
  guardarCantidad,
  index,
  inventory,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [barcode, saveBarcode] = useState("");
  const [debouncedBarcode] = useDebounce(barcode, 500);
  const [debouncedCantidad] = useDebounce(cantidad, 500);
  const { products, productsSelect, success } = useContext(ProductsContext);
  const { AddProductInventory } = useContext(InventoriesContext);
  useEffect(() => {
    productsSelect();
  }, [success]);
  //cambios del codigo de barras
  const handleChangeBarcode = (e) => {
    saveBarcode(e.target.value);
  };

  useEffect(() => {
    filterProductExist();
  }, [debouncedBarcode]);

  const filterProductExist = () => {
    if (debouncedBarcode != "") {
      var product = [];
      product = products.filter((p) => p.barcode === debouncedBarcode);
      if (!product.length) {
        Swal.fire({
          title: "¡Lo sentimos!",
          text: "El codigo de barras, no coincide con ninguno de los productos registrados",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
        saveBarcode("");
        return;
      }
      product[0].value = product[0]._id;
      guardarProductoID(product[0]);

      var data = {};
      data.inventory_id = inventory._id;
      data.product_id = product[0]._id;
      data.stock_disponible = product[0].quantity;
      AddProductInventory(data);
      saveBarcode("");
    }
  };

  return (
    <TextField
      placeholder="Usa el lector de codigos de barra"
      variant="outlined"
      required
      fullWidth
      value={barcode}
      id="barcode"
      label="Código De Barras"
      name="barcode"
      autoComplete="barcode"
      autoFocus
      error={errors.barcode ? true : false}
      helperText={errors?.barcode?.message}
      {...register("barcode", {
        required: {
          value: true,
          message: "Este campo es requerido",
        },
        minLength: {
          value: 2,
          message: "Minimo 2 caracteres",
        },
        maxLength: {
          value: 255,
          message: "Maximo 255 caracteres",
        },
        onChange: (e) => {
          handleChangeBarcode(e);
        },
      })}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <CropFreeIcon />
          </InputAdornment>
        ),
      }}
    />
  );
}
