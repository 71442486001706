import {
  ADD_EXIT_PRODUCTS_REPORTS,
  GET_ALL_DEPARTURES_NO_PAGINATE,
  GET_ALL_EXIT_PRODUCTS_REPORTS,
  SHOW_ERRORS_API,
} from "../../types";

export default function ExitProductsReducer(state, action) {
  switch (action.type) {
    case GET_ALL_EXIT_PRODUCTS_REPORTS:
      return {
        ...state,
        departures: action.payload.departures,
        totalDocs: action.payload.totalDocs,
        limit: action.payload.limit,
        page: action.payload.page,
        paginCounter: action.payload.paginCounter,
        hasPrevPage: action.payload.hasPrevPage,
        hasNextPage: action.payload.hasNextPage,
        prevPage: action.payload.prevPage,
        nextPage: action.payload.nextPage,
        totalPages: action.payload.totalPages,
        success: false,
        ErrorsAPI: [],
      };
    case GET_ALL_DEPARTURES_NO_PAGINATE:
      return {
        ...state,
        allDepartures: action.payload,
        success: false,
        ErrorsAPI: [],
      };
    case ADD_EXIT_PRODUCTS_REPORTS:
      return {
        ...state,
        departures: [action.payload, ...state.departures],
        success: true,
        ErrorsAPI: [],
      };
    case SHOW_ERRORS_API:
      return {
        ...state,
        success: true,
        ErrorsAPI: [],
      };
    default:
      return state;
  }
}
