import React, { useReducer } from "react";
import Swal from "sweetalert2";
import MethodGet, {
  MethodDelete,
  MethodPost,
  MethodPut,
} from "../../config/Service";
import {
  ADD_DIVISAS,
  CURRENT_DIVISAS,
  DELETE_DIVISAS,
  GET_ALL_DIVISAS,
  SHOW_ERRORS_API,
  UPDATE_DIVISAS,
} from "../../types";
import DivisasReducer from "./DivisasReducer";
import DivisasContext from "./DivisasContext";
import headerConfig from "../../config/imageHeaders";

const DivisasState = ({ children }) => {
  //estado inciial
  const initialState = {
    divisas: [],
    divisa: null,
    ErrorsApi: [],
    success: false,
  };

  const [state, dispatch] = useReducer(DivisasReducer, initialState);

  //obtener la lista de bancos
  const GetDivisas = () => {
    sessionStorage.removeItem("divisa");
    let url = "/currencies";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_DIVISAS,
          payload: res.data.currencies,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.message,
        });
      });
  };

  //registrar vbanco
  const AddDivisas = (divisa) => {
    const formData = new FormData();
    formData.append("currency", divisa.currency);
    formData.append("image", divisa.image);

    let url = "/currencies/";
    MethodPost(url, formData, { headerConfig })
      .then((res) => {
        Swal.fire({
          title: "Divisa Registrada",
          text: res.data.message,
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        dispatch({
          type: ADD_DIVISAS,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          timer: 2000,
          showConfirmButton: false,
          icon: "error",
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.error,
        });
      });
  };

  //funcion para editar banco
  const UpdateDivisa = (divisa) => {
    const formData = new FormData();
    formData.append("currency", divisa.currency);
    formData.append("image", divisa.image);

    let url = `/currencies/${divisa.id}`;
    MethodPut(url, formData, { headerConfig })
      .then((res) => {
        Swal.fire({
          title: "Divisa Actualizado",
          text: res.data.message,
          timer: 1000,
          icon: "success",
          showConfirmButton: false,
        });
        dispatch({
          type: UPDATE_DIVISAS,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.error,
        });
      });
  };

  //eliminar banck
  const DeleteDivisas = (id) => {
    Swal.fire({
      title: "¿Estas Seguro?",
      text: "La divisa seleccionada será eliminada",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/currencies/${id}`;

        MethodDelete(url)
          .then((res) => {
            Swal.fire({
              title: "Eliminado",
              text: res.data.message,
              icon: "success",
              timer: 2000,
              showConfirmButton: false,
            });
            dispatch({
              type: DELETE_DIVISAS,
              payload: id,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              timer: 2500,
              showConfirmButton: false,
            });
          });
      }
    });
  };

  //funcion para seleciionar ba cos
  const SaveCurrentDivisa = (divisa) => {
    sessionStorage.setItem("divisa", JSON.stringify(divisa));
    dispatch({
      type: CURRENT_DIVISAS,
      payload: divisa,
    });
  };

  return (
    <DivisasContext.Provider
      value={{
        divisas: state.divisas,
        divisa: state.divisa,
        ErrorsApi: state.ErrorsApi,
        success: state.success,
        GetDivisas,
        AddDivisas,
        UpdateDivisa,
        DeleteDivisas,
        SaveCurrentDivisa,
      }}>
      {children}
    </DivisasContext.Provider>
  );
};

export default DivisasState;
