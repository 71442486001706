import React, { useContext, useEffect, useState } from "react";
import SubcategoriesContext from "../../../context/SubCategories/SubcategoriesContext";
import ModalEdit from "../../../containers/categoriesps/ModalEdit";
import Marquee from "react-fast-marquee";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Card,
  Typography,
  Tooltip,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ProductSubcategory from "../../../containers/categoriesps/Products";
import { EyeIcon } from "../../icons/EyeIcon";
import { EditIcon } from "../../icons/EditIcon";
import { DeleteIcon } from "../../icons/DeleteIcon";

const useStyles = makeStyles({
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 2,
  },
});
export default function SubCategoriesCard({ subcategorie, permiss }) {
  const classes = useStyles();
  const [modalUpdate, AbrirModalUpdate] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openProducts, setOpenProducts] = useState(false);
  const [id_subcategoryOb, setIdSubcategoryOb] = useState();
  const handleClickOpen = () => {
    setOpenProducts(true);
  };
  const handleCloseProducts = () => {
    setOpenProducts(false);
  };
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //   //Menu

  const handleClickOpenUpdate = () => {
    AbrirModalUpdate(true);
  };
  const handleClickCloseUpdate = () => {
    AbrirModalUpdate(false);
  };

  const { subcategories, GetSubcategories, success, DeleteSubcategory } =
    useContext(SubcategoriesContext);

  const SelectSubcategory = (subcategory) => {
    // setIdSubcategory(subcategory);
    handleClickOpenUpdate();
  };
  const SelectSubcategoryDetail = (id) => {
    setIdSubcategoryOb(id);
    handleClickOpen();
  };

  return (
    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
      <Card>
        <Box
          fullWidth
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            paddingX: 2,
            backgroundColor: "white",
            color: "black",
          }}
        >
          <Typography
            variant="su arrowbtitle2"
            noWrap
            sx={{ fontWeight: "bold", fontSize: "17px" }}
            className="blink-1"
          >
            <Marquee play={false} gradient={false} speed={45}>
              {subcategorie.name}
            </Marquee>
          </Typography>
        </Box>
        <Box
          fullWidth
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            paddingX: 2,
            backgroundColor: "white",
            color: "black",
            marginBottom: 1,
          }}
        >
          {/* <Marquee play={false} gradient={false} speed={45}> */}
          <div className={classes.categorytext}>
            {" "}
            <ArrowCircleRightIcon /> {subcategorie.category.name}
          </div>
          {/* </Marquee> */}
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={4} display="flex" justifyContent="center">
            <Tooltip title="Ver productos" placement="top">
              <Button
                onClick={() => {
                  setAnchorEl(null);
                  SelectSubcategoryDetail(subcategorie._id);
                }}
              >
                <EyeIcon />
              </Button>
            </Tooltip>
          </Grid>
          {permiss.update === true && (
          <Grid item xs={4} display="flex" justifyContent="center">
            <Tooltip title="Editar" placement="top">
              <Button
                onClick={() => {
                  SelectSubcategory(subcategorie._id);
                }}
              >
                <EditIcon />
              </Button>
            </Tooltip>
          </Grid>
          )}
           {permiss.delete === true && (
          <Grid item xs={4} display="flex" justifyContent="center">
            <Tooltip title="Eliminar" placement="top">
              <Button
                onClick={() => {
                  DeleteSubcategory(subcategorie._id);
                }}
              >
                <DeleteIcon />
              </Button>
            </Tooltip>
          </Grid>
           )}
        </Grid>
      </Card>
      {id_subcategoryOb && (
        <ProductSubcategory
          open={openProducts}
          close={handleCloseProducts}
          id_subcategory={subcategorie._id}
          title={subcategorie.name}
        />
      )}

      <ModalEdit
        modalUpdate={modalUpdate}
        id={subcategorie._id}
        handleClickOpen={handleClickOpenUpdate}
        handleCloseEdit={handleClickCloseUpdate}
      />
    </Grid>
  );
}
