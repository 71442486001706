import React, { useContext, useState } from "react";
import {
  Box,
  Card,
  Typography,
  Tooltip,
  Grid,
  IconButton,
} from "@mui/material";

import TypeUsersContext from "../../../context/TypeUsers/TypeUsersContext";
import ModalEdit from "../../../containers/type_users/Edit";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
export default function TypeUsersCard({ typeuser }) {
  //Menu
  const [modalUpdate, AbrirModalUpdate] = useState(false);
  const { modules } = typeuser;
  const [id_typeusers, setIdTypeusers] = useState(null);
  const handleClickOpenUpdate = () => {
    AbrirModalUpdate(true);
  };
  const handleClickCloseUpdate = () => {
    sessionStorage.removeItem("typeuser");
    AbrirModalUpdate(false);
  };

  const { DeleteTypeUsers, SaveCurrentTypeUser } = useContext(TypeUsersContext);

  const SelectTypeUser = (typeuser) => {
    setIdTypeusers(typeuser);
    handleClickOpenUpdate();
  };

  const SelectTypeAggregatePermissions = (typeuser_id) => {
    SaveCurrentTypeUser(typeuser_id);
  };
  console.log(id_typeusers, "el id");
  return (
    <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
      <Card>
        <Box
          fullWidth
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            paddingX: 2,
            backgroundColor: "white",
            color: "black",
          }}>
          <Typography
            variant="su arrowbtitle2"
            noWrap
            sx={{ fontWeight: "bold", fontSize: "17px" }}
            className="blink-1">
            {typeuser.name}
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            display="flex"
            justifyContent="space-between">
            <Tooltip title="Editar" placement="top">
              <IconButton
                onClick={() => {
                  SelectTypeUser(typeuser._id);
                }}>
                <CreateIcon className="button_primary" />
              </IconButton>
            </Tooltip>
            {typeuser.modules.length > 0 && (
              <Tooltip title="Cambiar permisos" placement="top">
                <Link to="/ActualizarPermisos">
                  <IconButton
                    onClick={() => {
                      SelectTypeAggregatePermissions(typeuser._id);
                    }}>
                    <ManageAccountsIcon className="button_warning" />
                  </IconButton>
                </Link>
              </Tooltip>
            )}
            {!modules.length && (
              <Tooltip title="Asignar permisos" placement="top">
                <Link to="/AgregarPermisos">
                  <IconButton
                    onClick={() => {
                      SelectTypeAggregatePermissions(typeuser._id);
                    }}>
                    <ManageAccountsIcon className="button_success" />
                  </IconButton>
                </Link>
              </Tooltip>
            )}
            <Tooltip title="Eliminar" placement="top">
              <IconButton
                onClick={() => {
                  DeleteTypeUsers(typeuser._id);
                }}>
                <DeleteIcon className="button_danger" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Card>
      {id_typeusers !== null && (
        <ModalEdit
          modalUpdate={modalUpdate}
          id={id_typeusers}
          handleClickOpen={handleClickOpenUpdate}
          handleCloseEdit={handleClickCloseUpdate}
        />
      )}
    </Grid>
  );
}
