import {
  GET_ALL_CLIENTS,
  GET_CLIENTS,
  REGISTER_CLIENT,
  SEARCH_CLIENTS,
  CREATE_CLIENT_ACCOUNT,
  GET_CLIENTS_ACCOUNTS,
} from "../../types";

export default function ClientsReducer(state, action) {
  switch (action.type) {
    case GET_ALL_CLIENTS:
      return {
        ...state,
        clients: action.payload.clients,
        totalDocs: action.payload.totalDocs,
        limit: action.payload.limit,
        page: action.payload.page,
        paginCounter: action.payload.paginCounter,
        totalPages: action.payload.totalPages,
        hasPrevPage: action.payload.hasPrevPage,
        hasNextPage: action.payload.hasNextPage,
        prevPage: action.payload.prevPage,
        nextPage: action.payload.nextPage,
        success: false,
        ErrorsAPI: [],
      };
    case SEARCH_CLIENTS:
      return {
        ...state,
        search_clients: action.payload,
        success: false,
        ErrorsAPI: [],
      };
    case GET_CLIENTS:
      return {
        ...state,
        clients: action.payload,
        success: false,
        ErrorsAPI: [],
      };
    case REGISTER_CLIENT:
      return {
        ...state,
        clients: [action.payload, ...state.clients],
        success: true,
        ErrorsAPI: [],
      };
    case CREATE_CLIENT_ACCOUNT:
      return {
        ...state,
        clientsAccounts: [action.payload, ...state.clientsAccounts],
        success: true,
        ErrorsAPI: [],
      };
    case GET_CLIENTS_ACCOUNTS:
      return {
        ...state,
        clientsAccounts: action.payload,
        success: false,
        ErrorsAPI: [],
      };
    default:
      return state;
  }
}
