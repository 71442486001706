import { Box, Grid, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import TableSalesInvoiced from "./TableSalesInvoiced";
import invoicesContext from "../../../context/Invoices/InvoicesContext";
import NoDataComponents from "../../../components/loading/NoDataComponents";
import { useDebounce } from "use-debounce";
import Swal from "sweetalert2";
import SearchingComponent from "../../../components/loading/SearchingComponent";
import NoResultsComponent from "../../../components/loading/NoResultsComponent";
import { Paginate } from "../../../components/Pagination/Paginate";
const SalesInvoiced = () => {
  //paginacion
  var [pages, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const limite = rowsPerPage;
  const params = { limite, pages };
  const {
    orders,
    GetSalesInvoiced,
    totalPages,
    ordersInvoicedNp,
    GetSalesInvoicedNP,
  } = useContext(invoicesContext);
  useEffect(() => {
    GetSalesInvoiced(params);
  }, [rowsPerPage, pages]);
  /**ordenes no paginadas */
  useEffect(() => {
    GetSalesInvoicedNP();
  }, []);
  /**bUSQUEDA */
  const [magicWord, setMagicWord] = useState("");
  console.log(magicWord);
  const [debounceWord] = useDebounce(magicWord, 1500);
  const [search, saveSearch] = useState([]);
  const handleSearch = () => {
    console.log(ordersInvoicedNp);
    try {
      // if (debounceWord !== "") {
      if (ordersInvoicedNp !== null) {
        let searchResult = ordersInvoicedNp.filter(
          (sales) =>
            sales.folio.toString().toLowerCase() === magicWord.toLowerCase() ||
            sales.client_id.fullname
              .toString()
              .toLowerCase()
              .includes(debounceWord.toLowerCase())
        );

        saveSearch(searchResult);
      }
      // }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "No se encontraron registros que coincidan con los criterios de busqueda",
        icon: "error",
      });
    }
  };
  useEffect(() => {
    saveSearch(ordersInvoicedNp);
  }, []);
  useEffect(() => {
    handleSearch();
  }, [debounceWord]);
  /**TERMINA BUSQUEDA */
  console.log(search, "el resultado");
  /**termina */

  return (
    <>
      {orders ? (
        <>
          <Grid container spacing={2}>
            {orders.length && (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ marginBottom: 2 }}
              >
                <TextField
                  fullWidth
                  type="text"
                  placeholder="Buscar por folio venta o por nombre de cliente"
                  onChange={(e) => {
                    setMagicWord(e.target.value);
                    saveSearch(null);
                  }}
                />
              </Grid>
            )}
            {!magicWord &&
              (!orders.length ? (
                <NoDataComponents />
              ) : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TableSalesInvoiced orders={orders} />
                </Grid>
              ))}
            {magicWord &&
              (!search ? (
                <SearchingComponent />
              ) : search.length === 0 ? (
                <NoResultsComponent />
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TableSalesInvoiced orders={search} />
                  </Grid>
                </Grid>
              ))}
            <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
              {magicWord.length ? (
                <></>
              ) : (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  display="flex"
                  justifyContent="center"
                >
                  <Paginate
                    totalPages={totalPages}
                    pages={pages}
                    handleChangePage={handleChangePage}
                  />
                </Grid>
              )}
            </Box>
          </Grid>
        </>
      ) : (
        <NoDataComponents />
      )}
    </>
  );
};

export default SalesInvoiced;
