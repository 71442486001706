import * as React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { useState } from "react";
import MethodGet from "../../../config/Service";
import Swal from "sweetalert2";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SpinnerComponent from "../../../components/loading/SpinnerComponent";
import Layout from "../../../components/layout/Layout";
import { Box, Grid, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import formatDate from "../../../utils/FormatDate";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const useStyles = makeStyles(() => ({
  titleCard: {
    color: "white",
    fontSize: "35px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 2,
    marginRight: 5,
    display: "flex",
    justifyContent: "end",
  },
  img_data: {
    margin: "auto",
    height: "90%",
  },
}));
export default function DetailEntrie(props) {
  const classes = useStyles();
  const id = props.match.params.id;
  const [products, saveProducts] = useState();
  const [entrie, saveEntrie] = useState();
  const [cargando, spinnerCargando] = useState(false);
  useEffect(() => {
    let url = `/entries/${id}`;
    MethodGet(url)
      .then((res) => {
        saveEntrie(res.data.entrie);
        saveProducts(res.data.entrie.products);
        spinnerCargando(true);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: error.response.data.message,
        });
        spinnerCargando(true);
      });
  }, [id]);
  console.log(entrie);
  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ backgroundColor: "black", color: "white" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#000000",
              textAling: "center",
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
              // borderRadius: "0 0 24px 0",
            }}
          >
            <div className={classes.titleCard}>
              Detalle de entrada de productos
            </div>
            <div className={classes.fab}>
              <Tooltip title="Regresar" placement="top">
                <Button
                  sx={{ color: "white" }}
                  onClick={() => props.history.goBack()}
                >
                  <ArrowBackIcon />
                </Button>
              </Tooltip>
            </div>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {entrie && (
            <Grid container spacing={2} sx={{ margin: 2 }}>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                display="flex"
                justifyContent="center"
              >
                <Typography fontWeight="bold">
                  Fecha:
                  {formatDate(entrie.createdAt)}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                display="flex"
                justifyContent="center"
              >
                <Typography fontWeight="bold">
                  Folio:
                  {entrie.num_folio}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                display="flex"
                justifyContent="center"
              >
                <Typography fontWeight="bold">
                  Usuario:
                  {entrie.user}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
        <TableContainer component={Paper} sx={{ padding: 4 }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Código</StyledTableCell>
                <StyledTableCell align="center">producto</StyledTableCell>
                <StyledTableCell align="center">Marca</StyledTableCell>
                <StyledTableCell align="center">
                  Existencia anterior
                </StyledTableCell>
                <StyledTableCell align="center">Diferencia</StyledTableCell>
                <StyledTableCell align="center">
                  Existencia actual
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!products ? (
                <SpinnerComponent />
              ) : (
                <>
                  {products.map((row, index) => (
                    <StyledTableRow key={row.index}>
                      <StyledTableCell align="center">
                        {row.barcode}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.brand.name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.current_quantity}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Typography sx={{ color: "green" }}>
                          + {row.difference}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.new_quantity}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Layout>
  );
}
