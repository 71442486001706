import {
	ADD_USERS,
	GET_ALL_USERS,
	UPDATE_USERS,
	DELETE_USERS,
	SHOW_ERRORS_API,
	CURRENT_USER,
	GET_USERS_BLOCKED,
	RESTORE_USER,
	GET_ALL_USERS_SEARCH,
} from "../../types";

export default function UsersReducer(state, action) {
	switch (action.type) {
		case GET_ALL_USERS:
			return {
				...state,
				users: action.payload.users,
				totalDocs: action.payload.totalDocs,
				limit: action.payload.limit,
				page: action.payload.page,
				totalPages: action.payload.totalPages,
				paginCounter: action.payload.paginCounter,
				hasPrevPage: action.payload.hasPrevPage,
				hasNextPage: action.payload.hasNextPage,
				prevPage: action.payload.prevPage,
				nextPage: action.payload.nextPage,
				success: false,
				ErrorsAPI: [],
			};

		case GET_ALL_USERS_SEARCH:
			return {
				...state,
				allusers: action.payload.allusers,
				// totalDocs: action.payload.totalDocs,
				// limit: action.payload.limit,
				// page: action.payload.page,
				// paginCounter: action.payload.paginCounter,
				// hasPrevPage: action.payload.hasPrevPage,
				// hasNextPage: action.payload.hasNextPage,
				// prevPage: action.payload.prevPage,
				// nextPage: action.payload.nextPage,
				success: false,
				ErrorsAPI: [],
			};
		//funcion para obetner la lista de usuarios desactivados
		case GET_USERS_BLOCKED:
			return {
				...state,
				usersLocked: action.payload,
				success: false,
				ErrorsAPI: [],
			};

		case ADD_USERS:
			return {
				...state,
				success: true,
				ErrorsAPI: [],
			};
		case SHOW_ERRORS_API:
			return {
				...state,
				success: true,
				ErrorsAPI: [],
			};

		case CURRENT_USER:
			return {
				...state,
				user: action.payload,
			};

		case UPDATE_USERS:
			return {
				...state,
				success: true,
				ErrorsAPI: [],
			};

		case RESTORE_USER:
			return {
				...state,
				success: true,
				ErrorsAPI: [],
			};

		case DELETE_USERS:
			return {
				...state,
				success: true,
				users: state.users.filter((user) => user.id !== action.payload),
			};

		default:
			return state;
	}
}
