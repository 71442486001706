import Layout from "../../components/layout/Layout";
import { Grid } from "@mui/material";

import React from "react";
import ReportsUtility from "./ReportUtility";
import ReportUtilityGeneral from "./ReportUtilityGeneral";
import GraphProductsLessSold from "../../components/Dashboard/Graphs/GraphProductsLessSold";
import ProductsLessSold from "../../components/Dashboard/ProductsLessSold";

const IndexReports = () => {
  return (
    <Layout>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        <Grid item xs={12} md={6}>
          <ReportsUtility />
        </Grid>
        <Grid item xs={12} md={6}>
          <ReportUtilityGeneral />
        </Grid>
        <Grid item xs={12} md={6}>
          <GraphProductsLessSold />
        </Grid>
        <Grid item xs={12} md={6}>
          <ProductsLessSold />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default IndexReports;
