import React from 'react';
import { GET_COUPON_NEW_USER, UPDATE_COUPON_NEW_USER } from '../../types';

export default function CouponNewUserReducer(state, action) {

    switch (action.type) {
        case GET_COUPON_NEW_USER:
            return {
                ...state,
                couponsnu: action.payload,
                success: false,
                ErrorsApi: []
            }
        case UPDATE_COUPON_NEW_USER:
            return {
                ...state,
                success: true,
                ErrorsApi: []
            }
        default:
            return state;
    }
}
