import React from "react";
import Layout from "../../components/layout/Layout";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Link } from "react-router-dom";
import { useContext } from "react";
import InventoriesContext from "../../context/Inventories/InventoriesContext";
import { useEffect } from "react";
import FormatDate from "../../utils/FormatDate";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import { useState } from "react";
import ModalUpdateStock from "./ModalUpdateStock";
import NoDataComponents from "../../components/loading/NoDataComponents";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Inventories() {
  const {
    GetInventories,
    inventories,
    success,
    AddInventory,
    SaveCurrentInventory,
    ExportInventoryExcel,
    InventoryReportPdf,
  } = useContext(InventoriesContext);

  useEffect(() => {
    GetInventories();
  }, [success]);

  const selectInventory = (inventory) => {
    SaveCurrentInventory(inventory);
  };
  /**
   * MOdal update Stock
   */

  const [modalStock, abrirModalStock] = useState(false);
  const handleClickOpenUpdateStock = () => {
    abrirModalStock(true);
  };
  const handleClickCloseUpdateStock = () => {
    abrirModalStock(false);
  };
  /**Termina modal */
  return (
    <Layout>
      <Grid container spacing={2} justifyContent="center" sx={{ padding: 1 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Item sx={{ background: "black", color: "white" }}>
            <Typography variant="h5" fontFamily="monospace" fontWeight="bold">
              Inventarios
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Item sx={{ backgroundColor: "black" }}>
            <Tooltip title="Agregar inventario" placement="top">
              <Button
                onClick={() => AddInventory()}
                variant="contained"
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
              >
                Crear inventario
              </Button>
            </Tooltip>
          </Item>
        </Grid>
        {inventories.length > 0 ? (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Item sx={{ backgroundColor: "black" }}>
              <Tooltip title="Agregar inventario" placement="top">
                <Button
                  onClick={() => handleClickOpenUpdateStock()}
                  variant="contained"
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "black",
                      color: "white",
                    },
                  }}
                >
                  Actualizar Stock de productos
                </Button>
              </Tooltip>
            </Item>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      <Grid container spacing={2} sx={{ padding: 1 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {inventories.length ? (
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">
                      Fecha inicio
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Fecha termino
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      status inventario
                    </StyledTableCell>
                    <StyledTableCell align="center">opciones</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inventories.map((row) => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {row.dateFormattedStart ?? FormatDate(row.start_date)}
                      </StyledTableCell>
                      {row.dateFormattedEnd || row.end_date ? (
                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="center"
                        >
                          {row.dateFormattedEnd ?? FormatDate(row.end_date)}
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="center"
                        ></StyledTableCell>
                      )}
                      {row.status === 0 ? (
                        <StyledTableCell align="center">
                          <Typography
                            sx={{ background: "#9e9e9e", color: "white" }}
                          >
                            No iniciado
                          </Typography>
                        </StyledTableCell>
                      ) : row.status === 1 ? (
                        <StyledTableCell align="center">
                          <Typography
                            sx={{ background: "#1b5e20", color: "white" }}
                          >
                            En proceso
                          </Typography>
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell align="center">
                          <Typography
                            sx={{ background: "#01579b", color: "white" }}
                          >
                            Finalizado
                          </Typography>
                        </StyledTableCell>
                      )}
                      <StyledTableCell align="center">
                        {row.status !== 2 && (
                          <Link to="/Detalle-inventario">
                            <IconButton
                              onClick={() => selectInventory(row._id)}
                            >
                              <Tooltip
                                title="Ver detalle inventario"
                                placement="top"
                              >
                                <RemoveRedEyeIcon
                                  sx={{ color: "blueviolet" }}
                                />
                              </Tooltip>
                            </IconButton>
                          </Link>
                        )}
                        {row.status === 2 && (
                          <>
                            <IconButton
                              onClick={() => InventoryReportPdf(row._id)}
                            >
                              <Tooltip title="Descargar PDF" placement="top">
                                <PictureAsPdfIcon sx={{ color: "red" }} />
                              </Tooltip>
                            </IconButton>
                            <IconButton
                              onClick={() => ExportInventoryExcel(row._id)}
                            >
                              <Tooltip title="Descargar EXCEL" placement="top">
                                <BackupTableIcon sx={{ color: "green" }} />
                              </Tooltip>
                            </IconButton>
                          </>
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <NoDataComponents />
          )}
        </Grid>
      </Grid>
      <ModalUpdateStock
        modalStock={modalStock}
        handleClickOpen={handleClickOpenUpdateStock}
        handleClickClose={handleClickCloseUpdateStock}
      />
    </Layout>
  );
}
