import React, { useState, useContext, useEffect } from "react";
import Layout from "../../components/layout/Layout";
import AboutContext from "../../context/about/AboutContext";
import MethodGet from "../../config/Service";
import { Editor } from "@tinymce/tinymce-react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import { Box, Tooltip, Grid, IconButton, InputLabel, Fab } from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import Swal from "sweetalert2";

const useStyles = makeStyles({
  logoimagen: {
    height: "200px",
    boxShadow: "5px 5px #289FED",
    objectFit: "cover",
  },
  input: {
    display: "none",
  },
  card: {
    marginTop: 7,
    marginLeft: 7,
    marginRight: 7,
  },
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 2,
  },
  copybtn: {
    position: "absolute",
    right: 0,
  },
});

export default function AboutHistoryEdit(props) {
  const classes = useStyles();
  const { UpdateAboutHistory } = useContext(AboutContext);
  const [about, setAbout] = useState();
  const [RichText, setRichText] = useState();

  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState({
    urlPhoto:
      "https://cdn.pngsumo.com/index-of-areaedu-wp-content-uploads-2016-02-default-png-600_600.png",
    image: "",
  });

  const GetAbout = async () => {
    let url = await "/administrable/about";
    MethodGet(url)
      .then(({ data }) => {
        setAbout(data.about.about);
        setRichText(data.about.about.aboutThat.aboutThat);
        setImage({ urlPhoto: data.about.about.aboutThat.image, image: "" });
        setLoading(false);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: error.response.data.message,
          showConfirmButton: false,
          timer: 2000,
        });
      });
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  const handleEditorChange = (e) => {
    setRichText(e.target.getContent());
  };

  const handleChangeImage = (e) => {
    setImage({
      ...image,
      urlPhoto: URL.createObjectURL(e.target.files[0]),
      image: e.target.files[0],
    });
  };

  const onSubmit = (data, e) => {
    data.aboutThat = RichText;
    data.imageAbout = image.image;
    UpdateAboutHistory(data);
    props.history.goBack();
  };
  useEffect(() => {
    GetAbout();
  }, []);

  if (loading) return <></>;
  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#000000",
              textAling: "center",
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            }}>
            <div className={classes.titleCard}>Edita Historia</div>
            <div className={classes.fab}>
              <Tooltip title="Regresar">
                <Link to="/Nosotros">
                  <Fab
                    size="small"
                    sx={{
                      color: "white",
                      backgroundColor: "black",
                      borderRadius: "24px",
                      marginRight: 1,
                      "&:hover": {
                        backgroundColor: "white",
                        color: "black",
                      },
                    }}>
                    <ArrowCircleLeftOutlinedIcon />
                  </Fab>
                </Link>
              </Tooltip>
            </div>
          </Box>
          <form
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter")
                e.preventDefault();
            }}
            autoComplete="off">
            <Box sx={{ width: "100%", p: 1, padding: 4 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Editor
                    apiKey="4oemglfeurpxtmylg59yopah4kn16o7e3ma4ryvm44spbnlr"
                    onChange={handleEditorChange}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  sx={{ position: "relative" }}>
                  <div className={classes.copybtn}>
                    <Tooltip title="Copiar contenido" arrow>
                      <Fab
                        color="primary"
                        size="small"
                        aria-label="copycontent"
                        onClick={(e) => {
                          var element =
                            document.getElementById("copycontainer");
                          navigator.clipboard.writeText(
                            element.parentElement.textContent
                          );
                          return Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                              toast.addEventListener(
                                "mouseenter",
                                Swal.stopTimer
                              );
                              toast.addEventListener(
                                "mouseleave",
                                Swal.resumeTimer
                              );
                            },
                          }).fire({
                            icon: "info",
                            title: `Elemento Copiado! `,
                          });
                        }}>
                        <CopyAllIcon />
                      </Fab>
                    </Tooltip>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{ __html: RichText }}
                    id="copycontainer"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    <div>
                      <img
                        src={image.urlPhoto}
                        className={classes.logoimagen}
                        alt="agrega marca"
                      />
                    </div>
                  </Box>

                  <input
                    className={classes.input}
                    id="icon-button-file"
                    type="file"
                    name="image"
                    accept="image/png, image/jpg, image/jpeg, image/webp"
                    onChange={handleChangeImage}
                  />
                  <InputLabel sx={{ textAlign: "center" }}>
                    Selecciona una Imagen Referente:
                    <label htmlFor="icon-button-file">
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span">
                        <Tooltip
                          title="seleccionar imagen"
                          aria-label="seleccionar imagen">
                          <PhotoCamera />
                        </Tooltip>
                      </IconButton>
                    </label>
                  </InputLabel>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      textAling: "center",
                      paddingBottom: 10,
                    }}>
                    <div className={classes.fab}>
                      <Fab
                        type="submit"
                        variant="extended"
                        sx={{
                          backgroundColor: "#00B9FF",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#00DEED",
                            color: "white",
                          },
                        }}>
                        <SaveOutlinedIcon sx={{ mr: 1 }} />
                        Guardar
                      </Fab>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Grid>
      </Grid>
    </Layout>
  );
}
