import React, { useState, useEffect, useContext } from "react";
import {
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	FormHelperText,
} from "@mui/material";
import SubcategoriesContext from "../../context/SubCategories/SubcategoriesContext";

export default function SelectSubcategoriesCategories(props) {
	const { subcategoriesCategory, GetSubcategoriesOfCategory } =
		useContext(SubcategoriesContext);

	useEffect(() => {
		if (props.category_id !== "") {
			GetSubcategoriesOfCategory(props.category_id);
		} else if (props.category_id && props.SubcategoryId) {
			GetSubcategoriesOfCategory(props.category_id);
		}
	}, [props.category_id]);

	const detectarCambiosSubcategoryCategory = (e) => {
		props.detectarCambiosSubcategoryCategory(e);
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<FormControl fullWidth>
					<InputLabel id="demo-simple-select-label">Subcategorias</InputLabel>
					{props.SubcategoryId ? (
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							label="Subcategorias"
							onChange={detectarCambiosSubcategoryCategory}
							value={props.SubcategoryId}
							//defaultValue={props.SubcategoryId}
						>
							{subcategoriesCategory &&
								subcategoriesCategory.map((subcategory) => (
									<MenuItem key={subcategory._id} value={subcategory._id}>
										{subcategory.name}
									</MenuItem>
								))}
						</Select>
					) : (
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							label="Subcategorias"
							onChange={detectarCambiosSubcategoryCategory}
						>
							{subcategoriesCategory &&
								subcategoriesCategory.map((subcategory) => (
									<MenuItem key={subcategory._id} value={subcategory._id}>
										{subcategory.name}
									</MenuItem>
								))}
						</Select>
					)}
					<FormHelperText>Campo Requerido*</FormHelperText>
				</FormControl>
			</Grid>
		</Grid>
	);
}
