import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";

import { makeStyles } from "@mui/styles";
import {
  Grid,
  Box,
  Fab,
  TextField,
  Tooltip,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import WarehouseContext from "../../context/Warehouse/WarehouseContext";
import SelectShippments from "../../containers/SelectOptions/SelectShippments";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Swal from "sweetalert2";
import { DropzoneDialog } from "material-ui-dropzone";
import { useEffect } from "react";

const useStyles = makeStyles({
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 10,
  },
  input: {
    display: "none",
  },
});

export default function ModalShipped(props) {
  const classes = useStyles();
  const { modal, handleCloseShipped, id } = props;
  const [shippmentId, saveShippment] = useState("");
  const { ShippOrder, success } = useContext(WarehouseContext);
  const [file_first, setFile] = useState({
    file: "",
  });
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  useEffect(() => {
    if (success) {
      setValue("no_guide", "", { shouldDirty: true });
      setValue("comments", "", { shouldDirty: true });
      setValue("pdf", "", { shouldDirty: true });
    }
  }, [success]);

  const detectarCambiosShippment = (e) => {
    saveShippment(e.target.value);
  };
  const handleChangeFile = (e) => {
    setFile({
      ...file_first,
      file: e.target.files[0],
    });
  };

  const onSubmit = (data, e) => {
    if (file_first.file === "") {
      Swal.mixin({
        toast: true,
        background: "red",
        color: "white",
        iconColor: "white",
        position: "top-end",
        showConfirmButton: false,
        timer: 2400,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire({
        icon: "error",
        title: `Por favor sube la guia del pedido en PDF `,
      });
      return;
    }
    data.id = id;
    data.shipment_id = shippmentId;
    data.pdf = file_first.file;
    ShippOrder(data);
    handleCloseShipped();
  };

  return (
    <Dialog fullWidth maxWidth="md" open={modal} onClose={handleCloseShipped}>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#000000",
            textAling: "center",
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            borderRadius: "0 0 24px 0",
          }}>
          <div className={classes.titleCard}>Enviar Pedido</div>
          <div className={classes.fab}>
            <Tooltip title="cerrar" arrow>
              <Fab
                size="small"
                onClick={handleCloseShipped}
                sx={{
                  color: "#FFFFFF",
                  backgroundColor: "#FF0000",
                  "&:hover": {
                    color: "#FFFFFF",
                    backgroundColor: "#FF0000",
                  },
                }}>
                <CancelPresentationIcon />
              </Fab>
            </Tooltip>
          </div>
        </Box>
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter")
              e.preventDefault();
          }}
          autoComplete="off">
          <Grid container spacing={2} sx={{ marginTop: 1 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <SelectShippments
                detectarCambiosShippment={detectarCambiosShippment}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <TextField
                fullWidth
                id="no_guide"
                name="no_guide"
                placeholder="5484224875548"
                label="Número de guía"
                variant="outlined"
                sx={{ marginBottom: 2 }}
                error={errors.no_guide ? true : false}
                helperText={errors?.no_guide?.message}
                {...register("no_guide", {
                  required: {
                    value: true,
                    message: "Este campo es requerido",
                  },
                  minLength: {
                    value: 4,
                    message: "Minimo 4 caracteres",
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <TextField
                fullWidth
                id="comments"
                name="comments"
                placeholder="Agrega un comentario sobre el pedido, unicamente en caso de ser necesario"
                label="Comentarios"
                variant="outlined"
                multiline
                rows={4}
                sx={{ marginBottom: 2 }}
                error={errors.comments ? true : false}
                helperText={errors?.comments?.message}
                {...register("comments", {
                  required: {
                    value: false,
                    message: "Este campo es requerido",
                  },
                })}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              display="flex"
              justifyContent="center">
              <Button
                variant="contained"
                component="label"
                startIcon={<AttachFileIcon />}
                onChange={handleChangeFile}
                sx={{
                  color: "white",
                  backgroundColor: "#ad1457",
                  "&:hover": { backgroundColor: "#ad1457", color: "white" },
                }}>
                Adjuntar Guía PDF
                <input hidden accept="application/pdf" multiple type="file" />
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              display="flex"
              justifyContent="center">
              <Typography variant="overline" display="block" gutterBottom>
                Archivo Seleccionado: {""}
              </Typography>
              <Typography variant="overline" display="block" gutterBottom>
                {file_first.file !== ""
                  ? file_first?.file?.name
                  : " No se ha seleccionado ningun archivo"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  textAling: "center",
                }}>
                <div className={classes.fab}>
                  <Fab
                    variant="extended"
                    type="submit"
                    sx={{
                      backgroundColor: "#00B9FF",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#00DEED",
                        color: "white",
                      },
                    }}>
                    <SaveOutlinedIcon sx={{ mr: 1 }} />
                    Guardar
                  </Fab>
                </div>
              </Box>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}
