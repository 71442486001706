import { Route, Switch } from "react-router-dom";

//--------------------COMPONENT 404 ---------------/
import NotFoundComponent from "../components/loading/NotFoundComponent";
import Dashboard from "../containers/Dashboard/Dashboard";
import React from "react";
//-------------Usuarios---------------------------------------------//
import Users from "../containers/users/Users";
//--------------Tipo de Usuarios-----------------------------------//
import AggregatePermission from "../containers/type_users/AgregatePermissions";
import UpdatePermission from "../containers/type_users/UpdatePermissions";
//-------------Productos---------------------------------------------//
import EditProducts from "../containers/products/Edit";
import IndexProducts from "../containers/products/IndexProducts";
//-----------------------------Ofertas-------------------------------//
import Offers from "../containers/offers/Offers";
import AddOffers from "../containers/offers/Add";
import UpdateOffers from "../containers/offers/Edit";
//-------------Categorias---------------------------------------------//
//-------------SubCategorias---------------------------------------------//
import SubCategoryP from "../containers/categoriesps/CategoryS";
//-------------Preguntas Categorias--------------------------------------//
//------------------- Preguntas --------------------------------------//
import AddFaqs from "../containers/faqs/Add";
import EditFaqs from "../containers/faqs/Edit";
//------------------- Etiquetas --------------------------------------//
import Tags from "../containers/tags/Tags";
//------------------- Marcas --------------------------------------//
import Brands from "../containers/brands/brands";
//------------------------------------- Sliders -------------------------------------------//
import EcommerceSlider from "../containers/GeneralSlider/Ecommerce/EcommerceSlider";
import AddEcommerceSlider from "../containers/GeneralSlider/Ecommerce/Add";
//-----------------------------------CUPONES---------------------------//
import Coupons from "../containers/coupons/Coupons";
import CouponsExpired from "../containers/coupons/CouponesExpired";
//-----------------------------businesRules -----------------------------//
import IndexBusinessRules from "../containers/businessrules/Index";
//-----------------------------Nosotros -----------------------------//
// import AboutIndex from "../containers/about/AboutIndex";
import AboutHistoryEdit from "../containers/about/AboutHistoryEdit";
import AboutMissionEdit from "../containers/about/AboutMissionEdit";
///////////////////////////////////////////sales////////////////////////////////////////
// import SaleRevision from '../containers/Sale/SalesRevision';
import IndexSales from "../containers/Sale/IndexSales";
import ShowSaleAll from "../containers/Sale/ShowSaleAll";
import EditSale from "../containers/Sale/UpdateSale/EditSale";
import ImportOrder from "../containers/Sale/ImportOrder/ImportOrder";
/**Ventas fisicas */
import Sell from "../containers/SalesFisic/Sell";
import SalesFisic from "../containers/SalesFisic/SalesFisic";
/////////////////////////paqueterias/////////////////////
import Shippments from "../containers/Shippments/Shippments";
/******************************ALmcaen */
import IndexWarehouse from "../containers/Warehouse/IndexWarehouse";
////////////////////////////Clientes//////////////////
import Clients from "../containers/Clients/Clients";
//About Influencer
/////////////////////////////////Canvas////////////////////////////////////////////////////////////////
import IndexWarehouseCanvas from "../containers/WarehouseCanvas/IndexWarehouseCanvas";
//measures
//countries
import Countries from "../containers/PermissionCountries/Countries";
import UpdateFiles from "../containers/PermissionCountries/UpdateFiles";
//variantes canvas
import CanvasVariants from "../containers/canvasVariants/CanvasVariants";
import Inventories from "../containers/inventories/Inventories";
import ShowInventory from "../containers/inventories/ShowInventory";
import Reports from "../containers/Reports/Reports";
import ExitProducts from "../containers/ExitProducts/ExitProducts";
import DetailDepartures from "../containers/ExitProducts/DetailDeparture";
import AddExitProducts from "../containers/ExitProducts/AddExitProducts";
/**Tipos de clientes */
import TypeClients from "../containers/Clients/TypeClients/TypeClients";
/**ENtradas de prodycts */
import EnterProducts from "../containers/products/EnterProducts/EnterProducts";
import AddEnterProducts from "../containers/products/EnterProducts/AddEnterProducts";
import DetailEntrie from "../containers/products/EnterProducts/DetailEntrie";
/**politicas */
import Policies from "../containers/Policies/Policies";
import AddPolicies from "../containers/Policies/AddPolicies";
import UpdatePolicies from "../containers/Policies/UpdatePolicies";
/**proveedores */
import AddProviders from "../containers/Providers/AddProviders";
import Providers from "../containers/Providers/Providers";
import UpdateProviders from "../containers/Providers/UpdateProviders";
import DetailProviders from "../containers/Providers/DetailProviders";
/**Cuentas por pagar */
import AddAccountPayment from "../containers/Accounts/AccountsPayment/AddAccountPayment";
import AddInvoiceProvider from "../containers/Accounts/AccountsPayment/AddInvoiceProvider";
import AddPayAccount from "../containers/Providers/AddPayAccount.js";
import DetailAccount from "../containers/Providers/DetailAccount";
import UpdateAcount from "../containers/Providers/UpdateAcount";
import UpdatePayment from "../containers/Providers/UpdatePayment";
/**Cuentas por cobrar */
import AddAccountsReceivable from "../containers/Accounts/accountsReceivable/AddAccountsReceivable";
import DetailAccountReceivable from "../containers/Accounts/accountsReceivable/DetailAccountReceivable";
import AddPaymentToAccountReceivable from "../containers/Accounts/accountsReceivable/AddPaymentToAccountReceivable";
import AddAccountReceivableToClient from "../containers/Accounts/accountsReceivable/AddAccountReceivableToClient";
import DetailAccountPayments from "../containers/Accounts/accountsReceivable/DetailAccount";
import Menu from "../components/layout/Menu";
import Administration from "../containers/Administration/Administration";
import IndexCategories from "../containers/categoriesp/IndexCategories";
import IndexHelp from "../containers/faqscategories/IndexHelp";
import IndexAccounts from "../containers/Accounts/IndexAccounts";
import IndexAbout from "../containers/about/IndexAbout";
import IndexUsers from "../containers/users/IndexUsers";
import IndexInvoices from "../containers/Invoices/IndexInvoices";
import Expenses from "../containers/expenses/expenses";
/**Actualizar Venta */
import ModalUpdateSale from "../containers/Sale/UpdateSale/ModalUpdateSale";
import IndexReports from "../containers/ReportUtility/IndexReports";
// cotizaciones
import ModalCotizations from "../containers/Cotizations/Cotizations";
import IndexCoupons from "../containers/coupons/IndexCoupons";
import AllCotizations from "../containers/Cotizations/AllCotizations";
import ShowCotization from "../containers/Cotizations/ShowCotization";
import DiffProducts from "../containers/DiffProducts";
import Example from "../containers/examples/example.js";
/**Mensajes */
import ListContact from "../containers/Contact/ListContact.js";
export const EcommerceRoutes = () => {
  return (
    <Switch>
      <Route exact path="/diffProducts" component={DiffProducts} />
      <Route exact path="/" component={Menu} />
      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/reportes" component={IndexReports} />
      {/* cotizacion */}
      <Route exact path="/Cotizacion" component={AllCotizations} />
      <Route exact path="/Cotizacion/:id" component={ShowCotization} />
      {/* ventas fisicas */}
      <Route exact path="/ventas-fisicas" component={Sell} />
      <Route exact path="/ventas-fisicas-all" component={ShowSaleAll} />
      <Route exact path="/editar-venta/:id" component={EditSale} />
      <Route exact path="/ventas-fisicas/:id" component={Sell} />
      <Route exact path="/ventas-fisicas-all/:id" component={ShowSaleAll} />
      <Route exact path="/ventas-fisicas-edit/:id" component={EditSale} />
      <Route exact path="/ventas-fisicas-delete/:id" component={Sell} />
      {/* gastos */}
      <Route exact path="/Gastos" component={Expenses} />
      <Route exact path="/mis-usuarios" component={Users} />
      {/* Tipo de usuarios empieza */}
      <Route exact path="/AgregarPermisos" component={AggregatePermission} />
      <Route exact path="/ActualizarPermisos" component={UpdatePermission} />
      {/* Tipo de usuarios end */}
      {/* Productos empieza */}
      <Route exact path="/Productos" component={IndexProducts} />
      <Route exact path="/editar-producto/:id" component={EditProducts} />
      {/* Productos Termina */}
      {/**Reglas de negocio */}
      <Route exact path="/reglas-de-negocio" component={IndexBusinessRules} />
      {/* Preguntas Frecuentes empieza */}
      <Route exact path="/agrega-pregunta-frecuente" component={AddFaqs} />
      <Route exact path="/edita-pregunta-frecuente" component={EditFaqs} />
      {/* Preguntas Frecuentes Termina */}
      {/* Etiquetas empieza */}
      <Route exact path="/control-etiquetas" component={Tags} />
      {/* Ofertas*/}
      <Route exact path="/Administracion-ofertas" component={Offers} />
      <Route exact path="/agrega-oferta" component={AddOffers} />
      <Route exact path="/actualizar-oferta" component={UpdateOffers} />
      {/* Finaliza Ofertas*/}
      <Route exact path="/mis-clientes" component={Clients} />
      {/* <Route exact path="/categorias-productos" component={CategoryP} /> */}
      <Route exact path="/subcategorias-productos" component={SubCategoryP} />
      {/*Aqui inician las marcas */}
      <Route exact path="/administracion-marcas" component={Brands} />
      {/*Aqui termina las marcas */}
      {/**cupones */}
      <Route exact path="/Cupones" component={IndexCoupons} />
      {/*General Slider*/}
      <Route exact path="/EcommerceSlider" component={EcommerceSlider} />
      <Route
        exact
        path="/agregar-EcommerceSlider"
        component={AddEcommerceSlider}
      />
      <Route
        exact
        path="/editar-historia-nosotros"
        component={AboutHistoryEdit}
      />
      {/**Sales */}
      <Route exact path="/ventas" component={IndexSales} />
      <Route exact path="/vender" component={Sell} />
      {/* <Route exact path="/Detalle-orden" component={ShowSale} /> */}
      <Route exact path="/Detalle_venta/:id" component={ShowSaleAll} />
      <Route exact path="/Editar_venta/:id" component={EditSale} />
      {/**Ventas fisicas */}
      <Route exact path="/Ventas_de_tienda" component={SalesFisic} />
      <Route exact path="/Paqueterias" component={Shippments} />
      <Route exact path="/Almacen" component={IndexWarehouse} />
      <Route exact path="/AlmacenCanvas" component={IndexWarehouseCanvas} />
      {/* Measures */}
      <Route exact path="/Permisos-paises" component={Countries} />
      <Route exact path="/actualizar-archivos" component={UpdateFiles} />
      {/* Variantes cnas */}
      <Route exact path="/variantes-canvas" component={CanvasVariants} />
      <Route
        exact
        path="/editar-mision-nosotros"
        component={AboutMissionEdit}
      />
      <Route exact path="/inventarios" component={Inventories} />
      <Route exact path="/Detalle-inventario" component={ShowInventory} />
      <Route exact path="/Reportes" component={Reports} />
      <Route exact path="/salidas" component={ExitProducts} />
      <Route exact path="/nueva-salida-productos" component={AddExitProducts} />
      <Route exact path="/DetalleSalida/:id" component={DetailDepartures} />
      <Route exact path="/tipos-clientes" component={TypeClients} />
      <Route exact path="/Entradas" component={EnterProducts} />
      <Route exact path="/Nueva-Entrada" component={AddEnterProducts} />
      <Route exact path="/DetalleEntrada/:id" component={DetailEntrie} />
      <Route exact path="/Politicas" component={Policies} />
      <Route exact path="/AgregarPoliticas" component={AddPolicies} />
      <Route exact path="/EditarPoliticas/:id" component={UpdatePolicies} />
      <Route exact path="/AgregarProveedor" component={AddProviders} />
      <Route exact path="/Proveedores" component={Providers} />
      <Route
        exact
        path="/ActualizarProveedor/:id"
        component={UpdateProviders}
      />
      <Route exact path="/AgregarCuentaXpagar" component={AddAccountPayment} />
      <Route
        exact
        path="/AgregarFacturaProveedor/:id"
        component={AddInvoiceProvider}
      />
      <Route exact path="/DetalleProveedor/:id" component={DetailProviders} />
      <Route
        exact
        path="/AgregarPagoACuenta/:providerId/:accountId"
        component={AddPayAccount}
      />
      <Route
        exact
        path="/DetalleCuenta/:accountId/:providerId"
        component={DetailAccount}
      />
      <Route
        exact
        path="/UpdateAccount/:providerId/:accountId"
        component={UpdateAcount}
      />
      <Route
        exact
        path="/UpdatePayment/:accountId/:paymentId"
        component={UpdatePayment}
      />
      {/**Agregar cuentas por  */}
      <Route
        exact
        path="/AgregarCuentaCobrar"
        component={AddAccountsReceivable}
      />
      <Route
        exact
        path="/DetalleCuentaXCobrar/:idAccount"
        component={DetailAccountReceivable}
      />
      <Route
        exact
        path="/PaymentToAccountReceivable/:idClient/:idAccount"
        component={AddPaymentToAccountReceivable}
      />
      <Route
        exact
        path="/AddAccountToClient/:clientId"
        component={AddAccountReceivableToClient}
      />
      <Route
        exact
        path="/DetallePagosCuenta/:idAccount"
        component={DetailAccountPayments}
      />
      <Route exact path="/tiposClientes" component={TypeClients} />
      <Route exact path="/AgregarCuentaXpagar" component={AddAccountPayment} />
      <Route exact path="/Administracion" component={Administration} />
      <Route exact path="/Categorias" component={IndexCategories} />
      <Route exact path="/Ayuda" component={IndexHelp} />
      <Route exact path="/Cuentas" component={IndexAccounts} />
      <Route exact path="/Nosotros" component={IndexAbout} />
      <Route exact path="/Usuarios" component={IndexUsers} />
      <Route exact path="/Facturacion" component={IndexInvoices} />
      <Route exact path="/EditarVenta/:idSale" component={ModalUpdateSale} />
      <Route exact path="/ImportarVenta" component={ImportOrder} />
      <Route exact path="/example" component={Example} />
      <Route exact path="/mensajes" component={ListContact} />
      <Route path="*" component={NotFoundComponent} />
    </Switch>
  );
};
