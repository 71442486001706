import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ModalImage from "react-modal-image-responsive";
import { makeStyles } from "@mui/styles";
import { IconButton, TextField } from "@mui/material";
import { useState } from "react";
import InventoriesContext from "../../context/Inventories/InventoriesContext";
import { useEffect } from "react";
import { useDebounce } from "use-debounce";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import ModalChangeQuantity from "./ModalChangeQuantity";
import { Button, Stack, Typography } from "@mui/material";

import ImageIcon from "@mui/icons-material/Image";
import ModalForImage from "../../components/Detail/ModalImage";
const useStyles = makeStyles({
  img: {
    width: 100,
    height: 100,
  },
  jss59: {
    width: "100%",
    border: "2px solid #4dd0e1",
    height: "15%",
    outline: "none",
    padding: "15px 10px",
    marginLeft: "10px",
    borderRadius: "10px",
    textAlign: "center",
  },
});
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function TableProducts({
  inventario,
  guardarCantidad,
  saveIndex,
  index,
  inventory,
}) {
  const [openModal, setOpenModal] = React.useState(false);
  const [p_id, savePid] = useState(null);
  const [p_name, savePname] = useState(null);
  const handleClickOpenModal = (id, name) => {
    setOpenModal(true);
    savePname(name);
    savePid(id);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    savePname(null);
    savePid(null);
  };
  const classes = useStyles();
  //actualizacion de cantidad;
  const [new_quantity, saveNewQuantity] = useState(null);
  const [product_id, saveProductId] = useState(null);
  //funcion para el cambio de cantidad
  const handleChangeQuantity = (value, product_id) => {
    saveNewQuantity(value);
    saveProductId(product_id);
  };
  const [debouncedCantidad] = useDebounce(new_quantity, 1000);

  const data = {};
  if (new_quantity && product_id !== null) {
    data.quantity = new_quantity;
    data.product_id = product_id;
    data.inventory_id = inventory._id;
  }
  // const reset = () => {
  //   saveNewQuantity(0);
  //   saveProductId(0);
  // };

  // useEffect(() => {
  //   if (new_quantity && product_id !== null) {
  //     UpdateQuantityProduct(data);
  //   }
  // }, [debouncedCantidad]);

  const [open, setOpen] = useState(false);
  const [multimedia, saveMultimedia] = useState([]);
  const handleClickOpen = (multimedia) => {
    setOpen(true);
    saveMultimedia(multimedia);
  };

  const handleClose = () => {
    setOpen(false);
    saveMultimedia([]);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Imagen</StyledTableCell>
              <StyledTableCell align="center">Codigo de barras</StyledTableCell>
              <StyledTableCell align="center">Nombre</StyledTableCell>
              <StyledTableCell align="center">Marca</StyledTableCell>
              <StyledTableCell align="center">
                Cantidad inventariada
              </StyledTableCell>
              <StyledTableCell align="center">Opciones</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inventario.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell align="center">
                  <Button
                    variant="contained"
                    onClick={() => handleClickOpen(row.product_id.multimedia)}
                    sx={{
                      backgroundColor: "#f06292",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#f06292",
                        color: "white",
                      },
                    }}
                    endIcon={<ImageIcon />}
                  >
                    Ver
                  </Button>
                  {/* <ModalImage
                    className={classes.img}
                    small={row.image}
                    large={row.image}
                    alt={row.name}
                  /> */}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center">
                  {row.product_id.barcode}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center">
                  {row.name}
                </StyledTableCell>
                <StyledTableCell align="center">{row.brand}</StyledTableCell>
                <StyledTableCell align="center">{row.quantity}</StyledTableCell>
                <StyledTableCell align="center">
                  <IconButton
                    onClick={() =>
                      handleClickOpenModal(row.product_id._id, row.name)
                    }
                  >
                    <ChangeCircleIcon sx={{ color: "#00bcd4" }} />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {p_id !== null && p_name && (
        <ModalChangeQuantity
          open={openModal}
          handleClose={handleCloseModal}
          p_id={p_id}
          p_name={p_name}
          id_inventory={inventory._id}
        />
      )}
      {multimedia.length > 0 && (
        <ModalForImage
          open={open}
          handleClose={handleClose}
          image={multimedia}
        />
      )}
    </>
  );
}
