import {
  CANCEL_INVOICE,
  CREATE_GLOBAL_INVOICE,
  GET_CONCILIATIONS,
  GET_INVOICES_CANCELED,
  GET_PDF_INVOICE,
  GET_REQUEST_FOR_CANCEL_INVOICE,
  GET_SALES_FOR_INVOICE,
  GET_SALES_FOR_INVOICE_GLOBAL,
  GET_SALES_FOR_INVOICE_NO_PAGINATE,
  GET_SALES_INVOICED,
  GET_SALES_INVOICED_NO_PAGINATE,
  GET_ZIP_INVOICE,
  INVOICE_ORDER,
} from "./../../types/index";

export default function InvoicesReducer(state, action) {
  switch (action.type) {
    case GET_SALES_FOR_INVOICE:
      return {
        ...state,
        ordersForInvoice: action.payload.ordersForInvoice,
        totalDocs: action.payload.totalDocs,
        limit: action.payload.limit,
        page: action.payload.page,
        paginCounter: action.payload.paginCounter,
        hasPrevPage: action.payload.hasPrevPage,
        hasNextPage: action.payload.hasNextPage,
        prevPage: action.payload.prevPage,
        nextPage: action.payload.nextPage,
        totalPages: action.payload.totalPages,
        success: false,
        ErrorsAPI: [],
      };

    case GET_SALES_INVOICED:
      return {
        ...state,
        orders: action.payload.orders,
        totalDocs: action.payload.totalDocs,
        limit: action.payload.limit,
        page: action.payload.page,
        paginCounter: action.payload.paginCounter,
        hasPrevPage: action.payload.hasPrevPage,
        hasNextPage: action.payload.hasNextPage,
        prevPage: action.payload.prevPage,
        nextPage: action.payload.nextPage,
        totalPages: action.payload.totalPages,
        success: false,
        ErrorsAPI: [],
      };
    case GET_SALES_INVOICED_NO_PAGINATE:
      return {
        ...state,
        ordersInvoicedNp: action.payload,
        success: false,
        ErrorsAPI: [],
      };
    case GET_SALES_FOR_INVOICE_NO_PAGINATE:
      return {
        ...state,
        ordersForInvoiceNp: action.payload,
        success: false,
        ErrorsAPI: [],
      };
    case GET_SALES_FOR_INVOICE_GLOBAL:
      return {
        ...state,
        globalInvoice: action.payload,
        ErrorsAPI: [],
      };

    case GET_CONCILIATIONS:
      return {
        ...state,
        conciliations: action.payload,
        ErrorsAPI: [],
      };

    case CREATE_GLOBAL_INVOICE:
      return {
        ...state,
        orders: action.payload,
        ErrorsAPI: [],
      };

    case INVOICE_ORDER:
      return {
        ...state,
        success: true,
        ordersForInvoice: state.ordersForInvoice.filter(
          (invoice) => invoice._id !== action.payload
        ),
        ErrorsAPI: [],
      };
    case CANCEL_INVOICE:
      return {
        ...state,
        success: true,
        requestCancel: state.requestCancel.filter(
          (invoice) => invoice._id !== action.payload
        ),
      };
    case GET_PDF_INVOICE:
      return {
        ...state,
        success: true,
        ErrorsAPI: [],
      };
    case GET_ZIP_INVOICE:
      return {
        ...state,
        success: true,
        ErrorsAPI: [],
      };
    case GET_REQUEST_FOR_CANCEL_INVOICE:
      return {
        ...state,
        success: true,
        ErrorsAPI: [],
        requestCancel: action.payload.requestCancel,
        totalDocs: action.payload.totalDocs,
        limit: action.payload.limit,
        page: action.payload.page,
        paginCounter: action.payload.paginCounter,
        hasPrevPage: action.payload.hasPrevPage,
        hasNextPage: action.payload.hasNextPage,
        prevPage: action.payload.prevPage,
        nextPage: action.payload.nextPage,
        totalPages: action.payload.totalPages,
      };
    case GET_INVOICES_CANCELED:
      return {
        ...state,
        invoicesCanceled: action.payload.invoicesCanceled,
        success: true,
        ErrorsAPI: [],
      };
    default:
      return state;
  }
}
