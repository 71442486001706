import fileDownload from "js-file-download";
import React, { useReducer } from "react";
import Swal from "sweetalert2";
import MethodGet, { MethodPost } from "../../config/Service";
import {
  COUNT_SHIPPED,
  EXPORTEXCELCOTIZATION,
  GET_COTIZATIONS,
  INCOME_OF_DAY,
  INCOME_OF_MONTH,
  INCOME_OF_WEEK,
  PRODUCTS_CATALOGUE,
  PRODUCTS_CATALOGUE_NO_PAGINATION,
  SALES_OF_DAY,
  SALES_OF_MONTH,
  SALES_OF_WEEK,
  SHOW_ERRORS_API,
} from "../../types";
import DashboardContext from "./DashboardContext";
import DashboardReducer from "./DashboardReducer";
import headerConfig from "../../config/imageHeaders";

const DashboardState = ({ children }) => {
  const initialState = {
    sales_of_day: {},
    sales_of_week: {},
    sales_of_month: {},
    income_of_day: {},
    income_of_week: {},
    income_of_month: {},
    count_shipped: {},
    success: false,
    products_catalogue: [],
    products_catalogue_no_pagination: [],
    cotizations: [],
    ErrorsApi: [],
    totalDocs: 0,
  };

  const [state, dispatch] = useReducer(DashboardReducer, initialState);

  const SalesOfDay = () => {
    let url = "/orders/graphics/count-today";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: SALES_OF_DAY,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SHOW_ERRORS_API,
        });
      });
  };

  const SalesOfWeek = () => {
    let url = "/orders/graphics/count-week";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: SALES_OF_WEEK,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SHOW_ERRORS_API,
        });
      });
  };

  const SalesOfMonth = () => {
    let url = "/orders/graphics/count-month";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: SALES_OF_MONTH,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SHOW_ERRORS_API,
        });
      });
  };

  const IncomeOfDay = () => {
    let url = "/orders/graphics/money-today";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: INCOME_OF_DAY,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SHOW_ERRORS_API,
        });
      });
  };

  const IncomeOfWeek = () => {
    let url = "/orders/graphics/money-week";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: INCOME_OF_WEEK,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SHOW_ERRORS_API,
        });
      });
  };

  const IncomeOfMonth = () => {
    let url = "/orders/graphics/money-month";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: INCOME_OF_MONTH,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SHOW_ERRORS_API,
        });
      });
  };

  const CountShipped = () => {
    let url = "/orders/graphics/shipped";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: COUNT_SHIPPED,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SHOW_ERRORS_API,
        });
      });
  };

  const CatalogoProducts = (id, params) => {
    params.pages += 1;
    let url = `/products/product-catalogue/${id}?limit=${params.limite}&page=${params.pages}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: PRODUCTS_CATALOGUE,
          payload: {
            products_catalogue: res.data.products,
            totalDocs: res.data.totalDocs,
            limit: res.data.limit,
            page: res.data.page,
            paginCounter: res.data.paginCounter,
            hasPrevPage: res.data.hasPrevPage,
            hasNextPage: res.data.hasNextPage,
            prevPage: res.data.prevPage,
            nextPage: res.data.nextPage,
          },
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
      });
  };

  const ProductsCatalogueNoPagination = (id) => {
    let url = `/products/no-pagination/product-catalogue/${id}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: PRODUCTS_CATALOGUE_NO_PAGINATION,
          payload: res.data.products,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          icon: "error",
          timer: 2000,
          text: error.response.data.message,
          showConfirmButton: false,
        });
      });
  };

  const GetCotizations = () => {
    let url = "/cotizations";
    MethodGet(url).then((res) => {
      dispatch({
        type: GET_COTIZATIONS,
        payload: {
          cotizations: res.data.cotizations,
          totalDocs: res.data.totalDocs,
          limit: res.data.limit,
          page: res.data.page,
          paginCounter: res.data.paginCounter,
          hasPrevPage: res.data.hasPrevPage,
          hasNextPage: res.data.hasNextPage,
          prevPage: res.data.prevPage,
          nextPage: res.data.nextPage,
        },
      });
    });
  };

  const dowloadCotization = (id) => {
    Swal.fire({
      title: "Descargar cotizacion",
      text: "Se descargara la cotizacion seleccionada",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, descargar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/cotizations/download-xlsx/${id}`;
        MethodGet(url, { responseType: "blob" })
          .then((res) => {
            fileDownload(res.data, "cotizacion.pdf");
            Swal.fire({
              title: "Descargado",
              text: "La cotizacion se ha descargado correctamente",
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              icon: "error",
              text: error.response.data.message,
            });
            dispatch({
              type: SHOW_ERRORS_API,
            });
          });
      }
    });
  };

  const ExportExcelCotizacion = (data) => {
    Swal.fire({
      title: "Generar cotización",
      text: "Se generara la cotizacion",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Generar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = "/products/pre-cotization";
        MethodPost(url, data, { headerConfig })
          .then((res) => {
            //fileDownload(res.data, "cotizacion.xls");
            Swal.fire({
              title: "Creado",
              text: res.data.message,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
            dispatch({
              type: EXPORTEXCELCOTIZATION,
              payload: res.data.cotization,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
            });
            dispatch({
              type: SHOW_ERRORS_API,
            });
          });
      }
    });
  };

  return (
    <DashboardContext.Provider
      value={{
        sales_of_day: state.sales_of_day,
        sales_of_week: state.sales_of_week,
        sales_of_month: state.sales_of_month,
        income_of_day: state.income_of_day,
        income_of_month: state.income_of_month,
        income_of_week: state.income_of_week,
        count_shipped: state.count_shipped,
        products_catalogue: state.products_catalogue,
        products_catalogue_no_pagination:
          state.products_catalogue_no_pagination,
        totalDocs: state.totalDocs,
        limit: state.limit,
        page: state.page,
        paginCounter: state.paginCounter,
        hasPrevPage: state.hasPrevPage,
        prevPage: state.prevPage,
        hasNextPage: state.hasNextPage,
        nextPage: state.nextPage,
        cotizations: state.cotizations,
        SalesOfDay,
        SalesOfWeek,
        SalesOfMonth,
        IncomeOfDay,
        IncomeOfWeek,
        IncomeOfMonth,
        CountShipped,
        CatalogoProducts,
        ExportExcelCotizacion,
        GetCotizations,
        dowloadCotization,
        ProductsCatalogueNoPagination,
      }}>
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardState;
