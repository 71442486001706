import React, { useContext, useState } from "react";
import ProductsContext from "../../../context/Products/ProductsContext";
import { makeStyles } from "@mui/styles";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import LockIcon from "@mui/icons-material/Lock";
import VideoSettingsIcon from "@mui/icons-material/VideoSettings";
// material
import ModalImage from "react-modal-image-responsive";

import {
  Box,
  Card,
  Typography,
  Tooltip,
  IconButton,
  CardContent,
  CardActions,
  Grid,
  Badge,
  Stack,
  Alert,
} from "@mui/material";
import Marquee from "react-fast-marquee";
import ShowModal from "../../../containers/products/ProductShow";
import ModalUpdateMultimedia from "../../../containers/products/ModalUploadMultimedia";
import { Link } from "react-router-dom";
import SpinnerComponent from "../../loading/SpinnerComponent";
import CreateIcon from "@mui/icons-material/Create";
const useStyles = makeStyles({
  titleProduct: {
    color: "white",
  },
  ojito: {
    color: "red",
  },
  imgproduct: {
    top: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
  },
  card: {
    w: "400px",
    h: "400px",
  },
  cardHeader: {
    position: "absolute",
    borderTop: "$borderWeights$light solid $gray800",
    marginBottom: 0,
    zIndex: 1,
    background: " rgba(255, 255, 255, 0.2)",
    justifyContent: "normal",
    textAlign: "justify",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(8px)",
    border: "1px solid rgba(255, 255, 255, 0.3)",
    height: "auto",
  },
  cardBody: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
    alt: "Relaxing app background",
  },
  cardFooter: {
    borderTop: "$borderWeights$light solid $gray800",
    marginTop: "85%",
    background: " rgba(255, 255, 255, 0.2)",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(5px)",
    border: "1px solid rgba(255, 255, 255, 0.3)",
    width: "auto",
    height: "auto",
    justifyContent: "space-between",
  },
});

export default function ShopProductCard({ product, permiss, search }) {
  //Menu
  const classes = useStyles();
  const { DeleteProduct } = useContext(ProductsContext);
  const [id_p_multimedia, set_id_p_multimedia] = useState();
  //funciones para el modal del show
  const [modalShow, AbrirModalShow] = useState(false);
  const [id_product, setIdProduct] = useState(null);
  const handleClickOpenShow = (id) => {
    setIdProduct(id);
    AbrirModalShow(true);
  };
  const handleClickCloseShow = () => {
    AbrirModalShow(false);
    setIdProduct(null);
  };

  const [modalMultimedia, AbrirModalMultimedia] = useState(false);
  const handleClickOpenMultimedia = () => {
    AbrirModalMultimedia(true);
  };
  const handleClickCloseMultimedia = () => {
    AbrirModalMultimedia(false);
  };
  const ProductUpdateMultimedia = (product) => {
    set_id_p_multimedia(product);
    handleClickOpenMultimedia();
  };

  const { multimedia } = product; //desestructura de datos
  return (
    <Card>
      {/* {search && product.status === true && (
        <Stack
          direction="row"
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "rigth",
            marginRight: 3,
            marginTop: 2,
          }}
        >
          <Badge
            color="success"
            variant="string"
            overlap="rectangular"
            badgeContent={"activo"}
          />
        </Stack>
      )}
      {search && product.status === false && (
        <Stack
          direction="row"
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "rigth",
            marginRight: 5,
            marginTop: 2,
          }}
        >
          <Badge color="error" badgeContent={"Desactivado"} />
        </Stack>
      )} */}
      <CardContent>
        <Typography
          variant="su arrowbtitle2"
          noWrap
          sx={{ fontWeight: "bold", fontSize: "17px" }}
          className="blink-1"
        >
          {/* <Marquee play={false} gradient={false} speed={45}> */}
          {search && product.status === true ? (
            <Alert icon={false} severity="success">
              <Typography fontFamily="inherit">{product.name}</Typography>
            </Alert>
          ) : search && product.status === false ? (
            <Alert icon={false} severity="error">
              <Typography fontFamily="inherit">{product.name}</Typography>
            </Alert>
          ) : (
            !search && (
              <Typography fontFamily="inherit"> {product.name} </Typography>
            )
          )}
          {/* </Marquee> */}
        </Typography>
      </CardContent>
      {multimedia ? (
        <Box sx={{ width: "100%", display: "flex" }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box sx={{ pt: "100%", position: "relative" }}>
                <ModalImage
                  className={classes.imgproduct}
                  small={multimedia[0].path}
                  large={multimedia[0].path}
                  alt={product.name}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box sx={{ width: "100%", display: "flex" }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box sx={{ pt: "100%", position: "relative" }}>
                <SpinnerComponent />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
      <CardActions
        sx={{
          justifyContent: "space-between",
        }}
      >
        <Tooltip title="ver" placement="top">
          <IconButton onClick={() => handleClickOpenShow(product._id)}>
            <RemoveRedEyeIcon className="button_dark" />
          </IconButton>
        </Tooltip>
        {permiss.update === true && (
          <Tooltip title="Actualizar multimedia" placement="top">
            <IconButton onClick={() => ProductUpdateMultimedia(product._id)}>
              <VideoSettingsIcon className="button_warning" />
            </IconButton>
          </Tooltip>
        )}

        {permiss.update === true && (
          <Tooltip title="Editar" placement="top">
            <Link to={`/editar-producto/${product._id}`}>
              <IconButton>
                <CreateIcon className="button_primary" />
              </IconButton>
            </Link>
          </Tooltip>
        )}
        {product.status === true && permiss.delete === true && (
          <Tooltip title="Deshabilitar" placement="top">
            <IconButton
              onClick={() => {
                DeleteProduct(product._id);
              }}
            >
              <LockIcon className="button_danger" />
            </IconButton>
          </Tooltip>
        )}
      </CardActions>
      {id_product && (
        <>
          <ShowModal
            modalShow={modalShow}
            id={id_product}
            product={product}
            handleClickOpen={handleClickOpenShow}
            handleCloseShow={handleClickCloseShow}
          />
        </>
      )}
      {id_p_multimedia && (
        <ModalUpdateMultimedia
          modalMultimedia={modalMultimedia}
          id={id_p_multimedia}
          handleClickOpen={handleClickOpenMultimedia}
          handleCloseMultimedia={handleClickCloseMultimedia}
        />
      )}
    </Card>
  );
}
