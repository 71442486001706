import React, { useContext, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import SelectStock from "./SelectStock";
import { MethodPost } from "../../../config/Service";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  FormControl,
  Grid,
  Hidden,
  Paper,
  Typography,
} from "@mui/material";
import BarChart from "./BarChart";
import GraphsContext from "../../../context/Graphs/GraphsContext";
import { useForm } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 1,
    //marginLeft: theme.spacing(-1),
    paddingBottom: 1,
    paddingInlineEnd: 1,
    paddingRight: 0,
    marginLeft: "-50px",
  },
  formControl: {
    width: "100%",
    heigth: "100%",
    margin: 1,
    maxHeight: 400,
  },
  papercard: {
    padding: 2,
    margin: "auto",
    maxHeight: "470px",
    backgroundColor: "transparent",
    flexWrap: "nowrap",
    display: "relative",
    boxSizing: "border-box",
  },
  containerGraphic: {
    width: "100%",
    height: "440px",
    boxSizing: "border-box",
    position: "relative",
    display: "absolute",
  },
  GraphicDetails: {
    display: "absolute",
    width: "100%",
    height: "440px",
    maxHeight: "450px",
    position: "absolute",
    boxSizing: "border-box",
  },
}));

const GraphStock = () => {
  const classes = useStyles();

  const [stock, saveStock] = useState(1);
  const detectarCambiosStock = (e) => {
    saveStock(e.target.value);
  };
  const { GetStockProducts, products, success } = useContext(GraphsContext);

  const [prod, setProducts] = useState({
    labels: products.map((p) => p.name),
    datasets: [
      {
        label: "Stock de Productos",
        data: products.map((p) => p.quantity),
        backgroundColor: [
          "#aed581",
          "#dce775",
          "#fff176",
          "#ffd54f",
          "#ffb74d",
          "#ff8a65",
          "#f06292",
          "#f44336",
          "#ec407a",
          "#ab47bc",
          "#ff1744",
          "#f50057",
          "#d500f9",
          "#673ab7",
          "#3f51b5",
          "#2196f3",
          "#03a9f4",
          "#00bcd4",
          "#009688",
          "#4caf50",
          "#8bc34a",
          "#cddc39",
          "#ffeb3b",
          "#ffc107",
          "#ff9800",
          "#ff5722",
          "#795548",
          "#9e9e9e",
          "#607d8b",
          "#8e24aa",
          "#ad1457",
        ],
      },
    ],
  });

  useEffect(() => {
    GetStockProducts(stock);
    setProducts({
      labels: products.map((p) => p.name),
      datasets: [
        {
          label: "Stock de Productos",
          data: products.map((p) => p.quantity),
          backgroundColor: [
            "#aed581",
            "#dce775",
            "#fff176",
            "#ffd54f",
            "#ffb74d",
            "#ff8a65",
            "#f06292",
            "#f44336",
            "#ec407a",
            "#ab47bc",
            "#ff1744",
            "#f50057",
            "#d500f9",
            "#673ab7",
            "#3f51b5",
            "#2196f3",
            "#03a9f4",
            "#00bcd4",
            "#009688",
            "#4caf50",
            "#8bc34a",
            "#cddc39",
            "#ffeb3b",
            "#ffc107",
            "#ff9800",
            "#ff5722",
            "#795548",
            "#9e9e9e",
            "#607d8b",
            "#8e24aa",
            "#ad1457",
          ],
        },
      ],
    });
  }, [stock]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="h4" textAlign="center">
          Stock de productos
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <SelectStock detectarCambiosStock={detectarCambiosStock} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Bar data={prod} />
      </Grid>
    </Grid>
  );
};

export default GraphStock;
