import React, { useContext, useEffect, useState } from "react";
import ContactContext from "../../context/Contact/ContactContext";
import {
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Paginate } from "../../components/Pagination/Paginate";
import formatDate from "../../utils/FormatDate";
import NoDataComponents from "../../components/loading/NoDataComponents";
import DoneAllIcon from "@mui/icons-material/DoneAll";
const MessagesProcessUser = () => {
  const { messages, MessagesReadPerUser, makeAsReadMessage, totalPages } =
    useContext(ContactContext);
  //paginacion
  var [pages, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const limite = rowsPerPage;
  const params = { limite, pages };
  useEffect(() => {
    if (params.limite !== null && params.pages !== null) {
      MessagesReadPerUser(params);
    }
  }, [rowsPerPage, pages]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        {messages.length > 0 ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Nombre</TableCell>
                  <TableCell style={{ fontWeight: "bold" }} align="justify">
                    Mensaje
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }} align="justify">
                    Remitente
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }} align="justify">
                    Telefono
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }} align="justify">
                    Fecha
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }} align="justify">
                    Opción
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {messages.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="justify">{row.message}</TableCell>
                    <TableCell align="justify">{row.email}</TableCell>
                    <TableCell align="justify">{row.phone}</TableCell>
                    <TableCell align="justify">
                      {formatDate(row.createdAt)}
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => makeAsReadMessage(row._id)}>
                        <DoneAllIcon sx={{ color: "#53BDEB" }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Stack
              spacing={2}
              sx={{ marginBottom: 4, marginRight: 2, marginTop: 4 }}
            >
              <Paginate
                pages={pages}
                handleChangePage={handleChangePage}
                totalPages={totalPages}
              />
            </Stack>
          </TableContainer>
        ) : (
          <NoDataComponents />
        )}
      </Grid>
    </Grid>
  );
};

export default MessagesProcessUser;
