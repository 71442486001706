import React from 'react';
import { ADD_SHIPPMENTS, CURRENT_SHIPPMENTS, DELETE_SHIPPMENTS, GET_ALL_SHIPPMENTS, SHOW_ERRORS_API, UPDATE_SHIPPMENTS } from '../../types';

export default function ShippmentsReducer(state, action) {

    switch (action.type) {
        case GET_ALL_SHIPPMENTS:
            return {
                ...state,
                shippments: action.payload,
                success: false,
                ErrorsApi: []
            }
        case ADD_SHIPPMENTS:
            return {
                ...state,
                success: true,
                ErrorsApi: []
            }
        case SHOW_ERRORS_API:
            return {
                ...state,
                success: true,
                ErrorsAPI: []
            }
        case CURRENT_SHIPPMENTS:
            return {
                ...state,
                shippment: action.payload
            }
        case UPDATE_SHIPPMENTS:
            return {
                ...state,
                success: true,
                ErrorsApi: []
            }
        case DELETE_SHIPPMENTS:
            return {
                ...state,
                success: true,
                shippments: state.shippments.filter(shippment => shippment.id !== action.payload)
            }
        default:
            return state;
    }
}
