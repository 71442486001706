import React, { useContext, useState } from "react";
import CouponsContext from "../../../context/coupons/CouponsContext";
import formatDate from "../../../utils/FormatDate";
import EditCoupon from "../../../containers/coupons/EditCoupon";

import Swal from "sweetalert2";
import { Grid, IconButton, Box, Typography, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

export default function CuponCard({ coupon, permiss }) {
  const [idCoupon, setIdCoupon] = useState();
  const [modalUpdate, setModalUpdate] = useState(false);

  const { DeleteCoupon } = useContext(CouponsContext);

  const CopyText = (text, e) => {
    navigator.clipboard.writeText(text);
    return Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    }).fire({
      icon: "info",
      title: `Cupon ${text} copiado `,
    });
  };

  const HandleOpenModalUpdate = () => {
    setModalUpdate(true);
  };

  const HandleCloseModalUpdate = () => {
    sessionStorage.removeItem("coupon");
    setModalUpdate(false);
  };

  const SelectCoupon = (id_coupon) => {
    setIdCoupon(id_coupon);
    HandleOpenModalUpdate();
  };

  return (
    <Box sx={{ boxShadow: 3, padding: 1 }}>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              borderRight: 1,
            }}>
            <Typography
              sx={{ fontSize: 13, fontWeight: "bold", textColor: "black" }}>
              Fecha de Expiración
            </Typography>
            <Typography
              sx={{ fontSize: 12, fontWeight: "bold", textColor: "black" }}>
              {formatDate(coupon.expiration_date)}
            </Typography>

            {permiss.delete === true && (
              <Tooltip arrow title="Eliminar">
                <IconButton
                  onClick={(e) => {
                    DeleteCoupon(coupon._id);
                  }}>
                  <DeleteIcon className="button_danger" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}>
            <Typography
              sx={{ fontSize: 13, fontWeight: "bold", textColor: "black" }}>
              {coupon.discount}% de Descuento
            </Typography>
            <button
              className="border border-info px-3 rounded code"
              onClick={(e) => CopyText(coupon.code)}>
              {coupon.code}
            </button>
            {permiss.update === true && (
              <Tooltip arrow title="Modificar">
                <IconButton
                  onClick={(e) => {
                    SelectCoupon(coupon._id);
                  }}>
                  <EditIcon className="button_primary" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Grid>
      </Grid>
      {idCoupon && (
        <EditCoupon
          id_coupon={idCoupon}
          modal={modalUpdate}
          handleClose={HandleCloseModalUpdate}
        />
      )}
    </Box>
  );
}
