import React from "react";
import { CREATE_SALE, GET_SALES_FISIC, SEND_CART } from "../../types";

export default function SalesFisicReducer(state, action) {
  switch (action.type) {
    case GET_SALES_FISIC:
      return {
        ...state,
        sales_fisic: action.payload.sales_fisic,
        totalDocs: action.payload.totalDocs,
        limit: action.payload.limit,
        page: action.payload.page,
        paginCounter: action.payload.paginCounter,
        hasPrevPage: action.payload.hasPrevPage,
        hasNextPage: action.payload.hasNextPage,
        prevPage: action.payload.prevPage,
        nextPage: action.payload.nextPage,
        totalPages: action.payload.totalPages,
        success: false,
        ErrorsApi: [],
      };
    case CREATE_SALE:
      return {
        ...state,
        success: true,
        sales_fisic: [action.payload, ...state.sales_fisic],
        ErrorsApi: [],
      };
    default:
      return state;
  }
}
