import React, { useReducer } from "react";
import Swal from "sweetalert2";
import MethodGet, {
  MethodDelete,
  MethodPost,
  MethodPut,
} from "../../config/Service";
import {
  ADD_PRODUCTS,
  CURRENT_PRODUCTS,
  DELETE_PRODUCTS,
  GET_ALL_PRODUCTS,
  GET_ALL_PRODUCTS_DESACTIVATED,
  GET_PRODUCTS,
  RESTORE_PRODUCT,
  SHOW_ERRORS_API,
  UPDATE_PRODUCTS,
  UPLOAD_MULTIMEDIA,
  IMPORT_PRODUCTS_EXCEL,
  IMPORT_PRODUCTS_EXCEL_ERROR,
  UPDATE_PRODUCTS_EXCEL,
  UPDATE_PRODUCTS_EXCEL_ERROR,
  GET_PRODUCTS_CANVAS_ONLY,
  SEARCH_PRODUCT,
  UPDATE_STOCK,
  GET_ALL_MOVEMENTS,
  SEARCH_PRODUCTS_DESACTIVATED,
  SEARCH_PRODUCTS_MOVEMENTS,
  GET_ONE_PRODUCT,
  GET_PRODUCT_DESACTIVATED,
  CREATE_ENTER_PRODUCTS,
  GET_ENTER_PRODUCTS,
  GET_ALL_PRODUCTS_SELECT,
  EXPORT_ALL_PRODUCTS_STOCK_EXCEL,
} from "../../types";
import ProductsContext from "./ProductsContext";
import ProductsReducer from "./ProductsReducer";
import headerConfig from "../../config/imageHeaders";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import fileDownload from "js-file-download";

const ProductsState = ({ children }) => {
  //estado inicial
  const initialState = {
    products: [],
    movements: [],
    search_products_movements: [],
    entries: [],
    movement: {},
    productLock: {},
    product: null,
    ErrorsApi: [],
    success: false,
    loading: false,
    payload: {},
    //created: true,
    success_update: false,
    current_product: {},
  };
  //dispatch que ejecuta las acciones
  const [state, dispatch] = useReducer(ProductsReducer, initialState);
  //obtener todos los productos sin importar el status
  const GetAllProductsSelect = () => {
    let url = "/products/all-no-pagination";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_PRODUCTS_SELECT,
          payload: res.data.products,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //obtener la lista de productos
  const GetProducts = (params) => {
    sessionStorage.removeItem("product");
    //params.pages += 1;
    let url = `/products?limit=${params.limite}&page=${params.pages}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_PRODUCTS,
          payload: {
            products: res.data.products,
            totalDocs: res.data.totalDocs,
            limit: res.data.limit,
            page: res.data.page,
            paginCounter: res.data.paginCounter,
            hasPrevPage: res.data.hasPrevPage,
            hasNextPage: res.data.hasNextPage,
            prevPage: res.data.prevPage,
            nextPage: res.data.nextPage,
            totalPages: res.data.totalPages,
          },
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          text: error.response.data.message,
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.message,
        });
      });
  };
  //obtner un solo producto
  const GetOneProduct = (id) => {
    sessionStorage.removeItem("product");
    let url = `/products/${id} `;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ONE_PRODUCT,
          payload: id,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
      });
  };
  //busqueda de productos
  const searchProduct = (palabra) => {
    let url = `/search/productsAdmin?search=${palabra}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: SEARCH_PRODUCT,
          payload: res.data.results,
        });
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };
  //busqueda productos desactivados
  const searchProductDesactivated = (palabra) => {
    let url = `/search/productsDesactivated?search=${palabra}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: SEARCH_PRODUCTS_DESACTIVATED,
          payload: res.data.results,
        });
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };
  //busqueda movimientos productos
  const searchProductMovement = (palabra) => {
    let url = `/search/productsMovements?search=${palabra}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: SEARCH_PRODUCTS_MOVEMENTS,
          payload: res.data.results,
        });
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };
  //productos sin paginacion para el select
  const productsSelect = () => {
    sessionStorage.removeItem("product");
    let url = "/products/without/pagination";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_PRODUCTS,
          payload: res.data.products,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
      });
  };
  //productos canvas
  const GetProductsCanvas = () => {
    sessionStorage.removeItem("product");
    let url = "/products/canvas";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_PRODUCTS_CANVAS_ONLY,
          payload: res.data.products,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
      });
  };
  //lista de productos desactivados
  const GetProductsDesactivated = (params) => {
    sessionStorage.removeItem("productLock");
    //params.pages += 1;
    let url = `/products/product/desactivated?limit=${params.limite}&page=${params.pages}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_PRODUCTS_DESACTIVATED,
          payload: {
            productsLocked: res.data.products,
            totalDocs: res.data.totalDocs,
            limit: res.data.limit,
            page: res.data.page,
            paginCounter: res.data.paginCounter,
            hasPrevPage: res.data.hasPrevPage,
            hasNextPage: res.data.hasNextPage,
            prevPage: res.data.prevPage,
            nextPage: res.data.nextPage,
            totalPages: res.data.totalPages,
          },
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.message,
        });
      });
  };
  //agregar un nuevo producto
  const AddProducts = (product) => {
    const formData = new FormData();
    formData.append("barcode", product.barcode);
    formData.append("name", product.name);
    formData.append("short_description", product.short_description);
    formData.append("description", product.description);
    formData.append("category", product.category);
    formData.append("subcategory", product.subcategory);
    formData.append("brand", product.brand);
    formData.append("discount", product.discount);
    formData.append("quantity", 0);
    formData.append("tags", product.tags);
    formData.append("price", product.price);
    formData.append("price_purchase", product.price_purchase);
    formData.append("product_key", product.product_key);
    formData.append("sku", product.sku);
    formData.append("type_product", product.product_type);
    formData.append("weight", product.weight);
    formData.append("location", product.location);
    formData.append(
      "google__product_category",
      product.product_google_category
    );
    if (product.product_type === "2" && product.variation) {
      formData.append("variation", product.variation);
    }
    let url = `/products/`;
    MethodPost(url, product)
      .then((res) => {
        Swal.fire({
          title: "Creado",
          text: res.data.message,
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        dispatch({
          type: ADD_PRODUCTS,
          payload: res.data.product,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          text: error.response.data.message,
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.message,
        });
      });
  };
  //excel productos mas vendidos
  const ExportProductsMostSold = (data) => {
    Swal.fire({
      title: "Descargar",
      text: "Se desacargara un reporte de los productos mas vendidos",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, descargar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        // const formData = new FormData();
        // formData.append("brand", data.brand);
        // formData.append("limit", data.limit);
        // formData.append("start_date", data.start_date);
        // formData.append("end_date", data.end_date);
        let url = `/products/most/sold?brand=${data.brand}&limit=${data.limit}&start_date=${data.start_date}&end_date=${data.end_date}`;
        MethodGet(url, { responseType: "blob" })
          .then((res) => {
            let timerInterval;
            Swal.fire({
              title: "Generando",
              html: "El reporte se esta generando!",
              timer: 2000,
              timerProgressBar: true,
              showConfirmButton: false,
              // didOpen: () => {
              //   Swal.showLoading();
              //   const b = Swal.getHtmlContainer().querySelector("b");
              //   timerInterval = setInterval(() => {
              //     b.textContent = Swal.getTimerLeft();
              //   }, 100);
              // },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              fileDownload(
                res.data,
                `productos_mas_vendidos_${data.start_date}_al_${data.end_date}.xlsx`
              );
              Swal.fire({
                title: "Descargado",
                text: "Se ha descargado correctamente el archivo",
                icon: "success",
                timer: 2000,
                showConfirmButton: false,
              });
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: "Ocurrio un error, al descargar el archivo",
              icon: "error",
            });
            dispatch({
              type: SHOW_ERRORS_API,
            });
          });
      }
    });
  };

  const ExportProductsLessSold = (data) => {
    Swal.fire({
      title: "Descargar",
      text: "Se desacargara un reporte de los productos mas vendidos",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, descargar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        // const formData = new FormData();
        // formData.append("brand", data.brand);
        // formData.append("limit", data.limit);
        // formData.append("start_date", data.start_date);
        // formData.append("end_date", data.end_date);
        let url = `/products/less/sold?brand=${data.brand}&start_date=${data.start_date}&end_date=${data.end_date}`;
        MethodGet(url, { responseType: "blob" })
          .then((res) => {
            let timerInterval;
            Swal.fire({
              title: "Generando",
              html: "El reporte se esta generando!",
              timer: 2000,
              timerProgressBar: true,
              showConfirmButton: false,
              // didOpen: () => {
              //   Swal.showLoading();
              //   const b = Swal.getHtmlContainer().querySelector("b");
              //   timerInterval = setInterval(() => {
              //     b.textContent = Swal.getTimerLeft();
              //   }, 100);
              // },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              fileDownload(
                res.data,
                `productos_menos_vendidos_${data.start_date}_al_${data.end_date}.xlsx`
              );
              Swal.fire({
                title: "Descargado",
                text: "Se ha descargado correctamente el archivo",
                icon: "success",
                timer: 2000,
                showConfirmButton: false,
              });
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: "Ocurrio un error, al descargar el archivo",
              icon: "error",
            });
            dispatch({
              type: SHOW_ERRORS_API,
            });
          });
      }
    });
  };
  //excel ventas por fecha
  const ExportOrdersExcel = (data) => {
    Swal.fire({
      title: "Descargar",
      text: "Se desacargara un reporte de los productos mas vendidos",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, descargar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/orders/export/excel/${data.start_date}/${data.end_date}/${data.order_status}`;
        MethodGet(url, { responseType: "blob" })
          .then((res) => {
            let timerInterval;
            Swal.fire({
              title: "Generando",
              html: "El reporte se esta generando!",
              timer: 2000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const b = Swal.getHtmlContainer().querySelector("b");
                timerInterval = setInterval(() => {
                  b.textContent = Swal.getTimerLeft();
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              fileDownload(
                res.data,
                `reporte_Ventas_del_${data.start_date}_al_${data.end_date}.xlsx`
              );
              Swal.fire({
                title: "Descargado",
                text: "Se ha descargado correctamente el archivo",
                icon: "success",
                timer: 2000,
                showConfirmButton: false,
              });
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: "Ocurrio un error, al descargar el archivo",
              icon: "error",
            });
            dispatch({
              type: SHOW_ERRORS_API,
            });
          });
      }
    });
  };
  //plantilla para agregar productos desde excel
  const ExportProductsExcel = () => {
    Swal.fire({
      title: "Descargar plantilla",
      text: "Se descargara la plantilla para importar productos",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, descargar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = "/products/export/excel";
        MethodGet(url, { responseType: "blob" })
          .then((res) => {
            let timerInterval;
            Swal.fire({
              title: "Generando",
              html: "El reporte se esta generando",
              timer: 2000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const b = Swal.getHtmlContainer().querySelector("b");
                timerInterval = setInterval(() => {
                  b.textContent = Swal.getTimerLeft();
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              fileDownload(res.data, "plantilla_importar_productos.xlsx");
              Swal.fire({
                title: "Descargado",
                text: "Se ha descargado correctamente el archivo",
                icon: "success",
                timer: 2000,
                showConfirmButton: false,
              });
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: "Ocurrio un error, al descargar el archivo",
              icon: "error",
            });
            dispatch({
              type: SHOW_ERRORS_API,
            });
          });
      }
    });
  };
  //plantilla para actualizar productos desde excel
  const ExportUpdateProductsExcel = () => {
    Swal.fire({
      title: "Descargar actualizar productos",
      text: "Se descargara la plantilla para actualizar productos",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, descargar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = "/products/export-update/excel";
        MethodGet(url, { responseType: "blob" })
          .then((res) => {
            fileDownload(res.data, "Plantilla_actualizar_productos.xlsx");
            Swal.fire({
              title: "Descargado",
              text: res.data.message,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              icon: "error",
              timer: 1500,
              showConfirmButton: false,
              text: error.response.data.message,
            });
            dispatch({
              type: SHOW_ERRORS_API,
            });
          });
      }
    });
  };
  //importar productos desde excel
  const ImportProductsExcel = (products) => {
    const formData = new FormData();
    formData.append("excel", products.file);
    let url = "/products/import-excel";
    MethodPost(url, formData, { headerConfig })
      .then((res) => {
        toast.success("Productos importados Exitosamente!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        dispatch({
          type: IMPORT_PRODUCTS_EXCEL,
          //payload: res.data.products,
        });
      })
      .catch((error) => {
        error.response.data.errors.map((response) =>
          toast.error(
            <div>
              <p>Error: {response.message} </p>
            </div>,
            {
              position: "top-right",
              autoClose: 1200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "colored",
            }
          )
        );
        dispatch({
          type: IMPORT_PRODUCTS_EXCEL_ERROR,
        });
      });
  };
  //actualizar productos desde excel
  const UpdateProductsExcel = (products) => {
    const formData = new FormData();
    formData.append("excel", products.file);
    let url = "/products/update-excel";
    MethodPost(url, formData, { headerConfig })
      .then((res) => {
        toast.success("Productos actualizados Exitosamente!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        dispatch({
          type: UPDATE_PRODUCTS_EXCEL,
        });
      })
      .catch((error) => {
        error.response.data.errors.map((response) =>
          toast.error(
            <div>
              <p>Error: {response.message} </p>
            </div>,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "colored",
            }
          )
        );
        dispatch({
          type: UPDATE_PRODUCTS_EXCEL_ERROR,
        });
      });
  };
  //actualizar producto
  const UpdateProduct = (product) => {
    const formData = new FormData();
    formData.append("barcode", product.barcode);
    formData.append("name", product.name);
    formData.append("tags", product.tags);
    formData.append("short_description", product.short_description);
    formData.append("description", product.description);
    formData.append("category", product.category);
    formData.append("subcategory", product.subcategory);
    formData.append("brand", product.brand);
    formData.append("tags", product.tags);
    formData.append("price", product.price);
    formData.append("price_purchase", product.price_purchase);
    formData.append("product_key", product.product_key);
    formData.append("sku", product.sku);
    formData.append("product_type", product.product_type);
    formData.append("weight", product.weight);
    formData.append("location", product.location);
    formData.append("google_product_category", product.product_google_category);
    if (product.product_type === "2") {
      formData.append("variation", product.variation);
    }
    let url = `/products/${product.id}`;
    MethodPut(url, product)
      .then((res) => {
        Swal.fire({
          title: "Producto Actualizado",
          text: res.data.message,
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        dispatch({
          type: UPDATE_PRODUCTS,
          payload: res.data.product,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          icon: "error",
          showConfirmButton: false,
          text: error.response.data.message,
          timer: 1500,
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.message,
        });
      });
  };
  //actualizar multimedia
  const UploadMultimedia = (product) => {
    const formData = new FormData();

    if (product.multimedia_first && product.multimedia_first_id) {
      formData.append("multimedia_first", product.multimedia_first);
      formData.append("multimedia_first_id", product.multimedia_first_id);
    }
    if (product.multimedia_second && product.multimedia_second_id) {
      formData.append("multimedia_second", product.multimedia_second);
      formData.append("multimedia_second_id", product.multimedia_second_id);
    }
    if (product.multimedia_third && product.multimedia_third_id) {
      formData.append("multimedia_third", product.multimedia_third);
      formData.append("multimedia_third_id", product.multimedia_third_id);
    }
    if (product.multimedia_fourth && product.multimedia_fourth_id) {
      formData.append("multimedia_fourth", product.multimedia_fourth);
      formData.append("multimedia_fourth_id", product.multimedia_fourth_id);
    }

    let url = `/products/fileUpload/${product.id}`;

    MethodPut(url, formData, { headerConfig })
      .then((res) => {
        Swal.mixin({
          toast: true,
          background: "green",
          color: "white",
          iconColor: "white",
          position: "top-end",
          showConfirmButton: false,
          timer: 1200,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        }).fire({
          icon: "success",
          title: `Multimedia Actualizada `,
          text: res.data.message,
        });

        dispatch({
          type: UPLOAD_MULTIMEDIA,
          payload: res.data.product,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
          text: error.response.data.message,
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.message,
        });
      });
  };
  //eliminar producto
  const DeleteProduct = (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "El producto seleccionado será desactivado, y ya no sera visible en el E-commerce",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Desactivar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/products/${id}`;
        MethodDelete(url).then((res) => {
          Swal.fire({
            title: "Desactivado",
            text: res.data.message,
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          });
          dispatch({
            type: DELETE_PRODUCTS,
            payload: id,
          });
        });
      }
    });
  };
  //restaurar productos
  const RestoreProducts = (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "El producto seleccionado, se volverá a habilitar, y sera visible en el Ecommerce",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      allowOutsideClick: false,
      confirmButtonText: "Si, Habilitar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          title: "Asignar nuevo Stock",
          allowOutsideClick: false,
          html: `<input type="number" id="stock" class="swal2-input" placeholder="1258">`,
          confirmButtonText: "Guardar stock y Habilitar",
          showCancelButton: true,
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          focusConfirm: false,
          preConfirm: () => {
            var stock = Swal.getPopup().querySelector("#stock").value;
            if (!stock) {
              Swal.showValidationMessage(
                `Por favor ingresa una cantidad de stock al producto`
              );
            } else if (stock === 0) {
              Swal.showValidationMessage(
                `El stock que se asigne debe ser mayor a 0`
              );
            }
            return { stock: stock };
          },
        }).then((result) => {
          if (result.value) {
            let url = `/products/reactivate/${id}`;
            const formData = new FormData();
            formData.append("quantity", result.value.stock);
            MethodPost(url, formData)
              .then((res) => {
                Swal.fire({
                  title: "Habilitado",
                  text: res.data.message,
                  icon: "success",
                  timer: 1050,
                  showConfirmButton: false,
                });
                dispatch({
                  type: RESTORE_PRODUCT,
                  payload: id,
                });
              })
              .catch((error) => {
                toast.error(
                  <div>
                    <p>Error: {error.response.data.message}</p>
                  </div>,
                  {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "colored",
                  }
                );
              });
          }
        });
      }
    });
  };
  //Actualizar Stock
  const UpdateStock = (id) => {
    Swal.fire({
      title: "Agregar productos a Stock",
      allowOutsideClick: false,
      html: `<input type="number" id="add_stock" class="swal2-input" placeholder="1258">`,
      confirmButtonText: "Guardar stock",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      focusConfirm: false,
      preConfirm: () => {
        var stock = Swal.getPopup().querySelector("#add_stock").value;
        if (!stock) {
          Swal.showValidationMessage(
            `Por favor ingresa una cantidad de stock al producto`
          );
        } else if (stock === 0) {
          Swal.showValidationMessage(`La cantidad debe ser mayor a 0`);
        }
        return { stock: stock };
      },
    }).then((result) => {
      if (result.value) {
        let url = `/products-movements/${id}`;
        const formData = new FormData();
        formData.append("quantity", Number(result.value.stock));
        MethodPost(url, formData)
          .then((res) => {
            Swal.fire({
              title: "Stock Actualizado",
              text: res.data.message,
              icon: "success",
              timer: 1000,
              showConfirmButton: false,
            });
            dispatch({
              type: UPDATE_STOCK,
              payload: res.data.product,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.respose.data.message,
              icon: "error",
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    });
  };
  //funcion para el index de movimientos
  const indexMovements = (params) => {
    sessionStorage.removeItem("movement");

    let url = `/products-movements?limit=${params.limite}&page=${params.pages}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_MOVEMENTS,
          payload: {
            movements: res.data.movements,
            totalDocs: res.data.totalDocs,
            limit: res.data.limit,
            page: res.data.page,
            paginCounter: res.data.paginCounter,
            hasPrevPage: res.data.hasPrevPage,
            hasNextPage: res.data.hasNextPage,
            prevPage: res.data.prevPage,
            nextPage: res.data.nextPage,
            totalPages: res.data.totalPages,
          },
        });
      })
      .catch((error) => {
        toast.error(
          <div>
            <p>Error: {error.response.data.message}</p>
          </div>,
          {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
          }
        );
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.message,
        });
      });
  };
  //pdf de movimientos
  const PdfMovements = (data) => {
    Swal.fire({
      title: "Descargar archivo ",
      text: "Se descargará, el reporte de movimientos de stock",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, descargar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        // const formData = new FormData();
        // formData.append("initialDate", data.initialDate);
        // formData.append("finishDate", data.finishDate);
        let url = `/products-movements/generate/report?initialDate=${data.initialDate}&finishDate=${data.finishDate}`;

        MethodGet(url, { responseType: "blob" })
          .then((res) => {
            fileDownload(res.data, "movimientos_stock.pdf");
            Swal.fire({
              title: "Descargado",
              text: "Se ha descargado, el reporte de movimientos de stock",
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
            });
            dispatch({
              type: SHOW_ERRORS_API,
            });
          });
      }
    });
  };
  //reporte de stock
  const excelStockInfo = (data) => {
    Swal.fire({
      title: "Descargar archivo ",
      text: "Se descargará, el archivo de stock",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, descargar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/products/running-out/stock`;
        MethodGet(url, { responseType: "blob" })
          .then((res) => {
            let timerInterval;
            Swal.fire({
              title: "Generando",
              html: "El reporte se esta generando!",
              timer: 2000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const b = Swal.getHtmlContainer().querySelector("b");
                timerInterval = setInterval(() => {
                  b.textContent = Swal.getTimerLeft();
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              fileDownload(res.data, "stock.xlsx");
              Swal.fire({
                title: "Descargado",
                text: "Se ha descargado, el reporte de stock",
                icon: "success",
                timer: 1500,
                showConfirmButton: false,
              });
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
            });
            dispatch({
              type: SHOW_ERRORS_API,
            });
          });
      }
    });
  };
  //funcion para selleccionar un producto
  const SaveCurrentProduct = (product) => {
    sessionStorage.setItem("product", JSON.stringify(product));
    dispatch({
      type: CURRENT_PRODUCTS,
      payload: product,
    });
  };

  const getProductDesactivated = (id) => {
    sessionStorage.removeItem("product");

    dispatch({
      type: GET_PRODUCT_DESACTIVATED,
      payload: id,
    });
  };
  /**Entradas */
  const createEnterProducts = (data) => {
    let url = "/entries";
    MethodPost(url, data)
      .then((res) => {
        dispatch({
          type: CREATE_ENTER_PRODUCTS,
          payload: res.data.entrie,
        });
        Swal.fire({
          title: "Creada",
          text: res.data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
      });
  };
  /**Get Enter PRODUCTS */
  const GetEnterProducts = (params) => {
    //sessionStorage.removeItem("product");
    //params.pages += 1;
    let url = `/entries?limit=${params.limite}&page=${params.pages}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ENTER_PRODUCTS,
          payload: {
            entries: res.data.entries,
            totalDocs: res.data.totalDocs,
            limit: res.data.limit,
            page: res.data.page,
            paginCounter: res.data.paginCounter,
            hasPrevPage: res.data.hasPrevPage,
            hasNextPage: res.data.hasNextPage,
            prevPage: res.data.prevPage,
            nextPage: res.data.nextPage,
            totalPages: res.data.totalPages,
          },
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          text: error.response.data.message,
          //icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.message,
        });
      });
  };
  /**descargar pdf */
  const DownloadPdfEnterProducts = (data) => {
    Swal.fire({
      title: "¿Descargar PDF?",
      text: "Se descargará el reporte de entradas en PDF",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, descargar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/entries/export-pdf/${data._id}`;
        MethodGet(url, { responseType: "blob" })
          .then((res) => {
            let timerInterval;
            Swal.fire({
              title: "Generando",
              html: "El PDF se esta generando!",
              timer: 2000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const b = Swal.getHtmlContainer().querySelector("b");
                timerInterval = setInterval(() => {
                  b.textContent = Swal.getTimerLeft();
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              fileDownload(res.data, `Reporte_entradas_${data.num_folio}.pdf`);
              Swal.fire({
                title: "Descargado",
                text: "El PDF de las entradas se ha descargado correctamente",
                icon: "success",
                timer: 1500,
                showConfirmButton: false,
              });
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              icon: "error",
              text: error.response.data.message,
            });
            dispatch({
              type: SHOW_ERRORS_API,
            });
          });
      }
    });
  };
  /**PDF DE STOCK ACTUALIZADO */
  const DownloadReportStockActual = () => {
    Swal.fire({
      title: "Descargar PDF",
      text: "Se descargara un reporte de stock actual",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, descargar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/products/stockActualPdf`;
        MethodGet(url, { responseType: "blob" })
          .then((res) => {
            let timerInterval;
            Swal.fire({
              title: "Generando",
              html: "El PDF se esta generando!",
              timer: 2000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const b = Swal.getHtmlContainer().querySelector("b");
                timerInterval = setInterval(() => {
                  b.textContent = Swal.getTimerLeft();
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              fileDownload(res.data, `StockActual.pdf`);
              Swal.fire({
                title: "Descargado",
                text: "El PDF de las entradas se ha descargado correctamente",
                icon: "success",
                timer: 1500,
                showConfirmButton: false,
              });
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              icon: "error",
              text: error.response.data.message,
            });
            dispatch({
              type: SHOW_ERRORS_API,
            });
          });
      }
    });
  };
  /**Descargar stock de products excel true || false */
  const ExportAllProductsStockExcel = () => {
    let url = "/products/export-all-products/excel";
    Swal.fire({
      title: "Generando...!",
      showConfirmButton: false,
      showLoading: true,
    });
    Swal.showLoading();
    MethodGet(url, { responseType: "blob" })
      .then((res) => {
        dispatch({
          type: EXPORT_ALL_PRODUCTS_STOCK_EXCEL,
          payload: res.data,
        });
        fileDownload(res.data, `StockActual.xlsx`);
        Swal.fire({
          title: "Descargado",
          text: "Se ha descargado correctamente el archivo",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
      });
  };
  return (
    <ProductsContext.Provider
      value={{
        products: state.products,
        movements: state.movements,
        movement: state.movement,
        product: state.product,
        ErrorsApi: state.ErrorsApi,
        success: state.success,
        totalDocs: state.totalDocs,
        limit: state.limit,
        page: state.page,
        paginCounter: state.paginCounter,
        hasPrevPage: state.hasPrevPage,
        prevPage: state.prevPage,
        hasNextPage: state.hasNextPage,
        nextPage: state.nextPage,
        loading: state.loading,
        totalPages: state.totalPages,
        current_product: state.current_product,
        success_update: state.success_update,
        search_products_movements: state.search_products_movements,
        entries: state.entries,
        GetProducts,
        AddProducts,
        UpdateProduct,
        DeleteProduct,
        SaveCurrentProduct,
        GetProductsDesactivated,
        getProductDesactivated,
        RestoreProducts,
        UploadMultimedia,
        productsSelect,
        ImportProductsExcel,
        ExportProductsExcel,
        ExportUpdateProductsExcel,
        UpdateProductsExcel,
        GetProductsCanvas,
        ExportProductsMostSold,
        ExportOrdersExcel,
        searchProduct,
        searchProductDesactivated,
        UpdateStock,
        indexMovements,
        PdfMovements,
        searchProductMovement,
        GetOneProduct,
        excelStockInfo,
        createEnterProducts,
        GetEnterProducts,
        DownloadPdfEnterProducts,
        GetAllProductsSelect,
        ExportProductsLessSold,
        DownloadReportStockActual,
        ExportAllProductsStockExcel,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export default ProductsState;
