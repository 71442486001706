import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Grid } from "@mui/material";
import { useState } from "react";
import Swal from "sweetalert2";
import { useEffect } from "react";
import MethodGet from "../../config/Service";
import TableArray from "./TableArray";
import NoDataComponents from "../../components/loading/NoDataComponents";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function TableGlobalInvoice({ orders, paymentMethod }) {
  const [globalInvoice, saveGlobalInvoice] = useState([]);

  const AgregarProductoState = (id, folio, total) => {
    var exist = globalInvoice.filter((p) => p._id === id);
    if (exist.length) {
      Swal.fire({
        title: "No permitido",
        icon: "error",
        text: "Esa venta ya ha sido agregada",
        timer: 2000,
        showConfirmButton: false,
      });
      return;
    }
    let newOrderInvoice = {
      _id: id,
      folio,
      total,
    };
    saveGlobalInvoice([...globalInvoice, newOrderInvoice]);
    Swal.fire({
      title: "Agregado",
      icon: "success",
      text: "el producto se agrego a la venta correctamente!",
      timer: 1000,
      showConfirmButton: false,
    });
  };
  const totals = globalInvoice.map((g) => g.total);
  const initialValue = 0;
  const totalFinal = totals.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    initialValue
  );
  return (
    <Grid container spacing={2}>
      {orders.length > 0 ? (
        <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Folio Venta</StyledTableCell>
                  <StyledTableCell align="center">Cliente</StyledTableCell>
                  <StyledTableCell align="center">Total venta</StyledTableCell>
                  <StyledTableCell align="center">
                    Gastos de Envio
                  </StyledTableCell>
                  <StyledTableCell align="center">Opciones</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="center">
                      {row.folio}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.client}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.total}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.envio}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() =>
                          AgregarProductoState(row._id, row.folio, row.total)
                        }>
                        Agregar
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      ) : (
        <NoDataComponents />
      )}
      {globalInvoice.length > 0 && (
        <Grid item xs={3} md={3} lg={3} xl={3}>
          <TableArray
            globalInvoice={globalInvoice}
            saveGlobalInvoice={saveGlobalInvoice}
            paymentMethod={paymentMethod}
            totalFinal={totalFinal}
          />
        </Grid>
      )}
    </Grid>
  );
}
