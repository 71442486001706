import { Button, Grid } from "@mui/material";
import React, { useContext, useState } from "react";
import SelectClients from "../../SelectOptions/SelectClients";
import SelectDirectionsUser from "../../../components/Dashboard/UserDistribuidor/SelectDirections";
import AccountsSelect from "../../SelectOptions/AccountsSelect";
import SalesContext from "../../../context/Sales/SalesContext";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";
const SelectOptions = ({
  saveClient,
  saveAccount,
  saveClientDirection,
  client,
  clientDirection,
  account,
  shippingCost,
  total,
  subtotal,
  businessRule,
  discount,
  orderProducts,
  handleResetSale,
}) => {
  const history = useHistory();
  const detectarCambiosClient = (value) => {
    saveClient(value.value);
  };
  const [nameDirection, saveNameDirection] = useState("");
  const detectarCambiosDirection = (value) => {
    saveClientDirection(value.value);
    saveNameDirection(value.name);
  };

  const detectarCambiosAccount = (value) => {
    saveAccount(value.value);
  };
  const { completeOrderExcel, statusOrder } = useContext(SalesContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const onSubmit = (data) => {
    data.total = total;
    data.subtotal = subtotal;
    data.discount = discount;
    data.businessRule = businessRule;
    data.client = client;
    data.direction = clientDirection;
    data.shipping_cost = shippingCost;
    data.orderProducts = orderProducts;
    data.account = account;
    completeOrderExcel(data);
  };

  useEffect(() => {
    if (statusOrder !== null) {
      history.push("/ventas");
    }
  });

  return (
    <Grid container spacing={2} sx={{ padding: 2 }}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <label>Selecciona un cliente: </label>
        <SelectClients detectarCambiosClient={detectarCambiosClient} />
      </Grid>
      {client !== null && (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <label>Selecciona una dirección</label>
          <SelectDirectionsUser
            detectarCambiosDirection={detectarCambiosDirection}
            id_client={client}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <label>Selecciona una cuenta bancaria:</label>
        <AccountsSelect detectarCambiosAccount={detectarCambiosAccount} />
      </Grid>
      {client && clientDirection && account && (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter")
                e.preventDefault();
            }}
            autoComplete="off"
          >
            <Button
              fullWidth
              type="submit"
              variant="contained"
              size="large"
              sx={{
                background: "#e91e63",
                "&:hover": { background: "#e91e63" },
              }}
            >
              Guardar venta
            </Button>
          </form>
        </Grid>
      )}
    </Grid>
  );
};

export default SelectOptions;
