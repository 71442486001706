import React from "react";
import PermissionCountriesReducer from "./PermissionCountriesReducer";
import PermissionCountriesContext from "./PermissionCountriesContext";
import { useReducer } from "react";
import MethodGet, {
  MethodDelete,
  MethodPost,
  MethodPut,
} from "../../config/Service";
import {
  ADD_COUNTRY,
  CURRENT_COUNTRY,
  DELETE_COUNTRY,
  GET_ALL_COUNTRIES,
  SHOW_ERRORS_API,
  UPDATE_COUNTRY,
  UPDATE_FILES,
} from "../../types";
import Swal from "sweetalert2";
import headerConfig from "../../config/imageHeaders";

const PermissionCountriesState = ({ children }) => {
  //estado inicial
  const initialState = {
    countries: [],
    country: null,
    ErrorsApi: [],
    success: false,
  };

  const [state, dispatch] = useReducer(
    PermissionCountriesReducer,
    initialState
  );

  //obtener la lista de paises
  const GetCountries = () => {
    sessionStorage.removeItem("country");
    let url = "/country-permissions";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_COUNTRIES,
          payload: res.data.countries,
        });
      })
      .catch((error) => {
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.message,
        });
      });
  };

  //funcion para agregar paises
  const AddCountries = (country) => {
    const formData = new FormData();
    formData.append("country", country.country);
    formData.append("image", country.image);
    //formData.append("attachments", country.file);
    let url = "/country-permissions";
    MethodPost(url, formData, { headerConfig })
      .then((res) => {
        console.log(res.data.country, "la respuesta");
        let id = res.data.country._id;
        const form = new FormData();
        form.append("file_first", country.attachments[0]);
        form.append("file_second", country.attachments[1]);
        form.append("file_third", country.attachments[2]);
        form.append("file_fourth", country.attachments[3]);
        form.append("file_fifth", country.attachments[4]);

        let url = `/country-permissions/file-upload/${id}`;
        MethodPost(url, form, { headerConfig })
          .then((res) => {
            Swal.fire({
              title: "Pais registrado",
              text: res.data.message,
              icon: "success",
              timer: 1500,
              showCancelButton: false,
              showConfirmButton: false,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
            });
          });
        dispatch({
          type: ADD_COUNTRY,
        });
      })
      .catch((error) => {
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.nessage,
        });
      });
    // MethodPost(url, formData, { headerConfig })
    // 	.then((res) => {
    // 		Swal.fire({
    // 			title: "Registrado",
    // 			text: "El país se registro correctamente",
    // 			icon: "success",
    // 			timer: 1500,
    // 			showConfirmButton: false,
    // 		});
    // 		dispatch({
    // 			type: ADD_COUNTRY,
    // 		});
    // 	})
    // 	.catch((error) => {
    // 		Swal.fire({
    // 			title: "Error",
    // 			text: error.response.data.message,
    // 			showConfirmButton: false,
    // 			timer: 2000,
    // 			icon: "error",
    // 		});
    // 		dispatch({
    // 			type: SHOW_ERRORS_API,
    // 			payload: error.response.data.message,
    // 		});
    // 	});
  };

  //funcion para editar
  const UpdateCountry = (country) => {
    const formData = new FormData();
    formData.append("country", country.country);
    formData.append("image", country.image);
    //formData.append("attachments", country.attachments);

    let url = `/country-permissions/${country.id}`;
    MethodPut(url, formData, { headerConfig })
      .then((res) => {
        Swal.fire({
          title: "País Actualizado",
          text: res.data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch({
          type: UPDATE_COUNTRY,
        });
      })
      .catch((error) => {
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.error,
        });
      });
  };

  const UpdateFilesCountry = (country) => {
    const formData = new FormData();

    if (country.file_first && country.file_first_id) {
      formData.append("file_first", country.file_first);
      formData.append("file_first_id", country.file_first_id);
    }
    if (country.file_second && country.file_second_id) {
      formData.append("file_second", country.file_second);
      formData.append("file_second_id", country.file_second_id);
    }
    if (country.file_third && country.file_third_id) {
      formData.append("file_third", country.file_third);
      formData.append("file_third_id", country.file_third_id);
    }
    if (country.file_fourth && country.file_fourth_id) {
      formData.append("file_fourth", country.file_fourth);
      formData.append("file_fourth_id", country.file_fourth_id);
    }
    if (country.file_fifth && country.file_fifth_id) {
      formData.append("file_fifth", country.file_fifth);
      formData.append("file_fifth_id", country.file_fifth_id);
    }

    let url = `/country-permissions/file-upload/${country.id}`;
    MethodPut(url, formData, { headerConfig })
      .then((res) => {
        Swal.fire({
          title: "Archivos Actualizados",
          text: res.data.message,
          icon: "success",
          timer: 1500,
          showCancelButton: false,
          showConfirmButton: false,
        });
        dispatch({
          type: UPDATE_FILES,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.message,
        });
      });
  };

  const DeleteCountry = (id) => {
    Swal.fire({
      title: "¿Estas Seguro?",
      text: "La marca seleccionada sera eliminada",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/country-permissions/${id}`;
        MethodDelete(url)
          .then((res) => {
            Swal.fire({
              title: "Eliminado",
              text: res.data.message,
              icon: "success",
              timer: 2000,
              showConfirmButton: false,
            });
            dispatch({
              type: DELETE_COUNTRY,
              payload: id,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
    });
  };
  const saveCurrentCountry = (country) => {
    sessionStorage.setItem("country", JSON.stringify(country));
    dispatch({
      type: CURRENT_COUNTRY,
      payload: country,
    });
  };
  return (
    <PermissionCountriesContext.Provider
      value={{
        countries: state.countries,
        country: state.country,
        ErrorsApi: state.ErrorsApi,
        success: state.success,
        GetCountries,
        AddCountries,
        UpdateCountry,
        DeleteCountry,
        saveCurrentCountry,
        UpdateFilesCountry,
      }}>
      {children}
    </PermissionCountriesContext.Provider>
  );
};

export default PermissionCountriesState;
