import Grid from "@mui/material/Grid";
import Layout from "../../components/layout/Layout";
import {
  Button,
  TextField,
  Radio,
  FormControlLabel,
  Paper,
  Typography,
} from "@mui/material";
import SelectTypeExit from "../SelectOptions/SelectTypeExit";
import ComponentsBarcode from "./ComponentsBarcode";
import ProductsList from "./ProductsList";
import { useForm } from "react-hook-form";
import ExitProductsContext from "../../context/ExitProducts/ExitProductsContext";
import { useContext, useEffect, useState } from "react";
import SelectProduct from "./SelectProduct";
export default function AddExitProducts(props) {
  const ProductsExitOnLocal = localStorage.getItem("ExitProducts");
  const [productoID, guardarProductoID] = useState("");
  const [salida, guardarSalida] = useState(
    ProductsExitOnLocal ? JSON.parse(ProductsExitOnLocal) : []
  );
  const [exit, setExit] = useState(false);
  const [index, saveIndex] = useState();
  const [selectedValueRadio, setSelectedValueRadio] = useState("barcode");
  const [typeExit, saveTypeExit] = useState(null);
  const detectarCambiosTypeExit = (e) => {
    saveTypeExit(e.target.value);
  };
  const handleChangeRadio = (event) => {
    setSelectedValueRadio(event.target.value);
  };
  const { CreateDeparture, success } = useContext(ExitProductsContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  useEffect(() => {
    if (success) {
      props.history.push("/salidas");
    }
  }, [success]);

  const onSubmit = (data, e) => {
    if (typeExit === 1) {
      data.typeExit = "Salida Prestamo de productos";
    }
    if (typeExit === 2) {
      data.typeExit = "Salida WOS";
    }
    if (typeExit === 3) {
      data.typeExit = "Salida Productos por venta";
    }
    data.products = salida.map((p) => {
      let attribute = {
        product_id: p.product_id,
        quantity: p.quantity,
      };
      return attribute;
    });
    CreateDeparture(data);
    localStorage.removeItem("ExitProducts");
  };

  return (
    <Layout>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.code === "Enter" || e.code === "NumpadEnter")
            e.preventDefault();
        }}
        autoComplete="off"
      >
        <Paper elevation={3} sx={{ margin: 2, marginTop: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
              <Typography
                fontWeight="bold"
                fontFamily="monospace"
                align="center"
                variant="h3"
              >
                Generar salida de productos
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{ marginTop: 2 }}>
              <Button type="submit" fullwidth variant="contained" size="large">
                Generar Salida
              </Button>
            </Grid>
            <Grid container spacing={2} sx={{ margin: 2 }}>
              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                <SelectTypeExit
                  detectarCambiosTypeExit={detectarCambiosTypeExit}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                <TextField
                  fullWidth
                  label="¿Quien autoriza?"
                  name="authotizing_client"
                  variant="outlined"
                  error={errors.authotizing_client ? true : false}
                  helperText={errors?.authotizing_client?.message}
                  {...register("authotizing_client", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                <TextField
                  fullWidth
                  label="¿Quien recibe?"
                  name="receivingCustomer"
                  variant="outlined"
                  error={errors.receivingCustomer ? true : false}
                  helperText={errors?.receivingCustomer?.message}
                  {...register("receivingCustomer", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <TextField
                  fullWidth
                  label="Descuento"
                  name="discount"
                  type="number"
                  placeholder="Escribe el % de descuento si aplica"
                  variant="outlined"
                  error={errors.discount ? true : false}
                  helperText={errors?.discount?.message}
                  {...register("discount", {
                    required: {
                      value: false,
                      message: "Este campo es requerido",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <TextField
                  fullWidth
                  label="Comentario"
                  variant="outlined"
                  name="comment"
                  multiline
                  placeholder="Agrega un breve comentario de la salida"
                  minRows={1}
                  error={errors.comment ? true : false}
                  helperText={errors?.comment?.message}
                  {...register("comment", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                  })}
                />
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              display="flex"
              justifyContent="center"
            >
              <label style={{ marginTop: 7 }}>Codigo de barras</label>
              <Radio
                checked={selectedValueRadio === "barcode"}
                onChange={handleChangeRadio}
                value="barcode"
                name="radio-buttons"
                inputProps={{ "aria-label": "A" }}
              />
              <label style={{ marginTop: 7 }}>Seleccionar Producto</label>
              <Radio
                checked={selectedValueRadio === "select"}
                onChange={handleChangeRadio}
                value="select"
                name="radio-buttons"
                inputProps={{ "aria-label": "B" }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ margin: 2 }}
              display="flex"
              justifyContent="center"
            >
              {selectedValueRadio === "barcode" ? (
                <ComponentsBarcode
                  guardarProductoID={guardarProductoID}
                  salida={salida}
                  guardarSalida={guardarSalida}
                  index={index}
                />
              ) : (
                <SelectProduct
                  guardarProductoID={guardarProductoID}
                  salida={salida}
                  guardarSalida={guardarSalida}
                  index={index}
                />
              )}
            </Grid>
            {salida && (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ margin: 2 }}
              >
                <ProductsList salida={salida} guardarSalida={guardarSalida} />
              </Grid>
            )}
          </Grid>
        </Paper>
      </form>
    </Layout>
  );
}
