import { Button, Grid } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ProductsContext from "../../../context/Products/ProductsContext";
import { useContext, useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import SpinnerComponent from "../../../components/loading/SpinnerComponent";
import { Paginate } from "../../../components/Pagination/Paginate";
export default function StockProducts() {
  const { GetProducts, products, totalPages, excelStockInfo } =
    useContext(ProductsContext);
  //paginacion
  var [pages, setPage] = useState(1);
  //const rowsPerPage = 50;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const limite = 50;
  const params = { limite, pages };
  useEffect(() => {
    GetProducts(params);
  }, [limite, pages]);
  return (
    <Grid container spacing={2}>
      {products.length && (
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          display="flex"
          justifyContent="end"
        >
          <Button
            variant="contained"
            onClick={() => excelStockInfo()}
            sx={{
              backgroundColor: "#0d47a1",
              marginRight: 2,
              "&:hover": { backgroundColor: "#0d47a1" },
            }}
          >
            Descargar Reporte
          </Button>
        </Grid>
      )}
      {!products ? (
        <SpinnerComponent />
      ) : (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ margin: 5 }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" fontWeight="bold">
                    Codigo de barras
                  </TableCell>
                  <TableCell align="center" fontWeight="bold">
                    Nombre
                  </TableCell>
                  <TableCell align="center" fontWeight="bold">
                    Marca
                  </TableCell>
                  <TableCell align="center" fontWeight="bold">
                    Stock
                  </TableCell>
                  <TableCell align="center" fontWeight="bold">
                    Alerta
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" align="center">
                      {row.barcode}
                    </TableCell>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row.brand.name}</TableCell>
                    <TableCell align="center">{row.quantity}</TableCell>
                    <TableCell align="center">
                      {row.quantity <= 9 ? (
                        <Alert
                          severity="error"
                          variant="filled"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                            marginLeft: 5,
                            width: "auto",
                          }}
                        >
                          <AlertTitle>Producto Agotado</AlertTitle>
                        </Alert>
                      ) : row.quantity <= 29 ? (
                        <Alert
                          severity="warning"
                          variant="filled"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                            marginLeft: 5,
                            width: "auto",
                          }}
                        >
                          <AlertTitle>Producto por agotarse</AlertTitle>
                        </Alert>
                      ) : (
                        row.quantity >= 30 && (
                          <Alert
                            severity="success"
                            variant="filled"
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              textAlign: "center",
                              marginLeft: 5,
                              width: "auto",
                            }}
                          >
                            <AlertTitle>
                              Producto con suficiente stock
                            </AlertTitle>
                          </Alert>
                        )
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
      {products.length && (
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          display="flex"
          justifyContent="center"
        >
          <Paginate
            pages={pages}
            handleChangePage={handleChangePage}
            totalPages={totalPages}
          />
        </Grid>
      )}
    </Grid>
  );
}
