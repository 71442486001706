import React, { useReducer } from "react";
import Swal from "sweetalert2";
import MethodGet, {
  MethodDelete,
  MethodPost,
  MethodPut,
} from "../../config/Service";
import {
  ADD_SLIDER,
  CURRENT_SLIDER,
  DELETE_SLIDER,
  GET_ALL_SLIDERS,
  SHOW_ERRORS_API,
  UPDATE_SLIDER,
} from "../../types";
import SliderEcommerceReducer from "./SliderEcommerceReducer";
import SliderEcommerceContext from "./SliderEcommerceContext";
import HeadersConfig from "../../config/imageHeaders";

const SliderEcommerceState = ({ children }) => {
  //estado inicial
  const initialState = {
    sliders: [],
    slider: null,
    ErrorsApi: [],
    success: false,
  };
  //dispath que ejecuta las acciones
  const [state, dispatch] = useReducer(SliderEcommerceReducer, initialState);

  //obtener la lista de sliders
  const GetSliders = () => {
    sessionStorage.removeItem("slider");
    let url = `/administrable/slider`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_SLIDERS,
          payload: res.data.sliders.slider,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
      });
  };
  const AddSlider = (slider) => {
    const formData = new FormData();
    formData.append("text", slider.text);
    formData.append("short_text", slider.short_text);
    formData.append("imageWeb", slider.imageWeb);
    formData.append("imageMobile", slider.imageMobile);
    let url = `/administrable/slider/`;
    Swal.fire({
      title: "Subiendo Imagenes...!",
      showConfirmButton: false,
      showLoading: true,
    });
    Swal.showLoading();
    MethodPost(url, formData, { HeadersConfig })
      .then((res) => {
        Swal.fire({
          title: "Slider creado",
          text: res.data.message,
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
          showCancelButton: false,
        });
        dispatch({
          type: ADD_SLIDER,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.message,
        });
      });
  };

  //funcion para editar slider
  const UpdateSlider = (slider) => {
    const formData = new FormData();
    formData.append("text", slider.text);
    formData.append("short_text", slider.short_text);
    formData.append("image", slider.image);

    let url = `/administrable/slider/${slider.id}`;
    MethodPut(url, slider)
      .then((res) => {
        Swal.fire({
          title: "Slider Actualizado",
          text: res.data.message,
          timer: 3000,
          showCancelButton: false,
          icon: "success",
        });
        dispatch({
          type: UPDATE_SLIDER,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          timer: 2000,
          showConfirmButton: false,
          icon: "error",
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.res.data.error,
        });
      });
  };

  //eliminar slider
  const DeleteSlider = (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "El Slider sera eliminado",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/administrable/slider/${id}`;

        MethodDelete(url)
          .then((res) => {
            Swal.fire({
              title: "Eliminado",
              text: res.data.message,
              icon: "success",
              timer: 2000,
              showConfirmButton: false,
            });

            dispatch({
              type: DELETE_SLIDER,
              payload: id,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              timer: 2000,
              showConfirmButton: false,
            });
          });
      }
    });
  };

  //funcion para seleccionar un slider
  const SaveCurrentSlider = (slider) => {
    sessionStorage.setItem("slider", JSON.stringify(slider));
    dispatch({
      type: CURRENT_SLIDER,
      payload: slider,
    });
  };

  return (
    <SliderEcommerceContext.Provider
      value={{
        sliders: state.sliders,
        slider: state.slider,
        ErrorsApi: state.ErrorsApi,
        success: state.success,
        GetSliders,
        AddSlider,
        UpdateSlider,
        DeleteSlider,
        SaveCurrentSlider,
      }}
    >
      {children}
    </SliderEcommerceContext.Provider>
  );
};

export default SliderEcommerceState;
