import { Button, Grid, Tooltip, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { makeStyles } from "@mui/styles";
import SpinnerComponent from "../../../components/loading/SpinnerComponent";
const useStyles = makeStyles({
  img: {
    height: 120,
    width: 120,
  },
  jss59: {
    width: "50%",
    border: "1px solid #bbb",
    height: "15%",
    outline: "none",
    padding: "15px 10px",
    marginLeft: "10px",
    borderRadius: "10px",
  },
});
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ProductsOnOrder = ({
  currentProducts,
  deleteProducts,
  saveDeleteProducts,
  saveCurrentProducts,
  updateQuantityProducts,
  saveUpdateQuantityProducts,
  businessRule,
  currentTotal,
  saveCurrentTotal,
  newTotal,
  saveNewTotal,
  shippingCost,
}) => {
  const classes = useStyles();
  //funcion para eliminar productos de la orden actual
  const EliminarProductOrder = (id) => {
    let productoResultado = {};
    productoResultado.product_id = id;
    saveDeleteProducts([...deleteProducts, productoResultado]);
    const todosProducto = currentProducts.filter((p) => p.product._id !== id);
    saveCurrentProducts(todosProducto);
    Swal.fire({
      title: "Eliminado",
      text: "El producto se ha eliminado de la orden",
      icon: "success",
      timer: 1000,
      showConfirmButton: false,
    });
  };
  //funcion para actualizar cantidades de los productos actuales
  const ActualizarCantidadProducts = (e, i) => {
    const todosProducto = [...currentProducts];
    const newQuantity = Number(e.target.value);

    // if (newQuantity > todosProducto[i].product_id.quantity) {
    //   Swal.fire({
    //     title: "Error",
    //     text:
    //       "La nueva cantidad, no puede ser mayor al stock disponible!, Unicamente hay " +
    //       todosProducto[i].product_id.quantity +
    //       " Disponibles",
    //     icon: "error",
    //     timer: 1500,
    //     showConfirmButton: false,
    //   });
    //   return;
    // }
    let productoResultado = {};
    productoResultado.product_id = todosProducto[i].product._id;
    productoResultado.quantity = newQuantity;
    todosProducto[i].quantity = newQuantity;
    //todosProducto[i].new_quantity = newQuantity;
    todosProducto[i].total =
      todosProducto[i].product.discount > 0
        ? productoResultado.quantity *
          todosProducto[i].product.price *
          (1 - todosProducto[i].product.discount / 100).toFixed(2)
        : businessRule
        ? Number(todosProducto[i].product.price) *
            Number(todosProducto[i].quantity) -
          Number(
            todosProducto[i].product.price *
              todosProducto[i].quantity *
              businessRule.discount
          ) /
            100
        : todosProducto[i].quantity * todosProducto[i].product.price;

    //saveCurrentProducts(todosProducto);
    const data = todosProducto.map((p) => {
      let attribute = {
        product_id: p.product._id,
        quantity: p.quantity,
      };
      return attribute;
    });
    saveUpdateQuantityProducts(data);
  };

  /**suma de subtotal del arreglo de productos */
  const subtotals = currentProducts.map((p) => p.product.price * p.quantity);
  const initialValue = 0;
  const sumaSubtotal = subtotals.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    initialValue
  );
  /**suma del total de arreglo de productos */
  const totals = currentProducts.map((p) =>
    p.product.discount > 0
      ? p.quantity * p.product.price * (1 - p.product.discount / 100).toFixed(2)
      : businessRule
      ? p.quantity * p.product.price -
        (p.quantity * p.product.price * businessRule.discount) / 100
      : p.quantity * p.product.price
  );
  const initialValueTotal = 0;
  const sumaTotal = totals.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    initialValueTotal
  );
  saveCurrentTotal(sumaTotal);

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{ boxShadow: 8, margin: 2 }}
      >
        <Typography textAlign="center">
          Productos actuales en la orden
        </Typography>
        <TableContainer sx={{ padding: 1 }}>
          <div style={{ height: "330px", overflowY: "auto" }}>
            {currentProducts.length ? (
              <>
                <Table sx={{ marginBottom: 2 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">producto</StyledTableCell>
                      <StyledTableCell align="left">cantidad</StyledTableCell>
                      <StyledTableCell align="center">
                        Precio U.
                      </StyledTableCell>
                      <StyledTableCell align="center">Subtotal</StyledTableCell>
                      <StyledTableCell align="center">Total</StyledTableCell>
                      <StyledTableCell align="center">Opciones</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentProducts.map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="left">
                          {row.product.name}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <input
                            type="number"
                            step={1}
                            className={classes.jss59}
                            placeholder="Agrega la Cantidad Inicial"
                            name="quantityProduct"
                            min={1}
                            defaultValue={row.quantity}
                            onChange={(e) =>
                              ActualizarCantidadProducts(e, index)
                            }
                            onWheel={(e) => e.target.blur()}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          $ {row.product.price}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          $ {row.product.price * row.quantity}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          ${" "}
                          {row.product.discount > 0
                            ? row.quantity *
                              row.product.price *
                              (1 - row.product.discount / 100).toFixed(2)
                            : businessRule
                            ? row.quantity * row.product.price -
                              (row.quantity *
                                row.product.price *
                                businessRule.discount) /
                                100
                            : row.quantity * row.product.price}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Tooltip title="Eliminar producto" placement="top">
                            <Button
                              onClick={() =>
                                EliminarProductOrder(row.product_id._id)
                              }
                            >
                              <DeleteIcon sx={{ color: "red" }} />
                            </Button>
                          </Tooltip>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <Typography fontSize="bold" fontFamily="monospace">
                        Subtotal
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      $ {Number(sumaSubtotal)}
                    </TableCell>
                  </TableRow>
                  {businessRule && (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        <Typography fontSize="bold" fontFamily="monospace">
                          Descuento
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {businessRule.discount} %
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <Typography fontSize="bold" fontFamily="monospace">
                        Total
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      $ {Number(sumaTotal).toFixed(2)}
                    </TableCell>
                  </TableRow>
                </Table>
              </>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12} display="flex" justifyContent="center">
                  <SpinnerComponent />
                </Grid>
              </Grid>
            )}
          </div>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default ProductsOnOrder;
