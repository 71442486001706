import React, { useContext, useState } from "react";
import SalesContext from "../../../context/Sales/SalesContext";
import formatDate from "../../../utils/FormatDate";
import { Link } from "react-router-dom";
import Marquee from "react-fast-marquee";
import ArticleIcon from "@mui/icons-material/Article";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import WarehouseContext from "../../../context/Warehouse/WarehouseContext";
import AddToHomeScreenIcon from "@mui/icons-material/AddToHomeScreen";
import BlockIcon from "@mui/icons-material/Block";
import {
  Box,
  Card,
  Typography,
  Tooltip,
  Menu,
  MenuItem,
  IconButton,
  Divider,
  Grid,
  FormControlLabel,
  Checkbox,
  Button,
  Alert,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { purple } from "@mui/material/colors";
import { withStyles } from "@mui/styles";
import { EditIcon } from "../../icons/EditIcon";
import { PriceFormat } from "../../../utils/FormatPrice";

const PurpleCheckbox = withStyles({
  root: {
    color: purple[400],
    "&$checked": {
      color: purple[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const SalesPendingCard = ({ salesPending, salesChecked, setsalesChecked }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [id_order, setIdOrder] = useState({});
  const {
    SaveCurrentSale,
    AuthorizeSale,
    EnableUpdateSalePendingPayment,
    DisableUpdateSalePendingPayment,
  } = useContext(SalesContext);
  const { PdfOrder } = useContext(WarehouseContext);
  //check
  const [actualState, changeCheckState] = useState(false);
  const handleChange = (event, salesPending) => {
    const { checked } = event.target;
    if (checked === true) {
      const SalesCheck = {
        order_id: salesPending._id,
        isChecked: true,
      };
      setsalesChecked([...salesChecked, SalesCheck]);
      changeCheckState(true);
    } else {
      const filtroaver = salesChecked.filter(
        (sale) => sale.order_id !== salesPending._id
      );

      setsalesChecked(filtroaver);
      changeCheckState(false);
    }
  };

  const SelectShowOrder = (salesPending) => {
    SaveCurrentSale(salesPending);
  };

  return (
    <Card
    // sx={
    //   salesPending.super_order
    //     ? { boxShadow: 10 }
    //     : { boxShadow: 10, border: "1px solid black" }
    // }
    >
      <Grid container spacing={2}>
        {salesPending.payments.length === 0 ? (
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            sx={{ backgroundColor: "#d32f2f", color: "white" }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                display="flex"
                justifyContent="center"
              >
                <Typography>Sin pagos</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                display="flex"
                justifyContent="center"
              >
                <div>
                  <img src={require("../../../assets/gif/no_payment.gif")} />
                </div>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          salesPending.payments.length > 0 && (
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              sx={{ backgroundColor: "#4caf50", color: "white" }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  display="flex"
                  justifyContent="center"
                >
                  <Typography>Ya tiene un pago</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  display="flex"
                  justifyContent="center"
                >
                  <div>
                    <img src={require("../../../assets/gif/one_payment.gif")} />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          )
        )}
        <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              md={12}
              xl={12}
              display="flex"
              justifyContent="center"
            >
              <Alert severity="success" icon={false}>
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  fontFamily="monospace"
                >
                  #Pedido: {salesPending.folio}
                </Typography>
              </Alert>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              md={12}
              xl={12}
              display="flex"
              justifyContent="center"
            >
              <Typography variant="h6" fontWeight="bold" fontFamily="inherit">
                Fecha compra: {salesPending.dateFormatted}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              md={12}
              xl={12}
              display="flex"
              justifyContent="center"
            >
              <Alert
                icon={false}
                severity="info"
                display="flex"
                justifyContent="center"
              >
                <Typography variant="h6" fontWeight="bold" fontFamily="inherit">
                  Cliente: {salesPending.client_id.fullname}
                </Typography>
              </Alert>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              md={12}
              xl={12}
              display="flex"
              justifyContent="center"
            >
              <Typography variant="h6" fontWeight="bold" fontFamily="inherit">
                Total Compra: $ {PriceFormat(salesPending.total)}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              md={12}
              xl={12}
              display="flex"
              justifyContent="center"
            >
              <Typography variant="h6" fontWeight="bold" fontFamily="inherit">
                Gastos de envio: $ {PriceFormat(salesPending.shippment)}
              </Typography>
            </Grid>
            {salesPending.payments.length ? (
              <Grid
                item
                xs={12}
                sm={12}
                lg={12}
                md={12}
                xl={12}
                display="flex"
                justifyContent="space-between"
              >
                <Typography variant="h6" fontWeight="bold" fontFamily="cursive">
                  aprobar:
                </Typography>

                <FormControlLabel
                  control={
                    <PurpleCheckbox
                      onChange={(event) => handleChange(event, salesPending)}
                      value={salesPending._id}
                      checked={actualState}
                      name={salesPending._id}
                    />
                  }
                  label=""
                />
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs={12} display="flex" justifyContent="space-between">
              <div>
                <Tooltip title="ver" placement="top">
                  <Link to={`/Detalle_venta/${salesPending._id}`}>
                    <IconButton>
                      <ArticleIcon sx={{ color: "#1976d2" }} />
                    </IconButton>
                  </Link>
                </Tooltip>
              </div>
              <div>
                <Tooltip title="PDF" placement="top">
                  <IconButton onClick={() => PdfOrder(salesPending)}>
                    <PictureAsPdfIcon sx={{ color: "#b71c1c" }} />
                  </IconButton>
                </Tooltip>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default SalesPendingCard;
