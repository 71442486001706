import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { useState } from "react";
import { useEffect } from "react";
import MethodGet from "../../config/Service";
import Swal from "sweetalert2";
import { Grid } from "@mui/material";
import SpinnerComponent from "../../components/loading/SpinnerComponent";
import { PriceFormat } from "../../utils/FormatPrice";
import TableProducts from "./TableProducts";
import Payments from "./Payments";
import { makeStyles } from "@mui/styles";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DetailSale({ open, handleClose, id }) {
  const [infoSale, saveInfoSale] = useState(null);
  const [cargando, spinnerCargando] = useState(false);
  useEffect(() => {
    if (id !== null) {
      let url = `/sale/${id}`;
      MethodGet(url)
        .then((res) => {
          saveInfoSale(res.data.sale);
          spinnerCargando(true);
        })
        .catch((error) => {
          Swal.fire({
            title: "Error",
            text: error.response.data.message,
            icon: "error",
            timer: 2500,
            showConfirmButton: false,
          });
          spinnerCargando(true);
        });
    } else {
      Swal.fire({
        title: "Error",
        icon: "error",
        timer: 1500,
      });
    }
  }, [id]);

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{
            position: "relative",
            color: "white",
            backgroundColor: "#000",
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Detalle de venta {""}#{infoSale?.no_folio}
            </Typography>
          </Toolbar>
        </AppBar>
        {!cargando ? (
          <SpinnerComponent />
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card sx={{ boxShadow: 10, margin: 2 }}>
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      textAlign="center"
                      sx={{ backgroundColor: "black", color: "white" }}
                    >
                      Detalle de venta
                    </Typography>
                    <Grid
                      container
                      spacing={2}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <Typography>
                          Folio Venta: # {infoSale?.no_folio}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <Typography>
                          Vendedor: {infoSale?.user_id?.fullname}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <Typography>
                          Cliente: {infoSale?.customer_id?.fullname}
                        </Typography>
                      </Grid>
                      {/* <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <Typography>Telefono: { infoSale?.customer_id?.}</Typography>
                      </Grid> */}
                      <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <Typography>
                          Subtotal: $ {PriceFormat(infoSale?.subtotal)}{" "}
                        </Typography>
                      </Grid>
                      {infoSale?.business_rule_id && (
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                          <Typography>
                            Descuento: {infoSale?.business_rule_id?.discount} %{" "}
                          </Typography>
                        </Grid>
                      )}
                      <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <Typography>
                          Total: $ {PriceFormat(infoSale?.total)}{" "}
                        </Typography>
                      </Grid>
                      {/* <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <Typography>
                          Método de pago:{" "}
                          {infoSale.type_sale === 1
                            ? "Efectivo"
                            : infoSale.type_sale === 2
                            ? "Transferencia Electronica de fondos"
                            : infoSale?.type_sale === 3 &&
                              "Tarjeta de crédito o débito"}{" "}
                        </Typography>
                      </Grid> */}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card sx={{ boxShadow: 10, margin: 2 }}>
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      textAlign="center"
                      sx={{ color: "white", backgroundColor: "black" }}
                    >
                      Productos comprados
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TableProducts
                          products={infoSale?.products}
                          total={infoSale.total}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card sx={{ boxShadow: 10, margin: 2 }}>
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      textAlign="center"
                      sx={{ color: "white", backgroundColor: "black" }}
                    >
                      Pagos de la venta
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Payments payments={infoSale?.payments} />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </>
        )}
      </Dialog>
    </div>
  );
}
