import { Button, Grid, TextField, Typography } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import Layout from "../../components/layout/Layout";
import PoliciesContext from "../../context/Policies/PoliciesContext";
import SelectTypeTerms from "../SelectOptions/SelectTypeTerms";
const AddPolicies = (props) => {
  const [RichText, setRichText] = useState();
  const handleEditorChange = (e) => {
    setRichText(e.target.getContent());
  };
  const [typeTerms, saveTypeTerm] = useState(null);

  const detectarCambiosTypeTerms = (e) => {
    saveTypeTerm(e);
  };
  const { AddPolicies } = useContext(PoliciesContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const onSubmit = (data, e) => {
    data.text = RichText;
    data.type = typeTerms;
    AddPolicies(data);
    props.history.goBack();
  };
  return (
    <Layout>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.code === "Enter" || e.code === "NumpadEnter")
            e.preventDefault();
        }}
        autoComplete="off">
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ backgroundColor: "black", color: "white" }}>
            <Typography variant="h4" fontWeight="bold">
              Registrar Politicas de privacidad
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ margin: 2 }}>
            <SelectTypeTerms
              detectarCambiosTypeTerms={detectarCambiosTypeTerms}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ margin: 2 }}>
            <TextField
              label="Titulo"
              name="title"
              fullWidth
              error={errors.title ? true : false}
              helperText={errors?.title?.message}
              {...register("title", {
                required: {
                  value: true,
                  message: "El titulo es un campo requerido",
                },
              })}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ margin: 2 }}>
            <Editor
              apiKey="4oemglfeurpxtmylg59yopah4kn16o7e3ma4ryvm44spbnlr"
              onChange={handleEditorChange}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ marginLeft: 2 }}
            display="flex"
            justifyContent="start">
            <Button variant="contained" size="large" type="submit">
              Guardar
            </Button>
          </Grid>
        </Grid>
      </form>
    </Layout>
  );
};

export default AddPolicies;
