import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IconButton, Tooltip } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import invoicesContext from "../../context/Invoices/InvoicesContext";
import { useContext } from "react";
import formatDate from "./../../utils/FormatDate";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function TableConciliations({ rows, columns }) {
  const { GetZipInvoice, GetPdfInvoice } = useContext(invoicesContext);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <StyledTableCell key={index} align="center">
                {column.name}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows &&
            rows.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell align="center">
                  {formatDate(row.date)}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.payment === "03"
                    ? "Transferencia Electronica de fondos"
                    : row.payment === "04"
                    ? "Tarjeta de Crédito"
                    : row.payment === "28" && "Tarjeta de Débito"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.organization}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Tooltip title="Descargar PDF" placement="top">
                    <IconButton onClick={() => GetPdfInvoice(row.facturapi_id)}>
                      <PictureAsPdfIcon sx={{ color: "red" }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip>
                    <IconButton onClick={() => GetZipInvoice(row.facturapi_id)}>
                      <FolderZipIcon sx={{ color: "#64b5f6" }} />
                    </IconButton>
                  </Tooltip>
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
