import React, { useContext, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CotizationsContext from "../../context/Dashboard/DashboardContext";
import { Button, IconButton, Tooltip } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { useForm } from "react-hook-form";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function CustomizedTables() {
  const { cotizations, GetCotizations, dowloadCotization } =
    useContext(CotizationsContext);
  useEffect(() => {
    GetCotizations();
  }, []);

  console.log(cotizations);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Nombre de Folio</StyledTableCell>
            <StyledTableCell align="center">Numero de Folio</StyledTableCell>
            <StyledTableCell align="center">Descuento</StyledTableCell>
            <StyledTableCell align="center">Total</StyledTableCell>
            <StyledTableCell align="center">Cotizacion</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cotizations.map((cot) => (
            <StyledTableRow key={cot.fileName}>
              <StyledTableCell component="th" scope="row">
                {cot.fileName}
              </StyledTableCell>
              <StyledTableCell align="center">{cot.n_folio}</StyledTableCell>
              <StyledTableCell align="center">{cot.discount}</StyledTableCell>
              <StyledTableCell align="center">{cot.total}</StyledTableCell>
              <StyledTableCell align="center">
                <IconButton
                  onClick={() => dowloadCotization(cot._id)}
                  color="success"
                >
                  <DownloadIcon />
                </IconButton>
                <IconButton color="primary">
                  <Tooltip title="Ver Cotizacion" placement="top">
                    <Link to={`/cotizacion/${cot._id}`}>
                      <VisibilityIcon />
                    </Link>
                  </Tooltip>
                </IconButton>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
