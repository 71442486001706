import { Button, Grid, IconButton, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import SelectModule from "../SelectOptions/SelectModule";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Swal from "sweetalert2";
import MethodGet from "../../config/Service";
import DeleteIcon from "@mui/icons-material/Delete";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const AddPermissions = ({ modules, saveModules }) => {
  /**select modulo */
  const [moduleSelect, saveModuleSelect] = useState();
  const detectarCambiosModules = (value) => {
    saveModuleSelect(value.value);
  };
  const [product, saveProduct] = useState();
  useEffect(() => {
    if (moduleSelect !== "") {
      let url = `/permissions/modules/${moduleSelect}`;
      MethodGet(url)
        .then((res) => {
          saveProduct(res.data.module);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [moduleSelect]);

  //agregar modulos
  const AgregarModuloState = () => {
    let productoResultado = {};
    const id = productoResultado._id;
    productoResultado.module_id = product;
    productoResultado.name = product.name;
    productoResultado.permissions = {
      write: true,
      update: true,
      delete: true,
    };

    let exist = modules.filter(
      (module) => module.module_id.name === product.name
    );
    if (exist.length) {
      Swal.fire({
        title: "No permitido",
        icon: "error",
        text: "El modulo que intentas agregar, ya ha sido agregado, modifica los permisos",
        timer: 2000,
        showConfirmButton: false,
      });
      return;
    }
    saveModules([...modules, productoResultado]);
    Swal.fire({
      title: "Agregado",
      text: "Modulo Agregado",
      icon: "success",
      timer: 1500,
      showConfirmButton: false,
    });
  };
  //el actualizar de permisos
  const handleChangePermissionsWrite = (e, i) => {
    let newPermissionWrite = null;
    if (e.target.value === "si" || e.target.value === "SI") {
      newPermissionWrite = true;
    } else {
      newPermissionWrite = false;
    }
    const todosModulos = [...modules];
    todosModulos[i].permissions.write = newPermissionWrite;
    saveModules(todosModulos);
  };
  const handleChangePermissionsUpdate = (e, i) => {
    let newPermissionUpdate = null;

    if (e.target.value === "si" || e.target.value === "SI") {
      newPermissionUpdate = true;
    } else {
      newPermissionUpdate = false;
    }
    const todosModulos = [...modules];
    todosModulos[i].permissions.update = newPermissionUpdate;
    saveModules(todosModulos);
  };
  const handleChangePermissionsDelete = (e, i) => {
    let newPermissionDelete = null;

    if (e.target.value === "si" || e.target.value === "SI") {
      newPermissionDelete = true;
    } else {
      newPermissionDelete = false;
    }
    const todosModulos = [...modules];
    todosModulos[i].permissions.delete = newPermissionDelete;
    saveModules(todosModulos);
  };
  const eliminarModulo = (value) => {
    const todosProductos = modules.filter(
      (module) => module.module_id._id !== value
    );
    saveModules(todosProductos);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
        <SelectModule detectarCambiosModules={detectarCambiosModules} />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        xl={4}
        display="flex"
        justifyContent="center">
        <Button
          size="large"
          variant="contained"
          onClick={() => AgregarModuloState()}>
          Agregar
        </Button>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                {/* <StyledTableCell align="center">Imagen</StyledTableCell> */}
                <StyledTableCell align="center">Modulo</StyledTableCell>
                <StyledTableCell align="center">
                  Permiso P/Escribir
                </StyledTableCell>
                <StyledTableCell align="center">
                  Permiso P/Actualizar
                </StyledTableCell>
                <StyledTableCell align="center">
                  Permiso P/Eliminar
                </StyledTableCell>
                <StyledTableCell align="center">Opciones</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {modules.map((module, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell
                    align="center"
                    sx={{ fontWeight: "bold", width: "200px" }}>
                    {module.name}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      width: "200px",
                    }}>
                    <TextField
                      type="text"
                      placeholder="si no"
                      name="write"
                      defaultValue={
                        module.permissions?.write === true ? "si" : "no"
                      }
                      onChange={(e) => handleChangePermissionsWrite(e, index)}
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      width: "200px",
                    }}>
                    <TextField
                      type="text"
                      placeholder="si no"
                      name="update"
                      defaultValue={
                        module.permissions?.update === true ? "si" : "no"
                      }
                      onChange={(e) => handleChangePermissionsUpdate(e, index)}
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      width: "200px",
                    }}>
                    <TextField
                      type="text"
                      placeholder="si no"
                      name="delete"
                      defaultValue={
                        module.permissions?.delete === true ? "si" : "no"
                      }
                      onChange={(e) => handleChangePermissionsDelete(e, index)}
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      width: "200px",
                    }}>
                    <IconButton
                      onClick={() => eliminarModulo(module.module_id._id)}>
                      <DeleteIcon sx={{ color: "red" }} />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default AddPermissions;
