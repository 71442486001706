import React, { useContext, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import SalesContext from "../../context/Sales/SalesContext";
import SalesPendingCard from "../../components/Cards/Sales/SalesPendingCard";
import { useForm } from "react-hook-form";
import NoDataComponents from "../../components/loading/NoDataComponents";
import { styled, alpha } from "@mui/material/styles";

import { makeStyles } from "@mui/styles";
import {
  Button,
  Grid,
  TablePagination,
  Box,
  InputBase,
  Stack,
  Pagination,
  TextField,
} from "@mui/material";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import SearchIcon from "@mui/icons-material/Search";
import NoResultsComponent from "../../components/loading/NoResultsComponent";
import Swal from "sweetalert2";
import { Paginate } from "../../components/Pagination/Paginate";

const useStyles = makeStyles(() => ({
  titleCard: {
    color: "white",
    fontSize: "35px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
}));

//Estilos busqueda
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
  },
}));

export default function SalesPendingPayment() {
  const classes = useStyles();
  var [pages, setPage] = useState(1);
  const [magicWord, setMagicWord] = useState("");
  const [filterSales, setFilterSales] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [salesChecked, setsalesChecked] = useState([]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const limite = rowsPerPage;

  const params = { limite, pages };

  const {
    salesPending,
    success,
    GetSalesPendingPayment,
    totalPages,
    AuthorizeSale,
    salesPendingWithoutPagination,
    GetSalesPendingPaymentWithoutPagination,
  } = useContext(SalesContext);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  useEffect(() => {
    GetSalesPendingPaymentWithoutPagination();
  }, [success]);

  const handleSearchSale = ({ target }) => {
    setMagicWord(target.value);
    const number = target.value;
    try {
      let resultsearch = salesPendingWithoutPagination.filter(
        (salef) =>
          salef.folio.toString().toLowerCase().includes(number.toLowerCase()) ||
          salef.client_id.fullname
            .toString()
            .toLowerCase()
            .includes(number.toLowerCase())
      );
      setFilterSales(resultsearch);
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error.response.data.message,
        icon: "error",
      });
    }
  };

  useEffect(() => {
    GetSalesPendingPayment(params);
    setFilterSales(salesPendingWithoutPagination);
  }, [success, rowsPerPage, pages]);

  const onSubmit = (data, e) => {
    data.orders = salesChecked;
    AuthorizeSale(data);
    setsalesChecked([]);
  };

  return (
    <Grid container spacing={2} sx={{ backgroundColor: "white" }}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
          <Grid container spacing={2}>
            {salesPending.length ? (
              <>
                <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                  <TextField
                    fullWidth
                    label="Buscar orden"
                    //placeholder="Buscar venta por el número de Folio o por Nombre de cliente"
                    inputProps={{ "aria-label": "search" }}
                    onChange={handleSearchSale}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  xl={3}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                  }}
                >
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    onKeyDown={(e) => {
                      if (e.code === "Enter") e.preventDefault();
                    }}
                    autoComplete="off"
                  >
                    <Button
                      disabled={salesChecked.length > 0 ? false : true}
                      type="submit"
                      variant="outlined"
                      sx={
                        salesChecked.length > 0
                          ? {
                              color: "white",
                              fontWeight: "bold",
                              background: "black",
                              boxShadow: 3,
                              "&:hover": {
                                background: "green",
                              },
                            }
                          : {}
                      }
                      startIcon={<DoneAllIcon />}
                    >
                      Aprobar Ventas
                    </Button>
                  </form>
                </Grid>
              </>
            ) : (
              <></>
            )}

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container spacing={2}>
                {!magicWord &&
                  (!salesPending.length ? (
                    <NoDataComponents />
                  ) : (
                    salesPending.map((salesPending, index) => (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        xl={4}
                      >
                        <SalesPendingCard
                          salesPending={salesPending}
                          salesChecked={salesChecked}
                          setsalesChecked={setsalesChecked}
                        />
                      </Grid>
                    ))
                  ))}

                {magicWord &&
                  (!filterSales.length ? (
                    <NoResultsComponent />
                  ) : (
                    filterSales.map((salesPending) => (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        xl={4}
                        key={salesPending._id}
                      >
                        <SalesPendingCard
                          salesPending={salesPending}
                          salesChecked={salesChecked}
                          setsalesChecked={setsalesChecked}
                        />
                      </Grid>
                    ))
                  ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {!magicWord &&
          (!salesPending.length ? (
            <Box className={classes.pagination}></Box>
          ) : (
            <Box
              className={classes.pagination}
              sx={{ marginTop: 4, marginBottom: 4 }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                display="flex"
                justifyContent="center"
                sx={{ marginBottom: 10 }}
              >
                <Paginate
                  pages={pages}
                  totalPages={totalPages}
                  handleChangePage={handleChangePage}
                />
              </Grid>
            </Box>
          ))}
      </Grid>
    </Grid>
  );
}
