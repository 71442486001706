import { useReducer } from "react";
import Swal from "sweetalert2";
import MethodGet, { MethodPut } from "../../config/Service";
import { MIDDLEWARE_TOGGLE, MIDDLEWARE, ERROR_MIDDLEWARE } from "../../types";
import SettingsContext from "./SettingsContext";
import SettingsReducer from "./SettingsReducer";
// import SettingsContext from "./SettingsContext";
// import SettingsReducer from "./SettingsReducer";

const SettingsState = ({ children }) => {
  const INITIAL_STATE = {
    maintainment: false,
  };

  const [state, dispatch] = useReducer(SettingsReducer, INITIAL_STATE);

  const getMaintainment = () => {
    let url = `/administrable/maintainment`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: MIDDLEWARE,
          payload: res.data?.maintainment,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
      });
  };
  const putMaintainment = (maintainment) => {
    let url = `/administrable/maintainment`;
    const formData = new FormData();
    formData.append("maintainment", maintainment);

    Swal.fire({
      title: "¿Estas seguro?",
      text: `El Ecommerce sera ${
        !maintainment ? "Habilitado" : "Desahabilitado"
      }`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Aceptar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        MethodPut(url, formData)
          .then((res) => {
            dispatch({
              type: MIDDLEWARE_TOGGLE,
            });
          })
          .catch((error) => {
            dispatch({
              type: ERROR_MIDDLEWARE,
              payload: maintainment,
            });
          });
      }
    });
  };
  return (
    <SettingsContext.Provider
      value={{
        ...state,
        maintainment: state.maintainment,
        getMaintainment,
        putMaintainment,
      }}>
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsState;
