import * as React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, Grid } from "@mui/material";
import Layout from "../../components/layout/Layout";
import Sales from "./Sales";
import SalesCancel from "./SalesCancel";
import SalesPendingPayment from "./SalesPendingPayment";
import SalesWithoutPayments from "./SalesWithoutPayments";
import { Link } from "react-router-dom/cjs/react-router-dom";
import SalesContext from "../../context/Sales/SalesContext";
import { useContext } from "react";
import { useEffect } from "react";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function IndexSales() {
  const { CancelOrdersAtm, orders } = useContext(SalesContext);
  useEffect(() => {
    CancelOrdersAtm();
  }, []);
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Layout>
      <Grid contianer spacing={2} sx={{ padding: 2 }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          display="flex"
          justifyContent="end"
          sx={{ margin: 2 }}
        >
          <Link to="/ImportarVenta">
            <Button variant="contained" size="large">
              Importar venta
            </Button>
          </Link>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          display="flex"
          justifyContent="center"
        >
          <Box sx={{ bgcolor: "background.paper", width: "100%" }}>
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                //indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
                sx={{ backgroundColor: "black" }}
              >
                <Tab label="Ventas Completadas" {...a11yProps(0)} />
                <Tab label="Ventas Pendientes de pagos" {...a11yProps(1)} />
                <Tab label="Ventas Canceladas" {...a11yProps(2)} />
              </Tabs>
            </AppBar>
          </Box>
        </Grid>

        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <Sales />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <SalesPendingPayment />
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <SalesCancel />
          </TabPanel>
        </SwipeableViews>
      </Grid>
    </Layout>
  );
}
