import { Grid, Typography, Divider, Chip } from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";

export const AddressInfo = ({ direction }) => {
  return (
    <>
      <Divider>
        <Chip
          sx={{ fontWeight: "bold", fontSize: 18 }}
          label="Dirrección de Envio"
          icon={<LocalShippingIcon />}
        />
      </Divider>

      <Grid container sx={{ marginY: 2, textAlign: "center" }}>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4} sx={{ marginBottom: 1 }}>
          <Typography sx={{ fontWeight: "bold" }}>Pais </Typography>
          {direction.type_direction === 1 ? (
            <Typography>México</Typography>
          ) : (
            <Typography>
              {" "}
              {direction ? direction.country : "Internacional"}{" "}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4} sx={{ marginBottom: 1 }}>
          <Typography sx={{ fontWeight: "bold" }}>Estado </Typography>
          {direction.state !== " " ? (
            direction.state.name
          ) : (
            <Typography>Informacion no registrada</Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4} sx={{ marginBottom: 1 }}>
          <Typography sx={{ fontWeight: "bold" }}>Municipio </Typography>
          {direction.municipality !== " " ? (
            direction.municipality.name
          ) : (
            <Typography>Informacion no registrada</Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4} sx={{ marginBottom: 1 }}>
          <Typography sx={{ fontWeight: "bold" }}> Ciudad </Typography>
          {direction.city !== " " ? (
            direction.city
          ) : (
            <Typography>Informacion no registrada</Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4} sx={{ marginBottom: 1 }}>
          <Typography sx={{ fontWeight: "bold" }}>Codigo Postal </Typography>
          {direction.postalcode !== " " ? (
            direction.postalcode
          ) : (
            <Typography>Informacion no registrada</Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4} sx={{ marginBottom: 1 }}>
          <Typography sx={{ fontWeight: "bold" }}>Colonia </Typography>
          {direction.colony !== "" ? (
            <Typography>{direction.colony}</Typography>
          ) : (
            <p>Sin colonia</p>
          )}
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4} sx={{ marginBottom: 1 }}>
          <Typography sx={{ fontWeight: "bold" }}>Calle</Typography>
          {direction.street !== "" ? (
            <Typography>{direction.street}</Typography>
          ) : (
            <p>Dato no proporcionado</p>
          )}
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={4} xl={4} sx={{ marginBottom: 1 }}>
          <Typography sx={{ fontWeight: "bold" }}>N° Int.</Typography>
          <Typography>{direction.no_int ? direction.no_int : "S/N	"}</Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4} sx={{ marginBottom: 1 }}>
          <Typography sx={{ fontWeight: "bold" }}>N° Ext.</Typography>
          <Typography>{direction.no_ext}</Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4} sx={{ marginBottom: 1 }}>
          <Typography sx={{ fontWeight: "bold" }}>Entre calle </Typography>
          <Typography>
            {direction ? direction.between_street : "Dato no proporcionado"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4} sx={{ marginBottom: 1 }}>
          <Typography sx={{ fontWeight: "bold" }}>Referencias </Typography>
          <Typography>
            {direction ? direction.references : "Información no proporcionada"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4} sx={{ marginBottom: 1 }}>
          <Typography sx={{ fontWeight: "bold" }}>Telefono </Typography>
          {direction.phone_number !== "" ? (
            <Typography>{direction.phone_number}</Typography>
          ) : (
            <p>Sin telefono</p>
          )}
        </Grid>
      </Grid>
    </>
  );
};
