import React, { useReducer } from "react";
import InfluencersReducer from "./InfluencersReducer";
import InfluencersContext from "./InfluencersContext";
import MethodGet, { MethodPost, MethodPut } from "../../config/Service";
import {
  ACCEPT_INFLUENCER,
  GET_ALL_ACCEPTED,
  GET_ALL_PENDING,
  GET_ALL_REJECTED,
  REJECTED_INFLUENCER,
  GET_ALL_PENDING_DASHBOARD,
  ABOUT_INFLUENCERS,
  GET_ABOUT_INFLUENCERS,
} from "../../types";
import Swal from "sweetalert2";

const InfluencersState = ({ children }) => {
  const initialState = {
    influencersPending: [],
    influencersRejected: [],
    influencersAccepted: [],
    influencer: null,
    influencersindex: [],
    ErrorsApi: [],
    success: false,
  };

  const [state, dispatch] = useReducer(InfluencersReducer, initialState);
  //gET about de los inluencers
  const GetAboutInfluecers = () => {
    sessionStorage.removeItem("influencer");
    let url = `administrable/about-influencer`;
    MethodGet(url)
      .then(({ data }) => {
        dispatch({
          type: GET_ABOUT_INFLUENCERS,
          payload: data.about,
        });
      })
      .catch(({ error }) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
      });
  };

  //obtener la lista de influencers Aceptados
  const GetInfluencersAccepted = () => {
    sessionStorage.removeItem("influencer");
    let url = `/influencers/accepted/forms`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_ACCEPTED,
          payload: res.data.influencers,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
      });
  };

  //
  const GetInfluencersRejected = () => {
    sessionStorage.removeItem("influencer");
    let url = `/influencers/rejected/forms`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_REJECTED,
          payload: res.data.influencers,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
      });
  };

  //
  const GetInfluencersPending = () => {
    sessionStorage.removeItem("influencer");
    let url = `/influencers/pending/forms`;

    MethodGet(url).then((res) => {
      dispatch({
        type: GET_ALL_PENDING,
        payload: res.data.influencers,
      });
    });
  };

  const GetInfluencersPendingIndex = () => {
    sessionStorage.removeItem("influencersindex");
    let url = `/influencers/pending/index`;

    MethodGet(url).then((res) => {
      dispatch({
        type: GET_ALL_PENDING_DASHBOARD,
        payload: res.data.influencers,
      });
    });
  };

  const AboutInfluencersUpdate = (about) => {
    const formData = new FormData();
    formData.append("about", about.about);
    let url = `administrable/about-influencer`;

    MethodPut(url, formData)
      .then((res) => {
        Swal.fire({
          title: "Informacion Actualizada",
          text: res.data.message,
          timer: 3000,
          showConfirmButton: false,
          icon: "success",
        });
        dispatch({
          type: ABOUT_INFLUENCERS,
        });
      })
      .catch((error) => {
        // Swal.fire({
        //   title: "Error",
        //   text: error.response.data.message,
        //   icon: "error",
        // });
        // dispatch({
        //   type: SHOW_ERRORS_API,
        //   payload: error.res.data.error,
        // });
      });
  };
  const AcceptInfluencer = (id) => {
    Swal.fire({
      title: "Aceptar Influencer",
      text: "Se aceptara la solicitud del influencer seleccionado",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Aceptar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/influencers/acceptInfluencer/${id}`;
        MethodPost(url).then((res) => {
          Swal.fire({
            title: "Aceptado",
            text: "El influencer fue aceptado, se ha enviado un correo electronico, con los datos de acceso al sistema",
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          });
          dispatch({
            type: ACCEPT_INFLUENCER,
          });
        });
      }
    });
  };

  const RejectInfluencer = (id) => {
    Swal.fire({
      title: "Rechazar Solicitud",
      text: "Se rechazara, la solicitud del influencer seleccionado",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Rechazar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          title: "¿Porque se rechazo?",
          text: "Agrega una breve descripción de porque se declino, la solicitud",
          html: `<textarea name="textarea" rows="10" id="feedback" cols="5" class="swal2-input" style="width:370px" placeholder=""/>`,
          confirmButtonText: "Continuar",
          preConfirm: () => {
            var feedback = Swal.getPopup().querySelector("#feedback").value;
            if (!feedback) {
              Swal.showValidationMessage(
                `Por favor comenta porque se declino la solicitud`
              );
            }
            return { feedback: feedback };
          },
        }).then((result) => {
          if (result.value) {
            let url = `/influencers/rejectInfluencer/${id}`;
            const formData = new FormData();
            formData.append("feedback", result.value.feedback);
            MethodPost(url, formData).then((res) => {
              Swal.fire({
                title: "Solicitud Declinada",
                text: "La solicitud se declino, correctamente!",
                icon: "success",
                timer: 1500,
                showConfirmButton: false,
              });
              dispatch({
                type: REJECTED_INFLUENCER,
              });
            });
          }
        });
      }
    });
  };

  return (
    <InfluencersContext.Provider
      value={{
        influencersRejected: state.influencersRejected,
        influencersAccepted: state.influencersAccepted,
        influencersPending: state.influencersPending,
        influencer: state.influencer,
        influencersindex: state.influencersindex,
        ErrorsApi: state.ErrorsApi,
        success: state.success,
        GetAboutInfluecers,
        AboutInfluencersUpdate,
        GetInfluencersAccepted,
        GetInfluencersPending,
        GetInfluencersRejected,
        GetInfluencersPendingIndex,
        AcceptInfluencer,
        RejectInfluencer,
      }}>
      {children}
    </InfluencersContext.Provider>
  );
};

export default InfluencersState;
