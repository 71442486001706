import React, { useContext, useEffect, useState } from "react";
import TypeClientsContext from "../../../context/TypeClients/TypeClientsContext";

import {
  Box,
  Card,
  Typography,
  Tooltip,
  Grid,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import StyleIcon from "@mui/icons-material/Style";
import EditTypeClient from "../../../containers/Clients/TypeClients/Edit";
export default function TypeClientCard({ typeClient, permiss }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //Menu
  const [modalUpdate, AbrirModalUpdate] = useState(false);
  const handleClickOpenUpdate = () => {
    AbrirModalUpdate(true);
  };
  const handleClickCloseUpdate = () => {
    sessionStorage.removeItem("tag");
    AbrirModalUpdate(false);
  };
  //
  const [typeClientId, saveTypeClientId] = useState();
  const { DeleteTypeClient } = useContext(TypeClientsContext);

  const SelectTypeClient = (typeClient) => {
    saveTypeClientId(typeClient);
    handleClickOpenUpdate();
  };

  return (
    <>
      <Card sx={{ margin: 1 }}>
        <Box
          fullWidth
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            paddingX: 2,
            backgroundColor: "white",
            color: "black",
          }}
        >
          <Typography
            variant="su arrowbtitle2"
            noWrap
            sx={{ fontWeight: "bold", fontSize: "17px" }}
            className="blink-1"
          >
            {typeClient.name}
          </Typography>
          <div>
            <Tooltip arrow title="Operaciones" placement="top">
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                sx={{ color: "black" }}
              >
                <MoreVertIcon />
              </IconButton>
            </Tooltip>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
               {permiss.update === true && (
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  SelectTypeClient(typeClient._id);
                }}
              >
                Editar
              </MenuItem>
               )}
              {permiss.delete === true && (
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  DeleteTypeClient(typeClient._id);
                }}
              >
                Eliminar
              </MenuItem>
              )}
            </Menu>
          </div>
        </Box>
      </Card>
      <EditTypeClient
        modalUpdate={modalUpdate}
        typeClient={typeClient}
        handleClickOpen={handleClickOpenUpdate}
        handleCloseEdit={handleClickCloseUpdate}
      />
    </>
  );
}
