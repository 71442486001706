import React, { useContext, useState } from "react";
import BrandsContext from "../../../context/Brands/BrandsContext";
import ModalEdit from "../../../containers/brands/ModalEdit";
import { EditIcon } from "../../icons/EditIcon";
import { DeleteIcon } from "../../icons/DeleteIcon";
import { makeStyles } from "@mui/styles";
import { Box, Card, Typography, Tooltip, Grid, Button } from "@mui/material";
import ResponsiveImgMaterialUi from "responsive-responsive-img-material-ui";

const useStyles = makeStyles({
  Imagelogo: {
    height: "131px",
    objectFit: "cover",
    textAlign: "center",
  },
});
const BrandCard = ({ brand, permiss }) => {
  const { images } = brand;
  const classes = useStyles();
  const { DeleteBrands } = useContext(BrandsContext);
  const [modalUpdate, AbrirModalUpdate] = useState(false);
  const [id_brand, setIdBrand] = useState();
  const handleClickOpenUpdate = () => {
    AbrirModalUpdate(true);
  };
  const handleClickCloseUpdate = () => {
    sessionStorage.removeItem("brand");
    AbrirModalUpdate(false);
  };

  const selectBrand = (brand) => {
    setIdBrand(brand);
    handleClickOpenUpdate();
  };

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
      <Card sx={{ boxShadow: 3 }}>
        <Box
          fullWidth
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            paddingX: 2,
            marginTop: 2,
            backgroundColor: "white",
            color: "black",
          }}
        >
          <Typography
            variant="su arrowbtitle2"
            noWrap
            sx={{ fontWeight: "bold", fontSize: "17px" }}
            className="blink-1"
          >
            {brand.name}
          </Typography>
        </Box>
        <Box>
          <ResponsiveImgMaterialUi
            style={{ height: "330px", width: "100%" }}
            xs={images["400x400"]}
            md={images["750x750"]}
            lg={images["1000x1000"]}
            xl={images["1400x1400"]}
          />
        </Box>
        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          {permiss.update === true && (

            <Grid item xs={6} display="flex" justifyContent="center">
            <Tooltip title="Editar" placement="top">
              <Button onClick={() => selectBrand(brand._id)}>
                <EditIcon />
              </Button>
            </Tooltip>
          </Grid>
            )}
          {permiss.delete === true && (

          <Grid item xs={6} display="flex" justifyContent="center">
            <Tooltip title="Eliminar" placement="top">
              <Button onClick={() => DeleteBrands(brand._id)}>
                <DeleteIcon />
              </Button>
            </Tooltip>
          </Grid>
           )}
        </Grid>
      </Card>
      {id_brand && (
        <ModalEdit
          modalUpdate={modalUpdate}
          id={id_brand}
          handleClickOpen={handleClickOpenUpdate}
          handleCloseEdit={handleClickCloseUpdate}
        />
      )}
    </Grid>
  );
};

export default BrandCard;
