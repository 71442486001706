import React from "react";
import {
  GET_ALL_SHIPPING_CANVAS_ACCEPTED,
  GET_ALL_SHIPPING_CANVAS_PENDING,
  GET_ALL_SHIPPING_CANVAS_SHIPPED,
  PDF_CANVAS_ORDER,
  UPDATE_SHIPPING_CANVAS,
  WAREHOUSE_SHIPPING_CANVAS_ACCEPT_ORDER,
  WAREHOUSE_SHIPPING_CANVAS_SHIPPED_ORDER,
} from "../../types";

export default function WarehouseCanvasReducer(state, action) {
  switch (action.type) {
    case GET_ALL_SHIPPING_CANVAS_PENDING:
      return {
        ...state,
        shippingsCanvasPending: action.payload.shippingsCanvasPending,
        totalDocs: action.payload.totalDocs,
        limit: action.payload.limit,
        page: action.payload.page,
        paginCounter: action.payload.paginCounter,
        hasPrevPage: action.payload.hasPrevPage,
        hasNextPage: action.payload.hasNextPage,
        prevPage: action.payload.prevPage,
        nextPage: action.payload.nextPage,
        totalPages: action.payload.totalPages,
        success: false,
        ErrorsApi: [],
      };
    case GET_ALL_SHIPPING_CANVAS_ACCEPTED:
      return {
        ...state,
        shippingsCanvasAccepted: action.payload.shippingsCanvasAccepted,
        totalDocs: action.payload.totalDocs,
        limit: action.payload.limit,
        page: action.payload.page,
        paginCounter: action.payload.paginCounter,
        hasPrevPage: action.payload.hasPrevPage,
        hasNextPage: action.payload.hasNextPage,
        prevPage: action.payload.prevPage,
        nextPage: action.payload.nextPage,
        totalPages: action.payload.totalPages,
        success: false,
        ErrorsApi: [],
      };
    case GET_ALL_SHIPPING_CANVAS_SHIPPED:
      return {
        ...state,
        shippingsCanvasShipped: action.payload.shippingsCanvasShipped,
        totalDocs: action.payload.totalDocs,
        limit: action.payload.limit,
        page: action.payload.page,
        paginCounter: action.payload.paginCounter,
        hasPrevPage: action.payload.hasPrevPage,
        hasNextPage: action.payload.hasNextPage,
        prevPage: action.payload.prevPage,
        nextPage: action.payload.nextPage,
        totalPages: action.payload.totalPages,
        success: false,
        ErrorsApi: [],
      };
    case WAREHOUSE_SHIPPING_CANVAS_ACCEPT_ORDER:
      return {
        ...state,
        success: true,
        ErrorsApi: [],
      };
    case WAREHOUSE_SHIPPING_CANVAS_SHIPPED_ORDER:
      return {
        ...state,
        success: true,
        ErrorsApi: [],
      };
    case PDF_CANVAS_ORDER:
      return {
        ...state,
        success: true,
        ErrorsApi: [],
      };
    case UPDATE_SHIPPING_CANVAS:
      return {
        ...state,
        success: true,
        ErrorsApi: [],
        shippingsCanvasShipped: state.shippingsCanvasShipped?.map((shipp) =>
          shipp._id === action.payload._id ? { ...action.payload } : shipp
        ),
      };
    default:
      return state;
  }
}
