import React from "react";
import {
  ADD_OFFERS,
  CURRENT_OFFERS,
  DELETE_OFFERS,
  GET_ALL_OFFERS,
  SHOW_ERRORS_API,
  UPDATE_OFFERS,
} from "../../types";

export default function OffersReducer(state, action) {
  switch (action.type) {
    case GET_ALL_OFFERS:
      return {
        ...state,
        offers: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case ADD_OFFERS:
      return {
        ...state,
        success: true,
        ErrorsApi: [],
      };
    case SHOW_ERRORS_API:
      return {
        ...state,
        success: true,
        ErrorsApi: [],
      };
    case CURRENT_OFFERS:
      return {
        ...state,
        offer: action.payload,
      };
    case UPDATE_OFFERS:
      return {
        ...state,
        success: true,
        ErrorsApi: [],
      };
    case DELETE_OFFERS:
      return {
        ...state,
        success: true,
        offers: state.offers.filter((offer) => offer.id !== action.payload),
      };
    default:
      return state;
  }
}
