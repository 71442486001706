import { useContext, useEffect, useState } from "react";
import CategoriesContext from "../../context/CategoriesP/CategoriesContext";

export const useAutocompleCustom = (curentevalue) => {
  const { category } = useContext(CategoriesContext);

  // Input  values
  const [selectedValue, setSelectedValue] = useState(
    category?.typeCategory || {}
  );
  const [selectedValueDisplayed, setSelectedValueDisplayed] = useState("");

  const [idSelectedValue, setidSelectedValue] = useState(
    category?.typeCategory?._id || null
  );
  const [error, setError] = useState(false);
  const HandleChangeValueAutoComplete = (val) => {
    setSelectedValue(val);
    setidSelectedValue(val?._id);
  };
  useEffect(() => {
    if (idSelectedValue) {
      setError(false);
    } else {
      setError(true);
    }
  }, [idSelectedValue]);

  return {
    HandleChangeValueAutoComplete,
    idSelectedValue,

    // Selected Input Value

    setSelectedValue,
    selectedValue,

    error
  };
};
