import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import Layout from "../../components/layout/Layout";
import MethodGet from "../../config/Service";
import TableAccountsProvider from "./TableAccountsProvider";
import { PriceFormat } from "./../../utils/FormatPrice";
import SpinnerComponent from "../../components/loading/SpinnerComponent";
import AccountsForPaymentContext from "../../context/AccountsForPayment/AccountsForPaymentContext";
import { useContext } from "react";
const DetailProviders = (props) => {
  const id = props.match.params.id;
  const { accountsProvider, GetAccountsPrvider } = useContext(
    AccountsForPaymentContext
  );

  useEffect(() => {
    GetAccountsPrvider(id);
  }, [id]);

  const [provider, saveProvider] = useState();
  //const [accounts, saveAccounts] = useState([]);
  const [cargando, spinnerCargando] = useState(false);
  useEffect(() => {
    let url = `/providers/${id}`;
    MethodGet(url)
      .then((res) => {
        saveProvider(res.data.provider);
        //saveAccounts(res.data.provider.accounts);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
        spinnerCargando(true);
      });
  }, [id]);
  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ backgroundColor: "black", color: "white" }}
        >
          <Typography fontWeight="bold" fontSize="30px" sx={{ marginLeft: 2 }}>
            Detalle de proveedor
          </Typography>
        </Grid>
        {!cargando ? (
          <SpinnerComponent />
        ) : (
          <>
            <Grid
              sx={{ margin: 2 }}
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <Typography fontWeight="bold" fontSize="20px">
                Proveedor: {provider?.company}
              </Typography>
            </Grid>
            <Grid
              display="flex"
              justifyContent="center"
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
            >
              <Typography fontWeight="bold" fontSize="15px">
                Total A pagar: $ {PriceFormat(provider.total)}
              </Typography>
            </Grid>
            <Grid
              display="flex"
              justifyContent="center"
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
            >
              <Typography fontWeight="bold" fontSize="15px">
                Total Pagado: $ {PriceFormat(provider.totalPayment)}
              </Typography>
            </Grid>
            <Grid
              display="flex"
              justifyContent="center"
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
            >
              <Typography fontWeight="bold" fontSize="15px">
                Resta por pagar: ${" "}
                {PriceFormat(provider.total - provider.totalPayment)}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ margin: 2 }}
            >
              <Typography textAlign="center" fontWeight="bold" fontSize="20px">
                Cuentas por pagar
              </Typography>
              <TableAccountsProvider
                data={accountsProvider}
                provider={provider}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Layout>
  );
};

export default DetailProviders;
