import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  ButtonGroup,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Delete } from "@material-ui/icons";
import { CalUnitPrice } from "../../../utils/CalUnitPrice";
import { CalDiscount } from "../../../utils/CalDiscount";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function TableProducts({
  productsOrder,
  saveProductsOrder,
  sumaProductos,
  business_rule,
}) {
  //cambio de cantidad
  const handleChangeQuantity = (e, i) => {
    const newQuantity = Number(e.target.value);
    const todosProducto = [...productsOrder];
    todosProducto[i].quantity = newQuantity;
    todosProducto[i].subtotal =
      todosProducto[i].quantity * todosProducto[i].product.price;
    todosProducto[i].total_final = business_rule
      ? todosProducto[i].subtotal -
        (todosProducto[i].subtotal * business_rule.discount) / 100
      : todosProducto[i].quantity * todosProducto[i].product.price;
    saveProductsOrder(todosProducto);
  };
  const eliminarProducto = (value) => {
    const todosProductos = productsOrder.filter(
      (product) => product.product._id !== value
    );
    saveProductsOrder(todosProductos);
  };
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {/* <StyledTableCell align="center">Imagen</StyledTableCell> */}
            <StyledTableCell>C. barras</StyledTableCell>
            <StyledTableCell align="center">Producto</StyledTableCell>
            <StyledTableCell align="center">
              <Typography sx={{ fontSize: 12 }}>Precio U.</Typography>
            </StyledTableCell>
            <StyledTableCell align="center">Cantidad</StyledTableCell>

            <StyledTableCell align="center">
              <Typography sx={{ fontSize: 12 }}>Subtotal</Typography>
            </StyledTableCell>
            <StyledTableCell align="center">Total</StyledTableCell>
            <StyledTableCell align="center">Opciones</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {productsOrder.map((product, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell
                align="left"
                sx={{ fontWeight: "bold", width: "200px" }}>
                {product.product.barcode}
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{ fontWeight: "bold", width: "200px" }}>
                {product.product.name}
              </StyledTableCell>

              <StyledTableCell
                align="center"
                sx={{ fontWeight: "bold", width: "200px" }}>
                {product.product.discount === 0 ? (
                  <>
                    <Typography>{product.product.price}</Typography>
                  </>
                ) : (
                  <>
                    <Typography
                      sx={{
                        textDecoration: "line-through",
                        color: "#A9A9A9",
                      }}>
                      {CalUnitPrice(product.subtotal, product.quantity)}
                    </Typography>
                    <Typography sx={{ fontWeight: "bold", fontSize: 14 }}>
                      {CalDiscount(product.price, product.discount)}
                    </Typography>
                  </>
                )}
              </StyledTableCell>

              <StyledTableCell align="center">
                <ButtonGroup>
                  <TextField
                    type="number"
                    size="small"
                    fullWidth
                    onChange={(e) => handleChangeQuantity(e, index)}
                    defaultValue={product.quantity}
                    sx={{ width: "100%", borderRadius: "15px" }}
                    align="center"
                  />
                </ButtonGroup>
              </StyledTableCell>

              <StyledTableCell
                align="center"
                sx={{ fontWeight: "bold", width: "200px" }}>
                <Typography sx={{ fontWeight: "bold", fontSize: 14 }}>
                  ${product.subtotal}
                </Typography>
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{ fontWeight: "bold", width: "200px" }}>
                <Typography sx={{ fontWeight: "bold", fontSize: 14 }}>
                  ${" "}
                  {business_rule !== " " ? product.total_final : product.price}
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Box align="center">
                  <Tooltip title="Eliminar" placement="top">
                    <IconButton
                      onClick={() => eliminarProducto(product.product_id)}>
                      <Delete
                        style={{ fontSize: 30, marginTop: -5, color: "red" }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              </StyledTableCell>
            </StyledTableRow>
          ))}
          <TableRow>
            <TableCell rowSpan={3} />
            <TableCell colSpan={2} align="center">
              <Typography fontSize="bold" fontFamily="monospace">
                Total productos comprados
              </Typography>
            </TableCell>
            <TableCell align="center">
              <b>{sumaProductos}</b>
              pzs / uds
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
