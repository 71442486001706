import React, { useState, useContext, useEffect } from "react";
import UsersContext from "../../context/Users/UsersContext";
import SelectTypeUsers from "../SelectOptions/SelectTypeUsers";
import { useForm } from "react-hook-form";

import { makeStyles } from "@mui/styles";
import {
  InputAdornment,
  FormControl,
  OutlinedInput,
  Grid,
  Box,
  Fab,
  TextField,
  InputLabel,
  Tooltip,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles(() => ({
  logoimagen: {
    height: "200px",
    boxShadow: "5px 5px #289FED",
    borderRadius: "5px",
    objectFit: "cover",
  },

  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 2,
  },
  input: {
    display: "none",
  },
}));

export default function Add(props) {
  const classes = useStyles();
  const { modal, handleClose } = props;
  const [image, setImage] = useState({
    urlPhoto:
      "https://cdn.pngsumo.com/index-of-areaedu-wp-content-uploads-2016-02-default-png-600_600.png",
    image: "",
  });

  const handleChangeImage = (e) => {
    setImage({
      ...image,
      urlPhoto: URL.createObjectURL(e.target.files[0]),
      image: e.target.files[0],
    });
  };

  const { success, AddUsers } = useContext(UsersContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [typeUserId, saveTypeUser] = useState("");

  //Contraseña
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const detectarCambiosTypeUser = (e) => {
    saveTypeUser(e.target.value);
  };

  useEffect(() => {
    if (success) {
      setValue("fullname", "", { shouldDirty: true });
      setValue("email", "", { shouldDirty: true });
      setValue("password", "", { shouldDirty: true });
      setImage({
        urlPhoto:
          "https://cdn.pngsumo.com/index-of-areaedu-wp-content-uploads-2016-02-default-png-600_600.png",
        image: "",
      });
    }
  }, [success]);

  const onSubmit = (data, e) => {
    data.fullname = fullname;
    data.type_user_id = typeUserId;
    data.email = email;
    data.password = password;
    data.image = image.image;
    AddUsers(data);
    handleClose();
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={modal} onClose={handleClose}>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#000000",
            textAling: "center",
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            borderRadius: "0 0 24px 0",
          }}>
          <div className={classes.titleCard}>Agrega Usuario</div>
          <div className={classes.fab}>
            <Tooltip title="cerrar" arrow>
              <Fab
                size="small"
                onClick={handleClose}
                sx={{
                  color: "#FFFFFF",
                  backgroundColor: "#FF0000",
                  "&:hover": {
                    color: "#FFFFFF",
                    backgroundColor: "#FF0000",
                  },
                }}>
                <CancelPresentationIcon />
              </Fab>
            </Tooltip>
          </div>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginBottom: "6px" }}>
          Aqui podras agregar nuevos usuarios.
        </DialogContentText>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter")
              e.preventDefault();
          }}
          autoComplete="off">
          <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SelectTypeUsers
                  detectarCambiosTypeUser={detectarCambiosTypeUser}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  fullWidth
                  id="fullname"
                  placeholder="Ejemplo:Juan Osoño"
                  label="Nombre Completo"
                  name="fullname"
                  variant="outlined"
                  sx={{
                    color: "#343a40",
                  }}
                  error={errors.fullname ? true : false}
                  helperText={errors?.fullname?.message}
                  {...register("fullname", {
                    required: {
                      value: true,
                      message: "El campo nombre es requerido",
                    },
                    minLength: {
                      value: 4,
                      message: "Minimo 4 caracteres",
                    },
                    maxLength: {
                      value: 255,
                      message: "Maximo 255 caracteres",
                    },
                    onChange: ({ target }) => {
                      setFullname(target.value);
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  type="email"
                  variant="outlined"
                  error={errors.email ? true : false}
                  helperText={errors?.email?.message}
                  {...register("email", {
                    required: {
                      value: true,
                      message: "El campo email es requerido",
                    },
                    type: "email",
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Debes incluir el simbolo @",
                    },
                    onChange: ({ target }) => {
                      setEmail(target.value);
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    htmlFor="password"
                    error={errors.password ? true : false}>
                    Contraseña
                  </InputLabel>
                  <OutlinedInput
                    error={errors.password ? true : false}
                    id="password"
                    name="password"
                    type={values.showPassword ? "text" : "password"}
                    value={values.password}
                    onChange={({ target }) => {
                      setValues({ ...values, password: target.value });
                      setPassword(target.value);
                      // formik.handleChange(event);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end">
                          {values.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Contraseña"
                  />
                </FormControl>
                {/* <TextField
                  fullWidth
                  id="password"
                  label="Contraseña"
                  name="password"
                  type="password"
                  variant="outlined"
                  error={errors.password ? true : false}
                  helperText={errors?.password?.message}
                  {...register("password", {
                    required: {
                      value: true,
                      message: "La contraseña es requerida",
                    },
                    onChange: ({ target }) => {
                      setPassword(target.value);
                    },
                  })}
                /> */}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "cenetr",
                    justifyContent: "center",
                  }}>
                  <div>
                    <img
                      src={image.urlPhoto}
                      className={classes.logoimagen}
                      alt="agrega usuario"
                    />
                  </div>
                </Box>

                <input
                  className={classes.input}
                  id="icon-button-file"
                  type="file"
                  name="image"
                  accept="image/png, image/jpg, image/jpeg, image/webp"
                  onChange={handleChangeImage}
                />
                <InputLabel sx={{ textAlign: "center" }}>
                  imagen de perfil:
                  <label htmlFor="icon-button-file">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span">
                      <Tooltip
                        title="seleccionar imagen"
                        aria-label="seleccionar imagen">
                        <PhotoCamera />
                      </Tooltip>
                    </IconButton>
                  </label>
                </InputLabel>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    textAling: "center",
                  }}>
                  <div className={classes.fab}>
                    <Fab
                      type="submit"
                      variant="extended"
                      sx={{
                        backgroundColor: "#00B9FF",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#00DEED",
                          color: "white",
                        },
                      }}>
                      <SaveOutlinedIcon sx={{ mr: 1 }} />
                      Guardar
                    </Fab>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}
