import React from "react";
import {
	GET_SHIPPMENT_INTERNATIONAL,
	UPDATE_SHIPPMENT_INTERNATIONAL,
} from "../../types";

const ShippmentInternationalReducer = (state, action) => {
	switch (action.type) {
		case GET_SHIPPMENT_INTERNATIONAL:
			return {
				...state,
				shippment_inter: action.payload,
				success: false,
				ErrorsApi: [],
			};
		case UPDATE_SHIPPMENT_INTERNATIONAL:
			return {
				...state,
				success: true,
				ErrorsApi: [],
			};
		default:
			return state;
	}
};

export default ShippmentInternationalReducer;
