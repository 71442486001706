import React, { useEffect, useContext, useState } from "react";
import ProductsContext from "../../context/Products/ProductsContext";
import ShopProductCard from "../../components/Cards/products/ProductCard";
import NoDataComponents from "../../components/loading/NoDataComponents";
import { makeStyles } from "@mui/styles";
import ButtonImport from "./Imports/ButtonImport";
import { Box, Grid } from "@mui/material";
import ModulesContext from "../../context/Permissions/PermissionsContext";
import { Paginate } from "../../components/Pagination/Paginate";
import TabsOptions from "./TabsOptions";
// import Add from "./Add";
const useStyles = makeStyles({
  titleCard: {
    color: "white",
    fontSize: "35px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 2,
    marginRight: 5,
  },
  pagination: {
    margin: 25,
    marginRight: 25,
    fontFamily: "cursive",
  },
});

export default function Products() {
  const classes = useStyles();

  //paginacion
  var [pages, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const limite = rowsPerPage;
  const params = { limite, pages };
  //context products

  const { products, GetProducts, totalPages } = useContext(ProductsContext);
  const { permissionsPerUser, GetModulesPerUser } = useContext(ModulesContext);
  useEffect(() => {
    if (localStorage.getItem("module_id")) {
      const permiss = localStorage.getItem("module_id");
      GetModulesPerUser(permiss);
    }
  }, []);
  useEffect(() => {
    if (params.limite !== null && params.pages !== null) {
      GetProducts(params);
    }
  }, [rowsPerPage, pages]);

  if (!products) {
    return "Cargando";
  }

  return (
    <>
      <TabsOptions />
      {/* <Box>{products && <ButtonImport />}</Box> */}
      <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
        <Grid container spacing={2}>
          {!products.length ? (
            <NoDataComponents />
          ) : (
            products.map((product, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={4}
                xl={3}
                key={index}
                justifyContent="center"
              >
                <ShopProductCard
                  product={product}
                  permiss={permissionsPerUser}
                />
              </Grid>
            ))
          )}
        </Grid>
      </Box>
      <Grid container spacing={2}>
        <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
          {!products.length ? (
            <></>
          ) : (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              display="flex"
              justifyContent="center"
            >
              <Paginate
                pages={pages}
                handleChangePage={handleChangePage}
                totalPages={totalPages}
              />
            </Grid>
          )}
        </Box>
      </Grid>
    </>
  );
}
