import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import AccountsForPaymentContext from "./../../context/AccountsForPayment/AccountsForPaymentContext";
import { useContext } from "react";
import { useEffect } from "react";
import { IconButton, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Link } from "react-router-dom";
import SpinnerComponent from "../loading/SpinnerComponent";
import { PriceFormat } from "../../utils/FormatPrice";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function AccountPaymentTable() {
  const { GetAccountsPayment, accountsPayment, downloadPdfAllAccounts } =
    useContext(AccountsForPaymentContext);

  useEffect(() => {
    GetAccountsPayment();
  }, []);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Proveedor</StyledTableCell>
            <StyledTableCell align="center">Compañia</StyledTableCell>
            <StyledTableCell align="center">RFC</StyledTableCell>
            <StyledTableCell align="center">Monto Total</StyledTableCell>
            <StyledTableCell align="center">Monto Abonado</StyledTableCell>
            <StyledTableCell align="center">Opciones</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!accountsPayment ? (
            <SpinnerComponent />
          ) : (
            <>
              {accountsPayment.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell align="center">{row.name}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row.company}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.rfc}</StyledTableCell>
                  <StyledTableCell align="center">
                    $ {PriceFormat(Number(row.total))}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    $ {PriceFormat(Number(row.totalPayment))}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Tooltip
                      title="Agregar Factura a proveedor"
                      placement="top">
                      <Link to={`/AgregarFacturaProveedor/${row._id}`}>
                        <IconButton>
                          <AddIcon sx={{ color: "green", fontSize: "30px" }} />
                        </IconButton>
                      </Link>
                    </Tooltip>
                    <Tooltip
                      title="Detalle de cuentas del proveedor"
                      placement="top">
                      <Link to={`/DetalleProveedor/${row._id}`}>
                        <IconButton>
                          <RemoveRedEyeIcon
                            sx={{ color: "purple", fontSize: "30px" }}
                          />
                        </IconButton>
                      </Link>
                    </Tooltip>
                    <Tooltip
                      title="Descargar Ticket de las cuentas"
                      placement="top">
                      <IconButton onClick={() => downloadPdfAllAccounts(row)}>
                        <PictureAsPdfIcon sx={{ color: "red" }} />
                      </IconButton>
                    </Tooltip>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
