import React, { useReducer } from "react";
import Swal from "sweetalert2";
import MethodGet, { MethodPost, MethodPut } from "../../config/Service";
import {
  GET_ACCOUNTS_FOR_PAYMENT,
  GET_ACCOUNTS_PROVIDER,
  SHOW_ERRORS_API,
  UPDATE_ACCOUNTS_FOR_PAYMENT,
} from "../../types";

import AccountsForPaymentContext from "./AccountsForPaymentContext";
import AccountsForPaymentReducer from "./AccountsForPaymentReducer";
import { ADD_ACCOUNTS_FOR_PAYMENT } from "../../types/index";
import headerConfig from "../../config/imageHeaders";
import { ADD_PAYMENT_TO_ACCOUNT } from "./../../types/index";
import fileDownload from "js-file-download";
const AccountsState = ({ children }) => {
  //estado inicial
  const initialState = {
    accountsPayment: [],
    accountsProvider: [],
    accountPayment: null,
    ErrorsApi: [],
    paymentsInAccount: [],
    success: false,
  };

  const [state, dispatch] = useReducer(AccountsForPaymentReducer, initialState);
  //obtener la lista de cuentas bancarias
  const GetAccountsPayment = () => {
    sessionStorage.removeItem("accountPayment");
    let url = "/accounts-to-pay";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ACCOUNTS_FOR_PAYMENT,
          payload: res.data.accounts,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
      });
  };

  const GetAccountsPrvider = (id) => {
    let url = `/providers/${id}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ACCOUNTS_PROVIDER,
          payload: res.data.provider.accounts,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //registrar cuenta
  const AddAccountsPayment = (account) => {
    const formData = new FormData();
    formData.append("provider_id", account.provider_id);
    formData.append("NoFolio", account.folio_invoice);
    formData.append("typeInvoice", account.typeInvoice);
    formData.append("amount", Number(account.amount));
    let url = "/accounts-to-pay";
    MethodPost(url, formData)
      .then((res) => {
        Swal.fire({
          title: "Registrada",
          text: res.data.message,
          icon: "success",
          timer: 1200,
          showConfirmButton: false,
        });
        dispatch({
          type: ADD_ACCOUNTS_FOR_PAYMENT,
          payload: res.data.provider,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.message,
        });
      });
  };

  //fucnion para editar cuenta bancaria
  const UpdateAccountPayment = (account) => {
    const formData = new FormData();
    formData.append("provider_id", account.provider_id);
    formData.append("NoFolio", account.folio_invoice);
    formData.append("typeInvoice", account.type_invoice);
    formData.append("amount", account.amount);
    let url = `/accounts-to-pay/${account.account_id}`;
    MethodPut(url, formData)
      .then((res) => {
        console.log(res.data);
        Swal.fire({
          title: "Cuenta Actualizada",
          text: res.data.message,
          timer: 1000,
          showConfirmButton: false,
          icon: "success",
        });
        dispatch({
          type: UPDATE_ACCOUNTS_FOR_PAYMENT,
          payload: res.data.account,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
      });
  };

  const AddPaymentToAccount = (data) => {
    const formData = new FormData();
    formData.append("provider_id", data.provider_id);
    formData.append("account_id", data.account_id);
    formData.append("amount", data.amount);
    formData.append("folio_trans", data.folio_trans);
    formData.append("voucher", data.voucher);

    let url = `/accounts-to-pay/payment`;
    MethodPost(url, formData, { headerConfig })
      .then((res) => {
        console.log(res.data, "la respuesta al agregar pagos");
        dispatch({
          type: ADD_PAYMENT_TO_ACCOUNT,
          payload: res.data.account,
        });
        Swal.fire({
          title: "Registrado",
          text: res.data.message,
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        }).then(function () {
          window.location.reload();
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
      });
  };

  const downloadPdfAllAccounts = (account) => {
    Swal.fire({
      title: "Descargar PDF",
      text: "Se descargara un PDF con detalle de las cuentas",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, descargar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/accounts-to-pay/ticket-provider/${account._id}`;
        MethodGet(url, { responseType: "blob" })
          .then((res) => {
            let timerInterval;
            Swal.fire({
              title: "Generando",
              html: "El PDF se esta generando!",
              timer: 2000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const b = Swal.getHtmlContainer().querySelector("b");
                timerInterval = setInterval(() => {
                  b.textContent = Swal.getTimerLeft();
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              fileDownload(
                res.data,
                `cuentas_por_pagar_${account.company}.pdf`
              );
              Swal.fire({
                title: "Descargado",
                text: "El PDF de la orden se ha descargado correctamente",
                icon: "success",
                timer: 1500,
                showConfirmButton: false,
              });
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              timer: 1500,
              showConfirmButton: false,
            });
          });
      }
    });
  };

  const downloadPdfAccountDetail = (account) => {
    console.log(account._id, "lo que llega al state");
    Swal.fire({
      title: "Descargar PDF",
      text: "Se descargara un PDF con detalle de las cuentas",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, descargar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/accounts-to-pay/ticket-account/${account._id}`;
        MethodGet(url, { responseType: "blob" })
          .then((res) => {
            let timerInterval;
            Swal.fire({
              title: "Generando",
              html: "El PDF se esta generando!",
              timer: 2000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const b = Swal.getHtmlContainer().querySelector("b");
                timerInterval = setInterval(() => {
                  b.textContent = Swal.getTimerLeft();
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              fileDownload(
                res.data,
                `cuentas_por_pagar_${account.NoFolio}.pdf`
              );
              Swal.fire({
                title: "Descargado",
                text: "El PDF de la orden se ha descargado correctamente",
                icon: "success",
                timer: 1500,
                showConfirmButton: false,
              });
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              timer: 1500,
              showConfirmButton: false,
            });
          });
      }
    });
  };

  return (
    <AccountsForPaymentContext.Provider
      value={{
        accountPayment: state.accountPayment,
        accountsPayment: state.accountsPayment,
        success: state.success,
        ErrorsApi: state.ErrorsApi,
        paymentsInAccount: state.paymentsInAccount,
        accountsProvider: state.accountsProvider,
        GetAccountsPayment,
        AddAccountsPayment,
        UpdateAccountPayment,
        GetAccountsPrvider,
        AddPaymentToAccount,
        downloadPdfAllAccounts,
        downloadPdfAccountDetail,
      }}>
      {children}
    </AccountsForPaymentContext.Provider>
  );
};

export default AccountsState;
