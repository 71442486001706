import React, { useContext, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import OffersContext from "../../context/Offers/OffersContext";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import CategorySelect from "../../containers/SelectOptions/SelectCategory";
import SubcategoriesSelect from "../../containers/SelectOptions/SelectSubcategories";
import ProductsSelect from "../../containers/SelectOptions/ProductSelect";

import { makeStyles } from "@mui/styles";
import {
  Grid,
  Box,
  Fab,
  TextField,
  Tooltip,
  InputLabel,
  IconButton,
} from "@mui/material";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import MethodGet from "../../config/Service";
import SelectTypeOffer from "../SelectOptions/selectTypeOffer";
import Swal from "sweetalert2";

const useStyles = makeStyles({
  logoimagen: {
    height: "200px",
    width: "750px",
    boxShadow: "5px 5px #289FED",
    objectFit: "cover",
  },
  card: {
    marginTop: 7,
    marginLeft: 7,
    marginRight: 7,
  },
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 2,
  },
  input: {
    display: "none",
  },
});

export default function Edit(props) {
  const classes = useStyles();
  const [imageWeb, setImageWeb] = useState({
    urlPhotoWeb:
      "https://cdn.pngsumo.com/index-of-areaedu-wp-content-uploads-2016-02-default-png-600_600.png",
    imageWeb: "",
  });
  const handleChangeImageWeb = (e) => {
    setImageWeb({
      ...imageWeb,
      urlPhotoWeb: URL.createObjectURL(e.target.files[0]),
      imageWeb: e.target.files[0],
    });
  };
  //movil
  const [imageMobile, setImageMobile] = useState({
    urlPhotoMobile:
      "https://cdn.pngsumo.com/index-of-areaedu-wp-content-uploads-2016-02-default-png-600_600.png",
    imageMobile: "",
  });
  const handleChangeImageMobile = (e) => {
    setImageMobile({
      ...imageMobile,
      urlPhotoMobile: URL.createObjectURL(e.target.files[0]),
      imageMobile: e.target.files[0],
    });
  };

  const formEditOffersContext = useContext(OffersContext);
  const { success, UpdateOffers } = formEditOffersContext;
  const [cargando, spinnerCargando] = useState(false);

  const [offer, saveOffer] = useState({});
  const [selectType, saveType] = useState("");
  const [CategoryId, saveCategory] = useState("");
  const [SubcategoryId, saveSubcategory] = useState("");
  const [productId, saveProduct] = useState("");
  const [text, saveText] = useState("");

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues: offer });
  const detectarCambiosCategory = (e) => {
    saveCategory(e.target.value);
  };

  const detectarCambiosSubcategory = (e) => {
    saveSubcategory(e.target.value);
  };

  const detectarCambiosProducts = (e) => {
    saveProduct(e.target.value);
  };
  const detectarCambiosTypeOffer = (e) => {
    saveType(e);
  };
  let offerId = JSON.parse(sessionStorage.getItem("offer"));
  useEffect(() => {
    let url = `/offers/${offerId}`;
    MethodGet(url)
      .then((res) => {
        saveOffer(res.data.offer);
        saveText(res.data.offer.text);
        saveType(res.data.offer.type);
        saveCategory(res.data.offer.category);
        saveSubcategory(res.data.offer.subcategory);
        saveProduct(res.data.offer.product);
        setImageWeb({ urlPhotoWeb: res.data.offer.imageWeb, imageWeb: "" });
        setImageMobile({
          urlPhotoMobile: res.data.offer.imageMobile,
          imageMobile: "",
        });
        spinnerCargando(true);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
        spinnerCargando(true);
      });
  }, []);
  useEffect(() => {
    if (success) {
      props.history.goBack();
    }
  }, [success]);

  const onSubmit = (data, e) => {
    if (!selectType) {
    } else {
      data.type = selectType;
      if (data.type === 1) {
        data.category = CategoryId;
        data.type = selectType;
      } else if (data.type === 2) {
        data.subcategory = SubcategoryId;
        data.type = selectType;
      } else {
        data.type = selectType;
        data.product = productId;
      }
      data.id = offerId;
      data.imageWeb = imageWeb.imageWeb;
      data.imageMobile = imageMobile.imageMobile;
      UpdateOffers(data);
    }
  };
  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#000000",
              textAling: "center",
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            }}>
            <div className={classes.titleCard}>Actualizar Oferta</div>
            <div className={classes.fab}>
              <Tooltip title="Regresar">
                <Link to="/Administracion-ofertas">
                  <Fab
                    size="small"
                    sx={{
                      color: "white",
                      backgroundColor: "black",
                      borderRadius: "24px",
                      marginRight: 1,
                      "&:hover": {
                        backgroundColor: "white",
                        color: "black",
                      },
                    }}>
                    <ArrowCircleLeftOutlinedIcon />
                  </Fab>
                </Link>
              </Tooltip>
            </div>
          </Box>
          <form
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter")
                e.preventDefault();
            }}
            autoComplete="off">
            <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  {text && (
                    <TextField
                      fullWidth
                      id="text"
                      name="text"
                      defaultValue={text}
                      label="Ingrese algun Titulo"
                      variant="outlined"
                      error={errors.text ? true : false}
                      helperText={errors?.text?.message}
                      {...register("text", {
                        required: {
                          value: true,
                          message: "Este campo es requerido",
                        },
                        minLength: {
                          value: 4,
                          message: "Minimo 4 caracteres",
                        },
                        maxLength: {
                          value: 255,
                          message: "Maximo 255 caracteres",
                        },
                      })}
                      sx={{ color: "#343a40" }}
                    />
                  )}
                </Grid>
                {selectType !== "" && (
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <SelectTypeOffer
                      selectType={selectType}
                      detectarCambiosTypeOffer={detectarCambiosTypeOffer}
                    />
                  </Grid>
                )}
                {selectType == 1 && CategoryId !== "" && (
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <CategorySelect
                      CategoryId={CategoryId}
                      detectarCambiosCategory={detectarCambiosCategory}
                    />
                  </Grid>
                )}
                {selectType == 2 && SubcategoryId !== "" && (
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <SubcategoriesSelect
                      SubcategoryId={SubcategoryId}
                      detectarCambiosSubcategory={detectarCambiosSubcategory}
                    />
                  </Grid>
                )}
                {selectType == 3 && productId !== "" && (
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <ProductsSelect
                      productId={productId}
                      detectarCambiosProducts={detectarCambiosProducts}
                    />
                  </Grid>
                )}
                {/**Slider WEB */}
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    <div>
                      <img
                        src={imageWeb.urlPhotoWeb}
                        className={classes.logoimagen}
                        alt="agrega slider web"
                      />
                    </div>
                  </Box>

                  <input
                    className={classes.input}
                    id="icon-button--web"
                    type="file"
                    name="imageWeb"
                    accept="image/png, image/jpg, image/jpeg"
                    onChange={handleChangeImageWeb}
                  />
                  <InputLabel sx={{ textAlign: "center" }}>
                    Selecciona el Slider para el Ecommerce Web:
                    <label htmlFor="icon-button--web">
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span">
                        <Tooltip
                          title="seleccionar imagen"
                          aria-label="seleccionar imagen">
                          <PhotoCamera />
                        </Tooltip>
                      </IconButton>
                    </label>
                  </InputLabel>
                </Grid>
                {/**Slider movile */}
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    <div>
                      <img
                        src={imageMobile.urlPhotoMobile}
                        className={classes.logoimagen}
                        alt="slider mobile"
                      />
                    </div>
                  </Box>

                  <input
                    className={classes.input}
                    id="icon-button-file-mobile"
                    type="file"
                    name="imageMobile"
                    accept="image/png, image/jpg, image/jpeg"
                    onChange={handleChangeImageMobile}
                  />
                  <InputLabel sx={{ textAlign: "center" }}>
                    Selecciona el Slider para el Ecommerce Movil:
                    <label htmlFor="icon-button-file-mobile">
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span">
                        <Tooltip
                          title="seleccionar imagen"
                          aria-label="seleccionar imagen">
                          <PhotoCamera />
                        </Tooltip>
                      </IconButton>
                    </label>
                  </InputLabel>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      textAling: "center",
                      paddingBottom: 8,
                    }}>
                    <div className={classes.fab}>
                      <Fab
                        type="submit"
                        variant="extended"
                        sx={{
                          backgroundColor: "#00B9FF",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#00DEED",
                            color: "white",
                          },
                        }}>
                        <SaveOutlinedIcon sx={{ mr: 1 }} />
                        Guardar
                      </Fab>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Grid>
      </Grid>
    </Layout>
  );
}
