import React from "react";
import Layout from "../../components/layout/Layout";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Button, Radio, Typography } from "@mui/material";
import ComponentsBarcode from "./ComponentsBarcode";
import TableProducts from "./TableProducts";
import { useState } from "react";
import MethodGet from "../../config/Service";
import { useEffect } from "react";
import FormatDate from "../../utils/FormatDate";
import InventoriesContext from "../../context/Inventories/InventoriesContext";
import { useContext } from "react";
import SelectProduct from "./SelectProduct";
import InfoInventories from "./InfoInventories";
import Swal from "sweetalert2";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
export default function ShowInventory() {
  const [productoID, guardarProductoID] = useState("");
  const [inventario, guardarInventario] = useState([]);
  const [productsOnInventory, saveProductsOnInventory] = useState([]);
  const [info, saveInfo] = useState();
  const [inventory, setInventory] = useState("");
  const [products_inventoried, saveProducts_inventoried] = useState();
  const [cantidad, guardarCantidad] = useState(0);
  const [index, saveIndex] = useState();
  const [selectedValueRadio, setSelectedValueRadio] = useState("barcode");
  const handleChangeRadio = (event) => {
    setSelectedValueRadio(event.target.value);
  };

  const { UpdateBarcode, ExportInventoryExcel, endInventory, success } =
    useContext(InventoriesContext);
  let InventoryStorageID = JSON.parse(sessionStorage.getItem("inventory"));
  useEffect(() => {
    let url = `/inventory/${InventoryStorageID}`;
    MethodGet(url)
      .then((res) => {
        saveInfo(res.data);
        setInventory(res.data.inventory);
        saveProducts_inventoried(res.data.inventory.products.length);
        saveProductsOnInventory(res.data.inventory.products);
        guardarInventario(res.data.inventory.products);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
      });
  }, [UpdateBarcode, success]);
  const orderItems = inventario.toReversed();
  return (
    <Layout>
      <Grid container spacing={2} sx={{ padding: 1 }}>
        <Grid item xs={12}>
          {/* <Item> */}
          <Typography
            textAlign="center"
            variant="h4"
            fontSize="bold"
            color="black"
          >
            Inventario
          </Typography>
          {/* </Item> */}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          display="flex"
          justifyContent="center"
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={8}
              xl={8}
              display="flex"
              justifyContent="center"
            >
              <InfoInventories info={info} inventoried={products_inventoried} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              display="flex"
              justifyContent="center"
            >
              {inventory.status !== 2 ||
              info.inventory.quantityProducts === 0 ? (
                <Button
                  variant="contained"
                  sx={{
                    background: "#f06292",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#f06292",
                      color: "white",
                    },
                  }}
                  onClick={() => endInventory(inventory._id)}
                >
                  Terminar Inventario
                </Button>
              ) : (
                <>
                  <Button
                    onClick={() => ExportInventoryExcel(inventory._id)}
                    sx={{
                      background: "#f06292",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#f06292",
                        color: "white",
                      },
                    }}
                  >
                    Descargar detalle de inventario
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Item>
            <Grid container spacing={2} justifyContent="center">
              {inventory.status !== 2 && (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    display="flex"
                    justifyContent="center"
                  >
                    <label>Codigo de barras</label>
                    <Radio
                      checked={selectedValueRadio === "barcode"}
                      onChange={handleChangeRadio}
                      value="barcode"
                      name="radio-buttons"
                      inputProps={{ "aria-label": "A" }}
                    />
                    <label>Seleccionar Producto</label>
                    <Radio
                      checked={selectedValueRadio === "select"}
                      onChange={handleChangeRadio}
                      value="select"
                      name="radio-buttons"
                      inputProps={{ "aria-label": "B" }}
                    />
                  </Grid>
                  {selectedValueRadio === "barcode" ? (
                    <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
                      {inventory.status !== 2 && (
                        <ComponentsBarcode
                          guardarProductoID={guardarProductoID}
                          inventario={inventario}
                          guardarInventario={guardarInventario}
                          id_inventory={InventoryStorageID}
                          cantidad={cantidad}
                          guardarCantidad={guardarCantidad}
                          index={index}
                          inventory={inventory}
                        />
                      )}
                    </Grid>
                  ) : (
                    <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
                      <SelectProduct
                        productsOnInventory={productsOnInventory}
                        guardarProductoID={guardarProductoID}
                        inventario={inventario}
                        guardarInventario={guardarInventario}
                        id_inventory={InventoryStorageID}
                        cantidad={cantidad}
                        guardarCantidad={guardarCantidad}
                        index={index}
                        inventory={inventory}
                      />
                    </Grid>
                  )}
                </>
              )}

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography
                  textAlign="center"
                  fontFamily="monospace"
                  fontSize="25px"
                >
                  Detalle inventario
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TableProducts
                  inventario={orderItems}
                  guardarInventario={guardarInventario}
                  cantidad={cantidad}
                  guardarCantidad={guardarCantidad}
                  saveIndex={saveIndex}
                  index={index}
                  inventory={inventory}
                />
              </Grid>
            </Grid>
          </Item>
        </Grid>
      </Grid>
    </Layout>
  );
}
