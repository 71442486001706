import React, { useState, useEffect } from "react";
import MethodGet from "../../config/Service";
import { Link } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import ModalImage from "react-modal-image-responsive";

import { makeStyles } from "@mui/styles";
import { Box, Card, Typography, Tooltip, Grid, Fab } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import SpinnerComponent from "../loading/SpinnerComponent";
import Swal from "sweetalert2";

const useStyles = makeStyles({
  logoimagen: {
    height: "170px",
    width: "140px",
    boxShadow: "5px 5px #289FED",
    objectFit: "cover",
    margin: "auto",
  },
  input: {
    display: "none",
  },
  divcontainerabout: {
    width: "100%",
    position: "relative",
  },
  divimageabout: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 10,
  },
});

export default function AboutComponent() {
  const [about, setAbout] = useState();
  const [image, setImage] = useState({
    urlPhoto:
      "https://cdn.pngsumo.com/index-of-areaedu-wp-content-uploads-2016-02-default-png-600_600.png",
    image: "",
  });

  const [image2, setImage2] = useState({
    urlPhoto2:
      "https://cdn.pngsumo.com/index-of-areaedu-wp-content-uploads-2016-02-default-png-600_600.png",
    image2: "",
  });
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const GetAbout = async () => {
    let url = await "/administrable/about";
    MethodGet(url)
      .then(({ data }) => {
        setAbout(data.about);
        setImage({ urlPhoto: data.about.about.aboutThat.image, image: "" });
        setImage2({ urlPhoto2: data.about.about.mission.image, image2: "" });
        setLoading(false);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: error.response.data.message,
          timer: 2000,
          showConfirmButton: false,
        });
      });
  };

  useEffect(() => {
    GetAbout();
  }, []);
  if (loading)
    return (
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Box
          sx={{
            width: "105%",
            height: "177%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <SpinnerComponent />
        </Box>
      </Grid>
    );
  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Card>
        <Box
          fullWidth
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingX: 2,
            backgroundColor: "white",
            color: "white",
            background: "black",
          }}>
          <Typography
            variant="su arrowbtitle2"
            noWrap
            sx={{ fontWeight: "bold", fontSize: "17px" }}
            className="blink-1">
            Historia
          </Typography>
          {/* <div> {brand.name}</div> */}
          {/* <div> </div> */}
        </Box>
        <Box
          sx={{
            padding: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}>
          <div className={classes.divcontainerabout}>
            {/* <code>{about.about.aboutThat.aboutThat}</code> */}
            {/* {about.about.aboutThat.aboutThat} */}
            {/* <pre>{about.about.aboutThat.aboutThat }</pre>
            {about.about.aboutThat.aboutThat } */}
            {/* <Editor
              apiKey="opffcue24watiq15cj3hettul2z68aozru57cmwm2du2bllj"
              initialValue={about.about.aboutThat.aboutThat}
              inline={true}
            /> */}
            <Editor
              apiKey="4oemglfeurpxtmylg59yopah4kn16o7e3ma4ryvm44spbnlr"
              initialValue={about.about.aboutThat.aboutThat}
              inline={true}
              disabled={true}
            />
          </div>

          <div className={classes.divcontainerabout}>
            <ModalImage
              className={classes.logoimagen}
              small={image.urlPhoto}
              large={image.urlPhoto}
              alt="Historia"
            />
            <div className={classes.divimageabout}>
              <Tooltip title="Editar">
                <Link to="/editar-historia-nosotros">
                  <Fab
                    //   onClick={handleClickOpenAdd}
                    size="small"
                    sx={{
                      color: "white",
                      backgroundColor: "black",
                      borderRadius: "24px",
                      marginRight: 1,
                      "&:hover": {
                        backgroundColor: "white",
                        color: "black",
                      },
                    }}>
                    <EditIcon />
                  </Fab>
                </Link>
              </Tooltip>
            </div>
          </div>
        </Box>
        <Box
          fullWidth
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingX: 2,
            backgroundColor: "white",
            color: "white",
            background: "black",
          }}>
          <Typography
            variant="su arrowbtitle2"
            noWrap
            sx={{ fontWeight: "bold", fontSize: "17px" }}
            className="blink-1">
            Mision
          </Typography>
          {/* <div> {brand.name}</div> */}
          {/* <div> </div> */}
        </Box>
        <Box
          sx={{
            padding: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}>
          <div className={classes.divcontainerabout}>
            <Editor
              apiKey="662cf267cfa3db073a1a3aa76ded1fb4be467007320a48ce75cb84ffdc42d381"
              initialValue={about.about.mission.mission}
              inline={true}
              disabled={true}
            />
          </div>

          <div className={classes.divcontainerabout}>
            <ModalImage
              className={classes.logoimagen}
              small={image2.urlPhoto2}
              large={image2.urlPhoto2}
              alt="Mision"
            />
            <div className={classes.divimageabout}>
              <Tooltip title="Editar">
                <Link to="/editar-mision-nosotros">
                  <Fab
                    //   onClick={handleClickOpenAdd}
                    size="small"
                    sx={{
                      color: "white",
                      backgroundColor: "black",
                      borderRadius: "24px",
                      marginRight: 1,
                      "&:hover": {
                        backgroundColor: "white",
                        color: "black",
                      },
                    }}>
                    <EditIcon />
                  </Fab>
                </Link>
              </Tooltip>
            </div>
          </div>
        </Box>
      </Card>
    </Grid>
  );
}
