import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Button, Grid, TextField, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import FormatDate from "../../../utils/FormatDate";
import ProductsContext from "../../../context/Products/ProductsContext";
import ModalMovements from "./ModalMovements";
import { useDebounce } from "use-debounce";
import NoDataComponents from "../../../components/loading/NoDataComponents";
import NoResultComponent from "../../../components/loading/NoResultsComponent";
import { Paginate } from "../../../components/Pagination/Paginate";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles(() => ({
  titleCard: {
    color: "white",
    fontSize: "35px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 2,
  },
}));

export default function Index() {
  const classes = useStyles();
  const [modal, abrirModal] = useState(false);
  const handleClickOpenModal = () => {
    abrirModal(true);
  };
  const handleClickClose = () => {
    abrirModal(false);
  };
  const {
    movements,
    indexMovements,
    success,
    totalPages,
    searchProductMovement,
    search_products_movements,
  } = useContext(ProductsContext);
  //busqueda
  const [palabra, setPalabra] = useState(null);
  const [debouncedWord] = useDebounce(palabra, 500);

  useEffect(() => {
    if (palabra !== null) {
      searchProductMovement(palabra);
    }
  }, [debouncedWord]);
  //paginacion
  var [pages, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const limite = rowsPerPage;

  const params = { limite, pages };
  useEffect(() => {
    indexMovements(params);
  }, [success, rowsPerPage, pages]);
  //console.log(movements);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        {!movements.length ? (
          <></>
        ) : (
          <TextField
            fullWidth
            label="Buscar por nombre del Producto"
            id="filled-size-small"
            variant="outlined"
            size="small"
            onChange={({ target }) => {
              setPalabra(target.value);
              //FilterProducts(target.value);
            }}
          />
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        display="flex"
        justifyContent="end"
      >
        <Button
          variant="contained"
          onClick={handleClickOpenModal}
          sx={{
            backgroundColor: "#1565c0",
            color: "white",
            marginTop: 1,
            marginRight: 3,
            "&:hover": { backgroundColor: "#1565c0", color: "white" },
          }}
        >
          Reporte de movimientos
        </Button>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        {palabra &&
          (!search_products_movements.length ? (
            <NoResultComponent />
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">C. Barras</StyledTableCell>
                    <StyledTableCell align="center">Producto</StyledTableCell>
                    <StyledTableCell align="center">
                      Existencia Anterior
                    </StyledTableCell>
                    <StyledTableCell align="center">Diferencia</StyledTableCell>
                    <StyledTableCell align="center">Existente</StyledTableCell>
                    <StyledTableCell align="center">Desc.</StyledTableCell>
                    <StyledTableCell align="center">Tipo Mov.</StyledTableCell>
                    <StyledTableCell align="center">
                      Fecha de movimiento
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {search_products_movements.map((movement, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                        {movement.barcode
                          ? movement.barcode
                          : movement.product_id
                          ? movement.product_id.barcode
                          : "Producto borrado"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {movement.name
                          ? movement.name
                          : movement.product_id
                          ? movement.product_id.name
                          : "Producto borrado"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {movement.current_quantity}
                      </StyledTableCell>
                      {movement.current_quantity > movement.new_quantity ? (
                        <StyledTableCell align="center">
                          <Typography sx={{ color: "red" }}>
                            - {movement.diference}
                          </Typography>
                        </StyledTableCell>
                      ) : movement.current_quantity < movement.new_quantity ? (
                        <StyledTableCell align="center">
                          <Typography sx={{ color: "green" }}>
                            + {movement.diference}
                          </Typography>
                        </StyledTableCell>
                      ) : (
                        "N/A"
                      )}
                      <StyledTableCell align="center">
                        {movement.new_quantity}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {movement.description ? movement.description : "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {movement.type === 1
                          ? "Movimiento de stock gral."
                          : movement.type === 2
                          ? "movimiento de producto por venta"
                          : "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {FormatDate(movement.createdAt)}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ))}
        {!palabra &&
          (!movements.length ? (
            <NoDataComponents />
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">C. Barras</StyledTableCell>
                    <StyledTableCell align="center">Producto</StyledTableCell>
                    <StyledTableCell align="center">
                      Existencia Anterior
                    </StyledTableCell>
                    <StyledTableCell align="center">Diferencia</StyledTableCell>
                    <StyledTableCell align="center">Existente</StyledTableCell>
                    <StyledTableCell align="center">Desc.</StyledTableCell>
                    <StyledTableCell align="center">Tipo Mov.</StyledTableCell>
                    <StyledTableCell align="center">
                      Fecha de movimiento
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {movements.map((movement, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                        {movement.barcode
                          ? movement.barcode
                          : movement.product_id
                          ? movement.product_id.barcode
                          : "Producto borrado"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {movement.name
                          ? movement.name
                          : movement.product_id
                          ? movement.product_id.name
                          : "Producto borrado"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {movement.current_quantity}
                      </StyledTableCell>
                      {movement.current_quantity > movement.new_quantity ? (
                        <StyledTableCell align="center">
                          <Typography sx={{ color: "red" }}>
                            - {movement.diference}
                          </Typography>
                        </StyledTableCell>
                      ) : movement.current_quantity < movement.new_quantity ? (
                        <StyledTableCell align="center">
                          <Typography sx={{ color: "green" }}>
                            + {movement.diference}
                          </Typography>
                        </StyledTableCell>
                      ) : (
                        "No se ha modificado"
                      )}
                      <StyledTableCell align="center">
                        {movement.new_quantity}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {movement.description ? movement.description : "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {movement.type === 1
                          ? "Movimiento de stock gral."
                          : movement.type === 2
                          ? "movimiento de producto por venta"
                          : "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {FormatDate(movement.createdAt)}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ))}
      </Grid>
      {!palabra &&
        (!movements.length ? (
          <></>
        ) : (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            display="flex"
            justifyContent="center"
            sx={{ marginTop: 2 }}
          >
            <Paginate
              pages={pages}
              handleChangePage={handleChangePage}
              totalPages={totalPages}
            />
          </Grid>
        ))}
      <ModalMovements
        modal={modal}
        handleClickOpen={handleClickOpenModal}
        handleClickClose={handleClickClose}
      />
    </Grid>
  );
}
