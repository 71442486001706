import {
  Box,
  Button,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Layout from "../../components/layout/Layout";
import { useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useContext } from "react";
import AccountsForPaymentContext from "./../../context/AccountsForPayment/AccountsForPaymentContext";
import MethodGet from "../../config/Service";
import { PriceFormat } from "./../../utils/FormatPrice";

const useStyles = makeStyles({
  logoimagen: {
    height: "200px",
    //boxShadow: "5px 5px #289FED",
    objectFit: "cover",
  },
  input: {
    display: "none",
  },
});

const AddPayAccount = (props) => {
  const providerId = props.match.params.providerId;
  const accountId = props.match.params.accountId;
  const { AddPaymentToAccount } = useContext(AccountsForPaymentContext);
  const classes = useStyles();
  const [file_first, setFile] = useState({
    file: "",
  });
  const [account, saveAccount] = useState([]);
  const [cargando, spinnerCargando] = useState([]);
  useEffect(() => {
    let url = `/accounts-to-pay/${accountId}`;
    MethodGet(url)
      .then((res) => {
        saveAccount(res.data.account);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
        spinnerCargando(true);
      });
  }, [accountId]);

  const handleChangeFile = (e) => {
    setFile({
      ...file_first,
      file: e.target.files[0],
    });
  };
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  const reset = () => {
    setValue("amount", "", { shouldDirty: true });
    setValue("folio_trans", "", { shouldDirty: true });
    setFile("");
  };

  const onSubmit = (data, e) => {
    data.voucher = file_first.file;
    data.provider_id = providerId;
    data.account_id = accountId;
    AddPaymentToAccount(data);
    props.history.goBack();
    reset();
  };
  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ backgroundColor: "black", color: "white" }}>
          <Typography fontWeight="bold" fontSize="25px" sx={{ marginLeft: 2 }}>
            Agregar Pago a cuenta
          </Typography>
        </Grid>
      </Grid>
      {account && (
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            display="flex"
            justifyContent="center">
            <Typography fontWeight="bold">
              Total a pagar: $ {PriceFormat(account.total)}{" "}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            display="flex"
            justifyContent="center">
            <Typography fontWeight="bold">
              Total pagado: $ {PriceFormat(account.totalPayment)}{" "}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            display="flex"
            justifyContent="center">
            <Typography fontWeight="bold">
              Resta por pagar: ${" "}
              {PriceFormat(account.total - account.totalPayment)}{" "}
            </Typography>
          </Grid>
        </Grid>
      )}
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.code === "Enter" || e.code === "NumpadEnter")
            e.preventDefault();
        }}
        autoComplete="off">
        <Grid container spacing={2} sx={{ padding: 2 }}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              fullWidth
              name="folio_trans"
              label="Folio transaccion"
              error={errors.folio_trans ? true : false}
              helperText={errors?.folio_trans?.message}
              {...register("folio_trans", {
                required: {
                  value: true,
                  message: "Este campo es requerido",
                },
              })}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              fullWidth
              name="amount"
              label="Monto"
              type="number"
              error={errors.amount ? true : false}
              helperText={errors?.amount?.message}
              {...register("amount", {
                required: {
                  value: true,
                  message: "Este campo es requerido",
                },
              })}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            display="flex"
            justifyContent="center">
            <Button
              variant="contained"
              component="label"
              startIcon={<AttachFileIcon />}
              onChange={handleChangeFile}
              sx={{
                color: "white",
                backgroundColor: "#ad1457",
                "&:hover": { backgroundColor: "#ad1457", color: "white" },
              }}>
              Adjuntar Comprobante
              <input hidden accept="application/pdf" multiple type="file" />
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            display="flex"
            justifyContent="center">
            <div>
              <Typography variant="overline" display="block" gutterBottom>
                Archivo Seleccionado: {""}
                {file_first.file !== ""
                  ? file_first?.file?.name
                  : " No se ha seleccionado ningun archivo"}
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            display="flex"
            justifyContent="end">
            <Button size="large" variant="contained" type="submit">
              Guardar
            </Button>
          </Grid>
        </Grid>
      </form>
    </Layout>
  );
};

export default AddPayAccount;
