import ModulesCOntext from "../../context/Permissions/PermissionsContext";
import React, { useEffect } from "react";
import { useContext } from "react";
import { Fragment } from "react";
import Select from "react-select";

const SelectModule = (props) => {
  const { permissions, GetPermissions } = useContext(ModulesCOntext);
  useEffect(() => {
    GetPermissions();
  }, []);

  const detectarCambiosModules = (value) => {
    props.detectarCambiosModules(value);
  };
  return (
    <Fragment>
      <Select
        onChange={(value) => detectarCambiosModules(value)}
        className="basic-single"
        classNamePrefix="select"
        name="client"
        placeholder="Selecciona Modulo"
        options={permissions.map((modules) => {
          let attribute = {
            label: modules?.name,
            value: modules?._id,
          };
          return attribute;
        })}
      />
    </Fragment>
  );
};

export default SelectModule;
