import { formatHourNow } from "../../utils/FormatHour";
import formatDate from "../../utils/FormatDate";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles(() => ({
  fechastyle: {
    textAlign: "center",
  },
}));

export const DateTime = ({ Order }) => {
  const classes = useStyles();
  const { dateFormatted } = Order;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div className={classes.fechastyle}>
        <Typography sx={{ fontWeight: "bold" }}>
          Fecha y Hora de Registro de Orden
        </Typography>
        <Typography>{dateFormatted}</Typography>
      </div>
      {/* <div className={classes.fechastyle}>
        <Typography sx={{ fontWeight: "bold" }}>Hora de Registro</Typography>
        <Typography>{dateFormatted}</Typography>
      </div> */}
    </Box>
  );
};
