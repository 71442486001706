import React, { Fragment, useState } from "react";
import AuthContext from "../../../context/auth/authContext";
import Select from "react-select";
import { useContext } from "react";
import { useEffect } from "react";
import tokenAuth from "../../../config/TokenAuth";
import MethodGet from "../../../config/Service";
import Swal from "sweetalert2";
export default function SelectDirectionsUser(props) {
  let id_client = props.id_client;
  const [directions, saveDirections] = useState([]);
  useEffect(() => {
    let url = `/admin/auth/directions-by-id/${id_client}`;
    MethodGet(url)
      .then((res) => {
        saveDirections(res.data.directions);
      })
      .catch((error) => {
        Swal.fire({
          title: "error",
          text: error.response.data.message,
          icon: "error",
        });
      });
  }, [id_client]);

  const detectarCambiosDirection = (value) => {
    props.detectarCambiosDirection(value);
  };
  const nuevoArreglo = directions.map((direction) => {
    const directionName = `${direction.name},  ${direction.street}, ${direction.city}, ${direction.postalcode}, ${direction.municipality.name}, ${direction.state.name}`;
    const nameDirection = `${direction.name}`;
    return { value: direction._id, label: directionName, name: nameDirection };
  });
  return (
    <Fragment>
      <Select
        onChange={(value) => detectarCambiosDirection(value)}
        className="basic-single"
        classNamePrefix="select"
        name="product"
        placeholder="Selecciona direccion"
        options={nuevoArreglo}
      />
    </Fragment>
  );
}
