import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Layout from "../../components/layout/Layout";
import { Divider, Typography } from "@mui/material";
import SelectDate from "../../components/Dashboard/SelectDate";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

export default function Reports() {
	return (
		<Layout>
			<Grid container spacing={2} justifyContent="center" sx={{ padding: 1 }}>
				<Grid item xs={12}>
					<Item>
						<Typography variant="h4" fontFamily="monospace">
							Reportes
						</Typography>
					</Item>
				</Grid>
				<Grid item xs={6}>
					<Item>
						<Typography variant="h5" fontFamily="cursive">
							Reporte de entrada de productos
						</Typography>
						<Divider sx={{ marginBottom: 2, marginTop: 2 }} />
						<SelectDate />
					</Item>
				</Grid>
				<Grid item xs={6}>
					<Item>
						<Typography variant="h5" fontFamily="cursive">
							Corte de caja
						</Typography>
						<Divider sx={{ marginBottom: 2, marginTop: 2 }} />
						<SelectDate />
					</Item>
				</Grid>
				<Grid item xs={6}>
					<Item>
						<Typography variant="h5" fontFamily="cursive">
							Productos mas vendidos
						</Typography>
						<Divider sx={{ marginBottom: 2, marginTop: 2 }} />
						<SelectDate />
					</Item>
				</Grid>
				{/* <Grid item xs={6}>
					<Item>
						<Typography variant="h5" fontFamily="cursive">
							Ventas
						</Typography>
					</Item>
				</Grid>
				<Grid item xs={6}>
					<Item>
						<Typography variant="h5" fontFamily="cursive">
							Ventas
						</Typography>
					</Item>
				</Grid> */}
			</Grid>
		</Layout>
	);
}
