import fileDownload from "js-file-download";
import React, { useReducer } from "react";
import Swal from "sweetalert2";
import MethodGet, { MethodPost } from "../../config/Service";
import {
  CREATE_SALE,
  GET_ONE_SALE,
  GET_SALES_FISIC,
  SHOW_ERRORS_API,
} from "../../types";
import SalesFisicContext from "./SalesFisicContext";
import SalesFisicReducer from "./SalesFisicReducer";
import headerConfig from "../../config/imageHeaders";
const SalesFisicState = ({ children }) => {
  const initialState = {
    sales_fisic: [],
    sale_fisic: null,
    success: false,
  };

  const [state, dispatch] = useReducer(SalesFisicReducer, initialState);

  // const SendCart = (order) => {
  //   const formData = new FormData();
  //   formData.append("productsDiscount", order.productsDiscount);
  //   formData.append("productsWithoutDiscounr", order.productsWithoutDiscount);
  //   formData.append("customer_id", order.customer);

  //   let url = "/sale";
  //   MethodPost(url, formData).then((res) => {});
  // };

  const GetSalesFisic = (params) => {
    let url = `/sale?limit=${params.limite}&page=${params.pages}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_SALES_FISIC,
          payload: {
            sales_fisic: res.data.sales,
            totalDocs: res.data.totalDocs,
            limit: res.data.limit,
            page: res.data.page,
            paginCounter: res.data.paginCounter,
            hasPrevPage: res.data.hasPrevPage,
            hasNextPage: res.data.hasNextPage,
            prevPage: res.data.prevPage,
            nextPage: res.data.nextPage,
            totalPages: res.data.totalPages,
          },
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
      });
  };

  const CreateSale = (sale) => {
    const formData = new FormData();
    // formData.append("bank_account_id", sale.bank_account_id);
    // if (sale.type === 2 || sale.type === 3) {
    //   formData.append("image", sale.image);
    // }
    // formData.append("amount", sale.amount);
    // formData.append("type", Number(sale.type));
    formData.append(
      "payments",
      JSON.stringify(sale.payments) ? JSON.stringify(sale.payments) : []
    );
    formData.append(
      "productsDiscount",
      JSON.stringify(sale.productsDiscount)
        ? JSON.stringify(sale.productsDiscount)
        : []
    );
    formData.append(
      "productsWithoutDiscount",
      JSON.stringify(sale.productsWithoutDiscount)
        ? JSON.stringify(sale.productsWithoutDiscount)
        : []
    );
    formData.append("customer_id", sale.customer_id);
    if (sale.imageCard) {
      formData.append("imageCard", sale.imageCard);
    }
    if (sale.imageTransfer) {
      formData.append("imageTransfer", sale.imageTransfer);
    }

    let url = `/sale/create/sale`;
    MethodPost(url, formData, { headerConfig })
      .then((res) => {
        dispatch({
          type: CREATE_SALE,
          payload: res.data.sale,
        });
        Swal.fire({
          title: "Venta Registrada",
          text: res.data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
      });
  };
  const SaveCurrentSaleFisic = (sale_fisic) => {
    sessionStorage.setItem("sale_fisic", JSON.stringify(sale_fisic));
    dispatch({
      type: GET_ONE_SALE,
      payload: sale_fisic,
    });
  };

  const DownloadPdf = (order) => {
    Swal.fire({
      title: "¿Descargar PDF de la venta?",
      text: "Se descargará el pdf de la venta",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, descargar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `sale/report/pdf/${order._id}`;
        MethodGet(url, { responseType: "blob" })
          .then((res) => {
            let timerInterval;
            Swal.fire({
              title: "Generando!",
              html: "El PDF se esta generando!",
              timer: 2000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const b = Swal.getHtmlContainer().querySelector("b");
                timerInterval = setInterval(() => {
                  b.textContent = Swal.getTimerLeft();
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              fileDownload(res.data, `Orden_${order.no_folio}.pdf`);
              Swal.fire({
                title: "Descargado",
                text: "El PDF de la venta se ha descargado correctamente",
                icon: "success",
                timer: 1500,
                showConfirmButton: false,
              });
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              icon: "error",
              text: "Se ha producido un error al descargar el PDF",
            });
            dispatch({
              type: SHOW_ERRORS_API,
            });
          });
      }
    });
  };

  const DownloadTicket = (order) => {
    Swal.fire({
      title: "¿Descargar Ticket de la venta?",
      text: "Se descargará el Ticket de la venta",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, descargar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/sale/ticket/pdf/${order._id}`;
        MethodGet(url, { responseType: "blob" })
          .then((res) => {
            let timerInterval;
            Swal.fire({
              title: "Generando!",
              html: "El Ticket se esta generando!",
              timer: 2000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const b = Swal.getHtmlContainer().querySelector("b");
                timerInterval = setInterval(() => {
                  b.textContent = Swal.getTimerLeft();
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              fileDownload(res.data, `Venta_${order.no_folio}.pdf`);
              Swal.fire({
                title: "Descargado",
                text: "El Ticket de la venta se ha descargado correctamente",
                icon: "success",
                timer: 1500,
                showConfirmButton: false,
              });
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              icon: "error",
              text: "Se ha producido un error al descargar el Ticket",
            });
            dispatch({
              type: SHOW_ERRORS_API,
            });
          });
      }
    });
  };

  return (
    <SalesFisicContext.Provider
      value={{
        sales_fisic: state.sales_fisic,
        success: state.success,
        totalDocs: state.totalDocs,
        limit: state.limit,
        page: state.page,
        paginCounter: state.paginCounter,
        hasPrevPage: state.hasPrevPage,
        prevPage: state.prevPage,
        hasNextPage: state.hasNextPage,
        nextPage: state.nextPage,
        loading: state.loading,
        totalPages: state.totalPages,
        CreateSale,
        GetSalesFisic,
        SaveCurrentSaleFisic,
        DownloadPdf,
        DownloadTicket,
      }}>
      {children}
    </SalesFisicContext.Provider>
  );
};

export default SalesFisicState;
