import React from "react";
import {
  ADD_ACCOUNTS,
  CURRENT_ACCOUNTS,
  DELETE_ACCOUNTS,
  GET_ACCOUNT_ECOMMERCE,
  GET_ACCOUNT_FISIC,
  GET_ALL_ACCOUNTS,
  SHOW_ERRORS_API,
  UPDATE_ACCOUNTS,
} from "../../types";

export default function AccountsReducer(state, action) {
  switch (action.type) {
    case GET_ALL_ACCOUNTS:
      return {
        ...state,
        accounts: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case GET_ACCOUNT_ECOMMERCE:
      return {
        ...state,
        accounts_eccomerce: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case GET_ACCOUNT_FISIC:
      return {
        ...state,
        accounts_fisic: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case ADD_ACCOUNTS:
      return {
        ...state,
        success: true,
        ErrorsAPI: [],
      };
    case SHOW_ERRORS_API:
      return {
        ...state,
        success: true,
        ErrorsAPI: [],
      };
    case CURRENT_ACCOUNTS:
      return {
        ...state,
        account: action.payload,
      };
    case UPDATE_ACCOUNTS:
      return {
        ...state,
        success: true,
        ErrorsAPI: [],
      };
    case DELETE_ACCOUNTS:
      return {
        ...state,
        success: true,
        accounts: state.accounts.filter(
          (account) => account.id !== action.payload
        ),
      };
    default:
      return state;
  }
}
