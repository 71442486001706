import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import Layout from "../../../components/layout/Layout";
import MethodGet from "../../../config/Service";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { PriceFormat } from "./../../../utils/FormatPrice";
import SpinnerComponent from "./../../../components/loading/SpinnerComponent";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const DetailAccount = (props) => {
  const idAccount = props.match.params.idAccount;
  const [account, saveAccount] = useState();
  const [payments, savePayments] = useState();
  useEffect(() => {
    let url = `/accounts-to-receive/${idAccount}`;
    MethodGet(url)
      .then((res) => {
        saveAccount(res.data.account);
        savePayments(res.data.account.payments);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [idAccount, account]);
  return (
    <Layout>
      <Box sx={{ backgroundColor: "black", color: "white" }}>
        <Typography fontSize="35px" fontWeight="bold">
          Detalle de cuenta
        </Typography>
      </Box>
      {account && payments ? (
        <Grid container spacing={2} sx={{ padding: 2 }}>
          <>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <Typography fontWeight="bold" fontSize="25px">
                Total: $ {PriceFormat(account.total)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <Typography fontWeight="bold" fontSize="25px">
                Total Pagado: $ {PriceFormat(account.totalPayment)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <Typography fontWeight="bold" fontSize="25px">
                Resta por pagar: $ {""}
                {PriceFormat(account.total - account.totalPayment)}
              </Typography>
            </Grid>
          </>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">Monto</StyledTableCell>
                    <StyledTableCell align="center">
                      Folio Transaccion
                    </StyledTableCell>
                    <StyledTableCell align="center">Opciones</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payments.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                        $ {PriceFormat(row.amount)}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.folio_trans}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Tooltip label="Descargar comprobante" placement="top">
                          <IconButton>
                            <a href={row.voucher} rel="noreferrer">
                              <PictureAsPdfIcon sx={{ color: "red" }} />
                            </a>
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      ) : (
        <SpinnerComponent />
      )}
    </Layout>
  );
};

export default DetailAccount;
