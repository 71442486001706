//MIDDLEWARE
export const MIDDLEWARE_TOGGLE = "MIDDLEWARE_TOGGLE";
export const MIDDLEWARE = "MIDDLEWARE";
export const ERROR_MIDDLEWARE = "ERROR_MIDDLEWARE";

//login e inicio de sesion
export const types = {
  MOSTRAR_ALERTA: "[AUTH] mostrar alerta",
  OCULTAR_ALERTA: "[AUTH] ocultar alerta",
  LOGIN_ERROR: "[AUTH] login error",
  LOGIN_EXITOSO: "[AUTH] login existoso",
  OBTENER_USUARIO: "[AUTH] obtener usuario",
  CERRAR_SESION: "[AUTH] cerrar sesion",
  INICIAR_SESION: "[AUTH] inicia sesion",
  USER_CHANGEPHOTO: "[AUTH] USER_CHANGEPHOTO",
};
export const GET_DIRECTIONS_USER = "GET_DIRECTIONS_USER";
//Forgot Password
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";

//tipos usuarios
export const GET_ALL_TYPE_USERS = "GET_ALL_TYPE_USERS";
export const ADD_TYPE_USERS = "ADD_TYPE_USERS";
export const UPDATE_TYPE_USERS = "UPDATE_TYPE_USERS";
export const DELETE_TYPE_USERS = "DELETE_TYPE_USERS";
export const CURRENT_TYPE_USER = "CURRENT_TYPE_USER";
export const AGREGATE_PERMISSIONS = "AGREGATE_PERMISSIONS";
export const UPDATE_PERMISSIONS = "UPDATE_PERMISSIONS";

//clients
export const GET_ALL_CLIENTS = "GET_ALL_CLIENTS";
export const SEARCH_CLIENTS = "SEARCH_CLIENTS";
export const GET_CLIENTS = "GET_CLIENTS";
export const REGISTER_CLIENT = "REGISTER_CLIENT";
export const CREATE_CLIENT_ACCOUNT = "CREATE_CLIENT_ACCOUNT";
export const GET_CLIENTS_ACCOUNTS = "GET_CLIENTS_ACCOUNTS";

//tipo de clientes
export const GET_TYPE_CLIENTS = "GET_TYPE_CLIENTS";
export const ADD_TYPE_CLIENTS = "ADD_TYPE_CLIENTS";
export const UPDATE_TYPE_CLIENT = "UPDATE_TYPE_CLIENTS";
export const DELETE_TYPE_CLIENTS = "DELETE_TYPE_CLIENTS";

//usuarios
export const GET_ALL_USERS = "GET_ALL_USERS";
export const ADD_USERS = "ADD_USERS";
export const UPDATE_USERS = "UPDATE_USERS";
export const DELETE_USERS = "DELETE_USERS";
export const CURRENT_USER = "CURRENT_USER";
export const GET_USERS_BLOCKED = "GET_USERS_BLOCKED";
export const USER_LOGUEADO = "USER_LOGUEADO";
export const USER_CHANGEPASSWORD = "USER_CHANGEPASSWORD";
export const RESTORE_USER = "RESTORE_USER";
export const GET_ALL_USERS_SEARCH = "GET_ALL_USERS_SEARCH";

//Categories
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const GET_CATEGORY = "GET_CATEGORY";
export const ADD_CATEGORIES = "ADD_CATEGORIES";
export const UPDATE_CATEGORIES = "UPDATE_CATEGORIES";
export const DELETE_CATEGORIES = "DELETE_CATEGORIES";
export const CURRENT_CATEGORIES = "CURRENT_CATEGORIES";
export const IMPORT_CATEGORIES_EXCEL = "IMPORT_CATEGORIES_EXCEL";
export const PRODUCTS_PER_CATEGORY = "PRODUCTS_PER_CATEGORY";
export const SEARCH_CATEGORY = "SEARCH_CATEGORY";

//Subcategories
export const GET_ALL_SUBCATEGORIES = "GET_ALL_CATEGORIES";
export const ADD_SUBCATEGORIES = "ADD_SUBCATEGORIES";
export const UPDATE_SUBCATEGORIES = "UPDATE_SUBCATEGORIES";
export const DELETE_SUBCATEGORIES = "DELETE_SUBCATEGORIES";
export const CURRENT_SUBCATEGORIES = "CURRENT_SUBCATEGORIES";
export const GET_ALL_SUBCATEGORIES_CATEGORY = "GET_ALL_SUBCATEGORIES_CATEGORY";
export const IMPORT_SUBCATEGORIES_EXCEL = "IMPORT_SUBCATEGORIES_EXCEL";
export const IMPORT_SUBCATEGORIES_EXCEL_ERROR = "IMPORT_PRODUCTS_EXCEL_ERROR";
export const SUBCATEGORIES_NO_PAGINATE = "SUBCATEGORIES_NO_PAGINATE";

//marcas
export const GET_ALL_BRANDS = "GET_ALL_BRANDS";
export const ADD_BRANDS = "ADD_BRANDS";
export const UPDATE_BRANDS = "UPDATE_BRANDS";
export const DELETE_BRANDS = "DELETE_BRANDS";
export const CURRENT_BRAND = "CURRENT_BRAND";

//Gastos
export const GET_ALL_EXPENSES = "GET_ALL_EXPENSES";
export const ADD_EXPENSES = "ADD_EXPENSES";
export const UPDATE_EXPENSES = "UPDATE_EXPENSES";
export const DELETE_EXPENSES = "DELETE_EXPENSES";
export const CURRENT_EXPENSE = "CURRENT_EXPENSE";

//faq-categories
export const GET_ALL_FAQ_CATEGORIES = "GET_ALL_FAQ_CATEGORIES";
export const ADD_FAQ_CATEGORIES = "ADD_FAQ_CATEGORIES";
export const UPDATE_FAQ_CATEGORIES = "UPDATE_FAQ_CATEGORIES";
export const DELETE_FAQ_CATEGORIES = "DELETE_FAQ_CATEGORIES";
export const CURRENT_FAQ_CATEGORY = "CURRENT_FA_CATEGORY";

//faqs
export const GET_ALL_FAQS = "GET_ALL_FAQS";
export const ADD_FAQS = "ADD_FAQS";
export const UPDATE_FAQS = "UPDATE_FAQS";
export const DELETE_FAQS = "DELETE_FAQS";
export const CURRENT_FAQ = "CURRENT_FAQ";

//tags
export const GET_ALL_TAGS = "GET_ALL_TAGS";
export const ADD_TAGS = "ADD_TAGS";
export const UPDATE_TAGS = "UPDATE_TAGS";
export const DELETE_TAGS = "DELETE_TAGS";
export const CURRENT_TAG = "CURRENT_TAG";
export const TAGS_NO_PAGINATE = "TAGS_NO_PAGINATE";

//sliderEcommerce
export const GET_ALL_SLIDERS = "GET_ALL_SLIDERS";
export const ADD_SLIDER = "ADD_SLIDER";
export const UPDATE_SLIDER = "UPDATE_SLIDER";
export const DELETE_SLIDER = "DELETE_SLIDER";
export const CURRENT_SLIDER = "CURRENT_SLIDER";

//coupons
export const GET_ALL_COUPONS = "GET_ALL_COUPONS";
export const ADD_COUPON = "ADD_COUPON";
export const UPDATE_COUPON = "UPDATE_COUPON";
export const DELETE_COUPON = "DELETE_COUPON";
export const CURRENT_COUPON = "CURRENT_COUPON";
export const COUPONS_EXPIRED = "COUPONS_EXPIRED";

//type_products
export const GET_ALL_TYPE_PRODUCTS = "GET_ALL_TYPE_PRODUCTS";
export const ADD_TYPE_PRODUCTS = "ADD_TYPE_PRODUCTS";
export const UPDATE_TYPE_PRODUCTS = "UPDATE_TYPE_PRODUCTS";
export const DELETE_TYPE_PRODUCTS = "DELETE_TYPE_PRODUCTS";
export const CURRENT_TYPE_PRODUCTS = "CURRENT_TYPE_PRODUCTS";

//type_category
export const GET_ALL_TYPE_CATEGORY = "GET_ALL_TYPE_CATEGORY";
export const ADD_TYPE_CATEGORY = "ADD_TYPE_CATEGORY";
export const UPDATE_TYPE_CATEGORY = "UPDATE_TYPE_CATEGORY";
export const DELETE_TYPE_CATEGORY = "DELETE_TYPE_CATEGORY";
export const CURRENT_TYPE_CATEGORY = "CURRENT_TYPE_CATEGORY";

//reglas de negocio
//get
export const GET_ALL_BUSINESS_RULES_SALES = "GET_ALL_BUSINESS_RULES_SALES";
export const GET_ALL_BUSSINESS_RULES_SUBCATEGORY =
  "GET_ALL_BUSSINESS_RULES_SUBCATEGORY";
export const GET_ALL_BUSINESS_RULES_SHIPPMENT =
  "GET_ALL_BUSINESS_RULES_SHIPPMENT";
export const GET_ALL_BUSSINESS_RULES_CATEGORY = "BUSINESS_RULES_CATEGORY";
export const GET_BUSINESS_RULE_SALES_FISIC = "GET_BUSINESS_RULE_SALES_FISIC";

// Add
export const ADD_BUSINESS_RULES = "ADD_BUSINESS_RULES";
export const ADD_BUSINESS_RULE_SALES_FISIC = "ADD_BUSINESS_RULE_SALES_FISIC";
export const ADD_BUSINESS_RULE_CATEGORY = "ADD_BUSINESS_RULE_CATEGORY";
export const ADD_BUSINESS_RULE_SUBCATEGORY = "ADD_BUSINESS_RULE_SUBCATEGORY";
export const ADD_BUSINESS_RULE_SHIPPMENT = "ADD_BUSINESS_RULE_SHIPPMENT";
//update
export const UPDATE_BUSINESS_RULES = "UPDATE_BUSINESS_RULES";
export const UPDATE_BUSINESS_RULES_SALES_FISIC =
  "UPDATE_BUSINESS_RULES_SALES_FISIC";
export const UPDATE_BUSINESS_RULES_CATEGORY = "UPDATE_BUSINESS_RULES_CATEGORY";
export const UPDATE_BUSINESS_RULES_SHIPPMENT =
  "UPDATE_BUSINESS_RULES_SHIPPMENT";
export const UPDATE_BUSINESS_RULES_SUBCATEGORY =
  "UPDATE_BUSINESS_RULES_SUBCATEGORY";
//DELETE
export const DELETE_BUSINESS_RULES_SALES = "DELETE_BUSINESS_RULES_SALES";
export const DELETE_BUSINESS_RULES_SUBCATEGORY =
  "DELETE_BUSINESS_RULES_SUBCATEGORY";
export const DELETE_BUSINESS_RULES_SHIPPMENT =
  "DELETE_BUSINESS_RULES_SHIPPMENT";
export const DELETE_BUSSINESS_RULES_CATEGORY =
  "DELETE_BUSSINESS_RULES_CATEGORY";
export const DELETE_BUSSINESS_RULES_SALES_FISIC = "BUSINESS_RULES_SALES_FISIC";

export const CURRENT_BUSINESS_RULES = "CURRENT_BUSINESS_RULES";

//costos de envio

export const GET_ALL_SHIPPING_COSTS = "GET_ALL_SHIPPING_COSTS";
export const ADD_SHIPPING_COSTS = "ADD_SHIPPING_COSTS";
export const UPDATE_SHIPPING_COSTS = "UPDATE_SHIPPING_COSTS";
export const DELETE_SHIPPING_COSTS = "DELETE_SHIPPING_COSTS";
export const CURRENT_SHIPPING_COSTS = "CURRENT_SHIPPING_COSTS";

//productos
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const GET_ALL_PRODUCTS_FILTER_PAGINATED =
  "GET_ALL_PRODUCTS_FILTER_PAGINATED";
export const ADD_PRODUCTS = "ADD_PRODUCTS";
export const GET_PRODUCT_DESACTIVATED = "GET_PRODUCT";
export const UPDATE_PRODUCTS = "UPDATE_PRODUCTS";
export const DELETE_PRODUCTS = "DELETE_PRODUCTS";
export const CURRENT_PRODUCTS = "CURRENT_PRODUCTS";
export const GET_ONE_PRODUCT = "GET_ONE_PRODUCT";
export const SHOW_PRODUCT = "SHOW_PRODUCT";
export const GET_ALL_PRODUCTS_DESACTIVATED = "GET_ALL_PRODUCTS_DESACTIVATED";
export const RESTORE_PRODUCT = "RESTORE_PRODUCT";
export const UPLOAD_MULTIMEDIA = "UPLOAD_MULTIMEDIA";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const ADD_PRODUCTS_EXCEL = "ADD_PRODUCTS_EXCEL";
export const UPDATE_PRODUCTS_EXCEL = "UPDATE_PRODUCTS_EXCEL";
export const CREATE_MULTIMEDIA = "CREATE_MULTIMEDIA";
export const EXPORT_PRODUCTS_EXCEL = "EXPORT_PRODUCTS_EXCEL";
export const IMPORT_PRODUCTS_EXCEL = "IMPORT_PRODUCTS_EXCEL";
export const IMPORT_PRODUCTS_EXCEL_ERROR = "IMPORT_PRODUCTS_EXCEL_ERROR";
export const IMPORT_UPDATE_PRODUCTS_EXCEL = "IMPORT_UPDATE_PRODUCTS_EXCEL";
export const UPDATE_PRODUCTS_EXCEL_ERROR = "UPDATE_PRODUCTS_EXCEL_ERROR";
export const GET_PRODUCTS_CANVAS_ONLY = "GET_PRODUCTS_CANVAS_ONLY";
export const SEARCH_PRODUCT = "SEARCH_PRODUCT";
export const SEARCH_PRODUCTS_DESACTIVATED = "SEARCH_PRODUCTS_DESACTIVATED";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const UPDATE_STOCK = "UPDATE_STOCK";
export const GET_ALL_MOVEMENTS = "GET_ALL_MOVEMENTS";
export const SEARCH_PRODUCTS_MOVEMENTS = "SEARCH_PRODUCTS_MOVEMENTS";
export const CREATE_ENTER_PRODUCTS = "CREATE_ENTER_PRODUCTS";
export const GET_ENTER_PRODUCTS = "GET_ENTER_PRODUCTS";
export const GET_ALL_PRODUCTS_SELECT = "GET_ALL_PRODUCTS_SELECT";
export const EXPORT_ALL_PRODUCTS_STOCK_EXCEL =
  "EXPORT_ALL_PRODUCTS_STOCK_EXCEL";

//ofertsa
export const GET_ALL_OFFERS = "GET_ALL_OFFERS";
export const ADD_OFFERS = "ADD_OFFERS";
export const UPDATE_OFFERS = "UPDATE_OFFERS";
export const DELETE_OFFERS = "DELETE_OFFERS";
export const CURRENT_OFFERS = "CURRENT_OFFERS";

//influencers
export const ABOUT_INFLUENCERS = "ABOUT_INFLUENCERS";
export const GET_ABOUT_INFLUENCERS = "GET_ABOUT_INFLUENCERS";
export const GET_ALL_ACCEPTED = "GET_ALL_ACCEPTED";
export const GET_ALL_REJECTED = "GET_ALL_REJECTED";
export const GET_ALL_PENDING = "GET_ALL_PENDING";
export const GET_ALL_PENDING_DASHBOARD = "GET_ALL_PENDING_DASHBOARD";
export const ACCEPT_INFLUENCER = "ACCEPT_INFLUENCER";
export const REJECTED_INFLUENCER = "REJECTED_INFLUENCER";

//warehouse
export const GET_ALL_SHIPPING_PENDING = "GET_ALL_SHIPPING_PENDING";
export const GET_ALL_SHIPPING_ACEPTED = "GET_ALL_SHIPPING_ACEPTED";
export const GET_ALL_SHIPPING_SHIPPED = "GET_ALL_SHIPPING_SHIPPED";
export const GET_ALL_SHIPPING_DISPUTED = "GET_ALL_SHIPPING_DISPUTED";
export const WAREHOUSE_SHIPPING_ACCEPT_ORDER =
  "WAREHOUSE_SHIPPING_ACCEPT_ORDER";
export const WAREHOUSE_SHIPPING_SHIPPED_ORDER =
  "WAREHOUSE_SHIPPING_SHIPPED_ORDER";
export const CURRENT_ORDER = "CURRENT_ORDER";
export const PDF_ORDER = "ORDER_PDF";
export const UPDATE_SHIPPING = "UPDATE_SHIPPING";
export const REMOVE_FROM_DISPUTE = "REMOVE_FROM_DISPUTE";
export const MAKE_AS_DISPUTE = "MAKE_AS_DISPUTE";
//warehouseCnavas
export const GET_ALL_SHIPPING_CANVAS_PENDING =
  "GET_ALL_SHIPPING_CANVAS_PENDING";
export const GET_ALL_SHIPPING_CANVAS_ACCEPTED =
  "GET_ALL_SHIPPING_CANVAS_ACCEPTED";
export const GET_ALL_SHIPPING_CANVAS_SHIPPED =
  "GET_ALL_SHIPPING_CANVAS_SHIPPED";
export const WAREHOUSE_SHIPPING_CANVAS_ACCEPT_ORDER =
  "WAREHOUSE_SHIPPING_CANVAS_ACCEPT_ORDER";
export const WAREHOUSE_SHIPPING_CANVAS_SHIPPED_ORDER =
  "WAREHOUSE_SHIPPING_CANVAS_SHIPPED_ORDER";
export const CURRENT_ORDER_CANVAS = "CURRENT_ORDER_CANVAS";
export const PDF_CANVAS_ORDER = "PDF_CANVAS_ORDER";
export const UPDATE_SHIPPING_CANVAS = "UPDATE_SHIPPING_CANVAS";
//logo ecommerce
export const GET_LOGO = "GET_LOGO";
export const UPDATE_LOGO = "UPDATE_LOGO";
export const CURRENT_LOGO = "CURRENT_LOGO";

//About
export const GET_ALL_ABOUT = "GET_ALL_ABOUT";
export const UPDATE_ABOUT = "UPDATE_ABOUT";
export const CURRENT_ABOUT = "CURRENT_ABOUT";

//DVISAS
export const GET_ALL_DIVISAS = "GET_ALL_DIVISAS";
export const ADD_DIVISAS = "ADD_DIVISAS";
export const UPDATE_DIVISAS = "UPDATE_DIVISAS";
export const DELETE_DIVISAS = "DELETE_DIVISAS";
export const CURRENT_DIVISAS = "CURRENT_DIVISAS";

//bancos
export const GET_ALL_BANKS = "GET_ALL_BANKS";
export const ADD_BANKS = "ADD_BANKS";
export const UPDATE_BANKS = "UPDATE_BANKS";
export const DELETE_BANKS = "DELETE_BANKS";
export const CURRENT_BANKS = "CURRENT_BANKS";
//cuentas
export const GET_ALL_ACCOUNTS = "GET_ALL_ACCOUNTS";
export const ADD_ACCOUNTS = "ADD_ACCOUNTS";
export const UPDATE_ACCOUNTS = "UPDATE_ACCOUNTS";
export const DELETE_ACCOUNTS = "DELETE_ACCOUNTS";
export const CURRENT_ACCOUNTS = "CURRENT_ACCOUNTS";
export const GET_ACCOUNT_ECOMMERCE = "GET_ACCOUNT_ECOMMERCE";
export const GET_ACCOUNT_FISIC = "GET_ACCOUNT_FISIC";
//paqueterias
export const GET_ALL_SHIPPMENTS = "GET_ALL_SHIPPMENTS";
export const ADD_SHIPPMENTS = "ADD_SHIPPMENTS";
export const UPDATE_SHIPPMENTS = "UPDATE_SHIPPMENTS";
export const DELETE_SHIPPMENTS = "DELETE_SHIPPMENTS";
export const CURRENT_SHIPPMENTS = "CURRENT_SHIPPMENTS";
//coupon new user
export const UPDATE_COUPON_NEW_USER = "UPDATE_COUPON_NEW_USER";
export const GET_COUPON_NEW_USER = "GET_COUPON_NEW_USER";
export const CURRENT_COUPON_NEW_USER = "CURRENT_COUPON_NEW_USER";

//shippment international
export const UPDATE_SHIPPMENT_INTERNATIONAL = "UPDATE_SHIPPMENT_INTERNATIONAL";
export const GET_SHIPPMENT_INTERNATIONAL = "GET_SHIPPMENT_INTERNATIONAL";
export const CURRENT_SHIPPMENT_INTERNATIONAL =
  "CURRENT_SHIPPMENT_INTERNATIONAL";
//permisions
export const GET_PERMISSIONS = "GET_PERMISSIONS";
export const GET_ALL_PERMISSIONS = "GET_ALL_PERMISSIONS";
export const GET_PERMISSIONS_TYPE_USER = "GET_PERMISSIONS_TYPE_USER";
export const GET_MODULES_PER_USER = "GET_MODULES_PER_USER";

//sales
export const GET_ALL_SALES = "GET_ALL_SALES";
export const SALES_WITHOUT_PAYMENT = "SALES_WITHOUT_PAYMENT";
export const SALES_PENDING_PAYMENT = "SALES_PENDING_PAYMENT";
export const SALES_APPROVE_PAYMENT = "SALES_APPROVE_PAYMENT";
export const SALES_CANCEL = "SALES_CANCEL";
export const CURRENT_SALE = "CURRENT_SALE";
export const AUTHORIZE_SALE = "AUTHORIZE_SALE";
export const RESTORE_SALE = "RESTORE_SALE";
export const CANCEL_ALL_SALES_EXPIRED = "CANCEL_ALL_SALES_EXPIRED";
export const CANCEL_SALE = "CANCEL_SALE";
export const GET_ALL_SALES_PENDING_PAYMENT_WITHOUT_PAGINATION =
  "GET_ALL_SALES_PENDING_PAYMENT_WITHOUT_PAGINATION";
export const REQUEST_IMAGES = "REQUEST_IMAGES";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const ADD_EVIDENCE = "ADD_EVIDENCE";
export const UPDATE_EVIDENCE = "UPDATE_EVIDENCE";
export const SEARCH_SALES = "SEARCH_SALES";
export const ADD_COMMENTS_ORDER = "ADD_COMMENTS_ORDER";
export const UPDATE_SHIPPING_COST = "UPDATE_SHIPPING_COST";
export const ADD_NEW_SHIPPMENT = "ADD_NEW_SHIPPMENT";
export const CANCEL_ORDERS_ATM = "CANCEL_ORDERS_ATM";
/**Update sales*/
export const ENABLE_UPDATE_SALE = "ENABLE_UPDATE_SALE";
export const DISABLED_UPDATE_SALE = "DISABLED_UPDATE_SALE";
export const ENABLE_UPDATE_SALE_PENDING_PAY = "ENABLE_UPDATE_SALE_PENDING_PAY";
export const DISABLE_UPDATE_SALE_PENDING_PAY =
  "DISABLE_UPDATE_SALE_PENDING_PAY";
export const ADD_PAYMENT = "ADD_PAYMENT";

//importar venta
export const DOWNLOAD_TEMPLATE_ORDER = "DOWNLOAD_TEMPLATE_ORDER";
export const CREATE_ORDER_FROM_EXCEL = "CREATE_ORDER_FROM_EXCEL";
export const COMPLETE_ORDER_EXCEL = "COMPLETE_ORDER_EXCEL";
//Facturar
export const GET_SALES_INVOICED = "GET_SALES_INVOICED";
export const GET_SALES_INVOICED_NO_PAGINATE = "GET_SALES_INVOICED_NO_PAGINATE";
export const GET_SALES_FOR_INVOICE = "GET_SALES_FOR_INVOICE";
export const GET_SALES_FOR_INVOICE_NO_PAGINATE =
  "GET_SALES_FOR_INVOICE_NO_PAGINATE";
export const GET_SALES_NOT_INVOICED = "GET_SALES_NOT_INVOICED";
export const GET_CONCILIATIONS = "GET_CONCILIATIONS";
export const GET_SALES_FOR_INVOICE_GLOBAL = "GET_SALES_FOR_INVOICE_GLOBAL";
export const CREATE_GLOBAL_INVOICE = "CREATE_GLOBAL_INVOICE";
export const GET_PDF_INVOICE = "GET_PDF_INVOICE";
export const GET_ZIP_INVOICE = "GET_ZIP_INVOICE";
export const CANCEL_INVOICE = "CANCEL_INVOICE";
export const REINVOICE = "REINVOICE";
export const INVOICE_ORDER = "INVOICE_ORDER";
export const GET_REQUEST_FOR_CANCEL_INVOICE = "GET_REQUEST_FOR_CANCEL_INVOICE";
export const GET_INVOICES_CANCELED = "GET_INVOICES_CANCELED";
export const REGISTER_SAT_DIRECTION = "REGISTER_SAT_DIRECTION";

//dashboard
export const TOTAL_PENDING = "TOTAL_PENDING";
export const TOTAL_PAYMENT = "TOTAL_PAYMENT";
export const SALES_OF_DAY = "SALES_OF_DAY";
export const SALES_OF_WEEK = "SALES_OF_WEEK";
export const SALES_OF_MONTH = "SALES_OF_MONTH";
export const COUNT_SHIPPED = "COUNT_SHIPPED";
export const INCOME_OF_DAY = "INCOME_OF_DAY";
export const INCOME_OF_WEEK = "INCOME_OF_WEEK";
export const INCOME_OF_MONTH = "INCOME_OF_MONTH";

export const PRODUCTS_STOCK = "PRODUCTS_STOCK";
export const PRODUCTS_MOST_SOLD = "PRODUCTS_MOST_SOLD";
export const PRODUCTS_LESS_SOLD = "PRODUCTS_LESS_SOLD";

//catalogo
export const PRODUCTS_CATALOGUE = "PRODUCTS_CATALOGUE";
export const PRODUCTS_CATALOGUE_NO_PAGINATION =
  "PRODUCTS_CATALOGUE_NO_PAGINATION";
//canvas measures
export const GET_ALL_MEASURES = "GET_ALL_MEASURES";
export const ADD_MEASURE = "ADD_MEASURE";
export const UPDATE_MEASURE = "UPDATE_MEASURE";
export const DELETE_MEASURE = "DELETE_MEASURE";
export const CURRENT_MEASURE = "CURRENT_MEASURE";
//permissioncountries
export const ADD_COUNTRY = "ADD_COUNTRY";
export const GET_ALL_COUNTRIES = "GET_ALL_COUNTRIES";
export const UPDATE_COUNTRY = "UPDATE_COUNTRY";
export const DELETE_COUNTRY = "DELETE_COUNTRY";
export const CURRENT_COUNTRY = "CUREENT_COUNTRY";
export const UPDATE_FILES = "UPDATE_FILES";
//canvasVariants
export const GET_ALL_CANVAS_VARIANTS = "GET_ALL_CANVAS_VARIANTS";
export const ADD_CANVAS_VARIANT = "ADD_CANVAS_VARIANT";
export const UPDATE_CANVAS_VARIANT = "UPDATE_CANVAS_VARIANT";
export const DELETE_CANVAS_VARIANT = "DELETE_CANVAS_VARIANT";
//cotizations
export const GENERATE_COTIZACION = "GENERATE_COTIZACION";
export const GET_COTIZATIONS = "GET_COTIZATIONS";
export const EXPORTEXCELCOTIZATION = "EXPORTEXCELCOTIZATION";
//Errors API
export const SHOW_ERRORS_API = "SHOW_ERRORS_API";

/**INVENTARIOS */
export const GET_ALL_INVENTORIES = "GET_ALL_INVENTORIES";
export const ADD_NEW_INVENTORY = "ADD_NEW_INVENTORY";
export const CURRENT_INVENTORY = "CURRENT_INVENTORY";
export const UPDATE_INVENTORY = "UPDATE_INVENTORY";
export const DELETE_INVENTORY = "DELETE_INVENTORY";

export const ADD_PRODUCT_INVENTORY = "ADD_PRODUCT_INVENTORY";
export const GET_ALL_PRODUCTS_INVENTORIES = "GET_ALL_PRODUCTS_INVENTORIES";
export const END_INVENTORY = "END_INVENTORY";
export const UPDATE_STOCK_PRODUCTS = "UPDATE_STOCK_PRODUCTS";
export const UPDATE_STOCK_ERRORS = "UPDATE_STOCK_ERRORS";

/**Salidas */
export const GET_ALL_EXIT_PRODUCTS_REPORTS = "GET_ALL_EXIT_PRODUCTS_REPORTS";
export const ADD_EXIT_PRODUCTS_REPORTS = "ADD_EXIT_PRODUCTS_REPORTS";
export const GET_ALL_DEPARTURES_NO_PAGINATE = "GET_ALL_DEPARTURES_NO_PAGINATE";
/**Ventas Fisicas */
export const SEND_CART = "SEND_CART";
export const SHOW_CART = "SHOW_CART";
export const GET_SALES_FISIC = "GET_SALES_FISIC";
export const CREATE_SALE = "CREATE_SALE";
export const GET_ONE_SALE = "GET_ONE_SALE";
/**Politicas de privacidad */
export const GET_POLICIES = "GET_POLICIES";
export const ADD_POLICIES = "ADD_POLICIES";
export const UPDATE_POLICIES = "UPDATE_POLICIES";
export const DELETE_POLICIE = "DELETE_POLICIE";
/**Estados y municipios */
export const GET_STATES = "GET_STATES";
export const GET_MUNICIPALITIES = "GET_MUNICIPALITIES";
/*Proveedores */
export const GET_PROVIDERS = "GET_PROVIDERS";
export const ADD_PROVIDER = "ADD_PROVIDER";
export const UPDATE_PROVIDER = "UPDATE_PROVIDER";
export const DELETE_PROVIDER = "DELETE_PROVIDER";
export const CREATE_TICKET_PROVIDER = "CREATE_TICKET_PROVIDER";
export const ADD_PAY_PROVIDER = "ADD_PAY_PROVIDER";
/**Cuentas por pagar*/
export const GET_ACCOUNTS_FOR_PAYMENT = "GET_ACCOUNTS_FOR_PAYMENT";
export const ADD_ACCOUNTS_FOR_PAYMENT = "ADD_ACCOUNTS_FOR_PAYMENT";
export const UPDATE_ACCOUNTS_FOR_PAYMENT = "UPDATE_ACCOUNTS_FOR_PAYMENT";
export const ADD_PAYMENT_TO_ACCOUNT = "ADD_PAYMENT_TO_ACCOUNT";
export const GET_ACCOUNTS_PROVIDER = "GET_ACCOUNTS_PROVIDER";
export const PDF_ALL_ACCOUNTS_PAYMENT = "PDF_ALL_ACCOUNTS_PAYMENT";
/**Cuentas por pagar */
export const GET_ACCOUNTS_RECEIVABLE = "GET_ACCOUNTS_RECEIVABLE";
export const ADD_ACCOUNTS_RECEIVABLE = "ADD_ACCOUNTS_RECEIVABLE";
export const UPDATE_ACCOUNTS_RECEIVABLE = "UPDATE_ACCOUNTS_RECEIVABLE";
export const ADD_PAYMENT_ACCOUNT_RECEIVABLE = "ADD_PAYMENT_ACCOUNT_RECEIVABLE";
export const GET_ACCOUNTS_CLIENT = "GET_ACCOUNTS_CLIENT";
//Reportes
export const REPORT_UTILITY = "REPORT_UTILITY";
export const GRAPH_UTILITY = "GRAPH_UTILITY";
//Mensajes de contacto
export const GET_ALL_MESSAGES = "GET_ALL_MESSAGES";
export const MAKE_AS_READ = "MAKE_AS_READ";
export const GET_UNREAD_MESSAGES = "GET_UNREAD_MESSAGES";
export const ATTEND_MESSAGE = "ATTEND_MESSAGE";
export const GET_MESSAGES_PER_USER = "GET_MESSAGES_PER_USER";
