import { ADD_TYPE_PRODUCTS, CURRENT_TYPE_PRODUCTS, DELETE_TYPE_PRODUCTS, GET_ALL_TYPE_PRODUCTS, SHOW_ERRORS_API, UPDATE_TYPE_PRODUCTS } from '../../types';

export default function TypeProductsReducer(state, action) {
    switch (action.type) {
        case GET_ALL_TYPE_PRODUCTS:
            return {
                ...state,
                type_products: action.payload,
                success: false,
                ErrorsApi: []
            }
        case ADD_TYPE_PRODUCTS:
            return {
                ...state,
                success: true,
                ErrorsApi: []
            }
        case SHOW_ERRORS_API:
            return {
                ...state,
                success: true,
                ErrorsApi: []
            }
        case CURRENT_TYPE_PRODUCTS:
            return {
                ...state,
                ErrorsApi: []
            }
        case UPDATE_TYPE_PRODUCTS:
            return {
                ...state,
                success: true,
                type_product: action.payload
            }
        case DELETE_TYPE_PRODUCTS:
            return {
                ...state,
                success: true,
                type_products: state.type_products.filter(type_product => type_product.id !== action.payload)
            }

        default:
            return state;
    }

}
