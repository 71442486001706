import React, { useReducer } from "react";
import Swal from "sweetalert2";
import MethodGet, {
  MethodDelete,
  MethodPost,
  MethodPut,
} from "../../config/Service";
import {
  ADD_TYPE_PRODUCTS,
  CURRENT_TYPE_PRODUCTS,
  DELETE_TYPE_PRODUCTS,
  GET_ALL_TYPE_PRODUCTS,
  SHOW_ERRORS_API,
  UPDATE_TYPE_PRODUCTS,
} from "../../types";
import TypeProductsContext from "./TypeProductsContext";
import TypeProductsReducer from "./TypeProductsReducer";

const TypeProductsState = ({ children }) => {
  //estado inicial
  const initialState = {
    type_products: [],
    type_product: null,
    ErrorsApi: [],
    success: false,
  };
  const [state, dispatch] = useReducer(TypeProductsReducer, initialState);
  //obtener la lista de los tipos de products
  const GetTypeProducts = () => {
    sessionStorage.removeItem("type_product");
    let url = `/type_products`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_ALL_TYPE_PRODUCTS,
          payload: res.data.type_products,
        });
      })
      .catch((err) => {});
  };
  //funcion para agregar nuevo tipo de prod
  const AddTypeProduct = (type_product) => {
    const formData = new FormData();
    formData.append("name", type_product.name);

    let url = `/type_products/`;
    MethodPost(url, formData)
      .then((res) => {
        Swal.fire({
          title: "Tipo de producto Registrado",
          text: "El tipo de producto se ha regstrado exitosamente",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        dispatch({
          type: ADD_TYPE_PRODUCTS,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.res.dara.error,
          icon: "error",
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.res.data.error,
        });
      });
  };
  //funcion para editar el tipo de prod
  const UpdateTypeProducts = (type_product) => {
    const formData = new FormData();
    formData.append("name", type_product.name);
    let url = `/type_products/${type_product.id}`;
    MethodPut(url, formData)
      .then((res) => {
        Swal.fire({
          title: "Tipo de producto Actualizado",
          text: res.data.message,
          timer: 1500,
          showConfirmButton: false,
          icon: "success",
        });
        dispatch({
          type: UPDATE_TYPE_PRODUCTS,
        });
      })
      .catch((error) => {
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.res.data.error,
        });
      });
  };

  //eliminar tipo
  const DeleteTypeProducts = (id) => {
    Swal.fire({
      title: "¿Estas Seguro?",
      text: "El tipo de producto seleccionado será eliminado",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/type_products/${id}`;

        MethodDelete(url).then((res) => {
          Swal.fire({
            title: "Eliminado",
            text: res.data.message,
            icon: "success",
            timer: 1200,
            showConfirmButton: false,
          });
          dispatch({
            type: DELETE_TYPE_PRODUCTS,
            payload: id,
          });
        });
      }
    });
  };

  //funcion para seleccionar tupo de producto
  const SaveCurrentTypeProduct = (type_product) => {
    sessionStorage.setItem("type_product", JSON.stringify(type_product));
    dispatch({
      type: CURRENT_TYPE_PRODUCTS,
      payload: type_product,
    });
  };
  return (
    <TypeProductsContext.Provider
      value={{
        type_products: state.type_products,
        type_product: state.type_product,
        ErrorsApi: state.ErrorsApi,
        success: state.success,
        GetTypeProducts,
        AddTypeProduct,
        UpdateTypeProducts,
        DeleteTypeProducts,
        SaveCurrentTypeProduct,
      }}
    >
      {children}
    </TypeProductsContext.Provider>
  );
};

export default TypeProductsState;
