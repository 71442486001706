import { Grid, Radio } from "@mui/material";
import React from "react";

export default function Options({ selectedValue, handleChange }) {
  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={6}
        sm={6}
        md={6}
        lg={6}
        xl={6}
        display="flex"
        justifyContent="center"
      >
        <label style={{ marginTop: 7 }}>Codigo de barras</label>
        <Radio
          checked={selectedValue === "barcode"}
          onChange={handleChange}
          value="barcode"
          name="radio-buttons"
          inputProps={{ "aria-label": "barcode" }}
          sx={{ fontSize: "20px" }}
        />
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        md={6}
        lg={6}
        xl={6}
        display="flex"
        justifyContent="center"
      >
        <label style={{ marginTop: 7 }}>seleccionar producto</label>
        <Radio
          checked={selectedValue === "select_product"}
          onChange={handleChange}
          value="select_product"
          name="radio-buttons"
          inputProps={{ "aria-label": "select_product" }}
        />
      </Grid>
    </Grid>
  );
}
