import React, { useState, useContext, useEffect } from "react";
import PermissionCountriesContext from "../../context/PermissionsCountries/PermissionCountriesContext";
import MethodGet from "../../config/Service";
import { useForm } from "react-hook-form";
//Estilos , Icons y Alerta
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Box,
  Fab,
  Tooltip,
  Button,
  CardContent,
  Card,
  CardActions,
} from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import CircularProgress from "@mui/material/CircularProgress";

import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import DownloadIcon from "@mui/icons-material/Download";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
//pdf viewer
import Layout from "../../components/layout/Layout";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import BuildingComponent from "../../components/loading/BuildingComponent";
//
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Swal from "sweetalert2";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles({
  logoimagen: {
    height: "200px",
    //boxShadow: "5px 5px #289FED",
    objectFit: "cover",
  },
  input: {
    display: "none",
  },
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 10,
  },
});

const UpdateFiles = (props) => {
  const classes = useStyles();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const [cargando, spinnerCargando] = useState(false);
  const [country, SaveCountry] = useState({});
  const [attachments, saveAttachments] = useState({});
  const { UpdateFilesCountry, success } = useContext(
    PermissionCountriesContext
  );
  function AbrirTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }
  const [file_first_id, saveFileFirstId] = useState();
  const [file_second_id, saveFileSecondId] = useState();
  const [file_third_id, saveFileThirdId] = useState();
  const [file_fourth_id, saveFileFourthId] = useState();
  const [file_fifth_id, saveFileFifthId] = useState();

  const [file_first, setFileFirst] = useState({
    urlFileFirst:
      "http://bibliotecadigital.ilce.edu.mx/Colecciones/ObrasClasicas/_docs/JardinCerezos.pdf",
    file_first: "",
  });
  const [file_second, setFileSecond] = useState({
    urlFileSecond: "",
    file_second: "",
  });
  const [file_third, setFileThird] = useState({
    urlFileThird: "",
    file_third: "",
  });
  const [file_fourth, setFileFourth] = useState({
    urlFileFourth: "",
    file_fourth: "",
  });
  const [file_fifth, setFileFifth] = useState({
    urlFileFifth: "",
    file_fifth: "",
  });

  //cambio de archivos
  const handleChangeFileFirst = (e) => {
    setFileFirst({
      ...file_first,
      urlFileFirst: URL.createObjectURL(e.target.files[0]),
      file_first: e.target.files[0],
    });
  };
  const handleChangeFileSecond = (e) => {
    setFileSecond({
      ...file_second,
      urlFileSecond: URL.createObjectURL(e.target.files[0]),
      file_second: e.target.files[0],
    });
  };
  const handleChangeFileThird = (e) => {
    setFileThird({
      ...file_third,
      urlFileThird: URL.createObjectURL(e.target.files[0]),
      file_third: e.target.files[0],
    });
  };
  const handleChangeFileFourth = (e) => {
    setFileFourth({
      ...file_fourth,
      urlFileFourth: URL.createObjectURL(e.target.files[0]),
      file_fourth: e.target.files[0],
    });
  };
  const handleChangeFileFifth = (e) => {
    setFileFifth({
      ...file_fifth,
      urlFileFifth: URL.createObjectURL(e.target.files[0]),
      file_fifth: e.target.files[0],
    });
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
  } = useForm({ defaultValues: country });

  const CountryStorageId = JSON.parse(sessionStorage.getItem("country"));
  useEffect(() => {
    let url = `country-permissions/${CountryStorageId}`;
    MethodGet(url)
      .then((res) => {
        SaveCountry(res.data.country);
        saveAttachments(res.data.country.attachments);
        setFileFirst({
          urlFileFirst: res.data.country.attachments[0].file,
          attachments: "",
        });
        setFileSecond({
          urlFileSecond: res.data.country.attachments[1].file,
          attachments: "",
        });
        setFileThird({
          urlFileThird: res.data.country.attachments[2].file,
          attachments: "",
        });
        setFileFourth({
          urlFileFourth: res.data.country.attachments[3].file,
          attachments: "",
        });
        setFileFifth({
          urlFileFifth: res.data.country.attachments[4].file,
          attachments: "",
        });
        saveFileFirstId(res.data.country.attachments[0]._id);
        saveFileSecondId(res.data.country.attachments[1]._id);
        saveFileThirdId(res.data.country.attachments[2]._id);
        saveFileFourthId(res.data.country.attachments[3]._id);
        saveFileFifthId(res.data.country.attachments[4]._id);
        spinnerCargando(true);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
        spinnerCargando(true);
      });
  }, []);

  const getPDFName = (name = "") => {
    const nameReverse = name.split("").reverse().join("");
    const indexName = nameReverse.search("/");
    const newName = nameReverse
      .substring(0, indexName)
      .split("")
      .reverse()
      .join("");
    return newName;
  };

  useEffect(() => {
    if (success) {
      props.history.goBack();
    }
  }, [success]);

  const onSubmit = (data, e) => {
    data.id = CountryStorageId;
    if (file_first.file_first !== undefined) {
      data.file_first = file_first.file_first;
      data.file_first_id = file_first_id;
    }
    if (file_second.file_second !== undefined) {
      data.file_second = file_second.file_second;
      data.file_second_id = file_second_id;
    }
    if (file_third.file_third !== undefined) {
      data.file_third = file_third.file_third;
      data.file_third_id = file_third_id;
    }
    if (file_fourth.file_fourth !== undefined) {
      data.file_fourth = file_fourth.file_fourth;
      data.file_fourth_id = file_fourth_id;
    }
    if (file_fifth.file_fifth !== undefined) {
      data.file_fifth = file_fifth.file_fifth;
      data.file_fifth_id = file_fifth_id;
    }
    UpdateFilesCountry(data);
  };
  if (!cargando) return <BuildingComponent />;
  return (
    <Layout>
      <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.code === "Enter") e.preventDefault();
          }}
          autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "#000000",
                  textAling: "center",
                  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                  // borderRadius: "0 0 24px 0",
                }}>
                <div className={classes.titleCard}>
                  Actualizar archivos de permisos: {country.country}
                </div>
                <div className={classes.fab}>
                  <Tooltip title="Regresar">
                    <Fab
                      onClick={() => {
                        props.history.goBack();
                      }}
                      size="small"
                      sx={{
                        color: "white",
                        backgroundColor: "black",
                        borderRadius: "24px",
                        marginRight: 1,
                        "&:hover": {
                          backgroundColor: "white",
                          color: "black",
                        },
                      }}>
                      <ArrowCircleLeftOutlinedIcon />
                    </Fab>
                  </Tooltip>
                </div>
              </Box>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<TableContainer component={Paper}>
								<Table sx={{ minWidth: 700 }} aria-label="customized table">
									<TableHead>
										<TableRow>
											<StyledTableCell>Archivo</StyledTableCell>
											<StyledTableCell align="right">Opciones</StyledTableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{attachments.map((row) => (
											<StyledTableRow key={row.name}>
												<StyledTableCell component="th" scope="row">
													{getPDFName(row.file)}
												</StyledTableCell>
												<StyledTableCell align="right">
													<Button
														onClick={() => AbrirTab(row.file)}
														size="small"
														variant="cotained"
														sx={{
															backgroundColor: "#283593",
															color: "white",
															"&:hover": {
																color: "white",
																backgroundColor: "#283593",
															},
														}}
													>
														Ver archivo
													</Button>
													<input
														className={classes.input}
														id="icon-button-file-first"
														type="file"
														name="file"
														onChange={handleChangeFileFirst}
													/>
													<Button
														size="small"
														variant="cotained"
														sx={{
															backgroundColor: "#1b5e20",
															color: "white",
															"&:hover": {
																color: "white",
																backgroundColor: "#1b5e20",
															},
															marginLeft: 2,
														}}
													>
														<label htmlFor="icon-button-file-first">
															Cambiar archivo
														</label>
													</Button>
												</StyledTableCell>
											</StyledTableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid> */}
            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
              <Card sx={{ minWidth: 275 }}>
                <CardContent>{getPDFName(file_first.urlFileFirst)}</CardContent>
                <CardActions>
                  <Button
                    onClick={() => AbrirTab(file_first.urlFileFirst)}
                    size="small"
                    variant="cotained"
                    sx={{
                      backgroundColor: "#283593",
                      color: "white",
                      "&:hover": {
                        color: "white",
                        backgroundColor: "#283593",
                      },
                    }}>
                    Ver archivo
                  </Button>
                  <input
                    className={classes.input}
                    id="icon-button-file-first"
                    type="file"
                    name="file"
                    onChange={handleChangeFileFirst}
                  />
                  <Button
                    size="small"
                    variant="cotained"
                    sx={{
                      backgroundColor: "#1b5e20",
                      color: "white",
                      "&:hover": {
                        color: "white",
                        backgroundColor: "#1b5e20",
                      },
                    }}>
                    <label htmlFor="icon-button-file-first">
                      Cambiar archivo
                    </label>
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  {getPDFName(file_second.urlFileSecond)}
                </CardContent>
                <CardActions>
                  <Button
                    onClick={() => AbrirTab(file_second.urlFileSecond)}
                    size="small"
                    variant="cotained"
                    sx={{
                      backgroundColor: "#283593",
                      color: "white",
                      "&:hover": {
                        color: "white",
                        backgroundColor: "#283593",
                      },
                    }}>
                    Ver archivo
                  </Button>
                  <input
                    className={classes.input}
                    id="icon-button-file-second"
                    type="file"
                    name="file"
                    onChange={handleChangeFileSecond}
                  />
                  <Button
                    size="small"
                    variant="cotained"
                    sx={{
                      backgroundColor: "#1b5e20",
                      color: "white",
                      "&:hover": {
                        color: "white",
                        backgroundColor: "#1b5e20",
                      },
                    }}>
                    <label htmlFor="icon-button-file-second">
                      Cambiar archivo
                    </label>
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
              <Card sx={{ minWidth: 275 }}>
                <CardContent>{getPDFName(file_third.urlFileThird)}</CardContent>

                <CardActions>
                  <Button
                    onClick={() => AbrirTab(file_third.urlFileThird)}
                    size="small"
                    variant="cotained"
                    sx={{
                      backgroundColor: "#283593",
                      color: "white",
                      "&:hover": {
                        color: "white",
                        backgroundColor: "#283593",
                      },
                    }}>
                    Ver archivo
                  </Button>
                  <input
                    className={classes.input}
                    id="icon-button-file-third"
                    type="file"
                    name="file"
                    onChange={handleChangeFileThird}
                  />
                  <Button
                    size="small"
                    variant="cotained"
                    sx={{
                      backgroundColor: "#1b5e20",
                      color: "white",
                      "&:hover": {
                        color: "white",
                        backgroundColor: "#1b5e20",
                      },
                    }}>
                    <label htmlFor="icon-button-file-third">
                      Cambiar archivo
                    </label>
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  {getPDFName(file_fourth.urlFileFourth)}
                </CardContent>

                <CardActions>
                  <Button
                    onClick={() => AbrirTab(file_fourth.urlFileFourth)}
                    size="small"
                    variant="cotained"
                    sx={{
                      backgroundColor: "#283593",
                      color: "white",
                      "&:hover": {
                        color: "white",
                        backgroundColor: "#283593",
                      },
                    }}>
                    Ver archivo
                  </Button>
                  <input
                    className={classes.input}
                    id="icon-button-file-fourth"
                    type="file"
                    name="file"
                    onChange={handleChangeFileFourth}
                  />
                  <Button
                    size="small"
                    variant="cotained"
                    sx={{
                      backgroundColor: "#1b5e20",
                      color: "white",
                      "&:hover": {
                        color: "white",
                        backgroundColor: "#1b5e20",
                      },
                    }}>
                    <label htmlFor="icon-button-file-fourth">
                      Cambiar archivo
                    </label>
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
              <Card sx={{ minWidth: 275 }}>
                <CardContent>{getPDFName(file_fifth.urlFileFifth)}</CardContent>

                <CardActions>
                  <Button
                    onClick={() => AbrirTab(file_fifth.urlFileFifth)}
                    size="small"
                    variant="cotained"
                    sx={{
                      backgroundColor: "#283593",
                      color: "white",
                      "&:hover": {
                        color: "white",
                        backgroundColor: "#283593",
                      },
                    }}>
                    Ver archivo
                  </Button>
                  <input
                    className={classes.input}
                    id="icon-button-file-fifth"
                    type="file"
                    name="file"
                    onChange={handleChangeFileFifth}
                  />
                  <Button
                    size="small"
                    variant="cotained"
                    sx={{
                      backgroundColor: "#1b5e20",
                      color: "white",
                      "&:hover": {
                        color: "white",
                        backgroundColor: "#1b5e20",
                      },
                    }}>
                    <label htmlFor="icon-button-file-fifth">
                      Cambiar archivo
                    </label>
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  textAling: "center",
                  paddingBottom: 8,
                }}>
                <div className={classes.fab}>
                  <Fab
                    type="submit"
                    variant="extended"
                    sx={{
                      backgroundColor: "#00B9FF",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#00DEED",
                        color: "white",
                      },
                    }}>
                    <SaveOutlinedIcon sx={{ mr: 1 }} />
                    Guardar Archivos
                  </Fab>
                </div>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Layout>
  );
};

export default UpdateFiles;
