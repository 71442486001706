import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import Layout from "../../../components/layout/Layout";
import MethodGet from "../../../config/Service";
import AddProducts from "./AddProducts";
import InfoClient from "./InfoClient";
import ProductsOnOrder from "./ProductsOnOrder";
import SalesContext from "../../../context/Sales/SalesContext";
import { useContext } from "react";
const EditSale = (props) => {
  let id = props.match.params.id;
  const [order, saveOrder] = useState(); //estado que guarda toda la orden que se regresa en el response de la api
  const [cargando, spinnerCargando] = useState(false); //espiner para que no nos gane el renderizado
  const [client, saveClient] = useState(); //guardamos la informacion del cliente en este estado
  const [currentProducts, saveCurrentProducts] = useState([]); //guardamos los productos actuales de la orden
  const [newProducts, saveNewProducts] = useState([]); //guardamos la informacion de los nuevos productos
  const [deleteProducts, saveDeleteProducts] = useState([]); //guardamos la informacion de los productos que se eliminan de los que ya existian en la orden
  const [updateQuantityProducts, saveUpdateQuantityProducts] = useState([]); //guardamos la informacion de los productos que se actualiza la cantidad
  const [businessRule, saveBusinessRule] = useState(); //guardamos la informacion de la regla denegocio que tiene la venta
  const [shippmentDirection, saveShippmentDirection] = useState(); //guaramos la direccion de envio del cliente
  const [currentTotal, saveCurrentTotal] = useState(0); //guardamos el total actual de la orden
  const [newTotal, saveNewTotal] = useState(0); //guardamos el nuevo total de la orden
  const [shippingCost, saveShipping] = useState(0); //almacenamos el costo del envio
  const [totalOrder, saveTotalOrder] = useState(0); // guardamos el total de la orden ya con el costo de envio
  const arrNewProducts = newProducts.map((p) => {
    let attribute = {
      product_id: p.product_id,
      quantity: p.cantidad,
    };
    return attribute;
  }); //esta funcion mapea los nuevos productos agregados y manda solo la informacion necesaria
  const { UpdateOrder, success } = useContext(SalesContext); //es la funcion que nos permite actualizar la orden
  useEffect(() => {
    let url = `/orders/${id}`;
    MethodGet(url)
      .then((res) => {
        saveOrder(res.data.order);
        saveClient(res.data.order.client_id);
        saveCurrentProducts(res.data.order.products_list);
        if (res.data.order.business_rule_id) {
          saveBusinessRule(res.data.order.business_rule_id);
        }
        if (res.data.order.shippment_direction) {
          saveShippmentDirection(res.data.order.shippment_direction);
        }
        saveCurrentTotal(res.data.order.total);
        saveShipping(res.data.order.shippment);
        saveTotalOrder(res.data.order.total);
        spinnerCargando(true);
      })
      .catch((error) => {
        spinnerCargando(true);
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
      });
  }, [id]); // aqui realizamos una peticion a la api para obtener la orden
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm(); //funcion que nos permite enviar los datos
  const onSubmit = (data, e) => {
    if (newTotal < totalOrder) {
      Swal.fire({
        title: "Error",
        text: "No se puede editar la venta porque total es menor al total pagado, agrega más productos o modifica cantidades para poder continuar",
        icon: "error",
      });
      return;
    } else {
      data.new_products = newProducts;
      data.deleted_products = deleteProducts;
      data.updated_products = updateQuantityProducts;
      data.id = id;
      data.props = props;
      UpdateOrder(data);
      // props.history.goBack();
    }
  }; //funcion que se ejecuta al dar click en terminar edicion

  return (
    <Layout>
      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        <Grid item xs={12} sx={{ backgroundColor: "black", color: "white" }}>
          <Typography textAlign="center" variant="h4">
            Edicion de venta
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="end"
          sx={{ marginRight: 2 }}
        >
          <form
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter")
                e.preventDefault();
            }}
            autoComplete="off"
          >
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#ba68c8",
                color: "white",
                "&:hover": { backgroundColor: "#ba68c8", color: "white" },
              }}
            >
              Terminar edición
            </Button>
          </form>
        </Grid>
      </Grid>
      {client && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12} sx={{ margin: 2 }}>
            <InfoClient
              order={order}
              client={client}
              shippmentDirection={shippmentDirection}
              currentTotal={currentTotal}
              newTotal={newTotal}
              totalOrder={totalOrder}
              shippingCost={shippingCost}
            />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ margin: 2 }}>
          <AddProducts
            currentTotal={currentTotal}
            saveCurrentTotal={saveCurrentTotal}
            newTotal={newTotal}
            saveNewTotal={saveNewTotal}
            businessRule={businessRule}
            newProducts={newProducts}
            saveNewProducts={saveNewProducts}
            currentProducts={currentProducts}
            shippingCost={shippingCost}
            saveCurrentProducts={saveCurrentProducts}
          />
        </Grid>
      </Grid>
      <Grid spacing={2}>
        <Grid item xs={12} sx={{ margin: 2 }}>
          <ProductsOnOrder
            businessRule={businessRule}
            currentTotal={currentTotal}
            saveCurrentTotal={saveCurrentTotal}
            newTotal={newTotal}
            saveNewTotal={saveNewTotal}
            currentProducts={currentProducts}
            saveCurrentProducts={saveCurrentProducts}
            deleteProducts={deleteProducts}
            saveDeleteProducts={saveDeleteProducts}
            updateQuantityProducts={updateQuantityProducts}
            saveUpdateQuantityProducts={saveUpdateQuantityProducts}
            shippingCost={shippingCost}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default EditSale;
