import React, { useContext, useEffect } from "react";
import BussinesRulesContext from "../../context/BusinessRules/BusinessRulesContext";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Card, CardHeader, Grid, Typography } from "@mui/material";
export default function TableBussinessRules() {
  const { GetBusinessRulesSales, business_rules_sales } =
    useContext(BussinesRulesContext);
  useEffect(() => {
    GetBusinessRulesSales();
  }, []);
  return (
    <>
      <div>
        <Typography textAlign="center" fontWeight="bold" fontSize="25px">
          Descuentos
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Minimo de compra
                    </TableCell>
                    {business_rules_sales.map((row) => (
                      <TableCell align="center">
                        {" "}
                        ${row.minimum_money} MXN{" "}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Maximo de compra
                    </TableCell>
                    {business_rules_sales.map((row) => (
                      <TableCell align="center">
                        ${row.maximum_money} MXN{" "}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>Descuento</TableCell>
                    {business_rules_sales.map((row) => (
                      <TableCell align="center">{row.discount} %</TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
