import { GET_MUNICIPALITIES, GET_STATES } from "../../types";

export default function StatesReducer(state, action) {
  switch (action.type) {
    case GET_STATES:
      return {
        ...state,
        states: action.payload,
        success: false,
        ErrorsApi: [],
      };
    case GET_MUNICIPALITIES:
      return {
        ...state,
        municipalities: action.payload,
        success: false,
        ErrorsApi: [],
      };
    default:
      return state;
  }
}
