import { Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { PriceFormat } from "../../../utils/FormatPrice";
import SelectOptions from "./SelectOptions";
const DetailImportOrder = ({
  total,
  subtotal,
  discount,
  businessRule,
  orderProducts,
  client,
  saveClient,
  saveClientDirection,
  saveAccount,
  shippingCost,
  clientDirection,
  account,
  handleResetSale,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        xl={6}
        display="flex"
        justifyContent="center"
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            display="flex"
            justifyContent="center"
          >
            <Typography
              variant="h5"
              fontWeight="bold"
              fontFamily="monospace"
              sx={{ color: "#e91e63" }}
            >
              Detalle de la venta
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            display="flex"
            justifyContent="center"
          >
            <Grid container spacing={2}>
              {/**subtotal venta */}
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                display="flex"
                justifyContent="center"
              >
                <Typography variant="h6" textAlign="left">
                  Subtotal Venta
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                display="flex"
                justifyContent="center"
              >
                <Typography variant="h6">
                  $ {PriceFormat(subtotal)}
                  {/* {calculateTotal ? PriceFormat(calculateTotal?.subtotal) : 0} */}
                </Typography>
              </Grid>
              {/**Gastos de envio */}
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                display="flex"
                justifyContent="center"
              >
                <Typography variant="h6">Gastos de envio</Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                display="flex"
                justifyContent="center"
              >
                <Typography variant="h6">
                  $ {PriceFormat(shippingCost)}
                </Typography>
              </Grid>
              {/**descuento */}
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                display="flex"
                justifyContent="center"
              >
                <Typography variant="h6">Porcentaje de descuento</Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                display="flex"
                justifyContent="center"
              >
                <Typography variant="h6">
                  {businessRule.discount ?? 0} %
                </Typography>
              </Grid>
              {/**cantidad descontada */}
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                display="flex"
                justifyContent="center"
              >
                <Typography variant="h6">Cantidad descontada</Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                display="flex"
                justifyContent="center"
              >
                <Typography variant="h6">$ {PriceFormat(discount)}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Divider variant="inset" />
              </Grid>
              {/**total venta */}
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                display="flex"
                justifyContent="center"
              >
                <Typography variant="h6">Total Venta</Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                display="flex"
                justifyContent="center"
              >
                <Typography variant="h6">${PriceFormat(total)}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {total > 0 && subtotal > 0 && (
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <SelectOptions
            saveClient={saveClient}
            saveClientDirection={saveClientDirection}
            saveAccount={saveAccount}
            clientDirection={clientDirection}
            client={client}
            account={account}
            total={total}
            subtotal={subtotal}
            discount={discount}
            businessRule={businessRule}
            shippingCost={shippingCost}
            orderProducts={orderProducts}
            handleResetSale={handleResetSale}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default DetailImportOrder;
