import React, { useEffect } from "react";
import StyleIcon from "@mui/icons-material/Style";
//Estilos , Icons y Alerta
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Box,
  Fab,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import Chip from "@mui/material/Chip";
import { Fade } from "react-slideshow-image";
import ModalImage from "react-modal-image-responsive";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import SpinnerComponent from "../../components/loading/SpinnerComponent";
import { Image } from "react-bootstrap";
import MethodGet from "../../config/Service";
import { useState } from "react";
import SpinnerSmallComponent from "../../components/loading/SpinnerSmallComponent";
const useStyles = makeStyles({
  logoimagen: {
    height: "200px",
    boxShadow: "5px 5px #289FED",
    objectFit: "cover",
  },
  input: {
    display: "none",
  },
  card: {
    marginTop: 7,
    marginLeft: 7,
    marginRight: 7,
  },
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 10,
  },
  Imagelogo: {
    height: "131px",
    objectFit: "cover",
    textAlign: "center",
  },
});

export default function ProductShow({ modalShow, handleCloseShow, product }) {
  const classes = useStyles();
  let id = product._id;
  const [productinfo, saveProduct] = useState(null);
  const [cargando, spinnerCargando] = useState(false);
  const { brand, category, subcategory, variation, tags } = product;
  useEffect(() => {
    let url = `/products/admin/${id}`;
    MethodGet(url)
      .then((res) => {
        console.log(res.data, "la respuesta");
        spinnerCargando(true);
        saveProduct(res.data.product);
      })
      .catch((error) => {
        console.log(error);
        spinnerCargando(true);
      });
  }, [id]);

  return (
    <Dialog fullWidth maxWidth="md" open={modalShow} onClose={handleCloseShow}>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#000000",
            textAling: "center",
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            borderRadius: "0 0 24px 0",
          }}
        >
          <div className={classes.titleCard}>Detalle de producto</div>
          <div className={classes.fab}>
            <Tooltip title="cerrar" arrow>
              <Fab
                size="small"
                onClick={handleCloseShow}
                sx={{
                  color: "#FFFFFF",
                  backgroundColor: "#FF0000",
                  "&:hover": {
                    color: "#FFFFFF",
                    backgroundColor: "#FF0000",
                  },
                }}
              >
                <CancelPresentationIcon />
              </Fab>
            </Tooltip>
          </div>
        </Box>
      </DialogTitle>
      {product === null ? (
        <SpinnerComponent />
      ) : (
        <DialogContent>
          <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
            <Grid container spacing={2}>
              {product.product_type !== "" && (
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <b>Tipo de producto: </b>
                  {product.product_type === "1" ? "Variante" : "canvas"}
                </Grid>
              )}
              {product.barcode !== "" && (
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <b>Código de barras: </b>
                  {product.barcode}
                </Grid>
              )}
              {product.sku !== "" && (
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <b>SKU: </b>
                  {product.sku}
                </Grid>
              )}
              {product.product_key !== "" && (
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <b>product Key SAT: </b>
                  {product.product_key}
                </Grid>
              )}
              {product.google_product_category !== "" && (
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <b>Product Google Category: </b>
                  {product.google_product_category}
                </Grid>
              )}
              {product.name !== "" && (
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <b>Nombre:</b>
                  {product.name}
                </Grid>
              )}
              {product.description !== "" && (
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <b>Descripción:</b>
                  {product.description}
                </Grid>
              )}
              {brand.name !== "" && (
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <b>Marca:</b>
                  {brand.name}
                </Grid>
              )}
              {category.name !== "" && (
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <b>Categoría:</b>
                  {category.name}
                </Grid>
              )}
              {subcategory.name !== "" && (
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <b>Subcategoria:</b>
                  {subcategory.name}
                </Grid>
              )}
              {tags !== "" && (
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <b>Etiquetas:</b>
                  {tags.length !== 0
                    ? tags.map((tag) => (
                        <p>
                          <StyleIcon /> {tag.tag_id.name ? tag.tag_id.name : ""}
                        </p>
                      ))
                    : " No se registro esta información"}
                </Grid>
              )}
              {product.quantity !== "" && (
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <b>Stock:</b>
                  {product.quantity >= 200 ? (
                    <Chip
                      label={product.quantity}
                      variant="outlined"
                      style={{ backgroundColor: "#2e7d32", color: "white" }}
                    />
                  ) : product.quantity >= 100 ? (
                    <Chip
                      label={product.quantity}
                      variant="outlined"
                      style={{ backgroundColor: "#f9a825" }}
                    />
                  ) : product.quantity < 99 ? (
                    <Chip
                      label={product.quantity}
                      variant="outlined"
                      style={{ backgroundColor: "#dd2c00", color: "white" }}
                    />
                  ) : (
                    "Sin stock"
                  )}
                  Pzas.
                </Grid>
              )}
              {product.price_purchase !== "" && (
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <b>Precio Compra: $</b>
                  {product.price_purchase}
                </Grid>
              )}
              {product.weight !== "" && (
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <b>Peso: </b>
                  {product.weight ? product.weight : ""} <b>grs.</b>
                </Grid>
              )}
              {product.price !== "" && (
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <b>Precio: $</b>
                  {product.price}
                </Grid>
              )}

              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <b>Ubicación: </b>
                {product.location ? product.location : "N/D"}
              </Grid>

              {product.discount !== 0 && (
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <b>Descuento: $</b>
                  {product.discount}
                </Grid>
              )}
              {product.product_type === "2" && product.product_type !== "" && (
                <>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <b>Variante canvas: </b>
                    {variation?.name}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <b>Imagenes requeridas: </b>
                    {variation?.number_images}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <b>Ejemplo de la variante: </b>
                    <ModalImage
                      className={classes.Imagelogo}
                      small={variation?.image}
                      large={variation?.image}
                      alt={variation?.name}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
          {/**IMagenes del producto */}

          {productinfo === null ? (
            <SpinnerSmallComponent />
          ) : (
            productinfo.multimedia.length && (
              <Box sx={{ width: "100%", display: "flex", paddingTop: 4, p: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box sx={{ width: "300px", margin: "0 auto" }}>
                      <Slide>
                        {productinfo.multimedia.map((fadeImage, index) => (
                          <div className="each-fade" key={index}>
                            <div className="image-container">
                              <Image src={fadeImage.path} />
                            </div>
                            <h2>{fadeImage.caption}</h2>
                          </div>
                        ))}
                      </Slide>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )
          )}
        </DialogContent>
      )}
    </Dialog>
  );
}
