import React, { useEffect, useContext } from "react";
import CategoriesContext from "../../context/CategoriesP/CategoriesContext";

import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";

export default function SelectCategory(props) {
  const { categories, GetCategories, getCategory } =
    useContext(CategoriesContext);
  useEffect(() => {
    GetCategories();
  }, []);

  const detectarCambiosCategory = (e) => {
    props.detectarCambiosCategory(e);
    getCategory(e.target.value);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Categorias</InputLabel>
          {props.CategoryId ? (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Categorias"
              onChange={detectarCambiosCategory}
              value={props.CategoryId}
            >
              {categories.map((categories) => (
                <MenuItem key={categories._id} value={categories._id}>
                  {categories.name}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Categorias"
              onChange={detectarCambiosCategory}
            >
              {categories.map((categories) => (
                <MenuItem key={categories._id} value={categories._id}>
                  {categories.name}
                </MenuItem>
              ))}
            </Select>
          )}
          <FormHelperText>Campo Requerido*</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}
