import React, { Fragment } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import ProductsContext from "../../context/Products/ProductsContext";
import Select from "react-select";
export default function ProductSelectReactSelect(props) {
  const { products, productsSelect } = useContext(ProductsContext);

  useEffect(() => {
    productsSelect();
  }, []);
  const selectStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };

  const detectarCambiosProductSelect = (value) => {
    props.detectarCambiosProductSelect(value);
  };
  return (
    <Fragment>
      <Select
        onChange={(value) => detectarCambiosProductSelect(value)}
        className="basic-single"
        classNamePrefix="select"
        name="product"
        styles={selectStyles}
        defaultValue={{ label: "Selecciona un producto ", value: "" }}
        placeholder="Selecciona producto"
        options={products.map((product) => {
          let attribute = {
            label: product.name,
            value: product._id,
          };
          return attribute;
        })}
      />
    </Fragment>
  );
}
