import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {
  Box,
  Grid,
  InputAdornment,
  InputLabel,
  Radio,
  TextField,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import AccountSelect from "../../SelectOptions/AccountsSelect";
import SalesContext from "../../../context/Sales/SalesContext";
import { useContext } from "react";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const useStyles = makeStyles({
  logoimagen: {
    height: "200px",
    //boxShadow: "5px 5px #289FED",
    objectFit: "cover",
  },
  input: {
    display: "none",
  },
  card: {
    marginTop: 7,
    marginLeft: 7,
    marginRight: 7,
  },
  titleCard: {
    color: "white",
    fontSize: "30px",
    fontFamily: "roboto",
    fontWeight: "bolder",
    paddingLeft: 10,
  },
  fab: {
    position: "relative",
    marginTop: 10,
  },
});

export default function AddPayment({ open, handleClose, id, topay, currency }) {
  const classes = useStyles();

  const { AddPayment } = useContext(SalesContext);
  const [image, setImage] = useState({
    urlPhoto:
      "https://coffective.com/wp-content/uploads/2018/06/default-featured-image.png.jpg",
    image: "",
  });

  const handleChangeImage = (e) => {
    setImage({
      ...image,
      urlPhoto: URL.createObjectURL(e.target.files[0]),
      image: e.target.files[0],
    });
  };
  /**radio button */
  const [selectedValue, setSelectedValue] = useState("Tarjeta");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const [account, saveAccount] = useState();
  const detectarCambiosAccount = (value) => {
    saveAccount(value.value);
  };
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const onSubmit = (data, e) => {
    if (image.image === "") {
      Swal.mixin({
        toast: true,
        background: "red",
        color: "white",
        iconColor: "white",
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire({
        icon: "error",
        title: `Sube el comprobante de pago por transferencia`,
      });
      return;
    }
    data.image = image.image;
    data.id = id;
    data.bank_account = account;
    if (selectedValue === "Tarjeta") {
      data.type = 1;
    } else {
      data.type = 2;
    }
    AddPayment(data);
    handleClose();
  };
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography fontWeight="bold">Agregar comprobante de Pago</Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <form
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumpadEnter")
                e.preventDefault();
            }}
            autoComplete="off"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography textAlign="center" fontWeight="bold">
                  Resta por pagar: $ {topay}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <img
                      src={image.urlPhoto}
                      className={classes.logoimagen}
                      alt="comprobante"
                    />
                  </div>
                </Box>

                <input
                  className={classes.input}
                  id="icon-button-file"
                  type="file"
                  name="image"
                  accept="image/png, image/jpg, image/jpeg, image/webp"
                  onChange={handleChangeImage}
                />
                <InputLabel sx={{ textAlign: "center" }}>
                  Selecciona el comprobante :
                  <label htmlFor="icon-button-file">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <Tooltip
                        title="seleccionar imagen"
                        aria-label="seleccionar imagen"
                      >
                        <PhotoCamera />
                      </Tooltip>
                    </IconButton>
                  </label>
                </InputLabel>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                display="flex"
                justifyContent="center"
              >
                <div>
                  <label>Tarjeta</label>
                  <Radio
                    checked={selectedValue === "Tarjeta"}
                    onChange={handleChange}
                    value="Tarjeta"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "Tarjeta" }}
                  />
                  <label>Transferencia Electronica</label>
                  <Radio
                    checked={selectedValue === "Transferencia"}
                    onChange={handleChange}
                    value="Transferencia"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "Transferencia" }}
                  />
                </div>
              </Grid>
              {selectedValue === "Transferencia" && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <AccountSelect
                    detectarCambiosAccount={detectarCambiosAccount}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  label="Monto"
                  fullWidth
                  id="outlined-number"
                  placeholder="Cantidad a pagar"
                  type="text"
                  name="amount"
                  min="0"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {currency.currency}
                      </InputAdornment>
                    ),
                  }}
                  error={errors.amount ? true : false}
                  helperText={errors?.amount?.message}
                  {...register("amount", {
                    required: {
                      value: true,
                      message: "Este campo es requerido",
                    },
                  })}
                />
              </Grid>
              {selectedValue !== "Tarjeta" && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    fullWidth
                    label="Referencia"
                    id="outlined-number"
                    placeholder="4564682245"
                    type="text"
                    name="reference"
                    error={errors.reference ? true : false}
                    helperText={errors?.reference?.message}
                    {...register("reference", {
                      required: {
                        value: true,
                        message: "Este campo es requerido",
                      },
                    })}
                  />
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                display="flex"
                justifyContent="center"
              >
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    backgroundColor: "#f06292",
                    color: "white",
                    "&:hover": { backgroundColor: "#f06292", color: "white" },
                  }}
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
