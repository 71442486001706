import { Button, Grid, TextField } from "@mui/material";
import ProductsContext from "../../../context/Products/ProductsContext";
import React from "react";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { useState } from "react";
import ShopProductCard from "../../../components/Cards/products/ProductCard";
import SpinnerComponent from "../../../components/loading/SpinnerComponent";
import NoResultsComponent from "../../../components/loading/NoResultsComponent";
import ModulesContext from "../../../context/Permissions/PermissionsContext";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
export default function SearchProducts() {
  const { products, searchProduct } = useContext(ProductsContext);
  const { permissionsPerUser, GetModulesPerUser } = useContext(ModulesContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  useEffect(() => {
    if (localStorage.getItem("module_id")) {
      const permiss = localStorage.getItem("module_id");
      GetModulesPerUser(permiss);
      // if (params !== null) {
      searchProduct(query);
      // }
    }
  }, []);
  /**Helper search */
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery().get("search");
  const [palabra, setPalabra] = useState(query);
  const history = useHistory();
  // const handleSearchRequest = (e) => {
  //   history.push({
  //     pathname: history.location.pathname,
  //     search: `search=${e.trim()}`,
  //   });
  // };
  // const handleCancelSearch = () => {
  //   history.push({
  //     pathname: "/Productos",
  //     search: "",
  //   });
  // };
  /**termina search */

  const onSubmit = (data, e) => {
    if (data.palabra !== null) {
      history.push({
        pathname: history.location.pathname,
        search: `search=${data.palabra}`,
      });
      console.log(history, "el history");
      searchProduct(data.palabra);
      setPalabra(data.palabra);
    } else {
      //setPalabra(null);
      //searchProduct(data.palabra);
      history.push({
        pathname: history.location.pathname,
        search: `search=${data.palabra}`,
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Grid container spacing={2} sx={{ padding: 2, marginTop: -5 }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              fullWidth
              label="Buscar Producto"
              id="filled-size-small"
              variant="outlined"
              size="large"
              name="palabra"
              defaultValue={query}
              {...register("palabra", {
                required: {
                  value: false,
                  message: "Ingresa una palabra para buscar",
                },
              })}
            />
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <Button type="submit" value="Buscar" sx={{ display: "none" }}>
              Buscar
            </Button>
          </Grid>
        </Grid>
      </form>
      <Grid container spacing={2}>
        {palabra &&
          (products.length ? (
            products.map((product, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={4}
                xl={3}
                key={index}
                justifyContent="center">
                {products.length ? (
                  <ShopProductCard
                    permiss={permissionsPerUser}
                    product={product}
                    search={true}
                  />
                ) : (
                  <SpinnerComponent />
                )}
              </Grid>
            ))
          ) : (
            <NoResultsComponent />
          ))}
      </Grid>
    </>
  );
}
